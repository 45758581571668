import React, { useState } from 'react';

// Style
import style from 'style';

// ---------------------------------------- PROGRESS BAR ---------------------------------------- \\
const ProgressBar = ({ done }) => {
	const [styles, setStyles] = useState({});

	setTimeout(() => {
		const newStyles = {
			opacity: 1,
			width: `${done}%`
		};
		
		setStyles(newStyles);
	}, 200);

	return (
		<div className="progress">
			<div className="progress-done" style={styles}>
				{done > 2 && <>{done}%</>}
			</div>
			{done < 3 && (
				<div style={{ ...style.ml5, ...style.cW, ...style.dF, ...style.aiC }}>
					{done}%
				</div>
			)}
		</div>
	);
};

export default ProgressBar;
