import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider, IconButton, Button } from '@material-ui/core';
import { AssignmentTurnedIn as IconMobipass, School as IconMobitrain, Search as IconSearch, Style as IconPortfolio } from '@material-ui/icons';

// Services
import { getLearnerStatistics, getUser } from 'services/requests';
import { mobipassColor, mobitrainColor, getTranslation, getStatisticMonth } from 'services/helpers';
import { months } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import { EvaluationsChartBarExport } from '../evaluations/evaluations-export';
import MobitrainBarExport from '../formations/formations-export';
import SkillbasesChart from '../skillbases/skillbases-chart';
import ChartBar from 'components/items/chart-bar';
import ProgressBar from 'components/items/progress-bar';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import DatePicker from 'components/items/date-picker';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

const styles = {
    border: {
        border: '1px solid lightgray',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        marginBottom: 25,
    },
    legend: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
};

// -------------------------------------- LEARNER STATISTICS -------------------------------------- \\
const LearnerStatistics = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { companyId, learnerId } = useParams();
    const [learnerName, setLearnerName] = useState(null);

    useEffect(() => {
        getUser(learnerId).then(res => {
            if (res?.status === 200) {
                setLearnerName(res.data.data.user.full_name);
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button 
                        onClick={() => history.push(`/management/statistics/${companyId}/learners`)}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    <span>{learnerName}</span>
                </Grid>
            </Grid>
            <div className="full-container">
                <EvaluationsChart />
                <FormationsChart />
                <SkillbasesChart />
            </div>
        </>
    );
};

// --------------------------------------- EVALUATIONS CHART -------------------------------------- \\
const EvaluationsChart = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { learnerId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null,
        dataBar: null,
        skillbases: null,
        start_date: moment().subtract(5, 'months'),
        end_date: moment(),
        total_evaluated_learning_outcomes: 0,
        total_validated_learning_outcomes: 0,
    });

    const onSearch = () => {
        if (state.start_date == null || state.end_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.start_date > state.end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else if (state.end_date > moment()) {
            enqueueSnackbar(t('date.selected.compared.date.today'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            getLearnerStatistics({
                module: 'mobipass',
                start_date: moment(state.start_date).format('YYYY-MM-DD'),
                end_date: moment(state.end_date).format('YYYY-MM-DD'),
                learner_id: learnerId,
            }).then(res => {
                if (res?.status === 200) {

                    let res_months = res.data.data.months;
                    const dataBar = Object.entries(res_months).map(([key, value]) => ({
                        'month': getStatisticMonth(t, res_months, key),
                        [t('evaluated.learning.outcomes')]: value.nb_evaluated_learning_outcomes,
                        [t('validated.learning.outcomes')]: value.nb_validated_learning_outcomes,
                    }));

                    setState({
                        ...state,
                        loading: false,
                        data: res.data.data,
                        dataBar,
                        skillbases: res.data.data.skillbases,
                        total_evaluated_learning_outcomes: res.data.data.total_evaluated_learning_outcomes,
                        total_validated_learning_outcomes: res.data.data.total_validated_learning_outcomes,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <Grid style={styles.border}>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justifyContent="center" alignItems="center"
                style={{ backgroundColor: mobipassColor, ...style.p10, ...style.cW }}
            >
                <IconMobipass />
                <span style={{ ...style.ml5, ...style.ttU }}>
                    {t('evaluations')}
                </span>
            </Grid>
            {/* -------------------- SELECT PERIOD -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} md={8} style={style.p10}>
                    <b className="input-title">{t('period')} : </b>
                    <DatePicker
                        placeholder={t('start.date')}
                        value={state.start_date}
                        onChange={start_date => setState({ ...state, start_date })}
                        fullWidth={false}
                        outlined
                    />
                    <span> - </span>
                    <DatePicker
                        placeholder={t('end.date')}
                        value={state.end_date}
                        onChange={end_date => setState({ ...state, end_date })}
                        fullWidth={false}
                        outlined
                    />
                    <Tooltip
                        title={t('search')}
                        item={(
                            <IconButton style={{ padding: 8 }} onClick={onSearch}>
                                <IconSearch />
                            </IconButton>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'} style={style.p10}>
                    <EvaluationsChartBarExport type="learner" data={state.data} />
                </Grid>
            </Grid>
            {/* -------------------- CHART BAR -------------------- */}
            <ChartBar
                data={state.dataBar}
                keys={[t('evaluated.learning.outcomes'), t('validated.learning.outcomes')]}
                indexBy="month"
            />
            {/* -------------------- TOTALS -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs="auto" style={style.p10}>
                    <span>{t('totals')} :</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#e8c1a0' }} />
                    <span>{state.total_evaluated_learning_outcomes} {t('evaluated.learning.outcomes')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f47560' }} />
                    <span>{state.total_validated_learning_outcomes} {t('validated.learning.outcomes')}</span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- PERCENT PER SKILLBASE -------------------- */}
            <Grid container>
                <Grid item xs={6} style={style.p10}>
                    <span>{t('percentage.validated.per.skillbase')} :</span>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" style={style.p10}>
                    <Tooltip
                        title={t('portfolio.access')}
                        item={(
                            <IconButton
                                style={{ padding: 8 }}
                                onClick={() => history.push(`/mobipass/portfolio/${learnerId}`)}
                            >
                                <IconPortfolio />
                            </IconButton>
                        )}
                    />
                </Grid>
            </Grid>
            {state.skillbases.map(skillbase => (
                <Grid key={skillbase.id} container alignItems="center" style={style.p10}>
                    <Grid item sm={2} md={3} lg={4} />
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <span>{getTranslation('name', skillbase)}</span>
                        <ProgressBar done={Math.round(skillbase.percentage_validated)} />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

// --------------------------------------- FORMATIONS CHART --------------------------------------- \\
const FormationsChart = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { learnerId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null,
        dataBar: null,
        start_date: moment().subtract(5, 'months'),
        end_date: moment(),
        total_h_scenario: 0,
        total_h_reflection: 0,
        total_h_others: 0,
    });

    const onSearch = () => {
        if (state.start_date == null || state.end_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.start_date > state.end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else if (state.end_date > moment()) {
            enqueueSnackbar(t('date.selected.compared.date.today'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            getLearnerStatistics({
                module: 'mobitrain',
                start_date: moment(state.start_date).format('YYYY-MM-DD'),
                end_date: moment(state.end_date).format('YYYY-MM-DD'),
                learner_id: learnerId,
            }).then(res => {
                if (res?.status === 200) {
                    const dataBar = Object.entries(res.data.data.months).map(([key, value]) => ({
                        "month": t(months.find(m => m.value === key.split('-')[1]).label) + ' ' + key.split('-')[0],
                        [t('scenario')]: value.h_scenario,
                        [t('reflection')]: value.h_reflection,
                        [t('others')]: value.h_others,
                    }));
                    setState({
                        ...state,
                        loading: false,
                        data: res.data.data,
                        dataBar,
                        total_h_scenario: res.data.data.total_h_scenario,
                        total_h_reflection: res.data.data.total_h_reflection,
                        total_h_others: res.data.data.total_h_others,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <Grid style={styles.border}>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justifyContent="center" alignItems="center"
                style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.cW }}
            >
                <IconMobitrain />
                <span style={{ ...style.ml5, ...style.ttU }}>
                    {t('formations')}
                </span>
            </Grid>
            {/* -------------------- PERIOD -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} md={8} style={style.p10}>
                    <b className="input-title">{t('period')} : </b>
                    <DatePicker
                        placeholder={t('start.date')}
                        value={state.start_date}
                        onChange={start_date => setState({ ...state, start_date })}
                        fullWidth={false}
                        outlined
                    />
                    <span> - </span>
                    <DatePicker
                        placeholder={t('end.date')}
                        value={state.end_date}
                        onChange={end_date => setState({ ...state, end_date })}
                        fullWidth={false}
                        outlined
                    />
                    <Tooltip
                        title={t('search')}
                        item={(
                            <IconButton style={{ padding: 8 }} onClick={onSearch}>
                                <IconSearch />
                            </IconButton>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'} style={style.p10}>
                    <MobitrainBarExport type="learner" data={state.data} />
                </Grid>
            </Grid>
            {/* -------------------- CHART BAR -------------------- */}
            <ChartBar
                data={state.dataBar}
                keys={[t('scenario'), t('reflection'), t('others')]}
                indexBy="month"
            />
            {/* -------------------- TOTALS -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs="auto" style={style.p10}>
                    <span>{t('totals')} :</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#e8c1a0' }} />
                    <span>{state.total_h_scenario} {t('scenario')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f47560' }} />
                    <span>{state.total_h_reflection} {t('reflection')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f1e15b' }} />
                    <span>{state.total_h_others} {t('others')}</span>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LearnerStatistics;
