import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { getSoftCategories } from 'services/requests';
import { getTranslation} from 'services/helpers';

// Components
import Select from 'components/items/react-select';

// ------------------------------------ SELECT SOFT SKILL ----------------------------------------- \\
const SelectSoftSkill = ({ softSkillId, setSoftSkillId }) => {
    const { t } = useTranslation();
    const [softSkills, setSoftSkills] = useState([]);

    useEffect(() => {
        getSoftCategories().then(res => {
            if (res?.status === 200) {
                let softSkillsResources = [];
                res.data.data.soft_categories.forEach(soft_category => {
                    soft_category.softskills.forEach(softSkill => {
                        softSkillsResources.push({ value: softSkill.id, label: getTranslation('name', softSkill) });
                    });
                });
                setSoftSkills(softSkillsResources);
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            value={softSkillId ? softSkills.find(c => c.value === softSkillId) : null}
            onChange={softSkill => setSoftSkillId(softSkill ? softSkill.value : null)}
            options={softSkills}
            placeholder={t('soft.skill') + ' + ' + t('level')}
        />
    );
};

export default SelectSoftSkill;
