import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getFormattedDate, getTranslation } from 'services/helpers';
import { getEvaluations } from 'services/requests';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- EVALUATION EXPORT ---------------------------------------- \\
const ExportEvaluation = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
    });

    function getAllEvaluationsToExport(evaluations, offset) {
        getEvaluations({
            offset: offset,
            limit: data.limit,
            name: data.name,
            company_id: data.company_id,
            skillbase_id: data.skillbase_id,
            assessor: data.assessor,
            learner: data.learner,
            export_excel: true,
        }).then(res => {
            if (res?.status === 200) {

                let res_evaluations = res.data.data.evaluations;
                evaluations = evaluations.concat(res_evaluations);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * evaluations.length) / data.total_count) + '%',
                });

                if (res_evaluations.length < data.limit) {
                    createExcelFile(evaluations);
                }
                else {
                    getAllEvaluationsToExport(evaluations, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(evaluations) {
        if (evaluations.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('evaluations'));

            // Header
            worksheet.columns = [
                { header: t('name'), key: 'name', width: 35 },
                { header: t('date'), key: 'date', width: 15 },
                { header: t('company'), key: 'company', width: 25 },
                { header: t('assessor'), key: 'assessor', width: 25 },
                { header: t('learner'), key: 'learner', width: 25 },
                { header: t('skillbase'), key: 'skillbase', width: 25 },
                { header: t('learning.outcomes'), key: 'learning', width: 35 },
                { header: t('comment'), key: 'comment', width: 25 }
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            evaluations.map(evaluation => {

                let learners = '';
                evaluation.users.map(user => {
                    return learners = learners + user.full_name + '\r\n';
                });

                let learnings = '';
                evaluation.learning_outcomes.map(learning => {
                    return learnings = learnings + learning.position + '. ' + getTranslation('name', learning) + '\r\n';
                });

                return worksheet.addRow({
                    name: evaluation.name,
                    date: getFormattedDate(evaluation.evaluation_date),
                    company: evaluation.company.name,
                    assessor: evaluation.assessor.full_name,
                    learner: learners,
                    skillbase: getTranslation('name', evaluation.skillbase),
                    learning: learnings,
                    comment: evaluation.comment
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('evaluations') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllEvaluationsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                        className="list-btn"
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default ExportEvaluation;
