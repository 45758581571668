import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getCompanyList, updateFormationModel, getFormationModel, getCompanySkillbases } from 'services/requests';
import useMediaQueries from 'services/media';
import { getTranslation } from 'services/helpers';

// Components
import FormationModelFormPage1 from './formation-model-form/formation-model-form-page-1';
import FormationModelFormPage2 from './formation-model-form/formation-model-form-page-2';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- CREATE FORMATION MODEL ---------------------------------------- \\
const UpdateFormationModel = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { formationModelId } = useParams();

    const [state, setState] = useState({
        loadingFormationModel: true,
        loadingResources: false,
        errorResources: false,
        loading: false,
        actionType: 'UPDATE',
        page: 1,
        formation_model: null,

        // Page 1
        name: '',
        companies: [],
        company_id: null,
        skillbases: [],
        skillbase_id: null,

        // Page 2
        loadingSkillbase: true,
        errorSkillbase: false,
        skillbase: null,
        descriptors: {},
        learning: null,
        openLearningModal: false,
    });

    useEffect(() => {
        getFormationModel(formationModelId).then(res => {
            if (res?.status === 200) {
                const formation_model = res.data.data.formation_model;
                setState({
                    ...state,
                    loadingFormationModel: false,
                    loadingResources: true,
                    formation_model,
                    name: formation_model.name,
                    company_id: formation_model.company?.id,
                    skillbase_id: formation_model.skillbase?.id,
                });
            }
            else {
                console.log(res);
                setState({ ...state, loadingFormationModel: false, errorResources: true });
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getCompanySkillbases(state.company_id), getCompanyList()])
            .then(axios.spread(function (res_skillbases, res_companies) {
                if (res_skillbases?.status === 200 && res_companies?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                    });
                }
                else {
                    console.log(res_skillbases + res_companies);
                    setState(state => ({ ...state, loadingResources: false, errorResources: true }));
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {

            let competences = [], knowledges = [], skills = [];
            Object.values(state.descriptors).forEach(descriptor => {
                competences = competences.concat(descriptor.filter(d => d.includes('competence_')));
                knowledges = knowledges.concat(descriptor.filter(d => d.includes('knowledge_')));
                skills = skills.concat(descriptor.filter(d => d.includes('skill_')));
            });

            competences = competences.map(competence => competence.split('competence_')[1]);
            knowledges = knowledges.map(knowledge => knowledge.split('knowledge_')[1]);
            skills = skills.map(skill => skill.split('skill_')[1]);

            updateFormationModel(formationModelId, {
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
                competence_ids: competences,
                knowledge_ids: knowledges,
                skill_ids: skills,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobitrain/formation-models');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobitrain/formation-models`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('formation.model.update')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            {(state.loadingFormationModel || state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <FormationModelFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <FormationModelFormPage2 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default UpdateFormationModel;
