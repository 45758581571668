import React from 'react';

// Material-UI
import { Search as SearchIcon } from '@material-ui/icons';

const styles = {
    input: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        fontSize: 14,
        minWidth: 220,
    },
    button: {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderLeftWidth: 0,
        cursor: 'pointer'
    },
};

// ------------------------------------ INPUT SEARCH ------------------------------------ \\
const InputSearch = ({ placeholder, value, onChange, onKeyPress, onSearch }) => {
    return (
        <div style={{ display: 'flex' }}>
            <input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onKeyPress={e => e.key === 'Enter' ? onKeyPress() : null}
                type="text"
                className="form-control"
                style={styles.input}
            />
            <button
                onClick={onSearch}
                style={styles.button}
            >
                <SearchIcon />
            </button>
        </div>
    );
};

export default InputSearch;
