import React from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Material-UI
import { Button } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Tools to Create PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Services
import { softskillsColor } from 'services/helpers';
import { getStoredUser } from 'services/storage';

// Assets
import SoftskillsLogo from 'assets/images/soft-skill-logo.jpg';
import ErasmusLogo from 'assets/images/erasmuspluslow.png';

// ----------------------------------------- CERTIFICATE ------------------------------------------- \\
const SoftFormationCertificate = ({ soft_formation, level, learner }) => {
    const { t } = useTranslation();
    const companyFooter = getStoredUser()?.company?.footer;

    const downloadCertificate = () => {

        // Create new PDF
        const doc = new jsPDF('p', 'mm', [297, 210]);

        // Logos Softskills & Erasmus
        doc.addImage(SoftskillsLogo, 16, 4, 36, 20);
        doc.addImage(ErasmusLogo, (210 - 16 - 48), 12, 48, 10);

        // Title
        doc.setFontSize(20);
        doc.text(t('training.certificate').toUpperCase(), 105, 40, { align: 'center' });
        doc.line(16, 42, (210 - 16), 42);

        // Introduction
        let empty_space = ' . . . . . . . . . . . . . . . . . . . . . . ';
        doc.setFontSize(11);
        doc.text(16, 60, t('soft.certificate.undersigned') + empty_space + t('soft.certificate.at') + empty_space + t('soft.certificate.hereby'));

        // Learners
        doc.setFont('Helvetica', 'bold');
        let height = 70;
        doc.text(learner.full_name, 105, height, { align: 'center' });

        // Registered by company
        height += 6;
        let company_name = soft_formation.company != null ? soft_formation.company.name : '';
        doc.setFont('Helvetica', 'normal');
        doc.text(t('soft.certificate.registered') + company_name, 105, height, { align: 'center' });

        // Participated
        height += 12;
        let softskill_name = soft_formation.softskill != null ? soft_formation.softskill.name : '';
        let participated = t('soft.certificate.participated') + softskill_name + t('soft.certificate.level.' + level);
        let participatedContainer = doc.splitTextToSize(participated, 178);
        doc.text(participatedContainer, 16, height);

        // Took place & Period
        height += 18;
        let period = t('soft.certificate.took.place');

        if (soft_formation.dates.includes('-')) {
            let dates = soft_formation.dates.split('-');
            let start = dates[0].trim();
            let end = dates[dates.length - 1].trim();
            period += t('soft.certificate.took.place.between') + start + t('soft.certificate.took.place.and') + end;
        }
        else {
            period += t('soft.certificate.took.place.at') + soft_formation.dates;
        }

        period += t('soft.certificate.period') + (soft_formation.duration || '') + '.';
        let periodContainer = doc.splitTextToSize(period, 178);
        doc.text(periodContainer, 16, height);

        // Goals
        height += 12;
        doc.text(t('soft.certificate.goals'), 16, height);

        switch (level) {
            // Level 1
            case 1: {
                // Goal 1
                height += 12;
                let goal1 = t('soft.certificate.goals.1.level.1');
                let goal1Container = doc.splitTextToSize(goal1, 178);
                doc.text(goal1Container, 20, height);

                // Goal 2
                height += 6;
                let goal2 = t('soft.certificate.goals.2.level.1');
                let goal2container = doc.splitTextToSize(goal2, 178);
                doc.text(goal2container, 20, height);
                break;
            }
            
            // Level 2
            case 2: {
                // Goal 1
                height += 12;
                let goal1 = t('soft.certificate.goals.1.level.2');
                let goal1Container = doc.splitTextToSize(goal1, 178);
                doc.text(goal1Container, 20, height);

                // Goal 2
                height += 12;
                let goal2 = t('soft.certificate.goals.2.level.2');
                let goal2container = doc.splitTextToSize(goal2, 178);
                doc.text(goal2container, 20, height);
                break;
            }

            // Level 3
            case 3: {
                // Goal 1
                height += 12;
                let goal1 = t('soft.certificate.goals.1.level.3');
                let goal1Container = doc.splitTextToSize(goal1, 178);
                doc.text(goal1Container, 20, height);

                // Goal 2
                height += 6;
                let goal2 = t('soft.certificate.goals.2.level.3');
                let goal2container = doc.splitTextToSize(goal2, 178);
                doc.text(goal2container, 20, height);

                // Goal 3
                height += 6;
                let goal3 = t('soft.certificate.goals.3.level.3');
                let goal3container = doc.splitTextToSize(goal3, 178);
                doc.text(goal3container, 20, height);
                break;
            }

            // Level 4
            case 4: {
                // Goal 1
                height += 12;
                let goal1 = t('soft.certificate.goals.1.level.4');
                let goal1Container = doc.splitTextToSize(goal1, 178);
                doc.text(goal1Container, 20, height);

                // Goal 2
                height += 6;
                let goal2 = t('soft.certificate.goals.2.level.4');
                let goal2container = doc.splitTextToSize(goal2, 178);
                doc.text(goal2container, 20, height);
                break;
            }

            default:
                break;
        }

        // Steps
        height += 18;
        doc.text(t('soft.certificate.steps'), 16, height);

        switch (level) {
            // Level 1
            case 1: {
                // Step 1
                height += 12;
                doc.text(t('soft.certificate.steps.1.level.1'), 20, height);

                // Step 2
                height += 6;
                doc.text(t('soft.certificate.steps.2.level.1'), 20, height);

                // Step 3
                height += 6;
                doc.text(t('soft.certificate.steps.3.level.1'), 20, height);

                // Step 4
                height += 6;
                doc.text(t('soft.certificate.steps.4.level.1'), 20, height);

                // Step 5
                height += 6;
                doc.text(t('soft.certificate.steps.5.level.1'), 20, height);
                break;
            }

            // Level 2
            case 2: {
                // Step 1
                height += 12;
                doc.text(t('soft.certificate.steps.1.level.2'), 20, height);

                // Step 2
                height += 6;
                doc.text(t('soft.certificate.steps.2.level.2'), 20, height);

                // Step 3
                height += 6;
                doc.text(t('soft.certificate.steps.3.level.2'), 20, height);

                // Step 4
                height += 6;
                doc.text(t('soft.certificate.steps.4.level.2'), 20, height);

                // Step 5
                height += 6;
                doc.text(t('soft.certificate.steps.5.level.2'), 20, height);

                // Step 6
                height += 6;
                doc.text(t('soft.certificate.steps.6.level.2'), 20, height);
                break;
            }

            // Level 3
            case 3: {
                // Step 1
                height += 12;
                doc.text(t('soft.certificate.steps.1.level.3'), 20, height);

                // Step 2
                height += 6;
                doc.text(t('soft.certificate.steps.2.level.3'), 20, height);

                // Step 3
                height += 6;
                doc.text(t('soft.certificate.steps.3.level.3'), 20, height);

                // Step 4
                height += 6;
                doc.text(t('soft.certificate.steps.4.level.3'), 20, height);

                // Step 5
                height += 6;
                doc.text(t('soft.certificate.steps.5.level.3'), 20, height);
                break;
            }

            // Level 4
            case 4: {
                // Step 1
                height += 12;
                doc.text(t('soft.certificate.steps.1.level.4'), 20, height);

                // Step 2
                height += 6;
                doc.text(t('soft.certificate.steps.2.level.4'), 20, height);

                // Step 3
                height += 6;
                doc.text(t('soft.certificate.steps.3.level.4'), 20, height);

                // Step 4
                height += 6;
                doc.text(t('soft.certificate.steps.4.level.4'), 20, height);

                // Step 5
                height += 6;
                doc.text(t('soft.certificate.steps.5.level.4'), 20, height);
                break;
            }

            default:
                break;
        }

        // Right
        height += 12;
        doc.text(t('soft.certificate.right') + '.', 16, height);

        // Carried Out
        height += 6;
        doc.text(t('soft.certificate.carried.out') + moment().format('DD/MM/YYYY') + '.', 16, height);

        // Signature
        height += 6;
        doc.text(t('signature'), 200, height, { align: 'right' });

        // Company Footer
        if (companyFooter) {
            doc.line(10, 277, 200, 277);
            doc.setFontSize(10);
            doc.setFont('helvetica', 'italic');
            var companyFooterContainer = doc.splitTextToSize(companyFooter, 190);
            doc.text(companyFooterContainer, 105, 284, { align: 'center' });
        }

        // Save the PDF
        doc.save(learner.full_name + ' - ' + t('training.certificate') + '.pdf');
    };

    return (
        <Button
            onClick={downloadCertificate}
            variant="contained"
            style={{ textTransform: 'none', padding: '4px 8px', backgroundColor: softskillsColor, color: 'white' }}
        >
            {t('training.certificate')}
            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
        </Button>
    );
};

export default SoftFormationCertificate;
