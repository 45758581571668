import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Accordion, AccordionSummary, Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd, ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getTranslation, servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateUnit from './create-unit';
import UpdateUnit from './update-unit';
import DeleteUnit from './delete-unit';
import Learnings from '../learnings';
import SoftUnits from '../softunits';

// Style
import style from 'style';

// ---------------------------------------- UNITS ---------------------------------------- \\
const Units = ({ skillbase }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        units: skillbase?.units || [],
        unit: null,
        expanded: null,
        openCreateUnit: false,
        openUpdateUnit: false,
        openDeleteUnit: false,
    });

    function handleOpenModal(event, open, unit) {
        event.stopPropagation();
        setState({ ...state, [open]: true, unit });
    }

    return (
        <>
            {/* ---------------------- LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: servicesColor, padding: '0px 48px 0px 10px' }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={8} style={style.p10}>
                            <span style={style.cW}>{t('unit')}</span>
                        </Grid>
                        <Grid item xs={4} style={{ padding: 10, textAlign: 'center' }}>
                            <span style={style.cW}>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span style={style.cW}>{t('units')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- UNIT LIST ---------------------- */}
            <div>
                {(state.units != null && state.units.length > 0) ? (
                    state.units.map(unit => (
                        <Accordion
                            key={unit.id}
                            expanded={state.expanded === unit.id}
                            onChange={() => setState({ ...state, expanded: unit.id === state.expanded ? null : unit.id })}
                        >
                            <AccordionSummary expandIcon={<IconExpand />} style={style.p0_10}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={8}>
                                        <span style={style.fwB}>
                                            {unit.position}. {getTranslation('name', unit)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                                        <IconButton
                                            onClick={e => handleOpenModal(e, 'openUpdateUnit', unit)}
                                            style={{ padding: 6 }}
                                        >
                                            <IconEdit />
                                        </IconButton>
                                        <IconButton
                                            onClick={e => handleOpenModal(e, 'openDeleteUnit', unit)}
                                            style={{ padding: 6 }}
                                        >
                                            <IconDelete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <div style={{ padding: 24 }}>
                                {(state.expanded === unit.id) && (
                                    <>
                                        <Learnings unit={unit} />
                                        <SoftUnits unit={unit} />
                                    </>
                                )}
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateUnit: true })}
                    className="fab"
                    style={{ backgroundColor: servicesColor, color: 'white' }}
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('unit.add')}
                </span>
            </div>
            {/* ---------------------- CREATE UNIT ---------------------- */}
            {state.openCreateUnit && (
                <CreateUnit
                    onClose={() => setState({ ...state, openCreateUnit: false })}
                    refetch={units => setState({ ...state, openCreateUnit: false, units })}
                    units={state.units}
                />
            )}
            {/* ---------------------- UPDATE UNIT ---------------------- */}
            {state.openUpdateUnit && (
                <UpdateUnit
                    onClose={() => setState({ ...state, openUpdateUnit: false })}
                    refetch={units => setState({ ...state, openUpdateUnit: false, units })}
                    units={state.units}
                    unit={state.unit}
                />
            )}
            {/* ---------------------- DELETE UNIT ---------------------- */}
            {state.openDeleteUnit && (
                <DeleteUnit
                    onClose={() => setState({ ...state, openDeleteUnit: false })}
                    refetch={units => setState({ ...state, openDeleteUnit: false, units })}
                    units={state.units}
                    unit={state.unit}
                />
            )}
        </>
    );
};

export default Units;
