import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

// Material-UI
import { Button, Divider, Grid, IconButton } from '@material-ui/core';
import {
    Search as IconSearch, Cancel as IconCancel, Create as IconCreate,
    Delete as IconDelete, Assignment as IconDetail, Loupe as IconMore,
    ThumbsUpDown as IconChangeEval
} from '@material-ui/icons';

// Services
import { getEvaluations } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { getTranslation, mobipassColor, getFormattedDate } from 'services/helpers';

// Components
import CreateEvaluation from './evaluation-create';
import UpdateEvaluation from './evaluation-update';
import RatingEvaluation from './evaluation-rating';
import { DeleteEvaluation, LearnersModal } from './evaluation-modals';
import DetailsEvaluation from './evaluation-details';
import ExportEvaluation from './evaluation-export';
import ExportIndividualEvaluation from './evaluation-export-individual';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';
import SelectSkillbase from 'components/select/select-skillbase';
import ChangeOrderFilter from 'components/items/change-order-filter';

// Style
import style from 'style';

// ---------------------------------------- ROUTER ----------------------------------------- \\
const EvaluationsRouter = () => {
    const { t } = useTranslation();

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to="/mobipass/evaluation" className="breadcrumb-item">
                {t('evaluation')}
            </BreadcrumbsItem>
            {/* -------------------- ROUTER -------------------- */}
            <Switch>
                <Route exact path="/mobipass/evaluation">
                    <Evaluations />
                </Route>
                <Route exact path="/mobipass/evaluation/create">
                    <CreateEvaluation />
                </Route>
                <Route exact path="/mobipass/evaluation/update/:evaluationId">
                    <UpdateEvaluation />
                </Route>
                <Route exact path="/mobipass/evaluation/:evaluationId">
                    <RatingEvaluation />
                </Route>
                <Redirect from="*" to="/mobipass/evaluation" />
            </Switch>
        </>
    );
};

// -------------------------------------- EVALUATIONS --------------------------------------- \\
const Evaluations = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('evaluations-filters'));
    const ROLE_ID = getStoredUser()?.role?.id;

    const [state, setState] = useState({
        loading: true,
        error: false,
        certifications: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: storedFilters?.name || '',
        company_id: storedFilters?.company_id || null,
        skillbase_id: storedFilters?.skillbase_id || null,
        assessor: storedFilters?.assessor || '',
        learner: storedFilters?.learner || '',
        param: storedFilters?.param || null,
        order: storedFilters?.order || 'desc',
        openCreateEvaluation: false,
        openExportIndividual: false,
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('evaluations-filters');
        setState({
            ...state,
            loading: true,
            offset: 0,
            name: '',
            company_id: null,
            skillbase_id: null,
            assessor: '',
            learner: '',
            param: null,
            order: 'desc',
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('evaluations-filters', JSON.stringify({
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
                assessor: state.assessor,
                learner: state.learner,
                param: state.param,
                order: state.order,
            }));

            getEvaluations({
                offset: state.offset,
                limit: state.limit,
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
                assessor: state.assessor,
                learner: state.learner,
                param: state.param,
                order: state.order,
            }).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        evaluations: res.data.data.evaluations,
                    }));
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push(`/mobipass/evaluation/create`)}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => setState({ ...state, openExportIndividual: true })}
                            variant="contained"
                        >
                            {t('export.individual')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container alignItems="center" style={{ backgroundColor: mobipassColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('evaluation')}</span>
                                <span style={style.cW}>&nbsp;-&nbsp;</span>
                                <ChangeOrderFilter
                                    state={state}
                                    setState={setState}
                                    param='evaluation_date' 
                                    label={t('date')}   
                                />
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('assessor')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('evaluation')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('evaluation') + ' - ' + t('date')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('assessor')}
                                value={state.assessor}
                                onChange={e => setState({ ...state, assessor: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner}
                                onChange={e => setState({ ...state, learner: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportEvaluation data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- EVALUATION LIST -------------------- */}
                <EvaluationList
                    ROLE_ID={ROLE_ID}
                    evaluations={state.evaluations}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
            {/* -------------------- CREATE EVALUATION -------------------- */}
            {state.openCreateEvaluation && (
                <CreateEvaluation
                    open={state.openCreateEvaluation}
                    onClose={() => setState({ ...state, openCreateEvaluation: false })}
                />
            )}
            {/* -------------------- EXPORT INDIVIDUAL EVALUATION -------------------- */}
            {state.openExportIndividual && (
                <ExportIndividualEvaluation
                    open={state.openExportIndividual}
                    onClose={() => setState({ ...state, openExportIndividual: false })}
                />
            )}
        </>
    );
};

// ------------------------------------ EVALUATION LIST ------------------------------------- \\
const EvaluationList = ({ ROLE_ID, evaluations, loading, error, refetch }) => {
    const history = useHistory();
    const USER_ID = getStoredUser()?.id;
    const { t } = useTranslation();

    const [state, setState] = useState({
        evaluation: null,
        anchorEl: null,
        openUpdateEvaluation: false,
        openDeleteEvaluation: false,
        openDetailsEvaluation: false,
        openLearnersModal: false,
    });

    const onRefetch = () => {
        setState({ ...state, openDeleteEvaluation: false });
        refetch();
    };

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {evaluations.length > 0 ? (
                    evaluations.map(evaluation => (
                        <Fragment key={evaluation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{evaluation.name}</span>
                                    <br />
                                    <span>{getFormattedDate(evaluation.evaluation_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{evaluation.company.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getTranslation('name', evaluation.skillbase)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{evaluation.assessor.full_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {evaluation.users.length === 1 ? (
                                        <span>{evaluation.users[0].full_name}</span>
                                    ) : (
                                        <>
                                            <span>{evaluation.users.length}</span>
                                            <Tooltip title={t('learners.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openLearnersModal: true, evaluation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDetailsEvaluation: true, evaluation })}
                                                className="list-btn"
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        }
                                    />
                                    {(ROLE_ID <= 2 || USER_ID === evaluation.assessor?.id) && (
                                        <>
                                            <Tooltip title={t('settings.edit')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push(`/mobipass/evaluation/update/${evaluation.id}`)}
                                                        className="list-btn"
                                                    >
                                                        <IconCreate />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('results.edit')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push(`/mobipass/evaluation/${evaluation.id}`)}
                                                        className="list-btn"
                                                    >
                                                        <IconChangeEval />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('delete')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openDeleteEvaluation: true, evaluation })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </> 
                )}
            </div>
            {/* -------------------- DELETE EVALUATION -------------------- */}
            {state.openDeleteEvaluation && (
                <DeleteEvaluation
                    onClose={() => setState({ ...state, openDeleteEvaluation: false })}
                    refetch={() => onRefetch()}
                    evaluation={state.evaluation}
                />
            )}
            {/* -------------------- DETAILS EVALUATION -------------------- */}
            {state.openDetailsEvaluation && (
                <DetailsEvaluation
                    onClose={() => setState({ ...state, openDetailsEvaluation: false })}
                    evaluation={state.evaluation}
                />
            )}
            {/* -------------------- LEARNERS MODAL -------------------- */}
            {state.openLearnersModal && (
                <LearnersModal
                    onClose={() => setState({ ...state, openLearnersModal: false })}
                    evaluation={state.evaluation}
                />
            )}
        </>
    );
};

export default EvaluationsRouter;
