import React, { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Button, Divider, Accordion, AccordionSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, AddCircleOutline as IconAddPosition } from '@material-ui/icons';

// Serives
import { getSkillbase, getLastPositionForUser } from 'services/requests';
import { getTranslation, getFormattedDate, mobipassColor, mobipassColor2 } from 'services/helpers';

// Components
import Select from 'components/items/react-select';
import Badge from 'components/items/badge';
import FieldRequired from 'components/items/field-required';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

const styles = {
    td: {
        padding: 10,
        border: '1px solid lightgray',
    },
};

// ----------------------------------- HARD POSITION FORM -------------------------------------- \\
const HardPositionsForm = ({ state, setState }) => {
    const { t } = useTranslation();

    const resetState = () => {
        setState({
            ...state,
            loadingSkillbase: false,
            skillbase: null,
            unit: null,
            competence_ids: [],
            knowledge_ids: [],
            skill_ids: [],
            last_hard_position: null,
            last_competence_ids: [],
            last_knowledge_ids: [],
            last_skill_ids: [],
        });
    };

    useEffect(() => {
        if (state.skillbase_id) {
            axios.all([getSkillbase(state.skillbase_id), getLastPositionForUser(state.learner_id, state.skillbase_id, state.hard_position_id)])
            .then(axios.spread(function (res_skillbase, res_last_position) {
                if (res_skillbase?.status === 200 && res_last_position?.status === 200) {

                    let last_hard_position = res_last_position.data.data.last_hard_position;
                    let last_competence_ids = [], last_knowledge_ids = [], last_skill_ids = [];

                    if (last_hard_position != null) {
                        last_hard_position.competences.forEach(competence => {
                            last_competence_ids.push({ id: competence.id, mastery: competence.mastery }); 
                        });
                        last_hard_position.knowledges.forEach(knowledge => {
                            last_knowledge_ids.push({ id: knowledge.id, mastery: knowledge.mastery }); 
                        })
                        last_hard_position.skills.forEach(skill => {
                            last_skill_ids.push({ id: skill.id, mastery: skill.mastery }); 
                        });
                    }

                    if (state.actionType === 'CREATE') {
                        setState({
                            ...state,
                            loadingSkillbase: false,
                            skillbase: res_skillbase.data.data.skillbase,
                            unit: null,
                            competence_ids: [],
                            knowledge_ids: [],
                            skill_ids: [],
                            last_hard_position,
                            last_competence_ids,
                            last_knowledge_ids,
                            last_skill_ids,
                        });
                    }
                    if (state.actionType === 'UPDATE' || state.actionType === 'DUPLICATE') {
                        setState({
                            ...state,
                            loadingSkillbase: false,
                            skillbase: res_skillbase.data.data.skillbase,
                            last_hard_position,
                            last_competence_ids,
                            last_knowledge_ids,
                            last_skill_ids,
                        });
                    }
                }
                else {
                    console.log(res_skillbase + res_last_position);
                    resetState();
                }
            }));
        }
        else {
            resetState();
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.skillbase_id]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6}>
                    {/* -------------------- SKILLBASE -------------------- */}
                    <div>
                        {state.actionType === 'CREATE' ? (
                            <>
                                <b className="input-title">{t('select.skillbase')}</b><FieldRequired />
                                <Select
                                    placeholder={t('skillbase')}
                                    value={state.skillbase_id ? state.skillbases.find(s => s.value === state.skillbase_id) : null}
                                    onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null, loadingSkillbase: skillbase ? true : false })}
                                    options={state.skillbases}
                                />
                            </>
                        ) : (
                            <>
                                <b className="input-title">{t('skillbase')}</b>
                                {state.skillbase && (
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: 10, borderRadius: 4, width: 'fit-content' }}>
                                        <span>{getTranslation('name', state.skillbase)}</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
            {/* -------------------- LOADER -------------------- */}
            {state.loadingSkillbase && (
                <Spinner />
            )}
            {/* -------------------- UNITS -------------------- */}
            {(state.skillbase != null && state.unit == null) && (
                <>
                    <div style={{ marginTop: 24 }}>
                        <b className="input-title">{t('select.unit')}</b><FieldRequired />
                    </div>
                    <UnitList 
                        state={state}
                        setState={setState}
                    />
                </>
            )}
            {/* -------------------- LEARNINGS -------------------- */}
            {(state.skillbase != null && state.unit != null) && (
                <>
                    {/* -------------------- UNIT -------------------- */}
                    <div style={{ marginTop: 24 }}>
                        <b className="input-title">{t('select.unit')}</b><FieldRequired />
                    </div>
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: 10, borderRadius: 4, width: 'fit-content' }}>
                        <span>{state.unit.position}. {getTranslation('name', state.unit)}</span>
                    </div>
                    {/* -------------------- LEARNINGS -------------------- */}
                    <div style={{ marginTop: 24 }}>
                        <b className="input-title">{t('select.mastery.level')}</b><FieldRequired />
                    </div>
                    <div style={{ padding: '4px 0px 10px 0px' }}>
                        <Button
                            onClick={() => setState({ ...state, unit: null })}
                            variant="outlined" color="primary"
                        >
                            {t('units.return')}
                        </Button>
                    </div>
                    <LearningList
                        state={state}
                        setState={setState}
                    />
                </>
            )}
        </>
    );
};

// --------------------------------------- UNIT LIST ------------------------------------------- \\
const UnitList = ({ state, setState }) => {
    const { t } = useTranslation();

    const getDescriptorNumber = unit_id => {
        let number = 0;
        number += state.competence_ids.filter(c => c.unit_id === unit_id).length;
        number += state.knowledge_ids.filter(k => k.unit_id === unit_id).length;
        number += state.skill_ids.filter(s => s.unit_id === unit_id).length;
        return number;
    };

    return (
        <>
            <Grid style={{ backgroundColor: mobipassColor, color: 'white', padding: '12px 24px' }}>
                <span>
                    {t('units.of.learning.outcomes')}
                </span>
            </Grid>
            <div>
                {(state.skillbase.units != null && state.skillbase.units.length > 0) ? (
                    state.skillbase.units.map(unit => (
                        <Fragment key={unit.id}>
                            <Grid
                                onClick={() => setState({ ...state, unit })}
                                container alignItems="center" justifyContent="space-between"
                                className="list-item-hover"
                                style={{ padding: '12px 24px' }}
                            >
                                <span>
                                    {unit.position}. {getTranslation('name', unit)}
                                </span>
                                <Badge
                                    variant="primary"
                                    number={getDescriptorNumber(unit.id)}
                                />
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
        </>
    );
};

// ------------------------------------- LEARNING LIST ----------------------------------------- \\
const LearningList = ({ state, setState }) => {
    const { t } = useTranslation();

    const getDescriptorNumber = learning_id => {
        let number = 0;
        number += state.competence_ids.filter(c => c.learning_id === learning_id).length;
        number += state.knowledge_ids.filter(k => k.learning_id === learning_id).length;
        number += state.skill_ids.filter(s => s.learning_id === learning_id).length;
        return number;
    };

    return (
        <>
            <Grid style={{ backgroundColor: mobipassColor, color: 'white', padding: '12px 24px' }}>
                <span>
                    {t('learning.outcomes')}
                </span>
            </Grid>
            <div>
                {(state.unit.learning_outcomes != null && state.unit.learning_outcomes.length > 0) ? (
                    state.unit.learning_outcomes.map(learning => (
                        <Accordion key={learning.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: mobipassColor2 }}
                            >
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {state.unit.position}.{learning.position} {getTranslation('name', learning)}
                                    </span>
                                    <Badge
                                        variant="primary"
                                        number={getDescriptorNumber(learning.id)}
                                    />
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <div style={{ maxHeight: 400, overflow: 'auto' }}>
                                <PositioningTable
                                    learning={learning}
                                    state={state}
                                    setState={setState}
                                />
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>  
                )}
            </div>
        </>
    );
};

// ----------------------------------- POSITIONING TABLE --------------------------------------- \\
const PositioningTable = ({ learning, state, setState }) => {
    const { t } = useTranslation();

    const onClickMastery = (descriptor_ids, descriptor_id, descriptor_type) => {
        let index = descriptor_ids.findIndex(d => d.id === descriptor_id);

        if (index !== -1) {
            let mastery = descriptor_ids.find(d => d.id === descriptor_id)?.mastery;
            mastery += 1;

            if (mastery > 2) {
                descriptor_ids.splice(index, 1);
            }
            else {
                descriptor_ids[index].mastery = mastery;
            }
        }
        else {
            descriptor_ids.push({ unit_id: state.unit.id, learning_id: learning.id, id: descriptor_id, mastery: 0 });
        }

        setState({ ...state, [descriptor_type]: descriptor_ids });
    };
 
    const getMastery = (descriptor_ids, descriptor_id, last_position) => {
        let mastery = descriptor_ids.find(d => d.id === descriptor_id)?.mastery;

        switch (mastery) {
            case 0:
                return <MasteryPosition label={t('mastery.0')} color="#B60909" />;

            case 1:
                return <MasteryPosition label={t('mastery.1')} color="#E78200" />;

            case 2:
                return <MasteryPosition label={t('mastery.2')} color="#0EB813" />;

            default:
                return last_position ? '' : <AddPositionButton />;
        };
    };

    return (
        <table style={{ width: '100%', minWidth: 1000 }}>
            <thead>
                <tr>
                    {/* -------------------- DESCRIPTOR TITLE -------------------- */}
                    <th style={{ ...styles.td, width: state.last_hard_position != null ? '60%' : '80%', textAlign: 'center' }}>
                        <span style={style.ttU}>{t('descriptors')}</span>
                    </th>
                    {/* -------------------- LAST POSITION -------------------- */}
                    {state.last_hard_position != null && (
                        <th style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                            <span>{t('date')} : {getFormattedDate(state.last_hard_position?.position_date)}</span>
                            <br />
                            <span>{t('assessor')} : {state.last_hard_position.assessor?.full_name}</span>
                        </th>
                    )}
                    {/* -------------------- MASTERY TITLE -------------------- */}
                    <th style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                        <span style={style.ttU}>{t('mastery')}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {/* -------------------- COMPETENCES -------------------- */}
                {learning.competences.map(competence => (
                    <tr key={competence.id}>
                        <td style={{ ...styles.td, width: state.last_hard_position != null ? '60%' : '80%' }}>
                            <span>{competence.numerotation + t('competence.numerotation')} {getTranslation('name', competence)}</span>
                        </td>
                        {(state.last_hard_position != null) && (
                            <td style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                                {getMastery(state.last_competence_ids, competence.id, true)}
                            </td>
                        )}
                        <td
                            onClick={() => onClickMastery(state.competence_ids, competence.id, 'competence_ids')}
                            className="list-item-hover"
                            style={{ ...styles.td, width: '20%', textAlign: 'center' }}
                        >
                            {getMastery(state.competence_ids, competence.id)}
                        </td>
                    </tr>
                ))}
                {/* -------------------- KNOWLEDGES -------------------- */}
                {learning.knowledges.map(knowledge => (
                    <tr key={knowledge.id}>
                        <td style={{ ...styles.td, width: state.last_hard_position != null ? '60%' : '80%' }}>
                            <span>{knowledge.numerotation + t('knowledge.numerotation')} {getTranslation('name', knowledge)}</span>
                        </td>
                        {(state.last_hard_position != null) && (
                            <td style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                                {getMastery(state.last_knowledge_ids, knowledge.id, true)}
                            </td>
                        )}
                        <td
                            onClick={() => onClickMastery(state.knowledge_ids, knowledge.id, 'knowledge_ids')}
                            className="list-item-hover"
                            style={{ ...styles.td, width: '20%', textAlign: 'center' }}
                        >
                            {getMastery(state.knowledge_ids, knowledge.id)}
                        </td>
                    </tr>
                ))}
                {/* -------------------- SKILLS -------------------- */}
                {learning.skills.map(skill => (
                    <tr key={skill.id}>
                        <td style={{ ...styles.td, width: state.last_hard_position != null ? '60%' : '80%' }}>
                            <span>{skill.numerotation + t('skill.numerotation')} {getTranslation('name', skill)}</span>
                        </td>
                        {(state.last_hard_position != null) && (
                            <td style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                                {getMastery(state.last_skill_ids, skill.id, true)}
                            </td>
                        )}
                        <td
                            onClick={() => onClickMastery(state.skill_ids, skill.id, 'skill_ids')}
                            className="list-item-hover"
                            style={{ ...styles.td, width: '20%', textAlign: 'center' }}
                        >
                            {getMastery(state.skill_ids, skill.id)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

// ---------------------------------- ADD POSITION BUTTON -------------------------------------- \\
const AddPositionButton = () => {
    const { t } = useTranslation();

    return (
        <Button variant='outlined' disabled endIcon={<IconAddPosition />}>
            <span style={style.fs14}>{t('add')}</span>
        </Button>
    );
};

// ------------------------------------ MASTERY POSITION --------------------------------------- \\
const MasteryPosition = ({ label, color }) => {
    return (
        <div style={{ minHeight: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ textTransform: 'uppercase', color: color }}>{label}</span>
        </div>
    );
};

export default HardPositionsForm;
