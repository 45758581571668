import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { SaveAlt as IconSave } from '@material-ui/icons';

// Services
import { getSkillbase } from 'services/requests';

// Components
import GeneratePDF from './generate-pdf';
import Spinner from 'components/items/spinner';
import SelectSkillbase from 'components/select/select-skillbase';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// --------------------------------- SKILLBASE WITH EMPTY PLACEMENTS EXPORT -------------------------------------- \\
const SkillbaseWithEmptyPlacementsExport = ({ onClose }) => {
    const { t } = useTranslation();
    const {enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        skillbaseId: null,
    });

    const onGenerate = () => {
        if (state.skillbaseId == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            getSkillbase(state.skillbaseId).then(res => {
                if (res?.status === 200) {
                    GeneratePDF(res.data.data.skillbase, t);
                    onClose();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <Dialog open fullWidth>
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={style.pb20}>
                            <p className="dialog-title">
                                {t('export.empty.skillbase.with.position')}
                            </p>
                            {/* ---------------------- SKILLBASE ---------------------- */}
                            <span>{t('skillbase')}</span><FieldRequired />
                            <SelectSkillbase
                                skillbaseId={state.skillbaseId}
                                setSkillbaseId={skillbaseId => setState({ ...state, skillbaseId })}
                            />
                        </DialogContent>
                        <Divider />
                        <DialogActions style={style.jcSB}>
                            <Button onClick={onClose} variant="contained">
                                {t('cancel')}
                            </Button>
                            <Button onClick={onGenerate} variant="contained" color="primary">
                                PDF
                                <IconSave style={{ marginLeft: 5, fontSize: 16 }} />
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default SkillbaseWithEmptyPlacementsExport;
