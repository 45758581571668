import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Divider, Accordion, AccordionSummary, IconButton } from '@material-ui/core';
import { Create as IconUpdate, Delete as IconDelete } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';

// Components
import SoftSkillList from './softskill-list';
import { UpdateSoftCategory, DeleteSoftCategory } from './softcategory-modals';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ------------------------------------- SOFTCATEGORY LIST ------------------------------------- \\
const SoftCategoryList = ({ loading, error, soft_categories, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        category: undefined,
        openUpdateSoftCategory: false,
        openDeleteSoftCategory: false,
    });

    function handleOpen(event, open, category) {
        event.stopPropagation();
        setState({ ...state, [open]: true, category });
    }

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div>
                {soft_categories.length > 0 ? (
                    soft_categories.map(category => (
                        <Accordion key={category.id}>
                            <AccordionSummary>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={10}>
                                        <span style={{ ...style.fwB, ...style.ttU }}>
                                            {category.position}. {getTranslation('name', category)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} md={2} container justifyContent="center">
                                        <Tooltip title={t('edit')}
                                            item={(
                                                <IconButton
                                                    onClick={e => handleOpen(e, 'openUpdateSoftCategory', category)}
                                                    style={style.p6}
                                                >
                                                    <IconUpdate />
                                                </IconButton>
                                            )}
                                        />
                                        <Tooltip title={t('delete')}
                                            item={(
                                                <IconButton
                                                    onClick={e => handleOpen(e, 'openDeleteSoftCategory', category)}
                                                    style={{ ...style.p6, ...style.ml5 }}
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <SoftSkillList category={category} refetch={refetch} />
                        </Accordion>
                    ))
                ) : (
                    <>
                        <div style={style.p10}>
                            <span>{t('none')}</span>
                        </div>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- UPDATE SOFT CATEGORY -------------------- */}
            {state.openUpdateSoftCategory && (
                <UpdateSoftCategory
                    onClose={() => setState({ ...state, openUpdateSoftCategory: false })}
                    refetch={() => handleRefetch('openUpdateSoftCategory')}
                    category={state.category}
                />
            )}
            {/* -------------------- DELETE SOFT CATEGORY -------------------- */}
            {state.openDeleteSoftCategory && (
                <DeleteSoftCategory
                    onClose={() => setState({ ...state, openDeleteSoftCategory: false })}
                    refetch={() => handleRefetch('openDeleteSoftCategory')}
                    category={state.category}
                />
            )}
        </>
    );
};

export default SoftCategoryList;
