import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Services
import { initialLanguagesObject, getTranslation, servicesColor } from 'services/helpers';
import { languages } from 'services/constants';
import { createSoftCategory, updateSoftCategory, deleteSoftCategory } from 'services/requests';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Components
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ------------------------------------- CREATE SOFT CATEGORY ------------------------------------- \\
export const CreateSoftCategory = ({ onClose, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
    });
    
    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleCreateSoftCategory() {
        if (Object.values(state.names).every(name => name === "")) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createSoftCategory({ name: state.names }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('softcategory.add')}
                        </p>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleCreateSoftCategory}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------- UPDATE SOFT CATEGORY ------------------------------------- \\
export const UpdateSoftCategory = ({ onClose, refetch, category }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
        position: category.position,
    });

    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleUpdateSoftCategory() {
        if (Object.values(state.names).every(name => name === "")) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (category) {
            let names = state.names;
            Object.entries(category.translations).map(([key, value]) => {
                return names[key] = value.name;
            });
            setState({ ...state, names });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    useEffect(() => {
        if (state.loading) {
            updateSoftCategory({ name: state.names, position: state.position }, category.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('softcategory.edit')}
                        </p>
                        <InputTextGrid
                            placeholder={t('position')}
                            value={state.position}
                            onChange={e => setState({ ...state, position: e.target.value })}
                            type="number"
                        />
                        <Divider style={style.m15_0} />
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleUpdateSoftCategory}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------- DELETE SOFT CATEGORY ------------------------------------- \\
export const DeleteSoftCategory = ({ onClose, refetch, category }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteSoftCategory(category.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.pb20, ...style.taC }}>
                        <p className="dialog-title">
                            {getTranslation('name', category)}
                        </p>
                        <span>{t('softcategory.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
