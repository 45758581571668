import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { deleteSpecificBehavior } from 'services/requests';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// -------------------------------------- DELETE SPECIFIC BEHAVIOR ------------------------------------- \\
const DeleteSpecificBehavior = ({ onClose, refetch, specific_behavior }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteSpecificBehavior(specific_behavior.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(specific_behavior.id);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open fullWidth>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.pb20, ...style.taC }}>
                        <p className="dialog-title">
                            {t('specific.behavior.delete')}
                        </p>
                        <span>
                            {t('specific.behavior.delete.confirm')}
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default DeleteSpecificBehavior;
