import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getTranslation } from 'services/helpers';
import { getCompanyList, getCompanyMembers, getSkillbases, updateEvaluation, getEvaluation, getSkillbase } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import EvaluationFormPage1 from './evaluation-form/evaluation-form-page-1';
import EvaluationFormPage2 from './evaluation-form/evaluation-form-page-2';
import EvaluationFormPage3 from './evaluation-form/evaluation-form-page-3';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// --------------------------------------- UPDATE EVALUATION ---------------------------------------- \\
const UpdateEvaluation = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { evaluationId } = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        page: 1,
        loadingEvaluation: true,
        loadingResources: false,
        errorResources: false,
        loading: false,
        evaluation: null,

        // Page 1
        name: '',
        evaluation_date: null,
        details: '',

        // Page 2
        authorizedRefetchMembers: false,
        companies: [],
        company_id: null,
        assessorOptions: [],
        assessor_id: null,
        userOptions: [],
        users: null,

        // Page 3
        authorizedRefetchSkillbase: false,
        skillbases: [],
        skillbase_id: null,
        skillbase: null,
        learning_outcomes: {},
        soft_units: {},
        expanded: null,
    });

    useEffect(() => {
        getEvaluation(evaluationId).then(res => {
            if (res?.status === 200) {
                const evaluation = res.data.data.evaluation;
                setState({
                    ...state,
                    loadingEvaluation: false,
                    loadingResources: true,
                    evaluation,
                    name: evaluation.name,
                    evaluation_date: evaluation.evaluation_date ? moment(evaluation.evaluation_date, 'YYYY-MM-DD') : null,
                    details: evaluation.details,
                    company_id: evaluation.assessor?.company?.id || null,
                    company_name: evaluation.assessor?.company?.name || null,
                    assessor_id: evaluation.assessor?.id,
                    users: evaluation?.users.map(u => ({ value: u.id, label: u.full_name })),
                    skillbase_id: evaluation?.skillbase?.id,
                    skillbase_name: getTranslation('name', evaluation.skillbase),
                });
            }
            else {
                setState({ ...state, loadingEvaluation: false, errorResources: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Get Resources
    useEffect(() => {
        if (state.loadingResources) {
            axios.all([
                getCompanyList(),
                getSkillbases(),
                getCompanyMembers(state.company_id),
                getSkillbase(state.skillbase_id),
            ])
            .then(axios.spread(function (res_companies, res_skillbases, res_members, res_skillbase) {
                if (res_companies?.status === 200 && res_skillbases?.status === 200 && res_members?.status === 200 && res_skillbase?.status === 200) {

                    const skillbase = res_skillbase.data.data.skillbase;

                    let learning_outcomes = {}, soft_units = {};
                    skillbase.units.forEach(unit => {
                        learning_outcomes[unit.id] = [];
                        soft_units[unit.id] = [];
                    });

                    state.evaluation.learning_outcomes.forEach(lo => {
                        let learning_from_unit_to_push = learning_outcomes[lo.unit_id];
                        learning_from_unit_to_push.push(lo.id);
                    });

                    state.evaluation.soft_units.forEach(su => {
                        let soft_unit_from_unit_to_push = soft_units[su.unit_id];
                        soft_unit_from_unit_to_push.push(su.id);
                    });

                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        assessorOptions: res_members.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                        userOptions: res_members.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                        skillbase,
                        learning_outcomes,
                        soft_units,
                    });
                }
                else {
                    console.log(res_companies + res_skillbases + res_members + res_skillbase);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    // Handle Save Evaluation
    useEffect(() => {
        if (state.loading) {

            let learning_outcomes = [];
            Object.values(state.learning_outcomes).forEach(value => {
                learning_outcomes = learning_outcomes.concat(value)
            });

            let soft_unit_ids = [];
            Object.values(state.soft_units).forEach(value => {
                soft_unit_ids = soft_unit_ids.concat(value)
            });

            updateEvaluation(evaluationId, {
                name: state.name,
                evaluation_date: moment(state.evaluation_date).format('YYYY-MM-DD'),
                details: state.details,
                company_id: state.company_id,
                assessor_id: state.assessor_id,
                users: state.users.map(u => u.value),
                skillbase_id: state.skillbase_id,
                learning_outcomes: learning_outcomes,
                soft_unit_ids: soft_unit_ids,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/evaluation/${evaluationId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/evaluation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('evaluation.edit')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            {/* -------------------- CONTAINER -------------------- */}
            {(state.loadingEvaluation || state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <EvaluationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <EvaluationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <EvaluationFormPage3 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default UpdateEvaluation;
