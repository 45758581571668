import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Accordion, AccordionSummary, Divider, Button } from '@material-ui/core';

// Services
import { getSoftCategories } from 'services/requests';
import { softskillsColor, getTranslation } from 'services/helpers';

// Components
import SoftSkillDetails from './softskill-details';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ----------------------------------------- SOFT SKILLS ROUTER ---------------------------------------- \\
const SoftSkillsRouter = () => {
    const match = useRouteMatch();
    const { t } = useTranslation();

    return (
        <>
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('skillbase')}
            </BreadcrumbsItem>
            <Switch>
                <Route exact path={`${match.path}`}>
                    <SoftSkills />
                </Route>
                <Route exact path={`${match.path}/:softskillId`}>
                    <SoftSkillDetails />
                </Route>
            </Switch>
        </>
    );
};

// -------------------------------------------- SOFT SKILLS -------------------------------------------- \\
const SoftSkills = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        error: false,
        soft_categories: null,
        openAssessmentGrids: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSoftCategories().then(res => {
                if (res?.status === 200) {
                    setState(state => ({ ...state, loading: false, soft_categories: res.data.data.soft_categories }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid className="toolbar">
                <Button
                    onClick={() => history.push('/mobipass/softskills')}
                    variant="contained"
                >
                    {t('previous')}
                </Button>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                <Grid style={{ ...style.p10_24, backgroundColor: softskillsColor }}>
                    <span style={style.cW}>{t('softcategories')}</span>
                </Grid>
                <div>
                    {state.soft_categories.length > 0 ? (
                        state.soft_categories.map(category => (
                            <Accordion key={category.id}>
                                <AccordionSummary>
                                    <span style={style.fwB}>
                                        {category.position}. {getTranslation('name', category)}
                                    </span>
                                </AccordionSummary>
                                <Divider />
                                <SoftSkillList category={category} />
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <div style={style.p10}>
                                <span>{t('none')}</span>
                            </div>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

// ------------------------------------------ SOFT SKILL LIST ------------------------------------------ \\
const SoftSkillList = ({ category }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <>
            {(category.softskills && category.softskills.length > 0) ? (
                category.softskills.map(softskill => (
                    <Fragment key={softskill.id}>
                        <Grid
                            onClick={() => history.push(`${match.path}/${softskill.id}`)}
                            container
                            alignItems="center"
                            className="list-item-hover"
                            style={{ padding: 12 }}
                        >
                            <span>{category.position}.{softskill.position}  {getTranslation('name', softskill)}</span>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <div style={{ padding: 12 }}>
                        <span>{t('none')}</span>
                    </div>
                    <Divider />
                </>
            )}
        </>
    );
};

export default SoftSkillsRouter;
