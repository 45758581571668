import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';

// Services
import { deleteClient, getCompanyList, getLinkedCompaniesFromClient, associateCompaniesToClient } from 'services/requests';
import { servicesColor } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import SelectMultiple from 'components/items/select-multiple';

// Style
import style from 'style';

// ------------------------------ ASSOCIATE COMPANIES TO CLIENT ------------------------------- \\
export const AssociateCompaniesToClient = ({ onClose, refetch, client }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        error: false,
        companies: null,
        loadingAssociateCompanies: false,
    });

    function handleAssociateCompaniesToClient() {
        if (state.companies && state.companies.length > 0) {
            setState({ ...state, loadingAssociateCompanies: true });
        }
        else {
            enqueueSnackbar(t('company.select'), { variant: 'warning' });
        }
    }

    useEffect(() => {
        if (state.loadingAssociateCompanies) {
            associateCompaniesToClient(
                client.id,
                { company_ids: state.companies.map(c => c.value) }
            ).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingAssociateCompanies: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAssociateCompanies]);

    useEffect(() => {
        getLinkedCompaniesFromClient(client.id).then(res => {
            if (res?.status === 200) {
                setState(state => ({
                    ...state,
                    loading: false,
                    companies: res.data.data.companies.map(c => ({ label: c.name, value: c.id })),
                }));
            }
            else {
                console.log(res);
                setState(state => ({ ...state, loading: false, error: true }));
            }
        });
    }, [client]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {client.name}
                        </p>
                        <b className="input-title">{t('companies.linked.to.client')}</b>
                        <SelectCompanies
                            companies={state.companies}
                            setCompanies={companies => setState({ ...state, companies })}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleAssociateCompaniesToClient}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------- SELECT COMPANIES ------------------------------------- \\
const SelectCompanies = ({ companies, setCompanies }) => {
    const { t } = useTranslation(); 

    const [state, setState] = useState({
        loading: true,
        data: undefined,
        error: undefined,
    });

    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setState(state => ({
                    ...state,
                    loading: false,
                    data: res.data.data.companies.map(c => ({ label: c.name, value: c.id })),
                }));
            }
            else {
                console.log(res);
                setState(state => ({ ...state, loading: false, error: res }));
            }
        });
    }, []);

    if (state.loading) return <span>{t('loading')}</span>;
    if (state.error) return <span>{t('error.occured')}</span>;

    return (
        <SelectMultiple
            value={companies}
            onChange={value => setCompanies(value)}
            options={state.data}
        />
    );
};

// -------------------------------------- DELETE CLIENT --------------------------------------- \\
export const DeleteClient = ({ onClose, refetch, client }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteClient(client.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {client.name}
                        </p>
                        <span>{t('client.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
