// Import Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Import Moment
import moment from 'moment';

// Import Services
import { getFormattedDate, getTimeDifference, getTranslation } from '../../../../services/helpers';

// ------------------------------------ EXPORT EXCEL -------------------------------------- \\
const ExportXLSX = (formations, user, company, start_date, end_date, t) => {

    // Create Excel File
    let workbook = new ExcelJS.Workbook();

    // Create new worksheet with setup for A4 in landscape orientation
    let worksheet = workbook.addWorksheet(
        (t('training')), {
        pageSetup: { orientation: 'landscape' },
    });

    // adjust pageSetup settings
    worksheet.pageSetup.margins = {
        left: 0.3, right: 0.3,
        top: 0.5, bottom: 0.5,
        header: 0.5, footer: 0.5
    };

    // Header with informations on the session
    worksheet.mergeCells('A1', 'G1');
    worksheet.getCell('A1').value = t('company') + ' ' + company.label + '\r\n' + t('training.sessions.carried.out.for') + ' ' + user.label + ', ' + t('over.the.period') + ' ' + getFormattedDate(start_date) + ' ' + t('to.1') + ' ' + getFormattedDate(end_date);

    // Creating column headers
    worksheet.getRow(3).values = [
        t('name'),
        t('date'),
        t('company'),
        t('start'),
        t('end'),
        t('duration'),
        t('type'),
        t('place'),
        t('description'),
        t('trainer'),
        t('skillbase'),
        t('unit') + ' / ' + t('learning.outcomes') + ' -> ' + t('descriptors'),
        t('comment'),
    ];

    // Creating data structures for each column
    worksheet.columns = [
        { key: 'name', width: 25 },
        { key: 'date', width: 10 },
        { key: 'organism', width: 15 },
        { key: 'start', width: 10, style: { numFmt: 'HH:mm:ss'} },
        { key: 'end', width: 10, style: { numFmt: 'HH:mm:ss'} },
        { key: 'training_time', width: 10, style: { numFmt: 'HH:mm:ss'} },
        { key: 'type', width: 20 },
        { key: 'place', width: 10 },
        { key: 'description', width: 15 },
        { key: 'trainer', width: 20 },
        { key: 'skillbase', width: 25 },
        { key: 'descriptors', width: 70 },
        { key: 'comment', width: 20 },
    ];

    worksheet.getRow(1).font = { bold: true, color: { argb: 'FF000000' } };
    worksheet.getRow(2).font = { bold: true, color: { argb: 'FFbc2463' } };
    worksheet.getRow(3).font = { bold: true, color: { argb: 'FFFFFFFF' } };
    worksheet.getRow(3).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFe99300' } };

    formations.forEach(formation => {

        let trainers = '';
        formation.formation_trainers.forEach((ft, index) => {
            if (index === 0) {
                trainers += ft.trainer.full_name;
            }
            else {
                trainers += '\r\n' + ft.trainer.full_name;
            }
        });

        let descriptors_list = '';
        for (let i = 0; i < formation.descriptors.length; i += 1) {

            let unit = getTranslation('name', formation.descriptors[i].unit);
            let learning_outcome = getTranslation('name', formation.descriptors[i].learning_outcome);
            let descriptors = '';

            for (let j = 0; j < formation.descriptors[i].competences.length; j++) {
                descriptors += getTranslation('name', formation.descriptors[i].competences[j]) + '\r\n';
            }
            for (let j = 0; j < formation.descriptors[i].knowledges.length; j++) {
                descriptors += getTranslation('name', formation.descriptors[i].knowledges[j]) + '\r\n';
            }
            for (let j = 0; j < formation.descriptors[i].skills.length; j++) {
                descriptors += getTranslation('name', formation.descriptors[i].skills[j]) + '\r\n';
            }
            
            descriptors_list = unit + ' / ' + learning_outcome + ' -> \r\n' + descriptors;
        }

        let totalTrainingTime = getTimeDifference(formation.start, formation.end);
        worksheet.addRow({
            'name': formation.name,
            'date': getFormattedDate(formation.formation_date),
            'organism': company.label,
            'start': moment(formation.start, "HH:mm").format("HH:mm:ss"),
            'end': moment(formation.end, "HH:mm").format("HH:mm:ss"),
            'training_time': totalTrainingTime,
            'type': formation.type.name,
            'place': formation.place.name,
            'description': formation.program,
            'trainer': trainers,
            'skillbase': getTranslation('name', formation.skillbase),
            'descriptors': descriptors_list,
            'comment': formation.comment,
        });
    });

    let totalTime = 0;
    let hours = 0;
    let minutes = 0;

    for (let i = 0; i < formations.length; i++) {
        let start_time = formations[i].start;
        let end_time = formations[i].end;
        let formationTime = getTimeDifference(start_time, end_time);
        formationTime = formationTime.split(':');
        hours += parseInt(formationTime[0]);
        minutes += parseInt(formationTime[1]);
    }

    totalTime = (hours * 60) + minutes;
    hours = Math.floor(totalTime / 60);
    minutes = totalTime % 60;
    let totalTrainingTime = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;

    worksheet.getCell('A2').value = t('total.training.time');
    worksheet.getCell('B2').value = totalTrainingTime;
    workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), user.label + ' - ' + t('trainings') + '.xlsx'));
};

export default ExportXLSX;
