import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button, IconButton } from '@material-ui/core';
import { HelpOutline as HelpIcon } from '@material-ui/icons';

// Services
import { getCleaDomains, getCleaCourseForUser, updateCleaPosition, getCompanyList } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import CleaPositionFormPage1 from './clea-position-form/clea-position-form-page-1';
import CleaPositionFormPage2 from './clea-position-form/clea-position-form-page-2';
import { PositionHelpModal } from './clea-position-create';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// --------------------------------------- CREATE UPADTE POSITION ----------------------------------- \\
const CleaUpdatePosition = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { learnerId, cleaPositionId } = useParams();

    const [state, setState] = useState({
        loading: false,
        loadingRessources: true,
        errorRessources: false,
        page: 1,
        openHelp: false,
        
        // Page 1
        companies: [],
        company_id: null,
        position_date: null,
        trainer: '',
        learning_recommendation: '',
        
        // Page 2
        clea_domains: [],
        expanded: null,
        clea_competence_ids: [],
    });

    useEffect(() => {
        if (state.loadingRessources) {
            axios.all([getCleaDomains(), getCompanyList(), getCleaCourseForUser(learnerId)])
            .then(axios.spread(function (res_clea_domains, res_companies, res_course) {
                if (res_clea_domains?.status === 200 && res_companies?.status === 200 && res_course?.status === 200) {

                    const clea_position = res_course.data.data.clea_position;

                    setState({
                        ...state,
                        loadingRessources: false,
                        clea_domains: res_clea_domains.data.data.clea_domains,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        company_id: clea_position.company_id || null,
                        position_date: clea_position.position_date || null,
                        trainer: clea_position.trainer || '',
                        learning_recommendation: clea_position.learning_recommendation || '',
                        clea_competence_ids: clea_position.clea_competences.map(c => c.id),
                    });
                }
                else {
                    console.log(res_clea_domains, res_companies, res_course);
                    setState({ ...state, loadingRessources: false, errorRessources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingRessources]);
    
    useEffect(() => {
        if (state.loading) {
            updateCleaPosition(cleaPositionId, {
                user_id: learnerId,
                company_id: state.company_id,
                position_date: moment(state.position_date).format('YYYY-MM-DD'),
                trainer: state.trainer,
                learning_recommendation: state.learning_recommendation,
                clea_competence_ids: state.clea_competence_ids,
            }).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/clea/course/${learnerId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.goBack()}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                >
                    <div>
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {t('clea.position.edit')}
                        </span>
                        <Tooltip title={t('help')}
                            item={
                                <IconButton
                                    onClick={() => setState({ ...state, openHelp: true })}
                                    className="list-btn"
                                >
                                    <HelpIcon />
                                </IconButton>
                            }
                        />
                    </div>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- CONTAINER -------------------- */}
            {(state.loadingRessources || state.loading) ? (
                <Spinner />
            ) : state.errorRessources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <CleaPositionFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <CleaPositionFormPage2 state={state} setState={setState} />
                    )}
                </>
            )}
            {/* -------------------- HELP MODAL -------------------- */}
            {state.openHelp && (
                <PositionHelpModal
                    onClose={() => setState({ ...state, openHelp: false })}
                />
            )}
        </>
    );
};

export default CleaUpdatePosition;
