import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { softskillsColor2, getFormattedDate, getTranslation, softskillsColor } from 'services/helpers';

// Style
import style from 'style';

const styles = {
    tdMax: {
        border: '1px solid lightgray',
        padding: 10,
        fontSize: 14,
        minWidth: 320,
        width: '50%',
    },
    tdMin: {
        border: '1px solid lightgray',
        padding: 10,
        fontSize: 14,
        minWidth: 160,
        width: '25%',
        textAlign: 'center',
    },
    tdDialogHeader: {
        border: '1px solid lightgray',
        padding: 10,
        fontSize: 14,
        width: '25%',
        color: 'white',
        backgroundColor: softskillsColor,
        textAlign: 'center',
    },
    tdDialog: {
        border: '1px solid lightgray',
        padding: 10,
        fontSize: 14,
        width: '25%',
    },
};

// ---------------------------------------- SOFT PORTFOLIOS ---------------------------------------- \\
const SoftPortfolios = ({ unit }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        softskill: null,
        openSoftSkillDetails: false,
    });

    return (
        <>
            {unit.soft_units.length > 0 && (
                <table style={style.w100}>
                    <thead style={{ backgroundColor: softskillsColor2 }}>
                        <tr>
                            <td style={styles.tdMax}>
                                {t('softskills')}
                            </td>
                            <td style={styles.tdMin}>
                                {t('company')}
                            </td>
                            <td style={styles.tdMin}>
                                {t('date')} - {t('acquired.level')}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {unit.soft_units.map(soft_unit => {
                            const soft_portfolio = soft_unit.soft_portfolios.length > 0 ? soft_unit.soft_portfolios[0] : null;
                            return (
                                <tr key={soft_unit.id}>
                                    <td
                                        onClick={() => setState({ ...state, openSoftSkillDetails: true, softskill: soft_unit.softskill })}
                                        style={styles.tdMax}
                                        className="list-item-hover"
                                    >
                                        <Grid container alignItems="center">
                                            <Grid item xs={8}>
                                                <span>{getTranslation('name', soft_unit.softskill)}</span>
                                            </Grid>
                                            <Grid item xs={4} container justifyContent="flex-end" style={style.taR}>
                                                <span>
                                                    {t('level')}&nbsp;{soft_unit.soft_level?.level}&nbsp;{t('required')}
                                                    <br />
                                                    {soft_unit.soft_level?.level === 1 && t('remember.and.understand')}
                                                    {soft_unit.soft_level?.level === 2 && t('apply')}
                                                    {soft_unit.soft_level?.level === 3 && t('analyse.and.evaluate')}
                                                    {soft_unit.soft_level?.level === 4 && t('create')}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </td>
                                    <td style={styles.tdMin}>
                                        {soft_portfolio && (
                                            <>{soft_portfolio.evaluation?.company?.name}</>
                                        )}
                                    </td>
                                    <td style={styles.tdMin}>
                                        {soft_portfolio && (
                                            <>
                                                {getFormattedDate(soft_portfolio.evaluation?.evaluation_date)}
                                                &nbsp;-&nbsp;
                                                {t('level')}&nbsp;{soft_portfolio.soft_level?.level}
                                                <br />
                                                {soft_portfolio.soft_level?.level === 1 && t('remember.and.understand')}
                                                {soft_portfolio.soft_level?.level === 2 && t('apply')}
                                                {soft_portfolio.soft_level?.level === 3 && t('analyse.and.evaluate')}
                                                {soft_portfolio.soft_level?.level === 4 && t('create')}
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
            {/* -------------------- SOFT SKILL DETAILS -------------------- */}
            {state.openSoftSkillDetails && (
                <SoftSkillDetails
                    onClose={() => setState({ ...state, openSoftSkillDetails: false })}
                    softskill={state.softskill}
                />
            )}
        </>
    );
};

// ---------------------------------------- SOFT SKILL DETAILS ---------------------------------------- \\
const SoftSkillDetails = ({ onClose, softskill }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth maxWidth="md">
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {getTranslation('name', softskill)}
                </p>
                <table style={style.w100}>
                    <tbody>
                        <tr>
                            {softskill.soft_levels.map(soft_level => (
                                <td key={soft_level.id} style={styles.tdDialogHeader}>
                                    {t('level') + ' ' + soft_level.level}
                                    <br />
                                    {soft_level.level === 1 && t('remember.and.understand')}
                                    {soft_level.level === 2 && t('apply')}
                                    {soft_level.level === 3 && t('analyse.and.evaluate')}
                                    {soft_level.level === 4 && t('create')}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            {softskill.soft_levels.map(soft_level => (
                                <td key={soft_level.id} style={styles.tdDialog}>
                                    {getTranslation('definition', soft_level)}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SoftPortfolios;
