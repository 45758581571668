import React from 'react';
import { useTranslation } from 'react-i18next';

// Nivo
import { ResponsiveBar } from '@nivo/bar';

// Material-UI
import { Grid, IconButton } from '@material-ui/core';
import { ChevronLeft as IconLeft, ChevronRight as IconRight } from '@material-ui/icons';

// Services
import { getFormattedDate, mobipassColor } from 'services/helpers';

// ----------------------------------- GRAPHIC POSITIONING --------------------------------------- \\
const GraphicPositioning = ({ state, setState }) => {
    const { t } = useTranslation();
    const colors = { [t('mastery.0')]: '#E15252', [t('mastery.1')]: '#EAA449', [t('mastery.2')]: '#67D06A' };

    const getColor = bar => {
        return colors[bar.id];
    };

    const getNumberOfMastery = (hard_position, value) => {
        let numberOfMastery = 0;
        numberOfMastery += hard_position.competences.filter(c => c.mastery === value)?.length;
        numberOfMastery += hard_position.knowledges.filter(k => k.mastery === value)?.length;
        numberOfMastery += hard_position.skills.filter(s => s.mastery === value)?.length;
        return numberOfMastery;
    };

    const getData = () => {
        let data = [];
        state.hard_positions.forEach(hard_position => {
            data.push({
                'date': getFormattedDate(hard_position.position_date),
                [t('mastery.0')]: getNumberOfMastery(hard_position, 0),
                [t('mastery.1')]: getNumberOfMastery(hard_position, 1),
                [t('mastery.2')]: getNumberOfMastery(hard_position, 2),
            });
        });
        return data;
    };

    const getMaxValue = () => {
        let values = [];
        state.hard_positions.forEach(hard_position => {
            values.push(hard_position.competences?.length + hard_position.knowledges?.length + hard_position.skills?.length);
        });
        let maxValue = Math.max(...values);
        return Math.ceil(maxValue / 10) * 10;
    };

    const getTotalNumberOfDescriptors = () => {
        let descriptors_total_count = 0;
        state.skillbase.units.forEach(unit => {
            unit.learning_outcomes.forEach(learning => {
                descriptors_total_count += parseInt(learning.competences?.length) + parseInt(learning.knowledges?.length) + parseInt(learning.skills?.length);
            });
        });
        return descriptors_total_count;
    };

    return (
        <div style={{ overflow: 'auto' }}>
            <Grid
                container alignItems="center"
                style={{ backgroundColor: mobipassColor, textAlign: 'center' }}
            >
                <Grid item xs={2}>
                    {(state.offset > 0) && (
                        <IconButton
                            onClick={() => setState({ ...state, offset: state.offset -= 1, loading: true })}
                            style={{ padding: 2 }}
                        >
                            <IconLeft style={{ color: 'white' }} />
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={8} style={{ padding: 10 }}>
                    <span style={{ color: 'white' }}>
                        {t('positioning')}
                    </span>
                </Grid>
                <Grid item xs={2}>
                    {((state.offset + state.limit) < state.total_count) && (
                        <IconButton
                            onClick={() => setState({ ...state, offset: state.offset += 1, loading: true })}
                            style={{ padding: 2 }}
                        >
                            <IconRight style={{ color: 'white' }} />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <div style={{ minWidth: 1000, height: 400 }}>
                <ResponsiveBar
                    data={getData()}
                    maxValue={getMaxValue()}
                    colors={bar => getColor(bar)}
                    indexBy={'date'}
                    keys={[t('mastery.0'), t('mastery.1'), t('mastery.2')]}
                    layout="horizontal"
                    margin={{ top: 50, right: 150, bottom: 50, left: 75 }}
                    padding={0.45}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    borderColor={{ theme: 'background' }}
                    enableGridY={false}
                    enableGridX={true}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t('descriptors.number'),
                        legendPosition: 'middle',
                        legendOffset: 40,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'start',
                        legendOffset: -40,
                    }}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', '4' ] ] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            symbolSize: 20,
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            </div>
            <div style={{ textAlign: 'right' }}>
                <span>
                    {t('total.descriptors.skillbase')} : <b>{getTotalNumberOfDescriptors()}</b>
                </span>
            </div>
        </div>
    );
};

export default GraphicPositioning;
