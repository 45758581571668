import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { KeyboardDatePicker } from '@material-ui/pickers';

// ---------------------------------------- DATE PICKER ---------------------------------------- \\
const DatePicker = ({ placeholder, value, onChange, outlined, fullWidth }) => {
    const { t } = useTranslation();

    return (
        <KeyboardDatePicker
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            fullWidth={fullWidth}
            inputVariant={outlined ? 'outlined' : 'standard'}
            clearLabel={t('clear')}
            cancelLabel={t('cancel')}
            clearable
            size="small"
            format={'DD/MM/YYYY'}
            InputProps={{ style: { fontSize: 14 } }}
        />
    );
};

DatePicker.defaultProps = {
    fullWidth: true,
};

export default DatePicker;
