import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

// Material-UI
import { IconButton, Grid, Button } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt'

// Services
import { getContract} from 'services/requests';
import { servicesColor, getFormattedDate, fileUrl } from 'services/helpers';

// Components
import GenerateSubscriptions from '../generate-subscriptions';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ---------------------------------------- CONTRACT INFOS ---------------------------------------- \\
const ContractInfos = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const CONTRACT_ID = match.params.contractId;

    const [state, setState] = useState({
        loading: true,
        error: undefined,
        contract_id: undefined,
        reference: '',
        client: '',
        contract_start_date: '',
        contract_end_date: '',
        company_price: '',
        user_price: '',
        extra_price: '',
        document: '',
        client_id: undefined,
        openGenerateSubscriptions: false,
    });

    function handleRefetch() {
        window.location.reload();
        setState({ ...state, openGenerateSubscriptions: false });
    }

    useEffect(() => {
        if (state.loading) {
            getContract(CONTRACT_ID).then(res => {
                if (res?.status === 200) {
                    const contract = res.data.data.contract;
                    setState(state => ({
                        ...state,
                        loading: false,
                        contract_id: contract.id,
                        reference: contract.reference,
                        client: contract.client.name,
                        contract_start_date: contract.contract_start_date,
                        contract_end_date: contract.contract_end_date,
                        company_price: contract.company_price,
                        user_price: contract.user_price,
                        extra_price: contract.extra_price,
                        document: contract.document,
                        client_id: contract.client.id,
                    }));
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <span style={style.m10}>{t('loading')}</span>;
    if (state.error) return <span style={style.m10}>{t('error.occured')}</span>;

    return (
        <>
            <div style={style.p10}>
                <b>{t('contract')}</b>
            </div>            
            <div className="simple-container" style={{ paddingBottom: 24 }}>
                <Button
                    onClick={() => setState({ ...state, openGenerateSubscriptions: true })}
                    variant="contained"
                    style={style.mb10}
                >
                    {t('generate.subscriptions')}
                </Button>
                <Grid container alignItems="center" className="billing-infos">
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('reference')}:&nbsp;</span>
                        <span>{state.reference}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('client')}:&nbsp;</span>
                        <span>{state.client}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('start.date')}:&nbsp;</span>
                        <span>{getFormattedDate(state.contract_start_date)}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('end.date')}:&nbsp;</span>
                        <span>{getFormattedDate(state.contract_end_date)}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('company.price')}:&nbsp;</span>
                        <span>{state.company_price}€</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('user.price')}:&nbsp;</span>
                        <span>{state.user_price}€</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('extra.price')}:&nbsp;</span>
                        <span>{state.extra_price}€</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('document')}:&nbsp;</span>
                        {state.document ? (
                            <Tooltip title={t('download.document')}
                                item={
                                    <IconButton href={fileUrl + state.document} target="_blank" className="list-btn">
                                        <IconSave style={{ fontSize: 20, color: 'gray' }} />
                                    </IconButton>
                                }
                            />
                        ) : (
                            <span>{t('none')}</span>
                        )}
                    </Grid>
                </Grid>
            </div>
            {/* -------------------- GENERATE SUBSCRIPTIONS -------------------- */}
            {state.openGenerateSubscriptions && (
                <GenerateSubscriptions
                    open={state.openGenerateSubscriptions}
                    onClose={() => setState({ ...state, openGenerateSubscriptions: false })}
                    refetch={handleRefetch}
                    contract_id={state.contract_id}
                    client_id={state.client_id}
                />
            )}
        </>
    );
};

export default ContractInfos;
