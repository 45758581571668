import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { languages } from 'services/constants';

// Components
import TextEditor from 'components/items/editor/text-editor'

// Style
import style from 'style';

// ---------------------------------------- NEWS FORM PAGE 3 ---------------------------------------- \\
const NewsFormPage3 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    function navigateToPage4() {
        if (Object.values(state.details).every(value => !value.getCurrentContent().hasText())) {
            enqueueSnackbar(t('news.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 4 });
        }
    }

    function onChangeEditorState(code, editorState) {
        let details = state.details;
        details[code] = editorState;
        setState({ ...state, details });
    }

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, paddingTop: 24 }}>
            {/* -------------------- DETAILS -------------------- */}
                <div style={{...style.taC, margin: '20px 0 10px 0'}}>
                    <span style={style.fwB}>{t('news.details')}</span>
                </div>
                {languages.map((language, index) => (
                    <Fragment key={language.id}>
                        {index !== 0 && (
                            <Divider style={{ margin: 20 }}/>
                        )}
                        <TextEditor
                            label={language.label}
                            editorState={state.details[language.code]}
                            setEditorState={editorState => onChangeEditorState(language.code, editorState)}
                            disabled={state.disabled.find(item => item === language.code)}
                        />
                    </Fragment>
                ))}
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>3 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage4} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default NewsFormPage3;
