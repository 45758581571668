import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getSoftCategories, createSoftPosition, getCompanyList } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import GroupSoftPositionFormPage1 from './group-softposition-form/group-softposition-form-page-1';
import GroupSoftPositionFormPage2 from './group-softposition-form/group-softposition-form-page-2';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import DatePicker from 'components/items/date-picker';

// Style
import style from 'style';

// ------------------------------------ SOFT POSITIONS RATING -------------------------------------- \\
const CreateGroupSoftPosition = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loadingResources: true,
        errorResources: false,
        loading: false,
        page: 1,
        
        // Page 1
        companies: [],
        companyId: null,
        learnerOptions: [],
        learners: null,
        
        // Page 2
        position_date: new Date(),
        soft_categories: [],
        soft_levels: null,
        openBehavior: false,
        softskill: null,

        // Skill bases with soft skills
        filteredCategories: null,
        skillbase: null,
        openSkillbasesWithSoftskills: false,
    });

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getSoftCategories(), getCompanyList()])
            .then(axios.spread(function (res_soft_categories, res_companies) {
                if (res_soft_categories?.status === 200 && res_companies?.status === 200) {

                    const soft_categories = res_soft_categories.data.data.soft_categories;
                    let soft_levels = {};
                    for (let i = 0; i < soft_categories.length; i += 1) {
                        for (let j = 0; j < soft_categories[i].softskills.length; j += 1) {
                            soft_levels[soft_categories[i].softskills[j].id] = Object.fromEntries(
                                soft_categories[i].softskills[j].soft_levels.map(sl => [sl.id, false])
                            );
                        }
                    }

                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        soft_categories,
                        filteredCategories: soft_categories,
                        soft_levels,
                    });
                }
                else {
                    console.log(res_soft_categories + res_companies);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {

            const soft_level_ids = [];
            Object.values(state.soft_levels).forEach(sl => {
                Object.entries(sl).forEach(([key, value]) => {
                    if (value) {
                        soft_level_ids.push(key);
                    }
                });
            });

            createSoftPosition({
                learner_ids: state.learners.map(l => l.value),
                position_date: moment(state.position_date).format('YYYY-MM-DD'),
                soft_level_ids,
                skillbase_id: state.skillbase ? state.skillbase.id : null, 
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/softskills/positioning`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/softskills/positioning`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('group.soft.position.add')}
                    </span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}  
                >
                    <DatePicker
                        placeholder={t('date')}
                        value={state.position_date}
                        onChange={value => setState({ ...state, position_date: value })}
                        fullWidth={false}
                        outlined
                    />
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- FORM -------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <GroupSoftPositionFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <GroupSoftPositionFormPage2 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CreateGroupSoftPosition;
