import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider, IconButton} from '@material-ui/core';
import { Delete as IconDelete, Edit as IconEdit } from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { cleaColor } from 'services/helpers';

// Components
import CleaPositionDelete from '../clea-position/clea-position-delete';
import Tooltip from 'components/items/tooltip';

// --------------------------------- CLEA LEARNER POSITION ------------------------------------- \\
const CleaLearnerPosition = ({ refetch, clea_position, hasEvaluations }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const ROLE_ID = getStoredUser()?.role?.id;
    const { t } = useTranslation();

    const [state, setState] = useState({
        openDelete: false,
    });

    return (
        <>
            {clea_position == null && (
                <Grid container justifyContent="center" style={{ textAlign: 'center', padding: 24 }}>
                    <span style={{ fontStyle: 'italic' }}>
                        {t('no.clea.course')}
                        {ROLE_ID < 5 && (
                            <>
                                <br/>
                                {t('clea.start.course.by.clicking.top.right')}
                            </>
                        )}
                    </span>
                </Grid>
            )}
            {clea_position != null && (
                <>
                    {/* -------------------- CLEA POSITION INFOS -------------------- */}
                    <Grid container alignItems="center" justifyContent="space-between" style={{ padding: 24 }}>
                        <div style={{ paddingRight: 5 }}>
                            <b className="input-title" style={{ fontSize: 14 }}>
                                {t('clea.positioning')} {t('of')} {moment(clea_position.position_date).format('DD/MM/YYYY')}
                            </b>
                            <br />
                            <br />
                            <span><b>• {t('company')} : </b>{clea_position.company?.name}</span>
                            {clea_position.trainer != null && (
                                <>
                                    <br />
                                    <span><b>• {t('trainer')} : </b>{clea_position.trainer}</span>
                                </>
                            )}
                            {clea_position.learning_recommendation != null && (
                                <>
                                    <br />
                                    <span><b>• {t('clea.learning.recommendation')} : </b>{clea_position.learning_recommendation}</span>
                                </>
                            )}
                        </div>
                        <div style={{ paddingLeft: 5 }}>
                            <Tooltip title={t('clea.competences.selected')}
                                item={
                                    <div
                                        onClick={() => history.push(`${match.url}/competences-details/positioning`)}
                                        className="clea-icon-button"
                                    >
                                        {clea_position.clea_competences?.length}
                                    </div>
                                }
                            />
                        </div>
                    </Grid>
                    {/* -------------------- CLEA POSITION CREATOR & CRUD -------------------- */}
                    <Grid container alignItems="center" justifyContent="space-between" style={{ padding: '0px 24px 24px 24px' }}>
                        <div style={{ paddingRight: 5 }}>
                            <b className="input-title" style={{ color: cleaColor, fontStyle: 'italic' }}>
                                {t('clea.creation.date')} {moment(clea_position.position_date).format('DD/MM/YYYY')}
                                {clea_position.creator != null && (
                                    <>
                                        {t('by')} {clea_position.creator?.full_name}
                                    </>
                                )}
                            </b>
                            {clea_position.last_modifier != null && (
                                <>
                                    <br />
                                    <b className="input-title" style={{ color: cleaColor, fontStyle: 'italic' }}>
                                        {t('clea.last.modification')} {moment(clea_position.updated_at).format('DD/MM/YYYY')} {t('by')} {clea_position.last_modifier.full_name}
                                    </b>
                                </>
                            )}
                        </div>
                        <div style={{ paddingLeft: 5 }}>
                            {ROLE_ID < 5 && (
                                <>
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={() => history.push(`${match.url}/position/update/${clea_position.id}`)}
                                                style={{ padding: 8 }}
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    {!hasEvaluations && (
                                        <Tooltip title={t('delete')}
                                            item={
                                                <IconButton
                                                    onClick={() => setState({ ...state, openDelete: true })}
                                                    style={{ padding: 8 }}
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                </>
            )}
            <Divider />
            {/* -------------------- CLEA POSITION DELETE -------------------- */}
            {(clea_position != null && state.openDelete) && (
                <CleaPositionDelete
                    clea_position={clea_position}
                    refetch={refetch}
                    onClose={() => setState({...state, openDelete: false })}
                />
            )}
        </>
    );
};

export default CleaLearnerPosition;
