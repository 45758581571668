import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// Components
import Select from 'components/items/react-select';
import DatePicker from 'components/items/date-picker';
import InputText from 'components/items/input-text';
import FieldRequired from 'components/items/field-required';

// --------------------------------- SOFT EVALUATION FORM ---------------------------------------- \\
const CleaPositionFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage2 = () => {
        if (state.company_id == null || state.position_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('company')}</b><FieldRequired />
                            <Select
                                placeholder={t('company')}
                                value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- START DATE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('start.date')}</b><FieldRequired />
                            <DatePicker
                                placeholder={t('start.date')}
                                value={state.position_date}
                                onChange={position_date => setState({ ...state, position_date })}
                                outlined
                            />
                        </div>
                        {/* -------------------- TRAINER -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('trainer')}</b>
                            <InputText
                                placeholder={t('trainer')}
                                value={state.trainer}
                                onChange={e => setState({ ...state, trainer: e.target.value })}
                            />
                        </div>
                        {/* -------------------- RECOMMENDATION -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('clea.learning.recommendation')}</b>
                            <InputText
                                placeholder={t('clea.learning.recommendation')}
                                value={state.learning_recommendation}
                                onChange={e => setState({ ...state, learning_recommendation: e.target.value })}
                                multiline
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CleaPositionFormPage1;
