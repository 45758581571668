import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, GridList, Divider, Paper, Button } from '@material-ui/core';
import { MenuBook as IconOtherDoc, SaveAlt as SaveIcon, CancelPresentation as NoneIcon } from '@material-ui/icons';

// Services
import { getAllSoftTools } from 'services/requests';
import { fileUrl, softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ----------------------------------- OTHER DOCUMENTATIONS ----------------------------------- \\
const OtherDocumentations = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        soft_tools: [],
    });

    useEffect(() => {
        if (state.loading) {
            getAllSoftTools({
                offset: 0,
                limit: 100,
                soft_tool_type_id: 2,
                active: 1,
            }).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, soft_tools: res.data.data.soft_tools });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div className="full-container">
            <Grid
                container alignItems="center" justifyContent="center"
                style={{ ...style.p10, backgroundColor: softskillsColor }}
            >
                <IconOtherDoc style={style.cW}/>
                <span style={{ ...style.ml5, ...style.ttU, ...style.cW }}>
                    {t('other.documentation')}
                </span>
            </Grid>
            {/* -------------------- DOCUMENT LIST -------------------- */}
            <DocumentList 
                loading={state.loading}
                error={state.error}
                soft_tools={state.soft_tools}
            />
        </div>
    );
};

// -------------------------------------- DOCUMENT LIST --------------------------------------- \\
const DocumentList = ({ loading, error, soft_tools }) => {
    const { mediaXS, mediaSM, mediaMD, mediaLG, mediaXL } = useMediaQueries();
    const [cols, setCols] = useState(6);
    const { t } = useTranslation();

    useEffect(() => {
        if (mediaXS) setCols(1);
        if (mediaSM) setCols(2);
        if (mediaMD) setCols(3);
        if (mediaLG) setCols(5);
        if (mediaXL) setCols(6);
    }, [mediaXS, mediaSM, mediaMD, mediaLG, mediaXL]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            {/* ---------------------- SOFT TOOL CARDS ---------------------- */}
            <div className="full-container-content">
                <GridList cellHeight={280} cols={cols} style={{ margin: 0 }}>
                    {soft_tools.length > 0 ? (
                        soft_tools.map(soft_tool => (
                            <Grid container key={soft_tool.id} style={{ padding: 5 }}>
                                <Paper elevation={3} className="paper">
                                    <Grid container justifyContent="center">
                                        <b>{soft_tool.name}</b>
                                    </Grid>
                                    <Divider style={{ margin: '10px 0px' }} />
                                    <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ color: softskillsColor }}>{t('description')}</span>
                                        <span>{soft_tool.description ? soft_tool.description : t('none')}</span>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span style={{ color: softskillsColor }}>{t('documents')}</span>
                                        {soft_tool.soft_tool_links.length > 0 ? (
                                            soft_tool.soft_tool_links.map(soft_tool_link => (
                                                <Button
                                                    key={soft_tool_link.id}
                                                    href={fileUrl + soft_tool_link.url}
                                                    target="_blank"
                                                    variant='contained'
                                                    style={{ textTransform: 'none', margin: '5px 0' }}
                                                >
                                                    {soft_tool_link.link_type.name}
                                                    <span style={{ ...style.ttU, ...style.ml5 }}>
                                                        ({soft_tool_link.languages.map((l, index) => {
                                                            if (index > 0) return ', ' + l.code;
                                                            else return l.code;
                                                        })})
                                                    </span>
                                                    <SaveIcon style={{ marginLeft: 5, fontSize: 20 }} />
                                                </Button>
                                            ))
                                        ):(
                                            <span>{t('none')}</span>
                                        )}
                                    </div>
                                </Paper>
                            </Grid>
                        ))
                    ) : (
                        <Grid container style={{ padding: 5 }}>
                            <Paper elevation={3} className="paper">
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%', flexDirection: 'column' }}
                                >
                                    <NoneIcon style={{ color: 'gray', fontSize: 40 }} />
                                    <span>{t('none.result')}</span>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </GridList>
            </div>
        </>
    );
};

export default OtherDocumentations;
