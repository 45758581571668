import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, MenuItem, Dialog, DialogActions, DialogContent, Divider } from '@material-ui/core';

// Services
import { getSkillbase, importTranslations } from 'services/requests';
import { getTranslation, servicesColor } from 'services/helpers';
import { languages } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Units from '../skillbase-attributes/units';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import SelectOutlined from 'components/items/select-outlined';
import InputFileGrid from 'components/items/input-file-grid';

// Style
import style from 'style';

// ------------------------------------------ SKILLBASE  -------------------------------------------- \\
const Skillbase = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { skillbaseId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        skillbase: null,
        openImportSkillbase: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSkillbase(skillbaseId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, skillbase: res.data.data.skillbase });
                }
                else {
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/management/skillbases')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center" style={{ padding: mediaMD ? 0 : 10, textAlign: 'center' }}>
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {getTranslation('name', state.skillbase)}
                    </span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => setState({ ...state, openImportSkillbase: true })} variant="contained">
                        {t('language.import')}
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- UNITS -------------------- */}
            <div className="full-container">
                <Units skillbase={state.skillbase} />
            </div>
            {/* -------------------- IMPORT SKILLBASE -------------------- */}
            {state.openImportSkillbase && (
                <ImportSkillbase
                    skillbaseId={skillbaseId}
                    onClose={() => setState({ ...state, openImportSkillbase: false })}
                    refetch={() => setState({ ...state, openImportSkillbase: false, loading: true })}
                />
            )}
        </>
    );
};

// ---------------------------------------- IMPORT SKILLBASE ---------------------------------------- \\
const ImportSkillbase = ({ onClose, refetch, skillbaseId }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        code: languages[0].code,
        csv_file: null,
    });

    // Import Language
    function handleImportTranslations() {

        let file = document.getElementById('upload-csv-file').files[0];

        if (file) {
            let csv_file = new FormData();
            csv_file.append('excel', file);
            csv_file.append('language', state.code)
            setState({ ...state, loading: true, csv_file });
        }
        else {
            enqueueSnackbar(t('file.select'), { variant: 'warning' });
        }
    }

    useEffect(() => {
        if (state.loading) {
            importTranslations(skillbaseId, state.csv_file).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('skillbase.import.language')}
                        </p>
                        <Grid container alignItems="center" style={{ margin: '5px 0px' }}>
                            <Grid
                                item xs={12} sm={4}
                                style={{ paddingRight: mediaSM ? 5 : 0 }}
                            >
                                <b className="input-title">{t('file.language')}</b>
                            </Grid>
                            <Grid
                                item xs={12} sm={8}
                                style={{ paddingLeft: mediaSM ? 5 : 0 }}
                            >
                                <SelectOutlined
                                    value={state.code}
                                    onChange={e => setState({ ...state, code: e.target.value })}
                                >
                                    {languages.map(language => (
                                        <MenuItem key={language.id} value={language.code}>
                                            <span>{t(language.label)}</span>
                                        </MenuItem>
                                    ))}
                                </SelectOutlined>
                            </Grid>
                        </Grid>
                        <InputFileGrid
                            placeholder={t('file.csv')}
                            inputId="csv-file"
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleImportTranslations}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Skillbase;
