import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Button, Accordion, AccordionSummary } from '@material-ui/core';
import { ExpandMore as IconExpand, CheckBox as IconCheckBox, CheckBoxOutlineBlank as IconCheckoutline } from '@material-ui/icons';

// Services
import { getSkillbase } from 'services/requests';
import { getTranslation, mobipassColor, mobipassColor2, softskillsColor, softskillsColor2 } from 'services/helpers';
import { getSoftskillsAccess } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Select from 'components/items/react-select';
import Badge from 'components/items/badge';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------- EVALUATION FORM PAGE 3 ------------------------------------- \\
const EvaluationFormPage3 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const saveEvaluation = () => {
        if (Object.values(state.learning_outcomes).every(v => v.length === 0)
            && Object.values(state.soft_units).every(v => v.length === 0)
        ) {
            enqueueSnackbar(t('select.learning.or.softskill.warning'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.authorizedRefetchSkillbase) {
            if (state.skillbase_id) {
                getSkillbase(state.skillbase_id).then(res => {
                    if (res?.status === 200) {
    
                        const skillbase = res.data.data.skillbase;
    
                        let learning_outcomes = {}, soft_units = {};
                        skillbase.units.forEach(unit => {
                            learning_outcomes[unit.id] = [];
                            soft_units[unit.id] = [];
                        });
    
                        setState({ ...state, skillbase, learning_outcomes, soft_units, expanded: null });
                    }
                });
            }
            else {
                setState({ ...state, skillbase: null, learning_outcomes: {}, soft_units: {}, expanded: null });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.skillbase_id]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- SKILLBASE -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('skillbase')}
                            </b>
                            {state.actionType === 'CREATE' ? (
                                <Select
                                    placeholder={t('skillbase')}
                                    value={state.skillbase_id ? state.skillbases.find(c => c.value === state.skillbase_id) : null}
                                    onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null, authorizedRefetchSkillbase: true })}
                                    options={state.skillbases}
                                />
                            ) : (
                                <div style={{ padding: '6px 10px', borderRadius: 4, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                    <span>{state.skillbase_name}</span>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                {/* -------------------- UNITS -------------------- */}
                {state.skillbase != null && (
                    <>
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('learning.outcomes.select')} / {t('softskills')}</b><FieldRequired />
                        </div>
                        <div style={{ backgroundColor: mobipassColor, color: 'white', padding: '10px 20px' }}>
                            <span>{t('units.of.learning.outcomes')}</span>
                        </div>
                        <UnitList
                            state={state}
                            setState={setState}
                        />
                    </>
                )}
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>3 / 3</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={saveEvaluation} variant="contained" color="primary">
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// --------------------------------------- UNIT LIST --------------------------------------------- \\
const UnitList = ({ state, setState }) => {
    const { t } = useTranslation();

    const handleChangeAccordion = unit_id => {
        if (state.expanded === unit_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: unit_id });
        }
    };

    function getSelectedNumber(unit_id) {
        let num = 0;
        num += (state.learning_outcomes[unit_id].length + state.soft_units[unit_id].length);
        return num;
    }

    function handleSelectLearnings(unit_id, learning_id) {
        let learning_outcomes = state.learning_outcomes;
        let learning_outcome_exist = learning_outcomes[unit_id].find(lo => lo === learning_id);

        if (learning_outcome_exist == null) {
            learning_outcomes[unit_id].push(learning_id);
        }
        else {
            const index = learning_outcomes[unit_id].findIndex(lo => lo === learning_id);
            learning_outcomes[unit_id].splice(index, 1);
        }

        setState({ ...state, learning_outcomes });
    }

    function handleSelectSoftUnits(unit_id, soft_unit_id) {
        let soft_units = state.soft_units;
        let soft_unit_exist = soft_units[unit_id].find(su => su === soft_unit_id);

        if (soft_unit_exist == null) {
            soft_units[unit_id].push(soft_unit_id);
        }
        else {
            const index = soft_units[unit_id].findIndex(su => su === soft_unit_id);
            soft_units[unit_id].splice(index, 1);
        }

        setState({ ...state, soft_units });
    }

    return (
        <div>
            {state.skillbase.units.length > 0 ? (
                state.skillbase.units.map(unit => (
                    <Accordion
                        key={unit.id}
                        expanded={state.expanded === unit.id}
                        onChange={() => handleChangeAccordion(unit.id)}
                    >
                        <AccordionSummary expandIcon={<IconExpand />}>
                            <Grid container alignItems="center">
                                <Grid item xs={11}>
                                    <span style={style.fwB}>
                                        {unit.position}. {getTranslation('name', unit)}
                                    </span>
                                </Grid>
                                <Grid item xs={1} container justifyContent="center">
                                    <Badge
                                        variant="secondary"
                                        number={getSelectedNumber(unit.id)}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider />
                        <div>
                            {(unit.learning_outcomes?.length > 0 || unit.soft_units?.length > 0) ? (
                                <>
                                    {/* -------------------- LEARNING OUTCOMES -------------------- */}
                                    {unit.learning_outcomes.map(learning => (
                                        <Fragment key={learning.id}>
                                            <Grid
                                                container alignItems="center"
                                                className="list-item-hover"
                                                onClick={() => handleSelectLearnings(unit.id, learning.id)}
                                                style={{
                                                    padding: 10,
                                                    backgroundColor: state.learning_outcomes[unit.id].includes(learning.id)
                                                    ? mobipassColor2
                                                    : null
                                                }}
                                            >
                                                <Grid item xs={11}>
                                                    <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                                                </Grid>
                                                <Grid item xs={1} container justifyContent="center" style={style.p5}>
                                                    {state.learning_outcomes[unit.id].find(lo => lo === learning.id) != null ? ( 
                                                        <IconCheckBox />
                                                    ) : (
                                                        <IconCheckoutline />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Fragment>
                                    ))}
                                    {/* -------------------- SOFT UNITS -------------------- */}
                                    {getSoftskillsAccess() === '1' && unit.soft_units?.length > 0 && (
                                        <>
                                            <Grid container style={{ color: 'white', backgroundColor: softskillsColor, padding: 10 }}>
                                                <span>{t('softskills')}</span>
                                            </Grid>
                                            {unit.soft_units?.map(soft_unit => (
                                                <Fragment key={soft_unit.id}>
                                                    <Grid
                                                        container alignItems="center"
                                                        className="list-item-hover"
                                                        onClick={() => handleSelectSoftUnits(unit.id, soft_unit.id)}
                                                        style={{
                                                            padding: 10,
                                                            backgroundColor: state.soft_units[unit.id].includes(soft_unit.id)
                                                            ? softskillsColor2
                                                            : null
                                                        }}
                                                    >
                                                        <Grid item xs={11}>
                                                            <span>{soft_unit.softskill?.name}</span>
                                                        </Grid>
                                                        <Grid item xs={1} container justifyContent="center" style={style.p5}>
                                                            {state.soft_units[unit.id].find(su => su === soft_unit.id) != null ? (
                                                                <IconCheckBox />
                                                            ):(
                                                                <IconCheckoutline />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Divider />
                                                </Fragment>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Grid style={style.p10}>
                                    <span>{t('none')}</span>
                                </Grid>
                            )}
                        </div>
                    </Accordion>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default EvaluationFormPage3;
