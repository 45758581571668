import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getTranslation } from 'services/helpers';
import { getFormationModels } from 'services/requests';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- FORMATION EXPORT ---------------------------------------- \\
const ExportFormationModels = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
    });

    function getAllFormationModelsToExport(formation_models, offset) {
        getFormationModels({
            offset: offset,
            limit: data.limit,
            name: data.name,
            company_id: data.company_id,
            skillbase_id: data.skillbase_id,
            export_excel: true,
        }).then(res => {
            if (res?.status === 200) {

                let res_formation_models = res.data.data.formation_models;
                formation_models = formation_models.concat(res_formation_models);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * formation_models.length) / data.total_count) + '%',
                });

                if (res_formation_models.length < data.limit) {
                    createExcelFile(formation_models);
                }
                else {
                    getAllFormationModelsToExport(formation_models, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(formation_models) {
        if (formation_models.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('formation.models'), {
                pageSetup: { orientation: 'landscape' },
            });

            // Header
            worksheet.columns = [
                { header: t('name'), key: 'name', width: 25 },
                { header: t('company.name'), key: 'company', width: 15 },
                { header: t('skillbase'), key: 'skillbase', width: 60 },
                { header: t('competence'), key: 'competences', width: 120 },
                { header: t('knowledge'), key: 'knowledges', width: 120 },
                { header: t('skill'), key: 'skills', width: 120 },
            ];

            // Rows
            formation_models.forEach(formation_model => {
                let formation_model_competences = '';
                let formation_model_knowledges = '';
                let formation_model_skills = '';

                for (let comp = 0; comp < formation_model.competences.length; comp += 1) {
                    formation_model_competences += '- ' + formation_model.competences[comp].numerotation + t('competence.numerotation') + ' ' + getTranslation('name', formation_model.competences[comp]) + '\r\n';
                }

                for (let know = 0; know < formation_model.knowledges.length; know += 1) {
                    formation_model_knowledges += '- ' + formation_model.knowledges[know].numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', formation_model.knowledges[know]) + '\r\n';
                }

                for (let skill = 0; skill < formation_model.skills.length; skill += 1) {
                    formation_model_skills += '- ' + formation_model.skills[skill].numerotation + t('skill.numerotation') + ' ' + getTranslation('name', formation_model.skills[skill]) + '\r\n';
                }

                worksheet.addRow({
                    name: formation_model.name,
                    company: formation_model.company.name,
                    skillbase: getTranslation('name', formation_model.skillbase),
                    competences: formation_model_competences,
                    knowledges: formation_model_knowledges,
                    skills: formation_model_skills,
                });
            });

            // background color cell and border
            for (let index = 0; index <= formation_models.length; index += 1) {
                if (index % 2 === 0) {
                    worksheet.getRow(index).fill = {
                        type: 'gradient',
                        gradient: 'path',
                        center:{left:0.5,top:0.5},
                        stops: [
                            {position:0, color:{argb:'FFEBEBEB'}},
                            {position:1, color:{argb:'FFEBEBEB'}}
                        ]
                    }
                    worksheet.getRow(index).border = {
                        right: {style:'thin', color: {argb:'FFBFBFBF'}}
                    }
                }
            }

            // management of font and spacing
            worksheet.getColumn(4).font = { name: 'arial', color: { argb: 'FF135699'}};
            worksheet.getColumn(5).font = { name: 'arial', color: { argb: 'FF9C135B'}};
            worksheet.getColumn(6).font = { name: 'arial', color: { argb: 'FF0E720F'}};
            worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getColumn(3).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getColumn(4).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
            worksheet.getColumn(5).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
            worksheet.getColumn(6).alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };
            worksheet.getRow(1).font = { bold: true, color: { argb: 'FF000000'}};
            worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('formation.models') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllFormationModelsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default ExportFormationModels;