import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';
import { EuroSymbol as IconBills, People as IconClients, LibraryBooks as IconContracts } from '@material-ui/icons';

// Services
import { servicesColor } from 'services/helpers';

// Components
import Clients from './clients';
import Contracts from './contracts';
import Bills from './bills';

// Style
import style from 'style';

// --------------------------------------- BILLING ---------------------------------------- \\
const Billing = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <BillingMenu />
            </Route>
            <Route path={`${match.path}/clients`}>
                <Clients />
            </Route>
            <Route path={`${match.path}/contracts`}>
                <Contracts />
            </Route>
            <Route path={`${match.path}/bills`}>
                <Bills />
            </Route>
        </Switch>
    );
};

// --------------------------------------- BILLING MENU ---------------------------------------- \\
const BillingMenu = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation();

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid style={style.p10}>
                <b>{t('billing')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>
            </Grid>
            {/* -------------------- MENU -------------------- */}
            <Grid container className="simple-container">
                <Grid item xs={12} md={4} container justifyContent="center" style={style.p25}>
                    <div
                        onClick={() => history.push(`${match.path}/clients`)}
                        className="billing-menu"
                        style={{ borderWidth: 3, borderStyle: 'solid', borderColor: servicesColor }}
                    >
                        <div className="billing-menu-container-icon">
                            <IconClients style={{ color: servicesColor, fontSize: 48 }} />
                        </div>
                        <div className="billing-menu-container-label">
                            <span className="billing-menu-label">{t('clients')}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center" style={style.p25}>
                    <div
                        onClick={() => history.push(`${match.path}/contracts`)}
                        className="billing-menu"
                        style={{ borderWidth: 3, borderStyle: 'solid', borderColor: servicesColor }}
                    >
                        <div className="billing-menu-container-icon">
                            <IconContracts style={{ color: servicesColor, fontSize: 48 }} />
                        </div>
                        <div className="billing-menu-container-label">
                            <span className="billing-menu-label">{t('contracts')}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center" style={style.p25}>
                    <div
                        onClick={() => history.push(`${match.path}/bills`)}
                        className="billing-menu"
                        style={{ borderWidth: 3, borderStyle: 'solid', borderColor: servicesColor }}
                    >
                        <div className="billing-menu-container-icon">
                            <IconBills style={{ color: servicesColor, fontSize: 48 }} />
                        </div>
                        <div className="billing-menu-container-label">
                            <span className="billing-menu-label">{t('bills')}</span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default Billing;
