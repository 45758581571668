import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';

// Services
import { deleteCleaDomain} from 'services/requests';
import { getTranslation } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// -------------------------------------- DELETE CLEA DOMAIN --------------------------------- \\
const DeleteCleaDomain = ({ onClose, refetch, domain }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loadingDelete, setLoadingDelete] = useState(false);

    useEffect(() => {
        if (loadingDelete) {
            deleteCleaDomain(domain.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoadingDelete(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDelete]);

    return (
        <Dialog open>
            {loadingDelete ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.jcC}>
                        <p className="dialog-title">
                            {getTranslation('name', domain)}
                        </p>
                        <span>{t('clea.domain.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoadingDelete(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default DeleteCleaDomain;
