import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Button, Accordion, AccordionSummary, Checkbox } from '@material-ui/core';
import { ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getTranslation, cleaColor, cleaColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import Badge from 'components/items/badge';

// Style
import style from 'style';

const styles = {
    th: {
        padding: 10,
        fontSize: 14,
        border: '1px solid lightgray',
        textAlign: 'center',
    },
    td: {
        padding: 10,
        fontSize: 14,
        border: '1px solid lightgray',
    },
};

// ---------------------------------- CLEA EVALUATION FORM PAGE 2 ------------------------------------- \\
const CleaEvaluationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const onCheckedCompetence = competenceId => {
        let clea_competences = state.clea_competences;
        if (clea_competences.some(c => c.id === competenceId)) {
            let index = clea_competences.findIndex(c => c.id === competenceId);
            clea_competences.splice(index, 1);
        }
        else {
            clea_competences.push({ id: competenceId, locked: false });
        }
        setState({ ...state, clea_competences });
    };

    const getCheckedCompetencesNumber = (clea_domain) => {
        let number = 0;
        clea_domain.clea_subdomains.forEach(subdomain => {
            subdomain.clea_competences.forEach(competence => {
                if (state.clea_competences.some(c => c.id === competence.id)) {
                    number += 1;
                }
            });
        });
        return number;
    };

    const onValidate = () => {
        if (state.clea_competences.length === 0) {
            enqueueSnackbar(t('clea.select.one.competence'), { variant: 'warning' })
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    return (
        <>
            <div style={{ padding: '24px 24px 12px 24px' }}>
                <b className="input-title">{t('clea.competence.select')}</b><FieldRequired />
            </div>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- LIST HEADERS -------------------- */}
                <div style={{ backgroundColor: cleaColor, ...style.p10 }}>
                    <span style={style.cW}>
                        {t('clea.domain')}
                    </span>
                </div>
                {/* -------------------- CLEA DOMAIN LIST -------------------- */}
                <div>
                    {state.clea_domains?.length > 0 ? (
                        state.clea_domains.map(clea_domain => (
                            <Accordion
                                key={clea_domain.id}
                                expanded={state.expanded === clea_domain.id}
                                onChange={() => setState({ ...state, expanded: clea_domain.id === state.expanded ? null : clea_domain.id })}
                            >
                                <AccordionSummary
                                    expandIcon={<IconExpand />}
                                    style={{ backgroundColor: cleaColor2 }}
                                >
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <span style={style.fwB}>
                                            {clea_domain.position}. {getTranslation('name', clea_domain)}
                                        </span>
                                        <Badge number={getCheckedCompetencesNumber(clea_domain)} />
                                    </Grid>
                                </AccordionSummary>
                                <Divider />
                                <div style={{ overflow: 'auto' }}>
                                    <table style={{ ...style.w100, minWidth: 1000 }}>
                                        <thead>
                                            <tr>
                                                <th rowSpan="2" style={{ ...styles.th, width: '30%' }}>
                                                    {t('clea.sub.domain')?.toUpperCase()}
                                                </th>
                                                <th rowSpan="2" style={{ ...styles.th, width: '70%' }}>
                                                    {t('clea.competence')?.toUpperCase()}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clea_domain.clea_subdomains.length > 0 && (
                                                clea_domain.clea_subdomains?.map(sub_domain => (
                                                    <Fragment key={sub_domain.id}>
                                                        <tr>
                                                            <td
                                                                rowSpan={sub_domain.clea_competences.length + 1}
                                                                style={{ ...styles.th, width: '30%' }}
                                                            >
                                                                {sub_domain.position}. {getTranslation('name', sub_domain)}
                                                            </td>
                                                        </tr>
                                                        {sub_domain.clea_competences?.map(competence => (
                                                            <tr key={competence.id}>
                                                                <td style={{ ...styles.td, width: '70%' }}>
                                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                                        <div>
                                                                            <span>{sub_domain.position}.{competence.position} {getTranslation('name', competence)}</span>
                                                                            {/* -------------------- POSITIONED COMPETENCES -------------------- */}
                                                                            {state.validated_competences.some(c => c.id === competence.id && !c.locked) && (
                                                                                <>
                                                                                    <br />
                                                                                    <span style={{ color: cleaColor, fontStyle: 'italic' }}>
                                                                                        {t('competence.positioned')}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                            {/* -------------------- VALIDATED COMPETENCES -------------------- */}
                                                                            {state.validated_competences.some(c => c.id === competence.id && c.locked) && (
                                                                                <>
                                                                                    <br />
                                                                                    <span style={{ color: cleaColor, fontStyle: 'italic' }}>
                                                                                        {t('competence.validated')}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <Checkbox
                                                                            checked={state.clea_competences.some(c => c.id === competence.id)}
                                                                            onChange={() => onCheckedCompetence(competence.id)}
                                                                            disabled={state.validated_competences.some(c => c.id === competence.id && c.locked)}
                                                                            color="default"
                                                                        />
                                                                    </Grid>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </Fragment>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <span>2 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button
                        onClick={onValidate}
                        variant="contained"
                        style={{ backgroundColor: cleaColor, color: 'white' }}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CleaEvaluationFormPage2;
