import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Accordion, AccordionSummary, Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd, ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getTranslation, servicesColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateLearning from './create-learning';
import UpdateLearning from './update-learning';
import DeleteLearning from './delete-learning';
import Competences from '../competences';
import Knowledges from '../knowledges';
import Skills from '../skills';
import CleaCompetences from '../clea-competences';

// Style
import style from 'style';

// ---------------------------------------- LEARNINGS ---------------------------------------- \\
const Learnings = ({ unit }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        learnings: unit?.learning_outcomes || [],
        learning: null,
        expanded: null,
        openCreateLearning: false,
        openUpdateLearning: false,
        openDeleteLearning: false,
    });

    function handleOpenModal(event, open, learning) {
        event.stopPropagation();
        setState({ ...state, [open]: true, learning });
    }

    return (
        <>
            {/* ---------------------- LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: servicesColor2, padding: '0px 48px 0px 10px' }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={8} style={style.p10}>
                            <span>{t('learning.outcome')}</span>
                        </Grid>
                        <Grid item xs={4} style={{ padding: 10, textAlign: 'center' }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
                        <span>{t('learning.outcomes')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- LEARNING LIST ---------------------- */}
            <div>
            {state.learnings.length > 0 ? (
                state.learnings.map(learning => (
                    <Accordion
                        key={learning.id}
                        expanded={learning.id === state.expanded}
                        onChange={() => setState({ ...state, expanded: learning.id === state.expanded ? null : learning.id })}
                    >
                        <AccordionSummary
                            expandIcon={<IconExpand />}
                            classes={{ content: 'panel-summary' }}
                            style={style.p0_10}
                        >
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={8} style={{ padding: 10 }}>
                                    <span style={style.fwB}>
                                        {learning.position} {getTranslation('name', learning)}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ padding: 10, textAlign: 'center' }}>
                                    <IconButton
                                        onClick={e => handleOpenModal(e, 'openUpdateLearning', learning)}
                                        style={{ padding: 6 }}
                                    >
                                        <IconEdit />
                                    </IconButton>
                                    <IconButton
                                        onClick={e => handleOpenModal(e, 'openDeleteLearning', learning)}
                                        style={{ padding: 6 }}
                                    >
                                        <IconDelete />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider />
                        <div style={{ padding: 24 }}>
                            {(state.expanded === learning.id) && (
                                <>
                                    <Competences unitId={unit.id} learning={learning} />
                                    <Knowledges unitId={unit.id} learning={learning} />
                                    <Skills unitId={unit.id} learning={learning} />
                                    <CleaCompetences learning={learning} />
                                </>
                            )}
                        </div>
                    </Accordion>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            </div>
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateLearning: true })}
                    className="fab"
                    style={{ backgroundColor: servicesColor2 }}
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('learning.outcome.add')}
                </span>
            </div>
            {/* ---------------------- CREATE LEARNING ---------------------- */}
            {state.openCreateLearning && (
                <CreateLearning
                    onClose={() => setState({ ...state, openCreateLearning: false })}
                    refetch={learnings => setState({ ...state, openCreateLearning: false, learnings })}
                    learnings={state.learnings}
                    unitId={unit.id}
                />
            )}
            {/* ---------------------- UPDATE LEARNING ---------------------- */}
            {state.openUpdateLearning && (
                <UpdateLearning
                    onClose={() => setState({ ...state, openUpdateLearning: false })}
                    refetch={learnings => setState({ ...state, openUpdateLearning: false, learnings })}
                    learnings={state.learnings}
                    learning={state.learning}
                    unitId={unit.id}
                />
            )}
            {/* ---------------------- DELETE LEARNING ---------------------- */}
            {state.openDeleteLearning && (
                <DeleteLearning
                    onClose={() => setState({ ...state, openDeleteLearning: false })}
                    refetch={learnings => setState({ ...state, openDeleteLearning: false, learnings })}
                    learnings={state.learnings}
                    learning={state.learning}
                    unitId={unit.id}
                />
            )}
        </>
    );
};

export default Learnings;
