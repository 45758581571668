import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { RadioGroup, Grid, Divider, FormControlLabel, Radio, MenuItem } from '@material-ui/core';

// Services
import { getSkillbases } from 'services/requests';
import { getTranslation } from 'services/helpers';
import { roles, languages, toolTypes } from 'services/constants';

// Components
import SelectOutlined from 'components/items/select-outlined';
import InputTextGrid from 'components/items/input-text-grid';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// ---------------------------------------- TOOL FORM ---------------------------------------- \\
const ToolForm = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleDescriptions(event, code) {
        let descriptions = state.descriptions;
        descriptions[code] = event.target.value;
        setState({ ...state, descriptions });
    }

    return (
        <>
            {/* -------------------- TYPE -------------------- */}
            <Grid container alignItems="center" style={style.m5}>
                <Grid item xs={4}>
                    <b className="input-title">{t('type')}</b>
                </Grid>
                <Grid item xs={8}>
                    <SelectOutlined
                        value={state.tool_type_id}
                        onChange={e => setState({ ...state, tool_type_id: e.target.value })}
                    >
                        {toolTypes.map(toolType => (
                            <MenuItem key={toolType.id} value={toolType.id}>
                                <span>{t(toolType.label)}</span>
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- ROLES ACCESS -------------------- */}
            <div style={{ marginTop: 24 }}>
                <b className="input-title">{t('roles.access')}</b>
                <Select
                    placeholder={t('roles.access')}
                    value={state.roles}
                    onChange={value => setState({ ...state, roles: value })}
                    options={roles.map(role => ({ label: t(role.label), value: role.id }))}
                    isMulti
                />
            </div>
            {/* -------------------- SKILLBASES LINKED -------------------- */}
            <div style={{ marginTop: 24 }}>
                <b className="input-title">{t('skillbases.linked')}</b>
                <SelectSkillbases
                    skillbases={state.skillbases}
                    setSkillbases={skillbases => setState({ ...state, skillbases })}
                />
            </div>
            {/* -------------------- ACTIVE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 12 }}>
                <Grid item xs={4}>
                    <b className="input-title">{t('active')}</b>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        row
                        value={state.active}
                        onChange={e => setState({ ...state, active: e.target.value })}
                    >
                        <FormControlLabel
                            value="1"
                            label={t('yes')}
                            control={<Radio color="primary" />}
                        />
                        <FormControlLabel
                            value="0"
                            label={t('no')}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- NAME -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('name')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.names[language.code]}
                    onChange={e => handleNames(e, language.code)}
                />
            ))}
            <Divider style={style.m15_0} />
            {/* -------------------- DESCRIPTION -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('description')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.descriptions[language.code]}
                    onChange={e => handleDescriptions(e, language.code)}
                />
            ))}
        </>
    );
};

// ---------------------------------------- SELECT SKILLBASES --------------------------------------- \\
const SelectSkillbases = ({ skillbases, setSkillbases }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getSkillbases().then(res => {
            if (res?.status === 200) {
                setOptions(res.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <Select
            placeholder={t('skillbases')}
            value={skillbases}
            onChange={value => setSkillbases(value)}
            options={options}
            isMulti
        />
    );
};

export default ToolForm;
