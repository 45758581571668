import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider, IconButton } from '@material-ui/core';
import { AssignmentTurnedIn as IconMobipass, Search as IconSearch } from '@material-ui/icons';

// Services
import useMediaQueries from 'services/media';
import { getGlobalStatistics } from 'services/requests';
import { mobipassColor, getTranslation, getStatisticMonth } from 'services/helpers';

// Components
import { EvaluationsChartBarExport, EvaluationsChartPieExport, EvaluationsChartBarPDFExport } from './evaluations-export';
import ChartBar from 'components/items/chart-bar';
import ChartPie from 'components/items/chart-pie';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import DatePicker from 'components/items/date-picker';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

const styles = {
    border: {
        border: '1px solid lightgray',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        marginBottom: 25,
    },
    legend: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
};

// -------------------------------------- EVALUATIONS CHART --------------------------------------- \\
const EvaluationsChart = ({ company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null,
        dataBar: null,
        dataPie: null,
        fillPie: null,
        start_date: moment().subtract(5, 'months'),
        end_date: moment(),
        total_learners: 0,
        total_evaluated_learning_outcomes: 0,
        total_evaluated_learners: 0,
        total_validated_learning_outcomes: 0,
    });
 
    const onSearch = () => {
        if (state.start_date == null || state.end_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.start_date > state.end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else if (state.end_date > moment()) {
            enqueueSnackbar(t('date.selected.compared.date.today'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            getGlobalStatistics({
                module: 'mobipass',
                start_date: moment(state.start_date).format('YYYY-MM-DD'),
                end_date: moment(state.end_date).format('YYYY-MM-DD'),
                company_id: company?.id || null,
            }).then(res => {
                if (res?.status === 200) {

                    let res_months = res.data.data.months;
                    const dataBar = Object.entries(res_months).map(([key, value]) => ({
                        'month': getStatisticMonth(t, res_months, key),
                        [t('assesed.learners')]: value.nb_evaluated_learners,
                        [t('evaluated.learning.outcomes')]: value.nb_evaluated_learning_outcomes,
                        [t('validated.learning.outcomes')]: value.nb_validated_learning_outcomes,
                    }));

                    const dataPie = res.data.data.sectors.map(sector => ({
                        'id': getTranslation('name', sector),
                        'label': getTranslation('name', sector),
                        'value': sector.evaluations_count,
                    }));

                    const fillPie = res.data.data.sectors.map(sector => ({
                        match: { id: getTranslation('name', sector) },
                    }));

                    setState({
                        ...state,
                        loading: false,
                        data: res.data.data,
                        dataBar,
                        dataPie,
                        fillPie,
                        total_learners: res.data.data.total_learners,
                        total_evaluated_learning_outcomes: res.data.data.total_evaluated_learning_outcomes,
                        total_evaluated_learners: res.data.data.total_evaluated_learners,
                        total_validated_learning_outcomes: res.data.data.total_validated_learning_outcomes,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <Grid style={styles.border}>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justifyContent="center" alignItems="center"
                style={{ backgroundColor: mobipassColor, ...style.p10, ...style.cW }}
            >
                <IconMobipass />
                <span style={{ ...style.ml5, ...style.ttU }}>
                    {t('evaluations')}
                </span>
            </Grid>
            {/* -------------------- PERIOD -------------------- */}
            <Grid container alignItems="center">
                <Grid
                    item xs={12} md={8}
                    style={{ padding: 10 }}
                >
                    <b className="input-title">{t('period')} : </b>
                    <DatePicker
                        placeholder={t('start.date')}
                        value={state.start_date}
                        onChange={start_date => setState({ ...state, start_date })}
                        fullWidth={false}
                        outlined
                    />
                    <span> - </span>
                    <DatePicker
                        placeholder={t('end.date')}
                        value={state.end_date}
                        onChange={end_date => setState({ ...state, end_date })}
                        fullWidth={false}
                        outlined
                    />
                    <Tooltip
                        title={t('search')}
                        item={(
                            <IconButton style={{ padding: 8 }} onClick={onSearch}>
                                <IconSearch />
                            </IconButton>
                        )}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ padding: 10 }}
                >
                    <EvaluationsChartBarExport data={state.data} />
                    <EvaluationsChartBarPDFExport company={company} />
                </Grid>
            </Grid>
            {/* -------------------- CHART BAR -------------------- */}
            <ChartBar
                id="evaluations-chart-bar"
                data={state.dataBar}
                keys={[t('learners'), t('assesed.learners'), t('evaluated.learning.outcomes'), t('validated.learning.outcomes')]}
                indexBy="month"
            />
            {/* -------------------- TOTALS -------------------- */}
            <Grid container alignItems="center" style={style.p0_25}>
                <Grid item xs="auto" style={style.p10}>
                    <span>{t('totals')} :</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#e8c1a0' }} />
                    <span>{state.total_evaluated_learners} {t('assesed.learners')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f47560' }} />
                    <span>{state.total_evaluated_learning_outcomes} {t('evaluated.learning.outcomes')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f1e15b' }} />
                    <span>{state.total_validated_learning_outcomes} {t('validated.learning.outcomes')}</span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- EVALUATIONS SORT BY SECTORS -------------------- */}
            <Grid container>
                <Grid item xs={12} md={6} style={style.p10}>
                    <span>{t('number.evaluated.learning.outcomes.per.sector')} :</span>
                </Grid>
                <Grid item xs={12} md={6} container justifyContent={mediaMD ? 'flex-end' : 'center'} style={style.p10}>
                    <EvaluationsChartPieExport data={state.data} />
                </Grid>
            </Grid>
            <ChartPie
                id="evaluations-chart-pie"
                data={state.dataPie}
                fill={state.fillPie}
            />
            {/* -------------------- HIDDEN CHARTS FOR PDF EXPORT -------------------- */}
            <div style={{ height:0, width:0, overflow: 'hidden' }}>
                <div id="chart-to-export" style={{ width: 1200, height: 1000 }}>
                    <ChartBar
                        id="evaluations-chart-bar"
                        data={state.dataBar}
                        keys={[t('learners'), t('assesed.learners'), t('evaluated.learning.outcomes'), t('validated.learning.outcomes')]}
                        indexBy="month"
                    />
                    {/* -------------------- TOTALS -------------------- */}
                    <Grid container alignItems="center" style={style.p0_25}>
                        <Grid item xs="auto" style={style.p10}>
                            <span>{t('totals')} :</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#e8c1a0' }} />
                            <span>{state.total_evaluated_learners} {t('assesed.learners')}</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#f47560' }} />
                            <span>{state.total_evaluated_learning_outcomes} {t('evaluated.learning.outcomes')}</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#f1e15b' }} />
                            <span>{state.total_validated_learning_outcomes} {t('validated.learning.outcomes')}</span>
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- EVALUATIONS SORT BY SECTORS -------------------- */}
                    <div style={style.p10}>
                        <span>{t('number.evaluated.learning.outcomes.per.sector')} :</span>
                    </div>
                    <ChartPie
                        id="evaluations-chart-pie"
                        data={state.dataPie}
                        fill={state.fillPie}
                    />
                </div>
            </div>
        </Grid>
    );
};

export default EvaluationsChart;
