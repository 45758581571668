import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getHardPositionsForUser, getSkillbase } from 'services/requests';

// Components
import ExcelExport from './excel-export';
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- HARD POSITIONS EXPORT ---------------------------------------------- \\
const HardPositionsExport = ({ learner, data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: 'Téléchargement en cours...0%',
        start: false,
    });

    const getAllSkillbases = (hard_positions, skillbase_ids) => {
        let skillbases = [];

        skillbase_ids.forEach((id, index) => {
            getSkillbase(id).then(res => {
                if (res?.status === 200) {
                    skillbases.push(res.data.data.skillbase);

                    if ((index + 1) === skillbase_ids.length) {
                        ExcelExport(t, learner, skillbases, hard_positions, state, setState, enqueueSnackbar);
                    }
                }
                else {
                    console.log(res);
                    enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
                    setState({ ...state, start: false });
                }
            });
        });
    };

    const getAllHardPositionsToExport = (hard_positions, offset) => {
        getHardPositionsForUser(learner.id, {
            offset: offset,
            limit: data.limit,
            position_date: data.position_date ? moment(data.position_date).format('YYYY-MM-DD') : null,
            skillbase_id: data.skillbase_id,
            assessor: data.assessor,
        }).then(res => {
            if (res?.status === 200) {

                let res_hard_positions = res.data.data.hard_positions;
                hard_positions = hard_positions.concat(res_hard_positions);

                setState({
                    ...state,
                    message: 'Téléchargement en cours...' + Math.round((100 * hard_positions.length) / data.total_count) + '%',
                });

                if (res_hard_positions.length < data.limit) {
                    let skillbase_ids = [];
                    hard_positions.forEach(hard_position => {
                        skillbase_ids.push(hard_position.skillbase?.id);
                    });
                    getAllSkillbases(hard_positions, [...new Set(skillbase_ids)]);
                }
                else {
                    getAllHardPositionsToExport(hard_positions, offset + data.limit);
                }
            }
            else {
                console.log(res);
                enqueueSnackbar('Échec du téléchargement', { variant: 'error' });
                setState({ ...state, start: false });
            }
        });
    };

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllHardPositionsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')} 
                item={
                    <IconButton
                        onClick={() => setState({ ...state, start: true })}
                        disabled={data.hard_positions.length === 0 || state.start}
                        className="list-btn"
                    >
                        <DownloadIcon />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default HardPositionsExport;
