import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getCompanyList, createFormationModel  } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FormationModelFormPage1 from './formation-model-form/formation-model-form-page-1';
import FormationModelFormPage2 from './formation-model-form/formation-model-form-page-2';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- CREATE FORMATION MODEL ---------------------------------------- \\
const CreateFormationModel = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loadingResources: true,
        errorResources: false,
        loading: false,
        actionType: 'CREATE',
        page: 1,

        // Page 1
        name: '',
        companies: [],
        company_id: null,
        skillbases: [],
        skillbase_id: null,

        // Page 2
        loadingSkillbase: true,
        errorSkillbase: false,
        skillbase: null,
        descriptors: {},
        learning: null,
        openLearningModal: false,
    });

    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setState ({
                    ...state,
                    loadingResources: false,
                    companies: res.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                });
            }
            else {
                console.log(res);
                setState(state => ({ ...state, loadingResources: false, errorResources: true }));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading) {

            let competences = [], knowledges = [], skills = [];
            Object.values(state.descriptors).forEach(descriptor => {
                competences = competences.concat(descriptor.filter(d => d.includes('competence_')));
                knowledges = knowledges.concat(descriptor.filter(d => d.includes('knowledge_')));
                skills = skills.concat(descriptor.filter(d => d.includes('skill_')));
            });

            competences = competences.map(competence => competence.split('competence_')[1]);
            knowledges = knowledges.map(knowledge => knowledge.split('knowledge_')[1]);
            skills = skills.map(skill => skill.split('skill_')[1]);

            createFormationModel({
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
                competence_ids: competences,
                knowledge_ids: knowledges,
                skill_ids: skills,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobitrain/formation-models');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar" style={{flexDirection: mediaMD ? "row" : "column-reverse"}}>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobitrain/formation-models`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('formation.model.add')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <FormationModelFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <FormationModelFormPage2 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CreateFormationModel;
