import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Tools to Create PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// ---------------------------------------- PDF FORMATION BAR EXPORT ---------------------------------------- \\
const FormationsChartBarPDFExport = ({ company }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const createFormationPdfFile = () => {

        // Create new PDF
        const pdf = new jsPDF('landscape');

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text(t('activity.report.trainings'), (pdf.internal.pageSize.width / 2), 10, { align: 'center' });

        if (company != null) {
            pdf.setFontSize(9);
            pdf.setFont('helvetica', 'bold');
            pdf.text(company?.name, (pdf.internal.pageSize.width / 2), 15, { align: 'center' });
        }

        // Cover Page
        const formations_chart = document.getElementById('formations-chart-pdf-export');

        html2canvas(formations_chart, { allowTaint: true, useCORS: true, scrollY: -window.scrollY }).then(canvas => {

            // Add Cover Page to the PDF
            const img = canvas.toDataURL('image/png');
            pdf.addImage(img, 'JPEG', 10, 50, 280, 180 , '', 'FAST' );
            
            // Save the PDF
            if (company != null) {
                pdf.save(company?.name + ' - ' + t('trainings') + ' - ' + t('statistics') + '.pdf');
            }
            else {
                pdf.save(t('trainings') + ' - ' + t('statistics') + '.pdf');
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (loading) {
            createFormationPdfFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Button
            onClick={() => setLoading(true)}
            disabled={loading}
            variant="contained"
        >
            PDF
            {loading ? (
                <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
            ) : (
                <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
            )}
        </Button>
    );
};

export default FormationsChartBarPDFExport;
