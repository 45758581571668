import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';
import { CheckCircle as IconCheckCircle, Cancel as IconCancel, WarningRounded as IconWarning, Comment as IconComment } from '@material-ui/icons';

// Services
import { mobipassColor, softskillsColor } from 'services/helpers';
import { getEvaluation, setPortfolios, updateEvaluation } from 'services/requests';
import { getSoftskillsAccess } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import LearningsEvaluation from './evaluation-learnings';
import SoftSkillsEvaluation from './evaluation-softskills';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputText from 'components/items/input-text';

// Style
import style from 'style';

// ---------------------------------------- RATING EVALUATION ---------------------------------------- \\
const RatingEvaluation = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const match = useRouteMatch();

    const [state, setState] = useState({
        loading: true,
        error: false,
        loaded: false,
        menuSelected: 0,
        index: 0,

        // Data
        evaluation: null,
        users: null,

        // Learnings Evalution
        learnings: null,
        portfolios: null,
        learning: null,
        oneDisplayed: false,

        // Soft skills Evaluation
        soft_units: null,
        soft_portfolios: null,
        soft_unit: null,

        // Modals
        openLearningModal: false,
        openCommentModal: false,
        openConfirmSaveModal: false,
        openSoftskillModal: false,
    });

    function handleRefetchComment(comment) {
        let updatedEvaluation = state.evaluation;
        updatedEvaluation.comment = comment;
        setState({ ...state, openCommentModal: false, updatedEvaluation });
    }

    useEffect(() => {
        if (state.loading) {
            getEvaluation(match.params.evaluationId).then(res => {
                if (res?.status === 200) {
                    const evaluation = res.data.data.evaluation;
                    setState({
                        ...state,
                        loading: false,
                        loaded: true,
                        evaluation,
                        users: evaluation.users,
                        soft_units: evaluation.soft_units,
                        soft_portfolios: evaluation.soft_portfolios,
                        learnings: evaluation.learning_outcomes,
                        portfolios: evaluation.portfolios.map(p => Object.assign({}, p, { validated: p.validated === 0 ? false : true })),
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* ------------------ TOOLBAR ------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/mobipass/evaluation')}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justifyContent="center"
                    style={{ margin: mediaMD ? null : '10px 0px' }}
                >
                    <span style={{ ...style.fwB, ...style.ttU }}>
                        {state.evaluation && state.evaluation.name}
                    </span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button
                        onClick={() => setState({ ...state, openConfirmSaveModal: true })}
                        variant="contained"
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            {/* ------------------ NOTICE & COMMENT ------------------- */}
            <Grid container alignItems="center" className="simple-container" style={style.m10_0}>
                <Grid
                    item xs={12} md={6}
                    container direction="column" justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <div>
                        <IconCancel style={{ ...style.mr5, color: '#DA6236' }} />
                        <span>{t('learning.outcome.failed')}</span>
                    </div>
                    <div>
                        <IconCheckCircle style={{ ...style.mr5, color: 'green' }} />
                        <span>{t('learning.outcome.validated')}</span>
                    </div>
                    <div>
                        <IconWarning style={style.mr5} />
                        <span style={style.fwB}>{t('state.click.on')} !</span>
                    </div>
                </Grid>
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button onClick={() => setState({ ...state, openCommentModal: true })} style={style.ttN}>
                        <IconComment style={{ marginRight: 5, color: 'gray' }} />
                        {t('comment.add')}
                    </Button>
                </Grid>
            </Grid>
            {/* ------------------ RATING ------------------- */}
            {state.evaluation.soft_units.length > 0 && state.evaluation.learning_outcomes.length > 0 && (
                <div className="full-container" style={{ paddingBottom: 24 }}>
                    {/* ------------------ SWITCH ------------------- */}
                    <Grid container>
                        <Grid item xs={false} md={3} />
                        <Grid
                            item xs={6} md={3}
                            container justifyContent="center"
                            style={{ paddingRight: 5 }}
                        >
                            <div
                                className="evaluation-rating-button"
                                onClick={() => setState({ ...state, menuSelected: 0 })}
                                style={{
                                    backgroundColor: mobipassColor,
                                    opacity: state.menuSelected === 0 ? 1 : null,
                                }}
                            >
                                <span style={{ ...style.cW, ...style.ttU }}>
                                    {t('learning.outcomes')}
                                </span>
                            </div>
                        </Grid>
                        {getSoftskillsAccess() === '1' && (
                            <Grid
                                item xs={6} md={3}
                                container justifyContent="center"
                                style={{ paddingLeft: 5 }}
                            >
                                <div
                                    className="evaluation-rating-button"
                                    onClick={() => setState({ ...state, menuSelected: 1 })}
                                    style={{
                                        backgroundColor: softskillsColor,
                                        opacity: state.menuSelected === 1 ? 1 : null,
                                    }}
                                >
                                    <span style={{ ...style.cW, ...style.ttU }}>
                                        {t('softskills')}
                                    </span>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    {/* ------------------ MENU ------------------- */}
                    {state.menuSelected === 0 && (
                        <LearningsEvaluation state={state} setState={setState} solo={false} />
                    )}
                    {(getSoftskillsAccess() === '1'&& state.menuSelected === 1) && (
                        <SoftSkillsEvaluation state={state} setState={setState} solo={false}/>
                    )}
                </div>
            )}
            {state.evaluation.learning_outcomes.length > 0 && state.evaluation.soft_units.length === 0 && (
                <div className="full-container" style={{ paddingBottom: 24 }}>
                    <Grid
                        container justifyContent="center" alignItems="center"
                        style={{ backgroundColor: mobipassColor, ...style.p10, ...style.cW, ...style.mt15 }}
                    >
                        <span style={{ ...style.ml5, ...style.ttU }}>
                            {t('learning.outcomes')}
                        </span>
                    </Grid>
                    <LearningsEvaluation state={state} setState={setState} solo={true} />
                </div>
            )}
            {state.evaluation.soft_units.length > 0 && state.evaluation.learning_outcomes.length === 0 && (
                <div className="full-container" style={{ paddingBottom: 24 }}>
                    <Grid
                        container justifyContent="center" alignItems="center"
                        style={{ backgroundColor: softskillsColor, ...style.p10, ...style.cW, ...style.mt15 }}
                    >
                        <span style={{ ...style.ml5, ...style.ttU }}>
                            {t('softskills')}
                        </span>
                    </Grid>
                    <SoftSkillsEvaluation state={state} setState={setState} solo={true}/>
                </div>
            )}
            {/* -------------------- COMMENT MODAL -------------------- */}
            {state.openCommentModal && (
                <CommentModal
                    open={state.openCommentModal}
                    onClose={() => setState({ ...state, openCommentModal: false })}
                    refetch={comment => handleRefetchComment(comment)}
                    evaluation={state.evaluation}
                />
            )}
            {/* -------------------- CONFIRM SAVE MODAL -------------------- */}
            {state.openConfirmSaveModal && (
                <ConfirmSaveModal
                    open={state.openConfirmSaveModal}
                    onClose={() => setState({ ...state, openConfirmSaveModal: false })}
                    state={state}
                />
            )}
        </>
    );
};

// ------------------------------------------ COMMENT MODAL ------------------------------------------ \\
const CommentModal = ({ onClose, refetch, evaluation }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        comment: evaluation.comment || '',
    });

    useEffect(() => {
        if (state.loading) {
            updateEvaluation(evaluation.id, { comment: state.comment }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(state.comment);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.taC, ...style.pb20 }}>
                        <p className="dialog-title">
                            {t('comment.add')}
                        </p>
                        <InputText
                            value={state.comment}
                            onChange={e => setState({ ...state, comment: e.target.value })}
                            multiline
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained" color="primary">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------------- CONFIRM SAVE MODAL ---------------------------------------- \\
const ConfirmSaveModal = ({ onClose, state }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            setPortfolios(state.evaluation.id, {
                portfolios: Object.fromEntries(state.portfolios.map(p => [p.id, p.validated])),
                soft_portfolios: Object.fromEntries(state.soft_portfolios.map(sp => [sp.id, sp.soft_level_id])),
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobipass/evaluation');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {state.evaluation.name}
                        </p>
                        <span>{t('results.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained" color="primary">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default RatingEvaluation;
