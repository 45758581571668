import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// File-Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { deleteCertification, getCertification } from 'services/requests';
import { getFormattedDate, mobipassColor, fileUrl, getTranslation } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// --------------------------------------- DETAILS CERTIFICATION ---------------------------------------- \\
export const DetailsCertification = ({ onClose, certification }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        certification: null,
    });

    useEffect(() => {
        if (state.loading) {
            getCertification(certification.id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, certification: res.data.data.certification });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {t('certification')}
                </p>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobipassColor }}>
                            {t('date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getFormattedDate(certification.certification_date)}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobipassColor }}>
                            {t('assessor')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{certification.assessor_name}</span>
                        &nbsp;
                        {certification.company_name && (
                            <span>({certification.company_name})</span>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobipassColor }}>
                            {t('learner')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{certification.user.full_name}</span>
                        &nbsp;
                        {certification.user.company && (
                            <span>({certification.user.company.name})</span>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobipassColor }}>
                            {t('document')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {certification.document ? (
                            <Button
                                onClick={() => saveAs(fileUrl + certification.document, t('certification') + ' - ' + getFormattedDate(certification.certification_date))}
                                variant="contained" color="primary"
                                style={style.ttN}
                            >
                                {t('download.document')}
                                <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                            </Button>
                        ) : (
                            <span>{t('none')}</span>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobipassColor }}>
                            {t('skillbase')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getTranslation('name', certification.skillbase)}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobipassColor }}>
                            {t('units')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {certification.units.map(unit => (
                            <Fragment key={unit.id}>
                                <span>{unit.position}. {getTranslation('name', unit)}</span>
                                <br />
                            </Fragment>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog> 
    );
};

// ---------------------------------------- DELETE CERTIFICATION ---------------------------------------- \\
export const DeleteCertification = ({ onClose, refetch, certification }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteCertification(certification.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {t('certification')}
                            <br />
                            {getFormattedDate(certification.certification_date)}
                        </p>
                        <span>{t('certification.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
