import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getTranslation, softskillsColor2, softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------------- SOFT FORMATION FORM PAGE 3 ---------------------------------------- \\
const SoftFormationFormPage3 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const navigateToPage4 = () => {
        if (state.soft_level == null) {
            enqueueSnackbar(t('please.select.soft.level'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 4 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <p>
                    <b className="input-title">{t('select.soft.level')}</b><FieldRequired />
                </p>
                {/* -------------------- INFORMATIONS -------------------- */}
                <Grid>
                    <span>
                        <b>{t('information')}</b>
                        <br />
                        {t('positioning.information')}
                    </span>
                </Grid>
                {/* -------------------- SOFT CATEGORY -------------------- */}
                <Fragment>
                    <Grid
                        container justifyContent="center"
                        style={{ ...style.mt25, ...style.p10, backgroundColor: softskillsColor }}
                    >
                        <span style={{ ...style.fwB, ...style.ttU, ...style.cW }}>
                            {state.soft_category.position}. {getTranslation('name', state.soft_category)}
                        </span>
                    </Grid>
                    {/* -------------------- SOFT SKILL -------------------- */}
                    <div className="soft-level-table">
                        <table style={style.w100}>
                            <tbody>
                                <tr>
                                    <td
                                        className="soft-level-td list-item-hover"
                                        style={style.taC}
                                    >
                                        <span style={style.fwB}>
                                            {state.soft_category.position}.{state.softskill.position}  {getTranslation('name', state.softskill)}
                                        </span>
                                    </td>
                                    {state.softskill.soft_levels.map(soft_level => (
                                        <td
                                            key={soft_level.id}
                                            className="soft-level-td"
                                            onClick={() => setState({...state, soft_level })}
                                        >
                                            <Grid
                                                container
                                                className="soft-level"
                                                style={{
                                                    backgroundColor: state.soft_level?.id === soft_level.id
                                                    ? softskillsColor2
                                                    : null
                                                }}
                                            >
                                                <p style={{ ...style.taC, ...style.w100, ...style.fwB }}>
                                                    {t('level')}&nbsp;{soft_level.level}
                                                    <br />
                                                    {soft_level.level === 1 && t('remember.and.understand')}
                                                    {soft_level.level === 2 && t('apply')}
                                                    {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                    {soft_level.level === 4 && t('create')}
                                                </p>
                                                <p className="soft-level-definition">
                                                    {getTranslation('definition', soft_level)}
                                                </p>
                                            </Grid>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>3 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage4} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default SoftFormationFormPage3;
