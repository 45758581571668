import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Button } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// ------------------------------------- FORMATION FORM PAGE 2 ------------------------------------- \\
const FormationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage3 = () => {
        if (state.trainers == null || state.trainers.length === 0) {
            enqueueSnackbar(t('trainers.select.one'), { variant: 'warning' });
        }
        else if (state.learners == null || state.learners.length === 0) {
            enqueueSnackbar(t('learners.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- TRAINERS -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('trainers.select')}<FieldRequired />
                            </b>
                            <SelectTrainers
                                state={state}
                                setState={setState}
                            />
                        </div>
                        {/* -------------------- LEARNERS -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">
                                {t('learners.select')}<FieldRequired />
                            </b>
                            <SelectLearners
                                state={state}
                                setState={setState}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    style={{ textAlign: 'center' }}
                >
                    <span>2 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ---------------------------------------- SELECT TRAINERS ---------------------------------------- \\
const SelectTrainers = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchMembers) {
            if (state.trainers_company_id) {
                getCompanyMembers(state.trainers_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({ ...state, trainerOptions: res.data.data.assessors.map(l => ({ value: l.id, label: l.full_name })) });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, trainerOptions: [] });
                    }
                });
            }
            else {
                setState({ ...state, trainerOptions: [] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.trainers_company_id]);

    return (
        <>
            {/* -------------------- COMPANY -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('company')}
                </span>
                <Select
                    placeholder={t('company')}
                    value={state.trainers_company_id ? state.companies.find(c => c.value === state.trainers_company_id) : null}
                    onChange={company => setState({ ...state, trainers_company_id: company ? company.value : null, authorizedRefetchMembers: true })}
                    options={state.companies}
                />
            </div>
            {/* -------------------- TRAINERS -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('trainers')}
                </span>
                <Select
                    placeholder={t('trainers')}
                    value={state.trainers}
                    onChange={trainers => setState({ ...state, trainers })}
                    options={state.trainerOptions}
                    isMulti
                />
            </div>
        </>
    );
};

// ---------------------------------------- SELECT LEARNERS ---------------------------------------- \\
const SelectLearners = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchMembers) {
            if (state.learners_company_id) {
                getCompanyMembers(state.learners_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({ ...state, learnerOptions: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })) });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, learnerOptions: [] });
                    }
                });
            }
            else {
                setState({ ...state, learnerOptions: [] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.learners_company_id]);

    return (
        <>
            {/* -------------------- COMPANY -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('company')}
                </span>
                <Select
                    placeholder={t('company')}
                    value={state.learners_company_id ? state.companies.find(c => c.value === state.learners_company_id) : null}
                    onChange={company => setState({ ...state, learners_company_id: company ? company.value : null, authorizedRefetchMembers: true })}
                    options={state.companies}
                />
            </div>
            {/* -------------------- LEARNERS -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('learners')}
                </span>
                <Select
                    placeholder={t('learners')}
                    value={state.learners}
                    onChange={learners => setState({ ...state, learners })}
                    options={state.learnerOptions}
                    isMulti
                />
            </div>
        </>
    );
};

export default FormationFormPage2;
