import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/items/input-text';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// ------------------------------------ CLEA FORMATION FORM PAGE 1 --------------------------------- \\
const CleaFormationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const navigateToPage2 = () => {
        if (state.name === ''
            || state.company_id == null
            || state.clea_formation_type_id == null
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- NAME -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('clea.training.name')}</b><FieldRequired />
                            <InputText
                                placeholder={t('clea.training.name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                            />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('company')}</b><FieldRequired />
                            <Select
                                placeholder={t('company')}
                                value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- CLEA FORMATION TYPE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('clea.training.type')}</b><FieldRequired />
                            <Select
                                placeholder={t('clea.training.type')}
                                value={state.clea_formation_type_id ? state.clea_formation_types.find(c => c.value === state.clea_formation_type_id) : null}
                                onChange={clea_formation_type => setState({ ...state, clea_formation_type_id: clea_formation_type ? clea_formation_type.value : null })}
                                options={state.clea_formation_types}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CleaFormationFormPage1;
