import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Divider, Grid, IconButton, MenuItem } from '@material-ui/core';
import {
    Create as IconModify, Delete as IconDelete, Assignment as IconDetail,
    Cancel as IconCancel, Search as IconSearch
} from '@material-ui/icons';

// Services
import { getNews } from 'services/requests';
import { servicesColor, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateNews from './create-news';
import UpdateNews from './update-news';
import { DetailsNews, DeleteNews } from './news-modals';
import Pagination from 'components/items/pagination';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';
import InputFilter from 'components/items/input-filter';
import SelectOutlined from 'components/items/select-outlined';
import DatePicker from 'components/items/date-picker';

// Style
import style from 'style';

// ------------------------------------------ NEWS --------------------------------------------- \\
const NewsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <News />
            </Route>
            <Route exact path={`${match.path}/create-news`}>
                <CreateNews />
            </Route>
            <Route exact path={`${match.path}/:newsId`}>
                <UpdateNews />
            </Route>
        </Switch>
    );
};

// ------------------------------------------ NEWS --------------------------------------------- \\
const News = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        news: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        openCreateFormationModel: false,
        search: '',
        title: '',
        description: '',
        publication_date: null,
        status: -1,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            search: '',
            title: '',
            description: '',
            publication_date: null,
            status: -1,
        });
    };

    useEffect(() => {
        if (state.loading) {
            getNews({
                offset: state.offset,
                limit: state.limit,
                search: state.search,
                title: state.title,
                description: state.description,
                publication_date: state.publication_date ? moment(state.publication_date).format('YYYY-MM-DD') : null,
                status: state.status,
            }).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        news: res.data.data.news,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* ------------------------ TITLE & TOTAL COUNT ------------------------ */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('news')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button 
                        onClick={() => history.push('/management')}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button 
                        onClick={() => history.push(`${match.url}/create-news`)}
                        variant="contained"
                    >
                        {t('add')}
                    </Button>
                </Grid>
            </Grid>
            <div className="full-container">
                {/* --------------------------- LIST HEADERS ----------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('news.title')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('news.description')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('publication.date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('status')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid style={style.p10}>
                            <span style={style.cW}>{t('news')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('news.title')}
                                value={state.title}
                                onChange={e => setState({ ...state, title: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('news.description')}
                                value={state.description}
                                onChange={e => setState({ ...state, description: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('publication.date')}
                                value={state.publication_date}
                                onChange={publication_date => onSelectSearch('publication_date', publication_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.status}
                                onChange={e => onSelectSearch('status', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                <MenuItem value="0">
                                    <span>{t('rough')}</span>
                                </MenuItem>
                                <MenuItem value="1">
                                    <span>{t('published')}</span>
                                </MenuItem>
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- FORMATION MODEL LIST -------------------- */}
                <NewsList
                    news={state.news}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
        </>
    );
};

// ---------------------------------------- NEW LIST ------------------------------------------- \\
const NewsList = ({ news, loading, error, refetch }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch()

    const [state, setState] = useState({
        openDetailsActuality: false,
        openDeleteActuality: false,
        actuality: null,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {news.length > 0 ? (
                    news.map(actuality => (
                        <Fragment key={actuality.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{actuality.title}</span>
                                </Grid>
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{actuality.description}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(actuality.publication_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {actuality.status === 1 ? (
                                        <span>{t('published')}</span>
                                    ):(
                                        <span>{t('rough')}</span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                    {/* -------------------- DETAILS MODEL BUTTON -------------------- */}
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDetailsActuality: true, actuality })}
                                                className="list-btn"
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        }
                                    />
                                    {/* -------------------- UPDATE MODEL BUTTON -------------------- */}
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={() => history.push(`${match.path}/${actuality.id}`)}
                                                className="list-btn"
                                            >
                                                <IconModify />
                                            </IconButton>
                                        }
                                    />
                                    {/* -------------------- DELETE MODEL BUTTON -------------------- */}
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDeleteActuality: true, actuality })}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </> 
                )}
            </div>
            {/* -------------------- DETAILS NEWS -------------------- */}
            {state.openDetailsActuality && (
                <DetailsNews
                    onClose={() => setState({ ...state, openDetailsActuality: false })}
                    news={state.actuality}
                />
            )}
            {/* -------------------- DELETE NEWS -------------------- */}
            {state.openDeleteActuality && ( 
                <DeleteNews
                    onClose={() => setState({ ...state, openDeleteActuality: false })}
                    refetch={() => handleRefetch('openDeleteActuality')}
                    news={state.actuality}
                /> 
            )}
        </>
    );
};

export default NewsRouter;
