import React, { Fragment, useState, useEffect } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid, IconButton } from '@material-ui/core';
import { Delete as IconDelete, Create as IconEdit, Search as IconSearch, Cancel as IconCancel, Assignment as IconDetail } from '@material-ui/icons';

// Services
import { getContracts } from 'services/requests';
import useMediaQueries from 'services/media';
import { servicesColor, getFormattedDate } from 'services/helpers';

// Components
import ContractInfos from './contract-infos';
import Bills from '../bills';
import CreateContract from './contract-create';
import UpdateContract from './contract-update';
import { DeleteContract, DetailsContract } from './contract-modals';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// ----------------------------------------- ROUTER ----------------------------------------- \\
const ContractsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <Contracts />
            </Route>
            <Route path={`${match.path}/:contractId/bills`}>
                <ContractInfos />
                <Bills />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- CONTRACTS --------------------------------------- \\
const Contracts = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const match = useRouteMatch();
    const CLIENT_ID = match.params.clientId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 50,
        total_count: 0,
        reference: '',
        client_id: null,
        contract_start_date: null,
        contract_end_date: null,
        openCreateContract: false,
    });

    const onSearch = () => {
        if (state.contract_start_date && state.contract_end_date && (state.contract_start_date > state.contract_end_date)) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true, offset: 0, error: false });
        }
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            reference: '',
            client_id: null,
            contract_start_date: null,
            contract_end_date: null,
        });
    };

    useEffect(() => {
        if (state.loading) {
            getContracts(
                state.offset,
                state.limit,
                state.reference,
                CLIENT_ID || state.client_id,
                state.contract_start_date ? moment(state.contract_start_date).format('YYYY-MM-DD') : null,
                state.contract_end_date ? moment(state.contract_end_date).format('YYYY-MM-DD') : null,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        data: res.data.data.contracts,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                {CLIENT_ID ? (
                    <b>{t('contracts.linked.client')}</b>
                ) : (
                    <b>{t('contracts')}</b>
                )}
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        variant="contained"
                        onClick={CLIENT_ID ? (
                            () => history.push('/management/billing/clients')
                        ) : (
                            () => history.push('/management/billing')
                        )}
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button
                        onClick={() => setState({ ...state, openCreateContract: true })}
                        variant="contained"
                    >
                        {t('add')}
                    </Button>
                </Grid>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('reference')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('contract.start.date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('contract.end.date')}</span>
                            </Grid>
                            <Grid item xs={3} style={style.p10}>
                                {!CLIENT_ID && (
                                    <span style={style.cW}>{t('client')}</span>
                                )}
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('contracts')}</span>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('reference')}
                                value={state.reference}
                                onChange={e => setState({ ...state, reference: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('contract.start.date')}
                                value={state.contract_start_date}
                                onChange={contract_start_date => setState({ ...state, contract_start_date })}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('contract.end.date')}
                                value={state.contract_end_date}
                                onChange={contract_end_date => setState({ ...state, contract_end_date })}
                                outlined
                            />
                        </Grid>
                        <Grid item md={3} style={style.p10}>
                            {!CLIENT_ID && (
                                <InputFilter
                                    placeholder={t('client')}
                                    value={state.client}
                                    onChange={e => setState({ ...state, client: e.target.value })}
                                    onKeyPress={onSearch}
                                />
                            )}
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- CONTRACT LIST -------------------- */}
                <ContractList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* -------------------- CREATE CONTRACT -------------------- */}
            {state.openCreateContract && (
                <CreateContract
                    onClose={() => setState({ ...state, openCreateContract: false })}
                    refetch={() => setState({ ...state, openCreateContract: false, loading: true })}
                />
            )}
        </>
    );
};

// --------------------------------------- CONTRACT LIST ------------------------------------ \\
const ContractList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const CLIENT_ID = match.params.clientId;

    const [state, setState] = useState({
        contract: undefined,
        openDetailsContract: false,
        openUpdateContract: false,
        openDeleteContract: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    function handleOpenModal(e, open, contract) {
        e.stopPropagation();
        setState({ ...state, [open]: true, contract });
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="half-container-content">
                {data.length > 0 ? (
                    data.map(contract => (
                        <Fragment key={contract.id}>
                            <Grid
                                onClick={CLIENT_ID ? (
                                    () => history.push(`/management/billing/clients/${CLIENT_ID}/contracts/${contract.id}/bills`)
                                ) : (
                                    () => history.push(`${match.path}/${contract.id}/bills`)
                                )}
                                container
                                alignItems="center"
                                className="list-item-hover"
                            >
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{contract.reference}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(contract.contract_start_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(contract.contract_end_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={3} style={style.p10}>
                                    {!CLIENT_ID && (
                                        <span>{contract.client.name}</span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openDetailsContract', contract)}
                                                className="list-btn"
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openUpdateContract', contract)}
                                                className="list-btn"
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openDeleteContract', contract)}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- DETAILS CONTRACT -------------------- */}
            {state.openDetailsContract && (
                <DetailsContract
                    onClose={() => setState({ ...state, openDetailsContract: false })}
                    refetch={() => handleRefetch('openDetailsContract')}
                    contract={state.contract}
                />
            )}
            {/* -------------------- UPDATE CONTRACT -------------------- */}
            {state.openUpdateContract && (
                <UpdateContract
                    onClose={() => setState({ ...state, openUpdateContract: false })}
                    refetch={() => handleRefetch('openUpdateContract')}
                    contract={state.contract}
                />
            )}
            {/* -------------------- DELETE CONTRACT -------------------- */}
            {state.openDeleteContract && (
                <DeleteContract
                    onClose={() => setState({ ...state, openDeleteContract: false })}
                    refetch={() => handleRefetch('openDeleteContract')}
                    contract={state.contract}
                />
            )}
        </>
    );
};

export default ContractsRouter;
