import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { getCompanyList } from 'services/requests';

// Components
import Select from 'components/items/react-select';

// ------------------------------------ SELECT COMPANY ----------------------------------------- \\
const SelectCompany = ({ companyId, setCompanyId }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setCompanies(res.data.data.companies.map(c => ({ value: c.id, label: c.name })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            value={companyId ? companies.find(c => c.value === companyId) : null}
            onChange={company => setCompanyId(company ? company.value : null)}
            options={companies}
            placeholder={t('company')}
        />
    );
};

export default SelectCompany;
