import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Switch, Route, useHistory, Redirect, useLocation } from 'react-router-dom';

// Material-UI
import { Grid } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { EuroSymbol as IconBills, LibraryBooks as IconContracts } from '@material-ui/icons';

// Services
import { getClient } from 'services/requests';
import { servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Contracts from '../../contracts';
import Bills from '../../bills';

// Style
import style from 'style';

// --------------------------------------- CLIENT DETAILS ---------------------------------------- \\
const ClientDetails = () => {
    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const CLIENT_ID = match.params.clientId;

    const [state, setState] = useState({
        name: '',
        address: '',
        contact: '',
        displayed: 'contracts',
    });

    function navigateTo(displayed) {
        if (displayed) {
            history.push(`/management/billing/clients/${CLIENT_ID}/${displayed}`);
        }
    }

    useEffect(() => {
        if (location) {
            const contracts = location.pathname.includes('contracts');
            if (contracts) {
                setState(state => ({ ...state, displayed: 'contracts' }));
            }
            else {
                setState(state => ({ ...state, displayed: 'bills' }));
            }
        }
    }, [location]);

    useEffect(() => {
        getClient(CLIENT_ID).then(res => {
            if (res?.status === 200) {
                const client = res.data.data.client;
                setState(state => ({
                    ...state,
                    name: client.name,
                    address: client.address,
                    contact: client.contact,
                }));
            }
            else {
                console.log(res);
            }
        })
    }, [CLIENT_ID]);

    return (
        <>
            {/* -------------------- CLIENT DETAILS -------------------- */}
            <div style={style.p10}>
                <b>{t('client')}</b>
            </div>
            <div className="simple-container" style={{ paddingBottom: 24 }}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={6} md={8} lg={10}>
                        <Grid container alignItems="center" className="billing-infos">
                            <Grid item xs="auto" style={{ padding: '10px 6px', textAlign: 'initial' }}>
                                <span style={{ color: servicesColor }}>{t('name')}:&nbsp;</span>
                                <span>{state.name}</span>
                            </Grid>
                            <Grid item xs="auto" style={{ padding: '10px 6px', textAlign: 'initial' }}>
                                <span style={{ color: servicesColor }}>{t('bill.address')}:&nbsp;</span>
                                <span>{state.address}</span>
                            </Grid>
                            <Grid item xs="auto" style={{ padding: '10px 6px', textAlign: 'initial' }}>
                                <span style={{ color: servicesColor }}>{t('contact')}:&nbsp;</span>
                                <span>{state.contact}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item xs={12} sm={6} md={4} lg={2}
                        container justifyContent={mediaSM ? 'flex-end' : 'center'}
                        style={{ marginTop: mediaSM ? 0 : 10 }}
                    >
                        <ToggleButtonGroup
                            value={state.displayed}
                            onChange={(e, value) => navigateTo(value)}
                            exclusive
                            size="small"
                        >
                            <ToggleButton value="contracts" style={{ minWidth: 120 }}>
                                <span>{t('contract')}</span>
                                <IconContracts style={{ marginLeft: 5, fontSize: 18 }} />
                            </ToggleButton>
                            <ToggleButton value="bills" style={{ minWidth: 120 }}>
                                <span>{t('bills')}</span>
                                <IconBills style={{ marginLeft: 5, fontSize: 18 }} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </div>
            {/* -------------------- ROUTER -------------------- */}
            <Switch>
                <Route path={`${match.path}/contracts`}>
                    <Contracts />
                </Route>
                <Route path={`${match.path}/bills`}>
                    <Bills />
                </Route>
                <Redirect to={`${match.path}/contracts`} />
            </Switch>
        </>
    );
};

export default ClientDetails;
