import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Switch, Route, useHistory, Redirect } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Grid, IconButton, Divider } from '@material-ui/core';
import {
    Create as IconCreate, Delete as IconDelete, Cancel as IconCancel,
    Search as IconSearch, Assignment as IconDetail,
} from '@material-ui/icons';

// Services
import { getCertifications } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { mobipassColor, getTranslation, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateCertification from './certification-create';
import UpdateCertification from './certification-update';
import ExportCertification from './certification-export';
import { DetailsCertification, DeleteCertification } from './certification-modals';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import InputFilter from 'components/items/input-filter';
import SelectSkillbase from 'components/select/select-skillbase';

// Style
import style from 'style';

// ------------------------------------- CERTIFICATION ROUTER ------------------------------------ \\
const CertificationsRouter = () => {
    return (
        <Switch>
            <Route exact path="/mobipass/certification">
                <Certifications />
            </Route>
            <Route exact path="/mobipass/certification/create">
                <CreateCertification />
            </Route>
            <Route exact path="/mobipass/certification/update/:certificationId">
                <UpdateCertification />
            </Route>
            <Redirect from="*" to="/mobipass/certification" />
        </Switch>
    );
};

// ---------------------------------------- CERTIFICATION ---------------------------------------- \\
const Certifications = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('certifications-filters'));
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        certifications: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: storedFilters?.name || '',
        certification_date: storedFilters?.certification_date || null,
        skillbase_id: storedFilters?.skillbase_id || null,
        assessor: storedFilters?.assessor || '',
        learner: storedFilters?.learner || '',
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('certifications-filters');
        setState({
            ...state,
            loading: true,
            offset: 0,
            name: '',
            certification_date: null,
            skillbase_id: null,
            assessor: '',
            learner: '',
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('certifications-filters', JSON.stringify({
                name: state.name,
                certification_date: state.certification_date,
                skillbase_id: state.skillbase_id,
                assessor: state.assessor,
                learner: state.learner,
            }));

            getCertifications(
                state.offset,
                state.limit,
                state.certification_date ? moment(state.certification_date).format('YYYY-MM-DD') : null,
                state.skillbase_id,
                state.assessor,
                state.learner,
            ).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        certifications: res.data.data.certifications,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('certification')}
            </BreadcrumbsItem>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 4 && (
                        <Button
                            onClick={() => history.push('/mobipass/certification/create')}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: mobipassColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('date')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('assessor')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('certification')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('date')}
                                value={state.certification_date}
                                onChange={certification_date => onSelectSearch('certification_date', certification_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('assessor')}
                                value={state.assessor}
                                onChange={e => setState({ ...state, assessor: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner}
                                onChange={e => setState({ ...state, learner: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportCertification data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- CERTIFICATION LIST -------------------- */}
                <CertificationList
                    ROLE_ID={ROLE_ID}
                    certifications={state.certifications}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// -------------------------------------- CERTIFICATION LIST ------------------------------------- \\
const CertificationList = ({ ROLE_ID, certifications, loading, error, refetch }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const USER_ID = getStoredUser()?.id;

    const [state, setState] = useState({
        certification: null,
        openDetailsCertification: false,
        openDeleteCertification: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {certifications.length > 0 ? (
                    certifications.map(certification => (
                        <Fragment key={certification.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>
                                        {getFormattedDate(certification.certification_date)}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{getTranslation('name', certification.skillbase)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{certification.assessor_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{certification.user.full_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDetailsCertification: true, certification })}
                                                className="list-btn"
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        }
                                    />
                                    {(ROLE_ID <= 2 || USER_ID === certification.assessor_id) && (
                                        <>
                                            <Tooltip title={t('edit')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push('/mobipass/certification/update/' + certification.id)}
                                                        className="list-btn"
                                                    >
                                                        <IconCreate />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('delete')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openDeleteCertification: true, certification })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <> 
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>  
                )}
            </div>
            {/* -------------------- DETAILS CERTIFICATION -------------------- */}
            {state.openDetailsCertification && (
                <DetailsCertification
                    onClose={() => setState({ ...state, openDetailsCertification: false })}
                    certification={state.certification}
                />
            )}
            {/* -------------------- DELETE CERTIFICATION -------------------- */}
            {state.openDeleteCertification && (
                <DeleteCertification
                    onClose={() => setState({ ...state, openDeleteCertification: false })}
                    certification={state.certification}
                    refetch={() => handleRefetch('openDeleteCertification')}
                />
            )}
        </>
    );
};

export default CertificationsRouter;
