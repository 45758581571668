import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Grid, Button, GridList, Paper } from '@material-ui/core';
import {
    LibraryBooks as IconCards, ExpandMore as MoreIcon, ExpandLess as LessIcon,
    SaveAlt as SaveIcon, CancelPresentation as NoneIcon, Cancel as CancelIcon,
} from '@material-ui/icons';

// Services
import { getToolCards } from 'services/requests';
import { softskillsColor, fileUrl } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Filters from './soft-tool-filters';
import InputTextGrid from 'components/items/input-text-grid';
import Error from 'components/items/error';
import Spinner from 'components/items/spinner';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

const styles = {
    border: {
        border: '1px solid lightgray',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        marginBottom: 20,
    },
};

// --------------------------------------- TOOL CARDS ---------------------------------------- \\
const ToolCards = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        tool_cards: [],
        tool_card_name: '',
        checkLevels: { 1: false, 2: false, 3: false, 4: false },
        openFilters: false,

        // Tool Card Data
        participants: [],
        equipment: [],
        preparation: [],
        duration: [],
        phase: [],
        levels: [],
        audience: [],
        usable_online: '',
    });

    function cancelSearch() {
        setState({
            ...state,
            loading: true,
            tool_card_name: '',
            checkLevels: { 1: false, 2: false, 3: false, 4: false },
            openFilters: false,
            participants: [],
            equipment: [],
            preparation: [],
            duration: [],
            phase: [],
            levels: [],
            audience: [],
            usable_online: '',
        });
    }

    useEffect(() => {
        if (state.loading) {
            getToolCards({
                name: state.tool_card_name,
                participants: state.participants,
                equipment: state.equipment,
                preparation: state.preparation,
                duration: state.duration,
                phase: state.phase,
                levels: state.levels,
                audiences: state.audience,
                usable_online: state.usable_online,
            }).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, tool_cards: res.data.data.tool_cards });
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <div className="full-container">
            <Grid container alignItems="center" style={{ ...style.p10, backgroundColor: softskillsColor }}>
                <Grid item xs={false} md={4} />
                <Grid item xs={12} md={4} container justifyContent="center">
                    <IconCards style={{ color: 'white' }}/>
                    <span style={{ ...style.ml5, ...style.ttU, color: 'white' }}>{t('tool.cards')}</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={() => setState({ ...state, openFilters: !state.openFilters })}>
                        <span style={style.cW}>
                            {t('filters')}
                        </span>
                        {state.openFilters ? <LessIcon style={style.cW} /> : <MoreIcon style={style.cW} />}
                    </Button>
                </Grid>
            </Grid>
            <Grid style={styles.border}>
                {/* ---------------- SEARCH BY NAME ------------------------ */}
                <Grid container alignItems="center" style={style.p10}>
                    <Grid item xs={12} md={9}>
                        <InputTextGrid
                            placeholder={t('search.toolcards')}
                            value={state.tool_card_name}
                            onChange={e => setState({ ...state, tool_card_name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} container justifyContent="center">
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                            {t('search')}
                        </Button>
                        <Tooltip title={t('search.cancel')}
                            item={(
                                <Button onClick={cancelSearch} variant="contained" style={style.ml5}>
                                    <CancelIcon />
                                </Button>
                            )}
                        />
                    </Grid>
                </Grid>
                {/* ---------------- SEARCH BY FILTER ------------------------ */}
                {state.openFilters && (
                    <Grid container style={style.p10}>
                        <Filters state={state} setState={setState} />
                    </Grid>
                )}
            </Grid>
            {/* -------------------- SOFTTOOL CARD LIST -------------------- */}
            <ToolCardList
                loading={state.loading}
                error={state.error}
                tool_cards={state.tool_cards}
            />
        </div>
    );
};

// -------------------------------------- TOOL CARD LIST ------------------------------------- \\
const ToolCardList = ({ loading, error, tool_cards }) => {
    const { t } = useTranslation();
    const { mediaXS, mediaSM, mediaMD, mediaLG, mediaXL } = useMediaQueries();
    const [cols, setCols] = useState(6);

    function getParticipants(participants) {
        let groups = '';
        participants.forEach((item, index) => {
            if (index === 0) groups += t('group.' + item);
            else groups +=  (', ' + t('group.' + item));
        });
        return groups;
    }

    function getPreparation(preparation) {
        switch (preparation) {
            case 1:
                return 'preparation.1';
            case 2:
                return 'preparation.2';
            case 3:
                return 'preparation.3';
            default:
                return 'preparation.1'; 
        }
    }

    function getDuration(duration) {
        switch (duration) {
            case 10:
                return 'less.than.10';
            case 20:
                return 'less.than.20';
            case 30:
                return 'less.than.30';
            case 40:
                return 'less.than.40';
            case 50:
                return 'less.than.50';
            default:
                return 'less.than.10'; 
        }
    }

    function getEquipment(equipment) {
        switch (equipment) {
            case 1:
                return 'equipment.1';
            case 2:
                return 'equipment.2';
            case 3:
                return 'equipment.3';
            default:
                return 'equipment.1'; 
        }
    }

    function getPhase(phase) {
        switch (phase) {
            case 1:
                return 'phase.1';
            case 2:
                return 'phase.2';
            case 3:
                return 'phase.3';
            default:
                return 'phase.1'; 
        }
    }

    function getAudience(audience) {
        switch (audience) {
            case 1:
                return 'audience.1';
            case 2:
                return 'audience.2';
            case 3:
                return 'audience.3';
            default:
                return 'audience.1'; 
        }
    }

    function getLevels(items) {
        let levels = items.map(item => item.level);
        let filteredLevels = levels.filter((level, index) => levels.indexOf(level) === index);
        return filteredLevels;
    }

    function getSteps(items, level) {
        let result = '';
        const levels = items.filter(item => item.level === level);
        levels.forEach((item, index) => {
            if (index === 0) {
                result += item.step;
            }
            else {
                result += ', ' + item.step;
            }
        });
        return result;
    }

    useEffect(() => {
        if (mediaXS) setCols(1);
        if (mediaSM) setCols(2);
        if (mediaMD) setCols(3);
        if (mediaLG) setCols(4);
        if (mediaXL) setCols(5);
    }, [mediaXS, mediaSM, mediaMD, mediaLG, mediaXL]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            {/* ---------------------- SOFT TOOL CARDS ---------------------- */}
            <div>
                <GridList cellHeight={360} cols={cols} style={{ margin: 0 }}>
                    {tool_cards.length > 0 ? (
                        tool_cards.map(tool_card => (
                            <Grid container key={tool_card.id} style={{ padding: 5 }}>
                                <Paper elevation={3} className="tool-card">
                                    <Grid container justifyContent="center">
                                        <b>{tool_card.number}. {tool_card.name}</b>
                                    </Grid>
                                    <Divider style={{ margin: '10px 0px' }} />
                                    <Grid container>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('participants')}</span>
                                            <span>{getParticipants(tool_card.card_filter.participants)}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('preparation')}</span>
                                            <span>{t(getPreparation(tool_card.card_filter.preparation))}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('equipements')}</span>
                                            <span>{t(getEquipment(tool_card.card_filter.equipment))}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('duration')}</span>
                                            <span>{t(getDuration(tool_card.card_filter.duration))}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('phase')}</span>
                                            <span>{t(getPhase(tool_card.card_filter.phase))}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('level')}</span>
                                            {getLevels(tool_card.card_filter.levels).map(level => (
                                                <div key={level}>
                                                    <span>
                                                        {t('level')}&nbsp;{level}&nbsp;:&nbsp;{t('steps')}&nbsp;{getSteps(tool_card.card_filter.levels, level)}
                                                    </span>
                                                </div>
                                            ))}
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('audience')}</span>
                                            <span>{t(getAudience(tool_card.card_filter.audience))}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('usable.online')}</span>
                                            <span>{tool_card.card_filter?.usable_online === 0 ? t('no') : t('yes')}</span>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: 5, display: 'flex', flexDirection: 'column' }}>
                                            <span style={{ color: softskillsColor }}>{t('documents')}</span>
                                            {tool_card.soft_tool_links.length > 0 ? (
                                                tool_card.soft_tool_links.map(soft_tool_link => (
                                                    <Button
                                                        key={soft_tool_link.id}
                                                        href={fileUrl + soft_tool_link.url}
                                                        target="_blank"
                                                        variant='contained'
                                                        style={{ textTransform: 'none', margin: '5px 0' }}
                                                    >
                                                        {soft_tool_link.link_type.name}
                                                        <span style={{ ...style.ttU, ...style.ml5 }}>
                                                            ({soft_tool_link.languages.map((l, index) => {
                                                                if (index > 0) return ', ' + l.code;
                                                                else return l.code;
                                                            })})
                                                        </span>
                                                        <SaveIcon style={{ marginLeft: 5, fontSize: 20 }} />
                                                    </Button>
                                                ))
                                            ):(
                                                <span>{t('none')}</span>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))
                    ) : (
                        <Grid container style={{ padding: 5 }}>
                            <Paper elevation={3} className="tool-card">
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%', flexDirection: 'column' }}
                                >
                                    <NoneIcon style={{ color: 'gray', fontSize: 40 }} />
                                    <span>{t('none.result')}</span>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </GridList>
            </div>
        </>
    );
};

export default ToolCards;
