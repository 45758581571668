import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';

// Material-UI
import { Button, Grid, IconButton, Divider } from '@material-ui/core';
import { Search as IconSearch, Cancel as IconCancel } from '@material-ui/icons';

// Services
import { getCompanies } from 'services/requests';
import { servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import GlobalStatistics from './global-statistics';
import CompanyStatistics from './company-statistics';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// --------------------------------------- STATISTICS ROUTER ------------------------------------ \\
const StatisticsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <Statistics />
            </Route>
            <Route exact path={`${match.path}/global`}>
                <GlobalStatistics />
            </Route>
            <Route path={`${match.path}/:companyId`}>
                <CompanyStatistics />
            </Route>
        </Switch>
    );
};

// ------------------------------------------ STATISTICS ---------------------------------------- \\
const Statistics = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: '',
        address: '',
        email: '',
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            offset: 0,
            name: '',
            address: '',
            email: '',
        });
    };

    useEffect(() => {
        if (state.loading) {
            getCompanies(
                state.offset,
                state.limit,
                state.name,
                state.address,
                state.email,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data.companies,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('statistics')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid container justifyContent={mediaMD ? 'flex-start' : 'center'} item xs={12} md={4}>
                    <Button onClick={() => history.push('/management')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid container justifyContent="center" item xs={12} md={4} style={mediaMD ? null : style.m10}>
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid container justifyContent={mediaMD ? 'flex-end' : 'center'} item xs={12} md={4}>
                    <Button onClick={() => history.push('/management/statistics/global')} variant="contained">
                        {t('global')}
                    </Button>
                </Grid>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('address')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('email')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('companies')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('company')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('address')}
                                value={state.address}
                                onChange={e => setState({ ...state, address: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('email')}
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* ---------------------- COMPANY LIST ---------------------- */}
                <CompanyList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                />
            </div>
        </>
    );
};

// ----------------------------------------- COMPANY LIST --------------------------------------- \\
const CompanyList = ({ data, loading, error }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="full-container-content">
            {data.length > 0 ? (
                data.map(company => (
                    <Fragment key={company.id}>
                        <Grid
                            container
                            alignItems="center"
                            className="list-item-hover"
                            onClick={() => history.push(`${match.path}/${company.id}`)}
                        >
                            <Grid item xs={12} md={4} style={style.p10}>
                                <span>{company.name}</span>
                            </Grid>
                            <Grid item xs={12} md={4} style={style.p10}>
                                <span>{company.address} {company.zip_code} {company.city} <b>{company.country?.name}</b></span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{company.email}</span>
                            </Grid>
                        </Grid>
                        <Divider />     
                    </Fragment>
                ))
            ) : (
                <>     
                    <Grid container alignItems="center" style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default StatisticsRouter;
