import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Material-UI
import { List, ListItem, Divider, SvgIcon } from '@material-ui/core';
import {
    School as FormationIcon,
    AccountTree as FormationModelIcon,
    CheckCircle as SignatureIcon,
    Public as MobilityIcon,
} from '@material-ui/icons';

// Services
import { getStoredUser, getSoftskillsAccess, getCleaAccess } from 'services/storage';
import { getSignatures } from 'services/requests';
import { mobitrainColor, softskillsColor } from 'services/helpers';

// Components
import Profile from 'components/common/profile';
import Tooltip from 'components/items/tooltip';
import Badge from 'components/items/badge';
import CleaIcon from 'components/common/clea-icon';

// Style
import style from 'style';
import { cleaColor } from 'services/helpers';

// ---------------------------------------- SIDE MENU ---------------------------------------- \\
const SideMenu = ({ position }) => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const ROLE_ID = getStoredUser()?.role?.id;
    const [path, setPath] = useState('formation');
    const [signatures, setSignatures] = useState(0);

    useEffect(() => {
        getSignatures().then(res => {
            if (res?.status === 200) {
                setSignatures(res.data.data.pending_signatures);
            }
        });
    }, []);

    useEffect(() => {
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    const SoftFormationIcon = props => {
        return (
            <SvgIcon {...props}>
                <path viewBox="0 0 800 800" 
                d="M0.89,22.43H0v-2.24h0.06c0.32-2.42,4.37-4.33,9.37-4.33c0.18,0,0.34,0.02,0.52,0.03L6.6,19.13l3.25,3.35
                L0.89,22.43C0.89,22.43,0.89,22.43,0.89,22.43z M20.82,15.83l-1.62-1.62l-5.55,5.55l-2.19-2.19l-1.62,1.62l2.19,2.19l0,0L13.65,23
                l0,0l0,0l1.62-1.62l0,0L20.82,15.83z M7.71,4.72l3.11-2.73l0.61,2.72l0.8-0.18l-0.69-3.14L6.61,0L2.8,3.35L7.71,4.72z M4.78,4.6
                l0.39,1.58l3.07,0.9l2.43-2.09l-0.35-1.59L7.89,5.52L4.78,4.6z M10.77,5.4l0.08,0.37L8.43,7.87l-3.07-0.9L5.3,6.71
                c-0.7,0.97-1.02,2.22-0.74,3.48c0.52,2.34,2.84,3.82,5.18,3.3c2.34-0.52,3.82-2.84,3.31-5.18C12.76,7,11.89,5.97,10.77,5.4z"/>
            </SvgIcon>
        );
    };

    return (
        <div className="side-menu" style={{ position }}>
            <div className="profile">
                <Profile />
            </div>
            <Divider />
            <div className="menu">
                <List component="nav" style={style.p0}>
                    {/* ---------------------- FORMATION ---------------------- */}
                    <ListItem 
                        button
                        selected={path === 'formation'} 
                        onClick={() => history.push('/mobitrain/formation')} 
                        style={style.p12_16}
                    >
                        <FormationIcon style={{ color: mobitrainColor }} />
                        <span className="side-menu-title">{t('training')}</span>
                    </ListItem>
                    {/* ---------------------- FORMATION MODELS ---------------------- */}
                    {ROLE_ID <= 4 && (
                        <ListItem 
                            button
                            selected={path === 'formation-models'} 
                            onClick={() => history.push('/mobitrain/formation-models')} 
                            style={style.p12_16}
                        >
                            <FormationModelIcon style={{ color: mobitrainColor }} />
                            <span className="side-menu-title">{t('formation.models')}</span>
                        </ListItem>
                    )}
                    {/* ---------------------- SIGNATURE ---------------------- */}
                    <ListItem 
                        button
                        selected={path === 'signature'} 
                        onClick={() => history.push('/mobitrain/signature')} 
                        style={{ ...style.p12_16, ...style.jcSB }}
                    >
                        <div style={style.dF}>
                            <SignatureIcon style={{ color: mobitrainColor }} />
                            <span className="side-menu-title">{t('signature')}</span>
                        </div>
                        {signatures > 0 && (
                            <Tooltip
                                title={t('signatures.pending')}
                                item={<div><Badge variant="secondary" number={signatures} /></div>}
                            />
                        )}
                    </ListItem>
                    {/* ---------------------- MOBILITY ---------------------- */}
                    <ListItem 
                        button
                        selected={path === 'mobility'} 
                        onClick={() => history.push('/mobitrain/mobility')} 
                        style={style.p12_16}
                    >
                        <MobilityIcon style={{ color: mobitrainColor }} />
                        <span className="side-menu-title">{t('mobility')}</span>
                    </ListItem>
                    {/* ---------------------- SOFT SKILLS ---------------------- */}
                    {getSoftskillsAccess() === '1' && (
                        <>
                            <Divider />
                            <ListItem 
                                button
                                selected={path === 'soft-formation'}
                                onClick={() => history.push('/mobitrain/soft-formation')} 
                                style={style.p12_16}
                            >
                                <SoftFormationIcon style={{ fontSize: 24, color: softskillsColor }} />
                                <span className="side-menu-title">{t('soft.formation')}</span>
                            </ListItem>
                        </>
                    )}
                    {(getSoftskillsAccess() === '1' || getCleaAccess() === '1') && (
                        <Divider />
                    )}
                    {/* ---------------------- CLEA FORMATION ---------------------- */}
                    {getCleaAccess() === '1' && (
                        <>
                            <ListItem 
                                button
                                selected={path === 'clea'} 
                                onClick={() => history.push('/mobitrain/clea-formation')} 
                                style={style.p12_16}
                            >
                                <CleaIcon style={{ fontSize: 24, color: cleaColor }} />
                                <span className="side-menu-title">{t('clea.training')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </List>
            </div>
        </div>
    );
};

export default SideMenu;
