import React, { useState, useEffect } from 'react';

// Import Material-UI
import Pagination from '@material-ui/lab/Pagination';

// ---------------------------------------- PAGINATION ---------------------------------------- \\
const EditedPagination = ({ limit, total_count, setOffset }) => {

    const [state, setState] = useState({
        page: 1,
        count: undefined,
    });

    function handleChange(event, value) {
        setState({ ...state, page: value });
        setOffset((value - 1) * limit);
    }   

    useEffect(() => {
        // eslint-disable-next-line
        if (total_count == 0) {
            setState(state => ({
                ...state,
                count: 1,
            }))
        }
        else if (limit && total_count) {
            setState(state => ({
                ...state,
                count: Math.ceil(total_count / limit),
            }));
        }
    }, [limit, total_count]);

    return (
        <Pagination
            variant="outlined"
            shape="rounded"
            page={state.page}
            count={state.count}
            onChange={handleChange}
        />
    );
};

export default EditedPagination;
