import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThroughProvider } from 'react-through';

// Notistack (Handle Alert message)
import { SnackbarProvider } from 'notistack';

// Pickers from Material-UI
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';

// App Component
import App from './app';

// Services
import useMediaQueries from 'services/media';
import 'services/i18next';
import { getLocale } from 'services/storage';

// CSS Styles
import './index.css';

const NotistackProvider = () => {
    const { mediaMD } = useMediaQueries();

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                horizontal: mediaMD ? 'center' : 'left',
                vertical: 'top'
            }}
        >
            <App />
        </SnackbarProvider>
    );
};

ReactDOM.render(
    <BrowserRouter>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={getLocale() || 'fr'}>
            <ThroughProvider>
                <NotistackProvider />
            </ThroughProvider>
        </MuiPickersUtilsProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
