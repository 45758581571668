import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, IconButton, Button, Divider, Grid } from '@material-ui/core';
import { Create as IconEdit, Delete as IconDelete } from '@material-ui/icons';

// Services
import { getTranslation, servicesColor, initialLanguagesObject } from 'services/helpers';
import { languages } from 'services/constants';
import { getSectors, addSector, editSector, deleteSector } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/items/tooltip';
import InputTextGrid from 'components/items/input-text-grid';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// -------------------------------- SECTORS MANAGEMENT ------------------------------------ \\
const SectorsManagement = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        data: undefined,
        error: undefined,
        sector: undefined,
        openCreateModal: false,
        openUpdateModal: false,
        openDeleteModal: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSectors().then(res => {
                if (res?.status === 200) {
                    setState(state => ({ ...state, loading: false, data: res.data.data.sectors }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('sectors')}</b>
                <b>{state.data.length + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>
                <Button onClick={() => setState({ ...state, openCreateModal: true })} variant="contained">
                    {t('add')}
                </Button>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- HEADERS LIST -------------------- */}
                <Grid
                    container alignItems="center" justifyContent="space-between"
                    style={{ ...style.p10_25, backgroundColor: servicesColor }}
                >
                    <Grid item xs={6}>
                        <span style={style.cW}>{t('name')}</span>
                    </Grid>
                    <Grid container justifyContent={mediaMD ? 'center' : 'flex-end'} item xs={6}>
                        <span style={style.cW}>{t('action')}</span>
                    </Grid>
                </Grid>
                {/* -------------------- SECTOR LIST -------------------- */}
                <div className="full-container-content">
                    {state.data.length > 0 ? (
                        state.data.map(sector => (
                            <Fragment key={sector.id}>
                                <Grid container alignItems="center" style={style.p10_25}>
                                    <Grid item xs={6}>
                                        <span>{getTranslation('name', sector)} ({sector.count_skillbases})</span>
                                    </Grid>
                                    <Grid container justifyContent={mediaMD ? 'center' : 'flex-end'} item xs={6}>
                                        <Tooltip title={t('edit')}
                                            item={
                                                <IconButton
                                                    onClick={() => setState({ ...state, openUpdateModal: true, sector })}
                                                    className="list-btn"
                                                >
                                                    <IconEdit />
                                                </IconButton>
                                            }
                                        />
                                        {sector.count_skillbases === 0 && (
                                            <Tooltip title={t('delete')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openDeleteModal: true, sector })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <Grid container alignItems="center" justifyContent="space-between" style={style.p10_25}>
                            <span>{t('none')}</span>
                        </Grid>
                    )}     
                </div>
            </div>
            {/* -------------------- MODALS -------------------- */}
            {state.openCreateModal && (
                <CreateSectorModal
                    onClose={() => setState({ ...state, openCreateModal: false })}
                    refetch={() => setState({ ...state, openCreateModal: false, loading: true })}
                />
            )}
            {state.openUpdateModal && (
                <UpdateSectorModal
                    onClose={() => setState({ ...state, openUpdateModal: false })}
                    refetch={() => setState({ ...state, openUpdateModal: false, loading: true })}
                    sector={state.sector}
                />
            )}
            {state.openDeleteModal && (
                <DeleteSectorModal
                    onClose={() => setState({ ...state, openDeleteModal: false })}
                    refetch={() => setState({ ...state, openDeleteModal: false, loading: true })}
                    sector={state.sector}
                />
            )}
        </>
    );
};

// ---------------------------------------- MODALS ---------------------------------------- \\
const CreateSectorModal = ({ onClose, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
    });
    
    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleSaveSector() {
        if (Object.values(state.names).every(name => name === "")) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    // Mutation Save Sector
    useEffect(() => {
        if (state.loading) {
            addSector({ name: state.names }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('sector.add')}
                        </p>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleSaveSector}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

const UpdateSectorModal = ({ onClose, refetch, sector }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
    });
    
    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleSaveSector() {
        if (Object.values(state.names).every(name => name === "")) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    // Put Sector Names
    useEffect(() => {
        if (sector) {
            let names = state.names;
            Object.entries(sector.translations).map(([key, value]) => {
                return names[key] = value.name;
            });
            setState({ ...state, names });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sector]);

    // Mutation Update Sector
    useEffect(() => {
        if (state.loading) {
            editSector(sector.id, { name: state.names }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('sector.edit')}
                        </p>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleSaveSector}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

const DeleteSectorModal = ({ onClose, refetch, sector }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    function handleDeleteSector() {
        deleteSector(sector.id).then(res => {
            if (res?.status === 200) {
                enqueueSnackbar(res.data.message, { variant: 'success' });
                refetch();
            }
            else {
                enqueueSnackbar(res, { variant: 'error' });
                setLoading(false);
            }
        });
    }

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {getTranslation('name', sector)}
                        </p>
                        <span>{t('sector.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={handleDeleteSector} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default SectorsManagement;
