import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { softskillsColor } from 'services/helpers';

// Assets
import ErasmusLogo from 'assets/images/erasmuspluslow.png';
import SoftskillLogo from 'assets/images/soft-skill-logo.jpg';

// ------------------------------------ SOFT SKILLS MESSAGE ------------------------------------------ \\
const SoftSkillsMessage = ({ displayed, setDisplayed }) => {
    const { t } = useTranslation();

    return (
        <>
            {displayed ? (
                <div style={{ padding: '0px 25px', marginTop: 15 }}>
                    <Grid container style={{ border: '1px solid lightgray', borderRadius: 4, padding: 15 }}>
                        <Grid item xs={12} md={8}>
                            <span style={{ fontSize: 16 }}>
                                {t('softskills.message.title')}
                            </span>
                            <div style={{ padding: 10, marginTop: 10, fontSize: 14 }}>
                                • {t('softskills.message.1')}
                                <br />
                                • {t('softskills.message.2')}
                                <br />
                                • {t('softskills.message.3')}
                                <br />
                                • {t('softskills.message.4')}
                                <br />
                                • {t('softskills.message.5')}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} container justifyContent="center" alignItems="center">
                            <Grid style={{ margin: '15px auto' }}>
                                <img
                                    src={ErasmusLogo}
                                    alt="logo erasmus+"
                                    style={{ height: 50, width: 'auto'  }}
                                />
                            </Grid>
                            <Grid style={{ margin: '15px auto' }}>
                                <img
                                    src={SoftskillLogo} alt="logo softskills"
                                    style={{ height: 100, width: 'auto' }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            onClick={() => setDisplayed(false)}
                            variant="contained"
                            style={{ margin: '15px auto 0px auto', backgroundColor: softskillsColor, color: 'white' }}
                        >
                            {t('hide')}
                        </Button>
                    </Grid>
                </div>
            ) : (
                <div style={{ padding: '0px 25px', marginTop: 15 }}>
                    <Grid container alignItems="center" justifyContent="space-between" style={{ border: '1px solid lightgray', borderRadius: 4, padding: 10 }}>
                        <span style={{ fontSize: 16 }}>
                            {t('softskills.message.title')}
                        </span>
                        <Button
                            onClick={() => setDisplayed(true)}
                            variant="contained"
                            style={{ backgroundColor: softskillsColor, color: 'white' }}
                        >
                            {t('more')}
                        </Button>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default SoftSkillsMessage;
