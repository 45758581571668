import React from 'react';

// ---------------------------------------- INPUT FILTER ---------------------------------------- \\
const InputFilter = ({ placeholder, value, onChange, onKeyPress }) => {
    return (
        <input
            className="input-filter"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyPress={e => e.key === 'Enter' ? onKeyPress() : null}
        />
    );
};

InputFilter.defaultProps = {
    placeholder: '',
};

export default InputFilter;
