import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';
import IconVerified from '@material-ui/icons/VerifiedUser';

// Services
import useMediaQueries from 'services/media';
import { theme } from 'services/helpers';

// Component
import SelectLanguage from './select-language';
import Footer from './footer';
import FooterMobiRhin from './footer-mobirhin';

// Assets
import ZerobarrierLogo from 'assets/images/zerobarrier-white.png';
import LogoMobirhin from 'assets/images/mobirhin-white.png';

// Style
import style from 'style';

const styles = {
    card: {
        border: '1px solid lightgray',
        borderRadius: 4,
        textAlign: 'center',
        padding: 25,
    },
};

// ---------------------------------------- EMAIL CONFIRMED ---------------------------------------- \\
const EmailConfirmed = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Grid
                className="header"
                container alignItems="center"
            >
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <img
                        alt="Logo White"
                        src={theme === 'mobirhin' ? LogoMobirhin : ZerobarrierLogo}
                        style={{ height: 50 }}
                    />
                </Grid>
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <SelectLanguage />
                </Grid>
            </Grid>
            {/* -------------------- MESSAGE EMAIL CONFIRMED -------------------- */}
            <div className="main">
                <div className="full-container" style={{ ...style.jcC, ...style.aiC }}>
                    <div style={styles.card}>
                        <b>{t('email.confirmed')}</b>
                        <IconVerified style={style.ml5} color="secondary" />
                        <br />
                        <span>{t('email.send.identifiers')}</span>
                        <br />
                        <Button onClick={() => history.push('/home')} variant="contained" style={style.mt15}>
                            {t('home.go.to')}
                        </Button>
                    </div>
                </div>
            </div>
            {/* -------------------- FOOTER -------------------- */}
            {theme === 'mobirhin' ? (
                <FooterMobiRhin />
            ) : (
                <Footer />
            )}
        </>
    );
};

export default EmailConfirmed;
