import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// File-Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, IconButton, Dialog, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import { HelpOutline as HelpIcon, InsertDriveFile as IconCertif } from '@material-ui/icons';

// Services
import { cleaEvaluationTypes } from 'services/constants';
import { fileUrl, getFileExtension } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/items/input-text';
import Tooltip from 'components/items/tooltip';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';
import DatePicker from 'components/items/date-picker';
import InputFile from 'components/items/input-file';

// Style
import style from 'style';

// ------------------------------- CLEA EVALUATION FORM PAGE 1 ---------------------------------- \\
const CleaEvaluationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { evaluationType } = useParams();

    // Evaluation Types
    let evaluationTypes = cleaEvaluationTypes.map(a => ({ value: a.value, label: t(a.label) }));
    if (evaluationType === '1' || evaluationType === '2') {
        evaluationTypes = evaluationTypes.filter(t => t.value !== '0');
    }

    const navigateToPage2 = () => {
        if (state.assessor === ''
            || state.company_id == null
            || state.evaluation_date == null
            || state.evaluation_type == null
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            const uploaded_certificate = document.getElementById('upload-certificate').files[0];
            setState({ ...state, page: 2, uploaded_certificate });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- ASSESSOR -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('company.assessor')}</b><FieldRequired />
                            <Tooltip title={t('help')}
                                item={
                                    <IconButton
                                        onClick={() => setState({ ...state, openHelp: true })}
                                        style={{ padding: 6 }}
                                    >
                                        <HelpIcon />
                                    </IconButton>
                                }
                            />
                            <InputText
                                placeholder={t('company.assessor')}
                                value={state.assessor}
                                onChange={e => setState({ ...state, assessor: e.target.value })}
                            />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('company')}</b><FieldRequired />
                            <Select
                                placeholder={t('company')}
                                value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- DATE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('clea.evaluation.date')}</b><FieldRequired />
                            <DatePicker
                                placeholder={t('clea.evaluation.date')}
                                value={state.evaluation_date}
                                onChange={evaluation_date => setState({ ...state, evaluation_date })}
                                outlined
                            />
                        </div>
                        {/* -------------------- EVALUATION TYPE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('clea.evaluation.type')}</b><FieldRequired />
                            <Select
                                placeholder={t('clea.evaluation.type')}
                                value={state.evaluation_type ? evaluationTypes.find(a => a.value === state.evaluation_type) : null}
                                onChange={item => setState({ ...state, evaluation_type: item ? item.value : null })}
                                options={evaluationTypes}
                                isDisabled={evaluationType === '0'}
                            />
                        </div>
                        {/* -------------------- UPLOADED CERTIFICATE -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('leaflet')}</b>
                            <InputFile
                                inputId="certificate"
                            />
                            {(state.actionType === 'UPDATE' && state.certificate_url != null) && (
                                <>
                                    <Grid container alignItems="center" style={{ marginTop: 12 }}>
                                        <IconButton
                                            onClick={() => saveAs(fileUrl + state.certificate_url, t('leaflet') + ' - ' + t('clea.evaluation') + getFileExtension(state.certificate_url)?.toLowerCase())}
                                            style={{ padding: 8 }}
                                        >
                                            <IconCertif />
                                        </IconButton>
                                        <b className="input-title">{t('clea.view.certificate')}</b>
                                    </Grid>
                                    <Grid container alignItems="center" style={{ marginTop: 12 }}>
                                        <Checkbox
                                            checked={state.remove_certificate}
                                            onChange={e => setState({ ...state, remove_certificate: e.target.checked })}
                                            color="primary"
                                        />
                                        <b className="input-title">{t('clea.remove.actual.certificate')}</b>
                                    </Grid>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <span>1 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- HELP MODAL -------------------- */}
            {state.openHelp && (
                <HelpModal
                    onClose={() => setState({ ...state, openHelp: false })}
                />
            )}
        </>
    );
};

// -------------------------------------- HELP MODAL -------------------------------------------- \\
const HelpModal = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent style={style.taC}>
                <p className="dialog-title">
                    {t('help')}
                </p>
                <span>{t('help.explaination')}</span>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={() => onClose()} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CleaEvaluationFormPage1;
