import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid, Divider, IconButton, MenuItem } from '@material-ui/core';
import {
    Search as IconSearch, Cancel as IconCancel, Delete as IconDelete, 
    Create as IconCreate, Link as IconLink,
} from '@material-ui/icons';

// Services 
import { servicesColor, getTranslation } from 'services/helpers';
import { softToolTypes } from 'services/constants';
import { getAllSoftTools } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import SoftToolCreate from './soft-tool-create';
import SoftToolUpdate from './soft-tool-update';
import SoftToolLinks from './soft-tool-links';
import SoftToolDelete from './soft-tool-delete';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import SelectOutlined from 'components/items/select-outlined';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// ---------------------------------------- SOFT TOOLS MANAGEMENT ---------------------------------------- \\
const SoftToolsManagement = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        soft_tools: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: '',
        soft_tool_type_id: -1,
        active: -1,
        soft_tool: null,
        openSoftToolCreate: false,
        openSoftToolUpdate: false,
        openSoftToolDelete: false,
        openSoftToolLinks: false,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            offset: 0,
            name: '',
            soft_tool_type_id: -1,
            active: -1,
        });
    };

    useEffect(() => {
        if (state.loading) {
            getAllSoftTools({
                offset: state.offset, 
                limit: state.limit,
                soft_tool_type_id: state.soft_tool_type_id,
                active: state.active,
                name: state.name,
            }).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        soft_tools: res.data.data.soft_tools
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('management.soft.tools')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>                    
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
                <Button
                    onClick={() => setState({ ...state, openSoftToolCreate: true })}
                    variant="contained"
                >
                    {t('add')}
                </Button>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 20 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={12} md={4} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={12} md={4} style={style.p10}>
                                <span style={style.cW}>{t('type')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span style={style.cW}>{t('active')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('tools')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <SelectOutlined
                                value={state.soft_tool_type_id}
                                onChange={e => onSelectSearch('soft_tool_type_id', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                {softToolTypes.map(softToolType => (
                                    <MenuItem key={softToolType.id} value={softToolType.id}>
                                        <span>{t(softToolType.label)}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.active}
                                onChange={e => onSelectSearch('active', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                <MenuItem value={0}>
                                    <span>{t('no')}</span>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <span>{t('yes')}</span>
                                </MenuItem>
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- SOFT TOOL LIST -------------------- */}
                <SoftToolsList
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- CREATE SOFT TOOL -------------------- */}
            {state.openSoftToolCreate && (
                <SoftToolCreate
                    onClose={() => setState({ ...state, openSoftToolCreate: false })}
                    refetch={() => setState({ ...state, openSoftToolCreate: false, loading: true })}
                />
            )}
        </>
    );
};

// ------------------------------------------- SOFT TOOLS LIST -------------------------------------------- \\
const SoftToolsList = ({ state, setState }) => {
    const { t } = useTranslation();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
        {/* ---------------------- SOFT TOOL DISPLAY ---------------------- */}
        <div className="full-container-content">
                {state.soft_tools.length > 0 ? (
                    state.soft_tools.map(soft_tool => (
                        <Fragment key={soft_tool.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{soft_tool.soft_tool_type.id === 1 ? soft_tool.number + " - " : ""}{getTranslation('name', soft_tool)}</span>
                                </Grid>
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{soft_tool.soft_tool_type.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{soft_tool.active === 1 ? t('yes') : t('no')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.taC, ...style.p10 }}>
                                    <Tooltip title={t('links.edit')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openSoftToolLinks: true, soft_tool })}
                                                className="list-btn"
                                            >
                                                <IconLink />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openSoftToolUpdate: true, soft_tool })}
                                                className="list-btn"
                                            >
                                                <IconCreate />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openSoftToolDelete: true, soft_tool })}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* ---------------------- SOFT TOOL UPDATE ---------------------- */}
            {state.openSoftToolUpdate && (
                <SoftToolUpdate
                    onClose={() => setState({ ...state, openSoftToolUpdate: false })}
                    refetch={() => setState({ ...state, openSoftToolUpdate: false, loading: true })}
                    soft_tool={state.soft_tool}
                />
            )}
            {/* ---------------------- SOFT TOOL DELETE ---------------------- */}
            {state.openSoftToolDelete && (
                <SoftToolDelete
                    onClose={() => setState({ ...state, openSoftToolDelete: false })}
                    refetch={() => setState({ ...state, openSoftToolDelete: false, loading: true })}
                    soft_tool={state.soft_tool}
                />
            )}
            {/* ---------------------- SOFT TOOL LINKS MODAL ---------------------- */}
            {state.openSoftToolLinks && (
                <SoftToolLinks
                    onClose={() => setState({ ...state, openSoftToolLinks: false })}
                    soft_tool={state.soft_tool}
                />
            )}
        </>
    );
};

export default SoftToolsManagement;
