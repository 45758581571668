import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Grid, MenuItem, Button } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/items/input-text';
import SelectOutlined from 'components/items/select-outlined';

// Style
import style from 'style';

// ---------------------------------------- FORMATION FORM PAGE 1 ---------------------------------------- \\
const FormationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- PLACE -------------------- */}
                        <div style={{ marginTop: 20 }}>
                            <b className="input-title">
                                {t('place')}
                            </b>
                            <SelectOutlined
                                value={state.place_id}
                                onChange={e => setState({ ...state, place_id: e.target.value })}
                            >
                                {state.places.map(place => (
                                    <MenuItem key={place.id} value={place.id}>
                                        <span>{place.name}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                            <div style={style.m5_0} />
                            <InputText
                                placeholder={t('details')}
                                value={state.place_details}
                                onChange={e => setState({ ...state, place_details: e.target.value })}
                                multiline
                            />
                        </div>
                        {/* -------------------- TYPE -------------------- */}
                        <div style={{ marginTop: 20 }}>
                            <b className="input-title">
                                {t('type')}
                            </b>
                            <SelectOutlined
                                value={state.type_id}
                                onChange={e => setState({ ...state, type_id: e.target.value })}
                            >
                                {state.types.map(type => (
                                    <MenuItem key={type.id} value={type.id}>
                                        <span>{type.name}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                            <div style={style.m5_0} />
                            <InputText
                                placeholder={t('details')}
                                value={state.type_details}
                                onChange={e => setState({ ...state, type_details: e.target.value })}
                                multiline
                            />
                        </div>
                        {/* -------------------- DESCRIPTION -------------------- */}
                        <div style={{ marginTop: 20 }}>
                            <b className="input-title">
                                {t('description.program')}
                            </b>
                            <InputText
                                placeholder={t('description.program')}
                                value={state.program}
                                onChange={e => setState({ ...state, program: e.target.value })}
                                multiline
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default FormationFormPage1;