import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Services
import { fileUrl } from 'services/helpers';
import { getUser } from 'services/requests';
import { getStoredUser, setStoredUser } from 'services/storage';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';

// Material-UI
import { Avatar, IconButton } from '@material-ui/core';
import { Settings as SettingsIcon, AccountCircle as ProfileIcon } from '@material-ui/icons';

// Style
import style from 'style';

const styles = {
    avatar: {
        margin: 'auto',
        width: 125,
        height: 125
    },
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
    },
};

// ---------------------------------------- PROFILE ---------------------------------------- \\
const Profile = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation();
    const USER_ID = getStoredUser()?.id;

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
    });

    useEffect(() => {
        getUser(USER_ID).then(res => {
            if (res?.status === 200) {
                const user = res.data.data.user;
                setStoredUser(user);
                setState({ ...state, loading: false, user });
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- AVATAR -------------------- */}
            <div style={{ padding: 12, textAlign: 'center' }}>
                {state.user.photo ? (
                    <Avatar 
                        style={styles.avatar} 
                        alt="profile-avatar"
                        src={fileUrl + state.user.photo} 
                    />
                ) : (
                    <ProfileIcon
                        style={{ ...styles.avatar, color: 'lightgray' }}
                    />
                )}
            </div>
            {/* -------------------- USER NAME -------------------- */}
            <div style={{ textAlign: 'center', fontSize: 14, padding: '0px 12px' }}>
                {state.user.first_name}
                <br />
                {state.user.last_name}
            </div>
            {/* -------------------- SETTINGS -------------------- */}
            <div style={styles.container}>
                <Tooltip title={t('settings')}
                    item={
                        <IconButton
                            onClick={() => history.push(`${match.url}/settings`)}
                            color="secondary"
                            style={{ padding: 8 }}
                        >
                            <SettingsIcon />
                        </IconButton>
                    }
                />
                <span style={style.fwB}>
                    {state.user?.role?.name}
                </span>
            </div>
        </>
    );
};

export default Profile;
