import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { updateSoftEvaluation, getSoftEvaluation, getCompanyList, getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import SoftEvaluationFormPage1 from './soft-evaluation-form/soft-evaluation-form-1';
import SoftEvaluationFormPage2 from './soft-evaluation-form/soft-evaluation-form-2';
import SoftEvaluationFormPage3 from './soft-evaluation-form/soft-evaluation-form-3';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ------------------------------------ SOFT EVALUATION UPDATE -------------------------------------- \\
const SoftEvaluationUpdate = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { evaluationId } = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loadingEvaluation: true,
        loadingResources: false,
        errorResources: false,
        loading: false,
        page: 1,
        companies: [],
        
        // Page 1
        evaluation_date: null,
        evaluation_context: '',
        company_id: null,
        company_name: '',
        company_manual_entry: false,
        assessors: [],
        assessor_company_id: null,
        assessor_id: null,
        assessor_name: '',
        assessor_manual_entry: false,
        authorizedRefetchAssessor: false,
        learners: [],
        learner_company_id: null,
        learner_id: null,
        authorizedRefetchLearner: false,

        // Page 2
        loadingSoftCategories: true,
        errorSoftCategories: false,
        soft_categories: [],
        softskills: [],
        
        // Page 3
        softskill: null,
        soft_level_ids: [],
        soft_levels: {},
        soft_level: null,
        observed_behaviors: {},
        openObservedBehavior: false,
        openBehavior: false,
    });

    useEffect(() => {
        if (state.loadingEvaluation) {
            getSoftEvaluation(evaluationId).then(res => {
                if (res?.status === 200) {
                    const soft_evaluation = res.data.data.soft_evaluation;

                    let observed_behaviors = {};
                    soft_evaluation.soft_levels.forEach(soft_level => {
                        observed_behaviors[soft_level.id] = soft_level.observed_behaviors || null;
                    });

                    setState({
                        ...state,
                        loadingEvaluation: false,
                        loadingResources: true,
                        evaluation_date: soft_evaluation.evaluation_date,
                        evaluation_context: soft_evaluation.evaluation_context || '',
                        company_id: soft_evaluation.company_id,
                        company_name: soft_evaluation.company_name,
                        company_manual_entry: soft_evaluation.company_id == null ? true : false,
                        assessor_company_id: soft_evaluation.assessor_company_id,
                        assessor_id: soft_evaluation.assessor_id,
                        assessor_name: soft_evaluation.assessor_name,
                        assessor_manual_entry: soft_evaluation.assessor_id == null ? true : false,
                        learner_company_id: soft_evaluation.learner_company_id,
                        learner_id: soft_evaluation.learner.id,
                        soft_level_ids: soft_evaluation.soft_levels.map(s => s.id),
                        observed_behaviors,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingEvaluation: false, errorResources: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingEvaluation]);

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getCompanyList(), getCompanyMembers(state.learner_company_id), getCompanyMembers(state.assessor_company_id || 1)])
            .then(axios.spread(function (res_companies, res_learners, res_assessors) {
                if (res_companies?.status === 200 && res_learners?.status === 200 && res_assessors?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        learners: res_learners.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        assessors: state.assessor_company_id ? res_assessors.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })) : [],
                    });
                }
                else {
                    console.log(res_companies);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {
            updateSoftEvaluation(evaluationId, {
                evaluation_date: moment(state.evaluation_date).format('YYYY-MM-DD'),
                evaluation_context: state.evaluation_context,
                company_id: state.company_id,
                company_name: state.company_name,
                learner_id: state.learner_id,
                assessor_id: state.assessor_id,
                assessor_name: state.assessor_name,
                soft_levels: Object.values(state.soft_levels).map(id => ({ soft_level_id: id, observed_behaviors: state.observed_behaviors[id] || null })),
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobipass/softskills/evaluation');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/softskills/evaluation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('evaluation.edit')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }}/>
            {/* -------------------- SOFT EVALUATION FORM -------------------- */}
            {(state.loadingEvaluation || state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <SoftEvaluationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <SoftEvaluationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <SoftEvaluationFormPage3 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default SoftEvaluationUpdate;
