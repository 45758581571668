import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getFormattedDate, getTimeDifference, getTranslation } from 'services/helpers';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- CLEA FORMATION EXPORT ---------------------------------------- \\
const CleaFormationExport = ({ clea_formations }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...',
        start: false,
    });

    function createCleaFormationExportXLSX(clea_formations) {
        if (clea_formations == null || clea_formations.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {

            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('clea.training'));

            // Headers
            worksheet.columns = [
                { header: t('name'), key: 'name', width: 25 },
                { header: t('company'), key: 'company', width: 25 },
                { header: t('date'), key: 'date', width: 15 },
                { header: t('time'), key: 'time', width: 20 },
                { header: t('duration'), key: 'duration', width: 15 },
                { header: t('trainers'), key: 'trainer', width: 50 },
                { header: t('trainers.number'), key: 'nb_trainers', width: 20 },
                { header: t('learners'), key: 'learner', width: 50 },
                { header: t('learners.number'), key: 'nb_learners', width: 20 },
                { header: t('clea.domain') + ' / ' + t('clea.sub.domain') + ' -> ' +  t('clea.competence'), key: 'clea_competences', width: 80 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Formations
            clea_formations.forEach((clea_formation, index) => {

                // ---------------------- TRAINERS ---------------------- \\
                let trainers = '';
                clea_formation.trainers.forEach(trainer => {
                    trainers += trainer.full_name + (trainer.company_name ? ' (' + trainer.company_name + ')' : '') + '\r\n';
                });

                // ---------------------- LEARNERS ---------------------- \\
                let learners = '';
                clea_formation.learners.forEach(learner => {
                    learners += learner.full_name + (learner.company_name ? ' (' + learner.company_name + ')' : '') + '\r\n';
                });

                // ---------------------- CLEA COMPETENCES ---------------------- \\
                clea_formation.clea_sessions.forEach(session => {

                    let clea = '';
                    clea_formation.clea_competences.forEach(competence => {
                        clea += competence.clea_domain_name + ' / ' + competence.clea_subdomain_name + ' -> ' + getTranslation('name', competence) + '\r\n';
                    });

                    worksheet.addRow({
                        name: clea_formation.name,
                        company: clea_formation.company?.name,
                        date: getFormattedDate(session.session_date),
                        time: session.start + ' - ' + session.end,
                        duration: getTimeDifference(session.start, session.end),
                        trainer: trainers,
                        nb_trainers: clea_formation.trainers?.length,
                        learner: learners,
                        nb_learners: clea_formation.learners?.length,
                        clea_competences: clea,
                    });
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('clea.trainings') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        onClick={() => createCleaFormationExportXLSX(clea_formations)}
                        disabled={state.start}
                        className="list-btn"
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default CleaFormationExport;
