import { Get, Post, Put, Delete } from './axios-config';

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- SIGN IN ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const signIn = (email, password, remember) => {
    let url = 'signin';
    return Post(url, { email: email, password: password, remember: remember });
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- SIGN OUT ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const signOut = () => {
    let url = 'logout';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- LOG ACTIVITY ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const logActivity = () => {
    let url = 'log_activity';
    return Get(url);
};


// ------------------------------------------------------------------------------------ \\
// ----------------------------------- CONFIRMATION LINK ------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const sendConfirmationLink = email => {
    let url = 'confirm';
    return Post(url, { email: email });
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ PASSWORD -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const sendPasswordCode = email => {
    let url = 'password?email=' + email;
    return Get(url);
};

export const updatePassword = (email, code, password) => {
    let url = 'password';
    return Post(url, { email: email, resetToken: code, password: password })
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- CONTACT ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const contact = data => {
    let url = 'contact';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- SEARCH LEARNERS -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const searchLearners = (type, data) => {
    let url = (type === 'skillbases') ? 'search' : 'soft_search';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------------- USERS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getUsers = (offset, limit, last_name, first_name, company_id, email, role_id) => {
    let url = 'users?offset=' + offset + '&limit=' + limit;

    if (last_name != null) {
        url += '&last_name=' + last_name;
    }
    if (first_name != null) {
        url += '&first_name=' + first_name;
    }
    if (company_id != null && company_id !== 0) {
        url += '&company_id=' + company_id;
    }
    if (email != null) {
        url += '&email=' + email;
    }
    if (role_id != null & role_id !== -1) {
        url += '&role_id=' + role_id;
    }

    return Get(url);
};

export const getUser = id => {
    let url = 'users/' + id;
    return Get(url);
};

export const addUser = data => {
    let url = 'users';
    return Post(url, data);
};

export const editUser = (id, data) => {
    let url = 'users/' + id;
    return Put(url, data);
};

export const editUserPhoto = (id, data) => {
    let url = 'users/' + id + '/photo';
    return Post(url, data, 'file');
};

export const deleteUser = id => {
    let url = 'users/' + id;
    return Delete(url);
};

export const importUsers = data => {
    let url = 'users_import';
    return Post(url, data, 'file');
};

export const getUsersResources = () => {
    let url = 'users_resources';
    return Get(url);
};

export const setCompaniesAccess = (id, data) => {
    let url = 'users/' + id + '/companies';
    return Post(url, data);
};

export const getHasAccesses = user_id => {
    let url = 'users/' + user_id + '/accesses';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- USERS LOCKED ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getUsersLocked = () => {
    let url = 'users_locked';
    return Get(url);
};

export const lockUser = id => {
    let url = 'users_locked';
    return Post(url, { user_id: id });
};

export const unlockUser = id => {
    let url = 'users_locked/' + id;
    return Put(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- SECTORS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getSectors = () => {
    let url = 'sectors';
    return Get(url);
};

export const addSector = data => {
    let url = 'sectors';
    return Post(url, data);
};

export const editSector = (id, data) => {
    let url = 'sectors/' + id;
    return Put(url, data);
};

export const deleteSector = id => {
    let url = 'sectors/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ SKILLBASES ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getSkillbases = () => {
    let url = 'skillbases';
    return Get(url);
};

export const getSkillbase = id => {
    let url = 'skillbases/' + id;
    return Get(url);
};

export const addSkillbase = data => {
    let url = 'skillbases';
    return Post(url, data);
};

export const editSkillbase = (id, data) => {
    let url = 'skillbases/' + id;
    return Put(url, data);
};

export const deleteSkillbase = id => {
    let url = 'skillbases/' + id;
    return Delete(url);
};

export const getInactiveSkillbases = () => {
    let url = 'inactive_skillbases';
    return Get(url);
};

export const getSkillbasesLinkedWithSoftskills = () => {
    let url = 'skillbases_linked_with_softskills';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- SKILLBASES IMPORT --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const importSkillbase = data => {
    let url = 'skillbases_imports';
    return Post(url, data, 'file');
};

export const importTranslations = (skillbase_id, data) => {
    let url = 'skillbases_imports/' + skillbase_id;
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------------- UNITS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addUnit = (skillbase_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units';
    return Post(url, data);
};

export const editUnit = (skillbase_id, unit_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id;
    return Put(url, data);
};

export const deleteUnit = (skillbase_id, unit_id) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- LEARNING OUTCOMES -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addLearningOutcome = (skillbase_id, unit_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes';
    return Post(url, data);
};

export const editLearningOutcome = (skillbase_id, unit_id, learning_outcome_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id;
    return Put(url, data);
};

export const deleteLearningOutcome = (skillbase_id, unit_id, learning_outcome_id) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ COMPETENCES ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addCompetence = (skillbase_id, unit_id, learning_outcome_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/competences';
    return Post(url, data);
};

export const editCompetence = (skillbase_id, unit_id, learning_outcome_id, competence_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/competences/' + competence_id;
    return Put(url, data);
};

export const deleteCompetence = (skillbase_id, unit_id, learning_outcome_id, competence_id) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/competences/' + competence_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ KNOWLEDGES ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const addKnowledge = (skillbase_id, unit_id, learning_outcome_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/knowledges';
    return Post(url, data);
};

export const editKnowledge = (skillbase_id, unit_id, learning_outcome_id, knowledge_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/knowledges/' + knowledge_id;
    return Put(url, data);
};

export const deleteKnowledge = (skillbase_id, unit_id, learning_outcome_id, knowledge_id) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/knowledges/' + knowledge_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- SKILLS --------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addSkill = (skillbase_id, unit_id, learning_outcome_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/skills';
    return Post(url, data);
};

export const editSkill = (skillbase_id, unit_id, learning_outcome_id, skill_id, data) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/skills/' + skill_id;
    return Put(url, data);
};

export const deleteSkill = (skillbase_id, unit_id, learning_outcome_id, skill_id) => {
    let url = 'skillbases/' + skillbase_id + '/units/' + unit_id + '/learning_outcomes/' + learning_outcome_id + '/skills/' + skill_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------- EVALUATIONS ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getEvaluations = data => {
    let url = 'evaluations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null) {
        url += '&name=' + data.name;
    }
    if (data.company_id != null && data.company_id !== -1) {
        url += '&company_id=' + data.company_id;
    }
    if (data.skillbase_id != null && data.skillbase_id !== -1) {
        url += '&skillbase_id=' + data.skillbase_id;
    }
    if (data.assessor != null) {
        url += '&assessor=' + data.assessor;
    }
    if (data.learner != null) {
        url += '&learner=' + data.learner;
    }
    if (data.export_excel != null) {
        url += '&export=1';
    }
    if (data.param != null && data.order) {
        url += '&order_by[param]=' + data.param + '&order_by[order]=' + data.order;
    }

    return Get(url);
};

export const getEvaluation = id => {
    let url = 'evaluations/' + id;
    return Get(url);
};

export const getEvaluationsForUser = id => {
    let url = 'user_evaluations/' + id;
    return Get(url);
};

export const createEvaluation = data => {
    let url = 'evaluations';
    return Post(url, data);
};

export const updateEvaluation = (id, data) => {
    let url = 'evaluations/' + id;
    return Put(url, data);
};

export const deleteEvaluation = id => {
    let url = 'evaluations/' + id;
    return Delete(url);
};

export const setPortfolios = (id, data) => {
    let url = 'evaluations/' + id + '/portfolios';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- CERTIFICATIONS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getCertifications = (offset, limit, certification_date, skillbase_id, assessor, learner, export_excel) => {
    let url = 'certifications?offset=' + offset + '&limit=' + limit;

    if (certification_date != null) {
        url += '&certification_date=' + certification_date;
    }
    if (skillbase_id != null && skillbase_id !== -1) {
        url += '&skillbase_id=' + skillbase_id;
    }
    if (assessor != null) {
        url += '&assessor=' + assessor;
    }
    if (learner != null) {
        url += '&learner=' + learner;
    }
    if (export_excel != null) {
        url += '&export=1';
    }

    return Get(url);
};

export const getCertification = id => {
    let url = 'certifications/' + id;
    return Get(url);
};

export const createCertification = data => {
    let url = 'certifications';
    return Post(url, data, 'file');
};

export const updateCertification = (id, data) => {
    let url = 'certifications/' + id;
    return Post(url, data, 'file');
};

export const deleteCertification = id => {
    let url = 'certifications/' + id;
    return Delete(url);
};

export const setCertificationDocument = (id, data) => {
    let url = 'certifications/' + id + '/document';
    return Post(url, data, 'file');
};

export const getCertificationsResources = () => {
    let url = 'evaluations_resources?certification=true';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- PORTOFLIOS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getPortfolios = user_id => {
    let url = 'users/' + user_id + '/portfolios';
    return Get(url);
};

export const getPortfolioContent = (user_id, skillbase_id) => {
    let url = 'users/' + user_id + '/portfolios/' + skillbase_id;
    return Get(url);
};

export const getSoftPortfolios = user_id => {
    let url = 'users/' + user_id + '/soft_portfolios';
    return Get(url);
};

export const getCompanyCertificate = (user_id, skillbase_id, company_id) => {
    let url = 'company_certificate?user_id=' + user_id + '&skillbase_id=' + skillbase_id;

    if (company_id != null) {
        url += '&company_id=' + company_id;
    }

    return Get(url);
};

export const getCompanySoftCertificate = (user_id, company_id) => {
    let url = 'company_soft_certificate?user_id=' + user_id;

    if (company_id != null) {
        url += '&company_id=' + company_id;
    }

    return Get(url);
};

export const getLearners = (offset, limit, last_name, first_name, company_id) => {
    let url = 'users?role_id=5&offset=' + offset + '&limit=' + limit;

    if (last_name != null) {
        url += '&last_name=' + last_name;
    }
    if (first_name != null) {
        url += '&first_name=' + first_name;
    }
    if (company_id != null && company_id !== -1) {
        url += '&company_id=' + company_id;
    }

    return Get(url);
};

export const getCurriculum = user_id => {
    let url = 'users/' + user_id + '/curriculum';
    return Get(url, 'blob');
};

export const setSkillbaseComment = (skillbase_id, user_id, data) => {
    let url = 'users/' + user_id + '/skillbases/' + skillbase_id + '/comment';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------------- ROLES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getRoles = () => {
    let url = 'roles';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ LANGUAGES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getLanguages = () => {
    let url = 'languages';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- COUNTRIES -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCountries = () => {
    let url = 'countries';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ COMPANIES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCompanies = (offset, limit, name, address, email, skillbase_id, export_excel, client_id) => {
    let url = 'companies?offset=' + offset + '&limit=' + limit;

    if (name != null) {
        url += '&name=' + name;
    }
    if (address != null) {
        url += '&address=' + address;
    }
    if (email != null) {
        url += '&email=' + email;
    }
    if (skillbase_id != null && skillbase_id !== -1) {
        url += '&skillbase_id=' + skillbase_id;
    }
    if (export_excel != null) {
        url += '&export=1';
    }
    if (client_id != null) {
        url += '&client_id=' + client_id;
    }

    return Get(url);
};

export const getCompany = id => {
    let url = 'companies/' + id;
    return Get(url);
};

export const addCompany = data => {
    let url = 'companies';
    return Post(url, data)
};

export const editCompany = (id, data) => {
    let url = 'companies/' + id;
    return Put(url, data);
};

export const deleteCompany = id => {
    let url = 'companies/' + id;
    return Delete(url);
};

export const editCompanyLogo = (id, data) => {
    let url = 'companies/' + id + '/photo';
    return Post(url, data, 'file');
};

export const getCompanyList = () => {
    let url = 'companies_list';
    return Get(url);
};

export const getCompanyMembers = (id, certification) => {
    let url = 'companies/' + id + '/members';

    if (certification != null) {
        url += '?certification=true';
    }

    return Get(url);
};

export const getCompanySkillbases = id => {
    let url = 'companies/' + id + '/skillbases';
    return Get(url);
};

export const setCompanySkillbases = (id, data) => {
    let url = 'companies/' + id + '/skillbases';
    return Post(url, data);
};

export const getUsersListPerMonth = (company_id, data) => {
    let url = 'companies/' + company_id + '/usersList?from=' + data.from + '&to=' + data.to;
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------- COMPANIES ACCESSES --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCompaniesAccessible = () => {
    let url = 'companies_accessible';
    return Get(url);
};

export const getCompaniesNotAccessible = (offset, limit, search) => {
    let url = 'companies_not_accessible?offset=' + offset + '&limit=' + limit + '&search=' + search;
    return Get(url);
};

export const sendCompanyRequest = company_id => {
    let url = 'companies/' + company_id + '/requests';
    return Post(url);
};

export const removeCompanyRequest = (company_id, user_id) => {
    let url = 'companies/' + company_id + '/requests/' + user_id;
    return Delete(url);
};

export const getCompanyAccesses = company_id => {
    let url = 'companies/' + company_id + '/requests';
    return Get(url);
};

export const addAccess = (company_id, user_id) => {
    let url = 'companies/' + company_id + '/users/' + user_id;
    return Post(url);
};

export const removeAccess = (company_id, user_id) => {
    let url = 'companies/' + company_id + '/users/' + user_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- FORMATIONS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFormations = data => {
    let url = 'formations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.skillbase_id != null && data.skillbase_id !== -1) {
        url += '&skillbase_id=' + data.skillbase_id;
    }
    if (data.company_id != null && data.company_id !== -1) {
        url += '&company_id=' + data.company_id;
    }
    if (data.trainer != null && data.trainer !== '') {
        url += '&trainer=' + data.trainer;
    }
    if (data.learner != null && data.learner !== '') {
        url += '&learner=' + data.learner;
    }
    if (data.export_excel != null) {
        url += '&export=1';
    }
    if (data.param != null && data.order) {
        url += '&order_by[param]=' + data.param + '&order_by[order]=' + data.order;
    }

    return Get(url);
};

export const getFormation = id => {
    let url = 'formations/' + id;
    return Get(url);
};

export const addFormation = data => {
    let url = 'formations';
    return Post(url, data);
};

export const editFormation = (id, data) => {
    let url = 'formations/' + id;
    return Put(url, data);
};

export const deleteFormation = id => {
    let url = 'formations/' + id;
    return Delete(url);
};

export const getUserFormations = id => {
    let url = 'users/' + id + '/formations';
    return Get(url);
};

export const signFormation = (id, present) => {
    let url = 'formations/' + id + '/signatures';
    return Put(url, { present: present });
};

export const getFormationsResources = () => {
    let url = 'formations_resources';
    return Get(url);
};

export const getIndividualExportFormation = (user_id, date_from, date_to) => {
    let url = 'users/' + user_id + '/formations_export?date_from=' + date_from + '&date_to=' + date_to;
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- SIGNATURES ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFormationsSignatures = () => {
    let url = 'formations_signatures';
    return Get(url);
};

export const getSignatures = () => {
    let url = 'pending_signatures';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ MOBILITIES ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getMobilities = (offset, limit, learner, date, origin, host, export_excel) => {
    let url = 'mobilities?offset=' + offset + '&limit=' + limit;

    if (learner != null) {
        url += '&learner=' + learner;
    }
    if (date != null) {
        url += '&date=' + date;
    }
    if (origin != null) {
        url += '&origin=' + origin;
    }
    if (host != null) {
        url += '&host=' + host;
    }
    if (export_excel != null) {
        url += '&export=1';
    }

    return Get(url);
};

export const getMobility = id => {
    let url = 'mobilities/' + id;
    return Get(url);
};

export const createMobility = data => {
    let url = 'mobilities';
    return Post(url, data, 'file');
};

export const updateMobility = (id, data) => {
    let url = 'mobilities/' + id;
    return Post(url, data, 'file');
};

export const deleteMobility = id => {
    let url = 'mobilities/' + id;
    return Delete(url);
};

export const setMobilityDocument = (id, data) => {
    let url = 'mobilities/' + id + '/document';
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- TOOLS --------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getTools = (display, offset, limit, name, tool_type_id, skillbase_id, role_id, active) => {
    let url = 'tools?';

    if (display) {
        url += 'display=' + display;
    }
    else {
        url += 'offset=' + offset + '&limit=' + limit;
    }

    if (name != null) {
        url += '&name=' + name;
    }
    if (tool_type_id != null && tool_type_id !== -1) {
        url += '&tool_type_id=' + tool_type_id;
    }
    if (skillbase_id != null && skillbase_id !== -1) {
        url += '&skillbase_id=' + skillbase_id;
    }
    if (role_id != null && role_id !== -1) {
        url += '&role_id=' + role_id;
    }
    if (active != null && active !== -1) {
        url += '&active=' + active;
    }

    return Get(url);
};

export const getTool = id => {
    let url = 'tools/' + id;
    return Get(url);
};

export const getToolsResources = () => {
    let url = 'tools_resources';
    return Get(url);
};

export const addTool = data => {
    let url = 'tools';
    return Post(url, data);
};

export const editTool = (id, data) => {
    let url = 'tools/' + id;
    return Put(url, data);
};

export const deleteTool = id => {
    let url = 'tools/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- LINKS TOOLS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addLink = (tool_id, data) => {
    let url = 'tools/' + tool_id + '/links';
    return Post(url, data);
};

export const editLink = (tool_id, link_id, data) => {
    let url = 'tools/' + tool_id + '/links/' + link_id;
    return Put(url, data);
};

export const deleteLink = (tool_id, link_id) => {
    let url = 'tools/' + tool_id + '/links/' + link_id;
    return Delete(url);
};

export const uploadFileLink = (tool_id, link_id, data) => {
    let url = 'tools/' + tool_id + '/links/' + link_id + '/upload';
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- DIRECTORY ---------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getDirectoryUsers = (offset, limit, last_name, first_name, email, role_id, company) => {
    let url = 'directory?offset=' + offset + '&limit=' + limit;

    if (last_name != null) {
        url += '&last_name=' + last_name;
    }
    if (first_name != null) {
        url += '&first_name=' + first_name;
    }
    if (email != null) {
        url += '&email=' + email;
    }
    if (role_id != null && role_id !== -1) {
        url += '&role_id=' + role_id;
    }
    if (company != null) {
        url += '&company=' + company;
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- CLIENTS ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getClients = (offset, limit, name, address, contact, to_export) => {
    let url = 'clients?offset=' + offset + '&limit=' + limit;

    if (name != null && name !== '') {
        url += '&name=' + name;
    }
    if (address != null && address !== '') {
        url += '&address=' + address;
    }
    if (contact != null && contact !== '') {
        url += '&contact=' + contact;
    }
    if (to_export) {
        url += '&export=1';
    }
    
    return Get(url);
};

export const getClient = clientId => {
    let url = 'clients/' + clientId;
    return Get(url);
};

export const createClient = data => {
    let url = 'clients';
    return Post(url, data);
};

export const updateClient = (clientId, data) => {
    let url = 'clients/' + clientId;
    return Put(url, data);
};

export const deleteClient = clientId => {
    let url = 'clients/' + clientId;
    return Delete(url);
};

export const getLinkedCompaniesFromClient = clientId => {
    let url = 'clients/' + clientId + '/companies';
    return Get(url);
};

export const associateCompaniesToClient = (clientId, data) => {
    let url = 'clients/' + clientId + '/companies';
    return Put(url, data);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- CONTRACTS ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getContracts = (offset, limit, reference, client_id, contract_start_date, contract_end_date) => {
    let url = 'contracts?offset=' + offset + '&limit=' + limit;

    if (reference != null) {
        url += '&reference=' + reference;
    }
    if (client_id != null && client_id !== -1) {
        url += '&client_id=' + client_id;
    }
    if (contract_start_date != null) {
        url += '&contract_start_date=' + contract_start_date;
    }
    if (contract_end_date != null) {
        url += '&contract_end_date=' + contract_end_date;
    }
    
    return Get(url);
};

export const getContract = contractId => {
    let url = 'contracts/' + contractId;
    return Get(url, contractId);
};

export const createContract = data => {
    let url = 'contracts';
    return Post(url, data, 'file');
};

export const updateContract = (contractId, data) => {
    let url = 'contracts/' + contractId;
    return Post(url, data, 'file');
};

export const deleteContract = contractId => {
    let url = 'contracts/' + contractId;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------------- BILLS --------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getBills = data => {
    let url = 'bills?offset=' + data.offset + '&limit=' + data.limit;

    if (data.paid != null && data.paid !== -1) {
        url += '&paid=' + data.paid;
    }
    if (data.bill_date != null) {
        url += '&bill_date=' + data.bill_date;
    }
    if (data.number_bill !== '') {
        url += '&number_bill=' + data.number_bill;
    }
    if (data.contract_id != null && data.contract_id !== -1) {
        url += '&contract_id=' + data.contract_id;
    }
    if (data.client_id != null) {
        url += '&client_id=' + data.client_id;
    }
    if (data.client_name !== '') {
        url += '&client_name=' + data.client_name;
    }
    if (data.export_excel != null) {
        url += '&export=1';
    }
    
    return Get(url);
};

export const getBill = billId => {
    let url = 'bills/' + billId;
    return Get(url, billId);
};

export const createBill = data => {
    let url = 'bills';
    return Post(url, data);
};

export const updateBill = (billId, data) => {
    let url = 'bills/' + billId;
    return Put(url, data);
};

export const deleteBill = billId => {
    let url = 'bills/' + billId;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ SUBSCRIPTIONS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getSubscriptions = (offset, limit, start_date, end_date, subscription_type_id, company_id, bill_id) => {
    let url = 'subscriptions?offset=' + offset + '&limit=' + limit;

    if (start_date != null) {
        url +='&start_date=' + start_date;
    }
    if (end_date != null) {
        url +='&end_date=' + end_date;
    }
    if (subscription_type_id != null && subscription_type_id !== -1) {
        url +='&subscription_type_id=' + subscription_type_id;
    }
    if (company_id != null && company_id !== -1) {
        url +='&company_id=' + company_id;
    }
    if (bill_id != null && bill_id !== -1) {
        url +='&bill_id=' + bill_id;
    }
    
    return Get(url);
};

export const createSubscription = data => {
    let url = 'subscriptions';
    return Post(url, data);
};

export const updateSubscription = (id, data) => {
    let url = 'subscriptions/' + id;
    return Put(url, data);
};

export const deleteSubscription = id => {
    let url = 'subscriptions/' + id;
    return Delete(url);
};

export const generateSubscriptions = data => {
    let url = 'subscription_generations';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ STATISTICS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getGlobalStatistics = data => {
    let url = 'statistics/global?module=' + data.module + '&start_date=' + data.start_date + '&end_date=' + data.end_date;

    if (data.company_id != null) {
        url += '&company_id=' + data.company_id;
    }

    return Get(url);
};

export const getLearnerStatistics = data => {
    let url = 'statistics/learner?module=' + data.module + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&learner_id=' + data.learner_id;
    return Get(url);
};

export const getFormationPercentage = (type, id) => {
    let url = 'statistics/' + type + '/formation_coverage?';

    if (type === 'company') {
        url += 'company_id=' + id;
    }
    if (type === 'learner') {
        url += 'user_id=' + id;
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- FORMATION  MODELS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getFormationModels = data => {
    let url = 'formation_models?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null) {
        url += '&name=' + data.name;
    }
    if (data.company_id != null) {
        url += '&company_id=' + data.company_id;
    }
    if (data.skillbase_id != null && data.skillbase_id !== -1) {
        url += '&skillbase_id=' + data.skillbase_id;
    }
    if (data.export_excel != null && data.export_excel) {
        url += '&export=1';
    }

    return Get(url);
};

export const getCompanyFormationModels = (company_id) => {
    let url = 'company/' + company_id + '/formation_models';
    return Get(url);
};

export const getFormationModel = formation_model_id => {
    let url = 'formation_models/' + formation_model_id;
    return Get(url, formation_model_id);
};

export const createFormationModel = data => {
    let url = 'formation_models';
    return Post(url, data);
};

export const deleteFormationModel = id => {
    let url = 'formation_models/' + id;
    return Delete(url);
};

export const updateFormationModel = (formation_model_id, data) => {
    let url = 'formation_models/' + formation_model_id;
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- HARD POSITIONS ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getHardPositionsForUser = (user_id, data) => {
    let url = 'users/' + user_id + '/hard_positions?offset=' + data.offset + '&limit=' + data.limit;

    if (data.date != null) {
        url += '&date=' + data.date;
    }
    if (data.skillbase_id != null) {
        url += '&skillbase_id=' + data.skillbase_id;
    }
    if (data.assessor != null && data.assessor !== '') {
        url += '&assessor_name=' + data.assessor;
    }

    return Get(url);
};

export const getHardPosition = hard_position_id => {
    let url = 'hard_positions/' + hard_position_id;
    return Get(url);
};

export const createHardPosition = data => {
    let url = 'hard_positions';
    return Post(url, data);
};

export const updateHardPosition = (hard_position_id, data) => {
    let url = 'hard_positions/' + hard_position_id;
    return Post(url, data); 
};

export const deleteHardPosition = hard_position_id => {
    let url = 'hard_positions/' + hard_position_id;
    return Delete(url); 
};

export const getSkillbaseWithPositionForUser = user_id => {
    let url = 'users/' + user_id + '/hard_positions/skillbases';
    return Get(url);
};

export const getHardPositionsForUserBySkillbase = (offset, limit, user_id, skillbase_id, from_date, to_date) => {
    let url = 'users/' + user_id + '/hard_positions/skillbases/' + skillbase_id + '?offset=' + offset + '&limit=' + limit;

    if (from_date != null) {
        url += '&from_date=' + from_date;
    }
    if (to_date != null) {
        url += '&to_date=' + to_date;
    }

    return Get(url);
};

export const getLastPositionForUser = (user_id, skillbase_id, hard_position_id) => {
    let url = 'users/' + user_id + '/last_hard_position?skillbase_id=' + skillbase_id;

    if (hard_position_id != null) {
        url += '&hard_position_id=' + hard_position_id;
    }

    return Get(url);
};

export const getHardPositionsDocumentResources = (user_id, company_id) => {
    let url = 'users/' + user_id + '/hard_positions_document';

    if (company_id != null) {
        url += '?company_id=' + company_id;
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------- SOFT CATEGORIES ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getSoftCategories = user_id => {
    let url = 'soft_categories';

    if (user_id != null) {
        url += '?user_id=' + user_id;
    }

    return Get(url);
};

export const createSoftCategory = data => {
    let url = 'soft_categories';
    return Post(url, data);
};

export const updateSoftCategory = (data, id) => {
    let url = 'soft_categories/' + id;
    return Post(url, data);
};

export const deleteSoftCategory = id => {
    let url = 'soft_categories/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- SOFT SKILL -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getSoftSkill = id => {
    let url = 'softskills/' + id;
    return Get(url);
};

export const createSoftSkill = (soft_category_id, data) => {
    let url = 'soft_categories/' + soft_category_id + '/softskills';
    return Post(url, data);
};

export const updateSoftSkill = (id, data) => {
    let url = 'softskills/' + id;
    return Post(url, data);
};

export const deleteSoftSkill = id => {
    let url = 'softskills/' + id;
    return Delete(url);
};

export const updateSoftLevel = (id, data) => {
    let url = 'soft_levels/' + id;
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- SOFT EVALUATIONS ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getSoftEvaluations = ({ offset, limit, evaluation_date, company_name, assessor_name, learner_name }) => {
    let url = 'soft_evaluations?offset=' + offset + '&limit=' + limit;

    if (evaluation_date != null) {
        url += '&evaluation_date=' + evaluation_date;
    }
    if (company_name !== '') {
        url += '&company_name=' + company_name;
    }
    if (assessor_name !== '') {
        url += '&assessor_name=' + assessor_name;
    }
    if (learner_name !== '') {
        url += '&learner_name=' + learner_name;
    }

    return Get(url);
};

export const getSoftEvaluation = soft_evaluation_id => {
    let url = 'soft_evaluations/' + soft_evaluation_id;
    return Get(url);
};

export const getUserSoftEvaluations = user_id => {
    let url = 'users/' + user_id + '/soft_evaluations';
    return Get(url);
};

export const createSoftEvaluation = data => {
    let url = 'soft_evaluations';
    return Post(url, data);
};

export const updateSoftEvaluation = (id, data) => {
    let url = 'soft_evaluations/' + id;
    return Post(url, data);
};

export const deleteSoftEvaluation = id => {
    let url = 'soft_evaluations/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- SOFT UNITS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const createSoftUnit = data => {
    let url = 'soft_units';
    return Post(url, data);
};

export const updateSoftUnit = (id, data) => {
    let url = 'soft_units/' + id;
    return Post(url, data);
};

export const deleteSoftUnit = id => {
    let url = 'soft_units/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------- SPECIFIC BEHAVIORS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const createSpecificBehavior = data => {
    let url = 'specific_behaviors';
    return Post(url, data);
};

export const updateSpecificBehavior = (id, data) => {
    let url = 'specific_behaviors/' + id;
    return Post(url, data);
};

export const deleteSpecificBehavior = id => {
    let url = 'specific_behaviors/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- SOFT POSITIONS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getSoftPositions = user_id => {
    let url = 'users/' + user_id + '/soft_positions';
    return Get(url);
};

export const createSoftPosition = data => {
    let url = 'soft_positions';
    return Post(url, data);
};

export const updateSoftPosition = (id, data) => {
    let url = 'soft_positions/' + id;
    return Post(url, data);
};

export const deleteSoftPosition = id => {
    let url = 'soft_positions/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------ SOFT TOOLS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getAllSoftTools = data => {
    let url = 'soft_tools?offset=' + data.offset + '&limit=' + data.limit;

    if (data.soft_tool_type_id != null && data.soft_tool_type_id !== -1) {
        url += '&soft_tool_type_id=' + data.soft_tool_type_id;
    }
    if (data.active != null && data.active !== -1) {
        url += '&active=' + data.active;
    }
    if (data.name != null) {
        url += '&name=' + data.name;
    }

    return Get(url);
};

export const getSoftTool = soft_tool_id => {
    let url = 'soft_tools/' + soft_tool_id;
    return Get(url);
};

export const addSoftTool = data => {
    let url = 'soft_tools';
    return Post(url, data);
};

export const updateSoftTool = (soft_tool_id, data) => {
    let url = 'soft_tools/' + soft_tool_id;
    return Post(url, data);
};

export const deleteSoftTool = soft_tool_id => {
    let url = 'soft_tools/' + soft_tool_id;
    return Delete(url);
};

export const getToolCards = data => {
    let url = 'tool_cards';
    return Post(url, data);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- SOFT TOOLS LINKS -------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const addSoftToolLink = (soft_tool_id, data) => {
    let url = 'soft_tools/' + soft_tool_id + '/links';
    return Post(url, data);
};

export const updateSoftToolLink = (soft_tool_id, soft_tool_link_id, data) => {
    let url = 'soft_tools/' + soft_tool_id + '/links/' + soft_tool_link_id;
    return Put(url, data);
};

export const deleteSoftToolLink = (soft_tool_id, soft_tool_link_id) => {
    let url = 'soft_tools/' + soft_tool_id + '/links/' + soft_tool_link_id;
    return Delete(url);
};

export const uploadSoftToolLink = (soft_tool_id, soft_tool_link_id, data) => {
    let url = 'soft_tools/' + soft_tool_id + '/links/' + soft_tool_link_id + '/upload';
    return Post(url, data, 'file');
};

// ------------------------------------------------------------------------------------ \\
// ----------------------------------- BEHAVIORS -------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const createBehavior = data => {
    let url = 'behaviors';
    return Post(url, data);
};

export const updateBehavior = (id, data) => {
    let url = 'behaviors/' + id;
    return Post(url, data);
};

export const deleteBehavior = id => {
    let url = 'behaviors/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- SOFT FORMATIONS ----------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getAllSoftFormations = data => {
    let url = 'soft_formations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.company_id != null && data.company_id !== -1) {
        url += '&company_id=' + data.company_id;
    }
    if (data.soft_training_id != null && data.soft_training_id !== -1) {
        url += '&soft_training_id=' + data.soft_training_id;
    }
    if (data.softskill_id != null && data.softskill_id !== -1) {
        url += '&softskill_id=' + data.softskill_id;
    }
    if (data.trainer != null && data.trainer !== '') {
        url += '&trainer=' + data.trainer;
    }
    if (data.learner != null && data.learner !== '') {
        url += '&learner=' + data.learner;
    }

    return Get(url);
};

export const getSoftFormation = soft_formation_id => {
    let url = 'soft_formations/' + soft_formation_id;
    return Get(url);
};

export const getSoftFormationsResources = () => {
    let url = 'soft_formations_resources';
    return Get(url);
};

export const updateSoftFormation = (soft_formation_id, data) => {
    let url = 'soft_formations/' +  soft_formation_id;
    return Put(url, data);
};

export const createSoftFormation = data => {
    let url = 'soft_formations';
    return Post(url, data);
};

export const deleteSoftFormation = id => {
    let url = 'soft_formations/' + id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------------------ NEWS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const getNews = data => {
    let url = 'news?offset=' + data.offset + '&limit=' + data.limit;

    if (data.title != null && data.title !== '') {
        url += '&title=' + data.title;
    }
    if (data.description != null && data.description !== '') {
        url += '&description=' + data.description;
    }
    if (data.publication_date != null) {
        url += '&publication_date=' + data.publication_date;
    }
    if (data.status != null && data.status !== -1) {
        url += '&status=' + data.status;
    }

    return Get(url);
};

export const getNewsById = news_id => {
    let url = 'news/' + news_id;
    return Get(url);
};

export const createNews = data => {
    let url = 'news';
    return Post(url, data, 'file');
};

export const updateNews = (news_id, data) => {
    let url = 'news/' + news_id;
    return Post(url, data, 'file');
};

export const deleteNews = news_id => {
    let url = 'news/' + news_id;
    return Delete(url);
};

export const getNewsReadingStatus = user_id => {
    let url = 'users/' + user_id + '/news';
    return Get(url);
};

export const setLastReadingNews = user_id => {
    let url = 'users/' + user_id + '/news';
    return Post(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------------- CLEA --------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCleaDomains = () => {
    let url = 'clea_domains';
    return Get(url);
};

export const createCleaDomain = data => {
    let url = 'clea_domains';
    return Post(url, data);
};

export const editCleaDomain = (clea_domain_id, data) => {
    let url = 'clea_domains/' + clea_domain_id;
    return Post(url, data);
};

export const deleteCleaDomain = domain_id => {
    let url = 'clea_domains/' + domain_id;
    return Delete(url);
};

export const createCleaSubDomain = (domain_id, data) => {
    let url = 'clea_domains/' + domain_id + '/clea_subdomains';
    return Post(url, data);
};

export const editCleaSubDomain = (clea_sub_domain_id, data) => {
    let url = 'clea_subdomains/' + clea_sub_domain_id;
    return Post(url, data);
};

export const deleteCleaSubDomain = clea_sub_domain_id => {
    let url = 'clea_subdomains/' + clea_sub_domain_id;
    return Delete(url);
};

export const createCleaCompetence = (clea_sub_domain_id, data) => {
    let url = 'clea_subdomains/' + clea_sub_domain_id + '/clea_competences';
    return Post(url, data);
};

export const editCleaCompetence = (clea_competence_id, data) => {
    let url = 'clea_competences/' + clea_competence_id;
    return Post(url, data);
};

export const deleteCleaCompetence = clea_competence_id => {
    let url = 'clea_competences/' + clea_competence_id;
    return Delete(url);
};

export const getLinkedToLearningOutcome = learning_outcome_id => {
    let url = 'learning_outcomes/' + learning_outcome_id + '/clea_competences';
    return Get(url);
};

export const addLinkToLearningOutcome = (learning_outcome_id, data) => {
    let url = 'learning_outcomes/' + learning_outcome_id + '/clea_competences';
    return Post(url, data);
};

export const getLearningOutcomeLinkedToCleaCompetence = clea_competence_id => {
    let url = 'clea_competences/' + clea_competence_id + '/learning_outcomes';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- CLEA FORMATIONS ---------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCleaFormations = data => {
    let url = 'clea_formations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.formation_date != null) {
        url += '&formation_date=' + data.formation_date;
    }
    if (data.company_id != null) {
        url += '&company_id=' + data.company_id;
    }
    if (data.trainer != null && data.trainer !== '') {
        url += '&trainer=' + data.trainer;
    }
    if (data.learner != null && data.learner !== '') {
        url += '&learner=' + data.learner;
    }

    return Get(url);
};

export const getCleaFormation = clea_formation_id => {
    let url = 'clea_formations/' + clea_formation_id;
    return Get(url);
};

export const getCleaFormationsForUser = (user_id, data) => {
    let url = 'users/' + user_id + '/clea_formations?offset=' + data.offset + '&limit=' + data.limit;

    if (data.name != null && data.name !== '') {
        url += '&name=' + data.name;
    }
    if (data.formation_date != null) {
        url += '&formation_date=' + data.formation_date;
    }
    if (data.company_id != null) {
        url += '&company_id=' + data.company_id;
    }
    if (data.trainer != null && data.trainer !== '') {
        url += '&trainer=' + data.trainer;
    }

    return Get(url);
};

export const getCleaFormationsResources = () => {
    let url = 'clea_formations_resources';
    return Get(url);
};

export const createCleaFormation = data => {
    let url = 'clea_formations';
    return Post(url, data);
};

export const updateCleaFormation = (clea_formation_id, data) => {
    let url = 'clea_formations/' + clea_formation_id;
    return Put(url, data);
};

export const deleteCleaFormation = clea_formation_id => {
    let url = 'clea_formations/' + clea_formation_id;
    return Delete(url);
};

export const createCleaDocument = (clea_formation_id, data) => {
    let url = 'clea_formations/' + clea_formation_id + '/clea_formation_documents';
    return Post(url, data);
};

export const deleteCleaDocument = document_id => {
    let url = 'clea_formation_documents/' + document_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// ---------------------------------- CLEA COURSE ------------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCleaCourseForUser = learner_id => {
    let url = 'users/' + learner_id + '/clea_course';
    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// -------------------------------- CLEA POSITIONS ------------------------------------ \\
// ------------------------------------------------------------------------------------ \\

export const createCleaPosition = data => {
    let url = 'clea_positions';
    return Post(url, data);
};

export const updateCleaPosition = (clea_position_id, data) => {
    let url = 'clea_positions/' + clea_position_id;
    return Post(url, data);
};

export const deleteCleaPosition = clea_position_id => {
    let url = 'clea_positions/' + clea_position_id;
    return Delete(url);
};

// ------------------------------------------------------------------------------------ \\
// --------------------------------- CLEA EVALUATIONS --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const createCleaEvaluation = data => {
    let url = 'clea_evaluations';
    return Post(url, data);
};

export const updateCleaEvaluation = (clea_evaluation_id, data) => {
    let url = 'clea_evaluations/' + clea_evaluation_id;
    return Post(url, data);
};

export const deleteCleaEvaluation = clea_evaluation_id => {
    let url = 'clea_evaluations/' + clea_evaluation_id;
    return Delete(url);
};

export const getCleaValidatedCompetences = (user_id, clea_evaluation_id) => {
    let url = 'users/' + user_id;

    if (clea_evaluation_id != null) {
        url += '/clea_evaluations/' + clea_evaluation_id;
    }
    else {
        url += '/clea_competences';
    }

    return Get(url);
};

// ------------------------------------------------------------------------------------ \\
// ------------------------------ CLEA PROOF DOCUMENT --------------------------------- \\
// ------------------------------------------------------------------------------------ \\

export const getCleaProofDocument = user_id => {
    let url = 'users/' + user_id + '/clea_proof_document';
    return Get(url);
};
