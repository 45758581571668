import React from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button, IconButton } from '@material-ui/core';
import { AddCircle as IconAdd, RemoveCircle as IconRemove } from '@material-ui/icons';

// Services
import { isValidTime, mobitrainColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/items/input-text';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------------- FORMATION FORM PAGE 4 ---------------------------------------- \\
const FormationFormPage4 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const onChangeInput = (label, event, index) => {
        const sessions = state.sessions;
        sessions[index] = Object.assign({}, sessions[index], {
            [label]: event.target.value
        });
        setState({ ...state, sessions });
    };

    const onChangeDate = (value, index) => {
        const sessions = state.sessions;
        sessions[index] = Object.assign({}, sessions[index], {
            formation_date: value
        });
        setState({ ...state, sessions });
    };

    const addSession = () => {
        const sessions = state.sessions;
        let sessionIsOpen = {};
        sessions.push({ formation_date: null, start: '', end: '', comment: '' });
        sessions.map((session, index) => {
            if (index + 1 === sessions.length) {
                return sessionIsOpen[index] = true;
            }
            return sessionIsOpen[index] = false;
        });
        setState({ ...state, sessions, sessionIsOpen });
    };

    const removeSession = index => {
        const sessions = state.sessions;
        sessions.splice(index, 1);
        setState({ ...state, sessions });
    };

    const openSession = index => {
        let sessionIsOpen = state.sessionIsOpen;
        let bool = sessionIsOpen[index];
        Object.keys(sessionIsOpen).map(key => {
            return sessionIsOpen[key] = false;
        });
        sessionIsOpen[index] = !bool;
        setState({ ...state, sessionIsOpen });
    };

    const saveFormation = () => {
        const sessions = state.sessions;    

        if (sessions.length > 1) {

            let sessionsAreValid = true;

            for (let i = 0; i < sessions.length; i++) {

                if (!sessions[i].formation_date || !isValidTime(sessions[i].start) || !isValidTime(sessions[i].end)) {
                    sessionsAreValid = false;
                    enqueueSnackbar((t('session') + (i + 1) + ' : ' + t('fields.required')), { variant: 'warning' });
                    return;
                }
                else if (Date.parse('01/01/2019 ' + sessions[i].start) > Date.parse('01/01/2019 ' + sessions[i].end)) {
                    sessionsAreValid = false;
                    enqueueSnackbar((t('session') + (i + 1) + ' : ' + t('time.error')), { variant: 'warning' });
                    return;
                }
            }

            if (sessionsAreValid) {
                for (let i = 0; i < sessions.length; i++) {
                    sessions[i] = Object.assign({}, sessions[i], {
                        formation_date: moment(sessions[i].formation_date).format('YYYY-MM-DD'),
                    });
                }
                setState({ ...state, loading: true, sessions });
            }
        }
        else {
            if (!sessions[0].formation_date || !isValidTime(sessions[0].start) || !isValidTime(sessions[0].end)) {
                enqueueSnackbar(t('fields.required'), { variant: 'warning' });
            }
            else if (Date.parse('01/01/2019 ' + sessions[0].start) > Date.parse('01/01/2019 ' + sessions[0].end)) {
                enqueueSnackbar(t('time.error'), { variant: 'warning' });
            }
            else {
                sessions[0] = Object.assign({}, sessions[0], {
                    formation_date: moment(sessions[0].formation_date).format('YYYY-MM-DD'),
                });
                setState({ ...state, loading: true, sessions });
            }
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                        <Grid item xs={false} md={3} />
                        <Grid item xs={12} md={6}>
                            {/* -------------------- SESSIONS -------------------- */}
                            {state.sessions != null && state.sessions.length > 1 ? (
                                state.sessions.map((session, index) => (
                                    <div 
                                        key={index} 
                                        style={{ border: '1px solid gray', borderRadius: '4px', marginTop: index === 0 ? 0 : 10 }}
                                    >
                                        <Grid
                                            onClick={() => openSession(index)}
                                            container alignItems="center" justifyContent="space-between"
                                            className="session"
                                            style={style.p0_10}
                                        >
                                            <span>{t('session') + (index + 1)}</span>
                                            <Tooltip title={t('remove')}
                                                item={
                                                    <IconButton
                                                        onClick={() => removeSession(index)}
                                                        className="list-btn"
                                                    >
                                                        <IconRemove />
                                                    </IconButton>
                                                }
                                            />
                                        </Grid>
                                        {state.sessionIsOpen[index] && (
                                            <>
                                                <Divider />
                                                <div style={{ padding: 12 }}>
                                                    <SessionForm
                                                        index={index}
                                                        state={state}
                                                        onChangeDate={onChangeDate}
                                                        onChangeInput={onChangeInput}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <SessionForm
                                    index={0}
                                    state={state}
                                    onChangeDate={onChangeDate}
                                    onChangeInput={onChangeInput}
                                />
                            )}
                            {/* ------------------ ADD SESSION ------------------ */}
                            {(state.actionType === 'CREATE' || state.actionType === 'DUPLICATE') && (
                                <div style={{ textAlign: 'center', padding: 24 }}>
                                    <Button
                                        onClick={addSession}
                                        variant="contained"
                                    >
                                        <IconAdd style={style.mr5} />
                                        {t('session.add.formation')}
                                    </Button>
                                </div>
                            )}
                        </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 3 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>4 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={saveFormation}
                        variant="contained"
                        style={{ backgroundColor: mobitrainColor, color: 'white' }}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// -------------------------------------------- SESSION FORM --------------------------------------------- \\
const SessionForm = ({ index, state, onChangeDate, onChangeInput }) => {
    const { t } = useTranslation();

    return (
        <>
            {/* -------------------- DATE -------------------- */}
            <div>
                <b className="input-title">
                    {t('date')}<FieldRequired />
                </b>
                <DatePicker
                    placeholder={t('date')}
                    outlined
                    value={state.sessions[index].formation_date}
                    onChange={value => onChangeDate(value, index)}  
                />
            </div>
            {/* -------------------- START TIME & END TIME -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 12 }}>
                <Grid item xs={6} style={{ paddingRight: 5 }}>
                    <b className="input-title">
                        {t('time.start')}<FieldRequired />
                    </b>
                    <InputText
                        placeholder="--:--"
                        value={state.sessions[index].start}
                        onChange={e => onChangeInput('start', e, index)}
                        type="time"
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                    <b className="input-title">
                        {t('time.end')}<FieldRequired />
                    </b>
                    <InputText
                        placeholder="--:--"
                        value={state.sessions[index].end}
                        onChange={e => onChangeInput('end', e, index)}
                        type="time"
                    />
                </Grid>
            </Grid>
            {/* -------------------- COMMENT -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">
                    {t('training.comment')}
                </b>
                <InputText
                    value={state.sessions[index].comment}
                    onChange={e => onChangeInput('comment', e, index)}
                    multiline
                />
            </div>
        </>
    );
};

export default FormationFormPage4;
