import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';

// ------------------------------------ SKILLBASE EXPORT ------------------------------------ \\
const SkillbaseExport = ({ skillbase }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {

            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('skillbase'));

            // Title
            worksheet.mergeCells('A1', 'C1');
            worksheet.getCell('A1').value = t('skillbase')?.toUpperCase() + ' : ' + getTranslation('name', skillbase)?.toUpperCase();
            worksheet.getCell('A1').font = { bold: true, color: { argb: '3F51B5' }};

            // Columns Headers
            let columnsHeaders = [t('units'), t('learning.outcomes'), t('descriptors')];
            worksheet.getRow(2).values = columnsHeaders;
            worksheet.getRow(2).font = { bold: true };
            worksheet.getRow(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0F0FB' } };
            worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            // Columns Structure
            let columnsStructure = [
                { key: 'unit', width: 30 },
                { key: 'learning_outcome', width: 30 },
                { key: 'descriptor', width: 50 },
            ];
            worksheet.columns = columnsStructure;

            // Columns Styles
            worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getColumn(3).alignment = { vertical: 'middle', wrapText: true };

            // Title Alignement (need to set after columns styles)
            worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C2').alignment = { vertical: 'middle', horizontal: 'center' };

            // Merge Offsets
            let unitOffset = 3;
            let learningOffset = 3;

            // Add Skillbase Data
            skillbase.units.forEach(u => {

                // Unit Title
                let unit = u.position + '.' + getTranslation('name', u);
                let descriptorsPerUnit = 0;

                // Learning Outcomes
                u.learning_outcomes.forEach(l => {

                    // LearningTitle
                    let learning_outcome = u.position + '.' + l.position + ' ' + getTranslation('name', l);
                    let descriptorsPerLearning = 0;

                    // Competences
                    l.competences.forEach(c => {

                        descriptorsPerUnit += 1;
                        descriptorsPerLearning += 1;

                        worksheet.addRow({
                            'unit': unit,
                            'learning_outcome': learning_outcome,
                            'descriptor': c.numerotation + t('competence.numerotation') + ' ' + getTranslation('name', c),
                        });
                    });

                    // Knowledges
                    l.knowledges.forEach(k => {

                        descriptorsPerUnit += 1;
                        descriptorsPerLearning += 1;

                        worksheet.addRow({
                            'unit': unit,
                            'learning_outcome': learning_outcome,
                            'descriptor': k.numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', k),
                        });
                    });

                    // Skills
                    l.skills.forEach(s => {

                        descriptorsPerUnit += 1;
                        descriptorsPerLearning += 1;

                        worksheet.addRow({
                            'unit': unit,
                            'learning_outcome': learning_outcome,
                            'descriptor': s.numerotation + t('skill.numerotation') + ' ' + getTranslation('name', s),
                        });
                    });

                    // Merge Learnings Cells
                    if ((learningOffset + (descriptorsPerLearning - 1)) > (learningOffset + 1)) {
                        let limitLearning = learningOffset + (descriptorsPerLearning - 1);
                        worksheet.mergeCells(`B${learningOffset}`, `B${limitLearning}`);
                        learningOffset = learningOffset + descriptorsPerLearning;
                    }
                });

                // Merge Units Cells
                if ((unitOffset + (descriptorsPerUnit - 1)) > (unitOffset + 1)) {
                    let limitUnit = unitOffset + (descriptorsPerUnit - 1);
                    worksheet.mergeCells(`A${unitOffset}`, `A${limitUnit}`);
                    unitOffset = unitOffset + descriptorsPerUnit;
                }
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), getTranslation('name', skillbase)  + '.xlsx'));
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Button
            onClick={() => setLoading(true)}
            disabled={skillbase == null || loading}
            variant="contained" color="primary"
        >
            Excel
            {loading ? (
                <CircularProgress color="inherit" size={16} style={{ marginLeft: 5 }} />
            ) : (
                <DownloadIcon style={{ fontSize: 16, marginLeft: 5 }} />
            )}
        </Button>
    );
};

export default SkillbaseExport;
