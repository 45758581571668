import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Dialog, DialogContent, DialogActions, Divider, Button, IconButton } from '@material-ui/core';
import { Create as BehaviorIcon, HelpOutline as SoftskillIcon } from '@material-ui/icons';

// Services
import { getTranslation, softskillsColor2, softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import { BehaviorsModal } from '../../softpositions/softposition-rating';
import Tooltip from 'components/items/tooltip';
import InputText from 'components/items/input-text';
import FieldRequired from 'components/items/field-required';
import Badge from 'components/items/badge';

// Style
import style from 'style';

// ---------------------------------------- SOFT EVALUATION FORM PAGE 3 ---------------------------------------- \\
const SoftEvaluationFormPage3 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    // Selected Softskills
    const soft_category_ids = [...new Set(state.softskills.map(s => s.soft_category_id))];
    const softskill_ids = state.softskills.map(s => s.id);

    const onSelectSoftLevel = (softskill_id, soft_level_id) => {
        setState({ ...state, soft_levels: Object.assign({}, state.soft_levels, { [softskill_id]: soft_level_id }) })
    };

    const onOpenObservedBehaviors = (event, soft_level) => {
        event.stopPropagation();
        setState({ ...state, openObservedBehavior: true, soft_level });
    };

    const onCreateEvaluation = () => {
        if (state.soft_levels == null || Object.entries(state.soft_levels).length === 0) {
            enqueueSnackbar(t('select.atleast.one.softlevel'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                {/* -------------------- TITLE -------------------- */}
                <Grid>
                    <b className="input-title">{t('select.soft.levels')}</b><FieldRequired />
                </Grid>
                {/* -------------------- INFORMATIONS -------------------- */}
                <Grid style={{ marginTop: 20 }}>
                    <span>
                        <b>{t('information')}</b>
                        <br />
                        {t('positioning.information')}
                        <br />
                        {t('positioning.validation')}
                    </span>
                </Grid>
                {/* -------------------- SOFT CATEGORY -------------------- */}
                {state.soft_categories
                .filter(soft_category => soft_category_ids.includes(soft_category.id))
                .map(soft_category => (
                    <Fragment key={soft_category.id}>
                        <Grid
                            container justifyContent="center"
                            style={{ ...style.mt25, ...style.p10, backgroundColor: softskillsColor }}
                        >
                            <span style={{ ...style.fwB, ...style.ttU, ...style.cW }}>
                                {soft_category.position}. {getTranslation('name', soft_category)}
                            </span>
                        </Grid>
                        {/* -------------------- SOFT SKILL -------------------- */}
                        <div className="soft-level-table">
                            <table style={style.w100}>
                                <tbody>
                                    {soft_category.softskills
                                    .filter(softskill => softskill_ids.includes(softskill.id))
                                    .map(softskill => (
                                        <tr key={softskill.id}>
                                            <td
                                                onClick={() => setState({ ...state, openBehavior: true, softskill })}
                                                className="soft-level-td list-item-hover"
                                                style={style.taC}
                                            >
                                                <span style={style.fwB}>
                                                    {soft_category.position}.{softskill.position}  {getTranslation('name', softskill)}
                                                </span>
                                                <SoftskillIcon style={{ color: 'gray', marginLeft: 5, fontSize: 20 }} />
                                            </td>
                                            {softskill.soft_levels.map(soft_level => (
                                                <td
                                                    key={soft_level.id}
                                                    className="soft-level-td"
                                                    onClick={() => onSelectSoftLevel(softskill.id, soft_level.id)}
                                                >
                                                    <Grid
                                                        container
                                                        className="soft-level"
                                                        style={{
                                                            position: 'relative',
                                                            backgroundColor: (state.soft_levels[softskill.id] === soft_level.id)
                                                            ? softskillsColor2
                                                            : null
                                                        }}
                                                    >
                                                        {(state.soft_levels[softskill.id] === soft_level.id) && (
                                                            <Tooltip title={t('observed.behaviors.edit')}
                                                                item={(
                                                                    <IconButton
                                                                        onClick={e => onOpenObservedBehaviors(e, soft_level)}
                                                                        style={{ padding: 4, position: 'absolute', border: '1px solid gray', borderRadius: '50%', right: 2 }}
                                                                    >
                                                                        <BehaviorIcon style={{ fontSize: 20 }} />
                                                                    </IconButton>
                                                                )}
                                                            />
                                                        )}
                                                        <Tooltip
                                                            title={t('positioning.count') + soft_level.count_validated + t('times')}
                                                            item={<div><Badge variant={soft_level.count_validated >= 3 ? 'validated' : 'default'} number={soft_level.count_validated} /></div>}
                                                        />
                                                        <p style={{ ...style.taC, ...style.w100, ...style.fwB }}>
                                                            {t('level')}&nbsp;{soft_level.level}
                                                            <br />
                                                            {soft_level.level === 1 && t('remember.and.understand')}
                                                            {soft_level.level === 2 && t('apply')}
                                                            {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                            {soft_level.level === 4 && t('create')}
                                                        </p>
                                                        <p className="soft-level-definition">
                                                            {getTranslation('definition', soft_level)}
                                                        </p>
                                                    </Grid>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Fragment>
                ))}
            </div>
            {/* -------------------- BEHAVIORS MODAL -------------------- */}
            {state.openBehavior && (
                <BehaviorsModal
                    onClose={() => setState({ ...state, openBehavior: false })}
                    softskill={state.softskill}
                />
            )}
            {/* -------------------- OBSERVED BEHAVIORS MODAL -------------------- */}
            {state.openObservedBehavior && (
                <ObservedBehaviorsModal
                    onClose={() => setState({ ...state, openObservedBehavior: false })}
                    state={state}
                    setState={setState}
                />
            )}
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>3 / 3</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={onCreateEvaluation}
                        variant="contained"
                        style={{ backgroundColor: softskillsColor, color: 'white' }}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ------------------------------------------ OBSERVED BEHAVIORS MODAL ----------------------------------------- \\
const ObservedBehaviorsModal = ({ onClose, state, setState }) => {
    const { t } = useTranslation();
    const soft_level = state.soft_level;
    const [value, setValue] = useState(state.observed_behaviors[soft_level.id]);

    const saveObservedBehaviors = () => {
        setState({
            ...state,
            openObservedBehavior: false,
            observed_behaviors: Object.assign({}, state.observed_behaviors, { [soft_level.id]: value })
        });
    };

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('level')}&nbsp;{soft_level.level}
                    <br />
                    {soft_level.level === 1 && t('remember.and.understand')}
                    {soft_level.level === 2 && t('apply')}
                    {soft_level.level === 3 && t('analyse.and.evaluate')}
                    {soft_level.level === 4 && t('create')}
                </p>
                <p>{t('observed.behaviors.edit')}</p>
                <InputText
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    multiline
                />
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    {t('cancel')}
                </Button>
                <Button onClick={saveObservedBehaviors} variant="contained">
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SoftEvaluationFormPage3;
