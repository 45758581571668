import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import {  Button, Divider, Grid, Accordion, AccordionSummary, IconButton, Dialog, DialogActions, DialogContent, Badge } from '@material-ui/core';
import { ExpandMore, ExpandMore as IconExpand, Link as IconDetails } from '@material-ui/icons';

// Services
import { getCleaDomains, getLearningOutcomeLinkedToCleaCompetence } from 'services/requests';
import { cleaColor, cleaColor2, getTranslation, mobipassColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

const styles = {
    td: {
        padding: 10,
        fontSize: 14,
        border: '1px solid lightgray',
    },
};

// ------------------------------------- CLEA SKILLBASE ------------------------------------- \\
const CleaSkillbase = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: true,
        error: false,
        clea_domains: [],
        clea_competence_id: null,
    });

    useEffect(() => {
        if (state.loading) {
            getCleaDomains().then(res => {
                if (res?.status === 200) {                  
                    setState({
                        ...state,
                        loading: false,
                        clea_domains: res.data.data.clea_domains,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />
    if (state.error) return <Error />

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.goBack()}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    style={{ textAlign: 'center' }}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('clea.certificate')}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            <div className="full-container" style={style.mt25}>
                {/* ----------------- TITLE -------------------- */}
                <Grid container style={{ backgroundColor: cleaColor, ...style.cW, padding: '10px 20px' }}>
                    <span>{t('clea.domain')}</span>
                </Grid>
                {/* -------------------- DOMAIN LIST -------------------- */}
                <div>
                    {state.clea_domains?.length > 0 ? (
                        state.clea_domains.map(clea_domain => (
                            <Accordion key={clea_domain.id}>
                                <AccordionSummary
                                    expandIcon={<IconExpand />}
                                    style={{ backgroundColor: cleaColor2 }}
                                >
                                    <span style={style.fwB}>
                                        {clea_domain.position}. {getTranslation('name', clea_domain)}
                                    </span>
                                </AccordionSummary>
                                <Divider />
                                <div style={{ overflow: 'auto' }}>
                                    <table style={{ ...style.w100, minWidth: 1000 }}>
                                        <thead>
                                            <tr>
                                                <th style={{ ...styles.td, ...style.taC, width: '30%' }}>
                                                    {t('clea.sub.domain')?.toUpperCase()}
                                                </th>
                                                <th style={{ ...styles.td, ...style.taC, width: '70%' }}>
                                                    {t('clea.competence')?.toUpperCase()}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clea_domain.clea_subdomains?.length > 0 && (
                                                clea_domain.clea_subdomains.map(sub_domain => (
                                                    <Fragment key={sub_domain.id}>
                                                        {/* -------------------- SUB DOMAIN -------------------- */}
                                                        <tr>
                                                            <td
                                                                rowSpan={(sub_domain.clea_competences?.length + 1)}
                                                                style={{ ...styles.td, ...style.taC, width: '30%' }}
                                                            >
                                                                {sub_domain.position}. {getTranslation('name', sub_domain)}
                                                            </td>
                                                        </tr>
                                                        {sub_domain.clea_competences.map(competence => (
                                                            <tr key={competence.id}>
                                                                <td style={{ ...styles.td, width: '70%' }}>
                                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                                        <span>
                                                                            {competence.position}. {getTranslation('name', competence)}
                                                                        </span>
                                                                        <Tooltip title={t('link')} 
                                                                            item={
                                                                                <IconButton
                                                                                    onClick={() => setState({
                                                                                        ...state,
                                                                                        openCleaDetailModal: true,
                                                                                        clea_competence_id: competence.id
                                                                                    })}
                                                                                    disabled={competence.count_learning_outcomes == null || competence.count_learning_outcomes === 0}
                                                                                    className="list-btn"
                                                                                >
                                                                                    <Badge
                                                                                        badgeContent={competence.count_learning_outcomes}
                                                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                                        color="secondary"
                                                                                    >
                                                                                        <IconDetails />
                                                                                    </Badge>
                                                                                </IconButton>
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </Fragment>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
            {/* -------------------- CLEA COMPETENCE DETAILS -------------------- */}
            {state.openCleaDetailModal && (
                <CleaCompetenceDetails
                    onClose={() => setState({...state, openCleaDetailModal: false })}
                    clea_competence_id={state.clea_competence_id}
                />
            )}
        </>
    );
};

// --------------------------------- CLEA COMPETENCE DETAILS -------------------------------- \\
const CleaCompetenceDetails = ({ onClose, clea_competence_id }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        learnings: [],
    });

    useEffect(() => {
        if (state.loading) {
            getLearningOutcomeLinkedToCleaCompetence(clea_competence_id).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        learnings: res.data.data.learning_outcomes,
                    });
                }
                else {
                    console.log(res)
                    setState({...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    return (
        <>
            <Dialog open fullWidth maxWidth="lg">
                {state.loading ? (
                    <Spinner />
                ) : (
                    <>
                        <DialogContent style={{ paddingBottom: 20 }}>
                            <p className="dialog-title">
                                {t('clea.learning.linked')}
                            </p>
                            <div>
                                {state.learnings.map(learning => (
                                    <Accordion key={learning.id}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            style={{ backgroundColor: mobipassColor2 }}
                                        >
                                            <span style={style.fwB}>{learning.unit?.position}.{learning.position}  {getTranslation('name', learning)}</span>
                                        </AccordionSummary>
                                        <Divider />
                                        <div style={{ padding: 24 }}>
                                            <div>
                                                <b className="input-title">{t('skillbase')} :</b>
                                                <br/>
                                                <span>{getTranslation('name', learning.skillbase)}</span>
                                            </div>
                                            <div style={{ marginTop: 12 }}>
                                                <b className="input-title">{t('unit')} :</b>
                                                <br/>
                                                <span>{learning.unit?.position}. {getTranslation('name', learning.unit)}</span>
                                            </div>
                                        </div>
                                    </Accordion>
                                ))}
                            </div>
                        </DialogContent>
                        <Divider />
                        <DialogActions style={style.jcC}>
                            <Button onClick={onClose} variant="contained">
                                {t('close')}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default CleaSkillbase;
