import React, {useState, useEffect} from 'react';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// File Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Divider, Grid, Collapse, Card, CardHeader, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import { ExpandMore, ExpandLess, SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { getNews, setLastReadingNews } from 'services/requests';
import { fileUrl, theme, getTranslation } from 'services/helpers';

// Components
import Header from './common/header';
import Spinner from './items/spinner';
import Error from './items/error';
import Footer from 'components/common/footer';
import FooterMobirhin from 'components/common/footer-mobirhin';
import InputSearch from './items/input-search';

// Style
import style from 'style';

const styles = {
    card: {
        width: '100%',
        maxWidth: 800,
        boxShadow: '3px 3px 10px lightgray',
        transitionDuration: '0.3s'
    },
};

// --------------------------------------- NEWS ------------------------------------------ \\
const News = () => {
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const storedUserId = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        news: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        expanded: null,
        title: '',
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    useEffect(() => {
        setLastReadingNews(storedUserId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading) {
            getNews({
                offset: state.offset,
                limit: state.limit,
                title: state.title,
            }).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        limit: res.data.data.limit,
                        news: res.data.data.news,
                    });
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Header />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <b>{t('news')}</b>
                <InputSearch
                    placeholder={t('news.search')}
                    onChange={e => setState({ ...state, title: e.target.value })}
                    onKeyPress={onSearch}
                    onSearch={onSearch}
                />
            </Grid>
            <Divider style={{ width: '100%' }} />
            {/* -------------------- LIST -------------------- */}
            <NewList
                state={state}
                setState={setState}
            />
            {/* -------------------- FOOTER -------------------- */}
            {theme === 'mobirhin' ? (
                <FooterMobirhin />
            ) : (
                <Footer />
            )}
        </>
    );
};

// ------------------------------------- NEW LIST ---------------------------------------- \\
const NewList = ({ state, setState }) => {
    const { t } = useTranslation();

    const onExpandClick = actuality_id => {
        if (state.expanded === actuality_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: actuality_id });
        }
    };

    if (state.loading) return <Spinner />
    if (state.error) return <Error />

    return (
        <div className="main">
            {state.news.map(actuality => (
                <Grid
                    key={actuality.id}
                    container justifyContent="center"
                    style={{ padding: 24 }}
                >
                    <Card style={styles.card}>
                        {/* -------------------- TITLE -------------------- */}
                        <CardHeader
                            title={getTranslation('title', actuality)}
                            subheader={actuality.publication_date ? moment(actuality.publication_date).format('DD/MM/YYYY') : ''}
                        />
                        {/* -------------------- PHOTO -------------------- */}
                        {actuality.photo && (
                            <div
                                style={{
                                    margin: 16,
                                    minHeight: 200,
                                    width: 'auto',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundImage: 'url(' + fileUrl + actuality.photo + ')',
                                }}
                            />
                        )}
                        {/* -------------------- DESCRIPTION -------------------- */}
                        <CardContent>
                            <Typography
                                variant="body1" color="textPrimary" component="p"
                                style={{ wordWrap: 'break-word', fontSize: 14 }}
                            >
                                {getTranslation('description', actuality)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* -------------------- DOCUMENT -------------------- */}
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid>
                                    {actuality.document != null && (
                                        <Button
                                            onClick={() => saveAs(fileUrl + actuality.document, 'document.pdf')}
                                            color="primary"
                                            style={{ textTransform: 'none' }}
                                        >
                                            {t('download.linked.document')}
                                            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                        </Button>
                                    )}
                                </Grid>
                                <Grid>
                                    <Button
                                        onClick={() => onExpandClick(actuality.id)}
                                        style={{ textTransform: 'none', color: 'gray' }}
                                    >
                                        {t('show.more')}
                                        {state.expanded === actuality.id ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                        {/* -------------------- DETAILS -------------------- */}
                        <Collapse
                            in={state.expanded === actuality.id}
                            timeout="auto"
                            unmountOnExit
                        >
                            <Divider style={style.mb15} />
                            <CardContent>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: convertToHTML(convertFromRaw(JSON.parse(getTranslation('details', actuality))))
                                    }}
                                />
                            </CardContent>
                        </Collapse>
                    </Card>
                </Grid>
            ))}
        </div>
    );
};

export default News;
