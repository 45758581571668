import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory, useParams, Redirect } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getUser, getCleaCourseForUser } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import CleaProofDocument from '../clea-proof-document';
import CleaLearnerPosition from './clea-learner-position';
import CleaLearnerFormations from './clea-learner-formations';
import CleaLearnerEvaluations from './clea-learner-evaluations';
import CleaPositionCreate from '../clea-position/clea-position-create';
import CleaPositionUpdate from '../clea-position/clea-position-update';
import CleaEvaluationCreate from '../clea-evaluation/clea-evaluation-create';
import CleaEvaluationUpdate from '../clea-evaluation/clea-evaluation-update';
import CleaCompetencesDetails from './clea-competences-details';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// --------------------------------- CLEA LEARNER COURSE ROUTER ------------------------------------ \\
const CleaLearnerCourseRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <CleaLearnerCourse />
            </Route>
            {/* -------------------------- POSITION -------------------------------- */}
            <Route exact path={`${match.path}/position/create`}>
                <CleaPositionCreate />
            </Route>
            <Route exact path={`${match.path}/position/update/:cleaPositionId`}>
                <CleaPositionUpdate />
            </Route>
            {/* -------------------------- EVALUATION -------------------------------- */}
            <Route exact path={`${match.path}/evaluation/create/:evaluationType`}>
                <CleaEvaluationCreate />
            </Route>
            <Route exact path={`${match.path}/evaluation/update/:evaluationType/id/:cleaEvaluationId`}>
                <CleaEvaluationUpdate />
            </Route>
            {/* -------------------------- DETAILS -------------------------------- */}
            <Route exact path={`${match.path}/competences-details/:cleaEvaluationId`}>
                <CleaCompetencesDetails />
            </Route>
            <Redirect from="*" to={`${match.path}`} />
        </Switch>
    );
};

// ------------------------------------ CLEA LEARNER COURSE ---------------------------------------- \\
const CleaLearnerCourse = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const ROLE_ID = getStoredUser()?.role?.id;
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { learnerId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
        clea_position: null,
        clea_evaluations: [],
        hasFinalAssessment: false,
    });

    const getEvaluationType = () => {
        if (state.clea_evaluations == null || state.clea_evaluations.length === 0) {
            return '0';
        }
        return '1';
    };

    useEffect(() => {
        if (state.loading) {
            axios.all([getUser(learnerId), getCleaCourseForUser(learnerId)])
            .then(axios.spread(function (res_user, res_course) {
                if (res_user?.status === 200 && res_course?.status === 200) {
                    let clea_evaluations = res_course.data.data.clea_evaluations;
                    setState({
                        ...state,
                        loading: false,
                        user: res_user.data.data.user,
                        clea_position: res_course.data.data.clea_position,
                        clea_evaluations: clea_evaluations,
                        hasFinalAssessment: clea_evaluations.some(evaluation => evaluation.evaluation_type === 2),
                    });
                }
                else {
                    console.log(res_user, res_course);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/mobipass/clea/course')}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ padding: mediaMD ? 0 : 10 }}
                >
                    <span style={{ ...style.fwB, ...style.fs16, textAlign: 'center' }}>
                        {t('clea.course.of.learner')}
                        <br />
                        {state.user.full_name}
                    </span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    {(ROLE_ID < 5 && state.clea_position == null) && (
                        <Button
                            variant="contained"
                            onClick={() => history.push(`${match.url}/position/create`)}
                        >
                            {t('clea.start.course')}
                        </Button>
                    )}
                    {(ROLE_ID < 5 && state.clea_position != null && !state.hasFinalAssessment) && (
                        <Button
                            variant="contained"
                            onClick={() => history.push(`${match.url}/evaluation/create/${getEvaluationType()}`)}
                        >
                            {t('clea.add.evaluation')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- PROOF DOCUMENT -------------------- */}
            <CleaProofDocument
                user={state.user}
            />
            <Divider />
            {/* -------------------- POSITION -------------------- */}
            <CleaLearnerPosition
                clea_position={state.clea_position}
                hasEvaluations={state.clea_evaluations.length > 0}
                refetch={() => setState({ ...state, loading: true })}
            />
            {/* -------------------- EVALUATIONS -------------------- */}
            {(state.clea_evaluations != null && state.clea_evaluations.length > 0) && (
                <CleaLearnerEvaluations
                    clea_evaluations={state.clea_evaluations}
                    refetch={() => setState({ ...state, loading: true })}
                />
            )}
            {/* -------------------- FORMATIONS -------------------- */}
            <CleaLearnerFormations />
        </>
    );
};

export default CleaLearnerCourseRouter;
