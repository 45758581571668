import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Button, Grid } from '@material-ui/core';
import IconLogin from '@material-ui/icons/Input';

// Services
import { isAuthenticated } from 'services/storage';
import useMediaQueries from 'services/media';
import { theme } from 'services/helpers';

// Components
import HomeZeroBarrier from './home-zerobarrier';
import HomeMobiRhin from './home-mobirhin';
import SelectLanguage from '../common/select-language';
import Login from '../common/login';

// Assets
import ZerobarrierLogo from 'assets/images/zerobarrier-white.png';
import LogoMobirhin from 'assets/images/mobirhin-white.png';

// Style
import style from 'style';

// ---------------------------------------- HOME ---------------------------------------- \\
const Home = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const [open, setOpen] = useState(false);

    if (isAuthenticated()) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Grid className="header" alignItems="center" container>
                <Grid container item xs={12} md={6} justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <div>
                        <img
                            alt="home-logo"
                            src={theme === 'mobirhin' ? LogoMobirhin : ZerobarrierLogo}
                            style={{ height: 50 }}
                        />
                        {!mediaMD && <br />}
                        <Button
                            onClick={() => setOpen(true)}
                            variant="contained" color="primary"
                            style={mediaMD ? style.ml15 : style.m10}
                        >
                            {t('sign.in')}
                            <IconLogin style={style.ml10} />
                        </Button>
                    </div>
                </Grid>
                <Grid container item xs={12} md={6} justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <SelectLanguage />
                </Grid>
            </Grid>
            {/* -------------------- PAGE CONTENT -------------------- */}
            {theme === 'mobirhin' ? (
                <HomeMobiRhin />
            ) : (
                <HomeZeroBarrier />
            )}
            {/* -------------------- LOGIN MODAL -------------------- */}
            {open && (
                <Login open={open} onClose={() => setOpen(false)} />
            )}
        </>
    );
};

export default Home;
