import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { editUser, setCompaniesAccess } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { servicesColor } from 'services/helpers';

// Components
import UserForm from './user-form';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- UPDATE USER ---------------------------------------- \\
const UpdateUser = ({ onClose, refetch, user }) => {
    const  { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: false,
        last_name: user.last_name,
        first_name: user.first_name,
        email: user.email ? user.email : '',
        role_id: user.role ? user.role.id : 5,
        company_id: user.company ? user.company.id : null,
        companies_access: (user.companies_access && user.companies_access.length > 0) ? user.companies_access.map(c => ({ label: c.name, value: c.id })) : null,
    });

    const onUpdateUser = () => {
        if (state.last_name === '' || state.first_name === '' || state.role_id == null || state.company_id == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            editUser(user.id, {
                last_name: state.last_name,
                first_name: state.first_name,
                email: state.email !== '' ? state.email : null,
                role_id: state.role_id,
                company_id: state.company_id
            }).then(res => {
                if (res?.status === 200) {

                    if (ROLE_ID === 1) {
        
                        enqueueSnackbar(res.data.message, { variant: 'success' });
                        let accessible_company_list = undefined;
                        if ( state.companies_access == null) {
                            accessible_company_list = null;
                        }
                        else {
                            accessible_company_list = state.companies_access.map(c => c.value);
                        }

                        setCompaniesAccess(
                            res.data.data.user.id,
                            {
                                companies_ids: accessible_company_list
                            })
                        .then(second_res => {
                            if (second_res?.status === 200) {
                                enqueueSnackbar(second_res.data.message, { variant: 'success' });
                                refetch();
                            }
                            else {
                                enqueueSnackbar(second_res, { variant: 'error' });
                                setState({ ...state, loading: false });
                            }
                        });
                    }
                    else {
                        enqueueSnackbar(res.data.message, { variant: 'success' });
                        refetch();
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('user.edit')}
                        </p>
                        <UserForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onUpdateUser}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateUser;
