import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { getSkillbases } from 'services/requests';
import { getTranslation } from 'services/helpers';

// Components
import Select from 'components/items/react-select';

// ------------------------------------ SELECT SKILLBASE ----------------------------------------- \\
const SelectSkillbase = ({ skillbaseId, setSkillbaseId }) => {
    const { t } = useTranslation();
    const [skillbases, setSkillbases] = useState([]);

    useEffect(() => {
        getSkillbases().then(res => {
            if (res?.status === 200) {
                setSkillbases(res.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            value={skillbaseId ? skillbases.find(c => c.value === skillbaseId) : null}
            onChange={skillebase => setSkillbaseId(skillebase ? skillebase.value : null)}
            options={skillbases}
            placeholder={t('skillbase')}
        />
    );
};

export default SelectSkillbase;
