import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { addCompany, editCompanyLogo } from 'services/requests';
import { isValidEmail, servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CompanyForm from './company-form';
import Spinner from 'components/items/spinner';

// ---------------------------------------- COMPANY CREATE ---------------------------------------- \\
const CompanyCreate = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        name: '',
        address: '',
        zip_code: '',
        city: '',
        legal_status: '',
        leader: '',
        creation_date: null,
        email: '',
        phone: '',
        website: '',
        activities: '',
        approval: '',
        employees: '',
        description: '',
        country_id: 1,
        photo: null,
        footer: '',
        has_softskills_access: '0',
        has_clea_access: '0',
    });

    const onCreateCompany = () => {
        if (state.name === '') {
            enqueueSnackbar(t('company.enter.name'), { variant: 'warning' });
        }
        else if (state.country_id == null) {
            enqueueSnackbar(t('select.country'), { variant: 'warning' });
        }
        else if (state.email !== '' && !isValidEmail(state.email)) {
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-company').files[0];
            if (file) {
                const photo = new FormData();
                photo.append('photo', file);
                setState({ ...state, loading: true, photo });
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    };

    useEffect(() => {
        if (state.loading) {
            addCompany({
                name: state.name,
                address: state.address,
                zip_code: state.zip_code,
                city: state.city,
                legal_status: state.legal_status,
                leader: state.leader,
                creation_date: state.creation_date ? moment(state.creation_date).format('YYYY-MM-DD') : null,
                email: state.email,
                phone: state.phone,
                website: state.website,
                activities: state.activities,
                approval: state.approval,
                employees: state.employees,
                description: state.description,
                country_id: state.country_id,
                footer: state.footer,
                has_softskills_access: state.has_softskills_access,
                has_clea_access: state.has_clea_access,
            }).then(res => {
                if (res?.status === 200) {

                    enqueueSnackbar(res.data.message, { variant: 'success' });

                    if (state.photo) {                        
                        editCompanyLogo(res.data.data.company.id, state.photo).then(second_res => {
                            if (second_res?.status === 200) {
                                enqueueSnackbar(second_res.data.message, { variant: 'success' });
                            }
                            else {
                                enqueueSnackbar(second_res, { variant: 'error' });
                            }
                            history.push('/management/companies');
                        });
                    }
                    else {
                        history.push('/management/companies');
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button onClick={() => history.push('/management/companies')} variant="contained">
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" 
                    style={{ fontSize: 16, marginTop: mediaMD ? 0 : 10 }}
                >
                    <b>{t('company.add')}</b>
                </Grid>
            </Grid>
            <Divider />
            {/* ---------------------- FORM ---------------------- */}
            {state.loading ? (
                <Spinner />
            ) : (
                <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                    <Grid container>
                        <Grid item xs={false} sm={2} md={3} lg={4} />
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <CompanyForm
                                state={state}
                                setState={setState}
                            />
                            <div style={{ marginTop: 24, textAlign: 'center' }}>
                                <Button
                                    onClick={onCreateCompany}
                                    variant="contained"
                                    style={{ padding: '6px 24px', width: '100%', backgroundColor: servicesColor, color: 'white' }}
                                >
                                    {t('validate')}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default CompanyCreate;
