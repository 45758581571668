import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { updateSoftLevel } from 'services/requests';
import { initialLanguagesObject, servicesColor, getTranslation } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    container: {
        minWidth: 900,
        border: '1px solid lightgray',
    },
    borderRight: {
        borderRightColor: 'lightgray',
        borderRightStyle: 'solid',
        borderRightWidth: 1,
    },
    header: {
        backgroundColor: servicesColor,
        padding: 10,
        color: 'white',
        textAlign: 'center',
    },
    softLevel: {
        padding: 10,
        minHeight: 100,
    },
};

// ------------------------------------- SOFT LEVELS ------------------------------------- \\
const SoftLevels = ({ loading, error, softskill, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        soft_level: undefined,
        title: undefined,
        openUpdateSoftLevel: false,
    });

    function handleRefetch() {
        setState({ ...state, openUpdateSoftLevel: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            {/* -------------------- SOFT LEVELS -------------------- */}
            <Grid container style={styles.container}>
                {softskill.soft_levels.map(soft_level => (
                    <Grid
                        key={soft_level.id}
                        item xs={3}
                        className="list-item-hover"
                        style={soft_level.level !== 4 ? styles.borderRight : null}
                        onClick={() => setState({ ...state, openUpdateSoftLevel: true, soft_level })}
                    >
                        <div style={styles.header}>
                            <span>
                                {t('level')} {soft_level.level}
                            </span>
                            <br />
                            <span style={style.cW}>
                                {soft_level.level === 1 && t('remember.and.understand')}
                                {soft_level.level === 2 && t('apply')}
                                {soft_level.level === 3 && t('analyse.and.evaluate')}
                                {soft_level.level === 4 && t('create')}
                            </span>
                        </div>
                        <div style={styles.softLevel}>
                            <span>{getTranslation('definition', soft_level)}</span>
                        </div>
                    </Grid>
                ))}
            </Grid>
            {/* -------------------- UPDATE SOFT LEVELS -------------------- */}
            {state.openUpdateSoftLevel && (
                <UpdateSoftLevel
                    onClose={() => setState({ ...state, openUpdateSoftLevel: false })}
                    refetch={handleRefetch}
                    soft_level={state.soft_level}
                />
            )}
        </>
    );
};

// ------------------------------------- UPDATE SOFT LEVELS ------------------------------------- \\
const UpdateSoftLevel = ({ onClose, refetch, soft_level }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        definition: initialLanguagesObject(),
    });

    function handleDefinition(event, code) {
        let definition = state.definition;
        definition[code] = event.target.value;
        setState({ ...state, definition });
    }

    useEffect(() => {
        if (soft_level) {
            let definition = state.definition;
            Object.entries(soft_level.translations).map(([key, value]) => {
                return definition[key] = value.definition || '';
            });
            setState({ ...state, definition });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [soft_level]);

    useEffect(() => {
        if (state.loading) {
            updateSoftLevel(soft_level.id, { definition: state.definition }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('level')} {soft_level.level}
                            <br />
                            {soft_level.level === 1 && t('remember.and.understand')}
                            {soft_level.level === 2 && t('apply')}
                            {soft_level.level === 3 && t('analyse.and.evaluate')}
                            {soft_level.level === 4 && t('create')}
                        </p>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.definition[language.code]}
                                onChange={(e) => handleDefinition(e, language.code)}
                                multiline
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default SoftLevels;
