import React, { useState } from 'react';

// Material-UI
import { Drawer, Button } from '@material-ui/core';
import IconMenu from '@material-ui/icons/Menu';

// ---------------------------------------- SIDE DRAWER ---------------------------------------- \\
const SideDrawer = props => {
    const [left, setLeft] = useState(false);

    return (
        <>
            <Button
                onClick={() => setLeft(true)}
                className="side-drawer"
            >
                <IconMenu />
            </Button>
            <Drawer open={left} onClose={() => setLeft(false)}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={() => setLeft(false)}
                    onKeyDown={() => setLeft(false)}
                >
                    {props.menu}
                </div>
            </Drawer>
        </>
    );
};

export default SideDrawer;
