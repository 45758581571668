import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Services
import { getCompaniesNotAccessible, sendCompanyRequest, removeCompanyRequest } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Pagination from 'components/items/pagination';
import InputText from 'components/items/input-text';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Material-UI
import { Button, Dialog, DialogContent, DialogActions, Divider, Grid, IconButton } from '@material-ui/core';
import { Search as IconSearch } from '@material-ui/icons';

// Style
import style from 'style';

// ---------------------------------------- BLOCKED COMPANIES ---------------------------------------- \\
const BlockedCompanies = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: undefined,
        error: undefined,
        data: [],
        offset: 0,
        limit: 5,
        total_count: 0,
        searchName: '',
        companyListDisplayed: false,
    });

    function handleGetCompaniesNotAccessible() {
        if (state.searchName.length < 3) {
            enqueueSnackbar(t('enter.3.lettering'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true, companyListDisplayed: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            getCompaniesNotAccessible(state.offset, state.limit, state.searchName).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data.companies,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- SEARCH -------------------- */}
            <Grid className="simple-container">
                <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <InputText
                        placeholder={t('company.research')}
                        value={state.searchName}
                        onChange={e => setState({ ...state, searchName: e.target.value })}
                        onKeyPress={e => e.key === 'Enter' ? handleGetCompaniesNotAccessible() : null}
                    />
                    <IconButton onClick={handleGetCompaniesNotAccessible}>
                        <IconSearch />
                    </IconButton>
                </Grid>
            </Grid>
            {state.companyListDisplayed && (
                <>
                    {/* -------------------- TOOLBAR -------------------- */}
                    <Grid container alignItems="center" className="toolbar">
                        <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                            <b>{t('access.ask')}</b>
                        </Grid>
                        <Grid item xs={12} md={4} container justifyContent="center" style={mediaMD ? null : style.m10 }>
                            <Pagination
                                limit={state.limit}
                                total_count={state.total_count}
                                setOffset={offset => setState({ ...state, loading: true, offset })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                            <b>{state.total_count + ' ' + t('results')}</b>
                        </Grid>
                    </Grid>
                    <div className="full-container">
                        {/* -------------------- HEADERS -------------------- */}
                        <Grid container style={{ backgroundColor: servicesColor }}>
                            {mediaMD ? (
                                <>
                                    <Grid item xs={3} style={style.p10}>
                                        <span style={style.cW}>{t('name')}</span>
                                    </Grid>
                                    <Grid item xs={2} style={style.p10}>
                                        <span style={style.cW}>{t('country')}</span>
                                    </Grid>
                                    <Grid item xs={3} style={style.p10}>
                                        <span style={style.cW}>{t('address')}</span>
                                    </Grid>
                                    <Grid item xs={4} style={{ ...style.p10, ...style.taC }}>
                                        <span style={style.cW}>{t('status')}</span>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                                    <span style={style.cW}>{t('companies')}</span>
                                </Grid>
                            )}
                        </Grid>
                        {/* -------------------- COMPANY LIST -------------------- */}
                        <CompanyList
                            data={state.data}
                            loading={state.loading}
                            error={state.error}
                            refetch={() => setState({ ...state, loading: true })}
                        />
                    </div>
                </>
            )}
        </>
    );
};

// ------------------------------------------ COMPANY LIST ------------------------------------------- \\
const CompanyList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        company: undefined,
        openSendCompanyRequest: false,
        openRemoveCompanyRequest: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    return (
        <>
            <div className="half-container-content">
                {loading ? (
                    <Spinner />
                ) : error ? (
                    <Error />
                ) : (
                    <>
                        {data.length > 0 ? (
                            data.map(company => (
                                <Fragment key={company.id}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} md={3} style={style.p10}>
                                            <span>{company.name}</span>
                                        </Grid>
                                        <Grid item xs={12} md={2} style={style.p10}>
                                            <span>{company.country ? company.country.name : ''}</span>
                                        </Grid>
                                        <Grid item xs={12} md={3} style={style.p10}>
                                            <span>{company.address} {company.zip_code} {company.city}</span>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ ...style.p10, ...style.taC }}>
                                            {company.access === 'no' ? (
                                                <Button
                                                    onClick={() => setState({ ...state, openSendCompanyRequest: true, company })}
                                                    color="secondary" variant="contained"
                                                    style={{ textTransform: 'none', padding: '3px 16px' }}
                                                >
                                                    {t('access.ask')}
                                                </Button>
                                            ) : (
                                                <Grid>
                                                    <span>{t('demand.in.progress')}...</span>
                                                    <Button
                                                        onClick={() => setState({ ...state, openRemoveCompanyRequest: true, company })}
                                                        variant="contained"
                                                        style={{ textTransform: 'none', padding: '3px 16px', margin: '5.5px 0' }}
                                                    >
                                                        {t('cancel')}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Fragment>
                            ))
                        ) : (
                                <>     
                                    <Grid container alignItems="center" style={style.p10}>
                                        <span>{t('none')}</span>
                                    </Grid>
                                    <Divider />
                                </>
                        )}
                    </>
                )}
            </div>
            {/* -------------------- SEND COMPANY REQUEST -------------------- */}
            {state.openSendCompanyRequest && (
                <SendCompanyRequest
                    onClose={() => setState({ ...state, openSendCompanyRequest: false })}
                    refetch={() => handleRefetch('openSendCompanyRequest')}
                    company={state.company}
                />
            )}
            {/* -------------------- REMOVE COMPANY REQUEST -------------------- */}
            {state.openRemoveCompanyRequest && (
                <RemoveCompanyRequest
                    onClose={() => setState({ ...state, openRemoveCompanyRequest: false })}
                    refetch={() => handleRefetch('openRemoveCompanyRequest')}
                    company={state.company}
                />
            )}
        </>
    );
};

// ---------------------------------------- SEND COMPANY REQUEST ------------------------------------- \\
const SendCompanyRequest = ({ onClose, refetch, company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            sendCompanyRequest(company.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.jcC}>
                        <p className="dialog-title">
                            {company.name}
                        </p>
                        <span>{t('access.demand')}</span>
                        {ROLE_ID === 5 && (
                            <>
                                <br />
                                <span>({t('access.warning')})</span>
                            </>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// --------------------------------------- REMOVE COMPANY REQUEST ------------------------------------ \\
const RemoveCompanyRequest = ({ onClose, refetch, company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const USER_ID = getStoredUser()?.id;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            removeCompanyRequest(company.id, USER_ID).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.jcC}>
                        <p className="dialog-title">
                            {company.name}
                        </p>
                        <span>{t('access.demand.cancel')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default BlockedCompanies;
