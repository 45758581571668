import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Components
import EvaluationsChart from './evaluations/evaluations-chart';
import FormationsChart from './formations/formations-chart';

// Style
import style from 'style';

// ------------------------------------- GLOBAL STATISTICS --------------------------------------- \\
const GlobalStatistics = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" style={style.p10}>
                <b>{t('global.statistics')}</b>
            </Grid>
            <Divider />
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management/statistics')} variant="contained">
                    {t('previous')}
                </Button>
            </Grid>
            {/* ---------------------- STATISTICS ---------------------- */}
            <div className="full-container">
                <EvaluationsChart />
                <FormationsChart />
            </div>
        </>
    );
};

export default GlobalStatistics;
