// Tools to Create PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

// Services
import { getFormattedDate, getTranslation, mobipassColor } from 'services/helpers';

// Assets
import LogoZB from 'assets/images/zerobarrier.png';
import { mobipassColor2 } from 'services/helpers';
import { softskillsColor2 } from 'services/helpers';

// ------------------------------------- EVALUATION DETAILS EXPORT --------------------------------------- \\
const EvaluationDetailsExport = (t, evaluation, state, setState) => {

    // Create new PDF
    const pdf = new jsPDF();

    // Logo Zero Barrier
    pdf.addImage(LogoZB, 10, 10, 36.5, 7.7, '', 'FAST');

    // Title
    pdf.setFontSize(14);
    pdf.setFont('helvetica', 'bold');
    pdf.text(evaluation.name?.toUpperCase(), 105, 25, { align: 'center' });
    pdf.text(t('evaluation.of') + ' ' + getFormattedDate(evaluation.evaluation_date), 105, 30, { align: 'center' });

    // Details
    var bodyInfos = [];

    // Title
    var titleTab = [];
    titleTab.push({
        content: t('evaluation.details'),
        styles: { fillColor: mobipassColor, textColor: 'white', fontStyle: 'bold', halign: 'center' },
        colSpan: 2,
    });
    bodyInfos.push(titleTab);

    // Company
    var companyTab = [];
    companyTab.push({
        content: t('company'),
        styles: { cellWidth: 60 },
    });
    companyTab.push({
        content: evaluation.company?.name,
        styles: { cellWidth: 130 },
    });
    bodyInfos.push(companyTab);

    // Skillbase
    var skillbaseTab = [];
    skillbaseTab.push({
        content: t('skillbase'),
        styles: { cellWidth: 60 },
    });
    skillbaseTab.push({
        content: getTranslation('name', evaluation.skillbase),
        styles: { cellWidth: 130 },
    });
    bodyInfos.push(skillbaseTab);

    // Assessor
    var assessorTab = [];
    assessorTab.push({
        content: t('assessor'),
        styles: { cellWidth: 60 },
    });
    assessorTab.push({
        content: evaluation.assessor?.full_name,
        styles: { cellWidth: 130 },
    });
    bodyInfos.push(assessorTab);

    // Infos Additional
    var infosAdditionalTab = [];
    infosAdditionalTab.push({
        content: t('informations.additional'),
        styles: { cellWidth: 60 },
    });
    infosAdditionalTab.push({
        content: evaluation.details || '',
        styles: { cellWidth: 130 },
    });
    bodyInfos.push(infosAdditionalTab);

    // Comment
    var commentTab = [];
    commentTab.push({
        content: t('comment'),
        styles: { cellWidth: 60 },
    });
    commentTab.push({
        content: evaluation.comment || '',
        styles: { cellWidth: 130 },
    });
    bodyInfos.push(commentTab);

    autoTable(pdf, {
        margin: {
            top: 40,
            bottom: 10,
            right: 10,
            left: 10,
        },
        body: bodyInfos,
        theme: 'grid',
    });

    // Users
    evaluation.users.forEach((user, index) => {

        // Body
        var body = [];

        // User
        var userTab = [];
        userTab.push({
            content: user.full_name,
            styles: { fillColor: mobipassColor, textColor: 'white', fontStyle: 'bold', halign: 'center' },
            colSpan: 2,
        });
        body.push(userTab);

        // Portfolios
        if (evaluation.portfolios?.length > 0) {

            // Learning Title
            var learningTab = [];
            learningTab.push({
                content: t('learning.outcome')?.toUpperCase(),
                styles: { fillColor: mobipassColor2, halign: 'center' },
                colSpan: 2,
            });
            body.push(learningTab);
    
            // Learnings
            evaluation.learning_outcomes.forEach(learning => {
    
                // Valitated
                var validated = evaluation.portfolios
                    .find(p => p.learning_outcome_id === learning.id && p.user_id === evaluation.users[index]?.id)
                    ?.validated;

                // Learning
                var learningTab = [];
                learningTab.push({
                    content: getTranslation('name', learning),
                    styles: { cellWidth: 130 },
                });
                learningTab.push({
                    content: validated ? t('learning.outcome.validated') : t('learning.outcome.failed'),
                    styles: { cellWidth: 60, textColor: validated ? 'green' : 'red', halign: 'center', valign: 'middle' },
                });
                body.push(learningTab);
            });
        }

        // Soft Portfolios
        if (evaluation.soft_portfolios?.length > 0) {

            // Soft Skill Title
            var softskillsTab = [];
            softskillsTab.push({
                content: t('softskills')?.toUpperCase(),
                styles: { fillColor: softskillsColor2, halign: 'center' },
                colSpan: 2,
            });
            body.push(softskillsTab);

            // Soft Units
            evaluation.soft_units.forEach(soft_unit => {

                // Soft Level
                var soft_level_id = evaluation.soft_portfolios
                    .find(p => p.soft_unit_id === soft_unit.id && p.user_id === evaluation.users[index]?.id)
                    ?.soft_level_id;

                var soft_level = soft_unit.softskill?.soft_levels?.find(l => l.id === soft_level_id);

                // Soft Level Result
                var softLevelTab = [];
                softLevelTab.push({
                    content: getTranslation('name', soft_unit.softskill) + '\r\n' + t('level') + ' ' + soft_unit.soft_level?.level,
                    styles: { cellWidth: 130 },
                });
                softLevelTab.push({
                    content: soft_level ? (t('level') + ' ' + soft_level?.level) : t('not.evaluated'),
                    styles: { cellWidth: 60, halign: 'center', valign: 'middle', textColor: soft_level?.level >= soft_unit.soft_level?.level ? 'green' : 'red' },
                });
                body.push(softLevelTab);
            });
        }

        autoTable(pdf, {
            margin: 10,
            body: body,
            theme: 'grid',
        });
    });

    // Footers of Page
    var numberOfPages = pdf.internal.getNumberOfPages();
    for (let i = 0; i < numberOfPages; i += 1) {

        // Set Current Page
        pdf.setPage(i + 1);

        // Page Number
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bolditalic');
        pdf.text(((i + 1) + ' / ' + numberOfPages), 105, 287, { align: 'center' });
    }

    // Save the PDF
    pdf.save(evaluation.name + '.pdf');
    setState({ ...state, loadingExport: false });
};

export default EvaluationDetailsExport;
