import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';

// Services
import { editCleaSubDomain } from 'services/requests';
import { initialLanguagesObject, servicesColor } from 'services/helpers';

// Components
import SubDomainForm from './subdomain-form';
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ---------------------------------- UPDATE CLEA SUB DOMAIN ---------------------------------- \\
const UpdateCleaSubDomain = ({ onClose, refetch, sub_domain }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
        position: sub_domain.position || 1,
        percentage_to_validate: sub_domain.percentage_to_validate || 50,
    });

    function handleEditDomain() {
        if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (sub_domain) {
            let names = state.names;
            Object.entries(sub_domain.translations).map(([key, value]) => {
                return names[key] = value.name;
            });
            setState({ ...state, names });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sub_domain]);

    useEffect(() => {
        if (state.loading) {
            editCleaSubDomain(sub_domain.id, {
                position: state.position,
                name: state.names,
                percentage_to_validate: state.percentage_to_validate,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    console.log(state.percentage_to_validate);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('clea.sub.domain.edit')}
                        </p>
                        <Divider style={style.m15_0} />    
                        <InputTextGrid
                            placeholder={t('position')}
                            value={state.position}
                            onChange={e => setState({ ...state, position: e.target.value })}
                            type="number"
                        />
                        <Divider style={style.m15_0} /> 
                        <SubDomainForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleEditDomain}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: servicesColor }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateCleaSubDomain;
