import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Divider, Fab, ExpansionPanel, ExpansionPanelSummary, IconButton } from '@material-ui/core';
import { ExpandMore as IconExpand, Create as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getTranslation, servicesColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateCleaSubDomain from './create-clea-subdomain';
import UpdateCleaSubDomain from './update-clea-subdomain';
import DeleteCleaSubDomain from './delete-clea-subdomain';
import CleaCompetences from './competences';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ---------------------------------------- CLEA SUB DOMAIN ------------------------------------------- \\
const CleaSubDomain = ({ domain, refetch }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        clea_sub_domains: domain?.clea_subdomains || [],
        sub_domain: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    function onOpen(event, open, sub_domain) {
        event.stopPropagation();
        setState({ ...state, [open]: true, sub_domain });
    }

    return (
        <>
            {/* ---------------------- CLEA SUB DOMAIN LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: servicesColor2, padding: '0px 16px' }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={10} style={style.p10}>
                            <span>{t('clea.sub.domain')}</span>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center', padding: 10, paddingRight: 80 }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('clea.sub.domain')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- CLEA SUB DOMAIN LIST ---------------------- */}
            <div>
                {domain.clea_subdomains.length > 0 ? (
                    domain.clea_subdomains.map(sub_domain => (
                        <ExpansionPanel key={sub_domain.id}>
                            <ExpansionPanelSummary expandIcon={<IconExpand />}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={10}>
                                        <span style={style.fwB}>
                                            {sub_domain.position}. {getTranslation('name', sub_domain)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                                        <Tooltip title={t('edit')}
                                            item={
                                                <IconButton
                                                    onClick={e => onOpen(e, 'openUpdate', sub_domain)}
                                                    style={{ padding: 8 }}
                                                >
                                                    <IconEdit />
                                                </IconButton>
                                            }
                                        />
                                        <Tooltip title={t('delete')}
                                            item={
                                                <IconButton
                                                    onClick={e => onOpen(e, 'openDelete', sub_domain)}
                                                    style={{ padding: 8 }}
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <Divider />
                            <div style={{ padding: 24 }}>
                                <CleaCompetences
                                    key={sub_domain.id}
                                    sub_domain={sub_domain}
                                    domain_id={domain.id}
                                    refetch={refetch}
                                />
                            </div>
                        </ExpansionPanel>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- ADD SUB DOMAIN BUTTON -------------------- */}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreate: true })}
                    className="fab"
                    style={{ backgroundColor: servicesColor2 }}
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('clea.sub.domain.add')}
                </span>
            </div>
            {/* -------------------- CREATE CLEA SUB DOMAIN -------------------- */}
            {state.openCreate && (
                <CreateCleaSubDomain
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => refetch()}
                    domain={domain}
                />
            )}
            {/* -------------------- UPDATE CLEA SUB DOMAIN -------------------- */}
            {state.openUpdate && (
                <UpdateCleaSubDomain
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => refetch()}
                    sub_domain={state.sub_domain}
                />
            )}
            {/* -------------------- DELETE CLEA SUB DOMAIN -------------------- */}
            {state.openDelete && (
                <DeleteCleaSubDomain
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => refetch()}
                    sub_domain={state.sub_domain}
                />
            )}
        </>
    );
};

export default CleaSubDomain;
