import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { editLearningOutcome } from 'services/requests';
import { initialLanguagesObject } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ------------------------------------- UPDATE LEARNING ------------------------------------- \\
const UpdateLearning = ({ onClose, refetch, unitId, learnings, learning }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        position: learning.position,
        names: initialLanguagesObject(),
    });

    function handleNames(event, code) {
        setState({
            ...state,
            names: Object.assign({}, state.names, { [code]: event.target.value })
        });
    }

    function handleEditLearning() {
        if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (learning) {
            let names = state.names;
            Object.entries(learning.translations).map(([key, value]) => {
                return names[key] = value.name;
            });
            setState({ ...state, names });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learning]);

    useEffect(() => {
        if (state.loading) {
            editLearningOutcome(
                match.params.skillbaseId,
                unitId,
                learning.id,
                { position: state.position, name: state.names }
            ).then(res => {
                if (res?.status === 200) {

                    let updatedLearnings = learnings;

                    const learning_moved = updatedLearnings.find(l => l.position === Number(state.position));
                    learning_moved.position = learning.position;
    
                    const index = updatedLearnings.findIndex(l => l.id === learning.id);
                    updatedLearnings.splice(index, 1, res.data.data.learning_outcome);
    
                    updatedLearnings.sort(function (a, b) {
                        return a.position - b.position
                    });

                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(updatedLearnings);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('learning.outcome.edit')}
                        </p>
                        <InputTextGrid
                            placeholder={t('position')}
                            value={state.position}
                            onChange={e => setState({ ...state, position: e.target.value })}
                            number
                        />
                        <Divider style={style.m15_0} />
                        <div style={style.taC}>
                            <span style={style.fwB}>{t('learning.outcome.name')}</span>
                        </div>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleEditLearning} variant="contained">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateLearning;
