import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// File Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { deleteMobility, getMobility } from 'services/requests';
import { getFormattedDate, mobitrainColor, fileUrl } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// --------------------------------------- DETAILS MOBILITY ---------------------------------------- \\
export const DetailsMobility = ({ onClose, mobility }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        mobility: null,
    });

    useEffect(() => {
        if (state.loading) {
            getMobility(mobility.id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, mobility: res.data.data.mobility });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {t('mobility')}
                </p>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('learner')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{mobility.learner.full_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('start.date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getFormattedDate(mobility.start_date)}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('end.date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getFormattedDate(mobility.end_date)}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('description')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{mobility.description}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('tutor.origin')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{mobility.origin_trainer_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('company.origin')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{mobility.origin_company_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('tutor.host')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{mobility.host_trainer_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('company.host')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{mobility.host_company_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('document')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {mobility.document ? (
                            <Button
                                onClick={() => saveAs(fileUrl + mobility.document, t('mobility') + ' - ' + t('convention.document'))}
                                variant="contained"
                                style={{ textTransform: 'none', color: 'white', backgroundColor: mobitrainColor }}
                            >
                                {t('download.document')}
                                <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                            </Button>
                        ) : (
                            <span>{t('none')}</span>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: mobitrainColor }}>
                            {t('trainings')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {state.loading ? (
                            <span>{t('loading')}</span>
                        ) : state.error ? (
                            <span>{t('error.occured')}</span>
                        ) : (
                            <>
                                {state.mobility.formations && state.mobility.formations.map(f => (
                                    <span key={f.id}>
                                        {getFormattedDate(f.formation_date)} - {f.name}
                                        <br />
                                    </span>
                                ))}
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog> 
    );
};

// ---------------------------------------- DELETE MOBILITY ---------------------------------------- \\
export const DeleteMobility = ({ onClose, refetch, mobility }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteMobility(mobility.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {t('mobility')}
                            <br />
                            <b style={{ color: mobitrainColor }}>
                                {getFormattedDate(mobility?.start_date) + ' - ' + getFormattedDate(mobility?.end_date)}
                            </b>
                        </p>
                        <span>{t('mobility.confirm.delete')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
