import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Button } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// ------------------------------------ GROUP SOFT POSITION FORM PAGE 1 ---------------------------------- \\
const GroupSoftPositionFormPage1 = ({ state, setState }) => {
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const navigateToPage2 = () => {
        if (state.learners == null || state.learners.length === 0) {
            enqueueSnackbar(t('learners.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    useEffect(() => {
        if (state.companyId) {
            getCompanyMembers(state.companyId).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        learnerOptions: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, learnerOptions: [] });
                }
            });
        }
        else {
            setState({ ...state, learnerOptions: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.companyId]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- TITLE -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('learners.select')}</b><FieldRequired />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <span>{t('company')}</span>
                            <Select
                                placeholder={t('company')}
                                value={state.companyId ? state.companies.find(c => c.value === state.companyId) : null}
                                onChange={company => setState({ ...state, companyId: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- LEARNERS -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <span>{t('learners')}</span>
                            <Select
                                placeholder={t('learners')}
                                value={state.learners}
                                onChange={learners => setState({ ...state, learners })}
                                options={state.learnerOptions}
                                isMulti
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default GroupSoftPositionFormPage1;
