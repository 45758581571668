import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Divider, Button, Fab, Accordion, AccordionSummary, IconButton } from '@material-ui/core';
import { ExpandMore as IconExpand, Delete as IconDelete, NoteAdd as IconDocAdd, InsertDriveFile as IconCertif } from '@material-ui/icons';

// Services
import { getCleaFormation } from 'services/requests';
import { cleaColor, fileUrl, cleaColor2, getLabelFromArray } from 'services/helpers';
import { cleaDocumentTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import CreateCleaProof from './clea-proof-create';
import DeleteCleaProof from './clea-proof-delete';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ---------------------------------------- CLEA PROOF ---------------------------------------- \\
const CleaProof = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { cleaFormationId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        learners: [],
        learner_id: null,
        document: null,
        openCreate: false,
        openDelete: false,
    });

    useEffect(() => {
        if (state.loading) {
            getCleaFormation(cleaFormationId).then(res => {
                if (res?.status === 200) {

                    let clea_formation = res.data.data.clea_formation, learners = [];
                    clea_formation.learners.forEach(learner => {
                        let documents = clea_formation.clea_formation_documents.filter(d => d.user_id === learner.id);
                        learners.push(Object.assign({}, learner, { documents }));
                    });

                    setState({ ...state, loading: false, learners });
                }
                else {
                    console.log(res)
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR CLEA -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push('/mobitrain/clea-formation')}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('clea.proof.management')}
                    </span>
                </Grid>
            </Grid>
            <div className="full-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: cleaColor, padding: 16, paddingRight: 48 }}>
                    <Grid item xs={12} md={4}>
                        <span style={style.cW}>{t('learners')}</span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <span style={style.cW}>{t('company')}</span>
                    </Grid>
                </Grid>
                {/* -------------------- LEARNERS -------------------- */}
                <div>
                    {state.learners.length > 0 ? (
                        state.learners.map(learner => (
                            <Accordion key={learner.id}>
                                <AccordionSummary expandIcon={<IconExpand />}>
                                    <Grid container alignItems='center'>
                                        <Grid item xs={12} md={4}>
                                            <span>{learner.full_name}</span>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <span>{learner.company_name}</span>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <Divider />
                                <div style={{ padding: 24 }}>
                                    <CleaProofList
                                        learner={learner}
                                        state={state}
                                        setState={setState}
                                    />
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid container style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </> 
                    )}
                </div>
            </div>
            {/* -------------------- CREATE CLEA PROOF -------------------- */}
            {state.openCreate && (
                <CreateCleaProof
                    clea_formation_id={cleaFormationId}
                    learner_id={state.learner_id}
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={() => setState({ ...state, openCreate: false, loading: true })}
                />
            )}
            {/* -------------------- DELETE CLEA PROOF -------------------- */}
            {state.openDelete && (
                <DeleteCleaProof
                    clea_formation_id={cleaFormationId}
                    document={state.document}
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => setState({ ...state, openDelete: false, loading: true })}
                />
            )}
        </>
    );
};

// -------------------------------------- CLEA PROOF LIST ------------------------------------- \\
const CleaProofList = ({ learner, state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const handleOpenModal = (event, open, document) => {
        event.stopPropagation();
        setState({ ...state, [open]: true, document });
    };

    return (
        <>
            <Grid container style={{ backgroundColor: cleaColor2 }}>
                {mediaMD ? (
                    <>
                        <Grid item md={2} style={style.p10}>
                            <span>{t('clea.document.name')}</span>
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <span>{t('clea.document.type')}</span>
                        </Grid>
                        <Grid item md={6} style={style.p10}>
                            <span>{t('description')}</span>
                        </Grid>
                        <Grid item md={2} style={{ padding: 10, textAlign: 'center' }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('clea.document.name')}</span>
                    </Grid>
                )}
            </Grid>
            {learner.documents.length > 0 ? (
                learner.documents.map(document => (
                    <Fragment key={document.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                <span>{document.name}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10 }}>
                                <span>{t(getLabelFromArray(cleaDocumentTypes, document.type.toString()))}</span>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ padding: 10 }}>
                                <span>{document.description}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                <Tooltip title={t('clea.view.certificate')}
                                    item={
                                        <IconButton
                                            href={`${fileUrl + document.document}`}
                                            target="blank"
                                            style={{ padding: 8 }}
                                        >
                                            <IconCertif />
                                        </IconButton>
                                    }
                                />
                                <Tooltip title={t('delete')}
                                    item={
                                        <IconButton
                                            onClick={e => handleOpenModal(e, 'openDelete', document)}
                                            style={{ padding: 8 }}
                                        >
                                            <IconDelete />
                                        </IconButton>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid container style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </> 
            )}
            {/* -------------------- CREATE PROOF BUTTON -------------------- */}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreate: true, learner_id: learner.id })}
                    className="fab"
                    style={{ backgroundColor: cleaColor2 }}
                >
                    <IconDocAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('clea.proof.document.add')}
                </span>
            </div>
        </>
    );
};

export default CleaProof;
