import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// File-Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';
import { SaveAlt as IconSave } from '@material-ui/icons';

// Services
import { getCurriculum, getUser } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { mobipassColor, softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Portfolios from './portfolios';
import SoftSkills from './softskills';

// Style
import style from 'style';

// ----------------------------------- USER PORTFOLIO ROUTER ------------------------------------ \\
const UserPortfolioRouter = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        user: null,
        menuSelected: 0,
    });

    const onGetCurriculum = () => {
        getCurriculum(userId).then(res => {
            if (res?.status === 200) {
                saveAs(new Blob([res.data]), 'CurriculumVitae.docx');
            }
            else {
                enqueueSnackbar(res, { variant: 'error' });
            }
        });
    };

    useEffect(() => {
        getUser(userId).then(res => {
            if (res?.status === 200) {
                setState({ ...state, user: res.data.data.user });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            variant="contained"
                            onClick={() => history.push('/mobipass/portfolio')}
                        >
                            {t('previous')}
                        </Button>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    {(ROLE_ID < 5 && state.user) && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {state.user.full_name}
                        </span>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onGetCurriculum}
                    >
                        {t('curriculum.vitae')}
                        <IconSave style={{ marginLeft: 5, fontSize: 16 }} />
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SWITCH -------------------- */}
            <div className="full-container" style={{ paddingBottom: 24 }}>
                <Grid container style={style.mt25}>
                    <Grid item xs={false} md={3} />
                    <Grid
                        item xs={6} md={3}
                        container justifyContent="center"
                        style={{ paddingRight: 5 }}
                    >
                        <div
                            className="evaluation-rating-button"
                            onClick={() => setState({ ...state, menuSelected: 0 })}
                            style={{
                                backgroundColor: mobipassColor,
                                opacity: state.menuSelected === 0 ? 1 : null,
                            }}
                        >
                            <span style={{ ...style.cW, ...style.ttU }}>
                                {t('portfolio')}
                            </span>
                        </div>
                    </Grid>
                    <Grid
                        item xs={6} md={3}
                        container justifyContent="center"
                        style={{ paddingLeft: 5 }}
                    >
                        <div
                            className="evaluation-rating-button"
                            onClick={() => setState({ ...state, menuSelected: 1 })}
                            style={{
                                backgroundColor: softskillsColor,
                                opacity: state.menuSelected === 1 ? 1 : null,
                            }}
                        >
                            <span style={{ ...style.cW, ...style.ttU }}>
                                {t('softskills')}
                            </span>
                        </div>
                    </Grid>
                </Grid>
                {/* -------------------- MENU -------------------- */}
                {(state.user != null && state.menuSelected === 0) && (
                    <Portfolios user={state.user} />
                )}
                {(state.user != null && state.menuSelected === 1) && (
                    <SoftSkills user={state.user} />
                )}
            </div>
        </>
    );
};

export default UserPortfolioRouter;
