import React from 'react';

// Import Material-UI
import Select from '@material-ui/core/Select';

// ---------------------------------------- SELECT OUTLINED ---------------------------------------- \\
const SelectOutlined = ({ children, value, onChange, disabled }) => {
    return (
        <Select
            value={value}
            onChange={onChange}
            disabled={disabled}
            fullWidth
            variant="outlined"
            SelectDisplayProps={{ style: { padding: '10px 14px' } }}
            MenuProps={{
                style: { maxHeight: 300, maxWidth: 500 },
                getContentAnchorEl: null, 
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
            }}
        >
            {children}
        </Select>
    );
};

SelectOutlined.defaultProps = {
    disabled: false,
};

export default SelectOutlined;
