import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, CircularProgress } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { updatePassword } from 'services/requests';
import { theme } from 'services/helpers';

// Components
import Footer from './footer';
import FooterMobiRhin from './footer-mobirhin';
import SelectLanguage from './select-language';
import InputText from '../items/input-text';

// Assets
import ZerobarrierLogo from 'assets/images/zerobarrier-white.png';

// Style
import style from 'style';

const styles = {
    card: {
        border: '1px solid lightgray',
        borderRadius: 4,
        textAlign: 'center',
        padding: 25,
    },
};

// ---------------------------------------- RESET PASSWORD ---------------------------------------- \\
const ResetPassword = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        new_password: '',
        confirm_password: '',
    });

    function handleUpdatePassword() {
        if (state.new_password === '' || state.confirm_password === '') {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.new_password !== state.confirm_password) {
            enqueueSnackbar(t('password.not.match'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            const email = location.search.split('?email=')[1].split('&')[0];
            const code = location.search.split('&password_reset=')[1];

            updatePassword(email, code, state.new_password).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/home');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Grid
                className="header"
                container alignItems="center"
            >
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <img
                        alt="Logo Zerobarrier White"
                        src={ZerobarrierLogo}
                        style={{ height: 50 }}
                    />
                </Grid>
                <Grid
                    item xs={12} md={6}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <SelectLanguage />
                </Grid>
            </Grid>
            {/* -------------------- RESET PASSWORD -------------------- */}
            <div className="main">
                <div className="full-container" style={{ margin: 25, ...style.jcC }}>
                    <Grid container>
                        <Grid item xs={12} sm={2} md={3} lg={4} />
                        <Grid item xs={12} sm={8} md={6} lg={4} style={styles.card}>
                            {/* -------------------- TITLE -------------------- */}
                            <p style={{ ...style.fwB, ...style.ttU }}>
                                {t('password.update')}
                            </p>
                            {/* -------------------- NEW PASSWORD -------------------- */}
                            <div style={style.mb10}>
                                <InputText
                                    placeholder={t('password.new') + '*'}
                                    value={state.new_password}
                                    onChange={e => setState({ ...state, new_password: e.target.value })}
                                    type="password"
                                />
                            </div>
                            {/* -------------------- CONFIRM PASSWORD -------------------- */}
                            <div style={style.mb10}>
                                <InputText
                                    placeholder={t('password.confirm') + '*'}
                                    value={state.confirm_password}
                                    onChange={e => setState({ ...state, confirm_password: e.target.value })}
                                    type="password"
                                />
                            </div>
                            {/* -------------------- BUTTON VALIDATE -------------------- */}
                            <Button onClick={handleUpdatePassword} variant="contained" color="primary">
                                {state.loading ? (
                                    <CircularProgress size={24} style={style.cW} />
                                ) : (
                                    t('validate')
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* -------------------- FOOTER -------------------- */}
            {theme === 'mobirhin' ? (
                <FooterMobiRhin />
            ) : (
                <Footer />
            )}
        </>
    );
};

export default ResetPassword;
