import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid, Checkbox } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import InputText from 'components/items/input-text';
import Select from 'components/items/react-select';

// ---------------------------------------- MOBILITY FORM PAGE 2 ---------------------------------------- \\
const MobilityFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage3 = () => {
        if (state.origin_company_id == null
            || (state.origin_trainer_id == null && state.origin_trainer_name === '')
            || state.learner_id == null
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    const resetState = () => {
        setState(state => ({
            ...state,
            authorizedRefetchOrigin: false,
            origin_trainers: [],
            origin_trainer_id: null,
            learners: [],
            learner_id: null,
        }));
    };

    useEffect(() => {
        if (state.authorizedRefetchOrigin) {
            if (state.origin_company_id) {
                getCompanyMembers(state.origin_company_id).then(res => {
                    if (res?.status === 200) {
                        setState(state => ({
                            ...state,
                            authorizedRefetchOrigin: false,
                            origin_trainers: res.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                            origin_trainer_id: null,
                            learners: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                            learner_id: null,
                        }));
                    }
                    else {
                        console.log(res);
                        resetState();
                    }
                });
            }
            else {
                resetState();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.origin_company_id]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- ORIGIN COMPANY -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('company.origin')}</b><FieldRequired />
                            <Select
                                placeholder={t('company.origin')}
                                value={state.origin_company_id ? state.companies.find(c => c.value === state.origin_company_id) : null}
                                onChange={company => setState({ ...state, origin_company_id: company ? company.value : null, authorizedRefetchOrigin: true })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- ORIGIN TUTOR -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('tutor.origin')}</b><FieldRequired />
                            <Grid container alignItems="center" style={{ paddingBottom: 10 }}>
                                <Checkbox
                                    checked={state.origin_trainer_manual_entry}
                                    onChange={e => setState({ ...state, origin_trainer_manual_entry: e.target.checked, origin_trainer_id: null, origin_trainer_name: '' })}
                                    style={{ padding: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>{t('manual.entry')}</span>
                            </Grid>
                            {state.origin_trainer_manual_entry ? (
                                <InputText
                                    placeholder={t('tutor.origin')}
                                    value={state.origin_trainer_name}
                                    onChange={e => setState({ ...state, origin_trainer_name: e.target.value })}
                                />
                            ) : (
                                <Select
                                    placeholder={t('tutor.origin')}
                                    value={state.origin_trainer_id ? state.origin_trainers.find(t => t.value === state.origin_trainer_id) : null}
                                    onChange={trainer => setState({ ...state, origin_trainer_id: trainer ? trainer.value : null })}
                                    options={state.origin_trainers}
                                />
                            )}
                        </div>
                        {/* -------------------- LEARNER -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('learner')}</b><FieldRequired />
                            <Select
                                placeholder={t('learner')}
                                value={state.learner_id ? state.learners.find(t => t.value === state.learner_id) : null}
                                onChange={learner => setState({ ...state, learner_id: learner ? learner.value : null })}
                                options={state.learners}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>2 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default MobilityFormPage2;
