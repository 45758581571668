import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Tools to Create PDF
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Material-UI
import { Dialog, DialogActions, DialogContent, Button, Divider, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { fileUrl, convertImgToBase64URL, calculateAspectRatioFit, softskillsColor, softskillsColor2, getTranslation, getFormattedDate } from 'services/helpers';
import { getCompanySoftCertificate, getCompanyList } from 'services/requests';
import { getStoredUser } from 'services/storage';

// Assests
import LogoZB from 'assets/images/zerobarrier.png';

// Components
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// ---------------------------------------- SOFT CERTIFICATE ---------------------------------------- \\
const SoftCertificate = ({ onClose, userId }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();
    const storedUserCompany = storedUser ? storedUser.company : null;

    const [state, setState] = useState({
        companies: [],
        company: null,
        soft_categories: null,
        user: null,
        loading: false,
    });
    
    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setState({
                    ...state,
                    companies: res.data.data.companies.map(c => ({ value: c.id, label: c.name, logo: c.photo, footer: c.footer })),
                    company: ({ value: storedUserCompany?.id, label: storedUserCompany?.name, logo: storedUserCompany?.photo, footer: storedUserCompany?.footer }),
                });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.company && state.company.value) {
            getCompanySoftCertificate(userId, state.company.value).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        soft_categories: res.data.data.soft_categories,
                        user: res.data.data.user,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, soft_categories: null, user: null });
                }
            });
        }
        else {
            setState({ ...state, soft_categories: null, user: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company]);

    useEffect(() => {
        if (state.loading) {
            if (state.soft_categories.length > 0) {
                SoftCertificateExport(t, state, setState);
            }
            else {
                enqueueSnackbar(t('no.data.to.export.found'), { variant: 'warning' });
                setState({ ...state, loading: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 24 }}>
                {/* -------------------- COMPANY -------------------- */}
                <b className="input-title">{t('company')}</b><FieldRequired />
                <Select
                    placeholder={t('company')}
                    value={state.company}
                    onChange={company => setState({ ...state, company })}
                    options={state.companies}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={(state.soft_categories == null) || state.loading}
                    variant="contained" color="primary"
                >
                    {state.loading ? (
                        <CircularProgress color="inherit" size={24} />
                    ) : (
                        <>
                            {t('download')}
                            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                        </>
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// -------------------------------------- SOFT CERTIFICATE EXPORT ----------------------------------- \\
const SoftCertificateExport = async(t, state, setState) => {

    // Company footer
    var companyFooter = state.company?.footer;

    // Create new PDF
    var pdf = new jsPDF('p', 'mm', 'a4');

    // Introduction Sentence
    pdf.setFontSize(11);
    var introduction =  t('company.certificate.declaration.1') + ', ' + state.company?.label + ' ' + t('company.certificate.declaration.2') + ' ' + state.user?.full_name + ' ' + t('company.certificate.declaration.3') + ' :';
    var introductionContainer = pdf.splitTextToSize(introduction, 190);
    pdf.text(introductionContainer, 10, 40);

    // Company Logo
    var companyLogo = null, companyLogoWidth = null, companyLogoHeight = null;
    if (state.company?.logo != null) {
        try {
            let { imgData, width, height } = await convertImgToBase64URL(fileUrl + state.company.logo);
            let { resizedWidth, resizedHeight } = calculateAspectRatioFit(width, height, 50, 20);
            companyLogo = imgData; companyLogoWidth = resizedWidth; companyLogoHeight = resizedHeight;
        }
        catch (e) {
            console.log(e);
        }
    }

    // Validate Soft Skills
    state.soft_categories.forEach((soft_category, index) => {

        // Body
        var body = [];

        // Soft Category
        var softCategoryTab = [];
        softCategoryTab.push({
            content: getTranslation('name', soft_category)?.toUpperCase(),
            styles: { halign: 'center', fillColor: softskillsColor, fontStyle: 'bold', textColor: 'white' },
            colSpan: 3,
        });
        body.push(softCategoryTab);

        // Headers
        var headersTab = [];
        headersTab.push({
            content: t('softskills'),
            styles: { halign: 'center', fillColor: softskillsColor2, fontStyle: 'bold', cellWidth: 60 },
        });
        headersTab.push({
            content: t('level'),
            styles: { halign: 'center', fillColor: softskillsColor2, fontStyle: 'bold', cellWidth: 90 },
        });
        headersTab.push({
            content: t('validation.date'),
            styles: { halign: 'center', fillColor: softskillsColor2, fontStyle: 'bold', cellWidth: 40 },
        });
        body.push(headersTab);

        soft_category.softskills.forEach(softskill => {

            // Soft Skill
            var softskillTab = [];
            softskillTab.push({
                content: getTranslation('name', softskill) + '\r\n' + softskill.easy_to_read,
                styles: { valign: 'middle', cellWidth: 60 },
            });
            softskillTab.push({
                content: (softskill.evaluation_details != null) ? (t('level') + ' : ' + softskill.evaluation_details.level + '\r\n' + softskill.evaluation_details.level_definition) : '',
                styles: { valign: 'middle', cellWidth: 90 },
            });
            softskillTab.push({
                content: (softskill.evaluation_details != null) ? (softskill.evaluation_details.company + '\r\n' + getFormattedDate(softskill.evaluation_details.evaluation_date)) : '',
                styles: { halign: 'center', valign: 'middle', cellWidth: 40 },
            });
            body.push(softskillTab);
        });

        var bottom = companyFooter ? 25 : 15;
        if ((index + 1) === state.soft_categories.length) {
            bottom = companyFooter ? 55 : 40;
        }
    
        autoTable(pdf, {
            margin: {
                top: (index === 0) ? 50 : 30,
                bottom: bottom,
                right: 10,
                left: 10,
            },
            body: body,
            theme: 'grid',
        });
    });

    // Headers & Footers of Page
    var numberOfPages = pdf.internal.getNumberOfPages();
    for (let i = 0; i < numberOfPages; i += 1) {

        // Set Current Page
        pdf.setPage(i + 1);

        // ZeroBarrier Logo
        pdf.addImage(LogoZB, 10, 10, 36.5, 7.7);

        // Company Logo
        if (companyLogo != null && companyLogoWidth != null && companyLogoHeight != null) {
            pdf.addImage(companyLogo, 'JPEG', 150, 10, companyLogoWidth, companyLogoHeight);
        }

        // Title
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(0);
        pdf.text(t('soft.certificate'), (pdf.internal.pageSize.width / 2), 25, { align: 'center' });

        // Page Number
        var pageNumberY = companyFooter ? 272 : 287;
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bolditalic');
        pdf.text(((i + 1) + ' / ' + numberOfPages), (pdf.internal.pageSize.width / 2), pageNumberY, { align: 'center' });

        // Company Footer
        if (companyFooter) {
            pdf.line(10, 277, 200, 277);
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'italic');
            var companyFooterContainer = pdf.splitTextToSize(companyFooter, 190);
            pdf.text(companyFooterContainer, 105, 284, { align: 'center' });
        }

        if ((i + 1) === numberOfPages) {

            // Date
            var dateY = companyFooter ? 255 : 265;
            pdf.setFontSize(11);
            pdf.setFont('helvetica', 'normal');
            pdf.text((t('done.the') + moment().format('DD/MM/YYYY') + '.'), 10, dateY);

            // Conclusion
            var conslusionY = companyFooter ? 260 : 270;
            var conclusion = t('company.certificate.infos') + '  « Soft skills ».';
            var conclusionContainer = pdf.splitTextToSize(conclusion, 190);
            pdf.text(conclusionContainer, 10, conslusionY);
        }
    };

    // Save PDF
    pdf.save(state.user?.full_name + ' - ' + t('soft.certificate') + '.pdf');
    setState({ ...state, loading: false });
};

export default SoftCertificate;
