import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Radio, FormControlLabel, RadioGroup, Button, Checkbox } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { fileUrl, servicesColor } from 'services/helpers';

// Components
import DatePicker from 'components/items/date-picker';
import InputFile from 'components/items/input-file';

// Style
import style from 'style';
import FieldRequired from 'components/items/field-required';

// ------------------------------------ NEWS FORM PAGE 4 ---------------------------------------- \\
const NewsFormPage4 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    function onValidate() {
        if (state.publication_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            let photo = document.getElementById('upload-news-photo').files[0];
            let doc = document.getElementById('upload-news-document').files[0];
            setState({ ...state, loading: true, photo, document: doc });
        }
    }

    return (
        <>
            <div className="full-container" style={{ paddingTop: 24, paddingBottom: 24 }}>
                {/* -------------------- DATE -------------------- */}
                <Grid container alignItems="center" style={style.m5_0}>
                    <Grid item xs={12} md={4}>
                        <b className="input-title">{t('date')}</b><FieldRequired />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            placeholder={t('date')}
                            outlined
                            value={state.publication_date}
                            onChange={publication_date => setState({ ...state, publication_date })}
                        />
                    </Grid>
                </Grid>
                <Divider style={style.m10_0} />
                {/* -------------------- UPLOAD PHOTO -------------------- */}
                <Grid container alignItems="center" style={style.m5_0}>
                    <Grid item xs={12} md={4}>
                        <b className="input-title">{t('news.photo.upload')}</b>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <span style={{...style.fsI, ...style.fwB, fontSize: 12}}>
                            {t('format')} JPEG, PNG
                        </span>
                        <InputFile inputId="news-photo" />
                        {state.actionType === 'EDIT' && state.photo != null && (
                            <>
                                <Button
                                    variant="contained"
                                    href={`${fileUrl + state.photo}`}
                                    target="blank"
                                    style={{ margin: '16px 0px' }}
                                >
                                    {t('view.news.photo')}
                                </Button>
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.remove_photo}
                                        onChange={e => setState({ ...state, remove_photo: e.target.checked })}
                                        color="secondary"
                                    />
                                    <span>{t('news.remove.actual.photo')}</span>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Divider style={style.m10_0} />
                {/* -------------------- UPLOAD DOCUMENT -------------------- */}
                <Grid container alignItems="center" style={style.m5_0}>
                    <Grid item xs={12} md={4}>
                        <b className="input-title">{t('news.document.upload')}</b>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <span style={{...style.fsI, ...style.fwB, fontSize: 12}}>
                            {t('format')} PDF, DOC, DOCX
                        </span>
                        <InputFile inputId="news-document" />
                        {state.actionType === 'EDIT' && state.document != null && (
                            <>
                                <Button
                                    variant="contained"
                                    href={`${fileUrl + state.document}`}
                                    target="blank"
                                    style={{ margin: '16px 0px' }}
                                >
                                    {t('view.news.document')}
                                </Button>
                                <Grid container alignItems="center">
                                    <Checkbox
                                        checked={state.remove_document}
                                        onChange={e => setState({...state, remove_document: e.target.checked })}
                                        color="secondary"
                                    />
                                    <span>{t('news.remove.actual.document')}</span>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Divider style={style.m10_0} />
                {/* -------------------- STATUS-------------------- */}
                <Grid container alignItems="center" style={style.m5}>
                    <Grid item xs={4}>
                        <b className="input-title">{t('status')}</b>
                    </Grid>
                    <Grid container justifyContent="flex-start" item xs={8}>
                        <RadioGroup
                            row
                            value={state.status}
                            onChange={e => setState({ ...state, status: e.target.value })}
                        >
                            <FormControlLabel
                                value="0"
                                label={t('rough')}
                                control={<Radio />}
                            />
                            <FormControlLabel
                                value="1"
                                label={t('published')}
                                control={<Radio color="primary" />}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 3 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>4 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={onValidate}
                        variant="contained"
                        style={{ backgroundColor: servicesColor, color: 'white' }}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default NewsFormPage4;
