import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Button, Divider, Grid } from '@material-ui/core';
import { Build as IconTools, School as IconTrain, AssignmentTurnedIn as IconPass } from '@material-ui/icons';

// Services
import { mobipassColor, mobitrainColor, mobitoolsColor } from 'services/helpers';
import { theme } from 'services/helpers';

// Assets
import LogoMobipass from 'assets/images/mobipass.png';
import LogoMobitrain from 'assets/images/mobitrain.png';
import LogoMobitools from 'assets/images/mobitools.png';
import LogoErasmus from 'assets/images/erasmus.png';

// Components
import Header from './common/header';
import Footer from './common/footer';
import FooterMobiRhin from './common/footer-mobirhin';

// Style
import style from '../style';

// ---------------------------------------- DASHBOARD ---------------------------------------- \\
const Dashboard = () => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Header />
            {/* -------------------- MAIN -------------------- */}
            <div className="main">
                <Grid container alignItems="center" style={{ ...style.f1, ...style.p25 }}>
                    <Grid item xs={12} md={6} style={style.taC}>
                        <img style={{ height: 100 }} alt="mobipass" src={LogoMobipass} />
                    </Grid>
                    <Grid item xs={12} md={6} style={style.taC}>
                        <Button
                            onClick={() => history.push('/mobipass')}
                            variant="contained" color="primary"
                            style={{ backgroundColor: mobipassColor }}
                        >
                            {t('go.to') + ' MOBIPASS'}
                            <IconPass style={style.ml10} />
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center" style={{ ...style.f1, ...style.p25 }}>
                    <Grid item xs={12} md={6} style={style.taC}>
                        <img style={{ height: 100 }} alt="mobitrain" src={LogoMobitrain} />
                        <br />
                        <img style={{ height: 35, marginBottom: 10 }} alt="erasmus" src={LogoErasmus} />
                    </Grid>
                    <Grid item xs={12} md={6} style={style.taC}>
                        <Button
                            onClick={() => history.push('/mobitrain')}
                            variant="contained" color="primary"
                            style={{ backgroundColor: mobitrainColor }}
                        >
                            {t('go.to') + ' MOBITRAIN'}
                            <IconTrain style={style.ml10} />
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center" style={{ ...style.f1, ...style.p25 }}>
                    <Grid item xs={12} md={6} style={style.taC}>
                        <img style={{ height: 100 }} alt="mobitools" src={LogoMobitools} />
                    </Grid>
                    <Grid item xs={12} md={6} style={style.taC}>
                        <Button
                            onClick={() => history.push('/mobitools')}
                            variant="contained" color="primary"
                            style={{ backgroundColor: mobitoolsColor }}
                        >
                            {t('go.to') + ' MOBITOOLS'}
                            <IconTools style={style.ml10} />
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {/* -------------------- FOOTER -------------------- */}
            {theme === 'mobirhin' ? (
                <FooterMobiRhin />
            ) : (
                <Footer />
            )}
        </>
    );
};

export default Dashboard;
