import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid, Divider, IconButton } from '@material-ui/core';
import {
    Search as IconSearch, Cancel as IconCancel, Delete as IconDelete,
    Create as IconEdit, Assignment as IconDetails, Loupe as IconMore,
    FileCopy as IconDuplicate
} from '@material-ui/icons';

// Services
import { getFormations } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';
import { mobitrainColor, getTranslation, getFormattedDate } from 'services/helpers';

// Components
import CreateFormation from './formation-create';
import UpdateFormation from './formation-update';
import DeleteFormation from './formation-delete';
import DuplicateFormation from './formation-duplicate';
import DetailsFormation from './formation-details';
import ExportFormation from './formation-export';
import IndividualExportFormation from './formation-individual-export';
import { LearnersModal, TrainersModal, CompaniesModal } from './formation-modals';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import ChangeOrderFilter from 'components/items/change-order-filter';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';
import SelectSkillbase from 'components/select/select-skillbase';

// Style
import style from 'style';

// ------------------------------------- FORMATION ROUTER ------------------------------------ \\
const FormationRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <Formation />
            </Route>
            <Route exact path={`${match.path}/new-formation`}>
                <CreateFormation />
            </Route>
            <Route exact path={`${match.path}/:formationId`}>
                <UpdateFormation />
            </Route>
            <Route exact path={`${match.path}/:formationId/duplicate`}>
                <DuplicateFormation />
            </Route>
        </Switch>
    );
};

// ---------------------------------------- FORMATION ---------------------------------------- \\
const Formation = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('formations-filters'));
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        formations: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: storedFilters?.name || '',
        skillbase_id: storedFilters?.skillbase_id || null,
        company_id: storedFilters?.company_id || null,
        trainer: storedFilters?.trainer || '',
        learner: storedFilters?.learner || '',
        param: storedFilters?.param || null,
        order: storedFilters?.order || 'desc',
        openCreateFormation: false,
        openIndividualExport: false,
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;
    
    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    function onCancelSearch() {
        sessionStorage.removeItem('formations-filters');
        setState({
            ...state,
            loading: true,
            offset: 0,
            name: '',
            skillbase_id: null,
            company_id: null,
            trainer: '',
            learner: '',
            param: null,
            order: 'desc',
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('formations-filters', JSON.stringify({
                name: state.name,
                skillbase_id: state.skillbase_id,
                company_id: state.company_id,
                trainer: state.trainer,
                learner: state.learner,
                param: state.param,
                order: state.order,
            }));

            getFormations({
                offset: state.offset,
                limit: state.limit,
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
                trainer: state.trainer,
                learner: state.learner,
                param: state.param,
                order: state.order,
            }).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        formations: res.data.data.formations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('training')}
            </BreadcrumbsItem>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={3}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push(`${match.path}/new-formation`)}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={6} container justifyContent="center">
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid
                    item xs={12} md={3}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => setState({ ...state, openIndividualExport: true })}
                            variant="contained"
                        >
                            {t('export.individual')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container alignItems="center" style={{ backgroundColor: mobitrainColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                                <span style={style.cW}>&nbsp;-&nbsp;</span>
                                <ChangeOrderFilter
                                    state={state}
                                    setState={setState}
                                    param='formation_date' 
                                    label={t('date')}   
                                />
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('trainer')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('training')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('name') + ' - ' + t('date')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('trainer')}
                                value={state.trainer}
                                onChange={e => setState({ ...state, trainer: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner}
                                onChange={e => setState({ ...state, learner: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportFormation data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- FORMATION LIST -------------------- */}
                <FormationList
                    ROLE_ID={ROLE_ID}
                    formations={state.formations}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
            {/* -------------------- INDIVIDUAL EXPORT FORMATION -------------------- */}
            {state.openIndividualExport && (
                <IndividualExportFormation
                    open={state.openIndividualExport}
                    onClose={() => setState({ ...state, openIndividualExport: false })}
                />
            )}
        </>
    );
};

// -------------------------------------- FORMATION LIST ------------------------------------- \\
const FormationList = ({ ROLE_ID, formations, loading, error, refetch }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const USER_ID = getStoredUser()?.id;
    const { t } = useTranslation();

    const [state, setState] = useState({
        formation: null,
        openDetailsFormation: false,
        openCompaniesModal: false,
        openDeleteFormation: false,
        openUpdateFormation: false,
        openLearnersModal: false,
        openTrainersModal: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {formations.length > 0 ? (
                    formations.map(formation => (
                        <Fragment key={formation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{formation.name}</span>
                                    <br/>
                                    <span>{getFormattedDate(formation.formation_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {formation.companies && (
                                        formation.companies.length > 1 ? (
                                            <>
                                                <span>{formation.companies.length}</span>
                                                <Tooltip title={t('trainers.list')}
                                                    item={
                                                        <IconButton
                                                            onClick={() => setState({ ...state, openCompaniesModal: true, formation })}
                                                            className="list-btn"
                                                        >
                                                            <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                        </IconButton>
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <span>{formation.companies[0]?.name}</span>
                                        )
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{formation.skillbase ? getTranslation('name', formation.skillbase) : ''}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {formation.formation_trainers.length === 1 ? (
                                        <span>
                                            {formation.formation_trainers[0].trainer.full_name}
                                        </span>
                                    ) : (
                                        <>
                                            <span>{formation.formation_trainers.length}</span>
                                            <Tooltip title={t('trainers.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openTrainersModal: true, formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {formation.formation_learners.length === 1 ? (
                                        <span>
                                            {formation.formation_learners[0].learner.full_name}
                                        </span>
                                    ) : (
                                        <>
                                            <span>{formation.formation_learners.length}</span>
                                            <Tooltip title={t('learners.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openLearnersModal: true, formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, formation, openDetailsFormation: true })}
                                                className="list-btn"
                                            >
                                                <IconDetails />
                                            </IconButton>
                                        }
                                    />
                                    {(ROLE_ID <= 2 || formation.creator.id === USER_ID) && (
                                        <>
                                            <Tooltip title={t('duplicate')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push(`${match.path}/${formation.id}/duplicate`)}
                                                        className="list-btn"
                                                    >
                                                        <IconDuplicate />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('edit')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push(`${match.path}/${formation.id}`)}
                                                        className="list-btn"
                                                    >
                                                        <IconEdit />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('delete')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, formation, openDeleteFormation: true })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- UPDATE FORMATION -------------------- */}
            {state.openUpdateFormation && (
                <UpdateFormation
                    open={state.openUpdateFormation}
                    onClose={() => setState({ ...state, openUpdateFormation: false })}
                    refetch={() => handleRefetch('openUpdateFormation')}
                    formation={state.formation}
                />
            )}
            {/* -------------------- DETAILS FORMATION -------------------- */}
            {state.openDetailsFormation && (
                <DetailsFormation
                    open={state.openDetailsFormation}
                    onClose={() => setState({ ...state, openDetailsFormation: false })}
                    formation={state.formation}
                />
            )}
            {/* -------------------- DELETE FORMATION -------------------- */}
            {state.openDeleteFormation && (
                <DeleteFormation
                    open={state.openDeleteFormation}
                    onClose={() => setState({ ...state, openDeleteFormation: false })}
                    refetch={() => handleRefetch('openDeleteFormation')}
                    formation={state.formation}
                />
            )}
            {/* -------------------- COMPANIES MODAL -------------------- */}
            {state.openCompaniesModal && (
                <CompaniesModal
                    open={state.openCompaniesModal}
                    onClose={() => setState({ ...state, openCompaniesModal: false })}
                    formation={state.formation}
                />
            )}
            {/* -------------------- LEARNERS MODAL -------------------- */}
            {state.openLearnersModal && (
                <LearnersModal
                    open={state.openLearnersModal}
                    onClose={() => setState({ ...state, openLearnersModal: false })}
                    formation={state.formation}
                />
            )}
            {/* -------------------- TRAINERS MODAL -------------------- */}
            {state.openTrainersModal && (
                <TrainersModal
                    open={state.openTrainersModal}
                    onClose={() => setState({ ...state, openTrainersModal: false })}
                    formation={state.formation}
                />
            )}
        </>
    );
};

export default FormationRouter;
