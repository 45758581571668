import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Divider, Button, Grid, IconButton, MenuItem } from '@material-ui/core';
import {
    Delete as IconDelete, Create as IconEdit, Search as IconSearch, Assignment as IconDetail,
    Cancel as IconCancel, CheckCircle as IconPaid,
} from '@material-ui/icons';

// Services
import { getBills, getContracts } from 'services/requests';
import { servicesColor, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateBill from './bill-create';
import UpdateBill from './bill-update';
import ExportBill from './bill-export';
import { DeleteBill, DetailsBill } from './bill-modals';
import BillInfos from './bill-infos';
import Subscriptions from './subscriptions';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import SelectOutlined from 'components/items/select-outlined';
import DatePicker from 'components/items/date-picker';
import Autocomplete from 'components/items/autocomplete';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// ----------------------------------------- ROUTER ---------------------------------------- \\
const BillsRouter = () => {
    const match = useRouteMatch();
    const childRef = useRef();

    function refetch() {
        childRef.current.refetch();
    }

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <Bills />
            </Route>
            <Route path={`${match.path}/:billId`}>
                <BillInfos refetch={refetch} />
                <Subscriptions ref={childRef} />
            </Route>
        </Switch>
    );
};

// ------------------------------------------ BILLS ---------------------------------------- \\
const Bills = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const CLIENT_ID = match.params.clientId;
    const CONTRACT_ID = match.params.contractId;

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 50,
        total_count: 0,
        paid: -1,
        bill_date: null,
        number_bill: '',
        client_name: '',
        contract: null,
        openCreateBill: false,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            paid: -1,
            bill_date: null,
            number_bill: '',
            contract: '',
            client_name: '',
        });
    };

    useEffect(() => {
        if (state.loading) {
            getBills({
                offset: state.offset,
                limit: state.limit,
                paid: state.paid,
                bill_date: state.bill_date ? moment(state.bill_date).format('YYYY-MM-DD') : null,
                number_bill: state.number_bill,
                contract_id: CONTRACT_ID || (state.contract && state.contract.value),
                client_id: CLIENT_ID,
                client_name: state.client_name,
            }).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        data: res.data.data.bills,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                    }));
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                {CONTRACT_ID ? (
                    <b>{t('bills.linked.contract')}</b>
                ) : CLIENT_ID ? (
                    <b>Factures liées au client</b>
                ) : (
                    <b>{t('bills')}</b>
                )}
                {CONTRACT_ID && state.contract && (
                    <span style={style.fs16}>
                        {t('contract') + ' ' + state.contract.reference}
                    </span>
                )}
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="space-between" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        variant="contained"
                        onClick={(CLIENT_ID && CONTRACT_ID) ? (
                            () => history.push(`/management/billing/clients/${CLIENT_ID}/contracts`)
                        ) : CLIENT_ID ? (
                            () => history.push(`/management/billing/clients`)
                        ) : CONTRACT_ID ? (
                            () => history.push('/management/billing/contracts')
                        ) : (
                            () => history.push('/management/billing')
                        )}
                    >
                        {t('previous')}
                    </Button> 
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    {(!CLIENT_ID || CONTRACT_ID) && (
                        <Button
                            onClick={() => setState({ ...state, openCreateBill: true })}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>     
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={1} style={style.p10}>
                                <span style={style.cW}>{t('paid')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('number.bill')}</span>
                            </Grid>
                            <Grid item xs={1} style={style.p10}>
                                <span style={style.cW}>{t('total.amount')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('bill.date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('client')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                {!CONTRACT_ID && (
                                    <span style={style.cW}>{t('contract')}</span>
                                )}
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('bills')}</span>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={1} style={style.p10}>
                            <SelectOutlined
                                value={state.paid}
                                onChange={e => onSelectSearch('paid', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                <MenuItem value="0">
                                    <span>{t('unpaid')}</span>
                                </MenuItem>
                                <MenuItem value="1">
                                    <span>{t('paid')}</span>
                                </MenuItem>
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('number.bill')}
                                value={state.number_bill}
                                onChange={e => setState({ ...state, number_bill: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={1} style={style.p10} />
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('bill.date')}
                                value={state.bill_date}
                                onChange={bill_date => onSelectSearch('bill_date', bill_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('client')}
                                value={state.client_name}
                                onChange={e => setState({ ...state, client_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            {!CONTRACT_ID && (
                                <SelectContract
                                    contract={state.contract}
                                    setContract={contract => setState({ ...state, contract })}
                                />
                            )}
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportBill data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* ---------------------- BILL LIST ---------------------- */}
                <BillList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* ---------------------- CREATE BILL ---------------------- */}
            {state.openCreateBill && (
                <CreateBill
                    onClose={() => setState({ ...state, openCreateBill: false })}
                    refetch={() => setState({ ...state, openCreateBill: false, loading: true })}
                />
            )}
        </>
    );
};

// ---------------------------------------- BILL LIST -------------------------------------- \\
const BillList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const CLIENT_ID = match.params.clientId;
    const CONTRACT_ID = match.params.contractId;

    const [state, setState] = useState({
        bill: undefined,
        openDetailsBill: false,
        openUpdateBill: false,
        openDeleteBill: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    function handleOpenModal(e, open, bill) {
        e.stopPropagation();
        setState({ ...state, [open]: true, bill });
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="half-container-content">
                {data.length > 0 ? (
                    data.map(bill => (
                        <Fragment key={bill.id}>
                            <Grid
                                onClick={(CLIENT_ID && CONTRACT_ID) ? (
                                    () => history.push(`/management/billing/clients/${CLIENT_ID}/contracts/${CONTRACT_ID}/bills/${bill.id}`)
                                ) : CLIENT_ID ? (
                                    () => history.push(`/management/billing/clients/${CLIENT_ID}/bills/${bill.id}`)
                                ) : CONTRACT_ID ? (
                                    () => history.push(`/management/billing/contracts/${CONTRACT_ID}/bills/${bill.id}`)
                                ) : (
                                    () => history.push(`${match.path}/${bill.id}`)
                                )}
                                container
                                alignItems="center"
                                className="list-item-hover"
                            >
                                <Grid item xs={12} md={1} style={{ ...style.p10, ...style.taC }}>
                                    {bill.paid === 0 ? (
                                        <IconCancel style={{ color: 'red' }} />
                                    ) : (
                                        <IconPaid style={{ color: 'green' }} />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{bill.number_bill ? bill.number_bill : 'FATEMP' + bill.id}</span>
                                </Grid>
                                <Grid item xs={12} md={1} style={style.p10}>
                                    <span>{bill.total_amount_TTC}€</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(bill.bill_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{bill.contract.client.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {!CONTRACT_ID && (
                                        <span>{bill.contract.reference}</span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openDetailsBill', bill)}
                                                className="list-btn"
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openUpdateBill', bill)}
                                                className="list-btn"
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openDeleteBill', bill)}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- DETAILS BILL -------------------- */}
            {state.openDetailsBill && (
                <DetailsBill
                    onClose={() => setState({ ...state, openDetailsBill: false })}
                    refetch={() => handleRefetch('openDetailsBill')}
                    bill={state.bill}
                />
            )}
            {/* -------------------- UPDATE BILL -------------------- */}
            {state.openUpdateBill && (
                <UpdateBill
                    onClose={() => setState({ ...state, openUpdateBill: false })}
                    refetch={() => handleRefetch('openUpdateBill')}
                    bill={state.bill}
                />
            )}
            {/* -------------------- DELETE BILL -------------------- */}
            {state.openDeleteBill && (
                <DeleteBill
                    onClose={() => setState({ ...state, openDeleteBill: false })}
                    refetch={() => handleRefetch('openDeleteBill')}
                    bill={state.bill}
                />
            )}
        </>
    );
};

// -------------------------------------- SELECT CONTRACT ---------------------------------- \\
const SelectContract = ({ contract, setContract }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        open: false,
        loading: false,
        options: [],
        searchRef: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {        
            getContracts(0, 10, state.searchRef).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        options: res.data.data.contracts.map(c => ({ label: c.reference, value: c.id })),
                    });
                }
                else {
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Autocomplete
            value={contract}
            options={state.options}
            loading={state.loading}
            onChange={(e, value) => setContract(value)}
            onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
            placeholder={t('search.contract')}
        />
    );
};

export default BillsRouter;
