import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Tools to Create PDF
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Moment
import moment from 'moment';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getHardPositionsDocumentResources, getCompanyList } from 'services/requests';
import { fileUrl, mobipassColor, mobipassColor2, getTranslation, calculateAspectRatioFit, convertImgToBase64URL } from 'services/helpers';
import { getStoredUser } from 'services/storage';

// Assets
import LogoZB from 'assets/images/zerobarrier.png';

// Components
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';

// ----------------------------------- PERFORMANCE REPORT --------------------------------------------- \\
const PerformanceReport = ({ onClose, learner }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();

    const [state, setState] = useState({
        loading: false,
        companies: [],
        company_id: (storedUser && storedUser.company) ? storedUser.company.id : null,
    });

    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setState({ ...state, companies: res.data.data.companies.map(c => ({ value: c.id, label: c.name })) });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading) {
            getHardPositionsDocumentResources(learner.id, state.company_id).then(res => {
                if (res?.status === 200) {
                    const data = res.data.data;
                    if (data.skillbases != null && data.skillbases.length > 0) {
                        PerformanceReportExport(t, res.data.data, learner, state, setState);
                    }
                    else {
                        enqueueSnackbar(t('no.data.to.export.found'), { variant: 'warning' });
                        setState({ ...state, loading: false });
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'warning' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {t('performance.report')}
                </p>
                <b className="input-title">{t('company')}</b><FieldRequired />
                <Select
                    placeholder={t('company')}
                    value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                    onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                    options={state.companies}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    {t('close')}
                </Button>
                <Button
                    onClick={() => setState({ ...state, loading: true })}
                    disabled={(state.company_id == null) || state.loading}
                    variant="contained" color="primary"
                >
                    {state.loading ? (
                        <CircularProgress size={24} style={{ color: 'black' }} />
                    ) : (
                        <>
                            {t('download')}
                            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                        </>
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// --------------------------------- PERFORMANCE REPORT EXPORT ---------------------------------------- \\
const PerformanceReportExport = async(t, data, learner, state, setState) => {

    // Create PDF
    var pdf = new jsPDF('p', 'mm', 'a4');

    // Introduction Sentence
    pdf.setFontSize(11);
    var introduction = t('by.the.present') + data.company?.name + t('attest.that') + learner.full_name + t('has.made.the.proof');
    var introductionContainer = pdf.splitTextToSize(introduction, 190);
    pdf.text(introductionContainer, 10, 40);

    // Company Logo
    var companyLogo = null, companyLogoWidth = null, companyLogoHeight = null;
    if (data.company?.photo != null) {
        try {
            let { imgData, width, height } = await convertImgToBase64URL(fileUrl + data.company.photo);
            let { resizedWidth, resizedHeight } = calculateAspectRatioFit(width, height, 50, 20);
            companyLogo = imgData; companyLogoWidth = resizedWidth; companyLogoHeight = resizedHeight;
        }
        catch (e) {
            console.log(e);
        }
    }

    // Observed Performances
    data.skillbases.forEach((skillbase, index) => {

        // Body
        var body = [];

        // Skillbase
        var skillbaseTab = [];
        skillbaseTab.push({
            content: t('skillbase').toUpperCase(),
            styles: { halign: 'center', fillColor: [63, 81, 181], textColor: 'white', cellWidth: 60 },
        });
        skillbaseTab.push({
            content: getTranslation('name', skillbase),
            styles: { halign: 'center', fillColor: [63, 81, 181], textColor: 'white' },
        });
        body.push(skillbaseTab);

        // Units
        skillbase.units.forEach(unit => {

            // Unit
            var unitTab = [];
            unitTab.push({
                content: t('unit').toUpperCase(),
                styles: { halign: 'center', fillColor: mobipassColor, textColor: 'white', cellWidth: 60 },
            });
            unitTab.push({
                content: (unit.position + '. ' + getTranslation('name', unit)),
                styles: { halign: 'center', fillColor: mobipassColor, textColor: 'white' },
            });
            body.push(unitTab);

            // Headers
            var heardersTab = [];
            heardersTab.push({
                content: t('mastered.activity'),
                styles: { halign: 'center', fillColor: mobipassColor2, textColor: 'black', cellWidth: 60 },
            });
            heardersTab.push({
                content: t('observed.performances'),
                styles: { halign: 'center', fillColor: mobipassColor2, textColor: 'black' },
            });
            body.push(heardersTab);

            // Learnings
            unit.learning_outcomes.forEach(learning => {
                
                // Descriptors
                var descriptors = '';
                learning.competences.forEach(c => {
                    descriptors += (c.numerotation + ' ' + t('competence.numerotation') + ' - ' + getTranslation('name', c) + '\r\n');
                });
                learning.knowledges.forEach(k => {
                    descriptors += (k.numerotation + ' ' + t('knowledge.numerotation') + ' - ' + getTranslation('name', k) + '\r\n');
                });
                learning.skills.forEach(s => {
                    descriptors += (s.numerotation + ' ' + t('skill.numerotation') + ' - ' + getTranslation('name', s) + '\r\n');
                });

                // Learning
                var learningTab = [];
                learningTab.push({
                    content: (unit.position + '.' + learning.position + ' ' + getTranslation('name', learning)),
                    styles: { cellWidth: 60 },
                });
                learningTab.push({
                    content: descriptors,
                });
                body.push(learningTab);
            });
        });

        autoTable(pdf, {
            margin: {
                top: (index === 0) ? 50 : 30,
                bottom: ((index + 1) === data.skillbases.length) ? 55 : 10,
                right: 10,
                left: 10,
            },
            body: body,
            theme: 'grid',
        });
    });

    // Headers & Footers of Page
    var numberOfPages = pdf.internal.getNumberOfPages();
    for (let i = 0; i < numberOfPages; i += 1) {

        // Set Current Page
        pdf.setPage(i + 1);

        // ZeroBarrier Logo
        pdf.addImage(LogoZB, 10, 10, 36.5, 7.7);

        // Company Logo
        if (companyLogo != null && companyLogoWidth != null && companyLogoHeight != null) {
            pdf.addImage(companyLogo, 'JPEG', 150, 10, companyLogoWidth, companyLogoHeight);
        }

        // Title
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.text(t('performance.report').toUpperCase(), (pdf.internal.pageSize.width / 2), 25, { align: 'center' });

        // Page Number
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bolditalic');
        pdf.setTextColor(0);
        pdf.text(((i + 1) + ' / ' + numberOfPages), (pdf.internal.pageSize.width / 2), 287, { align: 'center' });

        if ((i + 1) === numberOfPages) {

            // Date
            pdf.setFontSize(11);
            pdf.setFont('helvetica', 'normal');
            pdf.text((t('done.the') + moment().format('DD/MM/YYYY') + '.'), 10, 242);

            // Signature
            pdf.text(t('signature'), (pdf.internal.pageSize.width / 2), 252, { align: 'center' });
            pdf.setDrawColor(63, 81, 181);
            pdf.rect(55, 254, 100, 28);
        }
    };

    // Save PDF
    pdf.save(learner.full_name + ' - ' + t('performance.report') + '.pdf');
    setState({ ...state, loading: false });
};

export default PerformanceReport;
