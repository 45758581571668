import React from 'react';

// Import Material-UI
import CircularProgress from '@material-ui/core/CircularProgress';

// ---------------------------------------- SPINNER ---------------------------------------- \\
const Spinner = () => {
    return (
        <div className="error-and-spinner">
            <CircularProgress color="inherit" />
        </div>
    );
};

export default Spinner;
