// Moment
import moment from 'moment';

// Tools to Create PDF
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Services
import { cleaColor, cleaColor2, getFormattedDate } from 'services/helpers';

// Assets
import LogoClea from 'assets/images/logo-clea.png';

// --------------------------------- GENERATE CLEA PROOF DOCUMENT --------------------------------- \\
const GenerateCleaProofDocument = (t, user, clea_competences, setLoading) => {
    
    // Create new PDF
    var pdf = new jsPDF('p', 'mm', 'a4');

    // Title
    pdf.setFontSize(14);
    pdf.setFont('helvetica', 'bold');
    pdf.text(t('clea.proof.file')?.toUpperCase(), 105, 20, { align: 'center' });

    // User
    pdf.setFontSize(12);
    pdf.text(user.full_name, 105, 25, { align: 'center' });

    // Introduction
    pdf.setFontSize(11);
    pdf.setFont('helvetica', 'normal');
    var introductionContainer = pdf.splitTextToSize(t('clea.certificate.intro'), 190);
    pdf.text(introductionContainer, 10, 40);

    // Clea Competences
    clea_competences.forEach((competence, index) => {

        // Body
        var body = [];

        // Competence
        var competenceTab = [];
        competenceTab.push({
            content: t('clea.competence') + ' : ' + competence.name,
            styles: { fillColor: cleaColor, textColor: 'white', fontStyle: 'bold' },
            colSpan: 2,
        });
        body.push(competenceTab);

        // Headers
        var headerTab = [];
        headerTab.push({
            content: t('learning.outcome'),
            styles: { halign: 'center', fillColor: cleaColor2, fontStyle: 'bold', cellWidth: 140 },
        });
        headerTab.push({
            content: t('validation.date'),
            styles: { halign: 'center', fillColor: cleaColor2, fontStyle: 'bold', cellWidth: 50 },
        });
        body.push(headerTab);

        // Learnings
        competence.learning_outcomes_validated.forEach(learning => {

            let evaluation_dates = '';
            learning.evaluations.forEach((evaluation, index) => {
                if (index === 0) {
                    evaluation_dates += getFormattedDate(evaluation.evaluation_date);
                }
                else {
                    evaluation_dates += '\r\n' + getFormattedDate(evaluation.evaluation_date);
                }
            });

            // Learning
            var learningTab = [];
            learningTab.push({
                content: learning.skillbase?.name + '\r\n' + learning.unit?.position + '. ' + learning.unit?.name + '\r\n' + learning.unit?.position + '.' + learning.position + ' ' + learning.name,
                styles: { cellWidth: 140 },
            });
            learningTab.push({
                content: evaluation_dates,
                styles: { halign: 'center', valign: 'middle', cellWidth: 50 },
            });
            body.push(learningTab);
        });

        autoTable(pdf, {
            margin: {
                top: (index === 0) ? 70 : 30,
                bottom: ((index + 1) === clea_competences.length) ? 60 : 10,
                right: 10,
                left: 10,
            },
            body: body,
            theme: 'grid',
        });
    });

    // Headers & Footers of Page
    var numberOfPages = pdf.internal.getNumberOfPages();
    for (let i = 0; i < numberOfPages; i += 1) {

        // Set Current Page
        pdf.setPage(i + 1);

        // Logo CLéA
        pdf.addImage(LogoClea, 10, 10, 15, 15);

        // Title
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bolditalic');
        if ((i + 1) > 1) {
            pdf.setTextColor(80, 80, 80);
            pdf.text(t('clea.proof.file'), 200, 14, { align: 'right' });
        }

        // Page Number
        pdf.setTextColor(0);
        pdf.text(((i + 1) + ' / ' + numberOfPages), 105, 287, { align: 'center' });

        if ((i + 1) === numberOfPages) {

            // Conclusion
            pdf.setFontSize(11);
            pdf.setFont('helvetica', 'normal');
            pdf.text(t('these.competences') + '.', 10, 237);

            // Date
            pdf.text((t('done.the') + moment().format('DD/MM/YYYY') + '.'), 10, 242);

            // Signature
            pdf.text(t('signature'), 105, 252, { align: 'center' });
            pdf.setDrawColor(63, 81, 181);
            pdf.rect(55, 254, 100, 28);
        }
    };

    // Save PDF
    pdf.save(user.full_name + ' - ' + t('clea.proof.file') + '.pdf');
    setLoading(false);
};

export default GenerateCleaProofDocument;
