import React from 'react';
import { Route, Switch, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { List, ListItem, Divider } from '@material-ui/core';
import {
    EuroSymbol as IconBilling,
    Category as IconSectors,
    HowToRegRounded as IconSofts,
    Description as IconSkillbases,
    Build as IconTools,
    Equalizer as IconStatistics,
    AccountBalance as IconCompanies,
    SwapHorizontalCircle as IconAccess,
    Group as IconUsers,
    Loyalty as IconSoftTools,
    MenuBook as IconNews,
} from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { servicesColor } from 'services/helpers';

// Components
import Header from '../common/header';
import Billing from './billing';
import Statistics from './statistics';
import SectorsManagement from './sectors';
import SkillsBaseManagement from './skillbases';
import SoftskillsManagement from './softskills';
import CompaniesManagement from './companies';
import UsersManagement from './users';
import AccessRequests from './access-requests';
import HardToolsManagement from './hard-tools';
import SoftToolsManagement from './soft-tools';
import NewsManagement from './news';
import CleaManagement from './clea';
import CleaIcon from 'components/common/clea-icon';

// Style
import style from 'style';

// ---------------------------------------- MANAGEMENT ---------------------------------------- \\
const Management = () => {
    const match = useRouteMatch();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    return (
        <>
            <Header />
            <div className="main">
                <Switch>
                    <Route exact path={`${match.path}`}>
                        <ManagementMenu ROLE_ID={ROLE_ID} />
                    </Route>
                    {ROLE_ID === 1 && (
                        <Route path={`${match.path}/billing`}>
                            <Billing />
                        </Route>
                    )}
                    {ROLE_ID === 1 && (
                        <Route exact path={`${match.path}/sectors`}>
                            <SectorsManagement />
                        </Route>
                    )}
                    {ROLE_ID === 1 && (
                        <Route path={`${match.path}/skillbases`}>
                            <SkillsBaseManagement />
                        </Route>
                    )}
                    {ROLE_ID === 1 && (
                        <Route path={`${match.path}/softskills`}>
                            <SoftskillsManagement />
                        </Route>
                    )}
                    {ROLE_ID === 1 && (
                        <Route exact path={`${match.path}/tools`}>
                            <HardToolsManagement />
                        </Route>
                    )}
                    {ROLE_ID === 1 && (
                        <Route exact path={`${match.path}/soft-tools`}>
                            <SoftToolsManagement />
                        </Route>
                    )}
                    {ROLE_ID === 1 && (
                        <Route exact path={`${match.path}/clea`}>
                            <CleaManagement />
                        </Route>
                    )}
                    {ROLE_ID < 5 && (
                        <Route path={`${match.path}/statistics`}>
                            <Statistics />
                        </Route>
                    )}
                    {ROLE_ID <= 2 && (
                        <Route path={`${match.path}/companies`}>
                            <CompaniesManagement />
                        </Route>
                    )}
                    <Route exact path={`${match.path}/access-requests`}>
                        <AccessRequests />
                    </Route>
                    <Route exact path={`${match.path}/users`}>
                        <UsersManagement />
                    </Route>
                    {ROLE_ID === 1 && (
                        <Route path={`${match.path}/news`}>
                            <NewsManagement />
                        </Route>
                    )}
                    <Redirect to="/management" />
                </Switch>
            </div>
        </>
    );
};

// -------------------------------------- MANAGEMENT MENU ------------------------------------- \\
const ManagementMenu = ({ ROLE_ID }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="simple-container" style={{ marginTop: 48, marginBottom: 24 }}>
            <div style={{ ...style.taC, ...style.p10, backgroundColor: servicesColor }}>
                <span style={{ ...style.cW, ...style.fs16 }}>{t('management')}</span>
            </div>
            <div style={{ ...style.w100, backgroundColor: 'theme.palette.background.paper' }}>
                <List component="nav" style={style.p0}>
                    {ROLE_ID === 1 && (
                        <>
                            <ListItem button onClick={() => history.push(`${match.url}/billing`)} style={style.p15_25}>
                                <IconBilling />
                                <span style={style.ml15}>{t('billing')}</span>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => history.push(`${match.url}/sectors`)} style={style.p15_25}>
                                <IconSectors />
                                <span style={style.ml15}>{t('management.sectors')}</span>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => history.push(`${match.url}/skillbases`)} style={style.p15_25}>
                                <IconSkillbases />
                                <span style={style.ml15}>{t('management.skillbases')}</span>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => history.push(`${match.url}/softskills`)} style={style.p15_25}>
                                <IconSofts />
                                <span style={style.ml15}>{t('softskills.management')}</span>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => history.push(`${match.url}/tools`)} style={style.p15_25}>
                                <IconTools />                                
								<span style={style.ml15}>{t('management.hard.tools')}</span>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => history.push(`${match.url}/soft-tools`)} style={style.p15_25}>
                                <IconSoftTools />
                                <span style={style.ml15}>{t('management.soft.tools')}</span>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => history.push(`${match.url}/clea`)} style={style.p15_25}>
                                <CleaIcon style={{ fontSize: 24 }} />
                                <span style={style.ml15}>{t('management.clea')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                    {ROLE_ID < 5 && (
                        <>
                            <ListItem button onClick={() => history.push(`${match.url}/statistics`)} style={style.p15_25}>
                                <IconStatistics />
                                <span style={style.ml15}>{t('statistics')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                    {ROLE_ID <= 2 && (
                        <>
                            <ListItem button onClick={() => history.push(`${match.url}/companies`)} style={style.p15_25}>
                                <IconCompanies />
                                <span style={style.ml15}>{t('management.companies')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                    <ListItem button onClick={() => history.push(`${match.url}/access-requests`)} style={style.p15_25}>
                        <IconAccess />
                        <span style={style.ml15}>{t('management.access.requests')}</span>
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => history.push(`${match.url}/users`)} style={style.p15_25}>
                        <IconUsers />
                        <span style={style.ml15}>{t('management.users')}</span>
                    </ListItem>
                    <Divider />
                    {ROLE_ID === 1 && (
                        <>
                            <ListItem button onClick={() => history.push(`${match.url}/news`)} style={style.p15_25}>
                                <IconNews />
                                <span style={style.ml15}>{t('news.management')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </List>
            </div>
        </div>
    );
};

export default Management;
