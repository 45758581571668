import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Accordion, AccordionSummary, Grid, Divider, IconButton, Fab } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd, ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { softskillsColor, getTranslation } from 'services/helpers';

// Components
import CreateSoftUnit from './create-softunit';
import UpdateSoftUnit from './update-softunit';
import DeleteSoftUnit from './delete-softunit';
import SpecificBehaviors from './specific-behaviors';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// --------------------------------------- SOFT UNITS ---------------------------------------- \\
const SoftUnits = ({ unit }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        soft_units: unit.soft_units,
        soft_unit: null,
        openCreateSoftUnit: false,
        openUpdateSoftUnit: false,
        openDeleteSoftUnit: false,
    });

    function handleUpdateSoftUnit(event, soft_unit) {
        event.stopPropagation();
        setState({ ...state, openUpdateSoftUnit: true, soft_unit });
    }

    function handleDeleteSoftUnit(event, soft_unit) {
        event.stopPropagation();
        setState({ ...state, openDeleteSoftUnit: true, soft_unit });
    }

    function handleRefetchCreateSoftUnit(soft_unit) {
        const soft_units = state.soft_units;
        soft_units.push(soft_unit);
        setState({ ...state, openCreateSoftUnit: false, soft_units });
    }

    function handleRefetchUpdateSoftUnit(soft_unit) {
        const soft_units = state.soft_units;
        const index = soft_units.findIndex(su => su.id === soft_unit.id);
        soft_units.splice(index, 1, soft_unit);
        setState({ ...state, openUpdateSoftUnit: false, soft_units });
    }

    function handleRefetchDeleteSoftUnit(soft_unit_id) {
        const soft_units = state.soft_units;
        const index = soft_units.findIndex(su => su.id === soft_unit_id);
        soft_units.splice(index, 1);
        setState({ ...state, openDeleteSoftUnit: false, soft_units });
    }

    return (
        <>
            {/* ---------------------- SOFT SKILL HEADER ---------------------- */}
            <Grid container style={{ backgroundColor: softskillsColor, ...style.mt25, ...style.p10 }}>
                <span style={style.cW}>{t('softskills')}</span>
            </Grid>
            {/* ---------------------- SOFT SKILL LIST ---------------------- */}
            <div>
                {state.soft_units.length > 0 ? (
                    state.soft_units.map(soft_unit=> (
                        <Accordion key={soft_unit.id}>
                            <AccordionSummary
                                expandIcon={<IconExpand />}
                                classes={{ content: 'panel-summary' }}
                                style={style.p0_10}
                            >
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={8}>
                                        <span>{getTranslation('name', soft_unit.softskill)}</span>
                                    </Grid>
                                    <Grid item xs={12} md={2} container justifyContent="center">
                                        <span>{t('level')} {soft_unit.soft_level.level}</span>
                                    </Grid>
                                    <Grid item xs={12} md={2} container justifyContent="center">
                                        <Tooltip title={t('edit')}
                                            item={(
                                                <IconButton
                                                    className="list-btn"
                                                    onClick={e => handleUpdateSoftUnit(e, soft_unit)}
                                                >
                                                    <IconEdit />
                                                </IconButton>
                                            )}
                                        />
                                        {!soft_unit.has_evaluations && (
                                            <Tooltip title={t('delete')}
                                                item={(
                                                    <IconButton
                                                        className="list-btn"
                                                        onClick={e => handleDeleteSoftUnit(e, soft_unit)}
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                )}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <div style={style.p24}>
                                <SpecificBehaviors soft_unit={soft_unit} />
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateSoftUnit: true })}
                    className="fab"
                    style={{ backgroundColor: softskillsColor, color:"white" }}
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('softskill.link')}
                </span>
            </div>
            {/* -------------------- CREATE SOFT UNIT ----------------------- */}
            {state.openCreateSoftUnit && (
                <CreateSoftUnit
                    onClose={() => setState({ ...state, openCreateSoftUnit: false })}
                    refetch={soft_unit => handleRefetchCreateSoftUnit(soft_unit)}
                    unit={unit}
                    soft_units={state.soft_units}
                />
            )}
            {/* -------------------- UPDATE SOFT UNIT ----------------------- */}
            {state.openUpdateSoftUnit && (
                <UpdateSoftUnit
                    onClose={() => setState({ ...state, openUpdateSoftUnit: false })}
                    refetch={soft_unit => handleRefetchUpdateSoftUnit(soft_unit)}
                    soft_unit={state.soft_unit}
                />
            )}
            {/* -------------------- DELETE SOFT UNIT ----------------------- */}
            {state.openDeleteSoftUnit && (
                <DeleteSoftUnit
                    onClose={() => setState({ ...state, openDeleteSoftUnit: false })}
                    refetch={soft_unit_id => handleRefetchDeleteSoftUnit(soft_unit_id)}
                    soft_unit={state.soft_unit}
                />
            )}
        </>
    );
};

export default SoftUnits;
