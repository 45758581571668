import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Eject as UploadIcon } from '@material-ui/icons';

// Style
import style from 'style';

// ---------------------------------------- INPUT FILE ---------------------------------------- \\
const InputFile = ({ inputId }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [fileName, setFileName] = useState(undefined);

    const onChange = event => {
        // Max size file : 10 Mo
        if (document.getElementById('upload-' + inputId).files[0].size >= 10000000) {
            enqueueSnackbar(t('size.max.file'), { variant: 'warning' });
        }
        else {
            const fileName = event.target.value;
            setFileName(fileName.replace(/^.*[\\/]/, ''));
        }
    };

    return (
        <div className="div-input-file">
            <div style={{ minWidth: 160 }}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={'upload-' + inputId} className="label-input-file">
                    <b className='input-title'>{t('select')}</b>
                    <UploadIcon style={{ color: 'rgba(80, 80, 80, 1)', fontSize: 20, marginBottom: 4 }} />
                </label>
                <input
                    id={'upload-' + inputId}
                    type="file"
                    style={style.dN}
                    onChange={e => onChange(e)}
                />
            </div>
            <div style={{ paddingLeft: 5, wordBreak: 'break-word' }}>
                <span style={{ fontSize: 12 }}>{fileName}</span>
            </div>
        </div>
    );
};

export default InputFile;
