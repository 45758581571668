import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { IconButton, Divider, Button, Grid, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';

// Services
import { isValidEmail, mobipassColor, mobitrainColor, mobitoolsColor } from 'services/helpers';
import { getUser, editUser, editUserPhoto } from 'services/requests';
import { getStoredUser } from 'services/storage';

// Components
import SelectCountry from 'components/select/select-country';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFile from 'components/items/input-file';
import InputText from 'components/items/input-text';
import DatePicker from 'components/items/date-picker';
import Tooltip from 'components/items/tooltip';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------------- SETTINGS ----------------------------------------- \\
const Settings = () => {
    const location = useLocation();
    const match = useRouteMatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();
    const USER_ID = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        loading: true,
        error: undefined,
        color: mobipassColor,
        photo: null,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        company: '',
        address: '',
        zipCode: '',
        city: '',
        countryId: null,
        phone: '',
        birthdate: null,
        loadingUpdate: false,
        loadingPhoto: false,
        openPasswordModal: false,
    });

    const onUpdateUser = () => {
        if (state.firstName === '' || state.lastName === '' || state.email === '') {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (!isValidEmail(state.email)) {
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-user-photo').files[0];

            if (file) {
                let formData = new FormData();
                formData.append('photo', file);
                setState({ ...state, loadingUpdate: true, photo: formData });
            }
            else {
                setState({ ...state, loadingUpdate: true });
            }
        }
    };

    // Handle BackgroundColor Header
    useEffect(() => {
        if (location) {
           const path = location.pathname.split('/')[1];
           if (path === 'mobipass') {
               setState(state => ({ ...state, color: mobipassColor }));
           }
           else if (path === 'mobitrain') {
                setState(state => ({ ...state, color: mobitrainColor }));
           }
           else {
                setState(state => ({ ...state, color: mobitoolsColor }));
           }
        }
    }, [location]);

    // Get logged user
    useEffect(() => {
        if (state.loading) {
            getUser(USER_ID).then(res => {
                if (res?.status === 200) {
                    const user = res.data.data.user;
                    setState(state => ({
                        ...state,
                        loading: false,
                        firstName: user.first_name,
                        lastName: user.last_name,
                        email: user.email || '',
                        role: user.role.name,
                        company: user.company ? user.company.name : '',
                        address: user.address || '',
                        zipCode: user.zip_code || '',
                        city: user.city || '',
                        countryId: user.country ? user.country.id : null,
                        phone: user.phone || '',
                        birthdate: user.birthdate ? moment(user.birthdate, 'YYYY-MM-DD') : null,
                        informations: user.informations || '',
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    // Update logged user
    useEffect(() => {
        if (state.loadingUpdate) {
            editUser(USER_ID, {
                first_name: state.firstName,
                last_name: state.lastName,
                email: state.email,
                address: state.address,
                zip_code: state.zipCode,
                city: state.city,
                country_id: state.countryId,
                phone: state.phone,
                birthdate: state.birthdate ? moment(state.birthdate).format('YYYY-MM-DD') : null,
                informations: state.informations,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    if (state.photo) {
                        setState({ ...state, loadingUpdate: false, loadingPhoto: true });
                    }
                    else {
                        setState({ ...state, loadingUpdate: false, loading: true });
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingUpdate: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingUpdate]);

    // Update photo of logged user
    useEffect(() => {
        if (state.loadingPhoto) {
            editUserPhoto(USER_ID, state.photo).then(res => {
                if (res?.status === 200) {
                    window.location.reload();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingPhoto: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingPhoto]);

    if (state.loading || state.loadingUpdate || state.loadingPhoto) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <BreadcrumbsItem className="breadcrumb-item" to={`${match.path}`}>
                {t('settings')}
            </BreadcrumbsItem>
            <div className="full-container" style={style.mt25}>
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" style={{ backgroundColor: state.color }}>
                    <Grid item xs={12} md={6} style={style.p10}>
                        <span style={style.cW}>{t('profile')}</span>
                    </Grid>
                    <Grid item xs={12} md={6} style={style.p10}>
                        <span style={style.cW}>{t('details')}</span>
                    </Grid>
                </Grid>
                <div className="full-container-content">
                    {/* -------------------- PHOTO -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('picture')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputFile inputId="user-photo" />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- FIRST NAME -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('first.name')}</span><FieldRequired />
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('first.name')}
                                value={state.firstName}
                                onChange={e => setState({ ...state, firstName: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />     
                    {/* -------------------- LAST NAME -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('last.name')}</span><FieldRequired />
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('last.name')}
                                value={state.lastName}
                                onChange={e => setState({ ...state, lastName: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- EMAIL -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('email')}</span><FieldRequired />
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('email')}
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- ROLE -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('role')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span>{state.role}</span>
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- COMPANY -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('company')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span>{state.company}</span>
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- PASSWORD -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('password')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <Tooltip title={t('password.update')}
                                item={
                                    <IconButton onClick={() => setState({ ...state, openPasswordModal: true })}>
                                        <EditIcon />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- ADDRESS -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('address')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('address')}
                                value={state.address}
                                onChange={e => setState({ ...state, address: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- ZIP CODE -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('zip.code')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('zip.code')}
                                value={state.zipCode}
                                onChange={e => setState({ ...state, zipCode: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- CITY -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('city')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('city')}
                                value={state.city}
                                onChange={e => setState({ ...state, city: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- COUNTRY -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('country')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <SelectCountry
                                countryId={state.countryId}
                                setCountryId={countryId => setState({ ...state, countryId })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- PHONE -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('phone')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('phone')}
                                value={state.phone}
                                onChange={e => setState({ ...state, phone: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- BIRTHDATE -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('birthdate')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <DatePicker
                                placeholder={t('birthdate')}
                                outlined
                                value={state.birthdate}
                                onChange={birthdate => setState({ ...state, birthdate })}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    {/* -------------------- INFORMATIONS -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} style={style.p10}>
                            <span style={style.fwB}>{t('informations.further')}</span>
                        </Grid>
                        <Grid item xs={12} md={6} style={style.p10}>
                            <InputText
                                placeholder={t('informations.further')}
                                value={state.informations}
                                onChange={e => setState({ ...state, informations: e.target.value })}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                {/* -------------------- SAVE BUTTON -------------------- */}
                <Grid container alignItems="center" justifyContent="center" style={style.p15}>
                    <Button onClick={onUpdateUser} variant="contained">
                        <span>{t('save')}</span>
                    </Button>
                </Grid>
            </div>
            {/* -------------------- PASSWORD MODAL -------------------- */}
            {state.openPasswordModal && (
                <PasswordModal
                    onClose={() => setState({ ...state, openPasswordModal: false })}
                    userId={USER_ID}
                />
            )}
        </>
    );
};

// ------------------------------------- PASSWORD MODAL -------------------------------------- \\
const PasswordModal = ({ onClose, userId }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        old_password: '',
        new_password: '',
        confirm_password: '',
    });

    const onUpdatePassword = () => {
        if (state.old_password === '' || state.new_password === '' || state.confirm_password === '') {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.new_password !== state.confirm_password) {
            enqueueSnackbar(t('password.not.match'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            editUser(userId, {
                old_password: state.old_password,
                new_password: state.new_password,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    onClose();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('password.update')}
                        </p>
                        {/* -------------------- OLD PASSWORD -------------------- */}
                        <div>
                            <b className="input-title">{t('password.old')}</b><FieldRequired />
                            <InputText
                                placeholder={t('password.old')}
                                value={state.old_password}
                                onChange={e => setState({ ...state, old_password: e.target.value })}
                                type="password"
                            />
                        </div>
                        {/* -------------------- NEW PASSWORD -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('password.new')}</b><FieldRequired />
                            <InputText
                                placeholder={t('password.new')}
                                value={state.new_password}
                                onChange={e => setState({ ...state, new_password: e.target.value })}
                                type="password"
                            />
                        </div>
                        {/* -------------------- CONFIRM PASSWORD -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('password.confirm')}</b><FieldRequired />
                            <InputText
                                placeholder={t('password.confirm')}
                                value={state.confirm_password}
                                onChange={e => setState({ ...state, confirm_password: e.target.value })}
                                type="password"
                            />
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={onUpdatePassword} variant="contained" color="primary">
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Settings;
