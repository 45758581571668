import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services 
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import InputText from 'components/items/input-text';
import DatePicker from 'components/items/date-picker';

// --------------------------------------- EVALUATION FORM PAGE 1 ---------------------------------------- \\
const EvaluationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage2 = () => {
        if (state.name === '' || state.evaluation_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- NAME -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('evaluation.name')}<FieldRequired />
                            </b>
                            <InputText
                                placeholder={t('evaluation.name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                            />
                        </div>
                        {/* -------------------- DATE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('evaluation.date')}<FieldRequired />
                            </b>
                            <DatePicker
                                placeholder={t('evaluation.date')}
                                value={state.evaluation_date}
                                onChange={evaluation_date => setState({ ...state, evaluation_date })}
                                outlined
                            />
                        </div>
                        {/* -------------------- DETAILS -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('details')}
                            </b>
                            <InputText
                                placeholder={t('details')}
                                value={state.details}
                                onChange={e => setState({ ...state, details: e.target.value })}
                                multiline
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 3</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default EvaluationFormPage1;
