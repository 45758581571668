import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Fab, Dialog, DialogContent, DialogActions, Divider, Button, IconButton } from '@material-ui/core';
import { Add as IconAdd, Delete as IconDelete } from '@material-ui/icons';

// Services
import { createBehavior, deleteBehavior, updateBehavior } from 'services/requests';
import { servicesColor, initialLanguagesObject, getTranslation } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    tdLevel: {
        border: '1px solid lightgray',
        wordBreak: 'break-word',
        width: '25%',
        maxWidth: '25%',
    },
    tdAdd: {
        textAlign: 'center',
        width: '25%',
        maxWidth: '25%',
        padding: 20,
    },
    case: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    p: {
        overflow: 'auto',
        height: 84,
        marginBottom: 0,
    },
};

// ------------------------------------- BEHAVIORS ------------------------------------- \\
export const Behaviors = ({ loading, error, softskill, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        index: undefined,
        behavior: undefined,
        tr_array: [],
        openCreateBehavior: false,
        openUpdateBehavior: false,
        openDeleteBehavior: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    function handleDelete(event, behavior) {
        event.stopPropagation();
        setState({ ...state, openDeleteBehavior: true, behavior });
    }

    useEffect(() => {
        if (softskill) {
            let tr_number = 0;
            for (let i = 0; i < 4; i += 1) {
                const behaviors_number = softskill.soft_levels[i].behaviors.length
                if (tr_number < behaviors_number) {
                    tr_number = behaviors_number;
                }
            }
            setState({ ...state, tr_array: Array.apply(null, { length: tr_number }).map(Number.call, Number) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softskill]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div style={{ minWidth: 900 }}>
                {/* ---------------------- BEHAVIORS ---------------------- */}
                <Grid container justifyContent="center" style={{ backgroundColor: servicesColor, ...style.p10 }}>
                    <span style={style.cW}>{t('observable.behavior')}</span>
                </Grid>
                <table style={style.w100}>
                    <tbody>
                        {state.tr_array.map(tr => (
                            <tr key={tr}>
                                {[0, 1, 2, 3].map(index => {
                                    const behavior = softskill.soft_levels[index].behaviors[tr];
                                    return (
                                        <td key={index} style={styles.tdLevel}>
                                            {behavior && (
                                                <div
                                                    onClick={() => setState({ ...state, openUpdateBehavior: true, index, behavior })}
                                                    style={styles.case}
                                                    className="list-item-hover"
                                                >
                                                    <div style={style.f1}>
                                                        <p style={styles.p}>
                                                            {behavior.position}. {getTranslation('definition', behavior)}
                                                        </p>
                                                    </div>
                                                    <div style={style.taR}>
                                                        <Tooltip title={t('delete')}
                                                            item={(
                                                                <IconButton
                                                                    onClick={e => handleDelete(e, behavior)}
                                                                    style={style.p6}
                                                                >
                                                                    <IconDelete />
                                                                </IconButton>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                        <tr>
                            {[0, 1, 2, 3].map(index => (
                                <td key={index} style={styles.tdAdd}>
                                    <Fab onClick={() => setState({ ...state, openCreateBehavior: true, index })}>
                                        <IconAdd />
                                    </Fab>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* ---------------------- CREATE BEHAVIOR ---------------------- */}
            {state.openCreateBehavior && (
                <CreateBehavior
                    onClose={() => setState({ ...state, openCreateBehavior: false })}
                    refetch={() => handleRefetch('openCreateBehavior')}
                    softskill={softskill}
                    index={state.index}
                />
            )}
            {/* ---------------------- UPDATE BEHAVIOR ---------------------- */}
            {state.openUpdateBehavior && (
                <UpdateBehavior
                    onClose={() => setState({ ...state, openUpdateBehavior: false })}
                    refetch={() => handleRefetch('openUpdateBehavior')}
                    behavior={state.behavior}
                    index={state.index}
                />
            )}
            {/* ---------------------- DELETE BEHAVIOR ---------------------- */}
            {state.openDeleteBehavior && (
                <DeleteBehavior
                    onClose={() => setState({ ...state, openDeleteBehavior: false })}
                    refetch={() => handleRefetch('openDeleteBehavior')}
                    behavior={state.behavior}
                />
            )}
        </>
    );
};

// ------------------------------------- CREATE BEHAVIOR ------------------------------------- \\
const CreateBehavior = ({ onClose, refetch, softskill, index }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        definition: initialLanguagesObject(),
    });

    function handleDefinition(event, code) {
        let definition = state.definition;
        definition[code] = event.target.value;
        setState({ ...state, definition });
    }

    const onCreate = () => {
        if (Object.values(state.definition).every(value => value === '')) {
            enqueueSnackbar(t('definition.enter'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true })
        }
    };

    useEffect(() => {
        if (state.loading) {
            createBehavior({ soft_level_id: softskill.soft_levels[index].id, definition: state.definition }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('observable.behavior.add')}
                </p>
                <Divider style={style.m15_0} />
                <p style={{ ...style.taC, ...style.fs16 }}>
                    {index === 0 && t('remember.and.understand')}
                    {index === 1 && t('apply')}
                    {index === 2 && t('analyse.and.evaluate')}
                    {index === 3 && t('create')}
                </p>
                {languages.map(language => (
                    <InputTextGrid
                        key={language.id}
                        placeholder={t(language.label)}
                        value={state.definition[language.code]}
                        onChange={(e) => handleDefinition(e, language.code)}
                        multiline
                    />
                ))}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    {t('cancel')}
                </Button>
                <Button onClick={onCreate} variant="contained">
                    {t('validate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ------------------------------------- UPDATE BEHAVIOR ------------------------------------- \\
const UpdateBehavior = ({ onClose, refetch, behavior, index }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        definition: initialLanguagesObject(),
        position: behavior.position,
    });

    function handleDefinition(event, code) {
        let definition = state.definition;
        definition[code] = event.target.value;
        setState({ ...state, definition });
    }

    const onUpdate = () => {
        if (Object.values(state.definition).every(value => value === '')) {
            enqueueSnackbar(t('definition.enter'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true })
        }
    };

    useEffect(() => {
        if (behavior) {
            let definition = state.definition;
            Object.entries(behavior.translations).map(([key, value]) => {
                return definition[key] = value.definition || '';
            });
            setState({ ...state, definition });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [behavior]);

    useEffect(() => {
        if (state.loading) {
            updateBehavior(behavior.id, { position: state.position, definition: state.definition }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('observable.behavior.edit')}
                </p>
                <Divider style={style.m15_0} />
                <p style={{ ...style.taC, ...style.fs16 }}>
                    {index === 0 && t('remember.and.understand')}
                    {index === 1 && t('apply')}
                    {index === 2 && t('analyse.and.evaluate')}
                    {index === 3 && t('create')}
                </p>
                {/* -------------------- POSITION -------------------- */}
                <InputTextGrid
                    placeholder={t('position')}
                    value={state.position}
                    onChange={e => setState({ ...state, position: e.target.value })}
                    type="number"
                />
                <Divider style={style.m15_0} />
                {languages.map(language => (
                    <InputTextGrid
                        key={language.id}
                        placeholder={t(language.label)}
                        value={state.definition[language.code]}
                        onChange={(e) => handleDefinition(e, language.code)}
                        multiline
                    />
                ))}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    {t('cancel')}
                </Button>
                <Button onClick={onUpdate} variant="contained">
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ------------------------------------- DELETE BEHAVIOR ------------------------------------- \\
const DeleteBehavior = ({ onClose, refetch, behavior }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteBehavior(behavior.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }   
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={{ ...style.pb20, ...style.taC }}>
                <p className="dialog-title">
                    {t('observable.behavior.delete')}
                </p>
                <span>{t('observable.behavior.delete.confirm')}</span>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    {t('no')}
                </Button>
                <Button onClick={() => setLoading(true)} variant="contained">
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Behaviors;
