import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Material-UI
import { List, ListItem, Divider } from '@material-ui/core';
import {
    Description as IconSkillbases,
    HowToRegRounded as IconSofts,
    CheckCircle as IconEvaluations,
    School as IconCertification,
    Style as IconPortfolio,
    Search as IconSearch,
    Ballot as IconPositioning,
} from '@material-ui/icons';

// Services
import { getStoredUser, getSoftskillsAccess, getCleaAccess } from 'services/storage';
import { mobipassColor, softskillsColor, cleaColor } from 'services/helpers';

// Components
import Profile from 'components/common/profile';
import CleaIcon from 'components/common/clea-icon';

// Style
import style from 'style';

// ---------------------------------------- SIDE MENU ---------------------------------------- \\
const SideMenu = ({ position }) => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const ROLE_ID = getStoredUser()?.role?.id;
    const [path, setPath] = useState('skillbases');

    useEffect(() => {
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    return (
        <div className="side-menu" style={{ position }}>
            <div className="profile">
                <Profile />
            </div>
            <Divider />
            <div className="menu">
                <List component="nav" style={style.p0}>
                    {/* -------------------- SKILLBASES -------------------- */}
                    <ListItem 
                        button
                        selected={path === 'skillbases'} 
                        onClick={() => history.push('/mobipass/skillbases')} 
                        style={style.p12_16}
                    >
                        <IconSkillbases style={{ color: mobipassColor }} />
                        <span className="side-menu-title">{t('skillbases.job')}</span>
                    </ListItem>
                    {/* -------------------- POSITIONING -------------------- */}
                    <ListItem 
                        button
                        selected={path === 'positioning'} 
                        onClick={() => history.push('/mobipass/positioning')} 
                        style={style.p12_16}
                    >
                        <IconPositioning style={{ color: mobipassColor }} />
                        <span className="side-menu-title">{t('positioning')}</span>
                    </ListItem>
                    {/* -------------------- EVALUATION -------------------- */}
                    <ListItem 
                        button
                        selected={path === 'evaluation'} 
                        onClick={() => history.push('/mobipass/evaluation')} 
                        style={style.p12_16}
                    >
                        <IconEvaluations style={{ color: mobipassColor }} />
                        <span className="side-menu-title">{t('evaluation')}</span>
                    </ListItem>
                    {/* -------------------- CERTIFICATION -------------------- */}
                    <ListItem 
                        button
                        selected={path === 'certification'} 
                        onClick={() => history.push('/mobipass/certification')} 
                        style={style.p12_16}
                    >
                        <IconCertification style={{ color: mobipassColor }} />
                        <span className="side-menu-title">{t('certification')}</span>
                    </ListItem>
                    {/* -------------------- PORTFOLIO -------------------- */}
                    <ListItem 
                        button
                        selected={path === 'portfolio'} 
                        onClick={() => history.push('/mobipass/portfolio')} 
                        style={style.p12_16}
                    >
                        <IconPortfolio style={{ color: mobipassColor }} />
                        <span className="side-menu-title">{t('portfolio')}</span>
                    </ListItem>
                    {/* -------------------- RESEARCH -------------------- */}
                    {ROLE_ID < 5 && (
                        <ListItem 
                            button
                            selected={path === 'research'} 
                            onClick={() => history.push('/mobipass/research')} 
                            style={style.p12_16}
                        >
                            <IconSearch style={{ color: mobipassColor }} />
                            <span className="side-menu-title">{t('research')}</span>
                        </ListItem>
                    )}
                    {/* -------------------- SOFT SKILLS -------------------- */}
                    {getSoftskillsAccess() === '1' && (
                        <>
                            <Divider />
                            <ListItem
                                button
                                selected={path === 'softskills'} 
                                onClick={() => history.push('/mobipass/softskills')} 
                                style={style.p12_16}
                            >
                                <IconSofts style={{ color: softskillsColor }} />
                                <span className="side-menu-title">{t('softskills')}</span>
                            </ListItem>
                        </>
                    )}
                    {(getSoftskillsAccess() === '1' || getCleaAccess() === '1') && (
                        <Divider />
                    )}
                    {/* -------------------- CLEA -------------------- */}
                    {getCleaAccess() === '1' && (
                        <>
                            <ListItem 
                                button
                                selected={path === 'clea'} 
                                onClick={() => history.push('/mobipass/clea')} 
                                style={style.p12_16}
                            >
                                <CleaIcon style={{ fontSize: 24, color: cleaColor }} />
                                <span className="side-menu-title">{t('clea')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </List>
            </div>
        </div>
    );
};

export default SideMenu;
