import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Divider, Slider } from '@material-ui/core';

// Services
import { servicesColor } from 'services/helpers';
import { languages, percentages } from 'services/constants';

// Components
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ---------------------------------- CREATE CLEA SUB DOMAIN ---------------------------------- \\
const SubDomainForm = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleNames(event, code) {
        setState({
            ...state,
            names: Object.assign({}, state.names, { [code]: event.target.value })
        });
    };

    function handleChangeValueSlider(event, value) {
        if (value !== state.percentage_to_validate) {
            setState({ ...state, percentage_to_validate: value });
        }
    };

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <b className="input-title">{t('clea.pourcent.competence.to.validate')}</b>
            </div>
            <Grid container style={{ padding: '12px 24px' }}>
                <Slider
                    value={state.percentage_to_validate}
                    onChange={(event, value) => handleChangeValueSlider(event, value)}
                    marks={percentages}
                    valueLabelDisplay="auto"
                    step={null}
                    min={50}
                    max={100}
                    style={{ color: servicesColor }}
                />
            </Grid>
            <Divider style={style.m15_0} />
            <div style={style.taC}>
                <span style={style.fwB}>{t('clea.sub.domain.name')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.names[language.code]}
                    onChange={(e) => handleNames(e, language.code)}
                />
            ))}
        </>
    );
};

export default SubDomainForm;
