import React from 'react';

// Material-UI
import { SvgIcon } from '@material-ui/core';

// ------------------------------------- CLEA ICON ------------------------------------- \\
const CleaIcon = props => {
    return (
        <SvgIcon {...props}> 
            <path viewBox="0 0 800 800" d="M12.17,0h-3.6l1.26,2.74L0,22.98l3.66-0.02c0,0,1.56-3.26,5.5-3.26c4.63,0,8.78,0,8.78,0l1.44,3.27l3.8-0.01
            L12.17,0z M10.34,16.28c-2.78,0-3.62,0.8-3.62,0.8l4.7-10.17l4.91,9.37C16.34,16.28,12.74,16.28,10.34,16.28z"/>
        </SvgIcon>
    );
};

export default CleaIcon;
