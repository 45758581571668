import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Switch, Route } from 'react-router-dom';

// Material-UI
import { Button, Grid, IconButton, Divider } from '@material-ui/core';
import { Search as IconSearch, Cancel as IconCancel, Delete as IconDelete, Create as IconEdit, Description as IconSkillbase, Reply as ExportIcon } from '@material-ui/icons';

// Services
import { getCompanies } from 'services/requests';
import { servicesColor, fileUrl } from 'services/helpers';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import CreateCompany from './company-create';
import UpdateCompany from './company-update';
import CompanyExport from './company-export';
import UsersListPerMonthExport from './users-list-per-month-export';
import { DeleteCompany, SkillbasesModal } from './company-modals';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectSkillbase from 'components/select/select-skillbase';

// Style
import style from 'style';

// -------------------------------------- COMPANIES ROUTER ------------------------------------------ \\
const CompaniesRouter = () => {
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    return (
        <Switch>
            <Route exact path="/management/companies">
                <CompaniesManagement />
            </Route>
            {ROLE_ID === 1 && (
                <Route exact path="/management/companies/create">
                    <CreateCompany />
                </Route>
            )}
            <Route exact path="/management/companies/update/:companyId">
                <UpdateCompany />
            </Route>
        </Switch>
    );
};

// ------------------------------------- COMPANIES MANAGEMENT --------------------------------------- \\
const CompaniesManagement = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: '',
        address: '',
        email: '',
        skillbase_id: null,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            name: '',
            address: '',
            email: '',
            skillbase_id: null,
        });
    };

    useEffect(() => {
        if (state.loading) {
            getCompanies(
                state.offset,
                state.limit,
                state.name,
                state.address,
                state.email,
                state.skillbase_id,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data.companies,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('companies')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid container justifyContent={mediaMD ? 'flex-start' : 'center'} item xs={12} md={4}>
                    <Button onClick={() => history.push('/management')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid container justifyContent="center" item xs={12} md={4} style={mediaMD ? null : style.m10}>
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid container justifyContent={mediaMD ? 'flex-end' : 'center'} item xs={12} md={4}>
                    {ROLE_ID === 1 && (
                        <Button onClick={() => history.push('/management/companies/create')} variant="contained">
                            {t('add')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>Logo</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('address')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('email')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('companies')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} />
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('address')}
                                value={state.address}
                                onChange={e => setState({ ...state, address: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('email')}
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <CompanyExport data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- COMPANY LIST -------------------- */}
                <CompanyList
                    ROLE_ID={ROLE_ID}
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
        </>
    );
};

// ----------------------------------------- COMPANY LIST ------------------------------------------- \\
const CompanyList = ({ ROLE_ID, data, loading, error, refetch }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const [state, setState] = useState({
        company: null,
        openDelete: false,
        openSkillbasesModal: false,
        openUsersListExport: false,
    });

    const onRefetch = () => {
        setState({ ...state, openDelete: false });
        refetch();
    };

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {data.length > 0 ? (
                    data.map(company => (
                        <Fragment key={company.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {company.photo && (
                                        <img alt="Company Logo" src={fileUrl + company.photo} style={{ height: 30, maxWidth: '100%' }} />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{company.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{company.address} {company.zip_code} {company.city} <b>{company.country?.name}</b></span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{company.email}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <Button
                                        onClick={() => setState({ ...state, openSkillbasesModal: true, company })}
                                        variant="contained"
                                        style={{ textTransform: 'none' }}
                                    >
                                        {ROLE_ID === 1 ? t('skillbases.handle') : t('skillbases.see')}
                                        <IconSkillbase style={{ marginLeft: 5, fontSize: 18 }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('users.list.per.month')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openUsersListExport: true, company })}
                                                className="list-btn"
                                            >
                                                <ExportIcon style={{ transform: 'rotateY(180deg)' }} />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                className="list-btn"
                                                onClick={() => history.push('/management/companies/update/' + company.id)}
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    {(ROLE_ID === 1 && company.evaluations_count === 0 && company.users_count === 0) && (
                                        <Tooltip title={t('delete')}
                                            item={
                                                <IconButton
                                                    className="list-btn"
                                                    onClick={() => setState({ ...state, openDelete: true, company })}
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {state.openSkillbasesModal && (
                <SkillbasesModal
                    onClose={() => setState({ ...state, openSkillbasesModal: false })}
                    company={state.company}
                />
            )}
            {state.openUsersListExport && (
                <UsersListPerMonthExport
                    onClose={() => setState({ ...state, openUsersListExport: false })}
                    company={state.company}
                />
            )}
            {(state.openDelete && ROLE_ID === 1 && state.company.evaluations_count === 0 && state.company.users_count === 0) && (
                <DeleteCompany
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={onRefetch}
                    company={state.company}
                />
            )}
        </>
    );
};

export default CompaniesRouter;
