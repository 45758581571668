import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Grid, Divider, IconButton } from '@material-ui/core';
import {
    Search as IconSearch, Cancel as IconCancel, Delete as IconDelete,
    Create as IconEdit, Assignment as IconDetails, Loupe as IconMore,
    InsertDriveFile as IconDocument,
} from '@material-ui/icons';

// Services
import { getCleaFormations } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { getTranslation, cleaColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateCleaFormation from './clea-formation-create';
import UpdateCleaFormation from './clea-formation-update';
import DeleteCleaFormation from './clea-formation-delete';
import CleaFormationsExport from './clea-formation-export';
import CleaProof from './clea-proof/clea-proof';
import { DetailsCleaFormation, CleaLearnersModal, CleaTrainersModal } from './clea-formation-modals';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';

// Style
import style from 'style';

// ----------------------------------- CLEA FORMATION ROUTER --------------------------------- \\
const CleaFormationRouter = () => {
    return (
        <Switch>
            <Route exact path="/mobitrain/clea-formation">
                <CleaFormation />
            </Route>
            <Route exact path="/mobitrain/clea-formation/create">
                <CreateCleaFormation />
            </Route>
            <Route exact path="/mobitrain/clea-formation/update/:cleaFormationId">
                <UpdateCleaFormation />
            </Route>
            <Route exact path="/mobitrain/clea-formation/proof/:cleaFormationId">
                <CleaProof />
            </Route>
            <Redirect from="*" to="/mobitrain/clea-formation" />
        </Switch>
    )
};

// -------------------------------------- CLEA FORMATION ------------------------------------- \\
const CleaFormation = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('clea-formations-filters'));
    const ROLE_ID = getStoredUser()?.role?.id;

    const [state, setState] = useState({
        loading: true,
        error: false,
        clea_formations: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: storedFilters?.name || '',
        clea_formation_date: storedFilters?.clea_formation_date || null,
        company_id: storedFilters?.company_id || null,
        trainer: storedFilters?.trainer || '',
        learner: storedFilters?.learner || '',
        openCreateCleaFormation: false,
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, error: false, offset: 0 });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, error: false, offset: 0, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('clea-formations-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            name: '',
            clea_formation_date: null,
            company_id: null,
            trainer: '',
            learner: '',
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('clea-formations-filters', JSON.stringify({
                name: state.name,
                clea_formation_date: state.clea_formation_date,
                company_id: state.company_id,
                trainer: state.trainer,
                learner: state.learner,
            }));

            getCleaFormations({
                offset: state.offset,
                limit: state.limit,
                name: state.name,
                formation_date: state.clea_formation_date ? moment(state.clea_formation_date, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
                company_id: state.company_id,
                trainer: state.trainer,
                learner: state.learner,
            }).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        clea_formations: res.data.data.clea_formations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to="/mobitrain/clea-formation" className="breadcrumb-item">
                {t('clea.training')}
            </BreadcrumbsItem>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push('/mobitrain/clea-formation/create')}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: cleaColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('trainer')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('clea.training')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('date')}
                                value={state.clea_formation_date}
                                onChange={clea_formation_date => onSelectSearch('clea_formation_date', clea_formation_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('trainer')}
                                value={state.trainer}
                                onChange={e => setState({ ...state, trainer: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner}
                                onChange={e => setState({ ...state, learner: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <CleaFormationsExport
                                clea_formations={state.clea_formations}
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- CLEA FORMATION LIST -------------------- */}
                <CleaFormationList
                    loading={state.loading}
                    error={state.error}
                    clea_formations={state.clea_formations}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// ------------------------------------ CLEA FORMATION LIST ---------------------------------- \\
const CleaFormationList = ({ loading, error, clea_formations, refetch }) => {
    const history = useHistory();
    const ROLE_ID = getStoredUser()?.role?.id;
    const { t } = useTranslation();

    const [state, setState] = useState({
        clea_formation: null,
        openDetails: false,
        openDelete: false,
        openUpdate: false,
        openCleaLearnersModal: false,
        openCleaTrainersModal: false,
    });

    const onRefetch = open => {
        setState({ ...state, [open]: false });
        refetch();
    };

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {clea_formations.length > 0 ? (
                    clea_formations.map(clea_formation => (
                        <Fragment key={clea_formation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{clea_formation.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{clea_formation.dates}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getTranslation('name', clea_formation?.company)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {clea_formation.trainers?.length > 1 ? (
                                        <>
                                            <span>{clea_formation.trainers.length}</span>
                                            <Tooltip title={t('trainers.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openCleaTrainersModal: true, clea_formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    ) : (
                                        <span>{clea_formation.trainers[0]?.full_name}</span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {clea_formation.learners?.length > 1 ? (
                                        <>
                                            <span>{clea_formation.learners.length}</span>
                                            <Tooltip title={t('learners.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openCleaLearnersModal: true, clea_formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    ) : (
                                        <span>
                                            {clea_formation.learners[0]?.full_name}
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDetails: true, clea_formation })}
                                                className="list-btn"
                                            >
                                                <IconDetails />
                                            </IconButton>
                                        }
                                    />
                                    {(ROLE_ID <= 2) && (
                                        <>
                                            <Tooltip title={t('edit')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push(`/mobitrain/clea-formation/update/${clea_formation.id}`)}
                                                        className="list-btn"
                                                    >
                                                        <IconEdit />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('clea.proof.file')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push(`/mobitrain/clea-formation/proof/${clea_formation.id}`)}
                                                        className="list-btn"
                                                    >
                                                        <IconDocument />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('delete')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, clea_formation, openDelete: true })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- UPDATE CLEA FORMATION -------------------- */}
            {state.openUpdate && (
                <UpdateCleaFormation
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={() => onRefetch('openUpdate')}
                    clea_formation={state.clea_formation}
                />
            )}
            {/* -------------------- DELETE CLEA FORMATION -------------------- */}
            {state.openDelete && (
                <DeleteCleaFormation
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => onRefetch('openDelete')}
                    clea_formation={state.clea_formation}
                />
            )}
            {/* -------------------- DETAILS CLEA FORMATION -------------------- */}
            {state.openDetails && (
                <DetailsCleaFormation
                    onClose={() => setState({ ...state, openDetails: false })}
                    clea_formation={state.clea_formation}
                />
            )}
            {/* -------------------- CLEA LEARNERS MODAL -------------------- */}
            {state.openCleaLearnersModal && (
                <CleaLearnersModal
                    onClose={() => setState({ ...state, openCleaLearnersModal: false })}
                    clea_formation={state.clea_formation}
                />
            )}
            {/* -------------------- CLEA TRAINERS MODAL -------------------- */}
            {state.openCleaTrainersModal && (
                <CleaTrainersModal
                    onClose={() => setState({ ...state, openCleaTrainersModal: false })}
                    clea_formation={state.clea_formation}
                />
            )}
        </>
    );
};

export default CleaFormationRouter;
