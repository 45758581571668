import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getUsers } from 'services/requests';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- USER EXPORT ---------------------------------------- \\
const UserExport = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
    });

    function getAllUsersToExport(users, offset) {
        getUsers(offset, data.limit, data.last_name, data.first_name, data.company_id, data.email, data.role_id).then(res => {
            if (res?.status === 200) {

                let res_users = res.data.data.users;
                users = users.concat(res_users);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * users.length) / data.total_count) + '%',
                });

                if (res_users.length < data.limit) {
                    createExcelFile(users);
                }
                else {
                    getAllUsersToExport(users, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(users) {
        if (users.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('users'));

            // Header
            worksheet.columns = [
                { header: t('last.name'), key: 'last_name', width: 25 },
                { header: t('first.name'), key: 'first_name', width: 25 },
                { header: t('company'), key: 'company', width: 25 },
                { header: t('email'), key: 'email', width: 25 },
                { header: t('role'), key: 'role', width: 25 },
                { header: t('last.connection'), key: 'last_connection', width: 25 }
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            users.map(user => {
                return worksheet.addRow({
                    last_name: user.last_name,
                    first_name: user.first_name,
                    company: user.company ? user.company.name : '',
                    email: user.email ? user.email : '',
                    role: user.role.name,
                    last_connection: user.last_connection ? user.last_connection : ''
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('users') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllUsersToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default UserExport;
