import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getCompanies } from 'services/requests';
import { getTranslation } from 'services/helpers';

// Components
import Tooltip from 'components/items/tooltip';
import { getSkillbases } from 'services/requests';

// ---------------------------------------- COMPANY EXPORT ---------------------------------------- \\
const CompanyExport = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
        loadingSkillbases: true,
        skillbases: [],
    });

    function getAllCompaniesToExport(companies, offset) {
        getCompanies(offset, data.limit, data.name, data.address, data.email, data.skillbase_id, true).then(res => {
            if (res?.status === 200) {
    
                let res_companies = res.data.data.companies;
                companies = companies.concat(res_companies);
    
                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * companies.length) / data.total_count) + '%',
                });

                if (res_companies.length < data.limit) {
                    createExcelFile(companies);
                }
                else {
                    getAllCompaniesToExport(companies, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    };
    
    function createExcelFile(companies) {
        if (companies.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {

            // Create Excel File
            var workbook = new ExcelJS.Workbook();
            
            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('companies'));
            
            // Headers
            worksheet.columns = [
                { header: t('name'), key: 'name', width: 40 },
                { header: t('country'), key: 'country', width: 20 },
                { header: t('address'), key: 'address', width: 40 },
                { header: t('email'), key: 'email', width: 40 },
                { header: t('skillbases'), key: 'skillbases', width: 60 },
                { header: t('manager'), key: 'managers', width: 40 }
            ];
            worksheet.getRow(1).font = { bold: true };
            
            // Rows
            companies.map(company => {
    
                let skillbaseList = '';
                state.skillbases.forEach(skillbase => {
                    if (company.skillbases_ids.includes(skillbase.id)) {
                        skillbaseList += getTranslation('name', skillbase) + '\r\n';
                    }
                });
                
                return worksheet.addRow({
                    name: company.name,
                    country: company.country,
                    address: company.address,
                    email: company.email,
                    skillbases: skillbaseList,
                    managers: company.emails_managers,
                });
            });
    
            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('companies') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    useEffect(() => {
        getSkillbases().then(res => {
            if (res?.status === 200) {
                setState({ ...state, loadingSkillbases: false, skillbases: res.data.data.skillbases });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllCompaniesToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.loadingSkillbases || state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default CompanyExport;
