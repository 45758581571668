import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Dialog, DialogActions, DialogContent, Divider, Button, Accordion, AccordionSummary, Checkbox, IconButton } from '@material-ui/core';
import { ExpandMore as ExpandIcon, Visibility as LinkIcon } from '@material-ui/icons';

// Services
import { getSkillbasesLinkedWithSoftskills } from 'services/requests';
import { getTranslation, mobipassColor } from 'services/helpers';

// Components
import Tooltip from 'components/items/tooltip';

// ----------------------------------- SKILL BASES WITH SOFT SKILLS ------------------------------------ \\
const SkillbasesWithSoftskills = ({ onClose, isRating, selectedSkillbase, setSelectedSkillbase }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [skillbases, setSkillbases] = useState([]);

    const getSelectedSkillbase = skillbaseId => {
        if (selectedSkillbase && selectedSkillbase.id === skillbaseId) {
            return true;
        }
        return false;
    };

    const onChangeSkillbase = (event, skillbase) => {
        event.stopPropagation();
        if (event.target.checked) {
            setSelectedSkillbase(skillbase);
        }
        else {
            setSelectedSkillbase(null);
        }
    };

    const onClickSkillbase = (event, skillbaseId) => {
        event.stopPropagation();
        history.push('/mobipass/skillbases/' + skillbaseId);
    };

    useEffect(() => {
        getSkillbasesLinkedWithSoftskills().then(res => {
            if (res?.status === 200) {
                setSkillbases(res.data.data.skillbases);
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <Dialog open fullWidth maxWidth="lg">
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {t('skillbase.list.with.softskills')}
                </p>
                <Grid container style={{ backgroundColor: mobipassColor, padding: 10, color: 'white' }}>
                    <span>{t('skillbases')}</span>
                </Grid>
                <div>
                    {skillbases.length > 0 ? (
                        skillbases.map(skillbase => (
                            <Accordion key={skillbase.id}>
                                <AccordionSummary expandIcon={<ExpandIcon />}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {isRating ? (
                                            <Checkbox
                                                checked={getSelectedSkillbase(skillbase.id)}
                                                onClick={e => onChangeSkillbase(e, skillbase)}
                                                color="primary"
                                                style={{ padding: 6 }}
                                            />
                                        ) : (
                                            <Tooltip title={t('see.skillbase')}
                                                item={(
                                                    <IconButton
                                                        onClick={e => onClickSkillbase(e, skillbase.id)}
                                                        style={{ padding: 6, marginRight: 5 }}
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                )}
                                            />
                                        )}
                                        <span>{getTranslation('name', skillbase)}</span>
                                    </div>
                                </AccordionSummary>
                                <Divider />
                                <div>
                                    {skillbase.softskills.map(softskill => (
                                        <Fragment key={softskill.id}>
                                            <Grid style={{ padding: 10 }}>
                                                <span>
                                                    <b>{getTranslation('name', softskill)}</b>
                                                    <br />
                                                    {getTranslation('definition', softskill)}
                                                </span>
                                            </Grid>
                                            <Divider />
                                        </Fragment>
                                    ))}
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid style={{ padding: 10 }}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SkillbasesWithSoftskills;
