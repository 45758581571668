import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Style
import style from 'style';

// ---------------------------------------- AUTOCOMPLETE ---------------------------------------- \\
const CustomizedAutocomplete = ({ value, options, loading, onChange, onInputChange, placeholder }) => {
    const { t } = useTranslation();

    return (
        <Autocomplete
            value={value}
            options={options}
            loading={loading}
            onChange={onChange}
            onInputChange={onInputChange}
            getOptionSelected={(option, value) => option.label === value.label}
            getOptionLabel={option => option.label}
            noOptionsText={t('none')}
            size="small"
            ListboxProps={{ style: { fontSize: 14 } }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={placeholder}
                    style={style.w100}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                        style: { fontSize: 14 }
                    }}
                />
            )}
        />
    );
};

export default CustomizedAutocomplete;
