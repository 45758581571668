import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import {
    Divider, Grid, Button, Accordion, AccordionSummary, Badge,
    Dialog, DialogContent, DialogActions, ButtonGroup, Checkbox,
} from '@material-ui/core';
import { ExpandMore as IconExpand, AddCircle as IconPlus } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

// Services
import { getSkillbase, getCompanyFormationModels } from 'services/requests';
import { getTranslation, mobitrainColor, mobitrainColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';
import InputText from 'components/items/input-text';
import BadgeCustomized from 'components/items/badge';

// Style
import style from 'style';

const styles = {
    border: {
        borderWidth: '0 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        padding: 10,
    },
};

// ----------------------------------- FORMATION FORM PAGE 3 --------------------------------- \\
const FormationFormPage3 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const onUseFormationModelName = checked => {
        if (checked) {
            let name = '';
            if (state.formation_model_id) {
                name = state.formationModels.find(fm => fm.value === state.formation_model_id)?.label;
            }
            setState({ ...state, useFormationModelName: true, name });
        }
        else {
            setState({ ...state, useFormationModelName: false });
        }
    };

    const onChangeMenu = value => {
        if (value) {
            setState({
                ...state,
                descriptorMenu: value,
                useFormationModelName: false,
                formation_model_id: null,
                skillbase_id: null,
                skillbase: null,
                descriptors: null,
            });
        }
    };

    const navigateToPage4 = () => {
        if (state.name === '') {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.descriptors == null || Object.values(state.descriptors).every(value => value.length === 0)) {
            enqueueSnackbar(t('descriptor.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 4 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- NAME -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('training.name')}<FieldRequired />
                            </b>
                            <InputText
                                placeholder={t('training.name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                disabled={state.useFormationModelName}
                            />
                            {state.descriptorMenu === 'model' && (
                                <Grid container alignItems="center" style={{ marginTop: 6 }}>
                                    <Checkbox
                                        checked={state.useFormationModelName}
                                        onChange={e => onUseFormationModelName(e.target.checked)}
                                        style={{ padding: 6 }}
                                    />
                                    <span>{t('define.formation.model.as.name')}</span>
                                </Grid>
                            )}
                        </div>
                        {/* -------------------- SWITCH -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('descriptors.select')}<FieldRequired />
                            </b>
                            <br />
                            <ToggleButtonGroup
                                value={state.descriptorMenu}
                                onChange={(e, value) => onChangeMenu(value)}
                                exclusive
                                size="small"
                                style={{ width: '100%' }}
                            >
                                <ToggleButton value="model" style={{ width: '50%' }}>
                                    <span>{t('from.formation.model')}</span>
                                </ToggleButton>
                                <ToggleButton value="skillbase" style={{ width: '50%' }}>
                                    <span>{t('from.skillbase')}</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {/* -------------------- FROM MODEL -------------------- */}
                        {state.descriptorMenu === 'model' && (
                            <FromFormationModel
                                state={state}
                                setState={setState}
                            />
                        )}
                        {/* -------------------- FROM SKILLBASE -------------------- */}
                        {state.descriptorMenu === 'skillbase' && (
                            <FromSkillbase
                                state={state}
                                setState={setState}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* -------------------- UNIT LIST -------------------- */}
                {state.skillbase != null &&
                    <>
                        <Grid
                            container justifyContent="center" alignItems="center"
                            style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.cW, marginTop: 24 }}
                        >
                            <span style={{ textTransform: 'uppercase', marginLeft: 5 }}>
                                {getTranslation('name', state.skillbase)}
                            </span>
                        </Grid>
                        <UnitList
                            skillbase={state.skillbase}
                            descriptors={state.descriptors}
                            setDescriptors={descriptors => setState({ ...state, descriptors })}
                        />
                    </>
                }
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>3 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage4} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ----------------------------------- FROM FORMATION MODEL ---------------------------------- \\
const FromFormationModel = ({ state, setState }) => {
    const { t } = useTranslation();

    const onChangeFormationModel = formation_model => {
        setState({
            ...state,
            formation_model_id: formation_model ? formation_model.value : null,
            name: state.useFormationModelName ? formation_model?.label : state.name,
            authorizedRefetchModel: true,
        });
    };

    useEffect(() => {
        if (state.authorizedRefetchModels) {
            if (state.formation_model_company_id) {
                getCompanyFormationModels(state.formation_model_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({
                            ...state,
                            formationModels: res.data.data.formation_models.map(fm => ({ value: fm.id, label: fm.name, model: fm })),
                            formation_model_id: null,
                            skillbase: null,
                            descriptors: null,
                            authorizedRefetchModels: false,
                        });
                    }
                });
            }
            else {
                setState({
                    ...state,
                    formationModels: [],
                    formation_model_id: null,
                    skillbase: null,
                    descriptors: null,
                    authorizedRefetchModels: false,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.formation_model_company_id]);

    useEffect(() => {
        if (state.authorizedRefetchModel) {

            if (state.formation_model_id) {

                const formation_model = state.formationModels.find(fm => fm.value === state.formation_model_id)?.model;

                getSkillbase(formation_model?.skillbase?.id).then(res => {
                    if (res?.status === 200) {
                        const skillbase = res.data.data.skillbase;
        
                        let descriptors = {};
                        skillbase.units.forEach(unit => {
                            unit.learning_outcomes.forEach(learning => {
                                descriptors[learning.id] = [];
                            });
                        });
    
                        formation_model.competences.forEach(competence => {
                            let learningDescriptors = descriptors[competence.learning_outcome_id];
                            learningDescriptors.push('competence_' + competence.id);
                        });
    
                        formation_model.knowledges.forEach(knowledge => {
                            let learningDescriptors = descriptors[knowledge.learning_outcome_id];
                            learningDescriptors.push('knowledge_' + knowledge.id);
                        });
    
                        formation_model.skills.forEach(skill => {
                            let learningDescriptors = descriptors[skill.learning_outcome_id];
                            learningDescriptors.push('skill_' + skill.id);
                        });
        
                        setState({ ...state, skillbase, skillbase_id: skillbase.id , descriptors, authorizedRefetchModel: false });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, skillbase: null, skillbase_id: null, descriptors: null, authorizedRefetchModel: false });
                    }
                });
            }
            else {
                setState({ ...state, skillbase: null, skillbase_id: null, descriptors: null, authorizedRefetchModel: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.formation_model_id]);

    return (
        <>
            <div style={{ marginTop: 24 }}>
                <span>
                    {t('company')}
                </span>
                <Select
                    placeholder={t('company')}
                    value={state.formation_model_company_id ? state.companies.find(c => c.value === state.formation_model_company_id) : null}
                    onChange={company => setState({ ...state, formation_model_company_id: company ? company.value : null, authorizedRefetchModels: true })}
                    options={state.companies}
                />
            </div>
            <div style={{ marginTop: 24 }}>
                <span>
                    {t('formation.model')}
                </span>
                <Select
                    placeholder={t('formation.model')}
                    value={state.formation_model_id ? state.formationModels.find(fm => fm.value === state.formation_model_id) : null}
                    onChange={formation_model => onChangeFormationModel(formation_model)}
                    options={state.formationModels}
                />
            </div>
        </>
    );
};

// ------------------------------------- FROM SKILLBASE -------------------------------------- \\
const FromSkillbase = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchSkillbase) {
            if (state.skillbase_id) {
                getSkillbase(state.skillbase_id).then(res => {
                    if (res?.status === 200) {
                        const skillbase = res.data.data.skillbase;
                        
                        let descriptors = {};
                        skillbase.units.forEach(unit => {
                            unit.learning_outcomes.forEach(learning => {
                                descriptors[learning.id] = [];
                            });
                        });
                        
                        setState({ ...state, skillbase, descriptors, authorizedRefetchSkillbase: false });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, skillbase: null, descriptors: null, authorizedRefetchSkillbase: false });
                    }
                });
            }
            else {
                setState({ ...state, skillbase: null, descriptors: null, authorizedRefetchSkillbase: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.skillbase_id]);

    return (
        <div style={{ marginTop: 24 }}>
            <span>
                {t('skillbase')}
            </span>
            <Select
                placeholder={t('skillbase')}
                value={state.skillbase_id ? state.skillbases.find(c => c.value === state.skillbase_id) : null}
                onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null, authorizedRefetchSkillbase: true })}
                options={state.skillbases}
            />
        </div>
    );
};

// ---------------------------------------- UNIT LIST ---------------------------------------- \\
const UnitList = ({ skillbase, descriptors, setDescriptors }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        expanded: null,
        learning: null,
        openLearningModal: false,
    });

    const onChangeAccordion = unit_id => {
        if (state.expanded === unit_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: unit_id });
        }
    };

    const getDescriptorsNumber = unit => {
        let num = 0;
        unit.learning_outcomes.forEach(learning => {
            num += descriptors[learning.id].length;
        });
        return num;
    };

    return (
        <>
            <div>
                {skillbase.units.length > 0 ? (
                    skillbase.units.map(unit => (
                        <Accordion
                            key={unit.id}
                            expanded={state.expanded === unit.id}
                            onChange={() => onChangeAccordion(unit.id)}
                        >
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <Grid container alignItems="center">
                                    <Grid item xs={11}>
                                        <span style={style.fwB}>
                                            {unit.position}. {getTranslation('name', unit)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={1} container justifyContent="center">
                                        <BadgeCustomized
                                            variant="secondary"
                                            number={getDescriptorsNumber(unit)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <div>
                                {unit.learning_outcomes.length > 0 ? (
                                    unit.learning_outcomes.map(learning => (
                                        <Fragment key={learning.id}>
                                            <Grid
                                                onClick={() => setState({ ...state, openLearningModal: true, learning })}
                                                container alignItems="center"
                                                className="list-item-hover"
                                                style={style.p10}
                                            >
                                                <Grid item xs={11}>
                                                    <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                                                </Grid>
                                                <Grid item xs={1} container justifyContent="center" style={style.p5}>
                                                    <Badge badgeContent={descriptors[learning.id].length} color="secondary">
                                                        <IconPlus />
                                                    </Badge>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Grid style={style.p10}>
                                        <span>{t('none')}</span>
                                    </Grid>
                                )}
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------------- LEARNING MODAL --------------------------- */}
            {state.openLearningModal && (
                <LearningModal
                    onClose={() => setState({ ...state, openLearningModal: false })}
                    learning={state.learning}
                    descriptors={descriptors}
                    setDescriptors={setDescriptors}
                />
            )}
        </>
    );
};

// -------------------------------------- LEARNING MODAL ------------------------------------- \\
const LearningModal = ({ onClose, learning, descriptors, setDescriptors }) => {
    const { t } = useTranslation();

    const onUpdateDescriptor = descriptorId => {
        const updatedDescriptors = descriptors;
        const learningDescriptors = updatedDescriptors[learning.id];

        if (learningDescriptors.includes(descriptorId)) {
            const index = learningDescriptors.findIndex(id => id === descriptorId);
            learningDescriptors.splice(index, 1);
        }
        else {
            learningDescriptors.push(descriptorId);
        }

        updatedDescriptors[learning.id] = learningDescriptors;
        setDescriptors(updatedDescriptors);
    };

    const onSelectAllDescriptors = () => {
        const updatedDescriptors = descriptors;
        let learningDescriptors = updatedDescriptors[learning.id];
        learningDescriptors = [];

        learning.competences.forEach(competence => {
            learningDescriptors.push('competence_' + competence.id);
        });
        learning.knowledges.forEach(knowledge => {
            learningDescriptors.push('knowledge_' + knowledge.id);
        });
        learning.skills.forEach(skill => {
            learningDescriptors.push('skill_' + skill.id);
        });

        updatedDescriptors[learning.id] = learningDescriptors;
        setDescriptors(updatedDescriptors);
    };

    const onDeselectAllDescriptors = () => {
        const updatedDescriptors = descriptors;
        let learningDescriptors = updatedDescriptors[learning.id];
        learningDescriptors = [];
        updatedDescriptors[learning.id] = learningDescriptors;
        setDescriptors(updatedDescriptors);
    };

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                {Object.keys(learning).length > 0 && (
                    <>
                        <p className="dialog-title">
                            {getTranslation('name', learning)}
                        </p>
                        <span>{t('descriptors.select')}</span>
                        <div style={{ padding: 12, textAlign: 'center' }}>
                            <ButtonGroup>
                                <Button
                                    onClick={onSelectAllDescriptors}
                                    color="primary"
                                >
                                    {t('select.all')}
                                </Button>
                                <Button
                                    onClick={onDeselectAllDescriptors}
                                    color="secondary"
                                >
                                    {t('deselect.all')}
                                </Button>
                            </ButtonGroup>
                        </div>
                        {/* -------------------- COMPETENCE -------------------- */}
                        <div style={{ backgroundColor: mobitrainColor, ...style.p10 }}>
                            <span style={style.cW}>{t('competence')}</span>
                        </div>
                        {learning.competences.length > 0 ? (
                            learning.competences.map(competence => (
                                <div
                                    key={competence.id}
                                    className="list-item-hover"
                                    onClick={() => onUpdateDescriptor('competence_' + competence.id)}
                                    style={{
                                        ...styles.border,
                                        backgroundColor: descriptors[learning.id].includes('competence_' + competence.id)
                                        ? mobitrainColor2
                                        : null
                                    }}
                                >
                                    <span>{competence.numerotation + t('competence.numerotation')}&nbsp;{getTranslation('name', competence)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                        {/* -------------------- KNOWLEDGE -------------------- */}
                        <div style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.mt15 }}>
                            <span style={style.cW}>{t('knowledge')}</span>
                        </div>
                        {learning.knowledges.length > 0 ? (
                            learning.knowledges.map(knowledge => (
                                <div
                                    key={knowledge.id}
                                    className="list-item-hover"
                                    onClick={() => onUpdateDescriptor('knowledge_' + knowledge.id)}
                                    style={{
                                        ...styles.border,
                                        backgroundColor: descriptors[learning.id].includes('knowledge_' + knowledge.id)
                                        ? mobitrainColor2
                                        : null
                                    }}
                                >
                                    <span>{knowledge.numerotation + t('knowledge.numerotation')}&nbsp;{getTranslation('name', knowledge)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                        {/* -------------------- SKILL -------------------- */}
                        <div style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.mt15 }}>
                            <span style={style.cW}>{t('skill')}</span>
                        </div>
                        {learning.skills.length > 0 ? (
                            learning.skills.map(skill => (
                                <div
                                    key={skill.id}
                                    className="list-item-hover"
                                    onClick={() => onUpdateDescriptor('skill_' + skill.id)}
                                    style={{
                                        ...styles.border,
                                        backgroundColor: descriptors[learning.id].includes('skill_' + skill.id)
                                        ? mobitrainColor2
                                        : null
                                    }}
                                >
                                    <span>{skill.numerotation + t('skill.numerotation')}&nbsp;{getTranslation('name', skill)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                >
                    <span style={style.p0_10}>{t('close')}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormationFormPage3;
