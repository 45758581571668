import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

// Components
import CleaSkillbase from './clea-skillbase';
import CleaCourse from './clea-course';

// Style
import style from 'style';

// ------------------------------------- CLEA MENU ROUTER ------------------------------------ \\
const CleaMenuRouter = () => {
    const { t } = useTranslation();

    return (
        <>
            <BreadcrumbsItem to="/mobipass/clea" className="breadcrumb-item">
                {t('clea')}
            </BreadcrumbsItem>
            <Switch>
                <Route exact path="/mobipass/clea">
                    <CleaMenu />
                </Route>
                <Route exact path="/mobipass/clea/skillbase">
                    <CleaSkillbase />
                </Route>
                <Route path="/mobipass/clea/course">
                    <CleaCourse />
                </Route>
                <Redirect from="*" to="/mobipass/clea" />
            </Switch>
        </>
    );
};

// ---------------------------------------- CLEA MENU ---------------------------------------- \\
const CleaMenu = () => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="full-container" style={style.mt25}>
            <div
                className="clea-menu"
                onClick={() => history.push("/mobipass/clea/skillbase")}
            >
                {t('clea.certificate')}
            </div>
            <div
                className="clea-menu"
                onClick={() => history.push("/mobipass/clea/course")}
                style={style.mt25}
            >
                {t('clea.course')}
            </div>
        </div>
    );
};

export default CleaMenuRouter;
