import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Accordion, AccordionSummary, Divider, Checkbox } from '@material-ui/core';
import { ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { cleaColor, getTranslation } from 'services/helpers';

// Components
import Badge from 'components/items/badge';

// Style
import style from 'style';

const styles = {
    td: {
        padding: 10,
        fontSize: 14,
        border: '1px solid lightgray',
    },
};

// ---------------------------------------- CLEA FORM ---------------------------------------- \\
const CleaForm = ({ state, setState }) => {
    const { t } = useTranslation();

    const getSelectedCompetencesNumber = () => {
        let number = 0;
        state.clea_domains.forEach(domain => {
            domain.clea_subdomains.forEach(subdomain => {
                number += subdomain.clea_competences.filter(c => state.clea_competence_ids.includes(c.id))?.length;
            });
        });
        return number;
    };

    const onCheckedCompetence = competenceId => {
        let clea_competence_ids = state.clea_competence_ids;
        if (clea_competence_ids.includes(competenceId)) {
            let index = clea_competence_ids.findIndex(id => id === competenceId);
            clea_competence_ids.splice(index, 1);
        }
        else {
            clea_competence_ids.push(competenceId);
        }
        setState({ ...state, clea_competence_ids });
    };

    return (
        <div>
            {state.clea_domains?.length > 0 && (
                state.clea_domains.map(clea_domain => (
                    <Accordion key={clea_domain.id}>
                        <AccordionSummary
                            expandIcon={<IconExpand />}
                            style={{ backgroundColor: cleaColor }}
                        >
                            <Grid container alignItems="center" justifyContent="space-between">
                                <span style={{ ...style.fwB, color: 'white' }}>
                                    {clea_domain.position}. {getTranslation('name', clea_domain)}
                                </span>
                                <Badge number={getSelectedCompetencesNumber()} />
                            </Grid>
                        </AccordionSummary>
                        <Divider />
                        <div style={{ overflow: 'auto' }}>
                            <table style={{ ...style.w100, minWidth: 1000 }}>
                                <thead>
                                    <tr>
                                        <th style={{ ...styles.td, ...style.taC, width: '30%' }}>
                                            {t('clea.sub.domain')?.toUpperCase()}
                                        </th>
                                        <th style={{ ...styles.td, ...style.taC, width: '70%' }}>
                                            {t('clea.competence')?.toUpperCase()}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clea_domain.clea_subdomains?.length > 0 && (
                                        clea_domain.clea_subdomains.map(sub_domain => (
                                            <Fragment key={sub_domain.id}>
                                                {/* -------------------- SUB DOMAIN -------------------- */}
                                                <tr>
                                                    <td
                                                        rowSpan={(sub_domain.clea_competences?.length + 1)}
                                                        style={{ ...styles.td, ...style.taC, width: '30%' }}
                                                    >
                                                        {sub_domain.position}. {getTranslation('name', sub_domain)}
                                                    </td>
                                                </tr>
                                                {/* -------------------- COMPETENCE -------------------- */}
                                                {sub_domain.clea_competences?.map(competence => (
                                                    <tr key={competence.id}>
                                                        <td style={{ ...styles.td, width: '70%' }}>
                                                            <Grid container alignItems="center" justifyContent="space-between">
                                                                {competence.position}. {getTranslation('name', competence)}
                                                                <Checkbox
                                                                    checked={state.clea_competence_ids.includes(competence.id)}
                                                                    onChange={() => onCheckedCompetence(competence.id)}
                                                                    color="default"
                                                                />
                                                            </Grid>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </Fragment>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Accordion>
                ))
            )}
        </div>
    );
};

export default CleaForm;
