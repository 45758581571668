import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getFormattedDate } from 'services/helpers';
import { getMobilities } from 'services/requests';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- MOBILITY EXPORT ---------------------------------------- \\
const ExportMobility = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message:  t('downloading') + '...0%',
        start: false,
    });

    function getAllMobilitiesToExport(mobilities, offset) {
        getMobilities(offset, data.limit, data.learner, data.date, data.origin, data.host, true).then(res => {
            if (res?.status === 200) {

                let res_mobilities = res.data.data.mobilities;
                mobilities = mobilities.concat(res_mobilities);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * mobilities.length) / data.total_count) + '%',
                });

                if (res_mobilities.length < data.limit) {
                    createExcelFile(mobilities);
                }
                else {
                    getAllMobilitiesToExport(mobilities, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(mobilities) {
        if (mobilities.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('mobilities'));

            // Header
            worksheet.columns = [
                { header: t('learner'), key: 'learner', width: 25 },
                { header: t('period'), key: 'date', width: 25 },
                { header: t('company.origin'), key: 'origin_company', width: 25 },
                { header: t('tutor.origin'), key: 'origin_tutor', width: 25 },
                { header: t('company.host'), key: 'host_company', width: 25 },
                { header: t('tutor.host'), key: 'host_tutor', width: 25 },
                { header: t('document'), key: 'document', width: 25 },
                { header: t('trainings'), key: 'formation', width: 25 }
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            mobilities.map(mobility => {

                var formations = '';
                mobility.formations.map(formation => {
                    return formations = formations + formation.name + '\r\n';
                });

                return worksheet.addRow({
                    learner: mobility.learner.full_name,
                    date: getFormattedDate(mobility.start_date) + ' - ' + getFormattedDate(mobility.end_date),
                    origin_company: mobility.origin_company_name,
                    origin_tutor: mobility.origin_trainer_name,
                    host_company: mobility.host_company_name,
                    host_tutor: mobility.host_trainer_name,
                    document: mobility.document != null ? t('yes') : t('no'),
                    formation: formations,
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('mobilities') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllMobilitiesToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default ExportMobility;
