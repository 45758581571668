import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { getCountries } from 'services/requests';

// Components
import Select from 'components/items/react-select';

// ------------------------------------ SELECT COUNTRY ----------------------------------------- \\
const SelectCountry = ({ countryId, setCountryId }) => {
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries().then(res => {
            if (res?.status === 200) {
                setCountries(res.data.data.countries.map(c => ({ value: c.id, label: c.name })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            value={countryId ? countries.find(c => c.value === countryId) : null}
            onChange={country => setCountryId(country ? country.value : null)}
            options={countries}
            placeholder={t('country')}
        />
    );
};

export default SelectCountry;
