import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getSoftCategories } from 'services/requests';
import { servicesColor } from 'services/helpers';

// Components
import SoftSkillAttributes from './softskill-attributes';
import SoftCategoryList from './softcategory-list';
import { CreateSoftCategory } from './softcategory-modals';

// Style
import style from 'style';

// ------------------------------------- SOFT SKILLS ROUTER ------------------------------------- \\
const SoftSkillsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <SoftSkillsManagement />
            </Route>
            <Route path={`${match.path}/:softskillId`}>
                <SoftSkillAttributes />
            </Route>
        </Switch>
    );
};

// ------------------------------------- SOFT SKILLS MANAGEMENT ------------------------------------- \\
const SoftSkillsManagement = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        error: false,
        soft_categories: undefined,
        openCreateSoftCategory: false,
    });

    useEffect(() => {
        if (state.loading) {
            getSoftCategories().then(res => {
                if (res?.status === 200) {
                    setState(state => ({ ...state, loading: false, soft_categories: res.data.data.soft_categories }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" style={style.p10}>
                <b>{t('softskills')}</b>
            </Grid>
            <Divider />
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>
                <Button onClick={() => setState({ ...state, openCreateSoftCategory: true })} variant="contained">
                    {t('softcategory.add')}
                </Button>
            </Grid>
            <div className="full-container">
                <Grid style={{ ...style.p10_24, backgroundColor: servicesColor  }}>
                    <span style={style.cW}>{t('softcategories')}</span>
                </Grid>
                <SoftCategoryList
                    loading={state.loading}
                    error={state.error}
                    soft_categories={state.soft_categories}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* -------------------- CREATE SOFT CATEGORY -------------------- */}
            {state.openCreateSoftCategory && (
                <CreateSoftCategory
                    onClose={() => setState({ ...state, openCreateSoftCategory: false })}
                    refetch={() => setState({ ...state, openCreateSoftCategory: false, loading: true })}
                />
            )}
        </>
    );
};

export default SoftSkillsRouter;
