import React from 'react';

// ---------------------------------------- BADGE ---------------------------------------- \\
const Badge = ({ variant = 'default', number }) => {

    const getBackgroundColor = () => {
        switch (variant) {
            case 'primary':
                return '#3f51b5';

            case 'secondary':
                return '#f32c72';
            
            case 'validated':
                return 'green';

            default:
                return 'rgba(0, 0, 0, 0.54)';
        };
    };

    return (
        <div
            className="badge"
            style={{
                backgroundColor: getBackgroundColor(),
                visibility: number > 0 ? 'visible' : 'hidden',
                fontSize: 14,
            }}
        >
            {number >= 100 ? '99+' : number}
        </div>
    );
};

Badge.defaultProps = {
    variant: 'default',
};

export default Badge;
