import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { createCleaFormation, getCompanyList, getCleaDomains, getCleaFormationsResources } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import CleaFormationFormPage1 from './clea-formation-form/clea-formation-form-page-1';
import CleaFormationFormPage2 from './clea-formation-form/clea-formation-form-page-2';
import CleaFormationFormPage3 from './clea-formation-form/clea-formation-form-page-3';
import CleaFormationFormPage4 from './clea-formation-form/clea-formation-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- CREATE CLEA FORMATION ---------------------------------------- \\
const CreateCleaFormation = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: false,
        loadingResources: true,
        errorResources: false,
        page: 1,
        
        // Page 1
        name: '',
        companies: [],
        company_id: null,
        clea_formation_types: [],
        clea_formation_type_id: null,
        trainersOptions: [],
        trainer_company_id: null,
        trainers: [],
        learnersOptions: [],
        learner_company_id: null,
        learners: [],

        // Page 2
        expanded: null,
        clea_domains: [],
        clea_competence_ids: [],
        
        // Page 3
        sessionIsOpen: null,
        sessions: [{
            session_date: null,
            start: '',
            end: '',
        }],
    });

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getCleaDomains(), getCompanyList(), getCleaFormationsResources()])
            .then(axios.spread(function (res_clea_domains, res_companies, res_clea_resources) {
                if (res_clea_domains?.status === 200 && res_companies?.status === 200 && res_clea_resources?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        clea_domains: res_clea_domains.data.data.clea_domains,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        clea_formation_types: res_clea_resources.data.data.clea_formation_types.map(t => ({ value: t.id, label: t.name })),
                    });
                }
                else {
                    console.log(res_clea_domains + res_companies);
                    setState(state => ({ ...state, loadingResources: false, errorResources: true }));
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);
    
    useEffect(() => {
        if (state.loading) {
            createCleaFormation({
                name: state.name,
                company_id: state.company_id,
                clea_formation_type_id: state.clea_formation_type_id,
                trainer_ids: state.trainers.map(trainer => trainer.value),
                learner_ids: state.learners.map(learner => learner.value),
                clea_competence_ids: state.clea_competence_ids,
                sessions: state.sessions,
            }).then(res => {
                if (res?.status === 200) {
                    const formation_id = res.data.data.clea_formation.id
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobitrain/clea-formation/proof/${formation_id}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobitrain/clea-formation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('clea.training.add')}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- CONTAINER -------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <CleaFormationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <CleaFormationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <CleaFormationFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 4 && (
                        <CleaFormationFormPage4 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CreateCleaFormation;
