import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { softskillsColor } from 'services/helpers';

// Style
import style from 'style';

// ----------------------------------------- SOFT FORMATION LEARNERS MODAL ----------------------------------------- \\
export const SoftFormationLearnersModal = ({ onClose, soft_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {soft_formation.softskill?.name}
                </p>
                <span style={{ color: softskillsColor }}>
                    {t('learners.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {soft_formation.learners.map(learner => (
                        <span key={learner.id}>
                            {learner.full_name}
                            &nbsp;
                            {learner.company_name && '(' + learner.company_name + ')'}
                            <br />
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------- SOFT FORMATION TRAINERS MODAL ----------------------------------------- \\
export const SoftFormationTrainersModal = ({ onClose, soft_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {soft_formation.softskill.name}
                </p>
                <span style={{ color: softskillsColor }}>
                    {t('trainers.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {soft_formation.trainers.map(trainer => (
                        <span key={trainer.id}>
                            {trainer.full_name}
                            &nbsp;
                            {trainer.company_name && '(' + trainer.company_name + ')'}
                            <br />
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
