import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Grid } from '@material-ui/core';

// Services
import { getClients } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Autocomplete from 'components/items/autocomplete';
import InputTextGrid from 'components/items/input-text-grid';
import DatePickerGrid from 'components/items/date-picker-grid';
import InputFileGrid from 'components/items/input-file-grid';

// Style
import style from 'style';
import FieldRequired from 'components/items/field-required';

// ---------------------------------------- CONTRACT FORM ---------------------------------------- \\
const ContractForm = ({ state, setState }) => {
    const { t } = useTranslation();
    const { clientId } = useParams();

    return (
        <>
            {/* -------------------- REFERENCE -------------------- */}
            <InputTextGrid
                value={state.reference}
                onChange={e => setState({ ...state, reference: e.target.value })}
                placeholder={t('reference')}
                required
            />
            {/* -------------------- CLIENT -------------------- */}
            {!clientId && (
                <SelectClient
                    client={state.client}
                    setClient={client => setState({ ...state, client })}
                />
            )}
            {/* -------------------- CONTRACT START DATE -------------------- */}
            <DatePickerGrid
                value={state.contract_start_date}
                onChange={contract_start_date => setState({ ...state, contract_start_date })}
                placeholder={t('contract.start.date')}
                outlined
                required
            />
            {/* -------------------- CONTRACT END DATE -------------------- */}
            <DatePickerGrid
                value={state.contract_end_date}
                onChange={contract_end_date => setState({ ...state, contract_end_date })}
                placeholder={t('contract.end.date')}
                outlined
                required
            />
            {/* -------------------- COMPANY PRICE -------------------- */}
            <InputTextGrid
                value={state.company_price}
                onChange={e => setState({ ...state, company_price: e.target.value })}
                placeholder={t('company.price')}
                type="number"
                required
            />
            {/* -------------------- USER PRICE -------------------- */}
            <InputTextGrid
                value={state.user_price}
                onChange={e => setState({ ...state, user_price: e.target.value })}
                placeholder={t('user.price')}
                type="number"
                required
            />
            {/* -------------------- EXTRA PRICE -------------------- */}
            <InputTextGrid
                value={state.extra_price}
                onChange={e => setState({ ...state, extra_price: e.target.value })}
                placeholder={t('extra.price')}
                type="number"
                required
            />
            {/* -------------------- DOCUMENT -------------------- */}
            <InputFileGrid
                placeholder={t('document')}
                inputId="contract-document"
            />
        </>
    );
};

// ---------------------------------------- SELECT CLIENT ---------------------------------------- \\
const SelectClient = ({ client, setClient }) => {
    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        open: false,
        loading: false,
        options: [],
        searchName: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchName: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {        
            getClients(0, 10, state.searchName).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        options: res.data.data.clients.map(c => ({ label: c.name, value: c.id })),
                    });
                }
                else {
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Grid container alignItems="center" style={style.m5_0}>
            <Grid
                item xs={12} sm={4}
                style={{ paddingRight: mediaSM ? 5 : 0 }}
            >
                <b className="input-title">{t('client')}</b><FieldRequired />
            </Grid>
            <Grid
                item xs={12} sm={8}
                style={{ paddingLeft: mediaSM ? 5 : 0 }}
            >
                <Autocomplete
                    value={client}
                    options={state.options}
                    loading={state.loading}
                    onChange={(e, value) => setClient(value)}
                    onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                    placeholder={t('client.search')}
                />
            </Grid>
        </Grid>
    );
};

export default ContractForm;
