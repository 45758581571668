import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, } from '@material-ui/core';

// Services
import { softskillsColor } from 'services/helpers';

// Components
import ToolCards from './tool-cards';
import OtherDocumentation from './other-documentation';

// ---------------------------------------- TOOLS ---------------------------------------- \\
const Tools = () => {
    const match = useRouteMatch();
    const { t } = useTranslation();
    const [button, setButton] = useState('tool-cards');

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                    {t('softskills.tools')}
            </BreadcrumbsItem>
            {/* -------------------- SWITCH TOOLCARD / OTHER DOCUMENTATION -------------------- */}
            <Grid container justifyContent="space-around" className="toolbar">
                <Button
                    variant="contained"
                    onClick={() => setButton("tool-cards")}
                    style={{
                        backgroundColor: button === 'tool-cards' ? softskillsColor : null,
                        color: button === 'tool-cards' ? 'white' : null,
                        margin: 10,
                    }}
                >
                    {t('tool.cards')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setButton("other-doc")}
                    style={{
                        backgroundColor: button === 'other-doc' ? softskillsColor : null, 
                        color: button === 'other-doc' ? 'white' : null,
                        margin: 10,
                    }}
                >
                    {t('other.documentation')}
                </Button>
            </Grid>
            {button === "tool-cards" && <ToolCards />}
            {button === "other-doc" && <OtherDocumentation />}
        </>
    );
};

export default Tools;
