import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Material-UI
import { Button, Divider, Grid, IconButton } from '@material-ui/core';
import { Create as IconModify, Delete as IconDelete, Search as IconSearch, Cancel as IconCancel, Assignment as IconDetails } from '@material-ui/icons';

// Services
import { getFormationModels } from 'services/requests';
import { mobitrainColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateFormationModel from './formation-model-create';
import UpdateFormationModel from './formation-model-update';
import ExportFormationModel from './formation-model-export';
import { DeleteFormationModel, DetailsFormationModel } from './formation-model-modals';
import Pagination from 'components/items/pagination';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';
import SelectSkillbase from 'components/select/select-skillbase';

// Style
import style from 'style';

// ------------------------------------------- ROUTER ---------------------------------------------- \\
const FormationModelsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <FormationModels />
            </Route>
            <Route path={`${match.path}/new-formation-model`}>
                <CreateFormationModel />
            </Route>
            <Route path={`${match.path}/:formationModelId`}>
                <UpdateFormationModel />
            </Route>
        </Switch>
    );
};

// --------------------------------------- FORMATION MODELS ---------------------------------------- \\
const FormationModels = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const storedFilters = JSON.parse(sessionStorage.getItem('formationmodels-filters'));

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        openCreateFormationModel: false,
        name: storedFilters?.name || '',
        company_id: storedFilters?.company_id || null,
        skillbase_id: storedFilters?.skillbase_id || null,
        export_excel: false
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;
    
    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('formationmodels-filters');
        setState({
            ...state,
            loading: true,
            offset: 0,
            name: '',
            company_id: null,
            skillbase_id: null,
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('formationmodels-filters', JSON.stringify({
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
            }));

            getFormationModels({
                offset: state.offset,
                limit: state.limit,
                name: state.name,
                company_id: state.company_id,
                skillbase_id: state.skillbase_id,
                export_excel: state.export_excel
            })
            .then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('formation.models')}
            </BreadcrumbsItem>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={2}>
                    <Button 
                        onClick={() => history.push(`${match.path}/new-formation-model`)}
                        variant="contained"
                    >
                        {t('add')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={8}
                    container
                    justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* --------------------------- LIST HEADERS ----------------------- */}
                <Grid container style={{ backgroundColor: mobitrainColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid style={style.p10}>
                            <span style={style.cW}>{t('formation.models')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={3} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={ style.taC }>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportFormationModel data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- FORMATION MODEL LIST -------------------- */}
                <FormationModelsList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true  })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// -------------------------------------- FORMATION MODEL LIST ------------------------------------- \\
const FormationModelsList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch()

    const [state, setState] = useState({
        skillbase: undefined,
        openUpdateFormationModel: false,
        openDeleteFormationModel: false,
        selected_model: undefined,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {data.formation_models.length > 0 ? (
                    data.formation_models.map(model => (
                        <Fragment key={model.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{model.name}</span>
                                </Grid>
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{model.company.name}</span>
                                </Grid>
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{model.skillbase.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    {/* -------------------- DETAILS MODEL BUTTON -------------------- */}
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDetailsFormationModel: true, selected_model: model })}
                                                className="list-btn"
                                            >
                                                <IconDetails />
                                            </IconButton>
                                        }
                                    />
                                    {/* -------------------- UPDATE MODEL BUTTON -------------------- */}
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                            onClick={() => history.push(`${match.path}/${model.id}`)}
                                                className="list-btn"
                                            >
                                                <IconModify />
                                            </IconButton>
                                        }
                                    />
                                    {/* -------------------- DELETE MODEL BUTTON -------------------- */}
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDeleteFormationModel: true, selected_model: model })}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </> 
                )}
            </div>
            {/* -------------------- DETAILS FORMATION MODELS -------------------- */}
            {state.openDetailsFormationModel && (
                <DetailsFormationModel
                    onClose={() => setState({ ...state, openDetailsFormationModel: false })}
                    refetch={() => handleRefetch('openDetailsFormationModel')}
                    model={state.selected_model}
                />
            )}
            {/* -------------------- DELETE FORMATION MODELS -------------------- */}
            {state.openDeleteFormationModel && ( 
                <DeleteFormationModel
                    onClose={() => setState({ ...state, openDeleteFormationModel: false })}
                    refetch={() => handleRefetch('openDeleteFormationModel')}
                    model={state.selected_model}
                /> 
            )}
        </>
    );
};

export default FormationModelsRouter;
