import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, MenuItem, Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/SaveAlt';

// Services
import { getUsersResources, importUsers } from 'services/requests';
import { servicesColor } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';
import InputFile from 'components/items/input-file';
import SelectOutlined from 'components/items/select-outlined';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// ----------------------------------------- IMPORT USERS ---------------------------------------- \\
const ImportUsers = ({ onClose, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        role_id: -1,
        company_id: null,
        data: null,
    });

    // CSV File Model
    const getModelFile = () => {

        // Create Excel File
        var workbook = new ExcelJS.Workbook();

        // Add Worksheet to the Workbook
        var worksheet = workbook.addWorksheet('Worksheet');

        // Header
        worksheet.columns = [
            { header: 'first_name', width: 25 },
            { header: 'last_name', width: 25 },
            { header: 'email', width: 25 },
            { header: 'birthdate', width: 25 },
            { header: 'phone', width: 25 },
            { header: 'address', width: 25 },
            { header: 'zip_code', width: 25 },
            { header: 'city', width: 25 }
        ];
        worksheet.getRow(1).font = { bold: true };

        // Save Excel File
        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'modele.xlsx'));
    };

    // Handle Import Users
    const onImportUsers = () => {
        if (state.role_id === -1 || state.company_id == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-users').files[0];

            if (file) {
                var data = new FormData();
    
                data.append('excel', file);
                data.append('company_id', state.company_id);
                data.append('role_id', state.role_id);
    
                setState({ ...state, data, loading: true });
            }
            else {
                enqueueSnackbar(t('file.select'), { variant: 'warning' });
            }
        }
    };

    useEffect(() => {
        if (state.loading) {
            importUsers(state.data).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('users.import')}
                        </p>
                        {/* -------------------- ROLE -------------------- */}
                        <div>
                            <b className="input-title">{t('role')}</b><FieldRequired />
                            <SelectRole
                                role_id={state.role_id}
                                setRoleId={role_id => setState({ ...state, role_id })}
                            />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('company')}</b><FieldRequired />
                            <SelectCompanies
                                company_id={state.company_id}
                                setCompanyId={company_id => setState({ ...state, company_id })}
                            />
                        </div>
                        {/* -------------------- CSV FILE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('file.csv')}</b><FieldRequired />
                            <InputFile
                                inputId="users"
                            />
                        </div>
                        {/* -------------------- DOWNLOAD MODEL -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('model.download')}</b>
                            <div>
                                <Button onClick={getModelFile} variant="contained" style={{ textTransform: 'none' }}>
                                    {t('download')}
                                    <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </Button>
                                <span style={{ marginLeft: 5 }}>({t('fields.required.to.user.export')})</span>
                            </div>
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onImportUsers}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------------ SELECT ROLE --------------------------------------- \\
const SelectRole = ({ role_id, setRoleId }) => {
    const { t } = useTranslation();
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getUsersResources().then(res => {
            if (res?.status === 200) {
                setRoles(res.data.data.roles);
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <SelectOutlined
            value={role_id}
            onChange={e => setRoleId(e.target.value)}
        >
            <MenuItem value={-1}>
                <span>{t('role')}</span>
            </MenuItem>
            {roles.map(role => (
                <MenuItem key={role.id} value={role.id}>
                    <span>{role.name}</span>
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

// ---------------------------------------- SELECT COMPANIES ------------------------------------- \\
const SelectCompanies = ({ company_id, setCompanyId }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getUsersResources().then(res => {
            if (res?.status === 200) {
                setCompanies(res.data.data.companies.map(c => ({ value: c.id, label: c.name })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <Select
            placeholder={t('company')}
            value={company_id ? companies.find(c => c.value === company_id) : null}
            onChange={company => setCompanyId(company ? company.value : null)}
            options={companies}
        />
    );
};

export default ImportUsers;
