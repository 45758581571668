import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, IconButton, Checkbox, Button, Divider, Grid, CircularProgress } from '@material-ui/core';
import { Visibility as VisibilityOnIcon, VisibilityOff as VisibilityOffIcon, AlternateEmail as LoginIcon, Lock as PasswordIcon, Send as SendIcon, Close as CloseIcon } from '@material-ui/icons';

// Services
import { isValidEmail } from 'services/helpers'
import { signIn, sendConfirmationLink, sendPasswordCode } from 'services/requests';
import { setToken } from 'services/storage';
import { theme } from 'services/helpers';

// Components
import InputText from 'components/items/input-text';
import Spinner from 'components/items/spinner';
import FieldRequired from 'components/items/field-required';

// Assets
import LogoZerobarrier from 'assets/images/zerobarrier.png';
import LogoMobirhin from 'assets/images/mobirhin.png';

// Style
import style from 'style';

const styles = {
    PasswordIcon: {
        position: 'absolute',
        right: 2,
        zIndex: 1,
        width: 38,
        height: 38,
        padding: 0
    },
};

// ----------------------------------------- HOME MODAL -------------------------------------------- \\
const HomeModal = ({ onClose }) => {
    const [page, setPage] = useState(1);
    
    return (
        <Dialog open fullWidth>
            {page === 1 && (
                <Login
                    onClose={onClose}
                    nextPage={() => setPage(2)}
                />
            )}
            {page === 2 && (
                <PasswordForgot
                    onClose={onClose}
                    previousPage={() => setPage(1)}
                    nextPage={() => setPage(3)}
                />
            )}
            {page === 3 && (
                <ConfirmationLink
                    onClose={onClose}
                    previousPage={() => setPage(2)}
                />
            )}
        </Dialog>
    );
};

// -------------------------------------------- LOGIN ---------------------------------------------- \\
const Login = ({ onClose, nextPage }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const src = theme === 'mobirhin' ? LogoMobirhin : LogoZerobarrier;

    const [state, setState] = useState({
        loading: false,
        email: '',
        password: '',
        passwordType: 'password',
        remember: false,
    });

    function handlePasswordType() {
        if (state.passwordType === 'text') {
            setState({ ...state, passwordType: 'password' });
        }
        else {
            setState({ ...state, passwordType: 'text' });
        }
    }

    function handleRemember() {
        setState({ ...state, remember: !state.remember });
    }

    function handleSignIn() {
        if (state.email === '') {
            enqueueSnackbar(t('email.enter'), { variant: 'warning' });
        }
        else if (!isValidEmail(state.email)) {
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else if (state.password === '') {
            enqueueSnackbar(t('password.enter'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            signIn(state.email, state.password, state.remember).then(res => {
                if (res?.status === 200) {
                    setToken(res.data.data, state.remember);
                    history.push('/dashboard');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.pb20, ...style.taC }}>
                        {/* ---------------------- LOGO ---------------------- */}
                        <img
                            alt="logo-zerobarrier"
                            src={src}
                            style={{ height: 50, marginBottom: 10 }}
                        />
                        {/* ---------------------- EMAIL ---------------------- */}
                        <div className="input-group" style={style.p10_0}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <LoginIcon />
                                </span>
                            </div>
                            <input
                                type="email"
                                tabIndex="1"
                                className="form-control"
                                placeholder={t('email')}
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                                onKeyPress={e => e.key === 'Enter' ? handleSignIn() : null}
                            />
                        </div>
                        {/* ---------------------- PASSWORD ---------------------- */}
                        <div className="input-group" style={style.p10_0}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <PasswordIcon />
                                </span>
                            </div>
                            <IconButton
                                tabIndex="3"
                                onClick={handlePasswordType}
                                style={styles.PasswordIcon}>
                                {state.passwordType === 'text' ? (
                                    <VisibilityOnIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )}
                            </IconButton>
                            <input
                                tabIndex="2"
                                className="form-control"
                                placeholder={t('password')}
                                type={state.passwordType}
                                value={state.password}
                                onChange={e => setState({ ...state, password: e.target.value })}
                                onKeyPress={e => e.key === 'Enter' ? handleSignIn() : null}
                                style={{ zIndex: 0 }}
                            />
                        </div>
                        {/* ---------------------- REMEMBER ---------------------- */}
                        <Grid container justifyContent="center" alignItems="center" style={style.mb10}>
                            <Checkbox
                                tabIndex="4"
                                color="primary"
                                checked={state.remember}
                                onKeyPress={e => e.key === 'Enter' ? handleRemember() : null}
                                onChange={handleRemember}
                            />
                            <span>{t('sign.in.stay')}</span>
                        </Grid>
                        {/* ---------------------- BUTTON LOGIN ---------------------- */}
                        <Button
                            onKeyPress={e => e.keyCode === 13 ? handleSignIn() : null}
                            onClick={handleSignIn}
                            variant="contained"
                            color="primary"
                            tabIndex="5"
                        >
                            {t('connection')}
                        </Button>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained" style={style.closeBtnModal}>
                            <CloseIcon />
                        </Button>
                        <Button onClick={nextPage} variant="contained" tabIndex="7" style={style.ttN}>
                            {t('password.forgot')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

// ---------------------------------------- PASSWORD FORGOT ---------------------------------------- \\
const PasswordForgot = ({ onClose, previousPage, nextPage }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        email: '',
    });

    const onSendPasswordCode = () => {
        if (!isValidEmail(state.email)) {
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            sendPasswordCode(state.email).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setState({ ...state, loading: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('password.forgot')}
                </p>
                <b className="input-title">{t('email.account')}</b><FieldRequired />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <InputText
                            placeholder={t('email')}
                            value={state.email}
                            onChange={e => setState({ ...state, email: e.target.value })}
                        />
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <Button
                            onClick={onSendPasswordCode}
                            color="primary" variant="contained"
                        >
                            {state.loading ? (
                                <CircularProgress size={24} style={style.cW} />
                            ) : (
                                <>
                                    {t('send.link')}
                                    <SendIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Grid>
                    <Button onClick={onClose} variant="contained" style={style.closeBtnModal}>
                        <CloseIcon />
                    </Button>
                    <Button onClick={previousPage} variant="contained" style={style.ttN}>
                        {t('previous')}
                    </Button>
                </Grid>
                <Button onClick={nextPage} variant="contained" style={style.ttN}>
                    {t('email.no.confirmed')}
                </Button>
            </DialogActions>
        </>
    );
};

// --------------------------------------- CONFIRMATION LINK ---------------------------------------- \\
const ConfirmationLink = ({ onClose, previousPage }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        email: '',
    });

    const onSendConfirmationLink = () => {
        if (!isValidEmail(state.email)) {
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            sendConfirmationLink(state.email).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setState({ ...state, loading: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('email.no.confirmed')}
                </p>
                <b className="input-title">{t('email.account')}</b><FieldRequired />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <InputText
                            placeholder={t('email')}
                            value={state.email}
                            onChange={e => setState({ ...state, email: e.target.value })}
                        />
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <Button
                            onClick={onSendConfirmationLink} 
                            color="primary" variant="contained"
                        >
                            {state.loading ? (
                                <CircularProgress size={24} style={style.cW} />
                            ) : (
                                <>
                                    {t('send.link')}
                                    <SendIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'left' }}>
                <Grid>
                    <Button onClick={onClose} variant="contained" style={style.closeBtnModal}>
                        <CloseIcon />
                    </Button>
                    <Button onClick={previousPage} variant="contained" style={style.ttN}>
                        {t('previous')}
                    </Button>
                </Grid>
            </DialogActions>
        </>
    );
};

export default HomeModal;
