import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Draft JS
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { setSkillbaseComment } from 'services/requests';

// Components
import TextEditor from 'components/items/editor/comment-text-editor';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ------------------------------------- SKILLBASE COMMENT -------------------------------------- \\
const SkillbaseComment = ({ onClose, refetch, skillbaseId, userId, skillbaseComment }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        error: false,
        comment: (skillbaseComment != null && skillbaseComment.comment != null) ? EditorState.createWithContent(convertFromRaw(JSON.parse(skillbaseComment.comment))) : EditorState.createEmpty(),
    });

    useEffect(() => {
        if (state.loading) {
            setSkillbaseComment(skillbaseId, userId, {
                comment: state.comment.getCurrentContent().hasText() ? JSON.stringify(convertToRaw(state.comment.getCurrentContent())) : null,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('informations.learner.course')}
                        </p>
                        <TextEditor
                            label={'comment'}
                            editorState={state.comment}
                            setEditorState={editorState => setState({ ...state, comment: editorState })}
                        />
                        {(skillbaseComment != null && skillbaseComment.last_modifier != null) && (
                            <div style={{ marginTop: 12, fontSize: 14 }}>
                                <span>{t('edited.the')}</span> 
                                <strong>{moment(skillbaseComment?.updated_at).format('DD/MM/YYYY')}</strong> 
                                <span>{t('at')}</span>
                                <strong>{moment(skillbaseComment?.updated_at).format('HH:mm')}</strong>
                                <span>{t('by')}</span>
                                <strong>{skillbaseComment.last_modifier?.full_name}</strong>
                            </div>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('close')}
                        </Button>
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained" color="primary">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default SkillbaseComment;
