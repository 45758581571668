import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Accordion, AccordionSummary, Grid, Button, CircularProgress } from '@material-ui/core';
import { ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getTranslation, softskillsColor, softskillsColor2 } from 'services/helpers';
import { getSoftCategories, searchLearners } from 'services/requests';

// Component
import LearnerList from './learner-list';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Badge from 'components/items/badge';

// Style
import style from 'style';

// ---------------------------------------- RESEARCH ---------------------------------------- \\
const SoftskillsSearch = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        // Softskills
        loading: true,
        error: false,
        expanded: null,
        soft_categories: [],
        soft_portfolios: {},

        // Users
        searchingLearners: false,
        errorLearners: false,
        searchEnded: false,
        users: [],
        filteredUsers: [],
        companies: [],
        company_id: null,
        learner: '',
    });

    const onChangeAccordion = soft_category_id => {
        if (state.expanded === soft_category_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: soft_category_id });
        }
    };

    const onClickSoftLevel = (softskill_id, soft_level_id) => {
        let soft_portfolios = state.soft_portfolios;

        if (soft_portfolios[softskill_id] === soft_level_id) {
            soft_portfolios[softskill_id] = null;
        }
        else {
            soft_portfolios[softskill_id] = soft_level_id;
        }

        setState({ ...state, soft_portfolios });
    };

    const getSoftSkillNumber = soft_category => {
        let number = 0;
        soft_category.softskills.forEach(softskill => {
            number += Object.entries(state.soft_portfolios).filter(([key, value]) => Number(key) === softskill.id && value != null).length;
        });
        return number;
    };

    useEffect(() => {
        if (state.loading) {
            getSoftCategories().then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, soft_categories: res.data.data.soft_categories });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.searchingLearners) {
            searchLearners('softskills', {
                soft_level_ids: Object.values(state.soft_portfolios).filter(v => v != null),
            }).then(res => {
                if (res?.status === 200) {
                    const data = res.data.data;
                    setState({
                        ...state,
                        searchingLearners: false,
                        searchEnded: true,
                        users: data.users,
                        filteredUsers: data.users,
                        companies: data.companies.map(c => ({ value: c.id, label: c.name })),
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, searchingLearners: false, errorLearners: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.searchingLearners]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* ---------------------- SOFT CATEGORIES ---------------------- */}
            {state.searchEnded === false && (
                <>
                    <div style={{ backgroundColor: softskillsColor, padding: 10, textAlign: 'center' }}>
                        <span style={style.cW}>{t('search.on.softskills')}</span>
                    </div>
                    {(state.soft_categories != null && state.soft_categories.length > 0) ? (
                        <>
                            <div>
                                {state.soft_categories.map(soft_category => (
                                    <Accordion 
                                        key={soft_category.id}
                                        expanded={state.expanded === soft_category.id}
                                        onChange={() => onChangeAccordion(soft_category.id)}
                                    >
                                        <AccordionSummary expandIcon={<IconExpand />}>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <span>{soft_category.position}. {getTranslation('name', soft_category)}</span>
                                                <Badge number={getSoftSkillNumber(soft_category)} />
                                            </Grid>
                                        </AccordionSummary>
                                        <Divider />
                                        {soft_category.softskills.length > 0 ? (
                                            <div className="soft-level-table" style={style.p24}>
                                                <table style={style.w100}>
                                                    <tbody>
                                                        {soft_category.softskills.map(softskill => (
                                                            <tr key={softskill.id}>
                                                                <td className="soft-level-td" style={style.taC}>
                                                                    <span style={style.fwB}>
                                                                        {soft_category.position}.{softskill.position} {getTranslation('name', softskill)}
                                                                    </span>
                                                                </td>
                                                                {softskill.soft_levels.map(soft_level => (
                                                                    <td
                                                                        key={soft_level.id}
                                                                        className="soft-level-td"
                                                                        onClick={() => onClickSoftLevel(softskill.id, soft_level.id)}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                            className="soft-level"
                                                                            style={{
                                                                                backgroundColor: state.soft_portfolios[softskill.id] === soft_level.id
                                                                                ? softskillsColor2
                                                                                : null
                                                                            }}
                                                                        >
                                                                            <p style={{ ...style.taC, ...style.fwB, ...style.w100 }}>
                                                                                {t('level')}&nbsp;{soft_level.level}
                                                                                <br />
                                                                                {soft_level.level === 1 && t('remember.and.understand')}
                                                                                {soft_level.level === 2 && t('apply')}
                                                                                {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                                                {soft_level.level === 4 && t('create')} 
                                                                            </p>
                                                                            <p className="soft-level-definition">
                                                                                {getTranslation('definition', soft_level)}
                                                                            </p>
                                                                        </Grid>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Grid style={style.p10_24}>
                                                <span>{t('none')}</span>
                                            </Grid>
                                        )}
                                    </Accordion>
                                ))}
                            </div>
                            <div style={{ padding: 24, textAlign: 'center' }}>
                                <Button
                                    onClick={() => setState({ ...state, searchingLearners: true })}
                                    disabled={state.searchingLearners || Object.values(state.soft_portfolios).every(value => value == null)}
                                    variant="contained"
                                    style={{ width: 160 }}
                                >
                                    {t('research')}
                                    {state.searchingLearners && (
                                        <CircularProgress
                                            color="inherit" size={20}
                                            style={{ marginLeft: 5 }}
                                        />
                                    )}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <Grid style={style.p10_24}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </>
            )}
            {/* -------------------- LEARNER LIST -------------------- */}
            {state.searchEnded === true && (
                <LearnerList
                    backgroundColor={softskillsColor}
                    state={state}
                    setState={setState}
                />
            )}
        </>
    );
};

export default SoftskillsSearch;
