import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

// Material-UI
import { Accordion, AccordionSummary, Divider, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Services
import { getTranslation, mobipassColor, mobitoolsColor } from 'services/helpers';
import { getSectors } from 'services/requests';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputSearch from 'components/items/input-search';

// Style
import style from 'style';

// -------------------------------------- SKILLBASE LIST --------------------------------------- \\
const SkillbaseList = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        sectors: [],
        filteredSectors: [],
        search: '',
    });

    const normalize = label => {
        return label?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };
    
    const onSearch = () => {
        if (state.search != null && state.search.length > 0) {
            let filteredSectors = [];
            state.sectors.forEach(sector => {
                let skillbases = sector.skillbases.filter(s =>
                    normalize(getTranslation('name', s)).includes(normalize(state.search))
                );
                if (skillbases.length > 0) {
                    filteredSectors.push(Object.assign({}, sector, { skillbases }));
                }
            });
            setState({ ...state, filteredSectors });
        }
        else {
            setState({ ...state, filteredSectors: state.sectors });
        }
    };

    useEffect(() => {
        getSectors().then(res => {
            if (res?.status === 200) {
                let sectors = res.data.data.sectors;
                setState(state => ({ ...state, loading: false, sectors, filteredSectors: sectors }));
            }
            else {
                console.log(res);
                setState(state => ({ ...state, loading: false, error: true }));
            }
        });
    }, []);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid container className="toolbar">
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <InputSearch
                        placeholder={t('research.skillbase')}
                        value={state.search}
                        onChange={e => setState({ ...state, search: e.target.value })}
                        onKeyPress={onSearch}
                        onSearch={onSearch}
                    />
                </Grid>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                <Grid style={{ ...style.p10, backgroundColor: pathname.includes('tools') ? mobitoolsColor : mobipassColor }}>
                    <span style={style.cW}>{t('skillbases')}</span>
                </Grid>
                <div>
                    {state.filteredSectors.length > 0 ? (
                        state.filteredSectors.map(sector => (
                            <Accordion key={sector.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <span style={style.fwB}>
                                        {getTranslation('name', sector)} ({sector.skillbases.length})
                                    </span>
                                </AccordionSummary>
                                <Divider />
                                <div>
                                    {sector.skillbases.length > 0 ? (
                                        sector.skillbases.map((skillbase, index) => (
                                            <Fragment key={skillbase.id}>
                                                {index > 0 && <Divider />}
                                                <div
                                                    onClick={() => history.push(`${match.url}/${skillbase.id}`)}
                                                    className="list-item-hover"
                                                    style={style.p10}
                                                >
                                                    <span>{getTranslation('name', skillbase)}</span>
                                                </div>
                                            </Fragment>
                                        ))
                                    ) : (
                                        <div style={style.p10}>
                                            <span>{t('none')}</span>
                                        </div>
                                    )}
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SkillbaseList;
