import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import { SaveAlt as IconSaveAlt } from '@material-ui/icons';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Services
import { getIndividualExportFormation, getCompanyList, getCompanyMembers } from 'services/requests';
import { mobitrainColor } from 'services/helpers';

// Components
import ExportXLSX from './xlsx-export';
import ExportPDF from './pdf-export';
import DatePicker from 'components/items/date-picker';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// ------------------------------------ INDIVIDUAL EXPORT FORMATION -------------------------------------- \\
const IndvidualExportFormation = ({ onClose }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        company: null,
        learner: null,
        learnerOptions: [],
        date_from: null,
        date_to: null,
        exportType: null,
    });

    const handleExport = exportType => {
        if (state.learner == null || state.date_from == null || state.date_to == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.date_from > state.date_to) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true, exportType });
        }
    };

    useEffect(() => {
        if (state.company) {
            getCompanyMembers(state.company.value).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, learnerOptions: res.data.data.learners.map(u => ({ value: u.id, label: u.full_name })) });
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company]);

    useEffect(() => {
        if (state.loading) {

            const date_from = moment(state.date_from).format('YYYY-MM-DD');
            const date_to = moment(state.date_to).format('YYYY-MM-DD');

            getIndividualExportFormation(state.learner.value, date_from, date_to).then(res => {
                if (res?.status === 200) {
                    const formations = res.data.data.formations;
                    if (formations.length > 0) {
                        if (state.exportType === 'xlsx') {
                            ExportXLSX(formations, state.learner, state.company, date_from, date_to, t);
                        }
                        else {
                            ExportPDF(formations, state.learner, state.company, date_from , date_to, t);
                        }
                    }
                    else {
                        enqueueSnackbar(t('no.data.to.export.found'), { variant: 'warning' });
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                }
                setState({ ...state, loading: false });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('export.individual.information')}
                </p>
                {/* -------------------- COMPANY -------------------- */}
                <div>
                    <b className="input-title">{t('company')}</b>
                    <SelectCompany
                        company={state.company}
                        setCompany={company => setState({ ...state, company, learner: null, learnerOptions: [] })}
                    />
                </div>
                {/* -------------------- USER -------------------- */}
                <div style={{ marginTop: 12 }}>
                    <b className="input-title">{t('learner')}</b><FieldRequired />
                    <Select
                        placeholder={t('learner')}
                        value={state.learner}
                        onChange={learner => setState({ ...state, learner })}
                        options={state.learnerOptions}
                    />
                </div>
                <Grid container style={{ marginTop: 12 }}>
                    <Grid item xs={6} style={{ paddingRight: 5 }}>
                        {/* ----------------- START DATE -------------------- */}
                        <b className="input-title">{t('start.date')}</b><FieldRequired />
                        <DatePicker
                            placeholder={t('start.date')}
                            value={state.date_from}
                            onChange={date_from => setState({ ...state, date_from })}
                            outlined
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        {/* ----------------- END DATE -------------------- */}
                        <b className="input-title">{t('end.date')}</b><FieldRequired />
                        <DatePicker
                            placeholder={t('end.date')}
                            value={state.date_to}
                            onChange={date_to => setState({ ...state, date_to })}
                            outlined
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    <span>{t('close')}</span>
                </Button>
                <Grid>
                    <Button
                        onClick={() => handleExport('xlsx')}
                        variant="contained"
                        style={{ color: 'white', backgroundColor: mobitrainColor }}
                    >
                        <span>EXCEL</span>
                        <IconSaveAlt style={{ marginLeft: 5, fontSize: 16 }} />
                    </Button>
                    <Button
                        onClick={() => handleExport('pdf')}
                        variant="contained"
                        style={{ marginLeft: 5, color: 'white', backgroundColor: mobitrainColor }}
                    >
                        <span>PDF</span>
                        <IconSaveAlt style={{ marginLeft: 5, fontSize: 16 }} />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog> 
    );
};

// ----------------------------------------- SELECT COMPANY ---------------------------------------------- \\
const SelectCompany = ({ company, setCompany }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setCompanies(res.data.data.companies.map(c => ({ value: c.id, label: c.name })));
            }
            else {
                console.log(res);
            }
        })
    }, []);

    return (
        <Select
            placeholder={t('company')}
            value={company}
            onChange={value => setCompany(value)}
            options={companies}
        />
    );
};

export default IndvidualExportFormation;
