import moment from 'moment';
import { languages, months } from './constants';

// --------------------------------------------------------------- \\
// --------------------- THEME MANAGEMENT ------------------------ \\
// --------------------------------------------------------------- \\

var origin = window.location.origin;
var theme;

if (origin.includes('zerobarrier')) {
    theme = 'zerobarrier';
}
else if (origin.includes('mobirhin')) {
    theme = 'mobirhin';
}
else if (origin.includes('localhost')) {
    theme = 'localhost';
}
else {
    theme = 'zerobarrier';
}

export { theme };

// --------------------------------------------------------------- \\
// ------------------------ THEME COLORS ------------------------- \\
// --------------------------------------------------------------- \\

var mobipassColor, mobipassColor2, mobitrainColor, mobitrainColor2, mobitoolsColor, servicesColor, servicesColor2, softskillsColor, softskillsColor2, cleaColor, cleaColor2;

switch (theme) {
    case 'mobirhin':
        mobipassColor = '#4CA8DD';
        mobipassColor2 = '#E0F0FB';
        mobitrainColor = '#4CA8DD';
        mobitrainColor2 = '#E0F0FB';
        mobitoolsColor = '#4CA8DD';
        servicesColor = '#562790';
        servicesColor2 =  '#BAF2AD';
        softskillsColor = '#67AF57';
        softskillsColor2 =  '#BAF2AD';
        cleaColor = '#641C80';
        cleaColor2 = '#E1CDE9';
        break;
    
    default:
        mobipassColor = '#3A88C8';
        mobipassColor2 = '#E0F0FB';
        mobitrainColor = '#E99300';
        mobitrainColor2 = '#FACB7D';
        mobitoolsColor = '#BD2363';
        servicesColor = '#67AF57';
        servicesColor2 =  '#BAF2AD';
        softskillsColor = '#008B69';
        softskillsColor2 =  '#B0E5D8';
        cleaColor = '#641C80';
        cleaColor2 = '#E1CDE9';
        break;
};

export { mobipassColor, mobipassColor2, mobitrainColor, mobitrainColor2, mobitoolsColor, servicesColor, servicesColor2, softskillsColor, softskillsColor2, cleaColor, cleaColor2 };

// --------------------------------------------------------------- \\
// ------------------------- FILE URL ---------------------------- \\
// --------------------------------------------------------------- \\

var fileUrl;

switch (theme) {
    case 'zerobarrier' || 'mobirhin':
        fileUrl = window.location.origin + '/';
        break;

    case 'localhost':
        fileUrl = 'http://localhost:8000/';
        break;

    default:
        fileUrl = window.location.origin + '/';
        break;
};

export { fileUrl };

// --------------------------------------------------------------- \\
// ---------------------------- DATE ----------------------------- \\
// --------------------------------------------------------------- \\

export const getFormattedDate = date => {
    if (date != null && typeof date === 'string') {
        const data = date.split('-');
        return data[2] + '/' + data[1] + '/' + data[0];
    }
    return '';
};

export const isValidDate = date => {
    const d = moment(date, 'YYYY-MM-DD', true);
    return d.isValid();
};

// --------------------------------------------------------------- \\
// ----------------------------- TIME ---------------------------- \\
// --------------------------------------------------------------- \\

export const isValidTime = time => {
    const expression = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;
    return expression.test(String(time).toLowerCase());
};

// --------------------------------------------------------------- \\
// ----------------------------- EMAIL --------------------------- \\
// --------------------------------------------------------------- \\

export const isValidEmail = email => {
    const expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return expression.test(String(email).toLowerCase());
};

// --------------------------------------------------------------- \\
// ---------------------- FILE EXTENSION ------------------------- \\
// --------------------------------------------------------------- \\

export const getFileExtension = name => {
    if (name != null && typeof name === 'string') {
        const index = name.lastIndexOf('.');
        return name.substring(index).toUpperCase();
    }
    return '';
};

// --------------------------------------------------------------- \\
// ---------------------- TIME DIFFERENCE ------------------------ \\
// --------------------------------------------------------------- \\

export const getTimeDifference = (start, end) => {
    if (start != null && end != null) {

        start = start.split(':');
        end = end.split(':');
    
        let startTime = new Date(0, 0, 0, start[0], start[1], 0);
        let endTime = new Date(0 ,0 ,0 , end[0], end[1], 0);
    
        let difference = endTime.getTime() - startTime.getTime();
    
        let hours = Math.floor(difference / 1000 / 60 / 60);
        difference -= hours * 1000 * 60 * 60;
        let minutes = Math.floor(difference / 1000 / 60);
    
        return moment(hours + ':' + (minutes < 9 ? '0' : '') + minutes, "HH:mm").format("HH:mm:ss");
    }
    return '00:00:00';
};

// --------------------------------------------------------------- \\
// -------------------------- TRANSLATION ------------------------ \\
// --------------------------------------------------------------- \\

export const getTranslation = (item, object) => {
    if (object != null) {
        if (object[item] != null) {
            return object[item];
        }
        if (object.translations != null && Object.keys(object.translations).findIndex(key => key === 'en') !== -1) {
            return object.translations['en'][item];
        }
        if (object.translations != null && Object.keys(object.translations).length > 0) {
            return Object.values(object.translations)[0][item];
        }
    }
    return '';
};

// --------------------------------------------------------------- \\
// ----------------------- STATISTIC MONTH ----------------------- \\
// --------------------------------------------------------------- \\

export const getStatisticMonth = (t, res_months, key) => {
    if (res_months != null && Object.keys(res_months).length < 10) {
        return t(months.find(m => m.value === key.split('-')[1]).label) + ' ' + key.split('-')[0];
    }
    return key.split('-')[1] + '/' + key.split('-')[0];
};

// --------------------------------------------------------------- \\
// ------------------ INITIAL LANGUAGES OBJECT ------------------- \\
// --------------------------------------------------------------- \\

export const initialLanguagesObject = () => {
    let object = {};
    for (let i = 0; i < languages.length; i += 1) {
        object[languages[i].code] = '';
    }
    return object;
};

// --------------------------------------------------------------- \\
// --------------------- ASPECT RATIO JSPDF ---------------------- \\
// --------------------------------------------------------------- \\

export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { resizedWidth: (srcWidth * ratio), resizedHeight: (srcHeight * ratio) };
};

// --------------------------------------------------------------- \\
// ---------------------- IMAGE TO BASE 64 ----------------------- \\
// --------------------------------------------------------------- \\

export const convertImgToBase64URL = url => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
            var canvas = document.createElement('CANVAS');
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0);
            resolve({ imgData: canvas.toDataURL('image/jpeg'), width: canvas.width, height: canvas.height });
            canvas = null; 
        };

        img.onerror = () => {
            reject('Image not found.');
        };

        img.src = url;
    });
};

// --------------------------------------------------------------- \\
// -------------------- GET LABEL FROM ARRAY  -------------------- \\
// --------------------------------------------------------------- \\

export const getLabelFromArray = (array, value) => {
    const item = array.find(item => item.value === value);
    if (item != null) {
        return item.label;
    }
    return '';
};
