import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

// Services
import { getFormattedDate, getTranslation, mobipassColor2 } from 'services/helpers';

// Components
import LearningOutcomeModal from 'components/mobipass/skillbase/learning-outcome-modal';

// Style
import style from 'style';

const styles = {
    td_learning: {
        padding: 10,
        textAlign: 'center',
        border: '1px solid lightgray',
        minWidth: 320,
        width: '50%',
    },
    td: {
        padding: 10,
        textAlign: 'center',
        border: '1px solid lightgray',
        minWidth: 160,
        width: '25%',
    },
};

// ----------------------------------------- PORTFOLIOS -------------------------------------------- \\
const Portfolios = ({ unit }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        portfolios: null,
        names: null,
        openLearningDetails: false,
        openEvaluationHistory: false,
    });

    const openEvaluationHistory = (unit, learning) => {
        const names = {
            unit: getTranslation('name', unit),
            learning: getTranslation('name', learning)
        };
        setState({ ...state, openEvaluationHistory: true, portfolios: learning.portfolios, names });
    };

    return (
        <>
            <table style={style.w100}>
                <thead>
                    <tr style={{ backgroundColor: mobipassColor2 }}>
                        <td style={{ ...styles.td_learning, textAlign: 'left' }}>
                            <span>{t('learning.outcomes')}</span>
                        </td>
                        <td style={styles.td}>
                            <span>{t('company')}</span>
                        </td>
                        <td style={styles.td}>
                            {unit.learning_outcomes[0].portfolios.findIndex(p => p.type === 'certification') !== -1 ? (
                                <span>{t('certification.date')}</span>
                            ) : (
                                <span>{t('validation.date')}</span>
                            )}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {unit.learning_outcomes.map(learning => {
                        const index = learning.portfolios.findIndex(p => p.type === 'certification');
                        return (
                            <tr key={learning.id}>
                                <td
                                    className="td"
                                    onClick={() => setState({ ...state, openLearningDetails: true, learning })}
                                    style={{ ...styles.td_learning, textAlign: 'left' }}
                                >
                                    <span>{getTranslation('name', learning)}</span>
                                </td>
                                {learning.portfolios.length > 0 ? (
                                    <>
                                        {index !== -1 ? (
                                            <>
                                                <td style={styles.td}>
                                                    <span>{learning.portfolios[index].evaluation.company.name}</span>
                                                </td>
                                                <td
                                                    className="td"
                                                    onClick={() => openEvaluationHistory(unit, learning)}
                                                    style={styles.td}
                                                >
                                                    <span>{getFormattedDate(learning.portfolios[index].evaluation.evaluation_date)}</span>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td style={styles.td}>
                                                    <span>{learning.portfolios[0].evaluation.company.name}</span>
                                                </td>
                                                <td
                                                    className="td"
                                                    onClick={() => openEvaluationHistory(unit, learning)}
                                                    style={styles.td}
                                                >
                                                    <span>{getFormattedDate(learning.portfolios[0].evaluation.evaluation_date)}</span>
                                                </td>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <td style={styles.td}></td>
                                        <td style={styles.td}></td>
                                    </>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* -------------------- LEARNING DETAILS -------------------- */}
            {state.openLearningDetails && (
                <LearningOutcomeModal
                    onClose={() => setState({ ...state, openLearningDetails: false })}
                    learning={state.learning}
                />
            )}
            {/* -------------------- EVALUATION HISTORY -------------------- */}
            {state.openEvaluationHistory && (
                <EvaluationHistory
                    onClose={() => setState({ ...state, openEvaluationHistory: false })}
                    portfolios={state.portfolios}
                    names={state.names}
                />
            )}
        </>
    );
};

// --------------------------------------- EVALUATION HISTORY --------------------------------------- \\
const EvaluationHistory = ({ onClose, portfolios, names }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullScreen>
            <DialogContent>
                <p className="dialog-title">
                    {t('historical')}
                </p>
                <p style={style.mb15}>
                    <b>{t('unit')} :</b> {names.unit}
                    <br />
                    <b>{t('learning.outcome')} :</b> {names.learning}
                </p>
                <table style={style.w100}>
                    <thead>
                        <tr style={{ backgroundColor: mobipassColor2 }}>
                            <td style={styles.td}><span>{t('date')}</span></td>
                            <td style={styles.td}><span>{t('name')}</span></td>
                            <td style={styles.td}><span>{t('company')}</span></td>
                            <td style={styles.td}><span>{t('assessor')}</span></td>
                            <td style={styles.td}><span>{t('informations.further')}</span></td>
                        </tr>
                    </thead>
                    <tbody>
                        {portfolios.map((portfolio, index) => {
                            if (portfolio.evaluation != null) {
                                return (
                                    <tr key={index}>
                                        <td style={styles.td}><span>{portfolio.evaluation.evaluation_date != null ? getFormattedDate(portfolio.evaluation.evaluation_date) : ''}</span></td>
                                        <td style={styles.td}><span>{portfolio.evaluation.name}</span></td>
                                        <td style={styles.td}><span>{portfolio.evaluation.company?.name}</span></td>
                                        <td style={styles.td}><span>{portfolio.evaluation.assessor?.full_name}</span></td>
                                        <td style={styles.td}><span>{portfolio.evaluation.comment}</span></td>
                                    </tr>
                                );
                            }
                            return <></>;
                        })}
                    </tbody>
                </table>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Portfolios;
