import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getCompanyList, getSkillbases, updateCertification, getCertification, getCompanyMembers, getSkillbase } from 'services/requests';
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CertificationFormPage1 from './certification-form/certification-form-page-1';
import CertificationFormPage2 from './certification-form/certification-form-page-2';
import CertificationFormPage3 from './certification-form/certification-form-page-3';
import CertificationFormPage4 from './certification-form/certification-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// --------------------------------------- UPDATE CERTIFICATION ---------------------------------------- \\
const UpdateCertification = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { certificationId } = useParams();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loadingCertification: true,
        loadingResources: false,
        errorResources: false,
        loading: false,
        page: 1,

        // Page 1
        certification_date: null,
        document: null,
        document_url: null,

        // Page 2
        authorizedRefetchAssessorCompanies: false,
        companies: [],
        company_manual_entry: false,
        company_id: null,
        company_name: '',
        assessors: [],
        assessor_manual_entry: false,
        assessor_id: null,
        assessor_name: '',

        // Page 3
        authorizedRefetchUserCompanies: false,
        user_company_id: null,
        users: [],
        user_id: null,

        // Page 4
        authorizedRefetchSkillbases: false,
        skillbases: [],
        skillbase_id: null,
        skillbase: null,
        units: [],
    });

    useEffect(() => {
        getCertification(certificationId).then(res => {
            if (res?.status === 200) {

                let certification = res.data.data.certification;

                setState({
                    ...state,
                    loadingCertification: false,
                    loadingResources: true,
                    certification_date: certification.certification_date,
                    document_url: certification.document,
                    company_manual_entry: certification.company_id ? false : true,
                    company_id: certification.company_id,
                    company_name: certification.company_name || '',
                    assessor_manual_entry: certification.assessor_id ? false : true,
                    assessor_id: certification.assessor_id,
                    assessor_name: certification.assessor_name || '',
                    user_company_id: certification.user?.company?.id,
                    user_id: certification.user?.id,
                    skillbase_id: certification.skillbase?.id,
                    units: certification.units ? certification.units.map(u => u.id) : [],
                });
            }
            else {
                console.log(res);
                setState({ ...state, loadingCertification: false, errorResources: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Get Resources
    useEffect(() => {
        if (state.loadingResources) {
            axios.all([
                getCompanyList(),
                getCompanyMembers(state.company_id || 1),
                getCompanyMembers(state.user_company_id || 1),
                getSkillbases(),
                getSkillbase(state.skillbase_id),
            ])
            .then(axios.spread(function (res_companies, res_assessors, res_learners, res_skillbases, res_skillbase) {
                if (res_companies?.status === 200 && res_assessors?.status === 200 && res_learners?.status === 200 && res_skillbases?.status === 200 && res_skillbase?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        assessors: res_assessors.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                        users: res_learners.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                        skillbase: res_skillbase.data.data.skillbase,
                    });
                }
                else {
                    console.log(res_companies + res_assessors + res_learners + res_skillbases + res_skillbase);
                    setState(state => ({ ...state, loadingResources: false, error: true })); 
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    // Handle Create Certification
    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('certification_date', moment(state.certification_date).format('YYYY-MM-DD'));
            formData.append('company_id', state.company_id != null ? state.company_id : '');
            formData.append('company_name', state.company_name);
            formData.append('assessor_id', state.assessor_id != null ? state.assessor_id : '');
            formData.append('assessor_name', state.assessor_name);
            formData.append('user_id', state.user_id);
            formData.append('skillbase_id', state.skillbase_id);
            formData.append('units', JSON.stringify(state.units));

            if (state.document) {
                formData.append('document', state.document);
            }

            updateCertification(certificationId, formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobipass/certification');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/mobipass/certification')} variant="contained">
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginTop: mediaMD ? 0 : 10 }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>{t('certification.edit')}</span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- FORM -------------------- */}
            {(state.loadingCertification || state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <CertificationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <CertificationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <CertificationFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 4 && (
                        <CertificationFormPage4 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default UpdateCertification;
