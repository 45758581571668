import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// ------------------------------ TRANSLATION FILES -------------------------------- \\
import translationDE from 'translations/de.translations.json';
import translationEN from 'translations/en.translations.json';
import translationES from 'translations/es.translations.json';
import translationFR from 'translations/fr.translations.json';
import translationIT from 'translations/it.translations.json';
import translationSL from 'translations/sl.translations.json';

// ---------------------------------- RESOURCES ------------------------------------ \\
const resources = {
    de: {
        translation: translationDE,
    },
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationES,
    },
    fr: {
        translation: translationFR,
    },
    it: {
        translation: translationIT,
    },
    sl: {
        translation: translationSL,
    },
};

// -------------------------------- I18NEXT CONFIG --------------------------------- \\
i18n.use(initReactI18next).init({
    debug: false,
    lng: 'fr',
    fallbackLng: 'en',
    resources,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
