import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Button, IconButton, Grid, ButtonGroup, Divider } from '@material-ui/core';
import {
    ChevronLeft as IconBack, ChevronRight as IconNext, CheckCircle as IconCheckCircle,
    Cancel as IconCancel, Check as IconCheck, Clear as IconClear,
} from '@material-ui/icons';

// Services
import { getTranslation, mobipassColor } from 'services/helpers';

// Components
import LearningOutcomeModal from 'components/mobipass/skillbase/learning-outcome-modal';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    table: {
        minWidth: '100%',
        width: '100%',
        fontSize: 14,
    },
    td: {
        textAlign: 'center',
        border: '1px solid lightgray',
        overflowWrap: 'break-word',
        minWidth: 150,
        width: 150,
        padding: '5px 0px',
    },
    tdX: {
        textAlign: 'left',
        border: '1px solid lightgray',
        overflowWrap: 'break-word',
        minWidth: 300,
        width: 300,
        padding: '5px 10px',
    },
    arrow: {
        padding: 6,
    },
};

// ---------------------------------------- LEARNINGS EVALUATION ---------------------------------------- \\
const LearningsEvaluation = ({ state, setState, solo }) => {
    const { t } = useTranslation();

    function checkPortfolio(portfolio) {
        let updatedPortolios = state.portfolios;
        updatedPortolios.find(p => p.id === portfolio.id).validated = !portfolio.validated;
        setState({ ...state, portfolios: updatedPortolios });
    }

    function checkLearning(learning, value) {
        let updatedPortolios = state.portfolios;
        updatedPortolios.filter(p => p.learning_outcome_id === learning.id).forEach(p => {
            p.validated = value;
        });
        setState({ ...state, portfolios: updatedPortolios });
    }

    function checkUser(user, value) {
        let updatedPortolios = state.portfolios;
        updatedPortolios.filter(p => p.user_id === user.id).forEach(p => {
            p.validated = value;
        });
        setState({ ...state, portfolios: updatedPortolios });
    }

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    if (state.loaded) {
        var users = state.users;
        var portfolios = state.portfolios;

        if (state.oneDisplayed) {
            users = users.filter((u, index) => index === state.index);
            portfolios = portfolios.filter(portfolio => portfolio.user_id === users[0].id);
        }
    }

    return (
        <>
            <Grid container style={{ backgroundColor: mobipassColor, height: solo ? 0 : 40 }} />
            {state.learnings.length > 0 ? (
                <div style={{ overflowX: 'auto' }}>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                {state.users.length > 1 ? (
                                    state.oneDisplayed ? (
                                        <td style={{ ...styles.td }} colSpan="1" rowSpan="2">
                                            <Button
                                                onClick={() => setState({ ...state, oneDisplayed: false })}
                                                variant="contained"
                                            >
                                                {t('show.all')}
                                            </Button>
                                        </td>
                                    ) : (
                                        <td style={{ ...styles.td }} colSpan="2" rowSpan="2">
                                            <Button
                                                onClick={() => setState({ ...state, oneDisplayed: true })}
                                                variant="contained"
                                            >
                                                {t('show.one')}
                                            </Button>
                                        </td>
                                    )
                                ) : (
                                    <td style={{ ...styles.td }} colSpan="1" rowSpan="2" />
                                )}
                                {users.map(user => (
                                    <td key={user.id} style={styles.td}>
                                        {state.oneDisplayed ? (
                                            <Grid container alignItems="center">
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        onClick={() => setState({ ...state, index: state.index -= 1 })}
                                                        disabled={state.index === 0}
                                                        style={styles.arrow}
                                                    >
                                                        <IconBack />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <span>
                                                        {user.last_name}
                                                        <br />
                                                        {user.first_name}
                                                    </span>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <IconButton
                                                        onClick={() => setState({ ...state, index: state.index += 1 })}
                                                        disabled={state.index + 1 === state.users.length}
                                                        style={styles.arrow}
                                                    >
                                                        <IconNext />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <span>
                                                {user.last_name}
                                                <br />
                                                {user.first_name}
                                            </span>
                                        )}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                {state.learnings.length > 1 && users.map(user => (
                                    <td key={user.id} style={styles.td}>
                                        <ButtonGroup size="small">
                                            <Button onClick={() => checkUser(user, true)}>
                                                <IconCheck />
                                            </Button>
                                            <Button onClick={() => checkUser(user, false)}>
                                                <IconClear />
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {state.learnings.map(learning => (
                                <tr key={learning.id}>
                                    <td
                                        style={styles.tdX}
                                        onClick={() => setState({ ...state, openLearningModal: true, learning })}
                                        className="td"
                                    >
                                        {getTranslation('name', learning)}
                                    </td>
                                    {(state.users.length > 1 && !state.oneDisplayed) && (
                                        <td style={styles.td}>
                                            <ButtonGroup size="small">
                                                <Button onClick={() => checkLearning(learning, true)}>
                                                    <IconCheck />
                                                </Button>
                                                <Button onClick={() => checkLearning(learning, false)}>
                                                    <IconClear />
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    )}
                                    {users.map(user => (
                                        portfolios.filter(portfolio => portfolio.learning_outcome_id === learning.id && portfolio.user_id === user.id).map(portfolio => (
                                            <td key={portfolio.id} style={styles.td}>
                                                <IconButton onClick={() => checkPortfolio(portfolio)} className="list-btn">
                                                    {portfolio.validated ? (
                                                        <IconCheckCircle style={{ color: 'green', fontSize: 30 }} />
                                                    ) : (
                                                        <IconCancel style={{ color: '#DA6236', fontSize: 30 }} />
                                                    )}
                                                </IconButton>
                                            </td>
                                        ))
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    <Grid container style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            {/* -------------------- LEARNING MODAL -------------------- */}
            {state.openLearningModal && (
                <LearningOutcomeModal
                    open={state.openLearningModal}
                    onClose={() => setState({ ...state, openLearningModal: false })}
                    learning={state.learning}
                />
            )}
        </>
    );
};

export default LearningsEvaluation;
