import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import Skillbase from './skillbase';
import SkillbaseList from 'components/common/skillbase-list';

// ---------------------------------------- ROUTER ----------------------------------------- \\
const SkillbasesRouter = () => {
    const { t } = useTranslation();

    return (
        <>
            <BreadcrumbsItem to="/mobipass/skillbases" className="breadcrumb-item">
                {t('skillbases')}
            </BreadcrumbsItem>
            <Switch>
                <Route exact path="/mobipass/skillbases">
                    <SkillbaseList />
                </Route>
                <Route exact path="/mobipass/skillbases/:skillbaseId">
                    <Skillbase />
                </Route>
                <Redirect from="*" to="/mobipass/skillbases" />
            </Switch>
        </>
    );
};

export default SkillbasesRouter;
