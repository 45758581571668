import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Button, Divider } from '@material-ui/core';

// Services
import { addSkillbase, importSkillbase } from 'services/requests';
import { initialLanguagesObject, servicesColor } from 'services/helpers';

// Components
import SkillbaseForm from './skillbase-form';
import Spinner from '../../../items/spinner';

// Style
import style from 'style';

// ---------------------------------------- CREATE SKILLBASE ---------------------------------------- \\
const CreateSkillbase = ({ onClose, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        sector_id: null,
        active: '0',
        names: initialLanguagesObject(),
        descriptions: initialLanguagesObject(),
        language_code: 'fr',
        csv_file: null,
    });

    function handleAddSkillbase() {
        if (state.sector_id == null) {
            enqueueSnackbar(t('sector.select'), { variant: 'warning' });
        }
        else if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {

            let file = document.getElementById('upload-csv-file').files[0];

            if (file) {
                let csv_file = new FormData();
                csv_file.append('excel', file);
                csv_file.append('language', state.language_code);
                setState({ ...state, loading: true, csv_file });
            }
            else {
                setState({ ...state, loading: true });
            }
        }
    }

    function handleImportSkillbase(skillbaseId) {

        let data = state.csv_file;
        data.append('skillbase_id', skillbaseId);

        importSkillbase(data).then(res => {
            if (res?.status === 200) {
                enqueueSnackbar(res.data.message, { variant: 'success' });
                refetch();
            }
            else {
                setState({ ...state, loading: false });
            }
        });
    }

    useEffect(() => {
        if (state.loading) {
            addSkillbase({
                sector_id: state.sector_id,
                active: state.active,
                name: state.names,
                description: state.descriptions,
            }).then(res => {
                if (res?.status === 200) {
    
                    enqueueSnackbar(res.data.message, { variant: 'success' });

                    if (state.csv_file) {
                        handleImportSkillbase(res.data.data.skillbase.id);
                    }
                    else {
                        refetch();
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('skillbase.add')}
                        </p>
                        <SkillbaseForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleAddSkillbase}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateSkillbase;
