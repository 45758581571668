import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid, IconButton, CircularProgress } from '@material-ui/core';
import { ChevronLeft as IconBack, ChevronRight as IconNext, SaveAlt as IconSave, CheckCircle as IconCheck, Cancel as IconCross } from '@material-ui/icons';

// Services
import { mobipassColor, getFormattedDate, getTranslation, softskillsColor } from 'services/helpers';
import { getEvaluation } from 'services/requests';

// Components
import EvaluationDetailsExport from './evaluation-details-export';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    border: {
        border: '1px solid lightgray',
    },
    table: {
        minWidth: '100%',
        width: '100%',
        fontSize: 14,
    },
    header: {
        textAlign: 'center',
        border: '1px solid lightgray',
        padding: 10,
        color: 'white',
    },
    td: {
        textAlign: 'center',
        border: '1px solid lightgray',
        overflowWrap: 'break-word',
        minWidth: 150,
        width: 150,
        padding: 5,
    },
    iconBtn: {
        padding: 6,
    },
};

// ------------------------------------- DETAILS EVALUATION --------------------------------------- \\
const DetailsEvaluation = ({ onClose, evaluation }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        displayOne: true,
        index: 0,
        evaluation: null,
        users: [],
        learnings: [],
        portfolios: [],
        soft_units: [],
        soft_portfolios: [],
        loadingExport: false,
    });

    useEffect(() => {
        getEvaluation(evaluation.id).then(res => {
            if (res?.status === 200) {
                const evaluation = res.data.data.evaluation;
                setState({
                    ...state,
                    loading: false,
                    evaluation,
                    users: evaluation.users,
                    learnings: evaluation.learning_outcomes,
                    portfolios: evaluation.portfolios,
                    soft_units: evaluation.soft_units,
                    soft_portfolios: evaluation.soft_portfolios,
                });
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluation]);

    useEffect(() => {
        if (state.loadingExport) {
            EvaluationDetailsExport(t, state.evaluation, state, setState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingExport]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('evaluation.details')}
                        </p>
                        <Divider />
                        {/* ---------------------- NAME ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('name')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.evaluation.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- COMPANY ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('company')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.evaluation.company?.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- DATE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('date')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{getFormattedDate(state.evaluation.evaluation_date)}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- INFOS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('informations.additional')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.evaluation.details}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- ASSESSOR ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('assessor')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.evaluation.assessor?.full_name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- SKILLBASE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('skillbase')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{getTranslation('name', state.evaluation.skillbase)}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- COMMENT ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobipassColor }}>
                                    {t('comment')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.evaluation.comment}</span>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" style={{ ...style.p10, ...styles.border }}>
                            <Grid item xs={2} container justifyContent="center">
                                <IconButton
                                    onClick={() => setState({ ...state, index: state.index -= 1 })}
                                    disabled={state.index === 0}
                                    style={styles.iconBtn}
                                >
                                    <IconBack />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8} container justifyContent="center" style={style.taC}>
                                <span>
                                    {state.users[state.index].last_name}
                                    <br />
                                    {state.users[state.index].first_name}
                                </span>
                            </Grid>
                            <Grid item xs={2} container justifyContent="center">
                                <IconButton
                                    onClick={() => setState({ ...state, index: state.index += 1 })}
                                    disabled={state.index + 1 === state.users.length}
                                    style={styles.iconBtn}
                                >
                                    <IconNext />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {/* ---------------------- PORTFOLIOS ---------------------- */}
                        {(state.portfolios != null && state.portfolios.length > 0) && (
                            <table style={styles.table}>
                                <thead>
                                    <tr>
                                        <td colSpan={2} style={{ ...styles.header, backgroundColor: mobipassColor }}>
                                            {t('learning.outcomes')}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.learnings.map(learning => (
                                        <tr key={learning.id}>
                                            <td style={styles.td}>
                                                <span>{getTranslation('name', learning)}</span>
                                            </td>
                                            <td style={styles.td}>
                                                {state.portfolios
                                                .find(p => p.learning_outcome_id === learning.id && p.user_id === state.users[state.index].id) 
                                                .validated ? (
                                                    <IconCheck style={{ color: 'green', fontSize: 26 }} />
                                                ) : (
                                                    <IconCross style={{ color: 'rgba(218, 98, 54, 1)', fontSize: 26 }} />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {/* ---------------------- SOFT PORTFOLIOS ---------------------- */}
                        {(state.soft_portfolios != null && state.soft_portfolios.length > 0) && (
                            <table style={styles.table}>
                                <thead>
                                    <tr>
                                        <td colSpan={2} style={{ ...styles.header, backgroundColor: softskillsColor }}>
                                            {t('softskills')}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.soft_units.map(soft_unit => {
                                        const soft_level_id = state.soft_portfolios.find(sp => 
                                            sp.soft_unit_id === soft_unit.id
                                            && sp.user_id === state.users[state.index].id
                                        ).soft_level_id;
                                        const soft_level = soft_unit.softskill.soft_levels.find(sl =>
                                            sl.id === soft_level_id
                                        );
                                        return (
                                            <tr key={soft_unit.id}>
                                                <td style={styles.td}>
                                                    {getTranslation('name', soft_unit.softskill)}
                                                    <br />
                                                    {t('level')}&nbsp;{soft_unit.soft_level.level}&nbsp;{t('required')}
                                                </td>
                                                {soft_level ? (
                                                    <td style={styles.td}>
                                                        {t('level')}&nbsp;{soft_level.level}
                                                        {soft_level.level >= soft_unit.soft_level.level ? (
                                                            <IconCheck style={{ color: 'green', fontSize: 20, ...style.ml5 }} />
                                                        ) : (
                                                            <IconCross style={{ color: 'rgba(218, 98, 54, 1)', fontSize: 20, ...style.ml5 }} />
                                                        )}
                                                    </td>
                                                ) : (
                                                    <td style={styles.td}>
                                                        {t('not.evaluated')}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('close')}
                        </Button>
                        <Button
                            onClick={() => setState({ ...state, loadingExport: true })}
                            disabled={state.loading || state.loadingExport}
                            variant="contained" color="primary"
                        >
                            PDF
                            {state.loadingExport ? (
                                <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                            ) : (
                                <IconSave style={{ marginLeft: 5, fontSize: 16 }} />
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default DetailsEvaluation;
