import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getSoftSkill } from 'services/requests';
import { getTranslation, softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    container: {
        minWidth: 900,
        border: '1px solid lightgray',
    },
    borderRight: {
        borderRightColor: 'lightgray',
        borderRightStyle: 'solid',
        borderRightWidth: 1,
    },
    header: {
        backgroundColor: softskillsColor,
        padding: 10,
        color: 'white',
        textAlign: 'center',
    },
    softLevel: {
        padding: 10,
        minHeight: 100,
    },
    tdLevel: {
        border: '1px solid lightgray',
        wordBreak: 'break-word',
        width: '25%',
        maxWidth: '25%',
    },
    case: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    p: {
        overflow: 'auto',
        height: 84,
        marginBottom: 0,
    },
};

// ---------------------------------------- SOFT SKILL DETAILS ------------------------------------------- \\
const SoftSkillDetails = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        softskill: null,
    });

    useEffect(() => {
        if (state.loading) {
            getSoftSkill(match.params.softskillId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, softskill: res.data.data.softskill });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push('/mobipass/softskills/skillbase')}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    style={style.taC}
                >
                    {state.softskill && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {getTranslation('name', state.softskill)}&nbsp;
                        </span>
                    )}
                    {(state.softskill && state.softskill.easy_to_read) && (
                        <>
                            <br />
                            <span>&nbsp;{state.softskill.easy_to_read}</span>
                        </>
                    )}
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- DEFINITION -------------------- */}
            <div className="simple-container" style={{ marginTop: 24 }}>
                {(state.softskill && state.softskill.definition) && (
                    <Grid>
                        <span style={style.fwB}>{t('definition')}</span>
                        <br />
                        <span>{state.softskill.definition}</span>
                    </Grid>
                )}
            </div>
            {/* -------------------- SOFT SKILL -------------------- */}
            <div className="full-container" style={{ padding: 24 }}>
                <div style={{ overflow: 'auto' }}>
                    <SoftLevelsAndBehaviors
                        loading={state.loading}
                        error={state.error}
                        softskill={state.softskill}
                    />
                </div>
            </div>
        </>
    );
};

// -------------------------------------- SOFT LEVElS & BEHAVIORS ---------------------------------------- \\
const SoftLevelsAndBehaviors = ({ loading, error, softskill }) => {
    const { t } = useTranslation();
    const [trArray, setTrArray] = useState([]);

    useEffect(() => {
        if (softskill) {
            let tr_number = 0;
            for (let i = 0; i < 4; i += 1) {
                const behaviors_number = softskill.soft_levels[i].behaviors.length
                if (tr_number < behaviors_number) {
                    tr_number = behaviors_number;
                }
            }
            setTrArray(Array.apply(null, { length: tr_number }).map(Number.call, Number));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softskill]);

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            {/* ---------------------- SOFT LEVELS ---------------------- */}
            <Grid container style={styles.container}>
                {softskill.soft_levels.map(soft_level => (
                    <Grid
                        key={soft_level.id}
                        item xs={3}
                        style={soft_level.level !== 4 ? styles.borderRight : null}
                    >
                        <div style={styles.header}>
                            <span>{t('level')} {soft_level.level}</span>
                            <br />
                            <span>
                                {soft_level.level === 1 && t('remember.and.understand')}
                                {soft_level.level === 2 && t('apply')}
                                {soft_level.level === 3 && t('analyse.and.evaluate')}
                                {soft_level.level === 4 && t('create')}
                            </span>
                        </div>
                        <div style={styles.softLevel}>
                            <span>{getTranslation('definition', soft_level)}</span>
                        </div>
                    </Grid>
                ))}
            </Grid>
            {/* ---------------------- BEHAVIORS ---------------------- */}
            <div style={{ minWidth: 900 }}>
                {/* ---------------------- BEHAVIORS ---------------------- */}
                <Grid container justifyContent="center" style={{ backgroundColor: softskillsColor, ...style.p10 }}>
                    <span style={style.cW}>{t('observable.behavior')}</span>
                </Grid>
                <table style={style.w100}>
                    <tbody>
                        {trArray.length > 0 ? (
                            trArray.map(tr => (
                                <tr key={tr}>
                                    {[0, 1, 2, 3].map(index => {
                                        const behavior = softskill.soft_levels[index].behaviors[tr];
                                        return (
                                            <td key={index} style={styles.tdLevel}>
                                                {behavior && (
                                                    <div style={styles.case}>
                                                        <div style={style.f1}>
                                                            <p style={styles.p}>
                                                                {behavior.position}. {getTranslation('definition', behavior)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td style={{ ...style.p10, border: '1px solid lightgray' }}>
                                    <span>{t('none')}</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SoftSkillDetails;
