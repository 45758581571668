import React, { Fragment, useState, useEffect } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Button, Grid, IconButton } from '@material-ui/core';
import { Delete as IconDelete, Create as IconEdit, Search as IconSearch, Cancel as IconCancel, AccountBalance as IconCompanies } from '@material-ui/icons';

// Services
import { getClients } from 'services/requests';
import useMediaQueries from 'services/media';
import { servicesColor } from 'services/helpers';

// Components
import CreateClient from './client-create';
import UpdateClient from './client-update';
import ExportClient from './client-export';
import { DeleteClient, AssociateCompaniesToClient } from './client-modals';
import ClientDetails from './client-details';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// --------------------------------------- ROUTER ----------------------------------------- \\
const ClientsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <Clients />
            </Route>
            <Route path={`${match.path}/:clientId`}>
                <ClientDetails />
            </Route>
        </Switch>
    );
};

// --------------------------------------- CLIENTS ---------------------------------------- \\
const Clients = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 50,
        total_count: 0,
        name: '',
        address: '',
        contact: '',
        openCreateClient: false,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            name: '',
            address: '',
            contact: '',
        });
    };

    useEffect(() => {
        if (state.loading) {
            getClients(
                state.offset,
                state.limit,
                state.name,
                state.address,
                state.contact,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        data: res.data.data.clients,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('clients')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management/billing')} variant="contained">
                    {t('previous')}
                </Button>                    
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
                <Button
                    onClick={() => setState({ ...state, openCreateClient: true })}
                    variant="contained"
                >
                    {t('add')}
                </Button>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('bill.address')}</span>
                            </Grid>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('contact')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('clients')}</span>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}  
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('bill.address')}
                                value={state.address}
                                onChange={e => setState({ ...state, address: e.target.value })}
                                onKeyPress={onSearch}  
                            />
                        </Grid>
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('contact')}
                                value={state.contact}
                                onChange={e => setState({ ...state, contact: e.target.value })}
                                onKeyPress={onSearch}  
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportClient data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- CLIENT LIST -------------------- */}
                <ClientList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* -------------------- CREATE CLIENT -------------------- */}
            {state.openCreateClient && (
                <CreateClient
                    onClose={() => setState({ ...state, openCreateClient: false })}
                    refetch={() => setState({ ...state, openCreateClient: false, loading: true })}
                />
            )}
        </>
    );
};

// ------------------------------------- CLIENT LIST -------------------------------------- \\
const ClientList = ({ data, loading, error, refetch }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation();

    const [state, setState] = useState({
        client: null,
        openAssociateCompanies: false,
        openUpdateClient: false,
        openDeleteClient: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    function handleOpenModal(e, open, client) {
        e.stopPropagation();
        setState({ ...state, [open]: true, client });
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {data.length > 0 ? (
                    data.map(client => (
                        <Fragment key={client.id}>
                            <Grid
                                onClick={() => history.push(`${match.path}/${client.id}`)}
                                container
                                alignItems="center"
                                className="list-item-hover"
                            >
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{client.name}</span>
                                </Grid>
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{client.address}</span>
                                </Grid>
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{client.contact}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('companies.assign')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openAssociateCompanies', client)}
                                                className="list-btn"
                                            >
                                                <IconCompanies />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openUpdateClient', client)}
                                                className="list-btn"
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openDeleteClient', client)}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- ASSOCIATE COMPANIES TO CLIENT -------------------- */}
            {state.openAssociateCompanies && (
                <AssociateCompaniesToClient
                    onClose={() => setState({ ...state, openAssociateCompanies: false })}
                    refetch={() => handleRefetch('openAssociateCompanies')}
                    client={state.client}
                />
            )}
            {/* -------------------- UPDATE CLIENT -------------------- */}
            {state.openUpdateClient && (
                <UpdateClient
                    onClose={() => setState({ ...state, openUpdateClient: false })}
                    refetch={() => handleRefetch('openUpdateClient')}
                    client={state.client}
                />
            )}
            {/* -------------------- DELETE CLIENT -------------------- */}
            {state.openDeleteClient && (
                <DeleteClient
                    onClose={() => setState({ ...state, openDeleteClient: false })}
                    refetch={() => handleRefetch('openDeleteClient')}
                    client={state.client}
                />
            )}
        </>
    );
};

export default ClientsRouter;
