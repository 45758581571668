import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { createSoftSkill, updateSoftSkill, deleteSoftSkill } from 'services/requests';
import { initialLanguagesObject, getTranslation } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';
import style from 'style';

// ------------------------------------- CREATE SOFT SKILL ------------------------------------- \\
export const CreateSoftSkill = ({ onClose, refetch, category }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: initialLanguagesObject(),
        easy_to_read: initialLanguagesObject(),
        definition: initialLanguagesObject(),
        actionType: 'CREATE',
    });

    function handleCreateSoftSkill() {
        if (Object.values(state.name).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createSoftSkill(category.id, {
                name: state.name,
                easy_to_read: state.easy_to_read,
                definition: state.definition,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <FormSoftSkill state={state} setState={setState} />
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleCreateSoftSkill} variant="contained">
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------- UPDATE SOFT SKILL ------------------------------------- \\
export const UpdateSoftSkill = ({ onClose, refetch, softskill }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: initialLanguagesObject(),
        easy_to_read: initialLanguagesObject(),
        definition: initialLanguagesObject(),
        actionType: 'UPDATE',
        position: softskill.position,
    });

    function handleUpdateSoftSkill() {
        if (Object.values(state.name).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (softskill) {
            let name = state.name;
            Object.entries(softskill.translations).map(([key, value]) => {
                return name[key] = value.name;
            });
            let easy_to_read = state.easy_to_read;
            Object.entries(softskill.translations).map(([key, value]) => {
                return easy_to_read[key] = value.easy_to_read || '';
            });
            let definition = state.definition;
            Object.entries(softskill.translations).map(([key, value]) => {
                return definition[key] = value.definition || '';
            });
            setState({ ...state, name, easy_to_read, definition });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softskill]);

    useEffect(() => {
        if (state.loading) {
            updateSoftSkill(softskill.id, {
                name: state.name,
                easy_to_read: state.easy_to_read,
                definition: state.definition,
                position: state.position,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <FormSoftSkill state={state} setState={setState} />
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleUpdateSoftSkill} variant="contained">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------- DELETE SOFT SKILL ------------------------------------- \\
export const DeleteSoftSkill = ({ onClose, refetch, softskill }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteSoftSkill(softskill.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.pb20, ...style.taC }}>
                        <p className="dialog-title">
                            {getTranslation('name', softskill)}
                        </p>
                        <span>{t('softskill.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------- FORM SOFT SKILL ------------------------------------- \\
const FormSoftSkill = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleName(event, code) {
        let name = state.name;
        name[code] = event.target.value;
        setState({ ...state, name });
    }

    function handleEasyToRead(event, code) {
        let easy_to_read = state.easy_to_read;
        easy_to_read[code] = event.target.value;
        setState({ ...state, easy_to_read });
    }

    function handleDefinition(event, code) {
        let definition = state.definition;
        definition[code] = event.target.value;
        setState({ ...state, definition });
    }

    return (
        <DialogContent style={style.pb20}>
            <p className="dialog-title">
                {state.actionType === 'CREATE' ? (
                    t('softskill.add')
                ) : (
                    t('softskill.edit')
                )}
            </p>
            {state.actionType === 'UPDATE' ? (
                <>
                    <Divider style={style.m15_0} />
                    {/* -------------------- POSITION -------------------- */}
                    <InputTextGrid
                        placeholder={t('position')}
                        value={state.position}
                        onChange={e => setState({ ...state, position: e.target.value })}
                        type="number"
                    />
                    <Divider style={style.m15_0} />
                </>
            ) : (
                <Divider style={style.mb15} />
            )}
            {/* -------------------- NAME -------------------- */}
            <p style={{ ...style.taC, ...style.fwB }}>
                {t('name')}
            </p>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.name[language.code]}
                    onChange={(e) => handleName(e, language.code)}
                />
            ))}
            <Divider style={style.m15_0} />
            {/* -------------------- EASY TO READ -------------------- */}
            <p style={{ ...style.taC, ...style.fwB }}>
                {t('easy.to.read')}
            </p>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.easy_to_read[language.code]}
                    onChange={(e) => handleEasyToRead(e, language.code)}
                    multiline
                />
            ))}
            <Divider style={style.m15_0} />
            {/* -------------------- DEFINITION -------------------- */}
            <p style={{ ...style.taC, ...style.fwB }}>
                {t('definition')}
            </p>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.definition[language.code]}
                    onChange={(e) => handleDefinition(e, language.code)}
                    multiline
                />
            ))}
        </DialogContent>
    );
};
