import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getNewsById, updateNews } from 'services/requests';
import { initialLanguagesObject } from 'services/helpers';
import { languages } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import NewsFormPage1 from './news-form/news-form-page-1';
import NewsFormPage2 from './news-form/news-form-page-2';
import NewsFormPage3 from './news-form/news-form-page-3';
import NewsFormPage4 from './news-form/news-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- UPDATE NEWS ---------------------------------------- \\
const UpdateNews = () => {
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const match = useRouteMatch();
    const history = useHistory();

    const initialDetails = () => {
        let details = {};
        languages.forEach(language => {
            details[language.code] = EditorState.createEmpty();
        });
        return details;
    };

    const [state, setState] = useState({
        actionType: 'EDIT',
        page: 1,
        loading_ressources: true,
        loading: false,
        error: false,

        // Page 1
        titles: initialLanguagesObject(),
        disabled: [],

        // Page 2
        descriptions: initialLanguagesObject(),

        // Page 3
        details: initialDetails(),
        
        // Page 4
        publication_date: null,
        photo: null,
        document: null,
        status: '0',
        remove_photo: false,
        remove_document: false,
    });

    useEffect(() => {
        if (state.loading_ressources) {
            getNewsById(match.params.newsId).then(res => {
                if (res?.status === 200) {

                    const news = res.data.data.news;
                    let titles = state.titles;
                    let descriptions = state.descriptions;
                    let details = state.details;
                    
                    Object.entries(res.data.data.news.translations).forEach(([key, value]) => {
                        titles[key] = value.title;
                        descriptions[key] = value.description; 
                        
                        if (value.details) {
                            let content = convertFromRaw(JSON.parse(value.details));
                            details[key] = EditorState.createWithContent(content);
                        }
                    });

                    setState({
                        ...state,
                        loading_ressources: false,
                        titles,
                        descriptions,
                        details,
                        publication_date: news.publication_date,
                        status: news.status.toString(),
                        photo: news.photo,
                        document: news.document,
                    });
                }
            })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading_ressources])

    useEffect(() => {
        if (state.loading) {

            const detailsContent = {};
            Object.entries(state.details).forEach(([key, value]) => {
                detailsContent[key] = JSON.stringify( convertToRaw(value.getCurrentContent()) )
            });

            const formData = new FormData();
            formData.append('title', JSON.stringify(state.titles));
            formData.append('description', JSON.stringify(state.descriptions));
            formData.append('details', JSON.stringify(detailsContent));
            formData.append('publication_date', moment(state.publication_date).format('YYYY-MM-DD'));
            formData.append('status', state.status);
            formData.append('remove_photo', state.remove_photo ? '1' : '0');
            formData.append('remove_document', state.remove_document ? '1' : '0');

            if (state.document) {
                formData.append('document', state.document);
            }
            if (state.photo) {
                formData.append('photo', state.photo);
            }

            updateNews(match.params.newsId, formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/management/news');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    if (state.loading_ressources || state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- FORM -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/management/news`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('news.edit')}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            {state.page === 1 && (
                <NewsFormPage1 state={state} setState={setState} />
            )}
            {state.page === 2 && (
                <NewsFormPage2 state={state} setState={setState} />
            )}
            {state.page === 3 && (
                <NewsFormPage3 state={state} setState={setState} />
            )}
            {state.page === 4 && (
                <NewsFormPage4 state={state} setState={setState} />
            )}
        </>
    );
};

export default UpdateNews;
