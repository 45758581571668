import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateKnowledge from './create-knowledge';
import UpdateKnowledge from './update-knowledge';
import DeleteKnowledge from './delete-knowledge';

// Style
import style from 'style';

// ---------------------------------------- KNOWLEDGES ---------------------------------------- \\
const Knowledges = ({ unitId, learning }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        knowledges: learning?.knowledges || [],
        knowledge: null,
        openCreateKnowledge: false,
        openUpdateKnowledge: false,
        openDeleteKnowledge: false,
    });

    function handleOpenModal(event, open, knowledge) {
        event.stopPropagation();
        setState({ ...state, [open]: true, knowledge });
    }

    return (
        <>
            {/* ---------------------- LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: '#e0e0e0', marginTop: 24 }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={8} style={style.p10}>
                            <span>{t('knowledge')}</span>
                        </Grid>
                        <Grid item xs={4} style={{ ...style.p10, ...style.taC }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('knowledges')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- KNOWLEDGE LIST ---------------------- */}
            {state.knowledges.length > 0 ? (
                state.knowledges.map(knowledge => (
                    <Fragment key={knowledge.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={8} style={{ padding: 10 }}>
                                <span>
                                    {getTranslation('name', knowledge)}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: 10, textAlign: 'center' }}>
                                <IconButton
                                    onClick={e => handleOpenModal(e, 'openUpdateKnowledge', knowledge)}
                                    style={{ padding: 6 }}
                                >
                                    <IconEdit />
                                </IconButton>
                                <IconButton
                                    onClick={e => handleOpenModal(e, 'openDeleteKnowledge', knowledge)}
                                    style={{ padding: 6 }}
                                >
                                    <IconDelete />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateKnowledge: true })}
                    className="fab"
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('knowledge.add')}
                </span>
            </div>
            {/* ---------------------- CREATE KNOWLEDGE ---------------------- */}
            {state.openCreateKnowledge && (
                <CreateKnowledge
                    onClose={() => setState({ ...state, openCreateKnowledge: false })}
                    refetch={knowledges => setState({ ...state, openCreateKnowledge: false, knowledges })}
                    knowledges={state.knowledges}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
            {/* ---------------------- UPDATE KNOWLEDGE ---------------------- */}
            {state.openUpdateKnowledge && (
                <UpdateKnowledge
                    onClose={() => setState({ ...state, openUpdateKnowledge: false })}
                    refetch={knowledges => setState({ ...state, openUpdateKnowledge: false, knowledges })}
                    knowledges={state.knowledges}
                    knowledge={state.knowledge}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
            {/* ---------------------- DELETE KNOWLEDGE ---------------------- */}
            {state.openDeleteKnowledge && (
                <DeleteKnowledge
                    onClose={() => setState({ ...state, openDeleteKnowledge: false })}
                    refetch={knowledges => setState({ ...state, openDeleteKnowledge: false, knowledges })}
                    knowledges={state.knowledges}
                    knowledge={state.knowledge}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
        </>
    );
};

export default Knowledges;
