import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { getSoftPortfolios } from 'services/requests';
import { getTranslation, softskillsColor, softskillsColor2 } from 'services/helpers';
import { getStoredUser } from 'services/storage';

// Components
import ChartPolarArea from 'components/items/chart-polar-area';
import SoftCertificate from './soft-certificate';
import SoftPasseport from './soft-passeport';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    soft_category: {
        border: '1px solid lightgray',
        marginTop: 24,
    },
    export_border: {
        border: '1px solid lightgray',
        padding: 10,
    },
    button: {
        textTransform: 'none',
        color: softskillsColor,
        borderColor: softskillsColor,
    },
};

// ------------------------------------------ SOFT SKILLS ------------------------------------------ \\
const Softskills = ({ user }) => {
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        soft_categories: [],
        openSoftCertificate: false,
    });

    useEffect(() => {
        getSoftPortfolios(user.id).then(res => {
            if (res?.status === 200) {
                let soft_categories = res.data.data.soft_categories;

                if (ROLE_ID === 5) {
                    soft_categories = soft_categories.filter(c => c.softskills.some(s => s.evaluation > 0));
                    soft_categories.forEach(c => { c.softskills = c.softskills.filter(s => s.evaluation > 0) });
                }

                setState({ ...state, loading: false, soft_categories });  
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* -------------------- LIST HEADERS -------------------- */}
            <Grid container justifyContent="center" style={{ ...style.p10, backgroundColor: softskillsColor }}>
                <span style={style.cW}>
                    {ROLE_ID < 5 ? t('softskills.positioned.and.validated') : t('softskills.validated.activity')}
                </span>
            </Grid>
            {ROLE_ID === 5 ? (
                <ChartSoftCategoriesLearner
                    user={user}
                    state={state}
                    setState={setState}
                />
            ) : (
                <ChartSoftCategories
                    user={user}
                    state={state}
                    setState={setState}
                />
            )}
        </>
    );
};

// -------------------------------------- CHART SOFT CATEGORIES ------------------------------------ \\
const ChartSoftCategories = ({ user, state, setState }) => {
    const { t } = useTranslation();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid container justifyContent="flex-end" style={styles.export_border}>
                <div>
                    <Button
                        onClick={() => setState({ ...state, openSoftCertificate: true })}
                        variant="outlined" color="primary"
                        style={styles.button}
                    >
                        {t('certificate')}
                    </Button>
                    <SoftPasseport
                        soft_categories={state.soft_categories}
                        user={user}
                    />
                </div>
            </Grid>
            {state.soft_categories.map(soft_category => (
                <div key={soft_category.id} style={styles.soft_category}>
                    <Grid container justifyContent="center" style={{ ...style.p10, backgroundColor: softskillsColor2 }}>
                        <span style={{ ...style.fwB, ...style.ttU }}>
                            {getTranslation('name', soft_category)}
                        </span>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid container justifyContent="center" item xs={12} md={6} style={{ flexDirection: 'column' }}>
                            <div style={{ ...style.taC, ...style.mt10 }}>
                                <span>{t('positioning')}</span>
                            </div>
                            <ChartPolarArea
                                labels={soft_category.softskills.map(softskill => getTranslation('name', softskill))}
                                data={soft_category.softskills.map(softskill => softskill.positioning || 0)}
                            />
                        </Grid>
                        <Grid container justifyContent="center" item xs={12} md={6} style={{ flexDirection: 'column' }}>
                            <div style={{ ...style.taC, ...style.mt10 }}>
                                <span>{t('evaluation')}</span>
                            </div>
                            <ChartPolarArea
                                labels={soft_category.softskills.map(softskill => getTranslation('name', softskill))}
                                data={soft_category.softskills.map(softskill => softskill.evaluation || 0)}
                            />
                        </Grid>
                    </Grid>
                </div>
            ))}
            {/* -------------------- CERTIFICATE -------------------- */}
            {state.openSoftCertificate && (
                <SoftCertificate
                    onClose={() => setState({ ...state, openSoftCertificate: false })}
                    userId={user.id}
                />
            )}
            {/* -------------------- PASSEPORT -------------------- */}
            {state.openSoftPasseport && (
                <SoftPasseport
                    onClose={() => setState({ ...state, openSoftPasseport: false })}
                    soft_categories={state.soft_categories}
                    user={user.id}
                />
            )}
        </>
    );
};

// ----------------------------------- CHART SOFT CATEGORIES LEARNER ------------------------------- \\
const ChartSoftCategoriesLearner = ({ user, state, setState }) => {

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid container justifyContent="flex-end" style={styles.export_border}>
                <SoftPasseport
                    soft_categories={state.soft_categories}
                    user={user}
                />
            </Grid>
            {state.soft_categories.map(soft_category => (
                <div key={soft_category.id} style={styles.soft_category}>
                    <Grid
                        container justifyContent="center"
                        style={{ ...style.p10, backgroundColor: softskillsColor2 }}
                    >
                        <span style={{ ...style.fwB, ...style.ttU }}>
                            {getTranslation('name', soft_category)}
                        </span>
                    </Grid>
                    <ChartPolarArea
                        labels={soft_category.softskills.map(softskill => getTranslation('name', softskill))}
                        data={soft_category.softskills.map(softskill => softskill.evaluation)}
                    />
                </div>
            ))}
            {/* -------------------- PASSEPORT -------------------- */}
            {state.openSoftPasseport && (
                <SoftPasseport
                    onClose={() => setState({ ...state, openSoftPasseport: false })}
                    soft_categories={state.soft_categories}
                    user={user}
                />
            )}
        </>
    );
};

export default Softskills;
