import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { createCleaEvaluation, getCleaDomains, getCleaValidatedCompetences, getCompanyList } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import CleaEvaluationFormPage1 from './clea-evaluation-form/clea-evaluation-form-page-1';
import CleaEvaluationFormPage2 from './clea-evaluation-form/clea-evaluation-form-page-2';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';
import moment from 'moment';

// ---------------------------------------- CLEA EVALUATION CREATE ---------------------------------------- \\
const CleaEvaluationCreate = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { learnerId, evaluationType } = useParams();

    const [state, setState] = useState({
        loadingRessources: true,
        errorRessources: false,
        loading: false,
        page: 1,
        openHelp: false,
        
        // Page 1
        assessor: '',
        companies: [],
        company_id: null,
        evaluation_date: null,
        evaluation_type: evaluationType || null,
        uploaded_certificate: null,
        
        // Page 2
        clea_domains: [],
        expanded: null,
        validated_competences: [],
        clea_competences: [],
    });
    
    useEffect(() => {
        if (state.loadingRessources) {
            axios.all([getCompanyList(), getCleaDomains(), getCleaValidatedCompetences(learnerId)])
            .then(axios.spread(function (res_companies, res_clea_domains, res_competences) {
                if (res_companies?.status === 200 && res_clea_domains?.status === 200 && res_competences?.status === 200) {
                    let clea_competences = res_competences.data.data.clea_competences.map(c => ({ id: c.id, locked: c.locked }));
                    setState({
                        ...state,
                        loadingRessources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        clea_domains: res_clea_domains.data.data.clea_domains,
                        clea_competences: clea_competences,
                        validated_competences: [...new Set(clea_competences)],
                    });
                }
                else {
                    console.log(res_companies, res_clea_domains, res_competences);
                    setState({ ...state, loadingRessources: false, error: true });
                }
            })); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingRessources]);
    
    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('user_id', learnerId);
            formData.append('assessor', state.assessor);
            formData.append('company_id', state.company_id);
            formData.append('evaluation_date', moment(state.evaluation_date).format('YYYY-MM-DD'));
            formData.append('evaluation_type', state.evaluation_type);
            formData.append('clea_competence_ids', JSON.stringify(state.clea_competences.map(c => c.id)));

            if (state.uploaded_certificate != null) {
                formData.append('uploaded_certificate', state.uploaded_certificate);
            }
            
            createCleaEvaluation(formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/clea/course/${learnerId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/clea/course/${learnerId}`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('clea.add.evaluation')}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- FORM -------------------- */}
            {(state.loadingRessources || state.loading) ? (
                <Spinner />
            ) : state.errorRessources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <CleaEvaluationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <CleaEvaluationFormPage2 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CleaEvaluationCreate;
