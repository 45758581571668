import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Divider, MenuItem, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

// Services
import { getSectors } from 'services/requests';
import { getTranslation } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import SelectOutlined from 'components/items/select-outlined';
import InputTextGrid from 'components/items/input-text-grid';
import InputFile from 'components/items/input-file';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// --------------------------------------- SKILLBASE FORM ---------------------------------------- \\
const SkillbaseForm = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleDescriptions(event, code) {
        let descriptions = state.descriptions;
        descriptions[code] = event.target.value;
        setState({ ...state, descriptions });
    }

    return (
        <>
            {/* -------------------- SECTOR -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('sector')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectSector
                        sectorId={state.sector_id}
                        setSectorId={sector_id => setState({ ...state, sector_id })}
                    />
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- ACTIVE -------------------- */}
            <Grid container alignItems="center" style={style.m5}>
                <Grid item xs={4}>
                    <b className="input-title">{t('active')}</b>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        row
                        value={state.active}
                        onChange={e => setState({ ...state, active: e.target.value })}
                    >
                        <FormControlLabel
                            value="1"
                            label={t('yes')}
                            control={<Radio color="primary" />}
                        />
                        <FormControlLabel
                            value="0"
                            label={t('no')}
                            control={<Radio />}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- FROM CSV -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('skillbase.create.from.csv')}</span>
            </div>
            <Grid container alignItems="center" style={style.mb10}>
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('file.language')}</b>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.language_code}
                        onChange={e => setState({ ...state, language_code: e.target.value })}
                    >
                        {languages.map(language => (
                            <MenuItem key={language.id} value={language.code}>
                                <span>{t(language.label)}</span>
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('file.csv')}</b>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputFile inputId="csv-file" />
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- NAME -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('skillbase.name')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.names[language.code]}
                    onChange={e => handleNames(e, language.code)}
                />
            ))}
            <Divider style={style.m15_0} />
            {/* -------------------- DESCRIPTION -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('skillbase.description')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.descriptions[language.code]}
                    onChange={e => handleDescriptions(e, language.code)}
                />
            ))}
        </>
    );
};

// ---------------------------------------- SELECT SECTOR ---------------------------------------- \\
const SelectSector = ({ sectorId, setSectorId }) => {
    const { t } = useTranslation(); 
    const [sectors, setSectors] = useState([]);

    useEffect(() => {
        getSectors().then(res => {
            if (res?.status === 200) {
                setSectors(res.data.data.sectors.map(s => ({ value: s.id, label: getTranslation('name', s) })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <Select
            placeholder={t('sector')}
            value={sectorId ? sectors.find(s => s.value === sectorId) : null}
            onChange={sector => setSectorId(sector ? sector.value : null)}
            options={sectors}
        />
    );
};

export default SkillbaseForm;
