import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { editCompany, editCompanyLogo, getCompany } from 'services/requests';
import { isValidEmail, fileUrl, servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CompanyForm from './company-form';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// ---------------------------------------- COMPANY UPDATE ---------------------------------------- \\
const CompanyUpdate = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { companyId } = useParams();

    const [state, setState] = useState({
        loadingResources: true,
        errorResources: false,
        loading: false,
        name: '',
        address: '',
        zip_code: '',
        city: '',
        legal_status: '',
        leader: '',
        creation_date: null,
        email: '',
        phone: '',
        website: '',
        activities: '',
        approval: '',
        employees: '',
        description: '',
        country_id: null,
        photo: null,
        footer: '',
        has_softskills_access: '0',
        has_clea_access: '0',
    });

    const onUpdateCompany = () => {
        if (state.name === '') {
            enqueueSnackbar(t('company.enter.name'), { variant: 'warning' });
        }
        else if (state.country_id == null) {
            enqueueSnackbar(t('select.country'), { variant: 'warning' });
        }
        else if (state.email !== '' && !isValidEmail(state.email)) {
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-company').files[0];
            if (file) {
                const photo = new FormData();
                photo.append('photo', file);
                setState({ ...state, photo, loading: true });
            }
            else {
                setState({ ...state, photo: null, loading: true });
            }
        }
    };

    useEffect(() => {
        getCompany(companyId).then(res => {
            if (res?.status === 200) {
                let company = res.data.data.company;
                setState({
                    loadingResources: false,
                    name: company.name,
                    address: company.address || '',
                    zip_code: company.zip_code || '',
                    city: company.city || '',
                    legal_status: company.legal_status || '',
                    leader: company.leader || '',
                    creation_date: company.creation_date || null,
                    email: company.email || '',
                    phone: company.phone || '',
                    website: company.website || '',
                    activities: company.activities || '',
                    approval: company.approval || '',
                    employees: company.employees || '',
                    description: company.description || '',
                    country_id: company.country_id || null,
                    photo: company.photo || null,
                    footer: company.footer || '',
                    has_softskills_access: company.has_softskills_access?.toString() || '0',
                    has_clea_access: company.has_clea_access?.toString() || '0',
                });
            }
            else {
                console.log(res);
                setState({ ...state, loadingResources: false, errorResources: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading) {
            editCompany(companyId, {
                name: state.name,
                address: state.address,
                zip_code: state.zip_code,
                city: state.city,
                legal_status: state.legal_status,
                leader: state.leader,
                creation_date: state.creation_date ? moment(state.creation_date).format('YYYY-MM-DD') : null,
                email: state.email,
                phone: state.phone,
                website: state.website,
                activities: state.activities,
                approval: state.approval,
                employees: state.employees,
                description: state.description,
                country_id: state.country_id,
                footer: state.footer,
                has_softskills_access: state.has_softskills_access,
                has_clea_access: state.has_clea_access,
            }).then(res => {
                if (res?.status === 200) {

                    enqueueSnackbar(res.data.message, { variant: 'success' });

                    if (state.photo) {
                        editCompanyLogo(companyId, state.photo).then(second_res => {
                            if (second_res?.status === 200) {
                                enqueueSnackbar(second_res.data.message, { variant: 'success' });
                            }
                            else {
                                enqueueSnackbar(second_res, { variant: 'error' });
                            }
                            history.push('/management/companies');
                        });
                    }
                    else {
                        history.push('/management/companies');
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button onClick={() => history.push('/management/companies')} variant="contained">
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" 
                    style={{ fontSize: 16, marginTop: mediaMD ? 0 : 10 }}
                >
                    <b>{t('company.edit')}</b>
                </Grid>
            </Grid>
            <Divider />
            {/* ---------------------- FORM ---------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                    <Grid container>
                        <Grid item xs={false} sm={2} md={3} lg={4} />
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            {/* ---------------------- PHOTO ---------------------- */}
                            {state.photo && (
                                <div style={{ marginTop: 12, textAlign: 'center' }}>
                                    <img
                                        alt="company-logo"
                                        src={fileUrl + state.photo}
                                        style={{ height: 50, width: 'auto' }}
                                    />
                                </div>
                            )}
                            <CompanyForm
                                state={state}
                                setState={setState}
                            />
                            <div style={{ marginTop: 24, textAlign: 'center' }}>
                                <Button
                                    onClick={onUpdateCompany}
                                    variant="contained"
                                    style={{ padding: '6px 24px', width: '100%', backgroundColor: servicesColor, color: 'white' }}
                                >
                                    {t('save')}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default CompanyUpdate;
