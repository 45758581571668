import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { MenuItem, Divider, Button, IconButton, Grid } from '@material-ui/core';
import {
    Search as IconSearch, Cancel as IconCancel, Delete as IconDelete,
    Create as IconEdit, WarningRounded as IconWarning,
    LockOpen as IconUnlock, Reply as IconRemove, Lock as IconLock,
} from '@material-ui/icons';

// Services
import { getUsersResources, getUsers } from 'services/requests';
import { servicesColor } from 'services/helpers';
import { roles } from 'services/constants';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import SelectOutlined from 'components/items/select-outlined';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Select from 'components/items/react-select';
import InputFilter from 'components/items/input-filter';
import CreateUser from './user-create';
import UpdateUser from './user-update';
import ExportUser from './user-export';
import ImportUsers from './user-import';
import { DeleteUser, LockUser, UnlockUser, RemoveUser } from './user-modals';

// Style
import style from 'style';

// ---------------------------------------- USERS MANAGEMENT --------------------------------------- \\
const UsersManagement = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 50,
        total_count: 0,
        last_name: '',
        first_name: '',
        company_id: null,
        email: '',
        role_id: -1,
        user: null,
        openCreateUser: false,
        openImportUsers: false,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            last_name: '',
            first_name: '',
            company_id: null,
            email: '',
            role_id: -1,
        });
    };

    // Handle Query (Get Users)
    useEffect(() => {
        if (state.loading) {
            getUsers(
                state.offset, 
                state.limit,
                state.last_name,
                state.first_name,
                state.company_id,
                state.email,
                state.role_id,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data.users,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('users')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>                    
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
                <div>
                    <Button
                        onClick={() => setState({ ...state, openCreateUser: true })}
                        variant="contained"
                    >
                        {t('add')}
                    </Button>
                    <Button
                        onClick={() => setState({ ...state, openImportUsers: true })}
                        variant="contained" style={style.ml5}
                    >
                        {t('import')}
                    </Button>
                </div>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('last.name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('first.name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('email')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('role')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('users')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('last.name')}
                                value={state.last_name}
                                onChange={e => setState({ ...state, last_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('first.name')}
                                value={state.first_name}
                                onChange={e => setState({ ...state, first_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('email')}
                                value={state.email}
                                onChange={e => setState({ ...state, email: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.role_id}
                                onChange={e => onSelectSearch('role_id', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                {roles.map(role => (
                                    <MenuItem key={role.id} value={role.id}>
                                        <span>{t(role.label)}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportUser data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- USER LIST -------------------- */}
                <UserList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* -------------------- CREATE USER -------------------- */}
            {state.openCreateUser && (
                <CreateUser
                    onClose={() => setState({ ...state, openCreateUser: false })}
                    refetch={() => setState({ ...state, openCreateUser: false, loading: true })}
                />
            )}
            {/* -------------------- IMPORT USERS -------------------- */}
            {state.openImportUsers && (
                <ImportUsers
                    onClose={() => setState({ ...state, openImportUsers: false })}
                    refetch={() => setState({ ...state, openImportUsers: false, loading: true })}
                />
            )}
        </>
    );
};

// ------------------------------------------- USER LIST ------------------------------------------- \\
const UserList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        user: undefined,
        openUpdateUser: false,
        openDeleteUser: false,
        openLockUser: false,
        openUnlockUser: false,
        openRemoveUser: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {data.length > 0 ? (
                    data.map(user => (
                        <Fragment key={user.id}>
                            <Grid
                                container alignItems="center"
                                style={{ backgroundColor: user.email ? null : 'rgba(255, 0, 0, 0.1)' }}
                            >
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{user.last_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{user.first_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{user.company ? user.company.name : ''}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {user.email ? (
                                        <span>{user.email}</span>
                                    ) : (
                                        <Tooltip title={t('email.missing')}
                                            item={
                                                <IconWarning style={{ color: 'red', fontSize: 28, cursor: 'pointer' }} />
                                            }
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{user.role.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    {getStoredUser()?.id !== user.id && (
                                        <>
                                            {(ROLE_ID === 1
                                            || (ROLE_ID === 2 && ROLE_ID < user.role.id)
                                            || user.role.id === 5) && (
                                                <Tooltip title={t('edit')}
                                                    item={
                                                        <IconButton
                                                            onClick={() => setState({ ...state, user, openUpdateUser: true })}
                                                            className="list-btn"
                                                        >
                                                            <IconEdit />
                                                        </IconButton>
                                                    }
                                                />
                                            )}
                                            {(ROLE_ID <= 2 && user.role.id > 1) && (
                                                user.active === 1 ? (
                                                    <Tooltip title={t('lock')}
                                                        item={
                                                            <IconButton
                                                                onClick={() => setState({ ...state, user, openLockUser: true })}
                                                                className="list-btn"
                                                            >
                                                                <IconUnlock />
                                                            </IconButton>
                                                        }
                                                    />
                                                ) : (
                                                    <Tooltip title={t('unlock')}
                                                        item={
                                                            <IconButton
                                                                onClick={() => setState({ ...state, user, openUnlockUser: true })}
                                                                className="list-btn"
                                                            >
                                                                <IconLock />
                                                            </IconButton>
                                                        }
                                                    />
                                                )
                                            )}
                                            {(ROLE_ID <= 2 && user.role.id === 5 && user.company !== null) && (
                                                <Tooltip title={t('remove')}
                                                    item={
                                                        <IconButton
                                                            onClick={() => setState({ ...state, user, openRemoveUser: true })}
                                                            className="list-btn"
                                                        >
                                                            <IconRemove style={{ transform: 'rotateY(180deg)' }} />
                                                        </IconButton>
                                                    }
                                                />
                                            )}
                                            {user.confirmed === 0 && (
                                                <Tooltip title={t('delete')}
                                                    item={
                                                        <IconButton
                                                            onClick={() => setState({ ...state, user, openDeleteUser: true })}
                                                            className="list-btn"
                                                        >
                                                            <IconDelete />
                                                        </IconButton>
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- DELETE USER -------------------- */}
            {state.openUpdateUser && (
                <UpdateUser
                    open={state.openUpdateUser}
                    onClose={() => setState({ ...state, openUpdateUser: false })}
                    refetch={() => handleRefetch('openUpdateUser')}
                    user={state.user}
                />
            )}
            {/* -------------------- DELETE USER -------------------- */}
            {state.openDeleteUser && (
                <DeleteUser
                    open={state.openDeleteUser}
                    onClose={() => setState({ ...state, openDeleteUser: false })}
                    refetch={() => handleRefetch('openDeleteUser')}
                    user={state.user}
                />
            )}
            {/* -------------------- LOCK USER -------------------- */}
            {state.openLockUser && (
                <LockUser
                    open={state.openLockUser}
                    onClose={() => setState({ ...state, openLockUser: false })}
                    refetch={() => handleRefetch('openLockUser')}
                    user={state.user}
                />
            )}
            {/* -------------------- UNLOCK USER -------------------- */}
            {state.openUnlockUser && (
                <UnlockUser
                    open={state.openUnlockUser}
                    onClose={() => setState({ ...state, openUnlockUser: false })}
                    refetch={() => handleRefetch('openUnlockUser')}
                    user={state.user}
                />
            )}
            {/* -------------------- REMOVE USER -------------------- */}
            {state.openRemoveUser && (
                <RemoveUser
                    open={state.openRemoveUser}
                    onClose={() => setState({ ...state, openRemoveUser: false })}
                    refetch={() => handleRefetch('openRemoveUser')}
                    user={state.user}
                />
            )}
        </>
    );
};

// ----------------------------------------- SELECT COMPANY ---------------------------------------- \\
const SelectCompany = ({ companyId, setCompanyId }) => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getUsersResources().then(res => {
            if (res?.status === 200) {
                setCompanies(res.data.data.companies.map(c => ({ value: c.id, label: c.name })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            value={companyId ? companies.find(c => c.id === companyId) : null}
            onChange={company => setCompanyId(company ? company.value : null)}
            options={companies}
            placeholder={t('company')}
        />
    );
};

export default UsersManagement;
