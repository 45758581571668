import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Services
import { getTranslation, getFormattedDate } from 'services/helpers';

// ------------------------------------ EXPORT EXCEL ------------------------------------- \\
const ExcelExport = (t, learner, skillbases, hard_positions, state, setState, enqueueSnackbar) => {
    
    // Create Excel File
    let workbook = new ExcelJS.Workbook();

    // One Skillbase per Worksheet
    skillbases.forEach(skillbase => {

        // Hard Positions of current Skillbase
        var filteredHardPositions = hard_positions.filter(hard_position => hard_position.skillbase.id === skillbase.id);

        // Add Worksheet to the Workbook
        var worksheet = workbook.addWorksheet(getTranslation('name', skillbase).replaceAll(/[[\]]/g, ''));

        // Title
        const lengthPositions = ConvertNumToLetter(filteredHardPositions.length);
        worksheet.mergeCells('A1', lengthPositions + '1');
        worksheet.getCell('A1').value = t('positioning') + ' ' + learner.full_name + '\r\n' + t('skillbase') + ' : ' + getTranslation('name', skillbase);
        worksheet.getCell('A1').font = { bold: true, color: { argb: '3F51B5' }};

        // Columns Headers
        let columnsHeaders = [t('units'), t('learning.outcomes'), t('descriptors')];
        worksheet.getRow(2).values = columnsHeaders;
        worksheet.getRow(2).font = { bold: true };
        worksheet.getRow(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E0F0FB' } };
        worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        // Columns Structure
        let columnsStructure = [
            { key: 'unit', width: 30 },
            { key: 'learning_outcome', width: 30 },
            { key: 'descriptor', width: 50 },
        ];
        worksheet.columns = columnsStructure;

        // Columns Styles
        worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getColumn(3).alignment = { vertical: 'middle', wrapText: true };

        // Title Alignement (need to set after columns styles)
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('C2').alignment = { vertical: 'middle', horizontal: 'center' };

        // Hard Positions Title
        filteredHardPositions.forEach((hard_position, index) => {
            worksheet.getColumn(index + 4).width = 40;
            worksheet.getColumn(index + 4).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell(ConvertNumToLetter(index + 1) + '2').value = t('positioning.of') + ' ' + getFormattedDate(hard_position.position_date) + '\r\n' + t('assessor')  + ' : ' + hard_position.assessor?.full_name;
        });

        // Hard Position Offset
        let hardPositionOffset = 2;

        // Merge Offsets
        let unitOffset = 3;
        let learningOffset = 3;

        // Add Skillbase Data
        skillbase.units.forEach(u => {

            // Unit Title
            let unit = u.position + '.' + getTranslation('name', u);
            let descriptorsPerUnit = 0;

            // Learning Outcomes
            u.learning_outcomes.forEach(l => {

                // LearningTitle
                let learning_outcome = u.position + '.' + l.position + ' ' + getTranslation('name', l);
                let descriptorsPerLearning = 0;

                // Competences
                l.competences.forEach(c => {
                    if (DescriptorIsPositioned(filteredHardPositions, 'competences', c.id)) {

                        descriptorsPerUnit += 1;
                        descriptorsPerLearning += 1;
                        hardPositionOffset += 1;

                        worksheet.addRow({
                            'unit': unit,
                            'learning_outcome': learning_outcome,
                            'descriptor': c.numerotation + t('competence.numerotation') + ' ' + getTranslation('name', c),
                        });

                        filteredHardPositions.forEach((position, index) => {
                            let competence = position.competences.find(competence => competence.id === c.id);
                            if (competence) {
                                let cell = ConvertNumToLetter(index + 1) + hardPositionOffset;
                                worksheet.getCell(cell).value = GetMasteryText(t, competence.mastery);
                                worksheet.getCell(cell).font = { bold: true, color: { argb: GetMasteryColor(competence.mastery) }};
                            }
                        });
                    }
                });

                // Knowledges
                l.knowledges.forEach(k => {
                    if (DescriptorIsPositioned(filteredHardPositions, 'knowledges', k.id)) {

                        descriptorsPerUnit += 1;
                        descriptorsPerLearning += 1;
                        hardPositionOffset += 1;

                        worksheet.addRow({
                            'unit': unit,
                            'learning_outcome': learning_outcome,
                            'descriptor': k.numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', k),
                        });

                        filteredHardPositions.forEach((position, index) => {
                            let knowledge = position.knowledges.find(knowledge => knowledge.id === k.id);
                            if (knowledge) {
                                let cell = ConvertNumToLetter(index + 1) + hardPositionOffset;
                                worksheet.getCell(cell).value = GetMasteryText(t, knowledge.mastery);
                                worksheet.getCell(cell).font = { bold: true, color: { argb: GetMasteryColor(knowledge.mastery) }};
                            }
                        });
                    }
                });

                // Skills
                l.skills.forEach(s => {
                    if (DescriptorIsPositioned(filteredHardPositions, 'skills', s.id)) {

                        descriptorsPerUnit += 1;
                        descriptorsPerLearning += 1;
                        hardPositionOffset += 1;

                        worksheet.addRow({
                            'unit': unit,
                            'learning_outcome': learning_outcome,
                            'descriptor': s.numerotation + t('skill.numerotation') + ' ' + getTranslation('name', s),
                        });

                        filteredHardPositions.forEach((position, index) => {
                            let skill = position.skills.find(skill => skill.id === s.id);
                            if (skill) {
                                let cell = ConvertNumToLetter(index + 1) + hardPositionOffset;
                                worksheet.getCell(cell).value = GetMasteryText(t, skill.mastery);
                                worksheet.getCell(cell).font = { bold: true, color: { argb: GetMasteryColor(skill.mastery) }};
                            }
                        });
                    }
                });

                // Merge Learnings Cells
                if ((learningOffset + (descriptorsPerLearning - 1)) > (learningOffset + 1)) {
                    let limitLearning = learningOffset + (descriptorsPerLearning - 1);
                    worksheet.mergeCells(`B${learningOffset}`, `B${limitLearning}`);
                    learningOffset = learningOffset + descriptorsPerLearning;
                }
            });

            // Merge Units Cells
            if ((unitOffset + (descriptorsPerUnit - 1)) > (unitOffset + 1)) {
                let limitUnit = unitOffset + (descriptorsPerUnit - 1);
                worksheet.mergeCells(`A${unitOffset}`, `A${limitUnit}`);
                unitOffset = unitOffset + descriptorsPerUnit;
            }
        });
    });

    // Save
    workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), learner.full_name + ' - ' + t('positioning') +  '.xlsx'));
    enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
    setState({ ...state, start: false });
};

// ---------------------------------- GET MASTERY TEXT ----------------------------------- \\
const GetMasteryText = (t, mastery) => {
    switch (mastery) {
        case 0 :
            return t('mastery.0');
        case 1 :
            return t('mastery.1');
        case 2 :
            return t('mastery.2');
        default:
            return t('mastery.0');
    };
};

// ---------------------------------- GET MASTERY COLOR ---------------------------------- \\
const GetMasteryColor = mastery => {
    switch (mastery) {
        case 0 :
            return 'DB60909'; // RED
        case 1 :
            return 'E78200'; // ORANGE
        case 2 :
            return '0EB813'; // GREEN
        default:
            return '757575'; // GRAY
    };
};

// ------------------------------- CONVERT NUM TO LETTER --------------------------------- \\
const ConvertNumToLetter = num => {
    switch (num) {
        case 1 :
            return 'D';
        case 2 :
            return 'E';
        case 3 :
            return 'F';
        case 4 :
            return 'G';
        case 5 :
            return 'H';
        case 6 :
            return 'I';
        case 7 :
            return 'J';
        case 8 :
            return 'K';
        case 9 :
            return 'L';
        case 10 :
            return 'M';
        default:
            return 'N';
    };
};

// ------------------------------ DESCRIPTOR IS POSITIONED ------------------------------- \\
const DescriptorIsPositioned = (filteredHardPositions, descriptorType, descriptorId) => {
    let isPositioned = false;
    filteredHardPositions.forEach(hard_position => {
        if (hard_position[descriptorType].findIndex(c => c.id === descriptorId) !== -1) {
            isPositioned = true;
        }
    });
    return isPositioned;
};

export default ExcelExport;
