import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { addKnowledge } from 'services/requests';
import { initialLanguagesObject } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ------------------------------------- CREATE KNOWLEDGE ------------------------------------- \\
const CreateKnowledge = ({ onClose, refetch, unitId, learningId, knowledges }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
    });

    function handleNames(event, code) {
        setState({
            ...state,
            names: Object.assign({}, state.names, { [code]: event.target.value })
        });
    }

    function handleAddKnowledge() {
        if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            addKnowledge(match.params.skillbaseId, unitId, learningId, { name: state.names }).then(res => {
                if (res?.status === 200) {

                    let updatedKnowledges = knowledges;
                    updatedKnowledges.push(res.data.data.knowledge);

                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(updatedKnowledges);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('knowledge.add')}
                        </p>
                        <Divider style={style.m15_0} />
                        <div style={style.taC}>
                            <span style={style.fwB}>{t('knowledge.name')}</span>
                        </div>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleAddKnowledge} variant="contained">
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateKnowledge;
