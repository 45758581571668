import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';

// --------------------------------------- CERTIFICATION FORM PAGE 3 ---------------------------------------- \\
const CertificationFormPage3 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage4 = () => {
        if (state.user_id == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 4 });
        }
    };

    useEffect(() => {
        if (state.authorizedRefetchUserCompanies) {
            if (state.user_company_id) {
                getCompanyMembers(state.user_company_id, true).then(res => {
                    if (res?.status === 200) {
                        setState({
                            ...state,
                            authorizedRefetchUserCompanies: false,
                            users: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                            user_id: null,
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, authorizedRefetchUserCompanies: false, users: [], user_id: null });
                    }
                });
            }
            else {
                setState({ ...state, authorizedRefetchUserCompanies: false, users: [], user_id: null });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user_company_id]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- TITLE -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('learner.select')}</b><FieldRequired />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <span>{t('company')}</span>
                            <Select
                                placeholder={t('company')}
                                value={state.user_company_id ? state.companies.find(c => c.value === state.user_company_id) : null}
                                onChange={company => setState({ ...state, user_company_id: company ? company.value : null, authorizedRefetchUserCompanies: true })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- USER -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <span>{t('learner')}</span>
                            <Select
                                placeholder={t('learner')}
                                value={state.user_id ? state.users.find(u => u.value === state.user_id) : null}
                                onChange={user => setState({ ...state, user_id: user ? user.value : null })}
                                options={state.users}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 2 })} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>3 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={navigateToPage4} variant="contained">
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CertificationFormPage3;
