import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Button, Accordion, AccordionSummary } from '@material-ui/core';
import { LibraryAdd as IconAdd, ExpandMore as IconExpand, CheckBox as IconCheckBox, CheckBoxOutlineBlank as IconCheckoutline } from '@material-ui/icons';

// Services
import { getTranslation, softskillsColor, softskillsColor2 } from 'services/helpers';
import { getSoftCategories } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Badge from 'components/items/badge';

// Style
import style from 'style';

// ---------------------------------- SOFT EVALUATION FORM PAGE 2 ------------------------------------------- \\
const SoftEvaluationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage3 = () => {
        if (state.softskills == null || state.softskills.length === 0) {
            enqueueSnackbar(t('select.atleast.one.softskill'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    useEffect(() => {
        if (state.loadingSoftCategories) {
            getSoftCategories(state.learner_id).then(res => {
                if (res?.status === 200) {

                    let soft_categories = res.data.data.soft_categories;

                    if (state.actionType === 'UPDATE') {

                        let softskills = [], soft_levels = {};

                        soft_categories.forEach(soft_category => {
                            soft_category.softskills.forEach(softskill => {

                                if (softskill.soft_levels.find(soft_level => state.soft_level_ids.includes(soft_level.id)) != null) {

                                    softskills.push(Object.assign({}, softskill,  { soft_category_id: soft_category.id }));

                                    softskill.soft_levels.forEach(soft_level => {
                                        if (state.soft_level_ids.includes(soft_level.id)) {
                                            soft_levels[softskill.id] = soft_level.id;
                                        }
                                    });
                                }
                            });
                        });

                        setState({ ...state, loadingSoftCategories: false, soft_categories, softskills, soft_levels });
                    }
                    else {
                        setState({ ...state, loadingSoftCategories: false, soft_categories });
                    }
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingSoftCategories: false, errorSoftCategories: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingSoftCategories]);

    if (state.loadingSoftCategories) return <Spinner />;
    if (state.errorSoftCategories) return <Error />;

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                {/* -------------------- TITLE -------------------- */}
                <Grid
                    container justifyContent="center" alignItems="center"
                    style={{ backgroundColor: softskillsColor, ...style.p10, ...style.cW, ...style.mt15 }}
                >
                    <IconAdd />
                    <span style={{ ...style.ml5, ...style.ttU }}>
                        {t('select.upto.3.softskills')}
                    </span>
                </Grid>
                {/* -------------------- SOFT CATEGORY LIST -------------------- */}
                <SoftCategoryList
                    soft_categories={state.soft_categories}
                    softskills={state.softskills}
                    setSoftskills={softskills => setState({ ...state, softskills })}
                />
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 3</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// -------------------------------------- SOFT CATEGORY LIST ------------------------------------------------ \\
const SoftCategoryList = ({ soft_categories, softskills, setSoftskills }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(null);

    const onChangeAccordion = soft_category_id => {
        if (expanded === soft_category_id) {
            setExpanded(null);
        }
        else {
            setExpanded(soft_category_id);
        }
    };

    const getSoftskillNumber = soft_category_id => {
        return softskills.filter(s => s.soft_category_id === soft_category_id)?.length;
    };

    const onSelectSoftskill = (soft_category_id, softskill) => {
        let updatedSoftskills = softskills;
        let index = updatedSoftskills.findIndex(s => s.id === softskill.id);
        
        if (index === -1) {
            if (updatedSoftskills.length === 3) {
                enqueueSnackbar(t('select.only.3.softskills'), { variant: 'warning' });
            }
            else {
                updatedSoftskills.push(Object.assign({}, softskill,  { soft_category_id }));
                setSoftskills(updatedSoftskills);
            }
        }
        else {
            updatedSoftskills.splice(index, 1);
            setSoftskills(updatedSoftskills);
        }
    };

    return (
        <div>
            {/* -------------------- SOFT CATEGORIES -------------------- */}
            {soft_categories.map(soft_category => (
                <Accordion
                    key={soft_category.id}
                    expanded={expanded === soft_category.id}
                    onChange={() => onChangeAccordion(soft_category.id)}
                >
                    <AccordionSummary expandIcon={<IconExpand />}>
                        <Grid container alignItems="center">
                            <Grid item xs={11}>
                                <span style={style.fwB}>
                                    {soft_category.position}. {getTranslation('name', soft_category)}
                                </span>
                            </Grid>
                            <Grid item xs={1} container justifyContent="center">
                                <Badge
                                    variant="secondary"
                                    number={getSoftskillNumber(soft_category.id)}
                                />
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <Divider />
                    <div>
                        {(soft_category.softskills && soft_category.softskills.length > 0) ? (
                            <>
                                {/* -------------------- SOFT SKILLS -------------------- */}
                                {soft_category.softskills.map(softskill => (
                                    <Fragment key={softskill.id}>
                                        <Grid
                                            container alignItems="center"
                                            className="list-item-hover"
                                            onClick={() => onSelectSoftskill(soft_category.id, softskill)}
                                            style={{
                                                padding: 10,
                                                backgroundColor: (softskills.findIndex(s => s.id === softskill.id) !== -1)
                                                ? softskillsColor2
                                                : null
                                            }}
                                        >
                                            <Grid item xs={10}>
                                                <span>{softskill.position}. {getTranslation('name', softskill)}</span>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent="flex-end" style={{ paddingRight: 14 }}>
                                                {(softskills.findIndex(s => s.id === softskill.id) !== -1) ? (
                                                    <IconCheckBox />
                                                ):(
                                                    <IconCheckoutline />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </>
                        ) : (
                            <Grid style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                        )}
                    </div>
                </Accordion>
            ))}
        </div>
    );
};

export default SoftEvaluationFormPage2;
