import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Import Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Import Services
import { getSoftSkill } from '../../../../services/requests';
import { getTranslation } from '../../../../services/helpers';

// Import Components
import SoftLevels from './softlevels';
import Behaviors from './behaviors';

// Import Style
import style from '../../../../style';

// ------------------------------------- SOFT SKILL ATTRIBUTES ------------------------------------- \\
const SoftSkillAttributes = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();

    const [state, setState] = useState({
        loading: true,
        error: false,
        softskill: undefined,
    });

    useEffect(() => {
        if (state.loading) {
            getSoftSkill(match.params.softskillId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, softskill: res.data.data.softskill });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" style={style.p10}>
                <b>{t('softskills')}</b>
            </Grid>
            <Divider />
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4}>
                    <Button onClick={() => history.push('/management/softskills')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    {state.softskill && getTranslation('name', state.softskill)}
                </Grid>
            </Grid>
            <div className="full-container">
                <div style={{ overflow: 'auto' }}>
                    {/* ---------------------- SOFT LEVELS ---------------------- */}
                    <SoftLevels
                        loading={state.loading}
                        error={state.error}
                        softskill={state.softskill}
                        refetch={() => setState({ ...state, loading: true })}
                    />
                    {/* ---------------------- BEHAVIORS ---------------------- */}
                    <Behaviors
                        loading={state.loading}
                        error={state.error}
                        softskill={state.softskill}
                        refetch={() => setState({ ...state, loading: true })}
                    />
                </div>
            </div>
        </>
    );
};

export default SoftSkillAttributes;
