import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Material-UI
import { Button, Grid, Divider } from '@material-ui/core';

// Services
import { mobipassColor, softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import SkillbasesSearch from './skillbases-search';
import SoftskillsSearch from './softskills-search';

const styles = {
    menuDefault: {
        backgroundColor: 'lightgray',
        color: 'black'
    },
    menuSkillbases: {
        backgroundColor: mobipassColor,
        color: 'white'
    },
    menuSoftskills: {
        backgroundColor: softskillsColor,
        color: 'white'
    },
};

// ---------------------------------------- RESEARCH ---------------------------------------- \\
const Research = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const [menuSelected, setMenuSelected] = useState(0);

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to="/mobipass/research" className="breadcrumb-item">
                {t('research')}
            </BreadcrumbsItem>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid
                container justifyContent="center"
                className="toolbar"
                style={{ paddingTop: 24, paddingBottom: 24 }}
            >
                <Grid
                    item xs={12} md={6}
                    container alignItems="center" justifyContent="center"
                >
                    <Button
                        style={menuSelected === 0 ? styles.menuSkillbases : styles.menuDefault}
                        variant="contained"
                        onClick={() => setMenuSelected(0)}
                    >
                        {t('search.skillbases.button')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={6}
                    container alignContent="center" justifyContent="center"
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        style={menuSelected === 1 ? styles.menuSoftskills : styles.menuDefault}
                        variant="contained"
                        onClick={() => setMenuSelected(1)}
                    >
                        {t('search.softskills.button')}
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SEARCH LEARNERS -------------------- */}
            <div className="full-container" style={{ paddingTop: 24, paddingBottom: 24 }}>
                {menuSelected === 0 ? (
                    <SkillbasesSearch />
                ) : (
                    <SoftskillsSearch />
                )}
            </div>
        </>
    );
};

export default Research;
