const style = {
    // ------------------ ALIGN ITEMS ---------------- \\
    aiC: {
        alignItems: 'center',
    },
    // --------------------- BORDER ------------------ \\
    border: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    // -------------------- DISPLAY ------------------ \\
    dF: {
        display: 'flex',
    },
    dN: {
        display: 'none',
    },
    // --------------------- COLOR ------------------- \\
    cW: {
        color: 'white',
    },
    // --------------------- COLOR ------------------- \\
    cP: {
        cursor: 'pointer',
    },
    // --------------------- FLEX -------------------- \\
    f1: {
        flex: 1,
    },
    // ---------------- FLEX DIRECTION --------------- \\
    fdC: {
        flexDirection: 'column',
    },
    // ------------------- FONT SIZE ----------------- \\
    fs14: {
        fontSize: 14,
    },
    fs16: {
        fontSize: 16,
    },
    // ------------------ FONT STYLE ----------------- \\
    fsI: {
        fontStyle: 'italic',
    },
    // ----------------- FONT WEIGHT ----------------- \\
    fwB: {
        fontWeight: 'bold',
    },
    // ---------------- JUSTIFY CONTENT -------------- \\
    jcC: {
        justifyContent: 'center',
    },
    jcSA: {
        justifyContent: 'space-around',
    },
    jcSB: {
        justifyContent: 'space-between',
    },
    // -------------------- MARGIN ------------------- \\
    m0: {
        margin: 0,
    },
    m5: {
        margin: 5,
    },
    m10: {
        margin: 10,
    },
    mA0: {
        margin: 'auto 0',
    },
    mb5: {
        marginBottom: 5,
    },
    mb10: {
        marginBottom: 10,
    },
    mb15: {
        marginBottom: 15,
    },
    mb25: {
        marginBottom: 25,
    },
    ml5: {
        marginLeft: 5,
    },
    ml10: {
        marginLeft: 10,
    },
    ml15: {
        marginLeft: 15,
    },
    mr5: {
        marginRight: 5,
    },
    mr10: {
        marginRight: 10,
    },
    mt5: {
        marginTop: 5,
    },
    mt10: {
        marginTop: 10,
    },
    mt15: {
        marginTop: 15,
    },
    mt24: {
        marginTop: 24,
    },
    mt25: {
        marginTop: 25,
    },
    mt50: {
        marginTop: 50,
    },
    m5_0: {
        margin: '5px 0px',
    },
    m5_10: {
        margin: '5px 10px',
    },
    m10_0: {
        margin: '10px 0px',
    },
    m15_0: {
        margin: '15px 0px',
    },
    m25_0: {
        margin: '25px 0px',
    },
    m5_0_15_0: {
        margin: '5px 0px 15px 0px',
    },
    // ------------------- PADDING ------------------- \\
    p0: {
        padding: 0,
    },
    p5: {
        padding: 5,
    },
    p6: {
        padding: 6,
    },
    p10: {
        padding: 10,
    },
    p15: {
        padding: 15,
    },
    p20: {
        padding: 20,
    },
    p24: {
        padding: 24,
    },
    p25: {
        padding: 25,
    },
    p0_5: {
        padding: '0px 5px',
    },
    p0_10: {
        padding: '0px 10px',
    },
    p0_25: {
        padding: '0px 25px',
    },
    p3_16: {
        padding: '3px 16px',
    },
    p10_0: {
        padding: '10px 0px',
    },
    p10_24: {
        padding: '10px 24px',
    },
    p10_25: {
        padding: '10px 25px',
    },
    p12_16: {
        padding: '12px 16px',
    },
    p15_25: {
        padding: '15px 25px',
    },
    pb20: {
        paddingBottom: 20,
    },
    // ----------------- TEXT ALIGN ------------------ \\
    taC: {
        textAlign: 'center',
    },
    taR: {
        textAlign: 'right',
    },
    // --------------- TEXT DECORATION ---------------- \\
    tdN: {
        textDecoration: 'none',
    },
    // ---------------- TEXT TRANSFORM ---------------- \\
    ttN: {
        textTransform: 'none',
    },
    ttU: {
        textTransform: 'uppercase',
    },
    // --------------------- WIDTH -------------------- \\
    wA: {
        width: 'auto',
    },
    w100: {
        width: '100%',
    },
    // --------------- CLOSE BUTTON MODAL ------------- \\
    closeBtnModal: {
        padding: '6px 0px',
        minWidth: 'auto',
        marginRight: 5,
    },
};

export default style;
