import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

// Services
import { mobitrainColor } from 'services/helpers';

// Style
import style from 'style';

// ---------------------------------------- COMPANIES MODAL ----------------------------------------- \\
export const CompaniesModal = ({ onClose, formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {formation.name}
                </p>
                <span style={{ color: mobitrainColor }}>
                    {t('companies')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {formation.companies.map(item => (
                        <span key={item.id}>
                            {item.name}
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------- LEARNERS MODAL ----------------------------------------- \\
export const LearnersModal = ({ onClose, formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {formation.name}
                </p>
                <span style={{ color: mobitrainColor }}>
                    {t('learners.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {formation.formation_learners.map(item => (
                        <span key={item.id}>
                            {item.learner.full_name}
                            &nbsp;
                            {item.learner.company && '(' + item.learner.company.name + ')'}
                            <br />
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------- TRAINERS MODAL ----------------------------------------- \\
export const TrainersModal = ({ onClose, formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {formation.name}
                </p>
                <span style={{ color: mobitrainColor }}>
                    {t('trainers.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {formation.formation_trainers.map(item => (
                        <span key={item.id}>
                            {item.trainer.full_name}
                            &nbsp;
                            {item.trainer.company && '(' + item.trainer.company.name + ')'}
                            <br />
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
