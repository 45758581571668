import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Material-UI
import { Grid, Accordion, AccordionSummary, Divider } from '@material-ui/core';
import { School as IconMobitrain, ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getFormationPercentage } from 'services/requests';
import { mobitrainColor, mobitrainColor2, getTranslation } from 'services/helpers';

// Components
import ProgressBar from 'components/items/progress-bar';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    container: {
        marginBottom: 25,
    },    
    border: {
        padding: 10,
        border: '1px solid lightgray',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
    },
};

// ------------------------------------- SKILLBASES CHART --------------------------------------- \\
const SkillbasesChart = () => {
    const { t } = useTranslation();
    const { companyId, learnerId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        skillbases: [],
    });

    useEffect(() => {
        getFormationPercentage((learnerId != null ? 'learner' : 'company'), (learnerId || companyId)).then(res => {
            if (res?.status === 200) {
                setState({ ...state, loading: false, skillbases: res.data.data.skillbases });
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid style={styles.container}>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justifyContent="center" alignItems="center"
                style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.cW }}
            >
                <IconMobitrain />
                <span style={{ ...style.ml5, ...style.ttU }}>
                    {t('skillbase.percentage.covered.formation')}
                </span>
            </Grid>
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <div>
                    {state.skillbases.length > 0 ? (
                        state.skillbases.map(skillbase => (
                            <Accordion key={skillbase.id}>
                                <AccordionSummary expandIcon={<IconExpand />} style={{ backgroundColor: mobitrainColor2 }}>
                                    <span style={style.fwB}>{getTranslation('name', skillbase)}</span>
                                </AccordionSummary>
                                <Divider />
                                <div>
                                    {skillbase.units.map(unit => (
                                        <Accordion key={unit.id}>
                                            <AccordionSummary expandIcon={<IconExpand />}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12} md={6} style={{ padding: '0px 10px' }}>
                                                        <span>{unit.position}. {getTranslation('name', unit)}</span>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} style={{ padding: '0px 10px' }}>
                                                        <ProgressBar done={Math.round(unit.percentage_covered)} />
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <Divider />
                                            <div style={style.p10}>
                                                {unit.learning_outcomes.map((learning, index) => (
                                                    <Grid
                                                        key={learning.id}
                                                        container alignItems="center"
                                                        style={{ marginTop: index === 0 ? 0 : 5 }}
                                                    >
                                                        <Grid item xs={12} md={6} style={{ padding: '0px 10px' }}>
                                                            <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} style={{ padding: '0px 10px' }}>
                                                            <ProgressBar done={Math.round(learning.percentage_covered)} />
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </div>
                                        </Accordion>
                                    ))}
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <div style={styles.border}>
                            <span>{t('none')}</span>
                        </div>
                    )}
                </div>
            )}
        </Grid>
    );
};

export default SkillbasesChart;
