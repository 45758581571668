import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Services
import { getStoredUser, getSoftskillsAccess, getCleaAccess } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import SideMenu from './side-menu';
import Research from './research';
import Skillbases from './skillbase';
import SoftSkills from './softskills';
import Clea from './clea';
import HardPositions from './hardpositions';
import Evaluation from './evaluation';
import Portfolio from './portfolio';
import Certification from './certification';
import Header from 'components/common/header';
import Breadcrumb from 'components/common/breadcrumb';
import Settings from 'components/common/settings';
import SideDrawer from 'components/common/side-drawer';

// ---------------------------------------- MOBIPASS ---------------------------------------- \\
const Mobipass = () => {
    const { mediaLG } = useMediaQueries();
    const ROLE_ID = getStoredUser()?.role?.id;

    return (
        <>
            <BreadcrumbsItem to="/mobipass" className="breadcrumb-item">
                MOBIPASS
            </BreadcrumbsItem>
            <Header />
            <div className="main">
                {mediaLG ? (
                    <SideMenu position="fixed" />
                ) : (
                    <SideDrawer menu={<SideMenu position="initial" />} />
                )}
                <div className="container">
                    <Breadcrumb />
                    <Switch>
                        <Route path="/mobipass/skillbases">
                            <Skillbases />
                        </Route>
                        <Route path="/mobipass/positioning">
                            <HardPositions />
                        </Route>
                        <Route path="/mobipass/evaluation">
                            <Evaluation />
                        </Route>
                        <Route path="/mobipass/certification">
                            <Certification />
                        </Route>
                        <Route path="/mobipass/portfolio">
                            <Portfolio />
                        </Route>
                        {ROLE_ID < 5 && (
                            <Route exact path="/mobipass/research">
                                <Research />
                            </Route>
                        )}
                        {getSoftskillsAccess() === '1' && (
                            <Route path="/mobipass/softskills">
                                <SoftSkills />
                            </Route>
                        )}
                        {getCleaAccess() === '1' && (
                            <Route path="/mobipass/clea">
                                <Clea />
                            </Route>
                        )}
                        <Route exact path="/mobipass/settings">
                            <Settings />
                        </Route>
                        <Redirect from="*" to="/mobipass/skillbases" />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Mobipass;
