import React, { useState, useEffect, Fragment } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Grid, Divider, IconButton } from '@material-ui/core';
import {
    Cancel as IconCancel, Search as IconSearch,
    Delete as IconDelete, Create as IconEdit, Assignment as IconDetail,
} from '@material-ui/icons';

// Services
import { getSoftEvaluations } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { softskillsColor, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateSoftEvaluation from './soft-evaluation-create';
import UpdateSoftEvaluation from './soft-evaluation-update';
import { DeleteSoftEvaluation, DetailsSoftEvaluation } from './soft-evaluation-modals';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// ----------------------------------- SOFT EVALUATIONS ROUTER ------------------------------------ \\
const SoftEvaluationsRouter = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('evaluation')}
            </BreadcrumbsItem>
            {/* -------------------- ROUTER -------------------- */}
            <Switch>
                <Route exact path={`${match.path}`}>
                    <SoftEvaluations />
                </Route>
                <Route path={`${match.path}/create`}>
                    <CreateSoftEvaluation />
                </Route>
                <Route path={`${match.path}/:evaluationId`}>
                    <UpdateSoftEvaluation />
                </Route>
            </Switch>
        </>
    );
};

// -------------------------------------- SOFT EVALUATIONS ---------------------------------------- \\
const SoftEvaluations = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('softevaluations-filters'));
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        soft_evaluations: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        evaluation_date: storedFilters?.evaluation_date || null,
        company_name: storedFilters?.company_name || '',
        assessor_name: storedFilters?.assessor_name || '',
        learner_name: storedFilters?.learner_name || '',
    });
    const total_page = state.offset + state.limit > state.total_count
        ? state.total_count
        : state.offset + state.limit;


    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0 });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('softevaluations-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            evaluation_date: null,
            company_name: '',
            assessor_name: '',
            learner_name: '',
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('softevaluations-filters', JSON.stringify({
                evaluation_date: state.evaluation_date,
                company_name: state.company_name,
                assessor_name: state.assessor_name,
                learner_name: state.learner_name,
            }));

            getSoftEvaluations({
                offset: state.offset,
                limit: state.limit,
                evaluation_date: state.evaluation_date ? moment(state.evaluation_date).format('YYYY-MM-DD') : null,
                company_name: state.company_name,
                assessor_name: state.assessor_name,
                learner_name: state.learner_name,
            }).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        soft_evaluations: res.data.data.soft_evaluations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button variant="contained" onClick={() => history.push('/mobipass/softskills')}>
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push(`${match.path}/create`)}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: softskillsColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('date')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('assessor')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('evaluation')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('date')}
                                value={state.evaluation_date}
                                onChange={evaluation_date => onSelectSearch('evaluation_date', evaluation_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('company')}
                                value={state.company_name}
                                onChange={e => setState({ ...state, company_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('assessor')}
                                value={state.assessor_name}
                                onChange={e => setState({ ...state, assessor_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner_name}
                                onChange={e => setState({ ...state, learner_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- EVALUATION LIST -------------------- */}
                <SoftEvaluationList
                    soft_evaluations={state.soft_evaluations}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// ------------------------------------- SOFT EVALUATION LIST ------------------------------------- \\
const SoftEvaluationList = ({ loading, error, refetch, soft_evaluations }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;
    const USER_ID = storedUser ? storedUser.id : null;

    const [state, setState] = useState({
        soft_evaluation: null,
        openDelete: false,
        openDetails: false,
    });

    function handleRefetch() {
        refetch();
        setState({ ...state, openDelete: false });
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {soft_evaluations.length > 0 ? (
                    soft_evaluations.map(soft_evaluation => (
                        <Fragment key={soft_evaluation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(soft_evaluation.evaluation_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{soft_evaluation.company_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{soft_evaluation.assessor_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{soft_evaluation.learner?.full_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={(
                                            <IconButton
                                                className="list-btn"
                                                onClick={() => setState({ ...state, openDetails: true, soft_evaluation })}
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        )}
                                    />
                                    {(ROLE_ID <= 2 || USER_ID === soft_evaluation.creator_id) && (
                                        <>
                                            <Tooltip title={t('edit')}
                                                item={(
                                                    <IconButton
                                                        className="list-btn"
                                                        onClick={() => history.push(`${match.path}/${soft_evaluation.id}`)}
                                                    >
                                                        <IconEdit />
                                                    </IconButton>
                                                )}
                                            />
                                            <Tooltip title={t('delete')}
                                                item={(
                                                    <IconButton
                                                        className="list-btn"
                                                        onClick={() => setState({ ...state, openDelete: true, soft_evaluation })}
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                )}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <> 
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>  
                )}
            </div>
            {/* -------------------- DELETE SOFT EVALUATION -------------------- */}
            {state.openDelete && (
                <DeleteSoftEvaluation
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={() => handleRefetch()}
                    soft_evaluation={state.soft_evaluation}
                />
            )}
            {/* -------------------- DETAILS SOFT EVALUATION -------------------- */}
            {state.openDetails && (
                <DetailsSoftEvaluation
                    onClose={() => setState({ ...state, openDetails: false })}
                    soft_evaluation={state.soft_evaluation}
                />
            )}
        </>
    );
};

export default SoftEvaluationsRouter;
