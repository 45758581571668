import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateSkill from './create-skill';
import UpdateSkill from './update-skill';
import DeleteSkill from './delete-skill';

// Style
import style from 'style';

// ---------------------------------------- SKILLS ---------------------------------------- \\
const Skills = ({ unitId, learning }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        skills: learning?.skills || [],
        skill: null,
        openCreateSkill: false,
        openUpdateSkill: false,
        openDeleteSkill: false,
    });

    function handleOpenModal(event, open, skill) {
        event.stopPropagation();
        setState({ ...state, [open]: true, skill });
    }

    return (
        <>
            {/* ---------------------- LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: '#e0e0e0', marginTop: 24 }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={8} style={style.p10}>
                            <span>{t('skill')}</span>
                        </Grid>
                        <Grid item xs={4} style={{ ...style.p10, ...style.taC }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('skills')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- SKILL LIST ---------------------- */}
            {state.skills.length > 0 ? (
                state.skills.map(skill => (
                    <Fragment key={skill.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={8} style={style.p10}>
                                <span>
                                    {getTranslation('name', skill)}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ ...style.p10, ...style.taC }}>
                                <IconButton
                                    onClick={e => handleOpenModal(e, 'openUpdateSkill', skill)}
                                    style={{ padding: 6 }}
                                >
                                    <IconEdit />
                                </IconButton>
                                <IconButton
                                    onClick={e => handleOpenModal(e, 'openDeleteSkill', skill)}
                                    style={{ padding: 6 }}
                                >
                                    <IconDelete />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openSkillCreate: true })}
                    className="fab"
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('skill.add')}
                </span>
            </div>
            {/* ---------------------- CREATE SKILL ---------------------- */}
            {state.openSkillCreate && (
                <CreateSkill
                    onClose={() => setState({ ...state, openSkillCreate: false })}
                    refetch={skills => setState({ ...state, openSkillCreate: false, skills })}
                    skills={state.skills}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
            {/* ---------------------- UPDATE SKILL ---------------------- */}
            {state.openUpdateSkill && (
                <UpdateSkill
                    onClose={() => setState({ ...state, openUpdateSkill: false })}
                    refetch={skills => setState({ ...state, openUpdateSkill: false, skills })}
                    skills={state.skills}
                    skill={state.skill}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
            {/* ---------------------- DELETE SKILL ---------------------- */}
            {state.openDeleteSkill && (
                <DeleteSkill
                    onClose={() => setState({ ...state, openDeleteSkill: false })}
                    refetch={skills => setState({ ...state, openDeleteSkill: false, skills })}
                    skills={state.skills}
                    skill={state.skill}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
        </>
    );
};

export default Skills;
