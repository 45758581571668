import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { RadioGroup, Grid, Divider, FormControlLabel, Radio, MenuItem } from '@material-ui/core';

// Services
import { languages, softToolTypes } from 'services/constants';

// Components
import Filters from './soft-tool-filters';
import SelectOutlined from 'components/items/select-outlined';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ---------------------------------------- TOOL FORM ---------------------------------------- \\
const ToolForm = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleNames(event, code) {
        let names = state.names;
        names[code] = event.target.value;
        setState({ ...state, names });
    }

    function handleDescriptions(event, code) {
        let descriptions = state.descriptions;
        descriptions[code] = event.target.value;
        setState({ ...state, descriptions });
    }
    
    return (
        <>
            {/* -------------------- TYPE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('type')}</b>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SelectOutlined
                        value={state.soft_tool_type_id}
                        onChange={e => setState({ ...state, soft_tool_type_id: e.target.value })}
                    >
                        {softToolTypes.map(softToolType => (
                            <MenuItem key={softToolType.id} value={softToolType.id}>
                                <span>{t(softToolType.label)}</span>
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- ACTIVE -------------------- */}
            <Grid container alignItems="center" style={{ marginTop: 12 }}>
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('active')}</b>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        row
                        value={state.active}
                        onChange={e => setState({ ...state, active: e.target.value })}
                        style={{ ...style.w100, ...style.jcSA }}
                    >
                        <FormControlLabel
                            value="1"
                            label={t('yes')}
                            control={<Radio color="primary" />}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            value="0"
                            label={t('no')}
                            control={<Radio />}
                            style={{ margin: 0 }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- NAME -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('name')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.names[language.code]}
                    onChange={e => handleNames(e, language.code)}
                />
            ))}
            <Divider style={style.m15_0} />
            {/* -------------------- DESCRIPTION -------------------- */}
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('description')}</span>
            </div>
            {languages.map(language => (
                <InputTextGrid
                    key={language.id}
                    placeholder={t(language.label)}
                    value={state.descriptions[language.code]}
                    onChange={e => handleDescriptions(e, language.code)}
                />
            ))}
            {/* -------------------- FILTERS -------------------- */}
            {state.soft_tool_type_id === 1 && (
                <>
                    <Divider style={style.m15_0} />
                    <Filters state={state} setState={setState} />
                </>
            )}
        </>
    );
};

export default ToolForm;
