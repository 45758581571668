import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getCompanyList, getSkillbases, createCertification } from 'services/requests';
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CertificationFormPage1 from './certification-form/certification-form-page-1';
import CertificationFormPage2 from './certification-form/certification-form-page-2';
import CertificationFormPage3 from './certification-form/certification-form-page-3';
import CertificationFormPage4 from './certification-form/certification-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// --------------------------------------- CREATE CERTIFICATION ---------------------------------------- \\
const CreateCertification = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        actionType: 'CREATE',
        loadingResources: true,
        errorResources: false,
        loading: false,
        page: 1,

        // Page 1
        certification_date: null,
        document: null,

        // Page 2
        authorizedRefetchAssessorCompanies: false,
        companies: [],
        company_manual_entry: false,
        company_id: null,
        company_name: '',
        assessors: [],
        assessor_manual_entry: false,
        assessor_id: null,
        assessor_name: '',

        // Page 3
        authorizedRefetchUserCompanies: false,
        user_company_id: null,
        users: [],
        user_id: null,

        // Page 4
        authorizedRefetchSkillbases: false,
        skillbases: [],
        skillbase_id: null,
        skillbase: null,
        units: [],
    });

    // Handle Get Resources
    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getCompanyList(), getSkillbases()])
            .then(axios.spread(function (res_companies, res_skillbases) {
                if (res_companies?.status === 200 && res_skillbases?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                    });
                }
                else {
                    console.log(res_companies + res_skillbases);
                    setState(state => ({ ...state, loadingResources: false, error: true })); 
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    // Handle Create Certification
    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('certification_date', moment(state.certification_date).format('YYYY-MM-DD'));
            formData.append('company_id', state.company_id != null ? state.company_id : '');
            formData.append('company_name', state.company_name);
            formData.append('assessor_id', state.assessor_id != null ? state.assessor_id : '');
            formData.append('assessor_name', state.assessor_name);
            formData.append('user_id', state.user_id);
            formData.append('skillbase_id', state.skillbase_id);
            formData.append('units', JSON.stringify(state.units));

            if (state.document) {
                formData.append('document', state.document);
            }

            createCertification(formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobipass/certification');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/mobipass/certification')} variant="contained">
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginTop: mediaMD ? 0 : 10 }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>{t('certification.add')}</span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- FORM -------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <CertificationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <CertificationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <CertificationFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 4 && (
                        <CertificationFormPage4 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CreateCertification;
