import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Accordion, AccordionSummary, Button, Grid, IconButton, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { ExpandMore as IconExpand, Assignment as IconDetail } from '@material-ui/icons';

// Services
import { getTranslation, getFileExtension, fileUrl, mobitoolsColor } from 'services/helpers';
import { toolTypes } from 'services/constants';
import { getTools, getSkillbase } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    row: {
        marginBottom: 5,
        padding: '5px 15px',
        borderRadius: 3,
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
    button: {
        textTransform: 'none',
        padding: '0 16px',
        color: 'white',
    },
};

// --------------------------------------- SKILLBASE TOOLS ------------------------------------- \\
const SkillbaseTools = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { skillbaseId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        tools: [],
        skillbase: null,
        loadingSkillbase: false,
    });

    useEffect(() => {
        if (state.loading) {
            getTools(true, null, null, null, null, skillbaseId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, tools: res.data.data.tools, loadingSkillbase: true });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingSkillbase) {
            getSkillbase(skillbaseId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loadingSkillbase: false, skillbase: res.data.data.skillbase });
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingSkillbase: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingSkillbase]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => history.push('/mobitools/tools')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fs16, ...style.fwB }}>
                        {state.skillbase ? getTranslation('name', state.skillbase) : ''}
                    </span>
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: mobitoolsColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={10} style={style.p10}>
                                <span style={style.cW}>{t('tools')}</span>
                            </Grid>
                            <Grid item container xs={2} justifyContent="center" style={style.p10}>
                                <span style={style.cW}>{t('links')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('tools')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* ---------------------- TOOL LIST ---------------------- */}
                <ToolList
                    tools={state.tools}
                    loading={state.loading}
                    error={state.error}
                />
            </div>
        </>
    );
};

// ----------------------------------------- TOOL LIST ----------------------------------------- \\
const ToolList = ({ tools, loading, error }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        tool: null,
        openLinksModal: false,
    });

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div>
                {toolTypes.map(toolType => {
                    const filtered = tools.filter(tool => tool.tool_type.id === toolType.id);
                    return (
                        <Accordion key={toolType.id}>
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <span style={style.fwB}>
                                    {t(toolType.label)} ({filtered.length})
                                </span>
                            </AccordionSummary>
                            <Divider />
                            <div>
                                {filtered.length > 0 ? (
                                    filtered.map((tool, index) => (
                                        <Fragment key={tool.id}>
                                            {index > 0 && <Divider />}
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={5} style={style.p10}>
                                                    <span>{getTranslation('name', tool)}</span>
                                                </Grid>
                                                <Grid item xs={12} md={5} style={style.p10}>
                                                    <span>{tool.description}</span>
                                                </Grid>
                                                <Grid item container xs={12} md={2} justifyContent="center" >
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openLinksModal: true, tool })}
                                                        className="list-btn"
                                                    >
                                                        <IconDetail />
                                                    </IconButton> 
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ))
                                ) : (
                                    <div style={style.p10}>
                                        <span>{t('none')}</span>
                                    </div>
                                )}
                            </div>
                        </Accordion>
                    )
                })}
            </div>
            {state.openLinksModal && (
                <LinksModal
                    onClose={() => setState({ ...state, openLinksModal: false })}
                    tool={state.tool}
                />
            )}
        </>
    );
};

// ---------------------------------------- LINKS MODAL ---------------------------------------- \\
const LinksModal = ({ onClose, tool }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {getTranslation('name', tool)}
                </p>
                {tool.tool_links.length > 0 ? (
                    tool.tool_links.map(link => {
                        if (link.link_type.id === 1) {
                            return (
                                <Grid key={link.id} container justifyContent="space-between" alignItems="center" style={styles.row}>
                                    <span>
                                        {getTranslation('name', link.link_type) + ' (' + getFileExtension(link.url) + ') - ' +
                                        link.languages.map(l => ' ' + l.code.toUpperCase())}
                                    </span>
                                    <Button
                                        href={fileUrl + link.url}
                                        target="_blank"
                                        color="secondary"
                                        variant="contained"
                                        style={styles.button}
                                    >
                                        {t('download')}
                                    </Button>
                                </Grid>
                            );
                        }
                        else {
                            return (
                                <Grid key={link.id} container justifyContent="space-between" alignItems="center" style={styles.row}>
                                    <span>
                                        {getTranslation('name', link.link_type) + ' - ' +
                                        link.languages.map(l => ' ' + l.code.toUpperCase())}
                                    </span>
                                    <Button
                                        href={link.url}
                                        target="_blank"
                                        color="secondary"
                                        variant="contained"
                                        style={styles.button}
                                    >
                                        {t('access')}
                                    </Button>
                                </Grid>
                            );
                        }
                    })
                ) : (
                    <Grid container style={styles.row}>
                        <span>{t('none')}</span>
                    </Grid>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SkillbaseTools;
