import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Grid, FormControlLabel, FormGroup, Typography, Checkbox, RadioGroup, Radio } from '@material-ui/core';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ------------------------------------ FILTERS --------------------------------------- \\
const Filters = ({ state, setState }) => {
    const { t } = useTranslation();

    function checkedParticipants(size) {
        if (state.participants.includes(size)) {
            return true;
        }
        return false;
    }

    function handleParticipants(size, checked) {
        let participants = state.participants;
        if (checked) {
            participants.push(size);
        }
        else {
            const index = participants.findIndex(s => s === size);
            participants.splice(index, 1);
        }
        setState({ ...state, participants });
    }

    return (
        <>
            {/* -------------------- NUMBER -------------------- */}
            <InputTextGrid
                placeholder={t('tool.card.number')}
                value={state.number}
                onChange={e => setState({ ...state, number: e.target.value })}
                type="number"
                required
            />
            <Divider style={style.m15_0} />
            {/* -------------------- PARTICIPANTS -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('participants')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <FormGroup>
                        {['small', 'medium', 'large'].map(item => (
                            <FormControlLabel
                                key={item}
                                label={<Typography style={style.fs14}>{t('group.' + item)}</Typography>}
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={checkedParticipants(item)}
                                        onChange={e => handleParticipants(item, e.target.checked)}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- EQUIPEMENTS -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('equipements')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        value={state.equipment}
                        onChange={e => setState({ ...state, equipment: e.target.value })}
                    >
                        <FormControlLabel
                            control={<Radio value="1" />}
                            label={<Typography style={style.fs14}>{t('equipment.1')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="2" />}
                            label={<Typography style={style.fs14}>{t('equipment.2')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="3" />}
                            label={<Typography style={style.fs14}>{t('equipment.3')}</Typography>}
                            style={{ margin: 0 }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- PREPARATION -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('preparation')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        value={state.preparation}
                        onChange={e => setState({ ...state, preparation: e.target.value })}
                    >
                        <FormControlLabel
                            control={<Radio value="1" />}
                            label={<Typography style={style.fs14}>{t('preparation.1')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="2" />}
                            label={<Typography style={style.fs14}>{t('preparation.2')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="3" />}
                            label={<Typography style={style.fs14}>{t('preparation.3')}</Typography>}
                            style={{ margin: 0 }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- DURATION -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('duration')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        value={state.duration}
                        onChange={e => setState({ ...state, duration: e.target.value })}
                    >
                        {['10', '20', '30', '40', '50'].map(item => (
                            <FormControlLabel
                                key={item}
                                control={<Radio value={item} />}
                                label={<Typography style={style.fs14}>{t('less.than.' + item)}</Typography>}
                                style={{ margin: 0 }}
                            />
                        ))}
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- LEVEL -------------------- */}
            <Levels state={state} setState={setState} />
            <Divider style={style.m15_0} />
            {/* -------------------- PHASE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('phase')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        value={state.phase}
                        onChange={e => setState({ ...state, phase: e.target.value })}
                    >
                        <FormControlLabel
                            control={<Radio value="1" />}
                            label={<Typography style={style.fs14}>{t('phase.1')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="2" />}
                            label={<Typography style={style.fs14}>{t('phase.2')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="3" />}
                            label={<Typography style={style.fs14}>{t('phase.3')}</Typography>}
                            style={{ margin: 0 }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- AUDIENCE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('audience')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        value={state.audience}
                        onChange={e => setState({ ...state, audience: e.target.value })}
                    >
                        <FormControlLabel
                            control={<Radio value="1" />}
                            label={<Typography style={style.fs14}>{t('audience.1')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="2" />}
                            label={<Typography style={style.fs14}>{t('audience.2')}</Typography>}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            control={<Radio value="3" />}
                            label={<Typography style={style.fs14}>{t('audience.3')}</Typography>}
                            style={{ margin: 0 }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
            <Divider style={style.m15_0} />
            {/* -------------------- USABLE ONLINE -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                    <b className="input-title">{t('usable.online')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <RadioGroup
                        row
                        value={state.usable_online}
                        onChange={e => setState({ ...state, usable_online: e.target.value })}
                        style={{ ...style.w100, ...style.jcSA }}
                    >
                        <FormControlLabel
                            value="1"
                            label={t('yes')}
                            control={<Radio color="primary" />}
                            style={{ margin: 0 }}
                        />
                        <FormControlLabel
                            value="0"
                            label={t('no')}
                            control={<Radio />}
                            style={{ margin: 0 }}
                        />
                    </RadioGroup>
                </Grid>
            </Grid>
        </>
    );
};

// ------------------------------------- LEVELS --------------------------------------- \\
const Levels = ({ state, setState }) => {
    const { t } = useTranslation();

    function onChangeLevel(level, checked) {
        let levels = state.levels;
        let checkLevels = state.checkLevels;
        checkLevels[level] = checked;

        if (!checked) {
            levels = levels.filter(item => item.level !== level);
        }

        setState({ ...state, checkLevels, levels });
    }

    function stepIsChecked(level, step) {
        if (state.levels.findIndex(item => item.level === level && item.step === step) !== -1) {
            return true;
        }
        return false;
    }

    function onChangeStep(level, step, checked) {
        let levels = state.levels;
        if (checked) {
            levels.push({ level: level, step: step });
        }
        else {
            const index = state.levels.findIndex(item => item.level === level && item.step === step);
            levels.splice(index, 1);
        }
        setState({ ...state, levels });
    }

    return (
        <>
            <div style={{ textAlign: 'center', marginBottom: 10 }}>
                <span style={style.fwB}>{t('level')}</span>
            </div>
            <Grid container>
                {[1, 2, 3, 4].map((level, index) => (
                    <Grid key={index} container alignItems="center" style={{ border: '1px solid lightgray', padding: 10 }}>
                        <Checkbox
                            checked={state.checkLevels[level]}
                            onChange={e => onChangeLevel(level, e.target.checked)}
                        />
                        <span>{t('level') + ' ' + level}</span>
                        {state.checkLevels[level] && (
                            <Grid container alignItems="center" justifyContent="center">
                                <span style={style.mr5}>{t('steps')}:</span>
                                {[1, 2, 3, 4, 5, 6, 7, 8].map(step => (
                                    <FormControlLabel
                                        key={step}
                                        label={<Typography style={style.fs14}>{step}</Typography>}
                                        style={{ margin: 0 }}
                                        control={
                                            <Checkbox
                                                checked={stepIsChecked(level, step)}
                                                onChange={e => onChangeStep(level, step, e.target.checked)}
                                            />
                                        }
                                    />
                                ))}
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Filters;
