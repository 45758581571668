import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, MenuItem } from '@material-ui/core';

// Services
import { getCompanies, getBill } from 'services/requests';
import { subscriptionTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import DatePickerGrid from 'components/items/date-picker-grid';
import SelectOutlined from 'components/items/select-outlined';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------------- SUBSCRIPTION FORM ---------------------------------------- \\
const SubscriptionForm = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();

    return (
        <>
            {/* -------------------- SUBSCRIPTION TYPE -------------------- */}
            <Grid container alignItems="center" style={style.m5_0}>
                <Grid
                    item xs={12} sm={4}
                    style={{ paddingRight: mediaSM ? 5 : 0 }}
                >
                    <b className="input-title">{t('subscription.type')}</b><FieldRequired />
                </Grid>
                <Grid
                    item xs={12} sm={8}
                    style={{ paddingLeft: mediaSM ? 5 : 0 }}
                >
                    <SelectOutlined
                        value={state.subscription_type_id}
                        onChange={e => setState({
                            ...state,
                            subscription_type_id: e.target.value,
                            price: '',
                            nb_items: '',
                        })}
                    >
                        {subscriptionTypes.map(subscriptionType => (
                            <MenuItem key={subscriptionType.id} value={subscriptionType.id}>
                                <span>{t(subscriptionType.label)}</span>
                            </MenuItem>
                        ))}
                    </SelectOutlined>
                </Grid>
            </Grid>
            {/* -------------------- COMPANY -------------------- */}
            <Grid container alignItems="center" style={style.m5_0}>
                <Grid
                    item xs={12} sm={4}
                    style={{ paddingRight: mediaSM ? 5 : 0 }}
                >
                    <b className="input-title">{t('company')}</b><FieldRequired />
                </Grid>
                <Grid
                    item xs={12} sm={8}
                    style={{ paddingLeft: mediaSM ? 5 : 0 }}
                >
                    <SelectCompany
                        companyId={state.company_id}
                        setCompanyId={company_id => setState({ ...state, company_id })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- START DATE -------------------- */}
            <DatePickerGrid
                value={state.start_date}
                onChange={value => setState({ ...state, start_date: value })}
                placeholder={t('start.date')}
                outlined
                required
            />
            {/* -------------------- END DATE -------------------- */}
            <DatePickerGrid
                value={state.end_date}
                onChange={value => setState({ ...state, end_date: value })}
                placeholder={t('end.date')}
                outlined
                required
            />
            {state.subscription_type_id === 3 && (
                <>
                    {/* -------------------- PRICE -------------------- */}
                    <InputTextGrid
                        value={state.price}
                        onChange={e => setState({ ...state, price: e.target.value })}
                        placeholder={t('price')}
                        type="number"
                        required
                    />
                    {/* -------------------- NB ITEMS -------------------- */}
                    <InputTextGrid
                        value={state.nb_items}
                        onChange={e => setState({ ...state, nb_items: e.target.value })}
                        placeholder={t('nb.items')}
                        type="number"
                        required
                    />
                </>
            )}
        </>
    );
};

// ---------------------------------------- SELECT COMPANY ---------------------------------------- \\
const SelectCompany = ({ companyId, setCompanyId }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();

    const [state, setState] = useState({
        loading: true,
        error: undefined,
        companies: undefined,
        client_id: undefined,
    });

    useEffect(() => {
        getBill(match.params.billId).then(res => {
            if (res?.status === 200) {
                setState({ ...state, client_id: res.data.data.bill.contract.client.id });
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.client_id) {        
            getCompanies(0, 50, null, null, null, null, null, state.client_id).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        companies: res.data.data.companies,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.client_id]);

    if (state.loading) return <span>{t('loading')}</span>;
    if (state.error) return <span>{t('error.occured')}</span>;

    return (
        <SelectOutlined value={companyId} onChange={e => setCompanyId(e.target.value)}>
            {(state.companies && state.companies.length > 0) ? (
                state.companies.map(company => (
                    <MenuItem key={company.id} value={company.id}>
                        <span>{company.name}</span>
                    </MenuItem>
                ))
            ) : (
                <MenuItem value="">
                    <span>{t('none')}</span>
                </MenuItem>
            )}
        </SelectOutlined>
    );
};

export default SubscriptionForm;
