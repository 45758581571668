import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getUserFormations } from 'services/requests';
import { getFormattedDate, mobitrainColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Select from 'components/items/react-select';

// ---------------------------------------- MOBILITY FORM PAGE 4 ---------------------------------------- \\
const MobilityFormPage4 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    useEffect(() => {
        getUserFormations(state.learner_id).then(res => {
            if (res?.status === 200) {
                setState({
                    ...state,
                    formationsOptions: res.data.data.formations.map(f => ({ value: f.id, label: getFormattedDate(f.formation_date) + ' - ' + f.name })),
                });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- FORMATIONS -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('trainings.select')}</b>
                            <Select
                                placeholder={t('trainings')}
                                value={state.formations}
                                onChange={formations => setState({ ...state, formations })}
                                options={state.formationsOptions}
                                isMulti
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 3 })} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>4 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button
                        onClick={() => setState({ ...state, loading: true })}
                        variant="contained"
                        style={{ backgroundColor: mobitrainColor, color: 'white' }}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default MobilityFormPage4;
