import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { createSpecificBehavior } from 'services/requests';
import { getTranslation, initialLanguagesObject } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// -------------------------------------- CREATE SPECIFIC BEHAVIOR ------------------------------------- \\
const CreateSpecificBehavior = ({ onClose, refetch, soft_unit }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        definition: initialLanguagesObject(),
    });

    function handleCreateSpecificBehavior() {
        if (Object.values(state.definition).every(definition => definition === '')) {
            enqueueSnackbar(t('definition.enter'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    function handleDefinition(event, code) {
        setState({
            ...state,
            definition: Object.assign({}, state.definition, { [code]: event.target.value })
        });
    }

    useEffect(() => {
        if (state.loading) {
            createSpecificBehavior({
                soft_unit_id: soft_unit.id,
                definition: state.definition,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.specific_behavior);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('specific.behavior.add')}
                        </p>
                        <Divider />
                        <p style={{ ...style.taC, ...style.mt10 }}>
                            {getTranslation('name', soft_unit.softskill)}
                        </p>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.definition[language.code]}
                                onChange={(e) => handleDefinition(e, language.code)}
                                multiline
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleCreateSpecificBehavior} variant="contained">
                            {t('add')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateSpecificBehavior;
