import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Material-UI
import { Menu, MenuItem, Divider, Button, Dialog, DialogActions, DialogContent, Badge } from '@material-ui/core';
import {
    HomeRounded as IconHome, PowerSettingsNew as IconPower, Apps as IconMenu,
    Build as IconTools, School as IconTrain, AssignmentTurnedIn as IconPass,
    People as IconDirectory, Email as IconSupport, Settings as IconManagement,
    SwapHorizontalCircle as IconAccessRequest, Http as WebIcon, MenuBook as IconNews
} from '@material-ui/icons';

// Services
import { signOut, getNewsReadingStatus } from 'services/requests';
import { clearToken, getStoredUser, setRefetchNews, getRefetchNews } from 'services/storage';
import { mobipassColor, mobitrainColor, mobitoolsColor, servicesColor, theme } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// CSS Styles
const styles = {
    link: {
        textDecoration: 'none',
    },
    menu: {
        display: 'inline-flex',
    },
    icon: {
        marginRight: 10,
    },
};

// ---------------------------------------- NAVIGATION MENU ---------------------------------------- \\
const NavigationMenu = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        openLogoutModal: false,
        anchorEl: null,
    });
    const open = Boolean(state.anchorEl);

    const navigateTo = link => {
        setState({ ...state, anchorEl: null });
        history.push(link);
    };

    return (
        <>
            {/* -------------------- ICON MENU -------------------- */}
            <Button
                style={{ outline: 'none', color: 'white', marginLeft: 15 }}
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={event => setState({ ...state, anchorEl: event.currentTarget })}
            >
                <span style={style.mr5}>MENU</span>
                <IconMenu style={{ width: 28, height: 28 }} />
            </Button>
            {/* -------------------- MENU -------------------- */}
            <Menu
                id="menu-appbar"
                anchorEl={state.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setState({ ...state, anchorEl: null })}
                MenuListProps={{ style: { padding: 0 } }}
            >
                <MenuItem onClick={() => navigateTo('/dashboard')} style={{ padding: '10px 16px' }}>
                    <IconHome style={{ ...styles.icon, color: 'gray' }} />
                    <span>{t('home')}</span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigateTo('/mobipass')}>
                    <IconPass style={{ ...styles.icon, color: mobipassColor }} />
                    <span>MOBIPass</span>
                </MenuItem>
                <MenuItem onClick={() => navigateTo('/mobitrain')}>
                    <IconTrain style={{ ...styles.icon, color: mobitrainColor }} />
                    <span>MOBITrain</span>
                </MenuItem>
                <MenuItem onClick={() => navigateTo('/mobitools')}>
                    <IconTools style={{ ...styles.icon, color: mobitoolsColor }} />
                    <span>MOBITools</span>
                </MenuItem>
                <Divider />
                {ROLE_ID < 5 && (
                    <MenuItem onClick={() => navigateTo('/management')}>
                        <IconManagement style={{ ...styles.icon, color: servicesColor }} />
                        <span>{t('management')}</span>
                    </MenuItem>
                )}
                <NewsMenu
                    navigateTo={navigateTo}
                />
                {(ROLE_ID === 5 && getStoredUser().company == null) && (
                    <MenuItem onClick={() => navigateTo('/access-requests')}>
                        <IconAccessRequest style={{ ...styles.icon, color: servicesColor }} />
                        <span>{t('access.requests')}</span>
                    </MenuItem>
                )}
                <MenuItem onClick={() => navigateTo('/directory')}>
                    <IconDirectory style={{ ...styles.icon, color: servicesColor }} />
                    <span>{t('directory')}</span>
                </MenuItem>
                <MenuItem onClick={() => navigateTo('/support')}>
                    <IconSupport style={{ ...styles.icon, color: servicesColor }} />
                    <span>{t('support')}</span>
                </MenuItem>
                {theme === 'mobirhin' && (
                    <>
                        <Divider />
                        <MenuItem>
                            <a
                                target="_blank" 
                                rel="noopener noreferrer" 
                                href="https://www.participation4-0.eu/"
                                style={{ textDecoration: 'none', color: 'black' }}
                            >
                                <WebIcon style={{ ...styles.icon, color: servicesColor }} />
                                <span>Participation 4.0</span>
                            </a>
                        </MenuItem>
                    </>
                )}
                <Divider />
                <MenuItem onClick={() => setState({ ...state, openLogoutModal: true })}  style={{ padding: '10px 16px' }}>
                    <IconPower style={{ ...styles.icon, color: 'gray' }} />
                    <span>{t('sign.out')}</span>
                </MenuItem>
            </Menu>
            {/* -------------------- LOGOUT MODAL -------------------- */}
            {state.openLogoutModal && (
                <LogoutModal
                    open={state.openLogoutModal}
                    onClose={() => setState({ ...state, openLogoutModal: false, anchorEl: null })}
                />
            )}
        </>
    );
};

// ----------------------------------------- LOGOUT MODAL ------------------------------------------ \\
const LogoutModal = ({ open, onClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            signOut().then(() => {
                clearToken();
                window.location.reload();
            });
        }
    }, [loading]);

    return (
        <Dialog open={open}>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ textAlign: 'center', paddingBottom: 20 }}>
                        <span className="dialog-title">
                            {t('disconnection')}
                        </span>
                        <br />
                        <span>
                            {t('sign.out.confirm')}
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ------------------------------------------- NEWS MENU ------------------------------------------- \\
const NewsMenu = ({ navigateTo }) => {
    const { t } = useTranslation();
    const lastGetNews = getRefetchNews();
    const storedUserId = getStoredUser()?.id;
    const [hasReadLastNews, setRead] = useState(true);

    const isNotTheSameDate = () => {
        if (moment().format('YYYY-MM-DD') !== moment(lastGetNews).format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    };

    const isNotTheSameHour = () => {
        if (moment().hours() !== moment(lastGetNews).hours()) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (lastGetNews == null || (lastGetNews != null && (isNotTheSameDate() || isNotTheSameHour()))) {
            getNewsReadingStatus(storedUserId).then(res => {
                if (res?.status === 200) {
                    setRefetchNews();
                    setRead(res.data.data.has_read_last_news);
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MenuItem onClick={() => navigateTo('/news')}>
            <Badge
                color="secondary" overlap="circular" variant="dot"
                invisible={hasReadLastNews}
                style={{ marginRight: 10 }}
            >
                <IconNews style={{ ...styles.icon, color: servicesColor, marginRight: -0.1 }} />
            </Badge>
            <span>{t('news')}</span>
        </MenuItem>
    );
};

export default NavigationMenu;
