import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Button, Accordion, AccordionSummary, Grid, Checkbox, CircularProgress } from '@material-ui/core';
import { ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getTranslation, mobipassColor, servicesColor } from 'services/helpers';
import { getSkillbases, getSkillbase, searchLearners } from 'services/requests';

// Components
import LearnerList from './learner-list';
import Select from 'components/items/react-select';
import Badge from 'components/items/badge';

// Style
import style from 'style';

// ---------------------------------------- RESEARCH ---------------------------------------- \\
const SkillbasesSearch = () => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        // Select
        skillbases: [],
        skillbase_id: null,

        // Skillbase
        skillbase: null,
        expanded: null,
        learning_ids: [],
        soft_unit_ids: [],

        // Users
        searchingLearners: false,
        errorLearners: false,
        searchEnded: false,
        users: [],
        filteredUsers: [],
        companies: [],
        company_id: null,
        learner: '',
    });

    const resetState = skillbase => {
        setState({
            ...state,
            skillbase: skillbase || null,
            expanded: null,
            learning_ids: [],
            soft_unit_ids: [],
            searchingLearners: false,
            errorLearners: false,
            searchEnded: false,
            users: [],
            filteredUsers: [],
            companies: [],
            company_id: null,
            learner: '',
        });
    };

    useEffect(() => {
        getSkillbases().then(res => {
            if (res?.status === 200) {
                setState({ ...state, skillbases: res.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })) });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.skillbase_id) {
            getSkillbase(state.skillbase_id).then(res => {
                if (res?.status === 200) {
                    resetState(res.data.data.skillbase);
                }
                else {  
                    console.log(res);
                    resetState();
                }
            });
        }
        else {
            resetState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.skillbase_id]);

    return (
        <>
            {state.searchEnded === false && (
                <>
                    {/* -------------------- TITLE -------------------- */}
                    <Grid container justifyContent="center" style={{ ...style.p10, backgroundColor: mobipassColor }}>
                        <span style={style.cW}>{t('search.on.skillbases')}</span>
                    </Grid>
                    {/* -------------------- SEARCH INPUT -------------------- */}
                    <Grid container style={{ padding: 24, border: '1px solid lightgray' }}>
                        <Grid item xs={false} sm={1} md={2} />
                        <Grid item xs={12} sm={10} md={8}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ paddingRight: 5 }}>
                                    <b className="input-title">{t('skillbase.choose')}</b>
                                </div>
                                <div style={{ paddingLeft: 5, flex: 1 }}>
                                    <Select
                                        placeholder={t('skillbase')}
                                        value={state.skillbase_id ? state.skillbases.find(s => s.value === state.skillbase_id) : null}
                                        onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null })}
                                        options={state.skillbases}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {/* -------------------- SKILLBASE -------------------- */}
                    {state.skillbase != null && (
                        <Skillbase
                            state={state}
                            setState={setState}
                        />
                    )}
                </>
            )}
            {/* -------------------- LEARNER LIST -------------------- */}
            {(state.skillbase != null && state.searchEnded === true) && (
                <LearnerList
                    backgroundColor={mobipassColor}
                    state={state}
                    setState={setState}
                />
            )}
        </>
    );
};

// ---------------------------------------- SKILLBASE --------------------------------------- \\
const Skillbase = ({ state, setState }) => {
    const { t } = useTranslation();

    const onChangeAccordion = unit_id => {
        if (state.expanded === unit_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: unit_id });
        }
    };

    const getUnitChecked = unit => {
        if (unit.learning_outcomes.every(lo => state.learning_ids.includes(lo.id))
            && unit.soft_units.every(su => state.soft_unit_ids.includes(su.id))
        ) {
            return true;
        }
        return false;
    };

    const onCheckUnit = (event, unit) => {
        event.stopPropagation();

        let learning_ids = state.learning_ids, soft_unit_ids = state.soft_unit_ids;
        if (event.target.checked) {

            unit.learning_outcomes.forEach(learning => {
                learning_ids.push(learning.id);
            });
            learning_ids = [...new Set(learning_ids)];

            unit.soft_units.forEach(soft_unit => {
                soft_unit_ids.push(soft_unit.id);
            });
            soft_unit_ids = [...new Set(soft_unit_ids)];
        }
        else {

            unit.learning_outcomes.forEach(learning => {
                let index = learning_ids.findIndex(id => id === learning.id);
                learning_ids.splice(index, 1);
            });

            unit.soft_units.forEach(soft_unit => {
                let index = soft_unit_ids.findIndex(id => id === soft_unit.id);
                soft_unit_ids.splice(index, 1);
            });
        }

        setState({ ...state, learning_ids, soft_unit_ids });
    };

    const getItemsSelectedNumber = unit => {
        let number = 0;
        unit.learning_outcomes.forEach(learning => {
            if (state.learning_ids.includes(learning.id)) {
                number += 1;
            }
        });
        unit.soft_units.forEach(soft_unit => {
            if (state.soft_unit_ids.includes(soft_unit.id)) {
                number += 1;
            }
        });
        return number;
    };

    const onCheckLearning = (checked, learning) => {
        let learning_ids = state.learning_ids;
        if (checked) {
            learning_ids.push(learning.id);
        }
        else {
            let index = learning_ids.findIndex(id => id === learning.id);
            learning_ids.splice(index, 1);
        }
        setState({ ...state, learning_ids });
    };

    const onCheckSoftUnit = (checked, soft_unit) => {
        let soft_unit_ids = state.soft_unit_ids;
        if (checked) {
            soft_unit_ids.push(soft_unit.id);
        }
        else {
            let index = soft_unit_ids.findIndex(id => id === soft_unit.id);
            soft_unit_ids.splice(index, 1);
        }
        setState({ ...state, soft_unit_ids });
    };

    useEffect(() => {
        if (state.searchingLearners) {
            searchLearners('skillbases', {
                learning_outcomes: state.learning_ids,
                soft_unit_ids: state.soft_unit_ids,
            }).then(res => {
                if (res?.status === 200) {
                    const data = res.data.data;
                    setState({
                        ...state,
                        searchingLearners: false,
                        searchEnded: true,
                        users: data.users,
                        filteredUsers: data.users,
                        companies: data.companies.map(c => ({ value: c.id, label: c.name })),
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, searchingLearners: false, errorLearners: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.searchingLearners]);

    return (
        <>
            {(state.skillbase.units != null && state.skillbase.units.length > 0) ? (
                <>
                    <div>
                        {state.skillbase.units.map(unit => (
                            <Accordion 
                                key={unit.id}
                                expanded={state.expanded === unit.id}
                                onChange={() => onChangeAccordion(unit.id)}
                            >
                                <AccordionSummary expandIcon={<IconExpand />}>
                                    {/* -------------------- UNIT -------------------- */}
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox 
                                                checked={getUnitChecked(unit)}
                                                onClick={e => onCheckUnit(e, unit)}
                                                color="default"
                                                style={{ padding: 8 }}
                                            />
                                            <span>{unit.position}. {getTranslation('name', unit)}</span>
                                        </div>
                                        <Badge number={getItemsSelectedNumber(unit)} />
                                    </Grid>
                                </AccordionSummary>
                                <Divider />
                                <div style={style.p24}>
                                    {/* -------------------- LEARNING OUTCOMES -------------------- */}
                                    <Grid container style={{ ...style.p10_24, backgroundColor: mobipassColor }}>
                                        <span style={style.cW}>{t('learning.outcomes')}</span>
                                    </Grid>
                                    {unit.learning_outcomes.length > 0 ? (
                                        unit.learning_outcomes.map(learning => (
                                            <Fragment key={learning.id}>
                                                <Grid container alignItems='center' style={style.p5}>
                                                    <Checkbox
                                                        checked={state.learning_ids.includes(learning.id)}
                                                        onClick={e => onCheckLearning(e.target.checked, learning)}
                                                        color="default"
                                                        style={{ padding: 8 }}
                                                    />
                                                    <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                                                </Grid>
                                                <Divider />
                                            </Fragment>
                                        ))
                                    ) : (
                                        <>
                                            <Grid style={style.p10_24}>
                                                <span>{t('none')}</span>
                                            </Grid>
                                            <Divider />
                                        </>
                                    )}
                                    {/* -------------------- SOFT SKILLS -------------------- */}
                                    <Grid container style={{ ...style.p10_24, backgroundColor: servicesColor }}>
                                        <span style={style.cW}>{t('softskills')}</span>
                                    </Grid>
                                    {unit.soft_units.length > 0 ? (
                                        unit.soft_units.map(soft_unit => (
                                            <Fragment key={soft_unit.id}>
                                                <Grid container alignItems='center' style={style.p5}>
                                                    <Checkbox
                                                        checked={state.soft_unit_ids.includes(soft_unit.id)}
                                                        onClick={e => onCheckSoftUnit(e.target.checked, soft_unit)}
                                                        color="default"
                                                        style={{ padding: 8 }}
                                                    />
                                                    <span>
                                                        {getTranslation('name', soft_unit.softskill)}
                                                        &nbsp;-&nbsp;
                                                        {t('level')}&nbsp;{soft_unit.soft_level.level}
                                                    </span>
                                                </Grid>
                                                <Divider />
                                            </Fragment>
                                        ))
                                    ) : (
                                        <>
                                            <Grid style={style.p10_24}>
                                                <span>{t('none')}</span>
                                            </Grid>
                                            <Divider />
                                        </>
                                    )}
                                </div>
                            </Accordion>
                        ))}
                    </div>
                    <div style={{ padding: 24, textAlign: 'center' }}>
                        <Button
                            onClick={() => setState({ ...state, searchingLearners: true })}
                            disabled={state.searchingLearners || (state.learning_ids.length === 0 && state.soft_unit_ids.length === 0)}
                            variant="contained"
                            style={{ width: 160 }}
                        >
                            {t('research')}
                            {state.searchingLearners && (
                                <CircularProgress
                                    color="inherit" size={20}
                                    style={{ marginLeft: 5 }}
                                />
                            )}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Grid style={style.p10_24}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </>
    );
};

export default SkillbasesSearch;
