import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, IconButton, Divider, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import { ChevronLeft as IconBack, ChevronRight as IconNext, CheckCircle as IconCheckCircle, Cancel as IconCancel } from '@material-ui/icons';

// Services
import { softskillsColor, getTranslation, softskillsColor2 } from 'services/helpers';

// Style
import style from 'style';

const styles = {
    tdLevelHeader: {
        border: '1px solid lightgray',
        wordBreak: 'break-word',
        width: '25%',
        maxWidth: '25%',
        padding: 10,
        textAlign: 'center',
        fontSize: 14,
    },
    tdLevel: {
        border: '1px solid lightgray',
        wordBreak: 'break-word',
        width: '25%',
        maxWidth: '25%',
        padding: 10,
        fontSize: 14,
    },
    p: {
        overflow: 'auto',
        height: 84,
        marginBottom: 0,
    },
};

// ------------------------------------- SOFT SKILLS EVALUATION ---------------------------------------- \\
const SoftSkillsEvaluation = ({ state, setState, solo }) => {
    const { t } = useTranslation();
    const user_id = state.users[state.index].id;

    const handleStyle = (soft_unit, soft_level) => {
        const index = state.soft_portfolios.findIndex(sp =>
            sp.user_id === user_id
            && sp.soft_unit_id === soft_unit.id
            && sp.soft_level_id === soft_level.id
        );
        return {
            backgroundColor: index !== -1
            ? softskillsColor2
            : null
        }
    }

    const handleValidatedLevel = soft_unit => {
        const soft_portfolio = state.soft_portfolios.find(sp =>
            sp.user_id === user_id
            && sp.soft_unit_id === soft_unit.id
        );
        

        let soft_level = null;
        if (soft_portfolio != null) {
            soft_level = soft_unit.softskill.soft_levels.find(soft_level => soft_level.id === soft_portfolio.soft_level_id);
        }

        if (soft_level != null && soft_level.level >= soft_unit.soft_level.level) {
            return true;
        }
        return false;
    }

    const handleSoftLevel = (soft_unit_id, soft_level) => {
        const soft_level_id = soft_level.id;
        const soft_portfolios = state.soft_portfolios;

        const soft_portfolio = soft_portfolios.find(sp =>
            sp.user_id === user_id
            && sp.soft_unit_id === soft_unit_id
        );
        if (soft_portfolio.soft_level_id === soft_level_id) {
            soft_portfolio.soft_level_id = null;
        }
        else {
            soft_portfolio.soft_level_id = soft_level_id;
        }

        setState({ ...state, soft_portfolios, soft_level });
    }

    return (
        <>
            <Grid container style={{ backgroundColor: softskillsColor, height: solo ? 0 : 40 }} />
            {state.soft_units.length > 0 ? (
                <>
                    {/* ---------------------- SELECTED USER ---------------------- */}
                    <Grid container>
                        <Grid item xs={2} container justifyContent="center">
                            <IconButton   
                                onClick={() => setState({ ...state, index: state.index -= 1 })}
                                disabled={state.index === 0}
                            >
                                <IconBack />
                            </IconButton>
                        </Grid>
                        <Grid item xs={8} container justifyContent="center" alignItems="center" style={style.p10}>
                            <span>{state.users[state.index].full_name}</span>
                        </Grid>
                        <Grid item xs={2} container justifyContent="center">
                            <IconButton
                                onClick={() => setState({ ...state, index: state.index += 1 })}
                                disabled={state.index + 1 === state.users.length}
                            >
                                <IconNext />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {/* ---------------------- SOFT UNITS ---------------------- */}
                    <div className="soft-level-table" style={style.mb25}>
                        <table style={style.w100}>
                            <tbody>
                                {state.soft_units.map(soft_unit => (
                                    <tr key={soft_unit.id}>
                                        <td
                                            onClick={() => setState({ ...state, openSoftskillModal: true, soft_unit })}
                                            className="soft-level-td list-item-hover"
                                            style={style.taC}
                                        >
                                            <span style={style.fwB}>
                                                {getTranslation('name', soft_unit.softskill)}
                                            </span>
                                            <br />
                                            <span>
                                                {t('level')}&nbsp;{soft_unit.soft_level.level}&nbsp;{t('required')}
                                            </span>
                                            <br />
                                            {handleValidatedLevel(soft_unit) ? (
                                                <IconCheckCircle style={{ color: 'green', fontSize: 30 }} />
                                            ) : (
                                                <IconCancel style={{ color: '#DA6236', fontSize: 30 }} />
                                            )}
                                        </td>
                                        {soft_unit.softskill.soft_levels.map(soft_level => (
                                            <td
                                                key={soft_level.id}
                                                className="soft-level-td"
                                                onClick={() => handleSoftLevel(soft_unit.id, soft_level)}
                                            >
                                                <Grid
                                                    container
                                                    className="soft-level"
                                                    style={handleStyle(soft_unit, soft_level)}
                                                >
                                                    <p style={{ ...style.taC, ...style.fwB, ...style.w100 }}>
                                                        {t('level')}&nbsp;{soft_level.level}
                                                        <br />
                                                        {soft_level.level === 1 && t('remember.and.understand')}
                                                        {soft_level.level === 2 && t('apply')}
                                                        {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                        {soft_level.level === 4 && t('create')} 
                                                    </p>
                                                    <p className="soft-level-definition">
                                                        {getTranslation('definition', soft_level)}
                                                    </p>
                                                </Grid>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <>
                    <Grid container style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            {/* ---------------------- SOFT SKILL MODAL ---------------------- */}
            {state.openSoftskillModal && (
                <SoftSkillModal
                    onClose={() => setState({ ...state, openSoftskillModal: false })}
                    softskill={state.soft_unit.softskill}
                    specific_behaviors={state.soft_unit.specific_behaviors}
                />
            )}
        </>
    );
};

// ---------------------------------------- SOFT SKILL MODAL ------------------------------------------- \\
const SoftSkillModal = ({ onClose, softskill, specific_behaviors }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        tr_array_behaviors: [],
    });

    useEffect(() => {
        if (softskill) {
            let nb_behaviors = 0;
            for (let i = 0; i < 4; i += 1) {
                const behaviors_number = softskill.soft_levels[i].behaviors.length;
                if (nb_behaviors < behaviors_number) {
                    nb_behaviors = behaviors_number;
                }
            }
            setState({ ...state, tr_array_behaviors: Array.apply(null, { length: nb_behaviors }).map(Number.call, Number) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softskill]);

    return (
        <Dialog open fullWidth maxWidth="md">
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {getTranslation('name', softskill)}
                </p>
                {/* ---------------------- BEHAVIORS ---------------------- */}
                <table style={style.w100}>
                    <thead>
                        <tr>
                            <td colSpan={4} style={{ backgroundColor: softskillsColor, ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('observable.behavior')}</span>
                            </td>
                        </tr>
                        <tr>
                            {softskill.soft_levels.map(soft_level => (
                                <td key={soft_level.id} style={styles.tdLevelHeader}>
                                    {t('level')}&nbsp;{soft_level.level}
                                    <br />
                                    {soft_level.level === 1 && t('remember.and.understand')}
                                    {soft_level.level === 2 && t('apply')}
                                    {soft_level.level === 3 && t('analyse.and.evaluate')}
                                    {soft_level.level === 4 && t('create')}
                                </td>
                            ))}
                        </tr>
                    </thead>      
                    <tbody>
                        {state.tr_array_behaviors.length > 0 ? (
                            state.tr_array_behaviors.map(tr => (
                                <tr key={tr}>
                                    {[0, 1, 2, 3].map(index => {
                                        const behavior = softskill.soft_levels[index].behaviors[tr];
                                        return (
                                            <td key={index} style={styles.tdLevel}>
                                                {behavior && (
                                                    <p style={styles.p}>
                                                        {behavior.position}. {getTranslation('definition', behavior)}
                                                    </p>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} style={styles.tdLevel}>
                                    {t('none')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* ---------------------- SPECIFIC BEHAVIORS ---------------------- */}
                <table style={style.w100}>
                    <thead>
                        <tr>
                            <td colSpan={4} style={{ backgroundColor: softskillsColor, ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('specific.behaviors')}</span>
                            </td>
                        </tr>
                    </thead>      
                    <tbody>
                        {specific_behaviors.length > 0 ? (
                            specific_behaviors.map(sb => (
                                <tr key={sb.id}>
                                    <td colSpan={4} style={styles.tdLevel}>
                                        {getTranslation('definition', sb)}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} style={styles.tdLevel}>
                                    {t('none')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SoftSkillsEvaluation;
