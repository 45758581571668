import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid } from '@material-ui/core';

// Services 
import { createCleaDocument } from 'services/requests';
import { cleaColor } from 'services/helpers';
import { cleaDocumentTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import InputFileGrid from 'components/items/input-file-grid';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- CREATE CLEA PROOF ---------------------------------------- \\
const CreateCleaProof = ({ onClose, refetch, clea_formation_id, learner_id }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaSM } = useMediaQueries();
    const documentTypes = cleaDocumentTypes.map(type => ({ value: type.value, label: t(type.label) }));

    const [state, setState] = useState({
        loading: false,
        name: '',
        type: null,
        document: null,
        description: '',
    });

    const saveDocument = () => {
        const file = document.getElementById('upload-clea-document').files[0];
        if (state.name === '' || state.type == null || file == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true, document: file });
        }
    };

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('user_id', learner_id);
            formData.append('name', state.name);
            formData.append('type', state.type);
            formData.append('document', state.document);
            formData.append('description', state.description);

            createCleaDocument(clea_formation_id, formData).then(res => {
                if (res.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('clea.proof.document.add')}
                        </p>
                        {/* -------------------- NAME --------------------  */}
                        <InputTextGrid
                            placeholder={t('name')}
                            value={state.name}
                            onChange={e => setState({ ...state, name: e.target.value })}
                            required
                        />
                        {/* -------------------- DOCUMENT TYPE -------------------- */}
                        <Grid container alignItems="center" style={style.m5_0}>
                            <Grid
                                item xs={12} sm={4}
                                style={{ paddingRight: mediaSM ? 5 : 0 }}
                            >
                                <b className="input-title">{t('type')}</b><FieldRequired />
                            </Grid>
                            <Grid
                                item xs={12} sm={8}
                                style={{ paddingLeft: mediaSM ? 5 : 0 }}
                            >
                                <Select
                                    placeholder={t('type')}
                                    value={state.type ? documentTypes.find(t => t.value === state.type) : null}
                                    onChange={item => setState({ ...state, type: item ? item.value : null })}
                                    options={documentTypes}
                                />
                            </Grid>
                        </Grid>
                        {/* -------------------- DOCUMENT -------------------- */}
                        <InputFileGrid
                            placeholder={t('document')}
                            inputId="clea-document"
                            required
                        />
                        {/* -------------------- DESCRIPTION -------------------- */}
                        <InputTextGrid
                            placeholder={t('description')}
                            value={state.description}
                            onChange={e => setState({ ...state, description: e.target.value })}
                            multiline
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={saveDocument}
                            variant="contained"
                            style={{ backgroundColor: cleaColor, color: 'white' }}
                        >
                            {t('add')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateCleaProof;
