import React, { useState, useEffect } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getBill } from 'services/requests';
import { servicesColor, getFormattedDate } from 'services/helpers';

// Components
import GenerateSubscriptions from '../generate-subscriptions';

// Style
import style from 'style';

// ---------------------------------------- BILL INFOS ---------------------------------------- \\
const BillInfos = ({ refetch }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const { billId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        bill_id: null,
        paid: '',
        bill_date: '',
        number_bill: '',
        contract: '',
        total_amount_TTC: '',
        deadline_date: '',
        client_id: undefined,
        openGenerateSubscriptions: false,
    });

    function handleRefetch() {
        refetch();
        setState({ ...state, openGenerateSubscriptions: false });
    }

    useEffect(() => {
        if (state.loading) {
            getBill(billId).then(res => {
                if (res?.status === 200) {
                    const bill = res.data.data.bill;
                    setState(state => ({
                        ...state,
                        loading: false,
                        paid: bill.paid,
                        bill_date: bill.bill_date,
                        number_bill: bill.number_bill,
                        contract: bill.contract,
                        total_mount_TTC: bill.total_amount_TTC,
                        deadline_date: bill.deadline_date,
                        client_id: bill.contract.client.id,
                    }));
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <span style={style.m10}>{t('loading')}</span>;
    if (state.error) return <span style={style.m10}>{t('error.occured')}</span>;

    return (
        <>
            <div style={style.p10}>
                <b>{t('bill')}&nbsp;{state.number_bill ? state.number_bill : 'FATEMP' + match.params.billId}</b>
            </div>
            <div className="simple-container" style={{ paddingBottom: 24 }}>
                <Button
                    onClick={() => setState({ ...state, openGenerateSubscriptions: true })}
                    variant="contained"
                    style={style.mb10}
                >
                    {t('generate.subscriptions')}
                </Button>
                <Grid container alignItems="center" className="billing-infos">
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('paid')}:&nbsp;</span>
                        <span>{state.paid === 0 ? t('no') : t('yes')}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('bill.date')}&nbsp;</span>
                        <span>{state.bill_date ? getFormattedDate(state.bill_date) : ''}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('contract')}:&nbsp;</span>
                        <span>{state.contract.reference}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('total.amount.TTC')}:&nbsp;</span>
                        <span>{state.total_amount_TTC ? state.total_amount_TTC + '€' : t('none')}</span>
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '10px 6px' }}>
                        <span style={{ color: servicesColor }}>{t('deadline.date')}:&nbsp;</span>
                        <span>{state.deadline_date ? getFormattedDate(state.deadline_date) : t('none')}</span>
                    </Grid>
                </Grid>
            </div>
            {/* -------------------- GENERATE SUBSCRIPTIONS -------------------- */}
            {state.openGenerateSubscriptions && (
                <GenerateSubscriptions
                    open={state.openGenerateSubscriptions}
                    onClose={() => setState({ ...state, openGenerateSubscriptions: false })}
                    refetch={handleRefetch}
                    client_id={state.client_id}
                    bill_id={billId}
                />
            )}
        </>
    );
};

export default BillInfos;
