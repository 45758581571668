import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services 
import { deleteTool } from 'services/requests';
import { getTranslation, servicesColor } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- DELETE TOOL ---------------------------------------- \\
export const DeleteTool = ({ onClose, refetch, tool }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteTool(tool.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {tool.name}
                        </p>
                        <span>{t('tool.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------------- SKILLBASES MODAL ---------------------------------------- \\
export const SkillbasesModal = ({ onClose, tool }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {tool.name}
                </p>
                <span style={{ color: servicesColor }}>
                    {t('skillbases')}
                </span>
                <Divider style={style.m5_0_15_0} />
                {tool.skillbases.map(skillbase => (
                    <Fragment key={skillbase.id}>
                        <span>{getTranslation('name', skillbase)}</span>
                        <br />
                    </Fragment>
                ))}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ---------------------------------------- ROLES MODAL ---------------------------------------- \\
export const RolesModal = ({ onClose, tool }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {tool.name}
                </p>
                <span style={{ color: servicesColor }}>
                    {t('roles')}
                </span>
                <Divider style={style.m5_0_15_0} />
                {tool.roles.map(role =>
                    <Fragment key={role.id}>
                        <span>{role.name}</span>
                        <br />
                    </Fragment>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
