import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Divider, Button, IconButton, Grid } from '@material-ui/core';
import { Cancel as IconCancel, Search as IconSearch } from '@material-ui/icons';

// Services
import useMediaQueries from 'services/media';

// Components
import Tooltip from 'components/items/tooltip';
import Select from 'components/items/react-select';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// ------------------------------------- LEARNER LIST --------------------------------------- \\
const LearnerList = ({ state, setState, backgroundColor }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const onSearch = () => {
        let users = state.users;
        if (state.learner !== '') {
            users = users.filter(user => user.full_name.toLowerCase().includes(state.learner.toLowerCase()));
        }
        if (state.company_id != null) {
            users = users.filter(user => user.company && (user.company.id === state.company_id));
        }
        setState({ ...state, filteredUsers: users });
    };

    const onSelectSearch = company_id => {
        let users = state.users;
        if (state.learner !== '') {
            users = users.filter(user => user.full_name.toLowerCase().includes(state.learner.toLowerCase()));
        }
        if (company_id != null) {
            users = users.filter(user => user.company && (user.company.id === company_id));
        }
        setState({ ...state, filteredUsers: users, company_id });
    };

    const onCancelSearch = () => {
        setState({ ...state, filteredUsers: state.users, learner: '', company_id: null });
    };

    return (
        <>
            {/* -------------------- COUNT -------------------- */}
            <Grid container alignItems="center" style={{ paddingBottom: 12 }}>
                <Grid item xs={12} md={4}>
                    <Button onClick={() => setState({ ...state, searchEnded: false })} variant="outlined">
                        {t('back')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    <b className="input-title">{t('learners.found')}</b>
                </Grid>
            </Grid>
            {/* -------------------- HEADERS -------------------- */}
            <Grid container alignItems="center" style={{ backgroundColor: backgroundColor }}>
                {mediaMD ? (
                    <>
                        <Grid item md={5} style={style.p10}>
                            <span style={style.cW}>{t('learner')}</span>
                        </Grid>
                        <Grid item md={5} style={style.p10}>
                            <span style={style.cW}>{t('company')}</span>
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={style.p10}>
                        <span>{t('learners')}</span>
                    </Grid>
                )}
            </Grid>
            {/* -------------------- LIST FILTERS -------------------- */}
            {mediaMD && (
                <Grid container alignItems="center">
                    <Grid item xs={5} style={style.p10}>
                        <InputFilter
                            placeholder={t('learner')}
                            value={state.learner}
                            onChange={e => setState({ ...state, learner: e.target.value })}
                            onKeyPress={onSearch}
                        />
                    </Grid>
                    <Grid item xs={5} style={style.p10}>
                        <Select
                            placeholder={t('company')}
                            value={state.company_id != null ? state.companies.find(c => c.value === state.company_id) : null}
                            onChange={company => onSelectSearch(company ? company.value : null)}
                            options={state.companies}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                        <Tooltip title={t('search')}
                            item={
                                <IconButton onClick={onSearch} className="list-btn">
                                    <IconSearch />
                                </IconButton>
                            }
                        />
                        <Tooltip title={t('search.cancel')}
                            item={
                                <IconButton onClick={onCancelSearch} className="list-btn">
                                    <IconCancel />
                                </IconButton>
                            }
                        />
                    </Grid>
                </Grid>
            )}
            <Divider />
            {/* -------------------- LEARNER LIST -------------------- */}
            {state.filteredUsers.length > 0 ? (
                state.filteredUsers.map(user => (
                    <Fragment key={user.id}>
                        <Grid
                            onClick={() => history.push(`/mobipass/portfolio/${user.id}`)}
                            container alignItems="center"
                            className="list-item-hover"
                        >
                            <Grid item xs={12} md={5} style={style.p10}>
                                <span>{user.full_name}</span>
                            </Grid>
                            <Grid item xs={12} md={5} style={style.p10}>
                                <span>{user.company?.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </>
    );
};

export default LearnerList;
