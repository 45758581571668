import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { HelpOutline as IconHelp } from '@material-ui/icons';

// Services
import { getSoftCategories, getUser, createSoftPosition, updateSoftPosition, getSoftPositions } from 'services/requests';
import { getTranslation, softskillsColor2, softskillsColor } from 'services/helpers';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import DatePicker from 'components/items/date-picker';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import SkillbasesWithSoftskills from '../skillbases-with-softskills';

// Style
import style from 'style';

const styles = {
    tdLevelHeader: {
        border: '1px solid lightgray',
        wordBreak: 'break-word',
        width: '25%',
        maxWidth: '25%',
        padding: 10,
        textAlign: 'center',
        fontSize: 14,
    },
    tdLevel: {
        border: '1px solid lightgray',
        wordBreak: 'break-word',
        width: '25%',
        maxWidth: '25%',
        padding: 10,
        fontSize: 14,
    },
    p: {
        overflow: 'auto',
        height: 84,
        marginBottom: 0,
    },
};

// ------------------------------------ SOFT POSITIONS CREATE RATING -------------------------------------- \\
export const SoftPositionsCreateRating = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: undefined,
        position_date: new Date(),
        soft_categories: undefined,
        soft_levels: undefined,
        loadingCreate: false,
        openBehavior: false,
        softskill: undefined,

        // Skill bases with soft skills
        filteredCategories: null,
        skillbase: null,
        openSkillbasesWithSoftskills: false,
    });

    useEffect(() => {
        axios.all([getUser(userId), getSoftCategories()])
        .then(axios.spread(function (res_user, res_categories) {
            if (res_user?.status === 200 && res_categories?.status === 200) {

                const soft_categories = res_categories.data.data.soft_categories;

                let soft_levels = {};
                for (let i = 0; i < soft_categories.length; i += 1) {
                    for (let j = 0; j < soft_categories[i].softskills.length; j += 1) {
                        soft_levels[soft_categories[i].softskills[j].id] = Object.fromEntries(
                            soft_categories[i].softskills[j].soft_levels.map(sl => [sl.id, false])
                        );
                    }
                }

                setState({
                    ...state,
                    loading: false,
                    user: res_user.data.data.user,
                    soft_categories,
                    filteredCategories: soft_categories,
                    soft_levels,
                });
            }
            else {
                console.log(res_user + res_categories);
                setState({ ...state, loading: false, error: true });
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loadingCreate) {

            const soft_level_ids = [];
            Object.values(state.soft_levels).forEach(sl => {
                Object.entries(sl).forEach(([key, value]) => {
                    if (value) {
                        soft_level_ids.push(key);
                    }
                });
            });

            createSoftPosition({
                learner_id: userId,
                position_date: moment(state.position_date).format('YYYY-MM-DD'),
                soft_level_ids,
                skillbase_id: state.skillbase ? state.skillbase.id : null, 
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/softskills/positioning/${userId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingCreate: false })
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingCreate]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/softskills/positioning/${userId}`)}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={style.taC}
                >
                    {ROLE_ID < 5 && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {state.user && state.user.full_name}
                        </span>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}  
                >
                    <DatePicker
                        placeholder={t('date')}
                        value={state.position_date}
                        onChange={value => setState({ ...state, position_date: value })}
                        outlined
                        fullWidth={false}
                    />
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SOFT SKILLS -------------------- */}
            <div className="full-container">
                <SoftSkillList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider style={style.mt25} />
            <Grid container justifyContent="center" style={style.p10}>
                <Button
                    onClick={() => setState({ ...state, loadingCreate: true })}
                    variant="contained"
                    style={{ backgroundColor: softskillsColor, color: 'white' }}
                >
                    {t('softposition.add')}
                </Button>
            </Grid>
        </>
    );
};

// ------------------------------------ SOFT POSITIONS UPDATE RATING -------------------------------------- \\
export const SoftPositionsUpdateRating = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: undefined,
        position_date: undefined,
        soft_categories: undefined,
        soft_levels: undefined,
        loadingUpdate: false,
        openBehavior: false,
        softskill: undefined,

        // Skill bases with soft skills
        filteredCategories: null,
        skillbase: null,
        openSkillbasesWithSoftskills: false,
    });

    useEffect(() => {
        axios.all([getUser(userId), getSoftCategories(), getSoftPositions(userId)])
        .then(axios.spread(function (res_user, res_categories, res_softpositions) {
            if (res_user?.status === 200 && res_categories?.status === 200 && res_softpositions?.status === 200) {

                const soft_categories = res_categories.data.data.soft_categories;
                const soft_position = res_softpositions.data.data.soft_positions.find(sp => sp.id === Number(match.params.softPositionId));
                const soft_levels_validated = soft_position.soft_levels.map(sl => sl.id);

                let soft_levels = {};
                for (let i = 0; i < soft_categories.length; i += 1) {
                    for (let j = 0; j < soft_categories[i].softskills.length; j += 1) {
                        soft_levels[soft_categories[i].softskills[j].id] = Object.fromEntries(
                            soft_categories[i].softskills[j].soft_levels.map(sl => {
                                if (soft_levels_validated.includes(sl.id)) {
                                    return [sl.id, true];
                                }
                                return [sl.id, false];
                            })
                        );
                    }
                }

                let filteredCategories = soft_categories;
                if (soft_position.skillbase) {
                    let softskill_ids = soft_position.skillbase.softskills.map(softskill => softskill.id);
                    filteredCategories = filteredCategories.filter(c => c.softskills.findIndex(s => softskill_ids.includes(s.id)) !== -1);
                    filteredCategories.forEach(category => {
                        category.softskills = category.softskills.filter(s => softskill_ids.includes(s.id));
                    });
                }

                setState({
                    ...state,
                    loading: false,
                    user: res_user.data.data.user,
                    position_date: soft_position.position_date,
                    soft_categories,
                    filteredCategories,
                    soft_levels,
                    skillbase: soft_position.skillbase ? soft_position.skillbase : null,
                });
            }
            else {
                console.log(res_user + res_categories);
                setState({ ...state, loading: false, error: true });
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loadingUpdate) {

            const soft_level_ids = [];
            Object.values(state.soft_levels).forEach(sl => {
                Object.entries(sl).forEach(([key, value]) => {
                    if (value) {
                        soft_level_ids.push(key);
                    }
                });
            });

            updateSoftPosition(match.params.softPositionId, {
                learner_id: userId,
                position_date: moment(state.position_date).format('YYYY-MM-DD'),
                soft_level_ids,
                skillbase_id: state.skillbase ? state.skillbase.id : null,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/softskills/positioning/${userId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingUpdate: false })
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingUpdate]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/softskills/positioning/${userId}`)}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={style.taC}
                >
                    {ROLE_ID < 5 && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {state.user && state.user.full_name}
                        </span>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}  
                >
                    <DatePicker
                        placeholder={t('date')}
                        value={state.position_date}
                        onChange={value => setState({ ...state, position_date: value })}
                        outlined
                        fullWidth={false}
                    />
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- SOFT SKILLS -------------------- */}
            <div className="full-container">
                <SoftSkillList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider style={style.mt25} />
            <Grid container justifyContent="center" style={style.p10}>
                <Button
                    onClick={() => setState({ ...state, loadingUpdate: true })}
                    variant="contained"
                    style={{ backgroundColor: softskillsColor, color: 'white' }}
                >
                    {t('save')}
                </Button>
            </Grid>
        </>
    );
};

// ------------------------------------------- SOFT SKILL LIST -------------------------------------------- \\
export const SoftSkillList = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleSoftSkills(skillbase) {
        let filteredCategories = state.soft_categories;
        if (skillbase) {
            let softskill_ids = skillbase.softskills.map(softskill => softskill.id);
            filteredCategories = filteredCategories.filter(c => c.softskills.findIndex(s => softskill_ids.includes(s.id)) !== -1);
            filteredCategories.forEach(category => {
                category.softskills = category.softskills.filter(s => softskill_ids.includes(s.id));
            });
        }
        setState({ ...state, skillbase, filteredCategories });
    }

    function handleSoftLevel(softskill_id, softlevel_id) {
        let soft_levels = state.soft_levels;
        Object.entries(soft_levels[softskill_id]).forEach(([key, value]) => {
            if (key === softlevel_id.toString()) {
                const obj = soft_levels[softskill_id];
                obj[key] = !value;
            }
            else {
                const obj = soft_levels[softskill_id];
                obj[key] = false;
            }
        });
        setState({ ...state, soft_levels });
    }

    if (state.loading || state.loadingCreate) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <Grid style={{ marginTop: 24 }}>
                <span>
                    <b>{t('information')}</b>
                    <br />
                    {t('positioning.information')}
                </span>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" style={{ margin: '24px 0px' }}>
                <span>
                    <b>Configuration</b> : {state.skillbase ? getTranslation('name', state.skillbase) : 'global'}
                </span>
                <Button
                    onClick={() => setState({ ...state, openSkillbasesWithSoftskills: true })}
                    variant="contained"
                    style={{ textTransform: 'none' }}
                >
                    Choisir un référentiel ECVET
                </Button>
            </Grid>
            {/* -------------------- SOFT CATEGORY -------------------- */}
            {state.filteredCategories.map(category => (
                <Fragment key={category.id}>
                    <Grid
                        container justifyContent="center"
                        style={{ ...style.p10, backgroundColor: softskillsColor }}
                    >
                        <span style={{ ...style.fwB, ...style.ttU, ...style.cW }}>
                            {category.position}. {getTranslation('name', category)}
                        </span>
                    </Grid>
                    {/* -------------------- SOFT SKILL -------------------- */}
                    <div className="soft-level-table">
                        <table style={style.w100}>
                            <tbody>
                                {category.softskills.map(softskill => (
                                    <tr key={softskill.id}>
                                        <td
                                            onClick={() => setState({ ...state, openBehavior: true, softskill })}
                                            className="soft-level-td list-item-hover"
                                            style={style.taC}
                                        >
                                            <span style={style.fwB}>
                                                {category.position}.{softskill.position}  {getTranslation('name', softskill)} 
                                                <IconHelp  style={{...style.ml5, padding: "1px 2px 2px 2px", verticalAlign: "middle", color: "gray"}}/>
                                            </span>
                                        </td>
                                        {softskill.soft_levels.map(soft_level => (
                                            <td
                                                key={soft_level.id}
                                                className="soft-level-td"
                                                onClick={() => handleSoftLevel(softskill.id, soft_level.id)}
                                            >
                                                <Grid
                                                    container
                                                    className="soft-level"
                                                    style={{
                                                        backgroundColor: state.soft_levels[softskill.id][soft_level.id]
                                                        ? softskillsColor2
                                                        : null
                                                    }}
                                                >
                                                    <p style={{ ...style.taC, ...style.fwB, ...style.w100 }}>
                                                        {t('level')}&nbsp;{soft_level.level}
                                                        <br />
                                                        {soft_level.level === 1 && t('remember.and.understand')}
                                                        {soft_level.level === 2 && t('apply')}
                                                        {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                        {soft_level.level === 4 && t('create')} 
                                                    </p>
                                                    <p className="soft-level-definition">
                                                        {getTranslation('definition', soft_level)}
                                                    </p>
                                                </Grid>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            ))}
            {/* -------------------- BEHAVIORS MODAL -------------------- */}
            {state.openBehavior && (
                <BehaviorsModal
                    onClose={() => setState({ ...state, openBehavior: false })}
                    softskill={state.softskill}
                />
            )}
            {/* -------------------- SKILL BASE LIST MODAL -------------------- */}
            {state.openSkillbasesWithSoftskills && (
                <SkillbasesWithSoftskills
                    isRating
                    onClose={() => setState({ ...state, openSkillbasesWithSoftskills: false })}
                    selectedSkillbase={state.skillbase}
                    setSelectedSkillbase={skillbase => handleSoftSkills(skillbase)}
                />
            )}
        </>
    );
};

// -------------------------------------------- BEHAVIORS MODAL ------------------------------------------- \\
export const BehaviorsModal = ({ onClose, softskill }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        tr_array_behaviors: [],
    });

    useEffect(() => {
        if (softskill) {
            let nb_behaviors = 0;
            for (let i = 0; i < 4; i += 1) {
                const behaviors_number = softskill.soft_levels[i].behaviors.length;
                if (nb_behaviors < behaviors_number) {
                    nb_behaviors = behaviors_number;
                }
            }
            setState({ ...state, tr_array_behaviors: Array.apply(null, { length: nb_behaviors }).map(Number.call, Number) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [softskill]);

    return (
        <Dialog open fullWidth maxWidth="md">
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {getTranslation('name', softskill)}
                </p>
                {/* ---------------------- BEHAVIORS ---------------------- */}
                <table style={{ width: '100%', minWidth: 800, overflowX: 'scroll' }}>
                    <thead>
                        <tr>
                            <td colSpan={4} style={{ backgroundColor: softskillsColor, ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('observable.behavior')}</span>
                            </td>
                        </tr>
                        <tr>
                            {softskill.soft_levels.map(soft_level => (
                                <td key={soft_level.id} style={styles.tdLevelHeader}>
                                    {t('level')}&nbsp;{soft_level.level}
                                    <br />
                                    {soft_level.level === 1 && t('remember.and.understand')}
                                    {soft_level.level === 2 && t('apply')}
                                    {soft_level.level === 3 && t('analyse.and.evaluate')}
                                    {soft_level.level === 4 && t('create')}
                                </td>
                            ))}
                        </tr>
                    </thead>      
                    <tbody>
                        {state.tr_array_behaviors.length > 0 ? (
                            state.tr_array_behaviors.map(tr => (
                                <tr key={tr}>
                                    {[0, 1, 2, 3].map(index => {
                                        const behavior = softskill.soft_levels[index].behaviors[tr];
                                        return (
                                            <td key={index} style={styles.tdLevel}>
                                                {behavior && (
                                                    <p style={styles.p}>
                                                        {behavior.position}. {getTranslation('definition', behavior)}
                                                    </p>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} style={styles.tdLevel}>
                                    {t('none')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
