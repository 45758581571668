import React, { useState, useEffect } from 'react';

// Material-UI
import { Snackbar, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Services
import { setCookieDisplayed } from 'services/storage';
import useMediaQueries from 'services/media';

const styles = {
    root: {
        backgroundColor: 'white',
        color: 'black',
    },
};

// ---------------------------------------- COOKIE MESSAGE ---------------------------------------- \\
const CookieMessage = props => {
    const { mediaSM } = useMediaQueries();
    const { classes } = props;
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (!open) {
            setCookieDisplayed('false');
        }
    }, [open]);

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            ContentProps={{ classes: { root: classes.root } }}
            message={(
                <>
                    <b>ACTUALITÉS :</b>
                    <br />
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScwYOg6NhDOZCuNg3ZKV7Gpguo0TkaAI_zuUkjC4OKe2ey4Jg/viewform"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sondage ZERO BARRIER
                    </a> - Merci de prendre quelques minutes pour y répondre et nous aider à améliorer la plateforme
                    <br />
                    • Une mise à jour vient d'être faite avec des améliorations d'ergonomie
                    <br />
                    • Plus d'info dans l'onglet Actualités
                </>
            )}
            action={(
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setOpen(false)}
                >
                    OK
                </Button>
            )}
            style={{ right: mediaSM ? 24 : 8 }}
        />
    );
};

CookieMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CookieMessage);
