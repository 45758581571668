import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Draft
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';

// Material-UI
import { Accordion, AccordionSummary, Divider, Button, Grid } from '@material-ui/core';
import {
    Folder as IconFolder, FolderOpen as IconFolderOpen, ExpandMore as IconExpand,
    Star as IconCert1, StarHalf as IconCert2,
} from '@material-ui/icons';

// Services
import { getPortfolios, getPortfolioContent } from 'services/requests';
import { getTranslation, fileUrl, mobipassColor } from 'services/helpers';
import { getStoredUser } from 'services/storage';

// Components
import Portfolios from './portfolios';
import SoftPortfolios from './soft-portfolios';
import JobPasseport from './job-passeport';
import Certificate from './certificate';
import SkillbaseComment from './skillbase-comment';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// -------------------------------------- PORTFOLIOS ---------------------------------------- \\
const UserPortfolio = ({ user }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        skillbases: [],
        skillbaseId: null,
        expanded: null,
        learning: null,

        // Portfolios Content
        skillbasesContent: [],
        loadingContent: false,

        // Modals
        openCertificate: false,
        skillbaseComment: null,
        openComment: false,
    });

    useEffect(() => {
        if (state.loading) {
            getPortfolios(user.id).then(res => {
                if (res?.status === 200) {    
                    setState({ ...state, loading: false, skillbases: res.data.data.skillbases });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- LIST HEADERS -------------------- */}
            <Grid
                container justifyContent="center"
                style={{ ...style.p10, backgroundColor: mobipassColor }}
            >
                <span style={style.cW}>{t('portfolio.competencies')}</span>
            </Grid>
            {/* -------------------- PORTFOLIO LIST -------------------- */}
            <Skillbases
                user={user}
                state={state}
                setState={setState}
            />
        </>
    );
};

// -------------------------------------- SKILLBASES ---------------------------------------- \\
const Skillbases = ({ user, state, setState }) => {
    const { t } = useTranslation();
    
    const openCertificate = (event, skillbaseId) => {
        event.stopPropagation();
        setState({ ...state, openCertificate: true, skillbaseId });
    };

    const onExpand = skillbaseId => {
        if (skillbaseId === state.expanded) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: skillbaseId, loadingContent: state.skillbasesContent.findIndex(s => s.id === skillbaseId) === -1 });
        }
    };

    useEffect(() => {
        if (state.expanded != null && state.skillbasesContent.findIndex(s => s.id === state.expanded) === -1) {
            getPortfolioContent(user.id, state.expanded).then(res => {
                if (res?.status === 200) {
                    var skillbasesContent = state.skillbasesContent;
                    skillbasesContent.push(res.data.data.skillbase);
                    setState({ ...state, loadingContent: false, skillbasesContent });
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingContent: false, expanded: null });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.expanded]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            <div>
                {(state.skillbases != null && state.skillbases.length > 0) ? (
                    state.skillbases.map(skillbase => (
                        <Accordion
                            key={skillbase.id}
                            expanded={state.expanded === skillbase.id}
                            onChange={() => onExpand(skillbase.id)}
                        >
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <div style={style.dF}>
                                        {(state.expanded === skillbase.id) ? (
                                            <IconFolderOpen style={{ color: 'gray' }} />
                                        ) : (
                                            <IconFolder style={{ color: 'gray' }} />
                                        )}
                                        <p style={{ ...style.fwB, margin: 'auto 0px auto 5px' }}>
                                            {getTranslation('name', skillbase)}
                                        </p>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={e => openCertificate(e, skillbase.id)}
                                            variant="outlined" color="primary"
                                            style={style.ttN}
                                        >
                                            {t('certificate')}
                                        </Button>
                                        <JobPasseport
                                            user={user}
                                            skillbaseId={skillbase.id}
                                        />
                                    </div>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            {/* -------------------- LOADER -------------------- */}
                            {state.loadingContent && (
                                <Spinner />
                            )}
                            {/* -------------------- CONTENT -------------------- */}
                            {(state.skillbasesContent.findIndex(s => s.id === state.expanded) !== -1) && (
                                <AccordionDetails
                                    skillbase={state.skillbasesContent.find(s => s.id === state.expanded)}
                                    state={state}
                                    setState={setState}
                                />
                            )}
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- COMMENT -------------------- */}
            {state.openComment && (
                <SkillbaseComment
                    onClose={() => setState({ ...state, openComment: false })}
                    refetch={() => setState({ ...state, openComment: false, expanded: null, skillbasesContent: [] })}
                    userId={user.id}
                    skillbaseId={state.skillbaseId}
                    skillbaseComment={state.skillbaseComment}
                />
            )}
            {/* -------------------- CERTIFICATE -------------------- */}
            {state.openCertificate && (
                <Certificate
                    onClose={() => setState({ ...state, openCertificate: false })}
                    user={user}
                    skillbaseId={state.skillbaseId}
                />
            )}
        </>
    );
};

// ----------------------------------- ACCORDION DETAILS ------------------------------------ \\
const AccordionDetails = ({ skillbase, state, setState }) => {
    const { t } = useTranslation();
    const ROLE_ID = getStoredUser()?.role?.id;

    return (
        <div style={{ padding: 24 }}>
            {/* ---------------------- SKILLBASE COMMENT ----------------------  */}
            <div>
                <div style={{ marginBottom: 6 }}>
                    <b style={{ fontSize: 14 }}>{t('informations.learner.course')}</b>
                </div>
                {ROLE_ID < 5 ? (
                    <Button
                        onClick={() => setState({ ...state, openComment: true, skillbaseId: skillbase.id, skillbaseComment: skillbase.skillbase_comment })}
                        variant="outlined"color="primary"
                        style={{ textTransform: 'none' }}
                    >
                        {t('skillbase.comment')}
                    </Button>
                ) : (
                    (skillbase.skillbase_comment != null && skillbase.skillbase_comment.comment != null) ? (
                        <div
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: 12, borderRadius: 4 }}
                            dangerouslySetInnerHTML={{__html: convertToHTML(convertFromRaw(JSON.parse(skillbase.skillbase_comment.comment)))}} 
                        />
                    ):(
                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: 12, borderRadius: 4 }}>
                            <span>{t('no.course.comment')}</span>
                        </div>
                        
                    )
                )}
            </div>
            {skillbase.units.map(unit => (
                <Fragment key={unit.id}>
                    {(unit.learning_outcomes != null && unit.learning_outcomes.length > 0) && (
                        <div style={{ overflowX: 'auto', marginTop: 24 }}>
                            <Grid container alignItems="center" style={{ ...style.fwB, ...style.mb5 }}>
                                {/* ---------------------- UNIT CERTIFICATION ----------------------  */}
                                {unit.certifications.length > 0 && (
                                    unit.certifications[0].document ? (
                                        <Tooltip title={t('download.document')}
                                            item={(
                                                <a 
                                                    href={fileUrl + unit.certifications[0].document} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className="certificated"
                                                    style={{ ...style.dF, ...style.aiC, ...style.mr10 }}
                                                >
                                                    <IconCert1 color="secondary" style={{ fontSize: 20 }} />
                                                    <span style={{ color: '#F50057', textTransform: 'uppercase' }}>
                                                        {t('certificated')}
                                                    </span>
                                                </a>
                                            )}
                                        />
                                    ) : (
                                        <Tooltip title={t('missing.document')}
                                            item={(
                                                <div style={{ ...style.dF, ...style.aiC, ...style.mr10 }}>
                                                    <IconCert2 color="secondary" style={{ fontSize: 20 }} />
                                                    <span style={{ color: '#F50057', ...style.ttU, cursor: 'pointer' }}>   
                                                        {t('certificated')}
                                                    </span>
                                                </div>
                                            )}
                                        />
                                    )
                                )}
                                {/* ---------------------- UNIT NAME ----------------------  */}
                                <p className="title" style={{ margin: 0 }}>
                                    {unit.position}. {getTranslation('name', unit)}
                                </p>
                            </Grid>
                            {/* ---------------------- PORTFOLIOS ----------------------  */}
                            <Portfolios
                                unit={unit}
                            />
                            {/* ---------------------- SOFT PORTFOLIOS ----------------------  */}
                            <SoftPortfolios
                                unit={unit}
                            />
                        </div>
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default UserPortfolio;
