// --------------------------------------------------------------- \\
// ----------------------------- ROLES --------------------------- \\
// --------------------------------------------------------------- \\

export const roles = [
    {
        id: 1,
        label: 'administrator',
    },
    {
        id: 2,
        label: 'manager',
    },
    {
        id: 3,
        label: 'external.assessor',
    },
    {
        id: 4,
        label: 'trainer',
    },
    {
        id: 5,
        label: 'learner',
    },
];

// --------------------------------------------------------------- \\
// --------------------------- LANGUAGES ------------------------- \\
// --------------------------------------------------------------- \\

export const languages = [
    {
        id: 1,
        code: 'en',
        label: 'english',
    },
    {
        id: 2,
        code: 'fr',
        label: 'french',
    },
    {
        id: 3,
        code: 'es',
        label: 'spanish',
    },
    {
        id: 4,
        code: 'it',
        label: 'italian',
    },
    {
        id: 5,
        code: 'sl',
        label: 'slovenian',
    },
    {
        id: 6,
        code: 'lv',
        label: 'latvian',
    },
    {
        id: 7,
        code: 'de',
        label: 'german',
    },
];

// --------------------------------------------------------------- \\
// ---------------------------- MONTHS --------------------------- \\
// --------------------------------------------------------------- \\

export const months = [
    {
        value: '01',
        label: 'january',
    },
    {
        value: '02',
        label: 'february',
    },
    {
        value: '03',
        label: 'march',
    },
    {
        value: '04',
        label: 'april',
    },
    {
        value: '05',
        label: 'may',
    },
    {
        value: '06',
        label: 'june',
    },
    {
        value: '07',
        label: 'july',
    },
    {
        value: '08',
        label: 'august',
    },
    {
        value: '09',
        label: 'september',
    },
    {
        value: '10',
        label: 'october',
    },
    {
        value: '11',
        label: 'november',
    },
    {
        value: '12',
        label: 'december',
    },
];

// --------------------------------------------------------------- \\
// -------------------------- TOOL TYPES ------------------------- \\
// --------------------------------------------------------------- \\

export const toolTypes = [
    {
        id: 1,
        label: 'skillbase.documentation',
    },
    {
        id: 2,
        label: 'educational.resources',
    },
];

// --------------------------------------------------------------- \\
// ----------------------- SOFT TOOL TYPES ----------------------- \\
// --------------------------------------------------------------- \\

export const softToolTypes = [
    {
        id: 1,
        label: 'tool.card',
    },
    {
        id: 2,
        label: 'other.documentation',
    },
];

// --------------------------------------------------------------- \\
// --------------------- SUBSCRIPTION TYPES ---------------------- \\
// --------------------------------------------------------------- \\

export const subscriptionTypes = [
    {
        id: 1,
        label: 'subscription.annual',
    },
    {
        id: 2,
        label: 'subscription.monthly'
    },
    {
        id: 3,
        label: 'subscription.custom',
    },
];

// --------------------------------------------------------------- \\
// ------------------------- PERCENTAGES ------------------------- \\
// --------------------------------------------------------------- \\

export const percentages = [
    {
        value: 50,
        label: '50%'
    },
    {
        value: 66,
        label: '66%'
    },
    { 
        value: 75,
        label: '75%'
    },
    {
        value: 100,
        label: '100%'
    },
];

// --------------------------------------------------------------- \\
// --------------------- CLEA EVALUATION TYPES ------------------- \\
// --------------------------------------------------------------- \\

export const cleaEvaluationTypes = [
    {
        value: '0',
        label: 'clea.preliminary.assessment'
    },
    {
        value: '1',
        label: 'clea.intermediary.assessment',
    },
    {
        value: '2',
        label: 'clea.final.assessment',
    },
];

// --------------------------------------------------------------- \\
// --------------------- CLEA DOCUMENT TYPES --------------------- \\
// --------------------------------------------------------------- \\

export const cleaDocumentTypes = [
    {
        value: '0',
        label: 'clea.certificate.document'
    },
    {
        value: '1',
        label: 'clea.proof.file',
    },
];
