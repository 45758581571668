import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { createSoftEvaluation, getCompanyList } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import SoftEvaluationFormPage1 from './soft-evaluation-form/soft-evaluation-form-1';
import SoftEvaluationFormPage2 from './soft-evaluation-form/soft-evaluation-form-2';
import SoftEvaluationFormPage3 from './soft-evaluation-form/soft-evaluation-form-3';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ------------------------------------ SOFT EVALUATION CREATE -------------------------------------- \\
const SoftEvaluationCreate = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'CREATE',
        loadingResources: true,
        errorResources: false,
        loading: false,
        page: 1,
        companies: [],
        
        // Page 1
        evaluation_date: null,
        evaluation_context: '',
        company_id: null,
        company_name: '',
        company_manual_entry: false,
        assessors: [],
        assessor_company_id: null,
        assessor_id: null,
        assessor_name: '',
        assessor_manual_entry: false,
        authorizedRefetchAssessor: false,
        learners: [],
        learner_company_id: null,
        learner_id: null,
        authorizedRefetchLearner: false,

        // Page 2
        loadingSoftCategories: true,
        errorSoftCategories: false,
        soft_categories: [],
        softskills: [],
        
        // Page 3
        softskill: null,
        soft_levels: {},
        soft_level: null,
        observed_behaviors: {},
        openObservedBehavior: false,
        openBehavior: false,
    });

    useEffect(() => {
        if (state.loadingResources) {
            getCompanyList().then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {
            createSoftEvaluation({
                evaluation_date: moment(state.evaluation_date).format('YYYY-MM-DD'),
                evaluation_context: state.evaluation_context,
                company_id: state.company_id,
                company_name: state.company_name,
                learner_id: state.learner_id,
                assessor_id: state.assessor_id,
                assessor_name: state.assessor_name,
                soft_levels: Object.values(state.soft_levels).map(id => ({ soft_level_id: id, observed_behaviors: state.observed_behaviors[id] || null })),
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobipass/softskills/evaluation');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/softskills/evaluation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('evaluation.add')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }}/>
            {/* -------------------- SOFT EVALUATION FORM -------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <SoftEvaluationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <SoftEvaluationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <SoftEvaluationFormPage3 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default SoftEvaluationCreate;
