import React from 'react';
import { useTranslation } from 'react-i18next';

// File Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { fileUrl } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import DatePicker from 'components/items/date-picker';
import InputFile from 'components/items/input-file';
import InputText from 'components/items/input-text';

// ---------------------------------------- MOBILITY FORM PAGE 1 ---------------------------------------- \\
const MobilityFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage2 = () => {
        if (state.start_date == null || state.end_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.start_date > state.end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-mobility-document').files[0];
            setState({ ...state, page: 2, document: file });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        <Grid container style={{ marginTop: 24 }}>
                            {/* -------------------- START DATE -------------------- */}
                            <Grid item xs={6} style={{ paddingRight: 5 }}>
                                <b className="input-title">{t('start.date')}</b><FieldRequired />
                                <DatePicker
                                    placeholder={t('start.date')}
                                    value={state.start_date}
                                    onChange={start_date => setState({ ...state, start_date })}
                                    outlined
                                />
                            </Grid>
                            {/* -------------------- END DATE -------------------- */}
                            <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                <b className="input-title">{t('end.date')}</b><FieldRequired />
                                <DatePicker
                                    placeholder={t('end.date')}
                                    value={state.end_date}
                                    onChange={end_date => setState({ ...state, end_date })}
                                    outlined
                                />
                            </Grid>
                        </Grid>
                        {/* -------------------- DOCUMENT -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('convention.document')}</b>
                            <InputFile
                                inputId="mobility-document"
                            />
                            {state.document_url && (
                                <Button
                                    onClick={() => saveAs(fileUrl + state.document_url, t('mobility') + ' - ' + t('convention.document'))}
                                    variant="contained" color="secondary"
                                    style={{ textTransform: 'none', marginTop: 10 }}
                                >
                                    {t('download.existing.document')}
                                    <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </Button>
                            )}
                        </div>
                        {/* -------------------- DESCRIPTION -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('description')}</b>
                            <InputText
                                placeholder={t('description')}
                                value={state.description}
                                onChange={e => setState({ ...state, description: e.target.value })}
                                multiline
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={false} md={4} />
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>1 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default MobilityFormPage1;
