// Tools to Create PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Services
import { getFormattedDate, getTimeDifference, fileUrl, mobitrainColor, mobitrainColor2 } from 'services/helpers';

// Assets
import LogoZB from 'assets/images/zerobarrier.png';

// Theme
import { theme } from 'services/helpers';

// ------------------------------------------ EXPORT PDF ------------------------------------------- \\
const ExportPDF = async( formations, user, company, start_date, end_date, t ) => {

    let company_logo;
    
    // Variables to process the aspect ratio of logo
    let company_logo_width = 0
    let company_logo_height = 0;
    let company_logo_fit_width = 0;
    let company_logo_fit_height = 0

    // Function to calculate the final dimension of the company logo
    const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        company_logo_fit_width = srcWidth * ratio;
        company_logo_fit_height = srcHeight * ratio;
    };

    // Transform img from the server to base64 image
    const getDataUri = url => {
        return new Promise(resolve => {
            var image = new Image();
            image.setAttribute('crossOrigin', 'anonymous'); // To get images from external domain
    
            image.onload = () => {
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;
    
                // Save img dimension for ratio calculation
                company_logo_width = canvas.width;
                company_logo_height = canvas.height;
    
                // Set white background in case png has a transparent background
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
    
                canvas.getContext('2d').drawImage(this, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };
    
            image.src = url;
        })
    };

    if (company.photo != null) {
        let imgUrl = fileUrl + company.photo;
        company_logo = await getDataUri(imgUrl);
    }

    calculateAspectRatioFit(company_logo_width, company_logo_height, 55, 25);

    const pdf = new jsPDF('p', 'mm', 'a4');

    const addHeadersAndFooters = (pdf, company_logo) => {

        const pageCount = pdf.internal.getNumberOfPages();
        pdf.setFont('helvetica');
        pdf.setFontSize(9);
        
        for (let index_page = 1; index_page <= pageCount; index_page += 1) {

            pdf.setPage(index_page);

            // Display the logo of zb and the company logo
            pdf.addImage(LogoZB, 5, 5, 36.5, 7.7, '', 'FAST');
            if (company.photo != null) {
                pdf.addImage(company_logo, 'PNG', 145, 7, company_logo_fit_width, company_logo_fit_height, '', 'FAST');
            }

            // Display learner infos
            pdf.setFontSize(12);
            pdf.setFont('helvetica', 'normal');
            pdf.text(t('learner') + ' :', 5, 25);
            pdf.setFont('helvetica', 'bolditalic');
            pdf.text(user.label, 34, 25);

            // Display company infos
            pdf.setFont('helvetica', 'normal');
            pdf.text(t('company') + ' : ', 5, 33);
            pdf.setFont('helvetica', 'bolditalic');
            pdf.text(company.label, 36, 33);
            
            // Display period
            pdf.setFont('helvetica', 'normal');
            pdf.text(t('for.the.period'), 5, 41);
            pdf.setFont('helvetica', 'bolditalic');
            pdf.text(t('from') + ' ' + getFormattedDate(start_date) + ' ' + t('to') + ' ' + getFormattedDate(end_date), 48, 41);

            // Display the total of hours for the page
            pdf.setFontSize(14);
            theme === 'mobirhin' ? pdf.setTextColor(76, 168, 221) :  pdf.setTextColor(233, 147, 0);
            pdf.setFont('helvetica', 'bold');
            pdf.text(t('certificate.of.training.completion'), pdf.internal.pageSize.width / 2, 57, { align: 'center' });
            
            // --------------- SIGNATURE + FOOTER ------------------- //

            // If end page, display the total hour for all the formations
            if (index_page === pageCount) {

                // Display the total of hours for the page
                pdf.setFontSize(13);
                pdf.setTextColor(0)
                pdf.setFont('helvetica', 'normal');
                pdf.text(t('total.hours.training') + getTotalHours(formations, t), pdf.internal.pageSize.width / 2, 205, { align: 'center' });
            };

            // Extract all the trainer of all the formations
            let compile_trainers = [];
            formations.forEach(formation => {
                formation.formation_trainers.forEach(formation_trainer => {
                    compile_trainers.push(formation_trainer.trainer?.full_name);
                });
            });

            // Remove duplicate of the trainers and keep only 7 trainers max
            let unique_trainers = [...new Set(compile_trainers)];

            if (unique_trainers.length > 7) {
                unique_trainers = unique_trainers.slice(0, 7);
            }

            let signature_body = [];

            // Body for the trainers signature autotable
            unique_trainers.forEach(unique_trainer => {
                signature_body.push([user.label, '', unique_trainer, '']);
            });

            // Autotable for the trainers signature
            pdf.autoTable({
                startY: 210,
                theme: 'grid',
                margin: {left: 5, right: 5},
                headStyles: { fontSize: 10, textColor: 240, tableLineColor: [255, 255, 255]},
                head: [
                    [
                        {   
                            content: t('learner'),
                            styles: { fontSize: 10, halign: 'center', fillColor: [mobitrainColor2], textColor: 2, cellWidth: 50 }
                        },
                        {   
                            content: t('signature'),
                            styles: { fontSize: 10, halign: 'center', fillColor: [mobitrainColor2], textColor: 2, cellWidth: 50  }
                        },
                        {   
                            content: t('trainer'),
                            styles: { fontSize: 10, halign: 'center', fillColor: [mobitrainColor2], textColor: 2, cellWidth: 50  }
                        },
                        {   
                            content: t('signature'),
                            styles: { fontSize: 10, halign: 'center', fillColor: [mobitrainColor2], textColor: 2, cellWidth: 50  }
                        },
                    ],
                ],
                body: signature_body,
                bodyStyles: { fontSize: 9, textColor: 2, cellPadding: 2 },
            });

            // Draw rectangle to hide the autotable for the learner
            pdf.setFontSize(9);
            pdf.setTextColor(0);
            pdf.setFont('helvetica', 'normal');
            pdf.setDrawColor( 255 );
            pdf.setFillColor(255);
            pdf.rect( 4, 218, 100.8, 80, 'F');
            
            // Row 2 learner
            pdf.setDrawColor( 180 );
            pdf.rect( 5.1, 217.5, 50, 15.5);
            pdf.rect( 55.1, 217.5, 49.9, 15.5);

            // Display name of the learner on two lines
            let learner_name = user.label.split(' ');
            pdf.text(learner_name[0], 27, 223, { align: 'center'});
            pdf.text(learner_name[1], 27, 228, { align: 'center'});
        }
    }

    // Number of formation per page
    const size = 8; 
    let array_by_size = [];

    // Slice formations in array of max 8 entries
    for (let i = 0; i < formations.length; i += size) {
        array_by_size.push(formations.slice(i, i + size));
    };

    let array_for_body = [];

    // Populate the body of each autotable 
    for ( let j = 0; j <= array_by_size.length - 1; j += 1 ) {

        // eslint-disable-next-line
        array_by_size[j].forEach(array => {
            array_for_body.push([
                getFormattedDate(array.formation_date),
                array.start,
                array.end,
                getTimeDifference(array.start, array.end),
                array.name,
                array.type.name,
                array.formation_trainers.map(trainer => (' ' + trainer.trainer.full_name + ' ')),
            ]);
        });

        pdf.autoTable({
            startY: 65,
            theme: 'grid',
            margin: {left: 5, right: 5},
            headStyles: { fontSize: 8, textColor: 240},
            head: [
                [
                    {   
                        content: t('date'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth: 20 },
                    },
                    {   
                        content: t('time.start'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth: 17.5 },
                    },
                    {   
                        content: t('time.end'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth: 17.5 },
                    },
                    {   
                        content: t('total.time'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth: 17.5 },
                    },
                    {   
                        content: t('session.name'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth: 50 },
                    },
                    {   
                        content: t('type'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth: 30 },
                    },
                    {   
                        content: t('trainer'),
                        styles: { fontSize: 9, halign: 'center', fillColor: [mobitrainColor], textColor: 2, cellWidth:  47 },
                    },
                ],
            ],
            body: array_for_body,
            bodyStyles: {fontSize: 9, textColor: 2},
        });

        // Reset the body to prepare the next autotable
        array_for_body = [];
            
        // Total hours for the page
        pdf.setFontSize(13);
        pdf.setTextColor(0)
        pdf.setFont('helvetica', 'normal');
        pdf.text(t('total.hours.training.on.page') + getTotalHours(array_by_size[j], t), pdf.internal.pageSize.width / 2, 195, {
            align: 'center'
        });
            
        // Add a page if there is other array to display
        if (j < array_by_size.length - 1) {
            pdf.addPage();
        }
        // Call for the Header and footers
        addHeadersAndFooters(pdf, company_logo);
    }

    const pageCount = pdf.internal.getNumberOfPages();

    // Display Page number
    for (let page = 1 ; page <= pageCount; page += 1) {
        pdf.setPage(page);
        pdf.setFontSize(9);
        pdf.setFont('helvetica', 'bolditalic');
        pdf.text( String(page) + ' / ' + String(pageCount), pdf.internal.pageSize.width / 2, 287, { align: 'center' });
    }

    // Save the PDF
    pdf.save(user.label + '_' + t('training') + '.pdf');
};

const getTotalHours = (formations , t) => {

    let total_hours = 0;
    let total_minutes = 0;

    for (let i = 0; i < formations.length; i+= 1) {

        let start = formations[i].start.split(':');
        let end = formations[i].end.split(':');
    
        let startTime = new Date(0, 0, 0, start[0], start[1], 0);
        let endTime = new Date(0 ,0 ,0 , end[0], end[1], 0);
    
        let difference = endTime.getTime() - startTime.getTime();
    
        let hours = Math.floor(difference / 1000 / 60 / 60);
        difference -= hours *1000 * 60 * 60;
        let minutes = Math.floor(difference / 1000 / 60);

        total_hours += hours;
        total_minutes += minutes;
    }

    let additional_hours = Math.floor(total_minutes / 60);
    total_minutes = total_minutes - (additional_hours * 60);
    total_hours += additional_hours;

    return total_hours + ' ' + t('hours') + ' ' + (total_minutes < 10 ? '0' : '') + total_minutes + ' ' + t('minutes');
};

export default ExportPDF;
