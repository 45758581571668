import React from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

// ---------------------------------------- SELECT MULTIPLE ---------------------------------------- \\
const SelectMultiple = ({ value, onChange, options }) => {
    const { t } = useTranslation();

    return (
        <ReactSelect
            value={value}
            onChange={onChange}
            options={options}
            isMulti
            isSearchable={false}
            placeholder={t('select')}
            noOptionsMessage={() => t('none')}
            isClearable={false}
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }) }}
        />
    );
};

export default SelectMultiple;
