import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getSoftCategories, getCompanyList, getSoftFormation, updateSoftFormation, getSoftFormationsResources } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import SoftFormationFormPage1 from './soft-formation-form/soft-formation-form-1';
import SoftFormationFormPage2 from './soft-formation-form/soft-formation-form-2';
import SoftFormationFormPage3 from './soft-formation-form/soft-formation-form-3';
import SoftFormationFormPage4 from './soft-formation-form/soft-formation-form-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ------------------------------------ SOFT FORMATION UPDATE -------------------------------------- \\
const SoftFormationUpdate = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { softFormationId } = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        actionType: 'UPDATE',
        loadingResources: true,
        errorResources: false,
        loading: false,
        page: 1,
        companies: [],
        soft_categories: [],
        expanded: null,
        
        // Page 1
        company_id: null,
        soft_trainings : [],
        soft_training_id: 1,
        learnerOptions: [],
        learners_company_id: null,
        learners: [],
        trainerOptions: [],
        trainers_company_id: null,
        trainers: [],
        
        // Page 2
        soft_category: null,
        softskill: null,
        
        // Page 3
        soft_level: null,

        // Page 4
        sessionIsOpen: null,
        sessions: [{
            session_date: null,
            start: '',
            end: '',
        }],
    });

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([
                getSoftFormationsResources(),
                getSoftCategories(),
                getCompanyList(),
                getSoftFormation(softFormationId),
            ])
            .then(axios.spread(function (
                res_soft_ressources,
                res_categories,
                res_companies,
                res_soft_formation,
            ) {
                if (res_soft_ressources?.status === 200 && res_categories?.status === 200 && res_companies?.status === 200 && res_soft_formation?.status === 200) {

                    const soft_formation = res_soft_formation.data.data.soft_formation;
                    const soft_categories = res_categories.data.data.soft_categories;

                    let soft_category, softskill;
                    soft_categories.forEach(category => {
                        category.softskills.forEach(ss => {
                            if (ss.id === soft_formation.softskill?.id) {
                                soft_category = category;
                                softskill = ss;
                            }
                        });
                    });
                    
                    let sessions = [];
                    soft_formation.soft_sessions.forEach(session => {
                        sessions.push({
                            id: session.id,
                            session_date: session.session_date,
                            start: moment(session.start, "HH:mm:ss").format("HH:mm"),
                            end: moment(session.end, "HH:mm:ss").format("HH:mm"),
                        });
                    });

                    setState({
                        ...state,
                        loadingResources: false,
                        soft_formation,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        company_id: soft_formation.company?.id,
                        soft_trainings: res_soft_ressources.data.data.soft_trainings,
                        soft_training_id: soft_formation.soft_training.id,
                        learners: soft_formation.learners.map(l => ({ label: l.full_name, value: l.id })),
                        trainers: soft_formation.trainers.map(t => ({ label: t.full_name, value: t.id })),
                        soft_categories,
                        soft_category,
                        softskill,
                        soft_level: soft_formation.soft_level,
                        sessions,
                        sessionIsOpen: sessions.length > 1 ? (sessions.length - 1) : null,
                    });
                }
                else {
                    console.log(res_categories + res_companies);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {
            updateSoftFormation(softFormationId, {
                company_id: state.company_id,
                soft_training_id: state.soft_training_id,
                soft_level_id: state.soft_level?.id,
                trainer_ids: state.trainers.map(trainer => trainer.value),
                learner_ids: state.learners.map(learner => learner.value),
                sessions: state.sessions,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobitrain/soft-formation');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    console.log(state.softskill);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobitrain/soft-formation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('soft.formation.edit')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }}/>
            {/* -------------------- SOFT FORMATION FORM -------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <SoftFormationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <SoftFormationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <SoftFormationFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 4 && (
                        <SoftFormationFormPage4 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default SoftFormationUpdate;
