import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button, Grid, CircularProgress } from '@material-ui/core';
import { Timelapse as IconTimer, CheckCircle as IconCheck, Cancel as IconCancel, SaveAlt as IconSave } from '@material-ui/icons';

// Services
import { getFormation } from 'services/requests';
import { getFormattedDate, mobitrainColor, getTranslation } from 'services/helpers';

// Components
import FormationDetailsExport from './formation-details-export';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    icon: {
        fontSize: 24,
        margin: 'auto 5px auto 1px',
        cursor: 'pointer'
    },
};

// --------------------------------------- DETAILS FORMATION ---------------------------------------- \\
const DetailsFormation = ({ onClose, formation }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formation: null,
        loadingExport: false,
    });

    useEffect(() => {
        if (state.loading) {
            getFormation(formation.id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, formation: res.data.data.formation });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingExport) {
            FormationDetailsExport(t, state, setState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingExport]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('training.details')}
                        </p>
                        <Divider />
                        {/* ---------------------- CREATOR ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('entered.by')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.formation.creator?.full_name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- NAME ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('name')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.formation.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- DATE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('date')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{getFormattedDate(state.formation.formation_date)}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- START TIME ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('time.start')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.formation.start}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- END TIME ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('time.end')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.formation.end}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- PLACE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('place')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>
                                    {state.formation.place?.name}
                                    <br />
                                    {state.formation.place_details}
                                </span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- TYPE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('type')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>
                                    {state.formation.type?.name}
                                    <br />
                                    {state.formation.type_details}
                                </span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- DESCRIPTION ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('description')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.formation.program}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- COMMENT ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('comment')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.formation.comment}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- TRAINERS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('trainers')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                {state.formation.formation_trainers.map(item => (
                                    <div key={item.id} style={{ ...style.dF, ...style.aiC }}>
                                        {item.signature_date ? (
                                            item.present ? (
                                                <Tooltip title={t('signed')}
                                                    item={
                                                        <IconCheck style={{ ...styles.icon, color: 'green' }} />
                                                    }
                                                />
                                            ) : (
                                                <Tooltip title={t('signed') + ' (' + t('missing') + ')'}
                                                    item={
                                                        <IconCancel style={{ ...styles.icon, color: '#DA6236' }} />
                                                    }
                                                />
                                            )
                                        ) : (
                                            <Tooltip title={t('signature.pending')}
                                                item={
                                                    <IconTimer style={{ ...styles.icon, color: 'gray' }} />
                                                }
                                            />
                                        )}
                                        <span>
                                            {item.trainer.full_name}
                                            &nbsp;
                                            {item.trainer.company && '(' + item.trainer.company.name + ')'}
                                        </span>
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- LEARNERS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('learners')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                {state.formation.formation_learners.map(item => (
                                    <div key={item.id} style={{ ...style.dF, ...style.aiC }}>
                                        {item.signature_date ? (
                                            item.present ? (
                                                <Tooltip title={t('signed')}
                                                    item={
                                                        <IconCheck style={{ ...styles.icon, color: 'green' }} />
                                                    }
                                                />
                                            ) : (
                                                <Tooltip title={t('signed') + ' (' + t('missing') + ')'}
                                                    item={
                                                        <IconCancel style={{ ...styles.icon, color: '#DA6236' }} />
                                                    }
                                                />
                                            )
                                        ) : (
                                            <Tooltip title={t('signature.pending')}
                                                item={
                                                    <IconTimer style={{ ...styles.icon, color: 'gray' }} />
                                                }
                                            />
                                        )}
                                        <span>
                                            {item.learner.full_name}
                                            &nbsp;
                                            {item.learner.company && '(' + item.learner.company.name + ')'}
                                        </span>
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- SKILLBASE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('skillbase')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>
                                    {getTranslation('name', state.formation.skillbase)}
                                </span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- DESCRIPTORS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('descriptors')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                {state.formation.descriptors.map((descriptor, index) => (
                                    <Fragment key={index}>
                                        <Tooltip
                                            title={getTranslation('name', descriptor.unit) + ' / ' + getTranslation('name', descriptor.learning_outcome)}
                                            item={(
                                                <div style={{ cursor: 'pointer' }}>
                                                    {descriptor.competences.map(competence => (
                                                        <Fragment key={competence.id}>
                                                            <span>
                                                                {competence.numerotation + t('competence.numerotation') + ' ' + getTranslation('name', competence)}
                                                            </span>
                                                            <br />
                                                        </Fragment>
                                                    ))}
                                                    {descriptor.knowledges.map(knowledge => (
                                                        <Fragment key={knowledge.id}>
                                                            <span>
                                                                {knowledge.numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', knowledge)}
                                                            </span>
                                                            <br />
                                                        </Fragment>
                                                    ))}
                                                    {descriptor.skills.map(skill => (
                                                        <Fragment key={skill.id}>
                                                            <span>
                                                                {skill.numerotation + t('skill.numerotation') + ' ' + getTranslation('name', skill)}
                                                            </span>
                                                            <br />
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                        {(state.formation.descriptors.length > 0 && index < state.formation.descriptors.length - 1) && (
                                            <Divider style={style.m10} />
                                        )}
                                    </Fragment>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            {t('close')}
                        </Button>
                        <Button
                            onClick={() => setState({ ...state, loadingExport: true })}
                            disabled={state.loadingExport}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: mobitrainColor }}
                        >
                            PDF
                            {state.loadingExport ? (
                                <CircularProgress color="inherit" size={16} style={{ marginLeft: 5 }} />
                            ) : (
                                <IconSave style={{ marginLeft: 5, fontSize: 16 }} />
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog> 
    );
};

export default DetailsFormation;
