import React, { Fragment, useState, useEffect } from 'react';
import { useRouteMatch, Switch, Route, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Grid, Divider, IconButton } from '@material-ui/core';
import { Search as IconSearch, Cancel as IconCancel, Delete as IconDelete, Create as IconEdit, Assignment as IconDetails } from '@material-ui/icons';

// Services
import { getMobilities } from 'services/requests';
import { mobitrainColor, getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';
import { getStoredUser } from 'services/storage';

// Components
import CreateMobility from './mobility-create';
import UpdateMobility from './mobility-update';
import { DeleteMobility, DetailsMobility } from './mobility-modals';
import ExportMobility from './mobility-export';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// --------------------------------------- MOBILITY ROUTER -------------------------------------- \\
const MobilityRouter = () => {
    return (
        <Switch>
            <Route exact path="/mobitrain/mobility">
                <Mobility />
            </Route>
            <Route exact path="/mobitrain/mobility/create">
                <CreateMobility />
            </Route>
            <Route exact path="/mobitrain/mobility/update/:mobilityId">
                <UpdateMobility />
            </Route>
        </Switch>
    );
};

// ------------------------------------------ MOBILITY ------------------------------------------ \\
const Mobility = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const storedFilters = JSON.parse(sessionStorage.getItem('mobilities-filters'));
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        mobilities: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        learner: storedFilters?.learner || '',
        date: storedFilters?.date || null,
        origin: storedFilters?.origin || '',
        host: storedFilters?.host || '',
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('mobilities-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            learner: '',
            date: null,
            origin: '',
            host: '',
        });
    };

    // Get Mobilities
    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('mobilities-filters', JSON.stringify({
                learner: state.learner,
                date: state.date,
                origin: state.origin,
                host: state.host,
            }));
            
            getMobilities(
                state.offset,
                state.limit,
                state.learner,
                state.date ? moment(state.date).format('YYYY-MM-DD') : null,
                state.origin,
                state.host,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        mobilities: res.data.data.mobilities,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('mobility')}
            </BreadcrumbsItem>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push('/mobitrain/mobility/create')}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: mobitrainColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company.origin')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company.host')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('mobility')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={4} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner}
                                onChange={e => setState({ ...state, learner: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('date')}
                                value={state.date}
                                onChange={date => onSelectSearch('date', date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('company.origin')}
                                value={state.origin}
                                onChange={e => setState({ ...state, origin: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('company.host')}
                                value={state.host}
                                onChange={e => setState({ ...state, host: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <ExportMobility data={state} />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- MOBILITY LIST -------------------- */}
                <MobilityList
                    ROLE_ID={ROLE_ID}
                    mobilities={state.mobilities}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// ---------------------------------------- MOBILITY LIST --------------------------------------- \\
const MobilityList = ({ ROLE_ID, mobilities, loading, error, refetch }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const USER_ID = getStoredUser()?.id;

    const [state, setState] = useState({
        mobility: null,
        openDetailsMobility: false,
        openDeleteMobility: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {mobilities != null && mobilities.length > 0 ? (
                    mobilities.map(mobility => (
                        <Fragment key={mobility.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{mobility.learner?.full_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>
                                        {getFormattedDate(mobility.start_date) + ' - ' + getFormattedDate(mobility.end_date)}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{mobility.origin_company_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{mobility.host_company_name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, mobility, openDetailsMobility: true })}
                                                className="list-btn"
                                            >
                                                <IconDetails />
                                            </IconButton>
                                        }
                                    />
                                    {(ROLE_ID <= 2 || USER_ID === mobility.creator?.id) && (
                                        <>
                                            <Tooltip title={t('edit')}
                                                item={
                                                    <IconButton
                                                        onClick={() => history.push('/mobitrain/mobility/update/' + mobility.id)}
                                                        className="list-btn"
                                                    >
                                                        <IconEdit />
                                                    </IconButton>
                                                }
                                            />
                                            <Tooltip title={t('delete')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, mobility, openDeleteMobility: true })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- DETAILS MOBILITY -------------------- */}
            {state.openDetailsMobility && (
                <DetailsMobility
                    onClose={() => setState({ ...state, openDetailsMobility: false })}
                    mobility={state.mobility}
                />
            )}
            {/* -------------------- DELETE MOBILITY -------------------- */}
            {state.openDeleteMobility && (
                <DeleteMobility
                    onClose={() => setState({ ...state, openDeleteMobility: false })}
                    refetch={() => handleRefetch('openDeleteMobility')}
                    mobility={state.mobility}
                />
            )}
        </>
    );
};

export default MobilityRouter;
