import React from 'react';

// Style
import style from 'style';

// ---------------------------------------- INPUT TEXT ---------------------------------------- \\
const InputText = ({ id, placeholder, value, onChange, onKeyPress, disabled, type, multiline }) => {
    return (
        <>
            {multiline ? (
                <textarea
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    className="form-control"
                    style={style.fs14}
                />
            ) : (
                <input
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    disabled={disabled}
                    type={type}
                    min={0}
                    className="form-control"
                    style={style.fs14}
                />
            )}
        </>
    );
};

InputText.defaultProps = {
    type: 'text',
    multiline: false,
    disabled: false,
};

export default InputText;
