import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, IconButton, Dialog, DialogContent, DialogActions, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { People as IconPeople, ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getCompaniesAccessible, getCompanyAccesses, addAccess, removeAccess, removeCompanyRequest } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    btnModal: {
        padding: '2px 8px',
    },
};

// ----------------------------------- AUTHORIZED COMPANIES ------------------------------------ \\
const AuthorizedCompanies = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        total_count: 0,
    });

    useEffect(() => {
        if (state.loading) {        
            getCompaniesAccessible().then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        data: res.data.data.companies,
                        total_count: res.data.data.companies.length,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <b>{t('companies.accessible')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('country')}</span>
                            </Grid>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('address')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('email')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                {ROLE_ID <= 2 && (
                                    <span style={style.cW}>{t('accesses.handle')}</span>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('companies')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- COMPANY LIST -------------------- */}
                <CompanyList
                    ROLE_ID={ROLE_ID}
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                />
            </div>
        </>
    );
};

// --------------------------------------- COMPANY LIST ---------------------------------------- \\
const CompanyList = ({ ROLE_ID, data, loading, error }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        company: undefined,
        openAccessesModal: false,
    });

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="half-container-content">
                {data.length > 0 ? (
                    data.map(company => (
                        <Fragment key={company.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{company.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{company.country ? company.country.name : ''}</span>
                                </Grid>
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{company.address} {company.zip_code} {company.city}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{company.email}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    {ROLE_ID < 3 && (
                                        <IconButton
                                            onClick={() => setState({ ...state, openAccessesModal: true, company })}
                                            className="list-btn"
                                        >
                                            <IconPeople />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                        <>     
                            <Grid container alignItems="center" style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                )}
            </div>
            {/* -------------------- ACCESSES MODAL -------------------- */}
            {state.openAccessesModal && (
                <AccessesModal
                    onClose={() => setState({ ...state, openAccessesModal: false })}
                    company={state.company}
                />
            )}
        </>
    );
};

// -------------------------------------- ACCESSES MODAL --------------------------------------- \\
const AccessesModal = ({ onClose, company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: true,
        loadingAdd: false,
        loadingReject: false,
        loadingRemove: false,
        error: undefined,
        users_accesses: undefined,
        users_requests: undefined,
        user: undefined,
    });

    useEffect(() => {
        if (state.loading) {
            getCompanyAccesses(company.id).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        users_accesses: res.data.data.users_accesses,
                        users_requests: res.data.data.users_requests
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loadingAdd) {
            addAccess(company.id, state.user.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingAdd: false, loading: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingAdd: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAdd]);

    useEffect(() => {
        if (state.loadingReject) {
            removeCompanyRequest(company.id, state.user.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingReject: false, loading: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingReject: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingReject]);

    useEffect(() => {
        if (state.loadingRemove) {
            removeAccess(company.id, state.user.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingRemove: false, loading: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingRemove: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingRemove]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {company.name}
                        </p>
                        <Accordion>
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <span style={style.fwB}>
                                    {t('access.requests')} ({state.users_requests.length})
                                </span>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0, display: 'block' }}>
                                {state.users_requests.length > 0 ? (
                                    state.users_requests.map(user => (
                                        <Fragment key={user.id}>
                                            <Divider />
                                            <Grid container alignItems="center" style={style.p10}>
                                                <Grid item xs={12} md={6}>
                                                    <span>{user.full_name}</span>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Button
                                                        onClick={() => setState({ ...state, loadingAdd: true, user })}
                                                        variant="contained"
                                                        style={styles.btnModal}
                                                    >
                                                        {t('accept')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Button
                                                        onClick={() => setState({ ...state, loadingReject: true, user })}
                                                        color="secondary" variant="contained"
                                                        style={styles.btnModal}
                                                    >
                                                        {t('reject')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ))
                                ) : (
                                    <>
                                        <Divider />
                                        <Grid container alignItems="center" style={style.p10}>
                                            <span>{t('none')}</span>
                                        </Grid>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <span style={style.fwB}>
                                    {t('accesses.users')} ({state.users_accesses.length})
                                </span>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0, display: 'block' }}>
                                {state.users_accesses.length > 0 ? (
                                    state.users_accesses.map(user => (
                                        <Fragment key={user.id}>
                                            <Divider />
                                            <Grid container alignItems="center" style={style.p10}>
                                                <Grid item xs={12} md={9}>
                                                    <span>{user.full_name}</span>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <Button
                                                        onClick={() => setState({ ...state, loadingRemove: true, user })}
                                                        color="secondary" variant="contained"
                                                        style={styles.btnModal}
                                                    >
                                                        {t('remove')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ))
                                ) : (
                                    <>
                                        <Divider />
                                        <Grid container alignItems="center" style={style.p10}>
                                            <span>{t('none')}</span>
                                        </Grid>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcC}>
                        <Button onClick={onClose} variant="contained">
                            {t('close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AuthorizedCompanies;
