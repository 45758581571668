import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getFormattedDate, getTranslation } from 'services/helpers';
import { getCertifications } from 'services/requests';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- CERTIFICATION EXPORT ---------------------------------------- \\
const ExportCertification = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
    });

    function getAllCertificationsToExport(certifications, offset) {
        getCertifications(
            offset,
            data.limit,
            data.certification_date ? moment(data.certification_date).format('YYYY-MM-DD') : null,
            data.skillbase_id,
            data.assessor,
            data.learner,
            true,
        ).then(res => {
            if (res?.status === 200) {

                let res_certifications = res.data.data.certifications;
                certifications = certifications.concat(res_certifications);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * certifications.length) / data.total_count) + '%',
                });

                if (res_certifications.length < data.limit) {
                    createExcelFile(certifications);
                }
                else {
                    getAllCertificationsToExport(certifications, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(certifications) {
        if (certifications.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('certifications'));

            // Header
            worksheet.columns = [
                { header: t('date'), key: 'date', width: 15 },
                { header: t('skillbase'), key: 'skillbase', width: 25 },
                { header: t('unit'), key: 'unit', width: 25 },
                { header: t('assessor'), key: 'assessor', width: 45 },
                { header: t('learner'), key: 'learner', width: 45 },
                { header: t('document'), key: 'document', width: 25 }
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            certifications.map(certification => {

                var units = '';
                certification.units.map(unit => {
                    return units = units + unit.position + '. ' + getTranslation('name', unit) + '\r\n';
                });

                var assessor_company = certification.company_name ? '(' + certification.company_name + ')' : '';
                var learner_company = certification.user.company ? '(' + certification.user.company.name + ')' : '';

                return worksheet.addRow({
                    date: getFormattedDate(certification.certification_date),
                    skillbase: getTranslation('name', certification.skillbase),
                    unit: units,
                    assessor: certification.assessor_name + ' ' + assessor_company,
                    learner: certification.user.full_name + ' ' + learner_company,
                    document: certification.document != null ? 'Oui' : 'Non'
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('certifications') +  '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllCertificationsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default ExportCertification;
