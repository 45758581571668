import React from 'react';
import { useTranslation } from 'react-i18next';

// File-Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { fileUrl } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import DatePicker from 'components/items/date-picker';
import InputFile from 'components/items/input-file';
import FieldRequired from 'components/items/field-required';

// --------------------------------------- CERTIFICATION FORM PAGE 1 ---------------------------------------- \\
const CertificationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage2 = () => {
        if (state.certification_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-certification-document').files[0];
            setState({ ...state, page: 2, document: file });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- DATE -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('certification.date')}</b><FieldRequired />
                            <DatePicker
                                placeholder={t('certification.date')}
                                value={state.certification_date}
                                onChange={certification_date => setState({ ...state, certification_date })}
                                outlined
                            />
                        </div>
                        {/* -------------------- DOCUMENT -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('document')}</b>
                            <InputFile
                                inputId="certification-document"
                            />
                            {state.document_url && (
                                <Button
                                    onClick={() => saveAs(fileUrl + state.document_url, t('certification'))}
                                    variant="contained" color="primary"
                                    style={{ textTransform: 'none', marginTop: 10 }}
                                >
                                    {t('Télécharger le document existant')}
                                    <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={false} md={4} />
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>1 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CertificationFormPage1;
