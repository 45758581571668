import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Button, Divider, Accordion, AccordionSummary } from '@material-ui/core';
import { ExpandMore as IconExpand } from '@material-ui/icons';

// Services
import { getHardPosition, getUser, getSkillbase } from 'services/requests';
import { getTranslation, mobipassColor, mobipassColor2, getFormattedDate } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Badge from 'components/items/badge';

// Style
import style from 'style';

const styles = {
    td: {
        padding: 10,
        border: '1px solid lightgray',
    }, 
};

// ---------------------------------- HARD POSITION DETAILS ---------------------------------------- \\
const HardPositionDetails = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { userId, hardPositionId } = useParams();

    const [state, setState] = useState({
        loadingHardPosition: true,
        loading: false,
        error: false,
        hard_position: null,
        competence_ids: [],
        knowledge_ids: [],
        skill_ids: [],
        user: null,
        skillbase: null,
        expanded: null,
    });

    const getMasteredDescriptorsNumber = unit => {
        var number = 0;
        unit.learning_outcomes.forEach(learning => {
            learning.competences.forEach(competence => {
                if (state.competence_ids.findIndex(c => c.id === competence.id) !== -1) {
                    number += 1;
                };
            });
            learning.knowledges.forEach(knowledge => {
                if (state.knowledge_ids.findIndex(k => k.id === knowledge.id) !== -1) {
                    number += 1;
                };
            });
            learning.skills.forEach(skill => {
                if (state.skill_ids.findIndex(s => s.id === skill.id) !== -1) {
                    number += 1;
                };
            });
        });
        return number;
    };

    useEffect(() => {
        if (state.loadingHardPosition) {
            getHardPosition(hardPositionId).then(res => {
                if (res?.status === 200) {

                    let hard_position = res.data.data.hard_position;
                    let competence_ids = [], knowledge_ids = [], skill_ids = [];

                    hard_position.competences.forEach(c => {
                        competence_ids.push({ id: c.id, mastery: c.mastery });
                    });

                    hard_position.knowledges.forEach(k => {
                        knowledge_ids.push({ id: k.id, mastery: k.mastery });
                    });

                    hard_position.skills.forEach(s => {
                        skill_ids.push({ id: s.id, mastery: s.mastery });
                    });

                    setState({
                        ...state,
                        loadingHardPosition: false,
                        loading: true,
                        hard_position,
                        competence_ids,
                        knowledge_ids,
                        skill_ids,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingHardPosition: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading && state.hard_position != null) {
            axios.all([getUser(state.hard_position.learner_id), getSkillbase(state.hard_position.skillbase?.id)])
            .then(axios.spread(function (res_user, res_skillbase) {
                if (res_user?.status === 200 && res_skillbase?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        user: res_user.data.data.user,
                        skillbase: res_skillbase.data.data.skillbase,
                    });
                }
                else {
                    console.log(res_user + res_skillbase);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loadingHardPosition || state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4}>
                    <Button onClick={() => history.push('/mobipass/positioning/' + userId)} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                        {state.user?.full_name}
                        <br />
                        {t('positioning.of')} {getFormattedDate(state.hard_position.position_date)}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            <div className="full-container" style={{ paddingBottom: 24 }}>
                <Grid style={{ paddingTop: 24, paddingBottom: 24, fontSize: 14 }}>
                    <b className="input-title">{t('assessor')} : </b><span>{state.hard_position.assessor?.full_name}</span>
                    <br />
                    <b className="input-title">{t('skillbase')} : </b><span>{getTranslation('name', state.hard_position.skillbase)}</span>
                </Grid>
                {/* -------------------- UNITS -------------------- */}
                <Grid container style={{ backgroundColor: mobipassColor, color: 'white', padding: '10px 20px' }}>
                    <span>{t('units.of.learning.outcomes')}</span>
                </Grid>
                {/* -------------------- UNIT LIST -------------------- */}
                <div>
                    {(state.skillbase.units != null && state.skillbase.units.length > 0) ? (
                        state.skillbase.units.map(unit => (
                            <Accordion
                                key={unit.id}
                                expanded={state.expanded === unit.id}
                                onChange={() => setState({ ...state, expanded: unit.id === state.expanded ? null : unit.id })}
                            >
                                <AccordionSummary style={{ backgroundColor: mobipassColor2 }} expandIcon={<IconExpand />}>
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <span style={{ fontWeight: 'bold' }}>
                                            {unit.position}. {getTranslation('name', unit)}
                                        </span>
                                        <Badge number={getMasteredDescriptorsNumber(unit)} />
                                    </Grid>
                                </AccordionSummary>
                                <Divider />
                                <div style={{ overflow: 'auto', maxHeight: 400 }}>
                                    <PositioningTable
                                        unit={unit}
                                        state={state}
                                    />
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid style={{ padding: 10 }}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

// ------------------------------------- POSITIONING TABLE ----------------------------------------- \\
const PositioningTable = ({ unit, state }) => {
    const { t } = useTranslation();

    const getMastery = (descriptor_ids, descriptor_id, index) => {
        let mastery = descriptor_ids.find(d => (d.id === descriptor_id && d.index === index))?.mastery;

        switch (mastery) {
            case 0:
                return <span style={{ textTransform: 'uppercase', color: '#B60909' }}>{t('mastery.0')}</span>;

            case 1:
                return <span style={{ textTransform: 'uppercase', color: '#E78200' }}>{t('mastery.1')}</span>;

            case 2:
                return <span style={{ textTransform: 'uppercase', color: '#0EB813' }}>{t('mastery.2')}</span>;

            default:
                return '';
        };
    };

    return (
        <table style={{ width: '100%', minWidth: 1200 }}>
            <thead>
                <tr>
                    {/* -------------------- LEARNING -------------------- */}
                    <th style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                        <span style={style.ttU}>{t('learning.outcomes')}</span>
                    </th>
                    {/* -------------------- DESCRIPTOR -------------------- */}
                    <th style={{ ...styles.td, width: '60%', textAlign: 'center' }}>
                        <span style={style.ttU}>{t('descriptors')}</span>
                    </th>
                    {/* -------------------- MASTERY -------------------- */}
                    <th style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                        <span style={style.ttU}>{t('mastery')}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {unit.learning_outcomes.map(learning => (
                    <Fragment key={learning.id}>
                        <tr>
                            <td
                                style={{ ...styles.td, width: '20%', textAlign: 'center' }}
                                rowSpan={learning.competences?.length + learning.knowledges?.length + learning.skills?.length + 1}
                            >
                                <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                            </td>
                        </tr>
                        {/* -------------------- COMPETENCES -------------------- */}
                        {learning.competences.map(competence => (
                            <tr key={competence.id}>
                                <td style={{ ...styles.td, width: '60%' }}>
                                    <span>{competence.numerotation + t('competence.numerotation')} {getTranslation('name', competence)}</span>
                                </td>
                                <td style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                                    {getMastery(state.competence_ids, competence.id)}
                                </td>
                            </tr>
                        ))}
                        {/* -------------------- KNOWLEDGES -------------------- */}
                        {learning.knowledges.map(knowledge => (
                            <tr key={knowledge.id}>
                                <td style={{ ...styles.td, width: '60%' }}>
                                    <span>{knowledge.numerotation + t('knowledge.numerotation')} {getTranslation('name', knowledge)}</span>
                                </td>
                                <td style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                                    {getMastery(state.knowledge_ids, knowledge.id)}
                                </td>
                            </tr>
                        ))}
                        {/* -------------------- SKILLS -------------------- */}
                        {learning.skills.map(skill => (
                            <tr key={skill.id}>
                                <td style={{ ...styles.td, width: '60%' }}>
                                    <span>{skill.numerotation + t('skill.numerotation')} {getTranslation('name', skill)}</span>
                                </td>
                                <td style={{ ...styles.td, width: '20%', textAlign: 'center' }}>
                                    {getMastery(state.skill_ids, skill.id)}
                                </td>
                            </tr>
                        ))}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default HardPositionDetails;
