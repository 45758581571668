import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { createSubscription } from 'services/requests';

// Components
import SubscriptionForm from './subscription-form';
import Spinner from 'components/items/spinner';
import { servicesColor } from 'services/helpers';

// Style
import style from 'style';

// ---------------------------------------- CREATE SUBSCRIPTION ---------------------------------------- \\
const CreateSubscription = ({ onClose, refetch }) => {
    const match = useRouteMatch();
    const  { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        subscription_type_id: '',
        company_id: '',
        start_date: null,
        end_date: null,
        price: '',
        nb_items: '',
    });

    function handleCreateSubscription() {
        if (state.subscription_type_id === '' ||
            state.company_id === '' ||
            !state.start_date ||
            !state.end_date ||
            (state.subscription_type_id === 3 && state.price === '') ||
            (state.subscription_type_id === 3 && state.nb_items === '')
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.start_date > state.end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createSubscription({
                bill_id: match.params.billId,
                subscription_type_id: state.subscription_type_id,
                company_id: state.company_id,
                start_date: moment(state.start_date).format('YYYY-MM-DD'),
                end_date: moment(state.end_date).format('YYYY-MM-DD'),
                price: state.price !== '' ? state.price : null,
                nb_items: state.nb_items !== '' ? state.nb_items : null,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('subscription.add')}
                        </p>
                        <SubscriptionForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleCreateSubscription}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateSubscription;
