import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// i18n
import i18next from 'i18next';

// Services 
import { theme } from 'services/helpers';
import { languages } from 'services/constants';
import { setLocale, getLocale } from 'services/storage';

// Material-UI
import { MenuItem, Select } from '@material-ui/core';

// ---------------------------------------- SELECT LANGUAGES ---------------------------------------- \\
const SelectLanguage = () => {
	const { t } = useTranslation();
	const locale = getLocale() || 'fr';
	const [options, setOptions] = useState(languages);

	const onChange = value => {
		if (value !== locale) {
			setLocale(value);
			window.location.reload();
		}
	};

	useEffect(() => {

		moment.locale(locale);
		i18next.changeLanguage(locale);

		if (theme === 'mobirhin') {
			setOptions(languages.filter(l => l.code === 'fr' || l.code === 'de'));
		}
		else {
			setOptions(languages.filter(l => l.code !== 'lv'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Select
			variant="outlined"
			value={locale}
			onChange={e => onChange(e.target.value)}
			style={{ minWidth: 150, color: 'white' }}
            SelectDisplayProps={{ style: { padding: '10px 14px' } }}
			MenuProps={{
				style: { maxHeight: 400, maxWidth: 500 },
				getContentAnchorEl: null, 
				anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
			}}
		>
			{options.map(l => (
				<MenuItem key={l.id} value={l.code}>
					<span>{t(l.label)}</span>
				</MenuItem>
			))}
		</Select>
	);
};

export default SelectLanguage;
