import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button, Fab, ExpansionPanel, ExpansionPanelSummary, IconButton } from '@material-ui/core';
import { ExpandMore as IconExpand, Create as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getCleaDomains } from 'services/requests';
import { servicesColor, getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CleaSubDomain from './subdomains';
import CreateCleaDomain from './create-clea-domain';
import UpdateCleaDomain from './update-clea-domain';
import DeleteCleaDomain from './delete-clea-domain';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ------------------------------------- CLEA MANAGEMENT ------------------------------------- \\
const CleaManagement = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        clea_domains: [],
    });

    useEffect(() => {
        if (state.loading) {
            getCleaDomains().then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        clea_domains: res.data.data.clea_domains,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />
    if (state.error) return <Error />

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('clea.certificate')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR CLEA -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>
            </Grid>
            <div className="full-container">
                {/* -------------------- CLEA LIST HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: servicesColor, padding: '0px 16px' }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={10} style={{ padding: '10px 0px' }}>
                                <span style={style.cW}>{t('clea.domain')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.taC, padding: '10px 0px', paddingRight: 80 }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.taC, padding: '10px 0px' }}>
                            <span style={style.cW}>{t('clea.domain')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- CLEA DOMAIN LIST -------------------- */}
                <CleaDomainList
                    loading={state.loading}
                    error={state.error}
                    clea_domains={state.clea_domains}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
        </>
    );
};

// ------------------------------------- CLEA DOMAIN LIST ------------------------------------ \\
const CleaDomainList = ({ loading, error, clea_domains, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        domain: null,
        openCreateCleaDomain: false,
        openUpdateCleaDomain: false,
        openDeleteCleaDomain: false,
    });
    
    function handleOpenModal(event, open, domain) {
        event.stopPropagation();
        setState({ ...state, [open]: true, domain });
    }

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div>
                {clea_domains?.length > 0 ? (
                    clea_domains.map(domain => (
                        <ExpansionPanel key={domain.id}>
                            <ExpansionPanelSummary expandIcon={<IconExpand />}>
                                <Grid container alignItems='center' style={style.p0_10}>
                                    <Grid item xs={12} md={10}>
                                        <span style={style.fwB}>
                                            {getTranslation('name', domain)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} md={2} style={style.taC}>
                                        <Tooltip title={t('edit')}
                                            item={
                                                <IconButton
                                                    onClick={e => handleOpenModal(e, 'openUpdateCleaDomain', domain)}
                                                    className="list-btn"
                                                >
                                                    <IconEdit />
                                                </IconButton>
                                            }
                                        />
                                        <Tooltip title={t('delete')}
                                            item={
                                                <IconButton
                                                    onClick={e => handleOpenModal(e, 'openDeleteCleaDomain', domain)}
                                                    className="list-btn"
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <Divider />
                            <div style={{ padding: 24 }}>
                                <CleaSubDomain
                                    domain={domain}
                                    refetch={refetch}
                                />
                            </div>
                        </ExpansionPanel>
                    ))
                ) : (
                    <p style={{ padding: '10px 15px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        {t('none')}
                    </p>
                )}
            </div>
            {/* -------------------- ADD DOMAIN BUTTON -------------------- */}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateCleaDomain: true })}
                    className="fab"
                    style={{ backgroundColor: servicesColor, color: 'white' }}
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('clea.domain.add')}
                </span>
            </div>
            {/* -------------------- CREATE CLEA DOMAIN -------------------- */}
            {state.openCreateCleaDomain && (
                <CreateCleaDomain
                    onClose={() => setState({ ...state, openCreateCleaDomain: false })}
                    refetch={() => handleRefetch('openCreateCleaDomain')}
                />
            )}
            {/* -------------------- UPDATE CLEA DOMAIN -------------------- */}
            {state.openUpdateCleaDomain && (
                <UpdateCleaDomain
                    onClose={() => setState({ ...state, openUpdateCleaDomain: false })}
                    refetch={() => handleRefetch('openUpdateCleaDomain')}
                    domain={state.domain}
                />
            )}
            {/* -------------------- DELETE CLEA DOMAIN -------------------- */}
            {state.openDeleteCleaDomain && (
                <DeleteCleaDomain
                    onClose={() => setState({ ...state, openDeleteCleaDomain: false })}
                    refetch={() => handleRefetch('openDeleteCleaDomain')}
                    domain={state.domain}
                />
            )}
        </>
    );
};

export default CleaManagement;
