import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button, Grid, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getUsersListPerMonth } from 'services/requests';

// Components
import DatePicker from 'components/items/date-picker';

// ----------------------------------------- USERS LIST PER MONTH EXPORT ------------------------------------------- \\
const UsersListPerMonthExport = ({ onClose, company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        from: null,
        to: null,
    });

    const onExport = () => {
        if (state.from == null || state.to == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.from > state.to) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            getUsersListPerMonth(company.id, {
                from: moment(state.from).format('YYYY-MM-DD'),
                to: moment(state.to).format('YYYY-MM-DD'),
            }).then(res => {
                if (res?.status === 200) {
                    UsersListPerMonthExcelExport(t, enqueueSnackbar, state, setState, company, res.data.data.months);
                }
                else {
                    setState({ ...state, loading: false });
                    enqueueSnackbar(res, { variant: 'error' });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open>
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {t('users.list.per.month')}
                </p>
                <Grid container alignItems="center">
                    <Grid item xs={6} style={{ paddingRight: 5 }}>
                        <b className="input-title">
                            {t('start.date')}
                        </b>
                        <DatePicker
                            placeholder={t('start.date')}
                            value={state.from}
                            onChange={from => setState({ ...state, from })}
                            outlined
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        <b className="input-title">
                            {t('end.date')}
                        </b>
                        <DatePicker
                            placeholder={t('end.date')}
                            value={state.to}
                            onChange={to => setState({ ...state, to })}
                            outlined
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button
                    onClick={onClose}
                    disabled={state.loading}
                    variant="contained"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={onExport}
                    disabled={state.loading}
                    variant="contained" color="primary"
                >
                    {t('export')}
                    {state.loading ? (
                        <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
                    ) : (
                        <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------- USERS LIST PER MONTH EXPORT ------------------------------------------- \\
const UsersListPerMonthExcelExport = (t, enqueueSnackbar, state, setState, company, months) => {

    // Create Excel File
    var workbook = new ExcelJS.Workbook();

    // Add Worksheet to the Workbook
    var worksheet = workbook.addWorksheet(company.name);

    // Headers
    worksheet.columns = [
        { header: t('month'), key: 'month', width: 20 },
        { header: t('last.name'), key: 'last_name', width: 30 },
        { header: t('first.name'), key: 'first_name', width: 30 },
        { header: t('email'), key: 'email', width: 60 },
    ];
    worksheet.getRow(1).font = { bold: true };
    worksheet.getColumn(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    worksheet.getColumn(2).alignment = { vertical: 'middle', wrapText: true };
    worksheet.getColumn(3).alignment = { vertical: 'middle', wrapText: true };
    worksheet.getColumn(4).alignment = { vertical: 'middle', wrapText: true };

    // Rows
    let monthOffset = 2;
    Object.entries(months).forEach(([key, value]) => {
        if (value.length > 0) {
            value.forEach(learner => {
                worksheet.addRow({
                    month: moment(key).format('MMMM YYYY') + '\r\n (' + value.length + ' ' + t('learners') + ')',
                    last_name: learner.last_name,
                    first_name: learner.first_name,
                    email: learner.email,
                });
            });
            if (value.length > 1) {
                worksheet.mergeCells(`A${monthOffset}`, `A${monthOffset + (value.length - 1)}`);
            }
            monthOffset += value.length;
        }
        else {
            worksheet.addRow({
                month: moment(key).format('MMMM YYYY') + '\r\n (' + value.length + ' ' + t('learners') + ')',
                last_name: '',
                first_name: '',
                email: '',
            });
            monthOffset += 1;
        }
    });

    // Save Excel File
    workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), company.name + ' - ' + t('users.list.per.month') + '.xlsx'));
    setState({ ...state, loading: false });
    enqueueSnackbar(t('download.complete'), { variant: 'success' });
};

export default UsersListPerMonthExport;
