import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { editTool } from 'services/requests';
import { getTranslation, initialLanguagesObject, servicesColor } from 'services/helpers';

// Components
import ToolForm from './tool-form';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- UPDATE TOOL ---------------------------------------- \\
const UpdateTool = ({ onClose, refetch, tool }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        tool_type_id: tool.tool_type.id,
        skillbases: tool.skillbases.map(skillbase => ({ label: getTranslation('name', skillbase), value: skillbase.id })),
        roles: tool.roles.map(role => ({ label: role.name, value: role.id })),
        active: tool.active.toString(),
        names: initialLanguagesObject(),
        descriptions: initialLanguagesObject(),
    });

    function handleEditTool() {
        if (!state.skillbases) {
            enqueueSnackbar(t('skillbase.choose'), { variant: 'warning' });
        }
        else if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (tool) {
            let names = state.names;
            let descriptions = state.descriptions;

            Object.entries(tool.translations).map(([key, value]) => {
                return (
                    names[key] = value.name || '',
                    descriptions[key] = value.description || ''
                );
            });

            setState({ ...state, names, descriptions });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tool])

    // Handle Edit Tool
    useEffect(() => {
        if (state.loading) {
            editTool(tool.id, {
                tool_type_id: state.tool_type_id,
                skillbases: state.skillbases.map(skillbase => skillbase.value),
                roles: state.roles ? state.roles.map(role => role.value) : null,
                active: state.active,
                name: state.names,
                description: state.descriptions,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading])

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('tool.edit')}
                        </p>
                        <ToolForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleEditTool}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}    
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateTool;
