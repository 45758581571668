import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';

// Services
import { createCleaDomain } from 'services/requests';
import { initialLanguagesObject, servicesColor } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import Spinner from 'components/items/spinner';
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ------------------------------------- CREATE CLEA DOMAIN ---------------------------------- \\
const CreateCleaDomain = ({ onClose, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        names: initialLanguagesObject(),
    });

    function handleNames(event, code) {
        setState({
            ...state,
            names: Object.assign({}, state.names, { [code]: event.target.value })
        });
    }

    function handleAddDomain() {
        if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            createCleaDomain({ name: state.names }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('clea.domain.add')}
                        </p>
                        <Divider style={style.m15_0} />
                        <div style={style.taC}>
                            <span style={style.fwB}>{t('clea.domain.name')}</span>
                        </div>
                        {languages.map(language => (
                            <InputTextGrid
                                key={language.id}
                                placeholder={t(language.label)}
                                value={state.names[language.code]}
                                onChange={(e) => handleNames(e, language.code)}
                            />
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleAddDomain}
                            variant="contained"
                            style={{ color: 'white', backgroundColor: servicesColor }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateCleaDomain;
