import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { Button } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { months } from 'services/constants';

// ---------------------------------------- FORMATIONS EXPORT ---------------------------------------- \\
const FormationsChartBarExport = ({ type, data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const createExcelFile = () => {

        // Create Excel File
        var workbook = new ExcelJS.Workbook();

        // Add Worksheet to the Workbook
        var worksheet = workbook.addWorksheet('Statistiques');

        // Header
        worksheet.columns = [
            { header: 'Mois', key: 'month', width: 30 },
            { header: 'Apprenants formés', key: 'trained_learners', width: 30 },
            { header: 'Mise en situation (h)', key: 'h_scenario', width: 30 },
            { header: 'Phase réflexive (h)', key: 'h_reflection', width: 30 },
            { header: 'Autres (h)', key: 'h_others', width: 30 },
        ];
        worksheet.getRow(1).font = { bold: true };

        // Rows
        Object.entries(data.months).map(([key, value]) => {
            return worksheet.addRow({
                month: t(months.find(m => m.value === key.split('-')[1]).label) + ' ' + key.split('-')[0],
                trained_learners: value.nb_trained_learners,
                h_scenario: value.h_scenario,
                h_reflection: value.h_reflection,
                h_others: value.h_others,
            });
        });

        worksheet.addRow([]);

        // Subscription Headers
        worksheet.addRow([
            'TOTAUX',
            data.total_trained_learners,
            data.total_h_scenario,
            data.total_h_reflection,
            data.total_h_others,
        ]);

        if (type === 'learner') {
            worksheet.spliceColumns(2, 1);
        }

        // Save Excel File
        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'mobitrain-statistics.xlsx'));
        enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
    };

    return (
        <Button
            onClick={createExcelFile}
            variant="contained"
            style={{ marginRight: 5 }}
        >
            EXCEL
            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
        </Button>
    );
};

export default FormationsChartBarExport;
