import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, IconButton } from '@material-ui/core';
import { School as IconMobitrain, Search as IconSearch } from '@material-ui/icons';

// Services
import useMediaQueries from 'services/media';
import { mobitrainColor, getStatisticMonth } from 'services/helpers';
import { getGlobalStatistics } from 'services/requests';

// Components
import FormationsChartBarExport from './formations-export';
import FormationsChartBarPDFExport from './formations-pdf-export';
import ChartBar from 'components/items/chart-bar';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import DatePicker from 'components/items/date-picker';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

const styles = {
    border: {
        border: '1px solid lightgray',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        marginBottom: 25,
    },
    legend: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
};

// -------------------------------------- FORMATIONS CHART --------------------------------------- \\
const FormationsChart = ({ company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null,
        dataBar: null,
        start_date: moment().subtract(5, 'months'),
        end_date: moment(),
        total_trained_learners: 0,
        total_h_scenario: 0,
        total_h_reflection: 0,
        total_h_others: 0,
    });

    const onSearch = () => {
        if (state.start_date == null || state.end_date == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.start_date > state.end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else if (state.end_date > moment()) {
            enqueueSnackbar(t('date.selected.compared.date.today'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            getGlobalStatistics({
                module: 'mobitrain',
                start_date: moment(state.start_date).format('YYYY-MM-DD'),
                end_date: moment(state.end_date).format('YYYY-MM-DD'),
                company_id: company?.id || null,
            }).then(res => {
                if (res?.status === 200) {

                    let res_months = res.data.data.months;
                    const dataBar = Object.entries(res_months).map(([key, value]) => ({
                        'month': getStatisticMonth(t, res_months, key),
                        [t('trained.learners')]: value.nb_trained_learners,
                        [t('scenario')]: value.h_scenario,
                        [t('reflection')]: value.h_reflection,
                        [t('others')]: value.h_others,
                    }));
                    
                    setState({
                        ...state,
                        loading: false,
                        data: res.data.data,
                        dataBar,
                        total_trained_learners: res.data.data.total_trained_learners,
                        total_h_scenario: res.data.data.total_h_scenario,
                        total_h_reflection: res.data.data.total_h_reflection,
                        total_h_others: res.data.data.total_h_others,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <Grid style={styles.border}>
            {/* -------------------- TITLE -------------------- */}
            <Grid
                container justifyContent="center" alignItems="center"
                style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.cW }}
            >
                <IconMobitrain />
                <span style={{ ...style.ml5, ...style.ttU }}>
                    {t('formations')}
                </span>
            </Grid>
            {/* -------------------- SELECT PERIOD -------------------- */}
            <Grid container>
                <Grid
                    item xs={12} md={8}
                    style={style.p10}
                >
                    <b className="input-title">{t('period')} : </b>
                    <DatePicker
                        placeholder={t('start.date')}
                        value={state.start_date}
                        onChange={start_date => setState({ ...state, start_date })}
                        fullWidth={false}
                        outlined
                    />
                    <span> - </span>
                    <DatePicker
                        placeholder={t('end.date')}
                        value={state.end_date}
                        onChange={end_date => setState({ ...state, end_date })}
                        fullWidth={false}
                        outlined
                    />
                    <Tooltip
                        title={t('search')}
                        item={(
                            <IconButton style={{ padding: 8 }} onClick={onSearch}>
                                <IconSearch />
                            </IconButton>
                        )}
                    />
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={style.p10}
                >
                    <FormationsChartBarExport type="global" data={state.data} />
                    <FormationsChartBarPDFExport company={company} />
                </Grid>
            </Grid>
            {/* -------------------- CHART BAR -------------------- */}
            <ChartBar
                id="formations-chart-bar"
                data={state.dataBar}
                keys={[t('trained.learners'), t('scenario'), t('reflection'), t('others')]}
                indexBy="month"
            />
            {/* -------------------- TOTALS -------------------- */}
            <Grid container alignItems="center">
                <Grid item xs="auto" style={style.p10}>
                    <span>{t('totals')} :</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#e8c1a0' }} />
                    <span>{state.total_trained_learners} {t('trained.learners')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f47560' }} />
                    <span>{state.total_h_scenario} {t('scenario')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#f1e15b' }} />
                    <span>{state.total_h_reflection} {t('reflection')}</span>
                </Grid>
                <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                    <div style={{ ...styles.legend, backgroundColor: '#e8a838' }} />
                    <span>{state.total_h_others} {t('others')}</span>
                </Grid>
            </Grid>
            {/* -------------------- HIDDEN CHARTS FOR PDF EXPORT -------------------- */}
            <div style={{ position: 'absolute', top: 0, height: 0, width: 0, overflow: 'hidden' }}>
                <div id="formations-chart-pdf-export" style={{ width: 1400, height: 900 }}>
                    <ChartBar
                        id="formations-chart-bar"
                        data={state.dataBar}
                        keys={[t('trained.learners'), t('scenario'), t('reflection'), t('others')]}
                        indexBy="month"
                    />
                    {/* -------------------- TOTALS -------------------- */}
                    <Grid container alignItems="center">
                        <Grid item xs="auto" style={style.p10}>
                            <span>{t('totals')} :</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#e8c1a0' }} />
                            <span>{state.total_trained_learners} {t('trained.learners')}</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#f47560' }} />
                            <span>{state.total_h_scenario} {t('scenario')}</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#f1e15b' }} />
                            <span>{state.total_h_reflection} {t('reflection')}</span>
                        </Grid>
                        <Grid item xs="auto" style={{ ...style.p10, ...style.dF }}>
                            <div style={{ ...styles.legend, backgroundColor: '#e8a838' }} />
                            <span>{state.total_h_others} {t('others')}</span>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Grid>
    );
};

export default FormationsChart;
