import React from 'react';

// Import Nivo/Bar
import { ResponsiveBar } from '@nivo/bar';

// ---------------------------------------- CHART BAR ---------------------------------------- \\
const ChartBar = ({ data, keys, indexBy, id }) => {
    return (
        <div id={id} className="chart">
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy={indexBy}
                groupMode="grouped"
                margin={{ top: 50, right: 220, bottom: 50, left: 50 }}
                padding={0.3}
                colors={{ scheme: 'nivo' }}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

export default ChartBar;
