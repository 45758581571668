import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { deleteSoftFormation } from 'services/requests';
import { getFormattedDate, softskillsColor } from 'services/helpers';

// Style
import style from 'style';

// ---------------------------------- DELETE SOFT FORMATION ---------------------------------- \\
const DeleteSoftFormation = ({ onClose, refetch, soft_formation }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteSoftFormation(soft_formation.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    <span>{t('soft.formation')}</span>
                </p>
                <p style={{ textAlign: 'center', fontWeight: 'bold', color: softskillsColor }}>
                    {soft_formation.soft_sessions?.map((session, index) => (
                        <Fragment key={session.id}>
                            {index !== 0 && (
                                <br />
                            )}
                            <span>
                                {getFormattedDate(session?.session_date) + ' : ' + t('from') + ' ' + moment(session?.start, 'HH:mm:ss').format('HH:mm') + ' ' + t('to') + ' ' + moment(session?.end, 'HH:mm:ss').format('HH:mm')}
                            </span>
                        </Fragment>
                    ))}
                </p>
                <span>{t('soft.formation.delete.confirm')}</span>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button variant="contained" onClick={onClose}>
                    {t('no')}
                </Button>
                <Button variant="contained" onClick={() => setLoading(true)}>
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSoftFormation;
