import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';

// Material-UI
import ArrowRight from '@material-ui/icons/NavigateNext';

// Services
import useMediaQueries from 'services/media';
import { mobipassColor, mobitrainColor, mobitoolsColor } from 'services/helpers';

// Styles
const styles = {
    arrow: {
        color: 'white',
        width: 28,
        height: 28,
        marginBottom: 5
    },
};

// ---------------------------------------- BREADCRUMB ---------------------------------------- \\
const Breadcrumb = () => {
    const location = useLocation();
    const { mediaLG } = useMediaQueries();
    const [color, setColor] = useState(mobipassColor);

    useEffect(() => {
        if (location) {
           const path = location.pathname.split('/')[1];
           if (path === 'mobipass') {
               setColor(mobipassColor);
           }
           else if (path === 'mobitrain') {
               setColor(mobitrainColor);
           }
           else {
               setColor(mobitoolsColor);
           }
        }
    }, [location]);

    return (
        <>
            {mediaLG ? (
                <>
                    <div
                        className="breadcrumb"
                        style={{ backgroundColor: color, position: 'fixed', width: 'calc(100% - 255px)' }}
                    >
                        <Breadcrumbs
                            item="span"
                            separator={<ArrowRight style={styles.arrow} />}
                            containerProps={{ style: { margin: 'auto 0' } }}
                        />
                    </div>
                    <div style={{ height: 45 }} />
                </>
            ) : (
                <div
                    className="breadcrumb"
                    style={{ backgroundColor: color, position: 'static', width: '100%' }}
                >
                    <Breadcrumbs
                        item="span"
                        separator={<ArrowRight style={styles.arrow} />}
                        containerProps={{ style: { margin: 'auto 0', marginLeft: 48 } }}
                    />
                </div>
            )}
        </>
    );
}

export default Breadcrumb;
