import React from 'react';
import ReactSelect, { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';

// ---------------------------------------- CUSTOMIZED REACT SELECT ---------------------------------------- \\
const CustomizedReactSelect = ({ value, onChange, options, placeholder, isMulti, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <ReactSelect
            value={value}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            noOptionsMessage={() => t('none')}
            isClearable
            isMulti={isMulti}
            isDisabled={isDisabled}
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            filterOption={createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                matchFrom: 'any',
                stringify: option => `${option.label}`,
                trim: true,
            })}
            styles={{
                control: base => ({ ...base, fontSize: 14 }),
                menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14 }),
                container: base => ({ ...base, width: '100%' }),
                placeholder: base => ({ ...base, whiteSpace: 'nowrap' })
            }}
        />
    );
};

CustomizedReactSelect.defaultProps = {
    isMulti: false,
    isDisabled: false,
};

export default CustomizedReactSelect;
