import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, FormControlLabel, FormGroup, Checkbox, Typography, RadioGroup, Radio } from '@material-ui/core';

// Services
import { softskillsColor } from 'services/helpers';

// Style
import style from 'style';

// ------------------------------------- FILTERS ---------------------------------------- \\
const Filters = ({ state, setState }) => {
    const { t } = useTranslation();

    function checkedItems(value, type) {
        if (type === 'participants') {
            if (state.participants.includes(value)) {
                return true;
            }
            return false;
        }
        if (type === 'preparation') {
            if (state.preparation.includes(value)) {
                return true;
            }
            return false;
        }
        if (type === 'equipment') {
            if (state.equipment.includes(value)) {
                return true;
            }
            return false;
        }
        if (type === 'duration') {
            if (state.duration.includes(value)) {
                return true;
            }
            return false;
        }
        if (type === 'phase') {
            if (state.phase.includes(value)) {
                return true;
            }
            return false;
        }
        if (type === 'audience') {
            if (state.audience.includes(value)) {
                return true;
            }
            return false;
        }
    }

    function onChange(checked, type, item) {
        let items = [];

        if (type === 'participants') items = state.participants;
        else if (type === 'preparation') items = state.preparation;
        else if (type === 'equipment') items = state.equipment;
        else if (type === 'duration') items = state.duration;
        else if (type === 'audience') items = state.audience;
        else items = state.phase;

        if (checked) {
            items.push(item);
        }
        else {
            const index = items.findIndex(p => p === item);
            items.splice(index, 1);
        }

        if (type === 'participants') setState({ ...state, participants: items });
        else if (type === 'preparation') setState({ ...state, preparation: items });
        else if (type === 'equipment') setState({ ...state, equipment: items });
        else if (type === 'duration') setState({ ...state, duration: items });
        else if (type === 'audience') setState({ ...state, audience: items });
        else setState({ ...state, phase: items });
    }

    return (
        <>
            <span>
                {t('choose.filters')}
            </span>
            <Grid container>
                {/* -------------------------------- PARTICIPANTS ----------------------------- */}
                <Grid item xs={12} md={4} style={style.p10}>
                    <span style={{ color: softskillsColor, ...style.fwB }}>{t('participants')}</span>
                    <FormGroup>
                        {['small', 'medium', 'large'].map(item => (
                            <FormControlLabel
                                key={item}
                                label={<Typography style={style.fs14}>{t('group.' + item)}</Typography>}
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox 
                                        checked={checkedItems(item, 'participants')}
                                        onChange={e => onChange(e.target.checked, 'participants', item)}
                                        style={{ color: softskillsColor }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
                {/* -------------------------------- PREPARATION ----------------------------- */}
                <Grid item xs={12} md={4} style={style.p10}>
                    <span style={{ color: softskillsColor, ...style.fwB }}>{t('preparation')}</span>
                    <FormGroup>
                        {[1, 2, 3].map(item => (
                            <FormControlLabel
                                key={item}
                                label={<Typography style={style.fs14}>{t('preparation.' + item)}</Typography>}
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={checkedItems(item, 'preparation')}
                                        onChange={e => onChange(e.target.checked, 'preparation', item)}
                                        style={{ color: softskillsColor }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
                {/* -------------------------------- EQUIPEMENTS ----------------------------- */}
                <Grid item xs={12} md={4} style={style.p10}>
                    <span style={{ color: softskillsColor, ...style.fwB }}>{t('equipements')}</span>
                    <FormGroup>
                        {[1, 2, 3].map(item => (
                            <FormControlLabel
                                key={item}
                                label={<Typography style={style.fs14}>{t('equipment.' + item)}</Typography>}
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={checkedItems(item, 'equipment')}
                                        onChange={e => onChange(e.target.checked, 'equipment', item)}
                                        style={{ color: softskillsColor }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container>
                {/* -------------------------------- DURATION ----------------------------- */}
                <Grid item xs={12} md={4} style={style.p10}>
                    <span style={{ color: softskillsColor, ...style.fwB }}>{t('duration')}</span>
                    <FormGroup>
                        {[10, 20, 30, 40, 50].map(item => (
                            <FormControlLabel
                                key={item}
                                label={<Typography style={style.fs14}>{t('less.than.' + item)}</Typography>}
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={checkedItems(item, 'duration')}
                                        onChange={e => onChange(e.target.checked, 'duration', item)}
                                        style={{ color: softskillsColor }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
                {/* -------------------------------- PHASE ----------------------------- */}
                <Grid item xs={12} md={4} style={style.p10}>
                    <span style={{ color: softskillsColor, ...style.fwB }}>{t('phase')}</span>
                    <FormGroup>
                        {[1, 2, 3].map(item => (
                            <FormControlLabel
                                key={item}
                                label={<Typography style={style.fs14}>{t('phase.' + item)}</Typography>}
                                style={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        checked={checkedItems(item, 'phase')}
                                        onChange={e => onChange(e.target.checked, 'phase', item)}
                                        style={{ color: softskillsColor }}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
                {/* -------------------------------- LEVEL ----------------------------- */}
                <Grid item xs={12} md={4} style={style.p10}>
                    <span style={{ color: softskillsColor, ...style.fwB }}>{t('level')}</span>
                    <Levels state={state} setState={setState} />
                </Grid>
                <Grid container>
                    {/* -------------------------------- AUDIENCE ----------------------------- */}
                    <Grid item xs={12} md={4} style={style.p10}>
                        <span style={{ color: softskillsColor, ...style.fwB }}>{t('audience')}</span>
                        <FormGroup>
                            {[1, 2, 3].map(item => (
                                <FormControlLabel
                                    key={item}
                                    label={<Typography style={style.fs14}>{t('audience.' + item)}</Typography>}
                                    style={{ margin: 0 }}
                                    control={
                                        <Checkbox
                                            checked={checkedItems(item, 'audience')}
                                            onChange={e => onChange(e.target.checked, 'audience', item)}
                                            style={{ color: softskillsColor }}
                                        />
                                    }
                                />
                            ))}
                        </FormGroup>
                    </Grid>
                    {/* -------------------------------- USABLE ONLINE ----------------------------- */}
                    <Grid item xs={12} md={4} style={style.p10}>
                        <span style={{ color: softskillsColor, ...style.fwB }}>{t('usable.online')}</span>
                        <RadioGroup
                            value={state.usable_online}
                            onChange={e => setState({ ...state, usable_online: e.target.value })}
                        >
                            <FormControlLabel
                                value="1"
                                label={t('yes')}
                                control={<Radio color="primary" />}
                                style={{ margin: 0 }}
                            />
                            <FormControlLabel
                                value="0"
                                label={t('no')}
                                control={<Radio />}
                                style={{ margin: 0 }}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

// ------------------------------------- LEVELS ----------------------------------------- \\
const Levels = ({ state, setState }) => {
    const { t } = useTranslation();

    function onChangeLevel(level, checked) {
        let levels = state.levels;
        let checkLevels = state.checkLevels;
        checkLevels[level] = checked;

        if (!checked) {
            levels = levels.filter(item => item.level !== level);
        }

        setState({ ...state, checkLevels, levels });
    }

    function stepIsChecked(level, step) {
        if (state.levels.findIndex(item => item.level === level && item.step === step) !== -1) {
            return true;
        }
        return false;
    }

    function onChangeStep(level, step, checked) {
        let levels = state.levels;
        if (checked) {
            levels.push({ level: level, step: step });
        }
        else {
            const index = state.levels.findIndex(item => item.level === level && item.step === step);
            levels.splice(index, 1);
        }
        setState({ ...state, levels });
    }

    return (
        <Grid container>
            {[1, 2, 3, 4].map((level, index) => (
                <Grid key={index} container alignItems="center" style={{ border: '1px solid lightgray', padding: 10 }}>
                    <Checkbox
                        checked={state.checkLevels[level]}
                        onChange={e => onChangeLevel(level, e.target.checked)}
                        style={{color: softskillsColor}}
                    />
                    <span>{t('level') + ' ' + level}</span>
                    {state.checkLevels[level] && (
                        <Grid container alignItems="center" justifyContent="center">
                            <span style={style.mr5}>{t('steps')}:</span>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map(step => (
                                <FormControlLabel
                                    key={step}
                                    label={<Typography style={style.fs14}>{step}</Typography>}
                                    style={{ margin: 0 }}
                                    control={
                                        <Checkbox
                                            checked={stepIsChecked(level, step)}
                                            onChange={e => onChangeStep(level, step, e.target.checked)}
                                            style={{color: softskillsColor}}
                                        />
                                    }
                                />
                            ))}
                        </Grid>
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default Filters;
