import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';

// File Saver
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { deleteNews, getNewsById } from 'services/requests';
import { getFormattedDate, servicesColor, fileUrl } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ------------------------------------ DELETE NEWS ---------------------------------------- \\
export const DeleteNews = ({ onClose, refetch, news }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteNews(news.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.pb20, ...style.taC }}>
                        <p className="dialog-title">
                            {news.title} ({getFormattedDate(news.publication_date)})
                        </p>
                        <span>
                            {t('news.delete.confirm')}
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ----------------------------------- DETAILS NEWS ---------------------------------------- \\
export const DetailsNews = ({ onClose, news }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        error: false,
        news: null,
    });

    useEffect(() => {
        if (state.loading) {
            getNewsById(news.id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, news: res.data.data.news });
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('details')}
                        </p>
                        <Divider />
                        {/* ---------------------- PUBLICATION DATE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: servicesColor }}>
                                    {t('publication.date')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.news.publication_date ? getFormattedDate(state.news.publication_date) : ''}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- STATUS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: servicesColor }}>
                                    {t('status')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                {state.news && (
                                    state.news.status === 1 ? (
                                        <span>{t('published')}</span>
                                    ) : (
                                        <span>{t('rough')}</span>
                                    )
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- TITLE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: servicesColor }}>
                                    {t('news.title')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.news ? state.news.title : ''}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- PHOTO ---------------------- */}
                        {(state.news && state.news.photo) && (
                            <>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4} style={style.p10}>
                                        <span style={{ color: servicesColor }}>
                                            {t('news.photo')}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} sm={8} style={style.p10}>
                                        <Button
                                            variant="contained"
                                            href={`${fileUrl + state.news.document}`}
                                            target="blank"
                                            style={{ textTransform: 'none' }}
                                        >
                                            {t('view.news.photo')}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </>
                        )}
                        {/* ---------------------- DOCUMENT ---------------------- */}
                        {(state.news && state.news.document) && (
                            <>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={4} style={style.p10}>
                                        <span style={{ color: servicesColor }}>
                                            {t('document')}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} sm={8} style={style.p10}>
                                        <Button
                                            onClick={() => saveAs(fileUrl + state.news.document)}
                                            variant="contained"
                                            style={{ textTransform: 'none' }}
                                        >
                                            {t('download')}
                                            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </>
                        )}
                        {/* ---------------------- DESCRIPTION ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: servicesColor }}>
                                    {t('news.description')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{state.news ? state.news.description : ''}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- DETAILS ---------------------- */}
                        {(state.news && state.news.details) && (
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4} style={style.p10}>
                                    <span style={{ color: servicesColor }}>
                                        {t('news.details')}
                                    </span>
                                </Grid>
                                <Grid item xs={12} sm={8} style={style.p10}>
                                    <div dangerouslySetInnerHTML={{__html: convertToHTML(convertFromRaw(JSON.parse(state.news.details)))}} />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSA}>
                        <Button onClick={onClose} variant="contained">
                            {t('close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
