import React from 'react';

// Material-UI
import Grid from '@material-ui/core/Grid';

// Services
import useMediaQueries from 'services/media';

// Components
import InputFile from './input-file';
import FieldRequired from './field-required';

// Style
import style from 'style';

// ---------------------------------------- INPUT FILE GRID ---------------------------------------- \\
const InputFileGrid = ({ placeholder, inputId, required }) => {
    const { mediaSM } = useMediaQueries();

    return (
        <Grid container alignItems="center" style={style.m5_0}>
            <Grid
                item xs={12} sm={4}
                style={{ paddingRight: mediaSM ? 5 : 0 }}
            >
                <b className="input-title">{placeholder}</b>{required && <FieldRequired />}
            </Grid>
            <Grid
                item xs={12} sm={8}
                style={{ paddingLeft: mediaSM ? 5 : 0 }}
            >
                <InputFile inputId={inputId} />
            </Grid>
        </Grid>
    );
};

export default InputFileGrid;
