import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Grid } from '@material-ui/core';

// Helpers
import { theme } from 'services/helpers';

// Components
import BlockedCompanies from './blocked-companies';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import FooterMobiRhin from 'components/common/footer-mobirhin';

// Style
import style from 'style';

// ---------------------------------------- LEARNER ACCESS REQUESTS ---------------------------------------- \\
const LearnerAccessRequests = () => {
    const { t } = useTranslation();

    return (
        <>
            <Header />
            <div className="main">
                {/* -------------------- TITLE -------------------- */}
                <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                    <b>{t('access.requests')}</b>
                </Grid>
                <Divider style={style.mb15} />
                <div className="simple-container">
                    <span>{t('company.join.enter')} :</span>
                </div>
                <BlockedCompanies />
            </div>
            {theme === 'mobirhin' ? (
                <FooterMobiRhin />
            ) : (
                <Footer />
            )}
        </>
    );
};

export default LearnerAccessRequests;
