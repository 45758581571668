import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getCleaProofDocument } from 'services/requests';
import { cleaColor } from 'services/helpers';

// Components
import GenerateCleaProofDocument from './generate-clea-proof-document';

// --------------------------------- CLEA PROOF DOCUMENT --------------------------------- \\
const CleaProofDocument = ({ user }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            getCleaProofDocument(user.id).then(res => {
                if (res?.status === 200) {
                    let clea_competences = res.data.data.clea_competences || [];
                    if (clea_competences.length > 0) {
                        GenerateCleaProofDocument(t, user, clea_competences, setLoading);
                    }
                    else {
                        enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
                        setLoading(false);
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <>
            <Grid container alignItems="center" style={{ padding: 24 }}>
                <b className="input-title" style={{ fontSize: 14 }}>
                    {t('clea.proof.file')} : 
                </b>
                <Button
                    onClick={() => setLoading(true)}
                    disabled={loading}
                    variant="contained"
                    style={{ backgroundColor: (loading ? 'lightgray' : cleaColor), color: 'white', marginLeft: 5 }}
                >
                    {loading ? (
                        <>
                            {t('downloading')}
                            <CircularProgress size={16} style={{ marginLeft: 5, color: 'white' }} />
                        </>
                    ) : (
                        <>
                            {t('download')}
                            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                        </>
                    )}
                </Button>
            </Grid>
        </>
    );
};

export default CleaProofDocument;
