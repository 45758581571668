import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid, IconButton, MenuItem } from '@material-ui/core';
import { Delete as IconDelete, Create as IconEdit, Search as IconSearch, Cancel as IconCancel } from '@material-ui/icons';

// Services
import { getSubscriptions, getCompanies, getBill } from 'services/requests';
import { servicesColor, getFormattedDate } from 'services/helpers';
import { subscriptionTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import CreateSubscription from './subscription-create';
import UpdateSubscription from './subscription-update';
import DeleteSubscription from './subscription-delete';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import SelectOutlined from 'components/items/select-outlined';
import DatePicker from 'components/items/date-picker';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// ---------------------------------------- SUBSCRIPTIONS -------------------------------------- \\
const Subscriptions = forwardRef((props, ref) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { billId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 50,
        total_count: 0,
        subscription_type_id: -1,
        company_id: null,
        start_date: null,
        end_date: null,
        openCreateSubscription: false,
    });

    const onSearch = () => {
        if ((state.start_date && state.end_date) && (state.start_date > state.end_date)) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true, offset: 0 });
        }
    };

    const onSelectSearch = (label, value) => {
        if ((state.start_date && state.end_date) && (state.start_date > state.end_date)) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true, offset: 0, [label]: value });
        }
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            offset: 0,
            subscription_type_id: -1,
            company_id: null,
            start_date: null,
            end_date: null,
        });
    };

    useImperativeHandle(ref, () => ({
        refetch() {
            setState({ ...state, loading: true });
        }
    }));

    useEffect(() => {
        if (state.loading) {
            getSubscriptions(
                state.offset,
                state.limit,
                state.start_date ? moment(state.start_date).format('YYYY-MM-DD') : null,
                state.end_date ? moment(state.end_date).format('YYYY-MM-DD') : null,
                state.subscription_type_id,
                state.company_id,
                billId,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        data: res.data.data.subscriptions,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                    }));
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('subscriptions.linked.bill')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.goBack()} variant="contained">
                    {t('previous')}
                </Button>                    
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
                <Button
                    onClick={() => setState({ ...state, openCreateSubscription: true })}
                    variant="contained"
                >
                    {t('add')}
                </Button>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('type')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('start.date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('end.date')}</span>
                            </Grid>
                            <Grid item xs={1} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('price')}</span>
                            </Grid>
                            <Grid item xs={1} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('items')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('bills')}</span>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.subscription_type_id}
                                onChange={e => onSelectSearch('subscription_type_id',  e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                {subscriptionTypes.map(type => (
                                    <MenuItem key={type.id} value={type.id}>
                                        <span>{t(type.label)}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('start.date')}
                                value={state.start_date}
                                onChange={start_date => setState({ ...state, start_date })}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('end.date')}
                                value={state.end_date}
                                onChange={end_date => setState({ ...state, end_date })}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10} />
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* ---------------------- SUBSCRIPTION LIST ---------------------- */}
                <SubscriptionList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* ---------------------- CREATE SUBSCRIPTION ---------------------- */} 
            {state.openCreateSubscription && (
                <CreateSubscription
                    onClose={() => setState({ ...state, openCreateSubscription: false })}
                    refetch={() => setState({ ...state, openCreateSubscription: false, loading: true })}
                />
            )}
        </>
    );
});

// --------------------------------------- SUBSCRIPTION LIST ----------------------------------- \\
const SubscriptionList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        subscription: null,
        openUpdateSubscription: false,
        openDeleteSubscription: false,
    });

    const handleRefetch = open => {
        setState({ ...state, [open]: false });
        refetch();
    };

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="half-container-content">
                {data.length > 0 ? (
                    data.map(subscription => (
                        <Fragment key={subscription.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{subscription.subscription_type.label}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{subscription.company.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(subscription.start_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(subscription.end_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={1} style={{ ...style.p10, ...style.taC }}>
                                    <span>{subscription.price}</span>
                                </Grid>
                                <Grid item xs={12} md={1} style={{ ...style.p10, ...style.taC }}>
                                    <span>{subscription.nb_items}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openUpdateSubscription: true, subscription })}
                                                className="list-btn"
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDeleteSubscription: true, subscription })}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- UPDATE SUBSCRIPTION -------------------- */}
            {state.openUpdateSubscription && (
                <UpdateSubscription
                    onClose={() => setState({ ...state, openUpdateSubscription: false })}
                    refetch={() => handleRefetch('openUpdateSubscription')}
                    subscription={state.subscription}
                />
            )}
            {/* -------------------- DELETE SUBSCRIPTION -------------------- */}
            {state.openDeleteSubscription && (
                <DeleteSubscription
                    onClose={() => setState({ ...state, openDeleteSubscription: false })}
                    refetch={() => handleRefetch('openDeleteSubscription')}
                    subscription={state.subscription}
                />
            )}
        </>
    );
};

// ---------------------------------------- SELECT COMPANY ------------------------------------- \\
const SelectCompany = ({ companyId, setCompanyId }) => {
    const { t } = useTranslation();
    const { billId } = useParams();

    const [state, setState] = useState({
        companies: [],
        client_id: null,
    });

    useEffect(() => {
        getBill(billId).then(res => {
            if (res?.status === 200) {
                setState({ ...state, client_id: res.data.data.bill?.contract?.client?.id });
            }
            else {
                console.log(res);
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.client_id) {
            getCompanies(0, 50, null, null, null, null, null, state.client_id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, companies: res.data.data.companies.map(c => ({ value: c.id, label: c.name })) });
                }
                else {
                    console.log(res);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.client_id]);

    return (
        <Select
            placeholder={t('company')}
            value={companyId ? state.companies.find(c => c.value === companyId) : null}
            onChange={company => setCompanyId(company ? company.value : null)}
            options={state.companies}
        />
    );
};

export default Subscriptions;
