import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Switch, Route, useHistory, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import { servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import LearnerChart from './learner-chart';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ------------------------------------- LEARNERS ROUTER --------------------------------------- \\
const LearnersRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <Learners />
            </Route>
            <Route path={`${match.path}/:learnerId`}>
                <LearnerChart />
            </Route>
        </Switch>
    );
};

// ----------------------------------------- LEARNERS ------------------------------------------- \\
const Learners = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { companyId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: null,
        learners: [],
    });

    useEffect(() => {
        if (state.loading) {
            getCompanyMembers(companyId).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        learners: res.data.data.learners,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: res }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* ---------------------- TOOLBAR ---------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/management/statistics')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
            </Grid> 
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    <Grid item xs={6} style={style.p10}>
                        <span style={style.cW}>{t('last.name')}</span>
                    </Grid>
                    <Grid item xs={6} style={style.p10}>
                        <span style={style.cW}>{t('first.name')}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* -------------------- LEARNERS LIST -------------------- */}
                <LearnersList
                    loading={state.loading}
                    error={state.error}
                    learners={state.learners}
                    companyId={companyId}
                />
            </div>
        </>
    );
};

// ---------------------------------------- LEARNERS LIST --------------------------------------- \\
const LearnersList = ({ loading, error, learners, companyId }) => {
    const { t } = useTranslation();
    const history = useHistory();

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="full-container-content">
            {learners.length > 0 ? (
                learners.map(learner => (
                    <Fragment key={learner.id}>
                        <Grid
                            onClick={() => history.push(`/management/statistics/${companyId}/learners/${learner.id}`)}
                            container alignItems="center"
                            className="list-item-hover"
                        >
                            <Grid item xs={6} style={style.p10}>
                                <span>{learner.last_name}</span>
                            </Grid>
                            <Grid item xs={6} style={style.p10}>
                                <span>{learner.first_name}</span>
                            </Grid>
                        </Grid>
                        <Divider />     
                    </Fragment>
                ))
            ) : (
                <>     
                    <Grid container alignItems="center" style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default LearnersRouter;
