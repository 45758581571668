import React from 'react';
import { Grid } from '@material-ui/core';

// React Carousel
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

// Services
import useMediaQueries from 'services/media';

// Logo
import Birk from 'assets/images/birk.jpg';
import Asw from 'assets/images/asw-small.png';
import Ihk from 'assets/images/ihk.jpg';
import Keeseek from 'assets/images/keeseek.png';
import Koa from 'assets/images/koa.jpg';
import Capemploi from 'assets/images/capemploi.jpg';
import Rde from 'assets/images/rde.jpg';
import Aaoffenburg from 'assets/images/aaoffenburg.jpg';
import Eurodistrict from 'assets/images/eurodistrict-small.png';
import Agbo from 'assets/images/agbo.jpg';
import Algeco from 'assets/images/algeco.jpg';
import Alsace from 'assets/images/alsace.jpg';
import CafaRso from 'assets/images/cafa-rso.jpg';
import Solivers from 'assets/images/solivers.png';

const styles= {
    logo: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        maxWidth: 300,
        margin: '0 10px 0 10px',
        alignSelf: 'center',
        padding: '40vw 10px'
    },
};

// ---------------------------------------- CAROUSEL MOBIRHIN ---------------------------------------- \\
const CarouselMobirhin = () => {
    const { mediaMD } = useMediaQueries();

    return (
        <Grid container>
            {mediaMD ? (
                <CarouselProvider
                    orientation="horizontal"
                    naturalSlideWidth="100vw"
                    naturalSlideHeight={350}
                    interval={5000}
                    totalSlides={5}
                    isPlaying={true}
                    infinite
                >
                    <Slider style={{ height: 200 }}>
                        <Slide index={0} style={{ height: 300 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Birk + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Asw + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Alsace + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={1} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Algeco+ ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Capemploi + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Aaoffenburg + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={2} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Solivers + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Rde + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Agbo + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={3} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Koa + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Ihk + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Keeseek + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={4} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Eurodistrict + ')' }} />
                                <div style={{...styles.logo}} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + CafaRso + ')' }} />
                            </div>
                        </Slide>
                    </Slider>
                </CarouselProvider>
            ) : (
                <CarouselProvider
                    orientation="horizontal"
                    naturalSlideWidth="100vw"
                    naturalSlideHeight={350}
                    interval={5000}
                    totalSlides={7}
                    isPlaying={true}
                    infinite
                >
                    <Slider style={{height: 200 }}>
                        <Slide index={0} style={{ height: 300 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Birk + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Asw + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={1} style={{ height: 300 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Alsace + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Aaoffenburg + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={2} style={{ height: 300 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Agbo + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Keeseek + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={3} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Algeco+ ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Capemploi + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={4} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Solivers + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Rde + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={5} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Koa + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + Ihk + ')' }} />
                            </div>
                        </Slide>
                        <Slide index={6} style={{ height: 200 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 200 }}>
                                <div style={{...styles.logo, backgroundImage: 'url(' + Eurodistrict + ')' }} />
                                <div style={{...styles.logo, backgroundImage: 'url(' + CafaRso + ')' }} />
                            </div>
                        </Slide>
                    </Slider>
                </CarouselProvider>
            )} 
        </Grid>
    );
};

export default CarouselMobirhin;
