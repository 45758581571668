import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, IconButton, Divider } from '@material-ui/core';
import { Search as IconSearch, Cancel as IconCancel } from '@material-ui/icons';

// Services
import { getLearners } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { mobipassColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import UserPortfolio from './user-portfolio';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import SelectCompany from 'components/select/select-company';
import InputFilter from 'components/items/input-filter';

// Style
import style from 'style';

// ---------------------------------------- ROUTER ---------------------------------------- \\
const PortfoliosRouter = () => {
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const ROLE_ID = storedUser?.role.id;
    const USER_ID = storedUser?.id;

    return (
        <>
            <BreadcrumbsItem to="/mobipass/portfolio" className="breadcrumb-item">
                {t('portfolio')}
            </BreadcrumbsItem>
            <Switch>
                {ROLE_ID < 5 && (
                    <Route exact path="/mobipass/portfolio">
                        <Portfolios />
                    </Route>
                )}
                <Route path="/mobipass/portfolio/:userId">
                    <UserPortfolio />
                </Route>
                {ROLE_ID < 5 ? (
                    <Redirect from="*" to="/mobipass/portfolio" />
                ) : (
                    <Redirect from="*" to={`/mobipass/portfolio/${USER_ID}`} />
                )}
            </Switch>
        </>
    );
};

// -------------------------------------- PORTFOLIOS -------------------------------------- \\
const Portfolios = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('portfolios-filters'));

    const [state, setState] = useState({
        loading: true,
        error: false,
        learners: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        last_name: storedFilters?.last_name || '',
        first_name: storedFilters?.first_name || '',
        company_id: storedFilters?.company_id || null,
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0 });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('portfolios-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            last_name: '',
            first_name: '',
            company_id: null,
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('portfolios-filters', JSON.stringify({
                last_name: state.last_name,
                first_name: state.first_name,
                company_id: state.company_id,
            }));

            getLearners(
                state.offset,
                state.limit,
                state.last_name,
                state.first_name,
                state.company_id,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        learners: res.data.data.users,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="center" className="toolbar">
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: mobipassColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('last.name')}</span>
                            </Grid>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('first.name')}</span>
                            </Grid>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('portfolio')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('last.name')}
                                value={state.last_name}
                                onChange={e => setState({ ...state, last_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('first.name')}
                                value={state.first_name}
                                onChange={e => setState({ ...state, first_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={4} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- LEARNER LIST -------------------- */}
                <LearnerList
                    learners={state.learners}
                    loading={state.loading}
                    error={state.error}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// ------------------------------------- LEARNER LIST ------------------------------------- \\
const LearnerList = ({ learners, loading, error }) => {
    const history = useHistory();
    const { t } = useTranslation();

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="full-container-content">
            {learners.length > 0 ? (
                learners.map(learner => (
                    <Fragment key={learner.id}>
                        <Grid
                            container
                            onClick={() => history.push(`/mobipass/portfolio/${learner.id}`)}
                            className="list-item-hover"
                        >
                            <Grid item xs={12} md={3} style={style.p10}>
                                <span>{learner.last_name}</span>
                            </Grid>
                            <Grid item xs={12} md={3} style={style.p10}>
                                <span>{learner.first_name}</span>
                            </Grid>
                            <Grid item xs={12} md={4} style={style.p10}>
                                <span>{learner.company?.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default PortfoliosRouter;
