import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Accordion, AccordionSummary, Grid, IconButton, Button, Divider } from '@material-ui/core';
import { ExpandMore as IconExpand, Assignment as IconDetails } from '@material-ui/icons';

// Services
import { getTranslation, mobipassColor, softskillsColor } from 'services/helpers';
import { getSkillbase } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import SkillbaseExport from './skillbase-export';
import LearningOutcomeModal from './learning-outcome-modal';
import SoftskillModal from './softskill-modal';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ----------------------------------------- SKILLBASE -------------------------------------------- \\
const Skillbase = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        skillbase: null,
    });

    useEffect(() => {
        if (state.loading) {
            getSkillbase(match.params.skillbaseId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, skillbase: res.data.data.skillbase });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push('/mobipass/skillbases')}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    {state.skillbase && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {getTranslation('name', state.skillbase)}
                        </span>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <SkillbaseExport
                        skillbase={state.skillbase}
                    />
                </Grid>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- LIST HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: mobipassColor, ...style.cW, padding: '10px 24px' }}>
                    <span>{t('units.of.learning.outcomes')}</span>
                </Grid>
                {/* -------------------- UNIT LIST -------------------- */}
                <UnitList
                    skillbase={state.skillbase}
                    loading={state.loading}
                    error={state.error}
                />
            </div>
        </>
    );
};

// ------------------------------------------ UNIT LIST ------------------------------------------- \\
const UnitList = ({ skillbase, loading, error }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        learning: null,
        soft_unit: null,
        softskill: null,
        openLearningModal: false,
        openSoftskillModal: false,
    });

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div>
                {skillbase.units.length > 0 ? (
                    skillbase.units.map((unit, index) => (
                        <Accordion key={unit.id}>
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <span style={style.fwB}>
                                    {unit.position}. {getTranslation('name', unit)}
                                </span>
                            </AccordionSummary>
                            <Divider />
                            <div>
                                {unit.learning_outcomes.length > 0 ? (
                                    unit.learning_outcomes.map(learning => (
                                        <Fragment key={learning.id}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} md={10} style={style.p10}>
                                                    <span>{unit.position}.{learning.position}  {getTranslation('name', learning)}</span>
                                                </Grid>
                                                <Grid item xs={12} md={2} container justifyContent="center">
                                                    <Tooltip title={t('details')}
                                                        item={(
                                                            <IconButton
                                                                onClick={() => setState({ ...state, openLearningModal: true, learning })}
                                                                className="list-btn"
                                                            >
                                                                <IconDetails />
                                                            </IconButton>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Grid style={style.p10}>
                                        <span>{t('none')}</span>
                                    </Grid>
                                )}
                                {unit.soft_units.length > 0 && (
                                    <Grid container style={{ backgroundColor: softskillsColor, ...style.cW, padding: '10px 24px' }}>
                                        <span>{t('softskills')}</span>
                                    </Grid>
                                )}
                                {unit.soft_units.length > 0 ? (
                                    unit.soft_units.map(soft_unit => (
                                        <Fragment key={soft_unit.id}>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item xs={12} md={soft_unit.soft_level.level !== null ? 5 : 12} style={style.p10}>
                                                    <span>{getTranslation('name', soft_unit.softskill)}</span>
                                                </Grid>
                                                {soft_unit.soft_level.level !== null && (
                                                    <Grid item container xs={12} md={5} justifyContent="center" style={style.p10}>
                                                        <span>{t('level')} {soft_unit?.soft_level?.level}</span>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} md={2} container justifyContent="center">
                                                    <Tooltip title={t('details')}
                                                        item={(
                                                            <IconButton
                                                                onClick={() => setState({ ...state, openSoftskillModal: true, softskill: soft_unit.softskill, soft_unit })}
                                                                className="list-btn"
                                                            >
                                                                <IconDetails />
                                                            </IconButton>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Fragment>
                                    ))
                                ) : (
                                    null
                                )}
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- LEARNING MODAL -------------------- */}
            {state.openLearningModal && (
                <LearningOutcomeModal
                    onClose={() => setState({ ...state, openLearningModal: false })}
                    learning={state.learning}
                />
            )}
            {state.openSoftskillModal && (
                <SoftskillModal
                    onClose={() => setState({ ...state, openSoftskillModal: false })}
                    soft_unit={state.soft_unit}
                    softskill={state.softskill}
                />
            )}
        </>
    );
};

export default Skillbase;
