import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Services
import { getStoredUser, getSoftskillsAccess, getCleaAccess } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import Formation from './formation';
import SoftFormation from './soft-formation';
import FormationModels from './formation-models';
import Mobility from './mobility';
import CleaFormation from './clea-formation';
import Signature from './signature';
import SideMenu from './side-menu';
import Settings from 'components/common/settings';
import Header from 'components/common/header';
import Breadcrumb from 'components/common/breadcrumb';
import SideDrawer from 'components/common/side-drawer';

// ---------------------------------------- MOBITRAIN ---------------------------------------- \\
const Mobitrain = () => {
    const { mediaLG } = useMediaQueries();
    const ROLE_ID = getStoredUser()?.role?.id;

    return (
        <>
            <BreadcrumbsItem to="/mobitrain" className="breadcrumb-item">
                MOBITRAIN
            </BreadcrumbsItem>
            <Header />
            <div className="main">
                {mediaLG ? (
                    <SideMenu position="fixed" />
                ) : (
                    <SideDrawer menu={<SideMenu position="initial" />} />
                )}
                <div className="container">
                    <Breadcrumb />
                    <Switch>
                        <Route path="/mobitrain/formation">
                            <Formation />
                        </Route>
                        {ROLE_ID < 5 && (
                            <Route path="/mobitrain/formation-models">
                                <FormationModels />
                            </Route>
                        )}
                        <Route exact path="/mobitrain/signature">
                            <Signature />
                        </Route>
                        <Route path="/mobitrain/mobility">
                            <Mobility />
                        </Route>
                        {getSoftskillsAccess() === '1' && (
                            <Route path="/mobitrain/soft-formation">
                                <SoftFormation />
                            </Route>
                        )}
                        {getCleaAccess() === '1' && (
                            <Route path="/mobitrain/clea-formation">
                                <CleaFormation />
                            </Route>
                        )}
                        <Route exact path="/mobitrain/settings">
                            <Settings />
                        </Route>
                        <Redirect from="*" to="/mobitrain/formation" />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Mobitrain;
