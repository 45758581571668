import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Serives
import useMediaQueries from 'services/media';
import { createHardPosition, getHardPosition, getUser } from 'services/requests';
import { getStoredUser } from 'services/storage';

// Components
import HardPositionsForm from './hardpositions-form';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import DatePicker from 'components/items/date-picker';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// --------------------------------- DUPLICATE HARD POSITION ---------------------------------------- \\
const DuplicateHardPosition = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { userId, hardPositionId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        actionType: 'DUPLICATE',
        loadingResources: true,
        errorResources: false,
        loading: false,
        learner: null,
        skillbases: [],
        unit: null,
        loadingSkillbase: false,

        // Hard Position
        hard_position_id: null,
        position_date: null,
        skillbase_id: null,
        learner_id: userId,
        competence_ids: [],
        knowledge_ids: [],
        skill_ids: [],

        // Last Position
        last_hard_position: null,
        last_competence_ids: [],
        last_knowledge_ids: [],
        last_skill_ids: [],
    });

    const onDuplicateHardPosition = () => {
        if (state.position_date == null) {
            enqueueSnackbar(t('selet.date.warning'), { variant: 'warning' });
        }
        else if (state.competence_ids.length === 0 && state.knowledge_ids.length === 0 && state.skill_ids.length === 0) {
            enqueueSnackbar(t('descriptor.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getUser(userId), getHardPosition(hardPositionId)])
            .then(axios.spread(function (res_user, res_hard_position) {
                if (res_user?.status === 200 && res_hard_position?.status === 200) {

                    let hard_position = res_hard_position.data.data.hard_position;

                    setState({
                        ...state,
                        loadingResources: false,
                        learner: res_user.data.data.user,
                        hard_position_id: hard_position.id,
                        position_date: hard_position.position_date,
                        skillbase_id: hard_position.skillbase?.id,
                        competence_ids: hard_position.competences.map(c => ({ unit_id: c.unit_id, learning_id: c.learning_outcome_id, id: c.id, mastery: c.mastery })),
                        knowledge_ids: hard_position.knowledges.map(k => ({ unit_id: k.unit_id, learning_id: k.learning_outcome_id, id: k.id, mastery: k.mastery })),
                        skill_ids: hard_position.skills.map(s => ({ unit_id: s.unit_id, learning_id: s.learning_outcome_id, id: s.id, mastery: s.mastery })),
                    });
                }
                else {
                    console.log(res_user + res_hard_position);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {
            createHardPosition({
                position_date: moment(state.position_date).format('YYYY-MM-DD'),
                skillbase_id: state.skillbase_id,
                learner_id: state.learner_id,
                competence_ids: state.competence_ids,
                knowledge_ids: state.knowledge_ids,
                skill_ids: state.skill_ids,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/positioning/${userId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/positioning/${userId}`)}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {ROLE_ID < 5 && (
                            <>
                                {state.learner?.full_name}
                                <br />
                            </>
                        )}
                        {t('duplicate.positioning')}
                    </span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container alignItems='center' justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}  
                >
                    <b className="input-title" style={{ paddingRight: 10 }}>
                        {t('date')}<FieldRequired />
                    </b>
                    <DatePicker
                        placeholder={t('date')}
                        value={state.position_date}
                        onChange={value => setState({ ...state, position_date: value })}
                        outlined
                        fullWidth={false}
                    />
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- HARD POSITIONS FORM -------------------- */}
            <div className="full-container" style={{ paddingBottom: 24, paddingTop: 24 }}>
                {(state.loadingResources || state.loading) ? (
                    <Spinner />
                ) : state.errorResources ? (
                    <Error />
                ) : (
                    <HardPositionsForm
                        state={state}
                        setState={setState}
                    />
                )}
            </div>
            <Divider />
            {/* -------------------- SAVE -------------------- */}
            <Grid container alignItems="center" justifyContent="center" style={{ padding: '15px 25px' }}>
                <Button onClick={onDuplicateHardPosition} variant="contained" color="primary">
                    {t('save')}
                </Button>
            </Grid>
        </>
    );
};

export default DuplicateHardPosition;
