import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar} from 'notistack';

// Material-UI
import { Grid, Divider, Fab, IconButton, CircularProgress } from '@material-ui/core';
import { Add as IconAdd, RemoveCircle as IconRemove } from '@material-ui/icons';

// Services
import { cleaColor, getTranslation } from 'services/helpers';
import { addLinkToLearningOutcome } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import AddLinkToLearning from './add-link-to-learning';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ---------------------------------------- CLEA COMPETENCES ---------------------------------------- \\
const CleaCompetences = ({ learning }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        clea_competences: learning?.clea_competences || [],
        clea_competence_id: null,
        openAdd: false,
    });

    useEffect(() => {
        if (state.loading) {

            let clea_competences = state.clea_competences;
            let index = clea_competences.findIndex(c => c.id === state.clea_competence_id);
            clea_competences.splice(index, 1);
            
            addLinkToLearningOutcome(learning.id, {
                clea_competence_ids: clea_competences.map(c => c.id),
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loading: false, clea_competences });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* ---------------------- HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: cleaColor, marginTop: 24 }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={8} style={style.p10}>
                            <span style={{ color: 'white' }}>{t('clea.competences')}</span>
                        </Grid>
                        <Grid item xs={4} style={{ ...style.p10, ...style.taC }}>
                            <span style={{ color: 'white' }}>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('clea.competences')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- CLEA COMPETENCE LIST ---------------------- */}
            {state.clea_competences.length > 0 ? (
                state.clea_competences.map(competence => (
                    <Fragment key={competence.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={8} style={{ padding: 10 }}>
                                <span>{getTranslation('name', competence)}</span>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ ...style.p10, ...style.taC }}>
                                <Tooltip title={t('remove.link')}
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, loading: true, clea_competence_id: competence.id })}
                                            disabled={(state.loading && state.clea_competence_id === competence.id)}
                                            style={{ padding: 6 }}
                                        >
                                            {(state.loading && state.clea_competence_id === competence.id) ? (
                                                <CircularProgress color="inherit" size={24} />
                                            ) : (
                                                <IconRemove />
                                            )}
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            {/* ---------------------- ADD LINK BUTTON ---------------------- */}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openAdd: true })}
                    className="fab"
                    style={{ backgroundColor: cleaColor, color: 'white' }}
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('clea.competence.link')}
                </span>
            </div>
            {/* ---------------------- ADD LINK TO LEARNING ---------------------- */}
            {state.openAdd && (
                <AddLinkToLearning
                    onClose={() => setState({ ...state, openAdd: false })}
                    refetch={clea_competences => setState({ ...state, openAdd: false, clea_competences })}
                    learning={learning}
                    clea_competences={state.clea_competences}
                />
            )}
        </>
    );
};

export default CleaCompetences;
