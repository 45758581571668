import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Lodash Debounce
import debounce from 'lodash.debounce';

// Material-UI
import { Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';

// Services
import { getContracts } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import DatePickerGrid from 'components/items/date-picker-grid';
import Autocomplete from 'components/items/autocomplete';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ------------------------------------------- BILL FORM ------------------------------------------- \\
const BillForm = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();
    const { contractId } = useParams();

    return (
        <>
            {/* -------------------- NUMBER BILL -------------------- */}
            <InputTextGrid
                value={state.number_bill}
                onChange={e => setState({ ...state, number_bill: e.target.value })}
                placeholder={t('number.bill')}
            />
            {/* -------------------- CONTRACT -------------------- */}
            {!contractId && (
                <SelectContract
                    contract={state.contract}
                    setContract={contract => setState({ ...state, contract })}
                />
            )}
            {/* -------------------- BILL DATE -------------------- */}
            <DatePickerGrid
                value={state.bill_date}
                onChange={value => setState({ ...state, bill_date: value })}
                placeholder={t('bill.date')}
                outlined
                required
            />
            {/* -------------------- DEADLINE DATE -------------------- */}
            <DatePickerGrid
                value={state.deadline_date}
                onChange={value => setState({ ...state, deadline_date: value })}
                placeholder={t('deadline.date')}
                outlined
            />
            {/* -------------------- DESCRIPTION -------------------- */}
            <InputTextGrid
                value={state.description}
                onChange={e => setState({ ...state, description: e.target.value })}
                placeholder={t('description')}
                multiline
            />
            {/* -------------------- PAID -------------------- */}
            {state.actionType === 'UPDATE' && (
                <Grid container alignItems="center" style={style.m5_0}>
                    <Grid
                        item xs={12} sm={4}
                        style={{ paddingRight: mediaSM ? 5 : 0 }}
                    >
                        <b className="input-title">{t('paid')}</b>
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ paddingLeft: mediaSM ? 5 : 0 }}
                    >
                        <RadioGroup
                            row
                            value={state.paid}
                            onChange={e => setState({ ...state, paid: e.target.value })}
                        >
                            <FormControlLabel
                                value="1"
                                label={t('yes')}
                                control={<Radio color="primary" />}
                            />
                            <FormControlLabel
                                value="0"
                                label={t('no')}
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

// ---------------------------------------- SELECT CONTRACT ---------------------------------------- \\
const SelectContract = ({ contract, setContract }) => {
    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        open: false,
        loading: false,
        options: [],
        searchRef: undefined,
    });

    // Handle Debounce
    const onSearch = value => {
        setState({ ...state, searchRef: value, loading: true });
    };
    const delayedOnSearch = useRef(debounce(val => onSearch(val), 500)).current;

    function handleDelayedOnSearch(value, reason) {
        if (reason === 'input') {
            delayedOnSearch(value);
        }
    }

    useEffect(() => {
        if (state.loading) {        
            getContracts(0, 10, state.searchRef).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        options: res.data.data.contracts.map(c => ({ label: c.reference, value: c.id })),
                    });
                }
                else {
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Grid container alignItems="center" style={style.m5_0}>
            <Grid
                item xs={12} sm={4}
                style={{ paddingRight: mediaSM ? 5 : 0 }}
            >
                <b className="input-title">{t('contract')}</b><FieldRequired />
            </Grid>
            <Grid
                item xs={12} sm={8}
                style={{ paddingLeft: mediaSM ? 5 : 0 }}
            >
                <Autocomplete
                    value={contract}
                    options={state.options}
                    loading={state.loading}
                    onChange={(e, value) => setContract(value)}
                    onInputChange={(e, value, reason) => handleDelayedOnSearch(value, reason)}
                    placeholder={t('search.contract')}
                />
            </Grid>
        </Grid>
    );
};

export default BillForm;
