import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { IconButton, Grid } from '@material-ui/core';
import { Facebook as FacebookIcon, Twitter as TwitterIcon, LinkedIn as LinkedInIcon } from '@material-ui/icons';

// Services
import useMediaQueries from 'services/media';
import { theme } from 'services/helpers';

// Assets
import ZeroBarrierLogo from 'assets/images/zerobarrier-white.png';

// Style
import style from 'style';

// ---------------------------------------- FOOTER ---------------------------------------- \\
const Footer = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const date = new Date();

    return (
        <div className="footer">
            <Grid container alignItems="center" style={style.p25}>
                <Grid item xs={12} md={4} style={{ textAlign: mediaMD ? 'left' : 'center' }}>
                    <p style={{ margin: 'auto' }}>
                        <b style={style.ttU}>
                            {t('contact')}
                        </b>
                        <br />
                        contact@zerobarrier.eu
                    </p>
                    <img alt="logo-zerobarrier-white" src={ZeroBarrierLogo} style={{ height: 30 }} />
                </Grid>
                <Grid item xs={12} md={4} container direction="column" justifyContent="space-between" style={style.taC}>
                    <Grid item>
                        <p style={{ ...style.fwB, ...style.ttU, margin: 0 }}>
                            {t('follow.us')}
                        </p>
                        <a target="_blank" rel="noopener noreferrer" href="https://fr-fr.facebook.com/solivers1/">
                            <IconButton>
                                <FacebookIcon style={{ color: 'white', fontSize: '2.2em' }}/>
                            </IconButton>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/solivers1">
                            <IconButton>
                                <div style={{ height: 24, width: 24, borderRadius: 3, backgroundColor: 'white' }}>
                                    <TwitterIcon style={{ color: 'rgba(60, 60, 60, 100)', fontSize: '1.7em', top: 0 }}/>
                                </div>
                            </IconButton>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/showcase/dispositif-zero-barrier">
                            <IconButton>
                                <LinkedInIcon style={{ color: 'white', fontSize: '2.2em' }}/>
                            </IconButton>
                        </a>
                    </Grid>
                    <Grid item>
                        <div style={{ ...style.taC, marginTop: 30 }}>
                            <span>© {date.getFullYear()} Solivers. {t('footer.rights')}</span>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: mediaMD ? 'right' : 'center' }}>
                    <p style={{ margin: 'auto' }}>
                        <b>{t('footer.solution')} SOLIVERS</b>
                        <br />
                        <a
                            style={style.cW}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://www.solivers.eu/home"
                        >
                            www.solivers.eu
                        </a>
                    </p>
                    {(theme === 'zerobarrier' || theme === 'localhost') && (
                        <p style={{ margin: 'auto', paddingTop: 10 }}>
                            <b>{t('deployed.by')} Discovia {t('national.level')}</b>
                            <br />
                            <a
                                style={style.cW}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="http://www.discovia.fr"
                            >
                                www.discovia.fr
                            </a>
                        </p>
                    )}
                    <p style={{ margin: 'auto', paddingTop: 10 }}>
                        <b>Avec notre partenaire La Famille de Capucine</b><br /><b>et Gaston en Auvergne-Rhône-Alpes</b>
                        <br />
                        <a
                            style={style.cW}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.lafamilledecapucineetgaston.org/"
                        >
                            www.lafamilledecapucineetgaston.org
                        </a>
                    </p>
                </Grid>
            </Grid>
        </div>
    );
};

export default Footer;
