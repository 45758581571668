import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getCompanyList, getCompanyMembers, getSkillbases, createEvaluation } from 'services/requests';
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import EvaluationFormPage1 from './evaluation-form/evaluation-form-page-1';
import EvaluationFormPage2 from './evaluation-form/evaluation-form-page-2';
import EvaluationFormPage3 from './evaluation-form/evaluation-form-page-3';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// --------------------------------------- CREATE EVALUATION ---------------------------------------- \\
const CreateEvaluation = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();
    const history = useHistory();
    const storedUser = getStoredUser();
    const COMPANY_ID = (storedUser && storedUser.company) ? storedUser.company.id : null;

    const [state, setState] = useState({
        actionType: 'CREATE',
        page: 1,
        loading: false,
        loadingResources: true,
        errorResources: false,

        // Page 1
        name: '',
        evaluation_date: null,
        details: '',

        // Page 2
        authorizedRefetchMembers: false,
        companies: [],
        company_id: COMPANY_ID,
        assessorOptions: [],
        assessor_id: storedUser?.id,
        userOptions: [],
        users: null,

        // Page 3
        authorizedRefetchSkillbase: false,
        skillbases: [],
        skillbase_id: null,
        skillbase: null,
        learning_outcomes: {},
        soft_units: {},
        expanded: null,
    });

    // Handle Get Resources
    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getCompanyList(), getCompanyMembers(COMPANY_ID), getSkillbases()])
            .then(axios.spread(function (res_companies, res_members, res_skillbases) {
                if (res_companies?.status === 200 && res_members?.status === 200 && res_skillbases?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        assessorOptions: res_members.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                        userOptions: res_members.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                    });
                }
                else {
                    console.log(res_companies + res_members + res_skillbases);
                    setState({ ...state, loadingResources: false, error: true }); 
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    // Handle Save Evaluation
    useEffect(() => {
        if (state.loading) {

            let learning_outcomes = [];
            Object.values(state.learning_outcomes).forEach(value => {
                learning_outcomes = learning_outcomes.concat(value);
            });

            let soft_unit_ids = [];
            Object.values(state.soft_units).forEach(value => {
                soft_unit_ids = soft_unit_ids.concat(value);
            });

            createEvaluation({
                name: state.name,
                evaluation_date: moment(state.evaluation_date).format('YYYY-MM-DD'),
                details: state.details,
                company_id: state.company_id,
                assessor_id: state.assessor_id,
                users: state.users.map(u => u.value),
                skillbase_id: state.skillbase_id,
                learning_outcomes: learning_outcomes,
                soft_unit_ids: soft_unit_ids,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/evaluation/${res.data.data.evaluation.id}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/evaluation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('evaluation.add')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            {/* -------------------- CONTAINER -------------------- */}
            {(state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <EvaluationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <EvaluationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <EvaluationFormPage3 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CreateEvaluation;
