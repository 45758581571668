import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import { SoftSkillList } from '../../softposition-rating';

// ------------------------------------ GROUP SOFT POSITION FORM PAGE 2 -------------------------------------- \\
const GroupSoftPositionFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const onSavePosition = () => {
        if (Object.values(state.soft_levels).every(item => Object.values(item).every(v => v === false))) {
            enqueueSnackbar(t('select.atleast.one.softlevel'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    return (
        <>
            {/* -------------------- SOFT SKILLS -------------------- */}
            <div className="full-container" style={{ paddingBottom: 24 }}>
                <SoftSkillList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={onSavePosition}
                        variant="contained"
                        style={{ backgroundColor: softskillsColor, color: 'white' }}
                    >
                        {t('softposition.add')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default GroupSoftPositionFormPage2;
