import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Tools to Create PDF
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as SaveIcon } from '@material-ui/icons';

// Services
import { getTranslation, getFormattedDate, softskillsColor, softskillsColor2, theme } from 'services/helpers';

// Assests
import LogoZB from 'assets/images/zerobarrier.png';
import LogoMobipass from 'assets/images/mobipass.png';
import LogoMobirhin from 'assets/images/mobirhin.png';

// ---------------------------------------- SOFT PASSEPORT ----------------------------------------- \\
const SoftPasseport = ({ soft_categories, user }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            CreateSoftPasseport(t, setLoading, soft_categories, user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Button
            onClick={() => setLoading(true)}
            disabled={loading}
            variant="outlined"
            style={{ marginLeft: 5, textTransform: 'none', borderColor: softskillsColor, color: softskillsColor }}
        >
            {t('soft.passeport')}
            {loading ? (
                <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
            ) : (
                <SaveIcon style={{ fontSize: 16, marginLeft: 5 }} />
            )}
        </Button>
    );
};

// ------------------------------------- CREATE SOFT PASSEPORT ------------------------------------- \\
const CreateSoftPasseport = (t, setLoading, soft_categories, user) => {

    // Create new PDF
    var pdf = new jsPDF('p', 'mm', 'a4');

    // Logo Mobipass
    pdf.addImage(LogoMobipass, 57.375, 120, 95.25, 31.75);

    // Title & Skillbase Name
    pdf.setFontSize(16);
    pdf.setFont('helvetica', 'bold');
    pdf.text(t('soft.passeport')?.toUpperCase(), 105, 170, { align: 'center' });

    // Learner
    pdf.setFontSize(12);
    pdf.setFont('helvetica', 'bold');
    pdf.setTextColor(80, 80, 80);
    pdf.text(t('last.name')?.toUpperCase() + ' : ' + user.last_name, 10, 240);
    pdf.text(t('first.name')?.toUpperCase() + ' : ' + user.first_name, 10, 246);

    // Start Portfolio on Second Page
    pdf.addPage();
    pdf.setPage(2);

    // Soft skills
    soft_categories.forEach(soft_category => {

        // Body
        var body = [];

        // Soft Category
        var softCategoryTab = [];
        softCategoryTab.push({
            content: getTranslation('name', soft_category)?.toUpperCase(),
            styles: { halign: 'center', fillColor: softskillsColor, fontStyle: 'bold', textColor: 'white' },
            colSpan: 3,
        });
        body.push(softCategoryTab);

        // Headers
        var headersTab = [];
        headersTab.push({
            content: t('softskills'),
            styles: { halign: 'center', fillColor: softskillsColor2, fontStyle: 'bold', cellWidth: 60 },
        });
        headersTab.push({
            content: t('level'),
            styles: { halign: 'center', fillColor: softskillsColor2, fontStyle: 'bold', cellWidth: 90 },
        });
        headersTab.push({
            content: t('validation.date'),
            styles: { halign: 'center', fillColor: softskillsColor2, fontStyle: 'bold', cellWidth: 40 },
        });
        body.push(headersTab);

        soft_category.softskills.forEach(softskill => {

            // Soft Skill
            var softskillTab = [];
            softskillTab.push({
                content: getTranslation('name', softskill) + '\r\n' + softskill.easy_to_read,
                styles: { valign: 'middle', cellWidth: 60 },
            });
            softskillTab.push({
                content: (softskill.evaluation_details != null) ? (t('level') + ' : ' + softskill.evaluation_details.level + '\r\n' + softskill.evaluation_details.level_definition) : '',
                styles: { valign: 'middle', cellWidth: 90 },
            });
            softskillTab.push({
                content: (softskill.evaluation_details != null) ? (softskill.evaluation_details.company + '\r\n' + getFormattedDate(softskill.evaluation_details.evaluation_date)) : '',
                styles: { halign: 'center', valign: 'middle', cellWidth: 40 },
            });
            body.push(softskillTab);
        });
    
        autoTable(pdf, {
            margin: {
                top: 25,
                bottom: 15,
                right: 10,
                left: 10,
            },
            body: body,
            theme: 'grid',
        });
    });

    // End Page
    pdf.addPage();
    pdf.setPage(pdf.internal.getNumberOfPages() + 1);

    // End Page Title
    pdf.setTextColor(0);
    pdf.setFontSize(12);
    pdf.text(t('job.passeport.endpage.title')?.toUpperCase(), 105, 30, { align: 'center' });

    // End Page Message
    pdf.setFont('helvetica', 'normal');
    pdf.setFontSize(11);            
    pdf.text(pdf.splitTextToSize(t('job.passeport.endpage.1'), 190), 10, 40);
    pdf.text(pdf.splitTextToSize(t('job.passeport.endpage.2'), 190), 10, 80);
    pdf.text(t('job.passeport.endpage.contact'), 105, 110, { align: 'center' });
    
    switch (theme) {
        case 'mobirhin':
            pdf.text(('ASW+W gemeinnützige GmbH\r\nIm Seewinkel 3\r\n77652 Offenburg\r\nDeutschland\r\nfarahe.midani@lebenshilfe-offenburg.de'), 105, 115, { align: 'center' });
            break;
        
        default:
            pdf.text(('SCIC Solivers\r\n46 Route Ecospace\r\n67120 Molsheim\r\nFrance\r\n+33 3 88 38 15 65\r\nzerobarrier@solivers.fr'), 105, 115, { align: 'center' });
            break;
    };

    // Headers & Footers of Page
    var numberOfPages = pdf.internal.getNumberOfPages();
    for (let i = 0; i < numberOfPages; i += 1) {

        // Set Current Page
        pdf.setPage(i + 1);

        // Logo
        switch (theme) {
            case 'mobirhin':
                pdf.addImage(LogoMobirhin, 10, 10, 29.76, 7.14);
                break;
            
            default:
                pdf.addImage(LogoZB, 10, 10, 33.87, 7.14);
                break;
        };

        // Skillbase Name
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bolditalic');
        pdf.setTextColor(80, 80, 80);
        if ((i + 1) > 1) {
            pdf.text(t('soft.passeport'), 200, 14, { align: 'right' });
        }

        // Page Number
        pdf.setTextColor(0);
        pdf.text(((i + 1) + ' / ' + numberOfPages), (pdf.internal.pageSize.width / 2), 287, { align: 'center' });
    }

    // Save PDF
    pdf.save(user.full_name + ' - ' + t('soft.passeport') + '.pdf');
    setLoading(false);
};

export default SoftPasseport;
