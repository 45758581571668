import React, { useEffect, useState, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Divider, Button, Accordion, AccordionSummary, Checkbox } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

// Services
import { getUser, getCleaDomains, getCleaCourseForUser } from 'services/requests';
import { cleaColor, cleaColor2, getFormattedDate, getTranslation, getLabelFromArray } from 'services/helpers';
import { cleaEvaluationTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Badge from 'components/items/badge';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

const styles = {
    th: {
        padding: 10,
        fontSize: 14,
        border: '1px solid lightgray',
        textAlign: 'center',
    },
    td: {
        padding: 10,
        fontSize: 14,
        border: '1px solid lightgray',
    },
};

// --------------------------------------- CLEA COMPETENCES DETAILS ---------------------------------------- \\
const CleaCompetencesDetails = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { learnerId, cleaEvaluationId } = useParams();
    const evaluationId = (cleaEvaluationId === 'positioning' ? null : cleaEvaluationId);

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: null,
        clea_domains: [],
        clea_position: null,
        clea_evaluation: null,
        validated_competences: [],
        expanded: null,
    });

    const getCheckedCompetencesNumber = (clea_domain) => {
        let number = 0;
        clea_domain.clea_subdomains.forEach(subdomain => {
            subdomain.clea_competences.forEach(competence => {
                if (state.validated_competences.some(c => c.id === competence.id)) {
                    number += 1;
                }
            });
        });
        return number;
    };

    useEffect(() => {
        if (state.loading) {
            axios.all([getUser(learnerId), getCleaDomains(), getCleaCourseForUser(learnerId)])
            .then(axios.spread(function (res_user, res_clea_domains, res_course, res_competences) {
                if (res_user?.status === 200 && res_clea_domains?.status === 200 && res_course?.status === 200) {

                    let clea_position, clea_evaluation, validated_competences = [];
                    if (evaluationId) {
                        clea_evaluation = res_course.data.data.clea_evaluations.find(e => e.id?.toString() === evaluationId);
                        validated_competences = clea_evaluation.clea_competences.map(c => ({ id: c.id, locked: true }));
                    }
                    else {
                        clea_position = res_course.data.data.clea_position;
                        validated_competences = clea_position.clea_competences.map(c => ({ id: c.id, locked: false }));
                    }

                    setState({
                        ...state,
                        loading: false,
                        user: res_user.data.data.user,
                        clea_domains: res_clea_domains.data.data.clea_domains,
                        clea_position,
                        clea_evaluation,
                        validated_competences,
                    });
                }
                else {
                    console.log(res_user, res_clea_domains, res_course);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/clea/course/${learnerId}`)}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ padding: mediaMD ? 0 : 10 }}
                >
                    <span style={{ ...style.fwB, ...style.fs16, textAlign: 'center' }}>
                        {evaluationId ? (
                            <>
                                {t(getLabelFromArray(cleaEvaluationTypes, state.clea_evaluation?.evaluation_type?.toString()))} {t('of')} {getFormattedDate(state.clea_evaluation?.evaluation_date)}
                            </>
                        ) : (
                            <>
                                {t('clea.positioning')} {t('of')} {getFormattedDate(state.clea_position?.position_date)} 
                            </>
                        )}
                        <br />
                        {state.user.full_name}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <div className="full-container" style={{ paddingTop: 24, paddingBottom: 24 }}>
                <div style={{ marginBottom: 10 }}>
                    <b className="input-title">{t('clea.competences.selected')}</b>
                </div>
                {/* -------------------- LIST HEADERS -------------------- */}
                <div style={{ backgroundColor: cleaColor, ...style.p10 }}>
                    <span style={style.cW}>
                        {t('clea.domain')}
                    </span>
                </div>
                {/* -------------------- CLEA DOMAIN LIST -------------------- */}
                <div>
                    {state.clea_domains?.length > 0 ? (
                        state.clea_domains.map(clea_domain => (
                            <Accordion
                                key={clea_domain.id}
                                expanded={state.expanded === clea_domain.id}
                                onChange={() => setState({ ...state, expanded: clea_domain.id === state.expanded ? null : clea_domain.id })}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    style={{ backgroundColor: cleaColor2 }}
                                >
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <span style={style.fwB}>
                                            {clea_domain.position}. {getTranslation('name', clea_domain)}
                                        </span>
                                        <Badge number={getCheckedCompetencesNumber(clea_domain)} />
                                    </Grid>
                                </AccordionSummary>
                                <Divider />
                                <div style={{ overflow: 'auto' }}>
                                    <table style={{ ...style.w100, minWidth: 1000 }}>
                                        <thead>
                                            <tr>
                                                <th rowSpan="2" style={{ ...styles.th, width: '30%' }}>
                                                    {t('clea.sub.domain')?.toUpperCase()}
                                                </th>
                                                <th rowSpan="2" style={{ ...styles.th, width: '70%' }}>
                                                    {t('clea.competence')?.toUpperCase()}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {clea_domain.clea_subdomains.length > 0 && (
                                                clea_domain.clea_subdomains?.map(sub_domain => (
                                                    <Fragment key={sub_domain.id}>
                                                        <tr>
                                                            <td
                                                                rowSpan={sub_domain.clea_competences.length + 1}
                                                                style={{ ...styles.th, width: '30%' }}
                                                            >
                                                                {sub_domain.position}. {getTranslation('name', sub_domain)}
                                                            </td>
                                                        </tr>
                                                        {sub_domain.clea_competences?.map(competence => (
                                                            <tr key={competence.id}>
                                                                <td style={{ ...styles.td, width: '70%' }}>
                                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                                        <div>
                                                                            <span>{sub_domain.position}.{competence.position} {getTranslation('name', competence)}</span>
                                                                            {/* -------------------- POSITIONED COMPETENCES -------------------- */}
                                                                            {state.validated_competences.some(c => c.id === competence.id && !c.locked) && (
                                                                                <>
                                                                                    <br />
                                                                                    <span style={{ color: cleaColor, fontStyle: 'italic' }}>
                                                                                        {t('competence.positioned')}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                            {/* -------------------- VALIDATED COMPETENCES -------------------- */}
                                                                            {state.validated_competences.some(c => c.id === competence.id && c.locked) && (
                                                                                <>
                                                                                    <br />
                                                                                    <span style={{ color: cleaColor, fontStyle: 'italic' }}>
                                                                                        {t('competence.validated')}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <Checkbox
                                                                            checked={state.validated_competences.some(c => c.id === competence.id)}
                                                                            disabled
                                                                            color="default"
                                                                        />
                                                                    </Grid>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </Fragment>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Accordion>
                        ))
                    ) : (
                        <>
                            <Grid style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                            <Divider />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default CleaCompetencesDetails;
