import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getClients } from 'services/requests';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- CLIENT EXPORT ---------------------------------------- \\
const ClientExport = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
    });

    function getAllClientsToExport(clients, offset) {
        getClients(offset, data.limit, data.name, data.address, data.contact, true).then(res => {
            if (res?.status === 200) {

                let res_clients = res.data.data.clients;
                clients = clients.concat(res_clients);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * clients.length) / data.total_count) + '%',
                });

                if (res_clients.length < data.limit) {
                    createExcelFile(clients);
                }
                else {
                    getAllClientsToExport(clients, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(clients) {
        if (clients.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            // Add Worksheet to the Workbook
            var worksheet = workbook.addWorksheet(t('clients'));

            // Header
            worksheet.columns = [
                { header: t('name'), key: 'name', width: 30 },
                { header: t('bill.address'), key: 'address', width: 30 },
                { header: t('contact'), key: 'contact', width: 30 },
                { header: t('companies'), key: 'companies', width: 50 },
            ];
            worksheet.getRow(1).font = { bold: true };

            // Rows
            clients.forEach(client => {
                worksheet.addRow({
                    name: client.name,
                    address: client.address,
                    contact: client.contact,
                    companies: client.companies?.map(c => c.name).join(', '),
                });
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('clients') + '.xlsx'));
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
            setState({ ...state, start: false });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllClientsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default ClientExport;
