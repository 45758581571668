import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Grid, Link } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// Components
import FooterMobiRhin from '../common/footer-mobirhin';
import CarouselMobiRhin from '../common/carousel-mobirhin';

// Assets
import LogoInterregEU from 'assets/images/logos-interreg-eu.png';
import HomePicture from 'assets/images/mobirhin-passerelle.jpg';
import imageParticipation from 'assets/images/participation-4-0sh.png'
import MobipackPicture from 'assets/images/mobipack.jpg';
import BackgroundPicture from 'assets/images/background.jpg';
import Interreg from 'assets/images/interreg.png';

// Style
import style from 'style';

const styles = {
    image_wrapper: {
        width: '500px',
        minWidth: '500px',
        height: 0,
        paddingTop: 50,
        paddingLeft: 70,
        paddingBottom: '80%',
        position: 'relative',
    },
    image_participation: {
        width: '100%',
        position: 'absolute',
    },
    image_europe_engage: {
        marginTop: 520,
        marginLeft: 0,
        width: '100%',
        position: 'absolute',
    },
    image_wrapper_mob: {
        width: 'calc(100% - 10vw)',
        maxWidth: 450,
        height: 0,
        paddingTop: 10,
        paddingLeft: 0,
        paddingBottom: '80%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    image_participation_mob: {
        marginTop: 440,
        width: '100%',
        position: 'absolute',
    },
    image_europe_engage_mob: {
        marginTop: 910,
        marginLeft: 10,
        width: 250,
        position: 'absolute',
    },
    cards: {
        padding: 10,
        border: '3px solid rgba(60, 60, 60)',
        marginBottom: 25,
        borderRadius: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    textTools: {
        color: 'white',
        padding: 10,
    },
    videos: {
        width: '100%',
        height: 'auto',
        borderRadius: 3,
    },
    home_part3_title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: 25,
        margin: 0,
    },
    home_part4_title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '25px 25px 0px 25px',
        margin: 0,
    },
    home_part5_title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '25px 25px 0px 25px',
        margin: 0,
    },
    logo: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        maxWidth: 300,
        margin: '0px 10px 0px 10px',
        alignSelf: 'center',
        padding: 90
    },
};

// ---------------------------------------- HOME ---------------------------------------- \\
const HomeMobiRhin = () => {
    return (
        <>
            <div className="main">
                <Part1 />
                <Part2 />
                <Part3 />
                <Part4 />
                <Part5 />
            </div>
            <FooterMobiRhin />
        </>
    );
};

// ------------------------------------ HOME CONTENT ------------------------------------ \\
const Part1 = () => {
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {mediaMD ? (
                <div 
                    style={{
                        height: 729,
                        backgroundImage: 'url(' + HomePicture + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'left',
                    }}
                >
                    <div style={{ ...style.p25, ...styles.image_wrapper }}>
                        <img
                            alt="logo-participation-4.0"
                            style={{...styles.image_participation}}
                            src={imageParticipation}
                        />
                        <img
                            alt="logos-interreg-eu"
                            src={LogoInterregEU}
                            style={{ ...styles.image_europe_engage }}
                        />
                    </div>
                </div>
            ) : (
                <div
                    className="row"
                    style={{
                        height: 500,
                        backgroundImage: 'url(' + HomePicture + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: '80% 0',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'left',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ ...style.p25, ...styles.image_wrapper_mob }}>
                        <img
                            alt="logo-participation-4.0"
                            style={{ ...styles.image_participation_mob }}
                            src={imageParticipation}
                        />
                        <img
                            alt="logos-interreg-eu"
                            src={LogoInterregEU}
                            style={{ ...styles.image_europe_engage_mob }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

const Part2 = () => {
    const { t } = useTranslation();
    const { mediaLG } = useMediaQueries();

    return (
        <Grid container alignItems="center" style={style.p25}>
            <Grid item xs={false} lg={2} />
            <Grid
                item xs={12} lg={4}
                container
                justifyContent={mediaLG ? 'flex-start' : 'center'}
            >
                <p>
                    <b>{t('home.mobirhin.part2.title1.1')} :</b><br />
                    {t('home.mobirhin.part2.title1.2')}<br />
                    {t('home.mobirhin.part2.title1.3')}<br />
                    {t('home.mobirhin.part2.title1.4')}<br />
                    {t('home.mobirhin.part2.title1.5')}<br />
                    <Link
                        style={{...style.fwB, position: "relative", zIndex: 900 }}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href="https://www.participation4-0.eu/"
                    >
                            www.participation4-0.eu
                    </Link>
                </p>
                <p>
                    <b>{t('home.mobirhin.part2.title2.1')} :</b><br />
                    • {t('home.mobirhin.part2.title2.2')}<br />
                    • {t('home.mobirhin.part2.title2.3')}<br />
                    • {t('home.mobirhin.part2.title2.4')}
                </p>
            </Grid>
            <Grid
                item xs={12} lg={4}
                container
                justifyContent="center"
            >
                <img
                    alt="mobipack"
                    src={MobipackPicture}
                    style={{ width: 'auto', height: 'auto', maxHeight: 235, maxWidth: '100%' }}
                />
            </Grid>
        </Grid>
    );
};

const Part3 = () => {
    const { t } = useTranslation();

    return (
        <div style={{ backgroundImage: 'url(' + BackgroundPicture + ')', backgroundSize: 'cover', padding: '25px 0' }}>
            <p style={styles.home_part3_title}>
                {t('home.mobirhin.part3.title')}
            </p>
            <Grid container alignItems="center">
                <Grid item xs={false} md={1} lg={2} />
                <Grid
                    item xs={12} md={5} lg={5}
                    style={style.p25}
                >
                    <p>
                        <b>{t('home.mobirhin.part3.content1.title')} :</b><br />
                        • {t('home.mobirhin.part3.content1.1')}<br />
                        • {t('home.mobirhin.part3.content1.2')}<br />
                    </p>
                </Grid>
                <Grid
                    item xs={12} md={5} lg={5}
                    style={style.p25}
                >
                    <p>
                        <b>{t('home.mobirhin.part3.content2.title')} :</b><br />
                        • {t('home.mobirhin.part3.content2.1')}<br />
                        • {t('home.mobirhin.part3.content2.2')}<br />
                        • {t('home.mobirhin.part3.content2.3')}<br />
                    </p>
                </Grid>
            </Grid>
        </div>
    );
};

const Part4 = () => {
    const { t } = useTranslation();

    return (
        <>
            <p style={styles.home_part4_title}>
                {t('home.mobirhin.part4.title')}
            </p>
            <Grid container alignItems="center">
                <Grid item xs={false} md={1} lg={2} />
                <Grid
                    item xs={12} md={10} lg={8}
                    style={style.p25}
                    container
                    justifyContent="center"
                >
                    <p>
                        <b>{t('home.mobirhin.part4.content1.1')}</b>
                    </p>
                    <p style={{ paddingTop: 25 }}>
                        <b>{t('home.mobirhin.part4.content2.title')} :</b>
                        <br />
                        {t('home.mobirhin.part4.content2.1')}
                    </p>
                    <p style={{ paddingTop: 25 }}>
                        <b>{t('home.mobirhin.part4.content3.title')}</b>
                        <br />
                        {t('home.mobirhin.part4.content3.1')}
                    </p>
                    <p style={{ paddingTop: 25 }}>
                        <b>{t('home.mobirhin.part4.content4.title')} </b>
                        <br />
                        {t('home.mobirhin.part4.content4.1')}
                    </p>
                </Grid>
            </Grid>
            <Divider />
        </>
    );
};

const Part5 = () => {
    const { t } = useTranslation();

    return (
        <div >
            <p style={styles.home_part5_title}>
                {t('home.mobirhin.part5.1.title')}
            </p>
            <Grid container alignItems="center">
                <Grid item xs={false} md={1} lg={2} />
                <Grid
                    item xs={12} md={12} lg={12}
                    style={style.p25}
                    container
                    justifyContent="center"
                >
                    <CarouselMobiRhin />
                </Grid>
            </Grid>
            <p style={styles.home_part5_title}>
                {t('home.mobirhin.part5.2.title')}
            </p>
            <Grid container alignItems="center">
                <Grid
                    item xs={12} md={12} lg={12}
                    style={{ ...style.p25, height: 250, margin: 5 }}
                    container
                    justifyContent="center"
                >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ ...styles.logo, backgroundImage: 'url(' + Interreg + ')' }} />
                    </div>
                </Grid>
            </Grid>
        </div>
    
    );
};

export default HomeMobiRhin;
