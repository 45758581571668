import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import {  List, ListItem, Grid, Divider, IconButton, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore as IconExpand, Create as IconEdit, Delete as IconDelete } from '@material-ui/icons';

// Services
import { getSectors, getInactiveSkillbases } from 'services/requests';
import { servicesColor, getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Skillbase from './skillbase-crud';
import CreateSkillbase from './skillbase-crud/skillbase-create';
import UpdateSkillbase from './skillbase-crud/skillbase-update';
import DeleteSkillbase from './skillbase-crud/skillbase-delete';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Tooltip from 'components/items/tooltip';
import InputSearch from 'components/items/input-search';

// Style
import style from 'style';

// --------------------------------------------- ROUTER ----------------------------------------------- \\
const SkillbasesRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <SkillbasesManagement />
            </Route>
            <Route path={`${match.path}/:skillbaseId`}>
                <Skillbase />
            </Route>
        </Switch>
    );
};

// --------------------------------------- SKILLBASES MANAGEMENT -------------------------------------- \\
const SkillbasesManagement = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        sectors: [],
        filteredSectors: [],
        search: '',
        total_count: 0,
        openCreateSkillbase: false,
    });

    const normalize = label => {
        return label?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const onSearch = () => {
        if (state.search != null && state.search.length > 0) {
            let filteredSectors = [];
            state.sectors.forEach(sector => {
                let skillbases = sector.skillbases.filter(s =>
                    normalize(getTranslation('name', s)).includes(normalize(state.search))
                );
                if (skillbases.length > 0) {
                    filteredSectors.push(Object.assign({}, sector, { skillbases }));
                }
            });
            setState({ ...state, filteredSectors });
        }
        else {
            setState({ ...state, filteredSectors: state.sectors });
        }
    };

    useEffect(() => {
        if (state.loading) {
            axios.all([getSectors(), getInactiveSkillbases()]).then(axios.spread(function (res_active, res_inactive) {
                if (res_active?.status === 200 && res_inactive?.status === 200) {
    
                    let total_count = 0;
                    let sectors = res_active.data.data.sectors;
                    let inactive = res_inactive.data.data.sectors;
    
                    for (let i = 0; i < sectors.length; i++) {
                        const skillbases = inactive.find(s => s.id === sectors[i].id).skillbases;
                        sectors[i].skillbases = sectors[i].skillbases.concat(skillbases);
                        total_count = total_count + sectors[i].skillbases.length;
                    }
    
                    setState({ ...state, loading: false, sectors, filteredSectors: sectors, total_count });
                }
                else {
                    console.log(res_active + res_inactive);
                    setState({ ...state, loading: false, error: true })
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('skillbases')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>
                <Button onClick={() => setState({ ...state, openCreateSkillbase: true })} variant="contained">
                    {t('add')}
                </Button>
            </Grid>
            {/* -------------------- SEARCH FILTER -------------------- */}
            <Grid container className="toolbar" style={{ paddingTop: 0 }}>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <InputSearch
                        placeholder={t('research.skillbase')}
                        value={state.search}
                        onChange={e => setState({ ...state, search: e.target.value })}
                        onKeyPress={onSearch}
                        onSearch={onSearch}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* -------------------- LIST HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('status')}</span>
                            </Grid>
                            <Grid item xs={3} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('content')}</span>
                            </Grid>
                            <Grid item xs={3} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('skillbases')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- SKILLBASES LIST -------------------- */}
                <SkillbaseList
                    filteredSectors={state.filteredSectors}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true  })}
                />
            </div>
            {/* -------------------- CREATE SKILLBASE -------------------- */}
            {state.openCreateSkillbase && (
                <CreateSkillbase
                    onClose={() => setState({ ...state, openCreateSkillbase: false })}
                    refetch={() => setState({ ...state, openCreateSkillbase: false, loading: true  })}
                />
            )}
        </>
    );
};

// ------------------------------------------ SKILLBASE LIST ------------------------------------------ \\
const SkillbaseList = ({ filteredSectors, loading, error, refetch }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        skillbase: null,
        openUpdateSkillbase: false,
        openDeleteSkillbase: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div>
                {filteredSectors.length > 0 ? (
                    filteredSectors.map(sector => (
                        <Accordion key={sector.id}>
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <span style={style.fwB}>
                                    {getTranslation('name', sector)} ({sector.skillbases.length})
                                </span>
                            </AccordionSummary>
                            <AccordionDetails style={style.p0}>
                                <List style={{ width: '100%', padding: 0 }}>
                                    {sector.skillbases.length > 0 ? (
                                        sector.skillbases.map(skillbase =>(
                                            <Fragment key={skillbase.id}>
                                                <Divider />
                                                <ListItem style={style.p0}>
                                                    <Grid container alignItems="center">
                                                        <Grid
                                                            item xs={12} md={4}
                                                            style={{ ...style.p10, textAlign: mediaMD ? null : 'center' }}
                                                        >
                                                            <span>{getTranslation('name', skillbase)}</span>
                                                        </Grid>
                                                        <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                                            <span>{skillbase.active === 1 ? t('active') : t('inactive')}</span>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} style={{ ...style.p10, ...style.taC }}>
                                                            <Button
                                                                onClick={() => history.push(`${match.path}/${skillbase.id}`)}
                                                                variant="contained" 
                                                                style={{ padding: '3px 16px', textTransform: 'none' }}
                                                            >
                                                                <span>{t('manage.content')}</span>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} md={3} style={{ ...style.p10, ...style.taC }}>
                                                            <Tooltip title={t('edit')}
                                                                item={
                                                                    <IconButton
                                                                        onClick={() => setState({ ...state, openUpdateSkillbase: true, skillbase })}
                                                                        className="list-btn"
                                                                    >
                                                                        <IconEdit />
                                                                    </IconButton>
                                                                }
                                                            />
                                                            <Tooltip title={t('delete')}
                                                                item={
                                                                    <IconButton
                                                                        onClick={() => setState({ ...state, openDeleteSkillbase: true, skillbase })}
                                                                        className="list-btn"
                                                                    >
                                                                        <IconDelete />
                                                                    </IconButton>
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            </Fragment>
                                        ))
                                    ) : (
                                        <>
                                            <Divider />
                                            <ListItem>
                                                <span>{t('none')}</span>
                                            </ListItem>
                                        </>
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <p style={{ padding: '10px 15px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        {t('none')}
                    </p>
                )}
            </div>
            {/* -------------------- UPDATE SKILLBASE -------------------- */}
            {state.openUpdateSkillbase && (
                <UpdateSkillbase
                    onClose={() => setState({ ...state, openUpdateSkillbase: false })}
                    refetch={() => handleRefetch('openUpdateSkillbase')}
                    skillbase={state.skillbase}
                />
            )}
            {/* -------------------- DELETE SKILLBASE -------------------- */}
            {state.openDeleteSkillbase && (
                <DeleteSkillbase
                    onClose={() => setState({ ...state, openDeleteSkillbase: false })}
                    refetch={() => handleRefetch('openDeleteSkillbase')}
                    skillbase={state.skillbase}
                />
            )}
        </>
    );
};

export default SkillbasesRouter;
