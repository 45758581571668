import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { updateClient } from 'services/requests';
import { servicesColor } from 'services/helpers';

// Components
import ClientForm from './client-form';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- UPDATE CLIENT ---------------------------------------- \\
const UpdateClient = ({ onClose, refetch, client }) => {
    const  { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        name: client.name,
        address: client.address,
        contact: client.contact,
    });

    function handleUpdateClient() {
        if (state.name === '' || state.address === '' || state.contact === '') {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    }

    useEffect(() => {
        if (state.loading) {
            updateClient(client.id, {
                name: state.name,
                address: state.address,
                contact: state.contact,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('client.edit')}
                        </p>
                        <ClientForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleUpdateClient}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateClient;
