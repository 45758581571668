import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid, Divider } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';

// Components
import AuthorizedCompanies from './authorized-companies';
import BlockedCompanies from './blocked-companies';

// Style
import style from 'style';

// ---------------------------------------- ACCESS REQUESTS ---------------------------------------- \\
const AccessRequests = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid style={style.p10}>
                <b>{t('access.requests')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>
            </Grid>
            {/* -------------------- COMPANIES -------------------- */}
            {ROLE_ID > 1 && (
                <BlockedCompanies />
            )}
            <AuthorizedCompanies />   
        </>
    );
};

export default AccessRequests;
