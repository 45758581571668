import React, { useState } from 'react';
import { Editor, RichUtils } from 'draft-js';
import { useTranslation } from 'react-i18next';

// DraftJS
import 'draft-js/dist/Draft.css';

// Material-UI
import { ButtonGroup, Button, Grid } from '@material-ui/core';
import { 
    FormatBold as BoldIcon, FormatItalic as ItalicIcon, FormatUnderlined as UnderLineIcon,
    Title as TitleIcon, FormatListBulleted as UlIcon, FormatListNumbered as OlIcon
} from '@material-ui/icons';

// Style
import style from 'style';

// ---------------------------------------- TEXT EDITOR ---------------------------------------- \\
const TextEditor = ({ label, editorState, setEditorState, disabled }) => {
    const { t } = useTranslation();
    const [blockType, setBlockType] = useState('')

    const editor = React.useRef(null);
    function focusEditor() {
        editor.current.focus();
    }

    function onBlockTypeClick(type) {
		setEditorState(RichUtils.toggleBlockType(editorState, type));
        blockType === type ? setBlockType('') : setBlockType(type);
	}

    function onInlineStyleClick(style) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
                <b className="input-title">{t(label)}</b>
            </Grid>
            <Grid item container xs={12} md={8}>
                <Grid item xs={12}>
                <div style={{ visibility: disabled ? "hidden" : "visible"}}>
                    <ButtonGroup color="primary">
                        <Button
                            value={"header-three"}
                            variant={blockType === "header-three" ? "contained" : null}
                            onClick={event => onBlockTypeClick(event.currentTarget.value)}
                        >
                            <TitleIcon style={style.fs16} />
                        </Button>
                        <Button
                            value={"unordered-list-item"}
                            variant={blockType === "unordered-list-item" ? "contained" : null}
                            onClick={event => onBlockTypeClick(event.currentTarget.value)}
                        >
                            <UlIcon style={style.fs16} />
                        </Button>
                        <Button
                            value={"ordered-list-item"}
                            variant={blockType === "ordered-list-item" ? "contained" : null}
                            onClick={event => onBlockTypeClick(event.currentTarget.value)}
                        >
                            <OlIcon style={style.fs16} />
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup color="primary" style={{marginLeft: 10 }}>
                        <Button 
                            onClick={() => onInlineStyleClick("UNDERLINE")}
                        >
                            <UnderLineIcon style={style.fs16} />
                        </Button>
                        <Button 
                            onClick={() => onInlineStyleClick("BOLD")}
                        >
                            <BoldIcon style={style.fs16}/>
                        </Button>
                        <Button 
                            onClick={() => onInlineStyleClick("ITALIC")}
                        >
                            <ItalicIcon style={style.fs16}/>
                        </Button>
                    </ButtonGroup>
                </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{ backgroundColor: disabled ? "#E8ECEF" : '', border: "1px solid lightgray", borderRadius: 5,  minHeight: "6em", cursor: "text", zIndex: 0 }}
                        onClick={focusEditor}
                        >
                        <Editor
                            ref={editor}
                            editorState={editorState}
                            onChange={setEditorState}
                            placeholder={disabled ? '' : t(label)}
                            readOnly={disabled}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
        
    );
};

export default TextEditor;
