import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// ------------------------------------ CLEA FORMATION FORM PAGE 2 --------------------------------- \\
const CleaFormationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const navigateToPage3 = () => {
        if ((state.learners == null || state.learners.length === 0)
            || (state.trainers == null || state.trainers.length === 0)
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- TRAINERS -------------------- */}
                        <SelectTrainers
                            state={state}
                            setState={setState}
                        />
                        {/* -------------------- LEARNERS -------------------- */}
                        <SelectLearners
                            state={state}
                            setState={setState}
                        />
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ---------------------------------------- SELECT TRAINERS ---------------------------------------- \\
const SelectTrainers = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.trainer_company_id) {
            getCompanyMembers(state.trainer_company_id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, trainersOptions: res.data.data.assessors.map(l => ({ value: l.id, label: l.full_name })) });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.trainer_company_id]);

    return (
        <div style={{ marginTop: 12 }}>
            <b className="input-title">{t('trainers.select')}</b><FieldRequired />
            <div>
                <span>{t('company')}</span>
                <Select
                    placeholder={t('company')}
                    value={state.trainer_company_id ? state.companies.find(c => c.value === state.trainer_company_id) : null}
                    onChange={company => setState({ ...state, trainer_company_id: company ? company.value : null })}
                    options={state.companies}
                />
            </div>
            <div style={{ marginTop: 12 }}>
                <span>{t('trainers')}</span>
                <Select
                    placeholder={t('trainers')}
                    value={state.trainers}
                    onChange={trainers => setState({ ...state, trainers })}
                    options={state.trainersOptions}
                    isMulti
                />
            </div>
        </div>
    );
};

// ---------------------------------------- SELECT LEARNERS ---------------------------------------- \\
const SelectLearners = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.learner_company_id) {
            getCompanyMembers(state.learner_company_id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, learnersOptions: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })) });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.learner_company_id]);

    return (
        <div style={{ marginTop: 12 }}>
            <b className="input-title">{t('learners.select')}</b><FieldRequired />
            <div>
                <span>{t('company')}</span>
                <Select
                    placeholder={t('company')}
                    value={state.learner_company_id ? state.companies.find(c => c.value === state.learner_company_id) : null}
                    onChange={company => setState({ ...state, learner_company_id: company ? company.value : null })}
                    options={state.companies}
                />
            </div>
            <div style={{ marginTop: 12 }}>
                <span>{t('learners')}</span>
                <Select
                    placeholder={t('learners')}
                    value={state.learners}
                    onChange={learners => setState({ ...state, learners })}
                    options={state.learnersOptions}
                    isMulti
                />
            </div>
        </div>
    );
};

export default CleaFormationFormPage2;
