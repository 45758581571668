import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Divider } from '@material-ui/core';

// Services
import { getCompanySkillbases } from 'services/requests';
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import InputText from 'components/items/input-text';
import Select from 'components/items/react-select';

// ---------------------------------------- FORMATION MODEL FORM PAGE 1 ---------------------------------------- \\
const FormationModelFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage2 = () => {
        if (state.name === '' || state.company_id == null || state.skillbase_id == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    useEffect(() => {
        if (state.company_id) {
            getCompanySkillbases(state.company_id).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, skillbases: res.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })) });
                }
                else {
                    setState({ ...state, skillbases: [] });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company_id]);

    return (
        <>
            {/* -------------------- FORM -------------------- */}
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- NAME -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('formation.model.name')}<FieldRequired />
                            </b>
                            <InputText
                                placeholder={t('formation.model.name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                            />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('company')}<FieldRequired />
                            </b>
                            <Select
                                placeholder={t('company')}
                                value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- SKILLBASE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('skillbase')}<FieldRequired />
                            </b>
                            <Select
                                placeholder={t('skillbase')}
                                value={state.skillbase_id ? state.skillbases.find(s => s.value === state.skillbase_id) : null}
                                onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null })}
                                options={state.skillbases}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------------- FOOTER --------------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default FormationModelFormPage1;
