import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Grid, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { deleteFormationModel, getFormationModel } from 'services/requests';
import { mobitrainColor, getTranslation } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ----------------------------------- DELETE FORMATION MODEL ---------------------------------------- \\
export const DeleteFormationModel = ({ onClose, refetch, model }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteFormationModel(model.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ textAlign: 'center', paddingBottom: 20 }}>
                        <span className="dialog-title">
                            {model.name}
                        </span>
                        <br />
                        <span>
                            {t('formation.model.delete.confirm')}
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// -------------------------------------- DETAILS FORMATION MODEL ------------------------------------- \\
export const DetailsFormationModel = ({ onClose, model }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState ({
        loading: true,
        error: false,
        formation_model: undefined,
    });

    useEffect(() => {
        if (state.loading) {
            getFormationModel(model.id).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        formation_model: res.data.data.formation_model,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('formation.model.details')}
                        </p>
                        <Divider />
                        {/* ---------------------- NAME ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={3} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('name')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={9} style={style.p10}>
                                <span>{state.formation_model.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- COMPANY ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={3} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('company')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={9} style={style.p10}>
                                <span>{state.formation_model.company.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- SKILLBASE ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={3} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('skillbase')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={9} style={style.p10}>
                                <span>{getTranslation('name', state.formation_model.skillbase)}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- COMPETENCES --------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={3} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('competence')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={9} style={style.p10}>
                                {state.formation_model.competences.length > 0 ? (
                                    state.formation_model.competences.map(competence => (
                                        <Fragment key={competence.id}>
                                            <span >
                                                {competence.numerotation + t('competence.numerotation') + ' ' + getTranslation('name', competence)}
                                            </span>
                                            <br/>
                                        </Fragment>
                                    ))
                                ):(
                                    <span>{t('none')}</span>
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- KNOWLEDGES ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={3} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('knowledge')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={9} style={style.p10}>
                                {state.formation_model.knowledges.length > 0 ? (
                                    state.formation_model.knowledges.map(knowledge => (
                                        <Fragment key={knowledge.id}>
                                            <span>
                                                {knowledge.numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', knowledge)}
                                            </span>
                                            <br/>
                                        </Fragment>
                                    ))
                                ):(
                                    <span>{t('none')}</span>
                                )}
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ----------------------- SKILLS ------------------------ */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={3} style={style.p10}>
                                <span style={{ color: mobitrainColor }}>
                                    {t('skill')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={9} style={style.p10}>
                                {state.formation_model.skills.length > 0 ? (
                                    state.formation_model.skills.map(skill => (
                                        <Fragment key={skill.id}>
                                            <span>
                                                {skill.numerotation + t('skill.numerotation') + ' ' + getTranslation('name', skill)}
                                            </span>
                                            <br/>
                                        </Fragment>
                                    ))
                                ):(
                                    <span>{t('none')}</span>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSA}>
                        <Button onClick={onClose} variant="contained">
                            {t('close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
