import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, IconButton, Divider, Accordion, AccordionSummary } from '@material-ui/core';
import { ExpandMore as IconExpand, ChevronLeft as IconLeft, ChevronRight as IconRight } from '@material-ui/icons';

// Services
import { getTranslation, mobipassColor, mobipassColor2, getFormattedDate} from 'services/helpers';

// Components
import Badge from 'components/items/badge';

// Style
import style from 'style';

const styles = {
    td: {
        padding: 10,
        border: '1px solid lightgray',
    }, 
};

// ----------------------------------- SKILLBASE POSITIONING --------------------------------------- \\
const SkillbasePositioning = ({ state, setState }) => {
    const { t } = useTranslation();

    const getMasteredDescriptorsNumber = unit => {
        var number = 0;
        unit.learning_outcomes.forEach(learning => {
            learning.competences.forEach(competence => {
                if (state.competence_ids.findIndex(c => c.id === competence.id) !== -1) {
                    number += 1;
                };
            });
            learning.knowledges.forEach(knowledge => {
                if (state.knowledge_ids.findIndex(k => k.id === knowledge.id) !== -1) {
                    number += 1;
                };
            });
            learning.skills.forEach(skill => {
                if (state.skill_ids.findIndex(s => s.id === skill.id) !== -1) {
                    number += 1;
                };
            });
        });
        return number;
    };

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Grid container style={{ backgroundColor: mobipassColor, ...style.cW, padding: '10px 20px' }}>
                <span>{t('units.of.learning.outcomes')}</span>
            </Grid>
            {/* -------------------- UNIT LIST -------------------- */}
            <div>
                {(state.skillbase.units != null && state.skillbase.units.length > 0) ? (
                    state.skillbase.units.map(unit => (
                        <Accordion
                            key={unit.id}
                            expanded={(state.expanded === unit.id)}
                            onChange={() => setState({ ...state, expanded: unit.id === state.expanded ? null : unit.id })}
                        >
                            <AccordionSummary style={{ backgroundColor: mobipassColor2 }} expandIcon={<IconExpand />}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <span style={style.fwB}>
                                        {unit.position}. {getTranslation('name', unit)}
                                    </span>
                                    <Badge number={getMasteredDescriptorsNumber(unit)} />
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <div style={{ overflow: 'auto', maxHeight: 400 }}>
                                <PositioningTable
                                    unit={unit}
                                    state={state}
                                    setState={setState}
                                />
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
        </>
    );
};

// ------------------------------------- POSITIONING TABLE ----------------------------------------- \\
const PositioningTable = ({ unit, state, setState }) => {
    const { t } = useTranslation();

    const getMastery = (descriptor_ids, descriptor_id, index) => {
        let mastery = descriptor_ids.find(d => (d.id === descriptor_id && d.index === index))?.mastery;

        switch (mastery) {
            case 0:
                return <span style={{ textTransform: 'uppercase', color: '#B60909' }}>{t('mastery.0')}</span>;

            case 1:
                return <span style={{ textTransform: 'uppercase', color: '#E78200' }}>{t('mastery.1')}</span>;

            case 2:
                return <span style={{ textTransform: 'uppercase', color: '#0EB813' }}>{t('mastery.2')}</span>;

            default:
                return '';
        };
    };

    const getLearningWidth = () => {
        switch (state.limit) {
            case 1 || 2:
                return '20%';

            case 3:
                return '15%';

            case 4 || 5:
                return '10%';

            default:
                return '10%';
        };
    };

    const getDescriptorWidth = () => {
        switch (state.limit) {
            case 1:
                return '60%';

            case 2 || 3:
                return '40%';

            case 4 || 5:
                return '30%';

            default:
                return '30%';
        };
    };

    const getMasteryWidth = () => {
        switch (state.limit) {
            case 1 || 2:
                return '20%';

            case 3 || 4:
                return '15%';

            case 5:
                return '12%';

            default:
                return '12%';
        };
    };

    return (
        <table style={{ width: '100%', minWidth: 1200 }}>
            <thead>
                <tr>
                    {/* -------------------- LEARNING -------------------- */}
                    <th
                        rowSpan={2}
                        style={{ ...styles.td, width: getLearningWidth(), textAlign: 'center' }}
                    >
                        <span style={style.ttU}>{t('learning.outcomes')}</span>
                    </th>
                    {/* -------------------- DESCRIPTOR -------------------- */}
                    <th
                        rowSpan={2}
                        style={{ ...styles.td, width: getDescriptorWidth(), textAlign: 'center' }}
                    >
                        <span style={style.ttU}>{t('descriptors')}</span>
                    </th>
                    {/* -------------------- SWITCH PLACEMENT -------------------- */}
                    <th
                        colSpan={state.limit}
                        style={{ ...styles.td, width: getDescriptorWidth(), textAlign: 'center' }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                {(state.offset > 0) && (
                                    <IconButton
                                        onClick={() => setState({ ...state, offset: state.offset -= 1, loading: true })}
                                        style={{ padding: 2 }}
                                    >
                                        <IconLeft />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {t('positioning')}
                            </Grid>
                            <Grid item xs={3}>
                                {((state.offset + state.limit) < state.total_count) && (
                                    <IconButton
                                        onClick={() => setState({ ...state, offset: state.offset += 1, loading: true })}
                                        style={{ padding: 2 }}
                                    >
                                        <IconRight />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </th>
                </tr>
                <tr>
                    {/* -------------------- MASTERY -------------------- */}
                    {state.hard_positions.map(hard_position => (
                        <th
                            key={hard_position.id}
                            style={{ ...styles.td, width: getMasteryWidth(), textAlign: 'center' }}
                        >
                            <span>{t('date')} : {getFormattedDate(hard_position.position_date)}</span>
                            <br />
                            <span>{t('assessor')} : {hard_position.assessor.full_name}</span>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {unit.learning_outcomes.map(learning => (
                    <Fragment key={learning.id}>
                        <tr>
                            <td
                                style={{ ...styles.td, width: getLearningWidth(), textAlign: 'center' }}
                                rowSpan={learning.competences?.length + learning.knowledges?.length + learning.skills?.length + 1}
                            >
                                <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                            </td>
                        </tr>
                        {/* -------------------- COMPETENCES -------------------- */}
                        {learning.competences.map(competence => (
                            <tr key={competence.id}>
                                <td style={{ ...styles.td, width: getDescriptorWidth() }}>
                                    <span>{competence.numerotation + t('competence.numerotation')} {getTranslation('name', competence)}</span>
                                </td>
                                {state.hard_positions.map((hard_position, index) => (
                                    <td key={hard_position.id} style={{ ...styles.td, width: getMasteryWidth(), textAlign: 'center' }}>
                                        {getMastery(state.competence_ids, competence.id, index)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {/* -------------------- KNOWLEDGES -------------------- */}
                        {learning.knowledges.map(knowledge => (
                            <tr key={knowledge.id}>
                                <td style={{ ...styles.td, width: getDescriptorWidth() }}>
                                    <span>{knowledge.numerotation + t('knowledge.numerotation')} {getTranslation('name', knowledge)}</span>
                                </td>
                                {state.hard_positions.map((hard_position, index) => (
                                    <td key={hard_position.id} style={{ ...styles.td, width: getMasteryWidth(), textAlign: 'center' }}>
                                        {getMastery(state.knowledge_ids, knowledge.id, index)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {/* -------------------- SKILLS -------------------- */}
                        {learning.skills.map(skill => (
                            <tr key={skill.id}>
                                <td style={{ ...styles.td, width: getDescriptorWidth() }}>
                                    <span>{skill.numerotation + t('skill.numerotation')} {getTranslation('name', skill)}</span>
                                </td>
                                {state.hard_positions.map((hard_position, index) => (
                                    <td key={hard_position.id} style={{ ...styles.td, width: getMasteryWidth(), textAlign: 'center' }}>
                                        {getMastery(state.skill_ids, skill.id, index)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default SkillbasePositioning;
