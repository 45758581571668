import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// File Saver
import { saveAs } from 'file-saver';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';

// Material-UI
import { Grid, Button } from '@material-ui/core';

// Services
import { getSkillbasesLinkedWithSoftskills, getSkillbase } from 'services/requests';
import { getTranslation } from 'services/helpers';
import { getLocale } from 'services/storage';

// Components
import Select from 'components/items/react-select';

// ------------------------------------ SPECIFIC ASSESSMENT GRID ----------------------------------- \\
const SpecificAssessmentGrid = () => {
    const { t } = useTranslation();
    const [skillbaseId, setSkillbaseId] = useState(null);

    return (
        <>
            <b className="input-title">
                {t('generate.specific.assessment.grid')}
            </b>
            <Grid container alignItems="center">
                <Grid item xs={8} style={{ paddingRight: 5 }}>
                    <SelectSkillbaseWithSoftskills
                        skillbaseId={skillbaseId}
                        setSkillbaseId={skillbaseId => setSkillbaseId(skillbaseId)}
                    />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 5 }}>
                    <GenerateSpecificAssessmentGrid
                        skillbaseId={skillbaseId}
                    />
                </Grid>
            </Grid>
        </>
    )
};

// ------------------------------ SELECT SKILLBASE WITH SOFT SKILLS -------------------------------- \\
const SelectSkillbaseWithSoftskills = ({ skillbaseId, setSkillbaseId }) => {
    const { t } = useTranslation();
    const [skillbases, setSkillbases] = useState([]);

    useEffect(() => {
        getSkillbasesLinkedWithSoftskills().then(res => {
            if (res?.status === 200) {
                setSkillbases(res.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })));
            }
            else {
                console.log(res);
            }
        });
    }, []);

    return (
        <Select
            placeholder={t('skillbase')}
            value={skillbaseId ? skillbases.find(s => s.value === skillbaseId) : null}
            onChange={skillbase => setSkillbaseId(skillbase ? skillbase.value : null)}
            options={skillbases}
        />
    );
};

// -------------------------------- GENERATE SPECIFIC ASSESSMENT GRID ------------------------------ \\
const GenerateSpecificAssessmentGrid = ({ skillbaseId }) => {
    const { t } = useTranslation();
    const [skillbase, setSkillbase] = useState(null);

    const columnToLetter = column => {
        var temp, letter = '';
        while (column > 0) {
            temp = (column - 1) % 26;
            letter = String.fromCharCode(temp + 65) + letter;
            column = (column - temp - 1) / 26;
        }
        return letter;
    };

    const onGenerateSpecificAssessmentGrid = () => {

        // Create Excel File
        var workbook = new ExcelJS.Workbook();

        // Add Worksheet to the Workbook
        var worksheet = workbook.addWorksheet(t('specific.assessment.grid'));

        // Columns Width
        worksheet.getColumn('A').width = 40;
        worksheet.getColumn('B').width = 20;
        worksheet.getColumn('C').width = 20;
        worksheet.getColumn('D').width = 36;
        worksheet.getColumn('E').width = 12;
        worksheet.getColumn('F').width = 24;
        worksheet.getColumn('G').width = 36;
        worksheet.getColumn('H').width = 12;
        worksheet.getColumn('I').width = 24;
        worksheet.getColumn('J').width = 36;
        worksheet.getColumn('K').width = 12;
        worksheet.getColumn('L').width = 24;
        worksheet.getColumn('M').width = 24;
        worksheet.getColumn('N').width = 12;

        // Title
        worksheet.getRow(5).height = 60;
        worksheet.mergeCells('A5:N5');
        worksheet.getCell('N5').value = t('specific.assessment.grid.title') + getTranslation('name', skillbase);
        worksheet.getCell('N5').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('N5').font = { bold: true, size: 22 };

        // Description
        worksheet.getRow(6).height = 140;
        worksheet.mergeCells('A6:N6');
        worksheet.getCell('N6').value = t('specific.assessment.grid.description');
        worksheet.getCell('N6').alignment = { vertical: 'middle', wrapText: true };
        worksheet.getCell('N6').font = { bold: true, size: 14 };

        // Rules : 1
        worksheet.mergeCells('A7:F7');
        worksheet.getCell('F7').alignment = { vertical: 'middle', wrapText: true };
        worksheet.getCell('F7').value = { 'richText': [
            { 'font': { 'bold': true, size: 12 }, 'text': t('specific.assessment.grid.rules.1.title') },
            { 'font': { size: 12 }, 'text': t('specific.assessment.grid.rules.1') },
        ]};

        // Rules : 2
        worksheet.mergeCells('G7:N7');
        worksheet.getCell('N7').alignment = { vertical: 'middle', wrapText: true };
        worksheet.getCell('N7').value = { 'richText': [
            { 'font': { 'bold': true, size: 12 }, 'text': t('specific.assessment.grid.rules.2.title') },
            { 'font': { size: 12 }, 'text': t('specific.assessment.grid.rules.2') },
        ]};

        // Trainee Name Title
        worksheet.getRow(8).height = 40;
        worksheet.mergeCells('A8:B8');
        worksheet.getCell('B8').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.trainee.name') }] };
        worksheet.getCell('B8').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B8').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DFEEF3' } };
        worksheet.getCell('B8').border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};

        // Traine Name
        worksheet.mergeCells('C8:F8');

        // Assessor Name Title
        worksheet.getRow(8).height = 40;
        worksheet.mergeCells('G8:J8');
        worksheet.getCell('J8').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessor.name') }] };
        worksheet.getCell('J8').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('J8').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DFEEF3' } };
        worksheet.getCell('J8').border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};

        // Assessor Name
        worksheet.mergeCells('K8:N8');

        // Evaluated Learnings & Soft Skills
        worksheet.getRow(9).height = 40;
        worksheet.mergeCells('A9:D9');
        worksheet.getCell('D9').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.evaluated.learnings.and.softskills') }] };
        worksheet.getCell('D9').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('D9').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DFEEF3' } };
        worksheet.getCell('D9').border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};

        // Evaluated Learnings & Soft Skills Values
        worksheet.mergeCells('E9:N9');

        // Assessment Dates Title
        worksheet.getRow(10).height = 40;
        worksheet.getRow(11).height = 40;
        worksheet.mergeCells('A10:D11');
        worksheet.getCell('D11').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.dates') }] };
        worksheet.getCell('D11').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D11').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DFEEF3' } };
        worksheet.getCell('D11').border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};

        // Assessment Dates
        worksheet.mergeCells('E10:F10');
        worksheet.mergeCells('E11:F11');
        worksheet.getCell('F10').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.first') }] };
        worksheet.getCell('F10').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.getCell('G10').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.second') }] };
        worksheet.getCell('G10').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells('H10:I10');
        worksheet.mergeCells('H11:I11');
        worksheet.getCell('I10').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.third') }] };
        worksheet.getCell('I10').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.getCell('J10').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.fourth') }] };
        worksheet.getCell('J10').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells('K10:L10');
        worksheet.mergeCells('K11:L11');
        worksheet.getCell('L10').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.fifth') }] };
        worksheet.getCell('L10').alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.mergeCells('M10:N10');
        worksheet.mergeCells('M11:N11');
        worksheet.getCell('N10').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.assessment.sixth') }] };
        worksheet.getCell('N10').alignment = { vertical: 'middle', horizontal: 'center' };

        // Context
        worksheet.getRow(12).height = 120;
        worksheet.mergeCells('A12:D12');
        worksheet.getCell('D12').value = { 'richText': [{ 'font': { 'bold': true, 'color': { 'argb': '5D799C' } }, 'text': t('specific.assessment.grid.context') }] };
        worksheet.getCell('D12').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D12').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DFEEF3' } };
        worksheet.getCell('D12').border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};

        worksheet.mergeCells('E12:F12');
        worksheet.mergeCells('H12:I12');
        worksheet.mergeCells('K12:L12');
        worksheet.mergeCells('M12:N12');

        // ULO Title
        worksheet.getRow(13).height = 60;
        worksheet.getCell('A13').value = t('specific.assessment.grid.ulo');
        worksheet.getCell('A13').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('A13').font = { bold: true, size: 16 };

        // Soft Skills Linked Title
        worksheet.mergeCells('B13:N13');
        worksheet.getCell('N13').value = t('specific.assessment.grid.linked.softskills');
        worksheet.getCell('N13').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('N13').font = { bold: true, size: 16 };

        // Anchor
        var anchorRow = 14;
        var anchorColumn = 3;

        // Assessment
        skillbase.units.forEach(unit => {
            if (unit.soft_units != null && unit.soft_units.length > 0) {

                // Divider Between Each Unit
                if (anchorRow !== 14) {
                    worksheet.getRow(anchorRow).height = 60;
                    worksheet.mergeCells('A' + anchorRow + ':Z' + anchorRow);
                    worksheet.getCell('Z' + anchorRow).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DFEEF3' } };
                    worksheet.getCell('Z' + anchorRow).border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};
                    anchorRow += 1;
                }

                // Unit & Learnings
                worksheet.mergeCells('A' + anchorRow + ':A' + (anchorRow + 2));
                worksheet.getCell('A' + (anchorRow + 2)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getCell('A' + (anchorRow + 2)).value = { 'richText': unit.learning_outcomes.map((learning, indexLearning) => {
                    if (indexLearning === 0) {
                        return { 'font': { 'bold': true, size: 12, 'color': { 'argb': '2C415C' } }, 'text': ('U' + unit.position + '. ' + getTranslation('name', unit) + '\r\n\r\n') };
                    }
                    return { 'font': { size: 12 }, 'text': ('U' + unit.position + '.A' + learning.position + '. ' + getTranslation('name', learning) + '\r\n') };
                })};

                // Unit + Description
                worksheet.mergeCells('A' + (anchorRow + 3) + ':A' + (anchorRow + 14));
                worksheet.getCell('A' + (anchorRow + 14)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getCell('A' + (anchorRow + 14)).value = { 'richText': [
                    { 'font': { 'bold': true, size: 12 }, 'text': t('specific.assessment.grid.softskills.evaluation.title') + '\r\n\r\n' },
                    { 'font': { 'bold': true, size: 12, 'color': { 'argb': '2C415C' } }, 'text': ('U' + unit.position + '. ' + getTranslation('name', unit)) }
                ]};

                // Empty Case
                worksheet.getRow(anchorRow).height = 40;
                worksheet.getCell('B' + anchorRow).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '06215A' } };
                worksheet.getCell('B' + anchorRow).border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};

                // Definition
                worksheet.getCell('B' + (anchorRow + 1)).value = t('definition');
                worksheet.getCell('B' + (anchorRow + 1)).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('B' + (anchorRow + 1)).font = { bold: true };

                // Observable Behavior
                worksheet.getCell('B' + (anchorRow + 2)).value = t('observable.behavior');
                worksheet.getCell('B' + (anchorRow + 2)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getCell('B' + (anchorRow + 2)).font = { bold: true };

                // Observed Behavior First Time
                worksheet.mergeCells('B' + (anchorRow + 3) + ':B' + (anchorRow + 6));
                worksheet.getCell('B' + (anchorRow + 6)).value = t('observed.behaviour.first.time');
                worksheet.getCell('B' + (anchorRow + 6)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getCell('B' + (anchorRow + 6)).font = { bold: true };

                // Observed Behavior Second Time
                worksheet.mergeCells('B' + (anchorRow + 7) + ':B' + (anchorRow + 10));
                worksheet.getCell('B' + (anchorRow + 10)).value = t('observed.behaviour.second.time');
                worksheet.getCell('B' + (anchorRow + 10)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getCell('B' + (anchorRow + 10)).font = { bold: true };

                // Observed Behavior Third Time
                worksheet.mergeCells('B' + (anchorRow + 11) + ':B' + (anchorRow + 14));
                worksheet.getCell('B' + (anchorRow + 14)).value = t('observed.behaviour.third.time');
                worksheet.getCell('B' + (anchorRow + 14)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getCell('B' + (anchorRow + 14)).font = { bold: true };

                // Soft Units
                unit.soft_units.forEach(soft_unit => {

                    // Soft Skill
                    worksheet.mergeCells(columnToLetter(anchorColumn) + anchorRow + ':' + columnToLetter(anchorColumn + 1) + anchorRow);
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + anchorRow).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '06215A' } };
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + anchorRow).border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + anchorRow).value = soft_unit.softskill ? soft_unit.softskill.name : '';
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + anchorRow).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + anchorRow).font = { color: { argb: 'FFFFFF' }};

                    // Acquired
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + anchorRow).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '06215A' } };
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + anchorRow).border = { top: { style: 'thin', color: { argb: 'ADADAD' } }, left: { style: 'thin', color: { argb: 'ADADAD' } }, bottom: { style: 'thin', color: { argb: 'ADADAD' } }, right: { style: 'thin', color: { argb: 'ADADAD' }}};
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + anchorRow).value = t('acquired');
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + anchorRow).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + anchorRow).font = { color: { argb: 'FFFFFF' }};

                    // Soft Level
                    worksheet.mergeCells(columnToLetter(anchorColumn) + (anchorRow + 1) + ':' + columnToLetter(anchorColumn + 1) + (anchorRow + 1));
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + (anchorRow + 1)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell(columnToLetter(anchorColumn + 1) + (anchorRow + 1)).value = { 'richText': [
                        { 'font': { 'bold': true, size: 12 }, 'text': (t('level') + (soft_unit.soft_level ? soft_unit.soft_level.level : '') + '\r\n') },
                        { 'font': { size: 12 }, 'text': (soft_unit.soft_level ? getTranslation('definition', soft_unit.soft_level) : '') },
                    ]};

                    // Observable Behaviors
                    let behaviors = soft_unit.specific_behaviors?.length > 0 ? soft_unit.specific_behaviors : soft_unit.soft_level?.behaviors;
                    worksheet.mergeCells(columnToLetter(anchorColumn) + (anchorRow + 2) + ':' + columnToLetter(anchorColumn + 2) + (anchorRow + 2));
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + (anchorRow + 2)).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    worksheet.getCell(columnToLetter(anchorColumn + 2) + (anchorRow + 2)).value = { 'richText': behaviors.map((behavior, indexBehavior) => {
                        if (indexBehavior === 0) {
                            return { 'font': { size: 12 }, 'text': getTranslation('definition', behavior) };
                        }
                        return { 'font': { size: 12 }, 'text': '\r\n' + getTranslation('definition', behavior) };
                    })};

                    // Anchor Row For Observed Behaviors
                    var anchorOB = anchorRow;

                    for (let i = 0; i < 3; i += 1) {

                        // Date
                        worksheet.getRow(anchorOB + 3).height = 20;
                        worksheet.getCell(columnToLetter(anchorColumn) + (anchorOB + 3)).value = t('date') + ':';
                        worksheet.getCell(columnToLetter(anchorColumn) + (anchorOB + 3)).alignment = { vertical: 'middle', horizontal: 'center' };
                        worksheet.mergeCells(columnToLetter(anchorColumn + 1) + (anchorOB + 3) + ':' + columnToLetter(anchorColumn + 2) + (anchorOB + 3));

                        // Place
                        worksheet.getRow(anchorOB + 4).height = 20;
                        worksheet.getCell(columnToLetter(anchorColumn) + (anchorOB + 4)).value = t('place') + ':';
                        worksheet.getCell(columnToLetter(anchorColumn) + (anchorOB + 4)).alignment = { vertical: 'middle', horizontal: 'center' };
                        worksheet.mergeCells(columnToLetter(anchorColumn + 1) + (anchorOB + 4) + ':' + columnToLetter(anchorColumn + 2) + (anchorOB + 4));

                        // Acquired
                        worksheet.getRow(anchorOB + 5).height = 20;
                        worksheet.getCell(columnToLetter(anchorColumn) + (anchorOB + 5)).value = t('acquired') + ':';
                        worksheet.getCell(columnToLetter(anchorColumn) + (anchorOB + 5)).alignment = { vertical: 'middle', horizontal: 'center' };
                        worksheet.mergeCells(columnToLetter(anchorColumn + 1) + (anchorOB + 5) + ':' + columnToLetter(anchorColumn + 2) + (anchorOB + 5));

                        // Observed Behaviors
                        worksheet.getRow(anchorOB + 6).height = 60;
                        worksheet.mergeCells(columnToLetter(anchorColumn) + (anchorOB + 6) + ':' + columnToLetter(anchorColumn + 2) + (anchorOB + 6));

                        // Increase Anchor Row For Observed Behaviors
                        anchorOB += 4;
                    }

                    // Inscrease Anchor Column
                    anchorColumn += 3;
                });

                // Setup Anchor For Next Unit
                anchorRow += 15;
                anchorColumn = 3;
            }
        });

        // Save Excel File
        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('specific.assessment.grid') + '.xlsx'));
    };

    useEffect(() => {
        if (skillbaseId != null) {
            getSkillbase(skillbaseId).then(res => {
                if (res?.status === 200) {
                    setSkillbase(res.data.data.skillbase);
                }
                else {
                    console.log(res);
                }
            });
        }
        else {
            setSkillbase(null);
        }
    }, [skillbaseId]);

    return (
        <Button
            onClick={onGenerateSpecificAssessmentGrid}
            disabled={skillbase == null ? true : false}
            variant="contained"
            style={{ width: '100%' }}
        >
            {t('generate')} ({getLocale() || 'fr'})
        </Button>
    )
};

export default SpecificAssessmentGrid;
