import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { IconButton, Grid } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';

// Pictures
import IconFacebook from 'assets/images/fb-icon.png';
import MobiRhinLogo from 'assets/images/mobirhin-white.png';

// Style
import style from 'style';

// ---------------------------------------- FOOTER MOBIRHIN ---------------------------------------- \\
const FooterMobiRhin = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const date = new Date();

    return (
        <div className="footer">
            <Grid container alignItems="center" style={style.p25}>
                <Grid item xs={12} md={4} style={{ textAlign: mediaMD ? 'left' : 'center' }}>
                    <p style={{ margin: 'auto' }}>
                        <b style={style.ttU}>{t('contact')}</b>
                        <br />
                        <b>farahe.midani@lebenshilfe-offenburg.de</b>
                    </p>
                    <img
                        alt="logo-zerobarrier-white"
                        src={MobiRhinLogo}
                        style={{ height: 30, marginTop: 10 }}
                    />
                </Grid>
                <Grid item xs={12} md={4} style={style.taC} >
                    <p style={{ ...style.fwB, ...style.ttU, margin: 0 }}>
                        {t('follow.us')}
                    </p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/Participation-40-350507678744690"
                    >
                        <IconButton>
                            <img alt="Logo Facebook" src={IconFacebook} />
                        </IconButton>
                    </a>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: mediaMD ? 'right' : 'center' }}>
                    <p style={{ margin: 'auto' }}>
                        <b>ASW+W gemeinnützige GmbH</b>
                        <br />
                        <b style={style.cW}>Farahe Midani, {t('project.owner')}</b>
                        <br />
                        <b style={style.cW}>Im Seewinkel 3, 77652 Offenburg</b>
                        <br />
                        <a
                            style={style.cW}
                            target="_blank" 
                            rel="noopener noreferrer" 
                            href="https://lebenshilfe-offenburg.de"
                        >
                            www.lebenshilfe-offenburg.de
                        </a>
                    </p>
                </Grid>
            </Grid>
            <div style={{ ...style.taC, marginBottom: 20 }}>
                <span>© {date.getFullYear()} Solivers. {t('footer.rights')}</span>
            </div>
        </div>
    );
};

export default FooterMobiRhin;
