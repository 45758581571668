import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';

// Components
import CreateSpecificBehavior from './create-specific-behavior';
import UpdateSpecificBehavior from './update-specific-behavior';
import DeleteSpecificBehavior from './delete-specific-behavior';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ---------------------------------------- SPECIFIC BEHAVIORS ---------------------------------------- \\
const SpecificBehaviors = ({ soft_unit }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        specific_behaviors: soft_unit.specific_behaviors,
        specific_behavior: undefined,
        openCreateBehavior: false,
        openUpdateBehavior: false,
        openDeleteBehavior: false,
    });

    function handleRefetchCreateBehavior(specific_behavior) {
        const specific_behaviors = state.specific_behaviors;
        specific_behaviors.push(specific_behavior);
        setState({ ...state, openCreateBehavior: false, specific_behaviors });
    }

    function handleRefetchUpdateBehavior(specific_behavior) {
        const specific_behaviors = state.specific_behaviors;
        const index = specific_behaviors.findIndex(sb => sb.id === specific_behavior.id);
        specific_behaviors.splice(index, 1, specific_behavior);
        setState({ ...state, openUpdateBehavior: false, specific_behaviors });
    }

    function handleRefetchDeleteBehavior(specific_behavior_id) {
        const specific_behaviors = state.specific_behaviors;
        const index = specific_behaviors.findIndex(sb => sb.id === specific_behavior_id);
        specific_behaviors.splice(index, 1);
        setState({ ...state, openDeleteBehavior: false, specific_behaviors });
    }

    return (
        <>
            {/* ---------------------- LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: 'gray', ...style.p10 }}>
                <span style={style.cW}>{t('specific.behaviors')}</span>
            </Grid>
            {/* ------------------ SPECIFIC BEHAVIOR LIST ------------------ */}
            {state.specific_behaviors.length > 0 ? (
                state.specific_behaviors.map(specific_behavior => (
                    <Fragment key={specific_behavior.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={10} style={style.p10}>
                                <span>{getTranslation('definition', specific_behavior)}</span>
                            </Grid>
                            <Grid item xs={12} md={2} container justifyContent="center">
                                <Tooltip title={t('edit')}
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openUpdateBehavior: true, specific_behavior })}
                                            className="list-btn"
                                        >
                                            <IconEdit />
                                        </IconButton>
                                    )}
                                />
                                <Tooltip title={t('delete')}
                                    item={(
                                        <IconButton
                                            onClick={() => setState({ ...state, openDeleteBehavior: true, specific_behavior })}
                                            className="list-btn"
                                        >
                                            <IconDelete />
                                        </IconButton>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateBehavior: true })}
                    className="fab"
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('specific.behavior.add')}
                </span>
            </div>
            {/* -------------------- CREATE SPECIFIC BEHAVIOR -------------------- */}
            {state.openCreateBehavior && (
                <CreateSpecificBehavior
                    open={state.openCreateBehavior}
                    onClose={() => setState({ ...state, openCreateBehavior: false })}
                    refetch={specific_behavior => handleRefetchCreateBehavior(specific_behavior)}
                    soft_unit={soft_unit}
                />
            )}
            {/* -------------------- UPDATE SPECIFIC BEHAVIOR -------------------- */}
            {state.openUpdateBehavior && (
                <UpdateSpecificBehavior
                    open={state.openUpdateBehavior}
                    onClose={() => setState({ ...state, openUpdateBehavior: false })}
                    refetch={specific_behavior => handleRefetchUpdateBehavior(specific_behavior)}
                    soft_unit={soft_unit}
                    specific_behavior={state.specific_behavior}
                />
            )}
            {/* -------------------- DELETE SPECIFIC BEHAVIOR -------------------- */}
            {state.openDeleteBehavior && (
                <DeleteSpecificBehavior
                    open={state.openDeleteBehavior}
                    onClose={() => setState({ ...state, openDeleteBehavior: false })}
                    refetch={specific_behavior_id => handleRefetchDeleteBehavior(specific_behavior_id)}
                    specific_behavior={state.specific_behavior}
                />
            )}
        </>
    );
};

export default SpecificBehaviors;
