import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory, useParams } from 'react-router-dom';

// Import Axios
import axios from 'axios';

// Import Notistack
import { useSnackbar } from 'notistack';

// Import Material-UI
import { Grid, Button, Divider, IconButton, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { Delete as IconDelete } from '@material-ui/icons';

// Import Services
import { getSoftPositions, getUser, deleteSoftPosition } from '../../../../services/requests';
import { getFormattedDate, softskillsColor } from '../../../../services/helpers';
import { getStoredUser } from '../../../../services/storage';
import useMediaQueries from '../../../../services/media';

// Import Components
import { SoftPositionsCreateRating, SoftPositionsUpdateRating } from './softposition-rating';
import Spinner from '../../../items/spinner';
import Error from '../../../items/error';
import Tooltip from '../../../items/tooltip';

// Import Style
import style from '../../../../style';

// ------------------------------ SOFT POSITION LIST ROUTER ----------------------------------- \\
const SoftPositionListRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <SoftPositions />
            </Route>
            <Route path={`${match.path}/create`}>
                <SoftPositionsCreateRating />
            </Route>
            <Route path={`${match.path}/:softPositionId`}>
                <SoftPositionsUpdateRating />
            </Route>
        </Switch>
    );
};

// ----------------------------------- SOFT POSITIONS ---------------------------------------- \\
const SoftPositions = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        user: undefined,
        soft_positions: undefined,
    });

    function navigateTo() {
        if (ROLE_ID < 5) {
            history.push('/mobipass/softskills/positioning');
        }
        else {
            history.push('/mobipass/softskills');
        }
    }

    useEffect(() => {
        if (state.loading) {
            axios.all([getUser(userId), getSoftPositions(userId)])
            .then(axios.spread(function (res_user, res_positions) {
                if (res_user?.status === 200 && res_positions?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        user: res_user.data.data.user,
                        soft_positions: res_positions.data.data.soft_positions,
                    });
                }
                else {
                    console.log(res_user + res_positions);
                    setState({ ...state, loading: false, error: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container
                    alignItems="center"
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={navigateTo}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={style.taC}
                >
                    {ROLE_ID < 5 && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {state.user && state.user.full_name}
                        </span>
                    )}
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    alignItems="center"
                    justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/softskills/positioning/${userId}/create`)}
                        variant="contained"
                    >
                        {ROLE_ID < 5 ? t('add') : t('self.positioning')}
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- SOFT POSITION LIST -------------------- */}
            <div className="full-container">
                <Grid container justifyContent="center" style={{ backgroundColor: softskillsColor, ...style.p10 }}>
                    <span style={style.cW}>{t('softpositions')}</span>
                </Grid>
                <SoftPositionList
                    ROLE_ID={ROLE_ID}
                    loading={state.loading}
                    error={state.error}
                    soft_positions={state.soft_positions}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
        </>
    );
};

// --------------------------------- SOFT POSITION LIST ---------------------------------------- \\
const SoftPositionList = ({ ROLE_ID, loading, error, soft_positions, refetch }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userId } = useParams();
    const STORED_USER_ID = getStoredUser()?.id;

    const [state, setState] = useState({
        soft_position: undefined,
        openDelete: false,
    });

    function handleClassName(soft_position) {
        if (ROLE_ID <= 2 || STORED_USER_ID === soft_position.assessor.id) {
            return 'list-item-hover';
        }
    }

    function handleOnClick(soft_position) {
        if (ROLE_ID <= 2 || STORED_USER_ID === soft_position.assessor.id) {
            history.push(`/mobipass/softskills/positioning/${userId}/${soft_position.id}`)
        }
    }

    function handleRefetch() {
        setState({ ...state, openDelete: false });
        refetch();
    }

    function handleDelete(event, soft_position) {
        event.stopPropagation();
        setState({ ...state, openDelete: true, soft_position })
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {soft_positions.length > 0 ? (
                    soft_positions.map(soft_position => (
                        <Fragment key={soft_position.id}>
                            <Grid
                                container
                                alignItems="center"
                                className={handleClassName(soft_position)}
                                onClick={() => handleOnClick(soft_position)}
                            >
                                <Grid item xs={12} md={10} style={style.p10}>
                                    <span>
                                        {t('positioning.of')}
                                        &nbsp;
                                        {getFormattedDate(soft_position.position_date)}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={2} container justifyContent="center">
                                    {(ROLE_ID <= 2 || STORED_USER_ID === soft_position.assessor.id) && (
                                        <Tooltip title={t('delete')}
                                            item={(
                                                <IconButton
                                                    onClick={e => handleDelete(e, soft_position)}
                                                    className="list-btn"
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            )}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- DELETE SOFT POSITION -------------------- */}
            {state.openDelete && (
                <DeleteSoftPosition
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={handleRefetch}
                    soft_position={state.soft_position}
                />
            )}
        </>
    );
};

// --------------------------------- DELETE SOFT POSITION -------------------------------------- \\
const DeleteSoftPosition = ({ onClose, soft_position, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteSoftPosition(soft_position.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open fullWidth>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ ...style.pb20, ...style.taC }}>
                        <p className="dialog-title">
                            {t('softposition.delete')}
                        </p>
                        <span>
                            {t('softposition.delete.confirm')}
                            &nbsp;
                            {getFormattedDate(soft_position.position_date)} ?
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ justifyContent: 'space-between' }}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default SoftPositionListRouter;
