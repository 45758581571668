import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import {
    Button, Grid, Divider, Accordion, AccordionSummary,
    Dialog, DialogContent, DialogActions, Badge, ButtonGroup
} from '@material-ui/core';
import { ExpandMore as IconExpand, AddCircle as IconPlus } from '@material-ui/icons';

// Services
import { getSkillbase  } from 'services/requests';
import { mobitrainColor, getTranslation, mobitrainColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import FieldRequired from 'components/items/field-required';
import BadgeCustomized from 'components/items/badge';

// Style
import style from 'style';

const styles = {
    border: {
        borderWidth: '0 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        padding: 10,
    },
};

// ------------------------------------- FORMATION MODEL PAGE 2 --------------------------------------- \\
const FormationModelFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const onValidate = () => {
        if (Object.values(state.descriptors).every(value => value.length === 0)) {
            enqueueSnackbar(t('descriptor.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loadingSkillbase) {
            getSkillbase(state.skillbase_id).then(res => {
                if (res?.status === 200) {

                    const skillbase = res.data.data.skillbase;

                    let descriptors = {};
                    skillbase.units.forEach(unit => {
                        unit.learning_outcomes.forEach(learning => {
                            descriptors[learning.id] = [];
                        });
                    });

                    if (state.actionType === 'UPDATE') {
                        state.formation_model.competences.forEach(competence => {
                            let learningDescriptors = descriptors[competence.learning_outcome_id];
                            learningDescriptors.push('competence_' + competence.id);
                        });
    
                        state.formation_model.knowledges.forEach(knowledge => {
                            let learningDescriptors = descriptors[knowledge.learning_outcome_id];
                            learningDescriptors.push('knowledge_' + knowledge.id);
                        });
    
                        state.formation_model.skills.forEach(skill => {
                            let learningDescriptors = descriptors[skill.learning_outcome_id];
                            learningDescriptors.push('skill_' + skill.id);
                        });
                    }

                    setState({ ...state, loadingSkillbase: false, skillbase, descriptors });
                }
                else {
                    console.log(res);
                    setState({ ...state, loadingSkillbase: false, errorSkillbase: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingSkillbase]);

    if (state.loadingSkillbase) return <Spinner />;
    if (state.errorSkillbase) return <Error />;

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                {/* -------------------- TITLE -------------------- */}
                <div>
                    <b className="input-title">
                        {t('descriptors.select')}<FieldRequired />
                    </b>
                </div>
                {/* -------------------- SKILLBASE -------------------- */}
                <Grid
                    container justifyContent="center" alignItems="center"
                    style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.cW, marginTop: 12 }}
                >
                    <span style={{ ...style.ml5, ...style.ttU }}>
                        {getTranslation('name', state.skillbase)}
                    </span>
                </Grid>
                {/* -------------------- UNIT LIST -------------------- */}
                <UnitList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider />
            {/* -------------------------- FOOTER --------------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button 
                        onClick={() => setState({ ...state, page: 1 })}
                        variant="contained"
                    >
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 2</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={onValidate}
                        variant="contained"
                        style={{ backgroundColor: mobitrainColor, color: 'white' }}
                    >
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ------------------------------------------ UNIT LIST ----------------------------------------------- \\
const UnitList = ({ state, setState }) => {
    const { t } = useTranslation();

    function handleChangeAccordion(unit_id) {
        if (state.expanded === unit_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: unit_id });
        }
    }

    function getDescriptorsNumber(unit) {
        let num = 0;
        unit.learning_outcomes.forEach(learning => {
            num += state.descriptors[learning.id].length
        });
        return num;
    }

    return (
        <>
            <div>
                {state.skillbase.units.length > 0 ? (
                    state.skillbase.units.map(unit => (
                        <Accordion
                            key={unit.id}
                            expanded={state.expanded === unit.id}
                            onChange={() => handleChangeAccordion(unit.id)}
                        >
                            <AccordionSummary expandIcon={<IconExpand />}>
                                <Grid container alignItems="center">
                                    <Grid item xs={11}>
                                        <span style={style.fwB}>
                                            {unit.position}. {getTranslation('name', unit)}
                                        </span>
                                    </Grid>
                                    <Grid item xs={1} container justifyContent="center">
                                        <BadgeCustomized
                                            variant="secondary"
                                            number={getDescriptorsNumber(unit)}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <Divider />
                            <div>
                                {unit.learning_outcomes.length > 0 ? (
                                    unit.learning_outcomes.map(learning => (
                                        <Fragment key={learning.id}>
                                            <Grid
                                                onClick={() => setState({ ...state, openLearningModal: true, learning })}
                                                container alignItems="center"
                                                className="list-item-hover"
                                                style={style.p10}
                                            >
                                                <Grid item xs={11}>
                                                    <span>{unit.position}.{learning.position} {getTranslation('name', learning)}</span>
                                                </Grid>
                                                <Grid item xs={1} container justifyContent="center" style={style.p5}>
                                                    <Badge badgeContent={state.descriptors[learning.id].length} color="secondary">
                                                        <IconPlus />
                                                    </Badge>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Fragment>
                                    ))
                                ) : (
                                    <Grid style={style.p10}>
                                        <span>{t('none')}</span>
                                    </Grid>
                                )}
                            </div>
                        </Accordion>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------------- LEARNING MODAL --------------------------- */}
            {state.openLearningModal && (
                <LearningModal state={state} setState={setState} />
            )}
        </>
    );
};

// ---------------------------------------- LEARNING MODAL -------------------------------------------- \\
const LearningModal = ({ state, setState }) => {
    const { t } = useTranslation();
    const learning = state.learning;

    const handleUpdateDescriptor = descriptor_id => {
        const descriptors = state.descriptors;
        const learningDescriptors = descriptors[learning.id];

        if (learningDescriptors.includes(descriptor_id)) {
            const index = learningDescriptors.findIndex(id => id === descriptor_id);
            learningDescriptors.splice(index, 1);
        }
        else {
            learningDescriptors.push(descriptor_id);
        }

        descriptors[learning.id] = learningDescriptors;
        setState({ ...state, descriptors });
    };

    const handleSelectAllDescriptors = learning => {
        const descriptors = state.descriptors;
        let learningDescriptors = descriptors[learning.id];
        learningDescriptors = [];

            learning.competences.forEach(competence => {
                learningDescriptors.push('competence_' + competence.id);
            });
            learning.knowledges.forEach(knowledge => {
                learningDescriptors.push('knowledge_' + knowledge.id);
            });
            learning.skills.forEach(skill => {
                learningDescriptors.push('skill_' + skill.id);
            });

        descriptors[learning.id] = learningDescriptors;
        setState({ ...state, descriptors });
    };

    const handleDeselectAllDescriptors = learning => {
        const descriptors = state.descriptors;
        let learningDescriptors = descriptors[learning.id];

        learningDescriptors = [];
        
        descriptors[learning.id] = learningDescriptors;
        setState({ ...state, descriptors });
    };

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                {Object.keys(learning).length > 0 && (
                    <>
                        <p className="dialog-title">
                            {getTranslation('name', learning)}
                        </p>
                        <span>{t('select.validated.descriptors')}</span>
                        <Grid container justifyContent="center" style={{ padding: 10 }}>
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    onClick={() => handleSelectAllDescriptors(learning)}
                                >
                                    {t('select.all')}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => handleDeselectAllDescriptors(learning)}
                                >
                                    {t('deselect.all')}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                        {/* -------------------- COMPETENCE -------------------- */}
                        <div style={{ backgroundColor: mobitrainColor, ...style.p10 }}>
                            <span style={style.cW}>{t('competence')}</span>
                        </div>
                        {learning.competences.length > 0 ? (
                            learning.competences.map(competence => (
                                <div
                                    key={competence.id}
                                    className="list-item-hover"
                                    onClick={() => handleUpdateDescriptor('competence_' + competence.id)}
                                    style={{
                                        ...styles.border,
                                        backgroundColor: state.descriptors[learning.id].includes('competence_' + competence.id)
                                        ? mobitrainColor2
                                        : null
                                    }}
                                >
                                    <span>{competence.numerotation + t('competence.numerotation')}&nbsp;{getTranslation('name', competence)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                        {/* -------------------- KNOWLEDGE -------------------- */}
                        <div style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.mt15 }}>
                            <span style={style.cW}>{t('knowledge')}</span>
                        </div>
                        {learning.knowledges.length > 0 ? (
                            learning.knowledges.map(knowledge => (
                                <div
                                    key={knowledge.id}
                                    className="list-item-hover"
                                    onClick={() => handleUpdateDescriptor('knowledge_' + knowledge.id)}
                                    style={{
                                        ...styles.border,
                                        backgroundColor: state.descriptors[learning.id].includes('knowledge_' + knowledge.id)
                                        ? mobitrainColor2
                                        : null
                                    }}
                                >
                                    <span>{knowledge.numerotation + t('knowledge.numerotation')}&nbsp;{getTranslation('name', knowledge)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                        {/* -------------------- SKILL -------------------- */}
                        <div style={{ backgroundColor: mobitrainColor, ...style.p10, ...style.mt15 }}>
                            <span style={style.cW}>{t('skill')}</span>
                        </div>
                        {learning.skills.length > 0 ? (
                            learning.skills.map(skill => (
                                <div
                                    key={skill.id}
                                    className="list-item-hover"
                                    onClick={() => handleUpdateDescriptor('skill_' + skill.id)}
                                    style={{
                                        ...styles.border,
                                        backgroundColor: state.descriptors[learning.id].includes('skill_' + skill.id)
                                        ? mobitrainColor2
                                        : null
                                    }}
                                >
                                    <span>{skill.numerotation + t('skill.numerotation')}&nbsp;{getTranslation('name', skill)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    onClick={() => setState({ ...state, openLearningModal: false })}
                    variant="contained"
                >
                    <span style={style.p0_10}>{t('close')}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormationModelFormPage2;
