import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Grid, MenuItem, Button } from '@material-ui/core';

// Services
import { generateSubscriptions, getCompanies } from 'services/requests';
import { servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import SelectOutlined from 'components/items/select-outlined';
import InputTextGrid from 'components/items/input-text-grid';
import DatePickerGrid from 'components/items/date-picker-grid';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// Style
import style from 'style';

// --------------------------------------- GENERATE SUBSCRIPTIONS ---------------------------------------- \\
const GenerateSubscriptions = ({ onClose, refetch, contract_id, bill_id, client_id }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaSM } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        error: undefined,
        data: undefined,
        companies: [],
        generation_type: 'annual',
        price: '',
        nb_items: '',
        start_date_annual: null,
        end_date_annual: null,
        start_date_monthly: null,
        end_date_monthly: null,
    });

    function handleGenerateSubscriptions() {
        if (!state.companies || state.companies.length === 0) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (
            (state.generation_type === 'annual' || state.generation_type === 'both' || state.generation_type === 'custom') && 
            ((!state.start_date_annual || !state.end_date_annual) ||
            (state.start_date_annual > state.end_date_annual))
        ) { 
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else if (
            (state.generation_type === 'monthly' || state.generation_type === 'both') && 
            ((!state.start_date_monthly || !state.end_date_monthly) ||
            (state.start_date_monthly > state.end_date_monthly))
        ) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else if ((state.generation_type === 'custom') && (!state.price || !state.nb_items)) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            const data = {
                company_ids: state.companies.map(c => c.value),
                generation_type: state.generation_type,
            };

            if (contract_id) {
                data.contract_id = contract_id;
            }
            else {
                data.bill_id = bill_id;
            }

            if (state.generation_type === 'annual' || state.generation_type === 'both' || state.generation_type === 'custom') {
                data.start_date_annual = moment(state.start_date_annual).format('YYYY-MM-DD');
                data.end_date_annual = moment(state.end_date_annual).format('YYYY-MM-DD');
            }

            if (state.generation_type === 'monthly' || state.generation_type === 'both') {
                data.start_date_monthly = moment(state.start_date_monthly).format('YYYY-MM-DD');
                data.end_date_monthly = moment(state.end_date_monthly).format('YYYY-MM-DD');
            }

            if (state.generation_type === 'custom') {
                data.price = state.price;
                data.nb_items = state.nb_items;
            }

            setState({ ...state, loading: true, data });
        }
    }

    useEffect(() => {
        if (state.loading) {
            generateSubscriptions(state.data).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    if (res.data.data.errors.length > 0) {
                        enqueueSnackbar(res.data.data.errors[0].response.error, { variant: 'error' });
                    }
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('generate.subscriptions')}
                </p>
                {/* -------------------- COMPANIES -------------------- */}
                <Grid container alignItems="center" style={style.m5_0}>
                    <Grid
                        item xs={12} sm={4}
                        style={{ paddingRight: mediaSM ? 5 : 0 }}
                    >
                        <b className="input-title">{t('companies')}</b><FieldRequired />
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ paddingLeft: mediaSM ? 5 : 0 }}
                    >
                        <SelectCompanies
                            companies={state.companies}
                            setCompanies={companies => setState({ ...state, companies })}
                            client_id={client_id}
                        />
                    </Grid>
                </Grid>
                {/* -------------------- GENERATION TYPE -------------------- */}
                <Grid container alignItems="center" style={style.m5_0}>
                    <Grid
                        item xs={12} sm={4}
                        style={{ paddingRight: mediaSM ? 5 : 0 }}
                    >
                        <b className="input-title">{t('generation.type')}</b><FieldRequired />
                    </Grid>
                    <Grid
                        item xs={12} sm={8}
                        style={{ paddingLeft: mediaSM ? 5 : 0 }}
                    >
                        <SelectOutlined
                            value={state.generation_type}
                            onChange={e => setState({ ...state, generation_type: e.target.value })}
                        >
                            <MenuItem value="annual">
                                <span>{t('subscription.annual')}</span>
                            </MenuItem>
                            <MenuItem value="monthly">
                                <span>{t('subscription.monthly')}</span>
                            </MenuItem>
                            <MenuItem value="both">
                                <span>{t('subscription.both')}</span>
                            </MenuItem>
                            <MenuItem value="custom">
                                <span>{t('subscription.custom')}</span>
                            </MenuItem>
                        </SelectOutlined>
                    </Grid>
                </Grid>
                {(state.generation_type === 'annual' || state.generation_type === 'both' || state.generation_type === 'custom') && (
                    <>
                        {/* -------------------- START DATE -------------------- */}
                        <DatePickerGrid
                            value={state.start_date_annual}
                            onChange={value => setState({ ...state, start_date_annual: value })}
                            placeholder={(state.generation_type === 'custom' ? t('start.date') : t('start.date.annual'))}
                            outlined
                            required
                        />
                        {/* -------------------- END DATE -------------------- */}
                        <DatePickerGrid
                            value={state.end_date_annual}
                            onChange={value => setState({ ...state, end_date_annual: value })}
                            placeholder={(state.generation_type === 'custom' ? t('end.date') : t('end.date.annual'))}
                            outlined
                            required
                        />
                    </>
                )}
                {(state.generation_type === 'monthly' || state.generation_type === 'both') && (
                    <>
                        {/* -------------------- START DATE -------------------- */}
                        <DatePickerGrid
                            value={state.start_date_monthly}
                            onChange={value => setState({ ...state, start_date_monthly: value })}
                            placeholder={t('start.date.monthly')}
                            outlined
                            required
                        />
                        {/* -------------------- END DATE -------------------- */}
                        <DatePickerGrid
                            value={state.end_date_monthly}
                            onChange={value => setState({ ...state, end_date_monthly: value })}
                            placeholder={t('end.date.monthly')}
                            outlined
                            required
                        />
                    </>
                )}
                {state.generation_type === 'custom' && (
                    <>
                        {/* -------------------- PRICE -------------------- */}
                        <InputTextGrid
                            value={state.price}
                            onChange={e => setState({ ...state, price: e.target.value })}
                            placeholder={t('price')}
                            type="number"
                            required
                        />
                        {/* -------------------- NB ITEMS -------------------- */}
                        <InputTextGrid
                            value={state.nb_items}
                            onChange={e => setState({ ...state, nb_items: e.target.value })}
                            placeholder={t('nb.items')}
                            type="number"
                            required
                        />
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button onClick={onClose} variant="contained">
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleGenerateSubscriptions}
                    variant="contained"
                    style={{ backgroundColor: servicesColor, color: 'white' }}
                >
                    {t('generate')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// --------------------------------------- SELECT COMPANIES ---------------------------------------- \\
const SelectCompanies = ({ companies, setCompanies, client_id }) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getCompanies(0, 50, null, null, null, null, null, client_id).then(res => {
            if (res?.status === 200) {
                setOptions(res.data.data.companies.map(c => ({ label: c.name, value: c.id })))
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            placeholder={t('companies')}
            value={companies}
            onChange={companies => setCompanies(companies)}
            options={options}
            isMulti
        />
    );
};

export default GenerateSubscriptions;
