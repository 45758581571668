 import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory, useParams } from 'react-router-dom';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button, Divider, IconButton, ButtonGroup } from '@material-ui/core';
import { Create as IconUpdate, Delete as IconDelete, Assignment as IconDetails, Search as IconSearch, Cancel as IconCancel } from '@material-ui/icons';

// Services
import { getHardPositionsForUser, getUser } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { mobipassColor, getFormattedDate, getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateHardPosition from './hardpositions-crud/hardpositions-create';
import UpdateHardPosition from './hardpositions-crud/hardpositions-update';
import DeleteHardPosition from './hardpositions-crud/hardpositions-delete';
import DuplicateHardPosition from './hardpositions-crud/hardpositions-duplicate';
import DetailsHardPosition from './hardpositions-details';
import HardPositionsExport from './hardpositions-export';
import HardPositionsPerformanceReport from './hardpositions-performance-report';
// import CompareHardPositions from './hardpositions-compare';
import HardPositionWithSkillbase from './hardpositions-with-skillbase';
import Pagination from 'components/items/pagination';
import DatePicker from 'components/items/date-picker';
import SkilbaseWithEmptyPlacementsExport from './skillbase-with-empty-placements-export';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectSkillbase from 'components/select/select-skillbase';

// Style
import style from 'style';

// ---------------------------------- USER HARD POSITIONS ROUTER ---------------------------------------- \\
const UserHardPositionsRouter = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <UserHardPositions />
            </Route>
            <Route exact path={`${match.path}/create`}>
                <CreateHardPosition />
            </Route>
            <Route exact path={`${match.path}/update/:hardPositionId`}>
                <UpdateHardPosition />
            </Route>
            <Route exact path={`${match.path}/duplicate/:hardPositionId`}>
                <DuplicateHardPosition />
            </Route>
            {/* <Route exact path={`${match.path}/compare/:hardPositionIds`}>
                <CompareHardPositions />
            </Route> */}
            <Route exact path={`${match.path}/details/:hardPositionId`}>
                <DetailsHardPosition />
            </Route>
        </Switch>
    );
};

// ------------------------------------- USER HARD POSITIONS -------------------------------------------- \\
const UserHardPositions = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        menu: 'all-positions',
        learner: null,
        openCompare: false,
        openEmptyExport: false,
        openPerformanceReport: false,
    });

    useEffect(() => {
        getUser(userId).then(res => {
            if (res?.status === 200) {
                setState({ ...state, learner: res.data.data.user });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid item xs={12} md={4} container alignItems="center" justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push('/mobipass/positioning')}
                            variant="contained"
                        >
                            {t('previous')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center" alignItems="center" style={{ padding: mediaMD ? 0 : 10 }}>
                    {ROLE_ID < 5 && (
                        <span style={{ ...style.fwB, ...style.fs16 }}>
                            {state.learner?.full_name}
                        </span>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container alignItems="center" justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button
                        onClick={() => setState({ ...state, openEmptyExport: true })}
                        variant="contained"
                        style={style.mr5}
                    >
                        {t('export.empty')}
                    </Button>
                    <Button
                        onClick={() => history.push(`/mobipass/positioning/${userId}/create`)}
                        variant="contained"
                    >
                        {ROLE_ID < 5 ? t('add') : t('self.positioning')}
                    </Button>
                    {/* <Button
                        onClick={() => setState({ ...state, openCompare: true })}
                        variant="contained"
                        style={style.ml5}
                    >
                        {t('compare')}
                    </Button> */}
                </Grid>
            </Grid>
            <Divider />
            <Grid container className="toolbar">
                {/* -------------------- SWITCH -------------------- */}
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <ButtonGroup size="small">
                        <Button
                            color="primary"
                            variant={state.menu === 'all-positions' ? 'contained' : 'outlined'}
                            onClick={() => setState({ ...state, menu: 'all-positions' })}
                            style={{ textTransform: 'none', boxShadow: 'none' }}
                        >
                            {t('all.positions')}
                        </Button>
                        <Button
                            color="primary"
                            variant={state.menu === 'skillbased' ? 'contained' : 'outlined'}
                            onClick={() => setState({ ...state, menu: 'skillbased' })}
                            style={{ textTransform: 'none', boxShadow: 'none' }}
                        >
                            {t('skillbased')}
                        </Button>
                    </ButtonGroup>
                </Grid>
                {/* -------------------- EXPORT PERFORMANCE REPORT -------------------- */}
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <Button
                        onClick={() => setState({ ...state, openPerformanceReport: true })}
                        variant="contained" color="primary"
                    >
                        {t('performance.report')}
                    </Button>
                </Grid>
            </Grid>
            {/* -------------------- SWITCH -------------------- */}
            {state.menu === 'all-positions' && (
                <HardPositions
                    learner={state.learner}
                />
            )}
            {state.menu === 'skillbased' && (
                <HardPositionWithSkillbase />
            )}
            {/* -------------------- COMPARE HARD POSITION MODAL -------------------- */}
            {/* {state.openCompare && (
                <CompareHardPositionModal
                    onClose={() => setState({ ...state, openCompare: false })}
                />
            )} */}
            {/* -------------------- PERFORMANCE REPORT -------------------- */}
            {state.openPerformanceReport && (
                <HardPositionsPerformanceReport
                    onClose={() => setState({ ...state, openPerformanceReport: false })}
                    learner={state.learner}
                />
            )}
            {/* -------------------- EMPTY SKILLBASE EXPORT MODAL -------------------- */}
            {state.openEmptyExport && (
                <SkilbaseWithEmptyPlacementsExport
                    onClose={() => setState({ ...state, openEmptyExport: false })}
                />
            )}
        </>
    );
};

// ---------------------------------------- HARD POSITIONS ---------------------------------------------- \\
const HardPositions = ({ learner }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        hard_positions: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        position_date: null,
        skillbase_id: null,
        assessor: '',
    });

    useEffect(() => {
        if (state.loading) {
            getHardPositionsForUser(userId, {
                offset: state.offset,
                limit: state.limit,
                position_date: state.position_date ? moment(state.position_date).format('YYYY-MM-DD') : null,
                skillbase_id: state.skillbase_id,
                assessor: state.assessor,
            }).then(res => {
                if (res?.status === 200) {
                    const data = res.data.data;
                    setState({
                        ...state,
                        loading: false,
                        offset: data.offset,
                        total_count: data.total_count,
                        limit: data.limit,
                        hard_positions: data.hard_positions,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0 });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            position_date: null,
            skillbase_id: null,
            assessor: '',
        });
    };

    return (
        <>
            <div className="full-container">
                {/* -------------------- HEADERS -------------------- */}
                <Grid container alignItems="center" style={{ backgroundColor: mobipassColor, color: 'white' }}>
                    <Grid item xs={12} md={2} style={style.p10}>
                        <span>{t('date')}</span>
                    </Grid>
                    <Grid item xs={12} md={5} style={style.p10}>
                        <span>{t('skillbase')}</span>
                    </Grid>
                    <Grid item xs={12} md={3} style={style.p10}>
                        <span>{t('assessor')}</span>
                    </Grid>
                    <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('action')}</span>
                    </Grid>
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('date')}
                                value={state.position_date}
                                onChange={position_date => onSelectSearch('position_date', position_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={5} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('assessor')}
                                value={state.assessor}
                                onChange={e => setState({ ...state, assessor: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                            <HardPositionsExport
                                learner={learner}
                                data={state}
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- LIST -------------------- */}
                <HardPositionList
                    loading={state.loading}
                    error={state.error}
                    hard_positions={state.hard_positions}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- PAGINATION -------------------- */}
            <Grid container alignItems="center" justifyContent="center" style={style.p20}>
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
            </Grid>
        </>
    );
};

// ------------------------------------- HARD POSITION LIST --------------------------------------------- \\
const HardPositionList = ({ loading, error, refetch, hard_positions }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;
    const USER_ID = storedUser?.id;

    const [state, setState] = useState({
        hard_position: undefined,
        openDelete: false,
    });

    function handleRefetch() {
        setState({ ...state, openDelete: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {hard_positions.length > 0 ? (
                    hard_positions.map(hard_position => (
                        <Fragment key={hard_position.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getFormattedDate(hard_position.position_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={5} style={style.p10}>
                                    <span>{getTranslation('name', hard_position.skillbase)}</span>
                                </Grid>
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>
                                        {(hard_position.assessor.id === Number(userId)
                                            ? t('self.positioning.name')
                                            : hard_position.assessor.full_name
                                        )}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={(
                                            <IconButton
                                                onClick={() => history.push(`/mobipass/positioning/${userId}/details/${hard_position.id}`)}
                                                className="list-btn"
                                            >
                                                <IconDetails />
                                            </IconButton>
                                        )}
                                    />
                                    {(ROLE_ID <= 2 || USER_ID === hard_position.assessor?.id) && (
                                        <>
                                            <Tooltip title={t('edit')}
                                                item={(
                                                    <IconButton
                                                        onClick={() => history.push(`/mobipass/positioning/${userId}/update/${hard_position.id}`)}
                                                        className="list-btn"
                                                    >
                                                        <IconUpdate />
                                                    </IconButton>
                                                    
                                                )}
                                            />
                                            <Tooltip title={t('delete')}
                                                item={(
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openDelete: true, hard_position })}
                                                        className="list-btn"
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                )}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </> 
                )}
            </div>
            {/* -------------------- DELETE HARD POSITION -------------------- */}
            {state.openDelete && (
                <DeleteHardPosition
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={handleRefetch}
                    hard_position={state.hard_position}
                />
            )}

        </>
    );
};

export default UserHardPositionsRouter;
