import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { getSoftCategories, updateSoftUnit } from 'services/requests';
import { getTranslation, softskillsColor2, servicesColor } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

const styles = {
    th: {
        padding: 10,
        textAlign: 'center',
        border: '1px solid lightgray',
    },
};

// ------------------------------------- UPDATE SOFT UNIT ------------------------------------- \\
const UpdateSoftUnit = ({ onClose, refetch, soft_unit }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    
    const [state, setState] = useState({
        loadingRessources: true,
        loading: false,
        softskill: null,
        soft_level_id: soft_unit.soft_level.id,
    });

    const onUpdateSoftUnit = () => {
        if (state.soft_level_id == null) {
            enqueueSnackbar(t('softlevel.select.error'), { variant: 'error' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        getSoftCategories().then(res => {
            if (res?.status === 200) {
                const soft_category = res.data.data.soft_categories.find(c => c.id === soft_unit?.softskill?.soft_category?.id);
                const softskill = soft_category?.softskills?.find(s => s.id === soft_unit?.softskill?.id);
                setState({ ...state, loadingRessources: false, softskill });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    useEffect(() => {
        if (state.loading) {
            updateSoftUnit(soft_unit.id, { soft_level_id: state.soft_level_id }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.soft_unit);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth maxWidth="md">
            {(state.loadingRessources || state.loading) ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('softskill.link.update')}
                        </p>
                        <div style={{ marginBottom: 10 }}>
                            <b className="input-title">{t('softlevel.select')}<FieldRequired /></b>
                        </div>
                        {state.softskill != null && (
                            <div className="soft-level-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan={4} style={styles.th}>
                                                {getTranslation('name', state.softskill)}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {state.softskill.soft_levels.map(soft_level => (
                                                <td
                                                    key={soft_level.id}
                                                    className="soft-level-td"
                                                    onClick={() => setState({ ...state, soft_level_id: soft_level.id })}
                                                >
                                                    <Grid
                                                        container
                                                        className="soft-level"
                                                        style={{
                                                            backgroundColor: state.soft_level_id === soft_level.id
                                                            ? softskillsColor2
                                                            : null
                                                        }}
                                                    >
                                                        <p style={{ ...style.taC, ...style.fwB, ...style.w100 }}>
                                                            {t('level')}&nbsp;{soft_level.level}
                                                            <br />
                                                            {soft_level.level === 1 && t('remember.and.understand')}
                                                            {soft_level.level === 2 && t('apply')}
                                                            {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                            {soft_level.level === 4 && t('create')} 
                                                        </p>
                                                        <p className="soft-level-definition">
                                                            {getTranslation('definition', soft_level)}
                                                        </p>
                                                    </Grid>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onUpdateSoftUnit}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('save')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default UpdateSoftUnit;
