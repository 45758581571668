import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Tools to Create PDF
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Material-UI
import { Button, CircularProgress } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';
import { months } from 'services/constants';

// ---------------------------------------- BAR EXPORT ---------------------------------------- \\
export const EvaluationsChartBarExport = ({ type, data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    function createExcelFile() {

        // Create Excel File
        var workbook = new ExcelJS.Workbook();

        // Add Worksheet to the Workbook
        var worksheet = workbook.addWorksheet('Statistiques');

        // Header
        worksheet.columns = [
            { header: 'Mois', key: 'month', width: 30 },
            { header: 'Apprenants évalués', key: 'evaluated_learners', width: 30 },
            { header: 'Acquis d\'apprentissage évalués', key: 'evaluated_learnings', width: 40 },
            { header: 'Acquis d\'apprentissage validés', key: 'valitated_learnings', width: 40 },
        ];
        worksheet.getRow(1).font = { bold: true };

        // Rows
        Object.entries(data.months).map(([key, value]) => {
            return worksheet.addRow({
                month: t(months.find(m => m.value === key.split('-')[1]).label) + ' ' + key.split('-')[0],
                evaluated_learners: value.nb_evaluated_learners,
                evaluated_learnings: value.nb_evaluated_learning_outcomes,
                valitated_learnings: value.nb_validated_learning_outcomes,
            });
        });

        worksheet.addRow([]);

        // Subscription Headers
        worksheet.addRow([
            'TOTAUX',
            data.total_evaluated_learners,
            data.total_evaluated_learning_outcomes,
            data.total_validated_learning_outcomes,
        ]);

        if (type === 'learner') {
            worksheet.spliceColumns(2, 1);
        }

        // Save Excel File
        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'mobipass-statistics.xlsx'));
        enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
    }

    return (
        <Button
            onClick={createExcelFile}
            variant="contained"
            style={{ marginRight: 5 }}
        >
            Excel
           <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
        </Button>
    );
};

// ---------------------------------------- PDF BAR EXPORT ---------------------------------------- \\
export const EvaluationsChartBarPDFExport = ({ company }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const createPdfFile = () => {

        // Create new PDF
        const pdf = new jsPDF('landscape');

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text(t('activity.report.evaluations'), (pdf.internal.pageSize.width / 2), 10, { align: 'center' });

        if (company != null) {
            pdf.setFontSize(9);
            pdf.setFont('helvetica', 'bold');
            pdf.text(company?.name, (pdf.internal.pageSize.width / 2), 15, { align: 'center' });
        }

        // Cover Page
        const activity_chart = document.getElementById('chart-to-export');

        html2canvas(activity_chart, { allowTaint: true, useCORS: true }).then(canvas => {

            // Add Cover Page to the PDF
            const img = canvas.toDataURL('image/jpg');
            pdf.addImage(img, 'JPEG', 10, 18, 280, 210, '', 'FAST');
            
            // Save the PDF
            if (company != null) {
                pdf.save(company?.name + ' - ' + t('evaluations') + ' - ' + t('statistics') + '.pdf');
            }
            else {
                pdf.save(t('evaluations') + ' - ' + t('statistics') + '.pdf');
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (loading) {
            createPdfFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Button
            onClick={() => setLoading(true)}
            disabled={loading}
            variant="contained"
        >
            PDF
            {loading ? (
                <CircularProgress size={16} color="inherit" style={{ marginLeft: 5 }} />
            ) : (
                <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
            )}
        </Button>
    );
};

// ---------------------------------------- PIE EXPORT ---------------------------------------- \\
export const EvaluationsChartPieExport = ({ data }) => {
    const { enqueueSnackbar } = useSnackbar();

    const createExcelFile = () => {

        // Create Excel File
        var workbook = new ExcelJS.Workbook();

        // Add Worksheet to the Workbook
        var worksheet = workbook.addWorksheet('Statistiques');

        // Header
        worksheet.columns = [
            { header: 'Secteurs', key: 'sector', width: 40 },
            { header: 'Nombre d\'acquis d\'apprentissage évalués', key: 'evaluated_learnings', width: 40 },
        ];
        worksheet.getRow(1).font = { bold: true };

        // Rows
        data.sectors.forEach(sector => {
            return worksheet.addRow({
                sector: getTranslation('name', sector),
                evaluated_learnings: sector.evaluations_count,
            });
        })

        // Save Excel File
        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), 'mobipass-statistics.xlsx'));
        enqueueSnackbar('Téléchargement terminé', { variant: 'success' });
    };

    return (
        <Button
            onClick={createExcelFile}
            variant="contained"
        >
            Excel
            <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
        </Button>
    );
};


