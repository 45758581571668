import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';

// Components
import SkillbaseTools from './skillbase-tools';
import SkillbaseList from 'components/common/skillbase-list';

// ---------------------------------------- TOOLS ---------------------------------------- \\
const Tools = () => {
    const match = useRouteMatch();
    const { t } = useTranslation();

    return (
        <>
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('tools')}
            </BreadcrumbsItem>
            <Switch>
                <Route exact path={`${match.path}`}>
                    <SkillbaseList />
                </Route>
                <Route path={`${match.path}/:skillbaseId`}>
                    <SkillbaseTools />
                </Route> 
                <Redirect to={`${match.path}`} />
            </Switch>
        </>
    );
};

export default Tools;
