import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Grid, Button, Accordion, AccordionSummary, Badge } from '@material-ui/core';
import { 
    LibraryAdd as IconAdd, ExpandMore as IconExpand, CheckBox as IconCheckBox,
    CheckBoxOutlineBlank as IconCheckoutline, Check as IconCheck
} from '@material-ui/icons';

// Services
import { getTranslation, softskillsColor, softskillsColor2 } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------- SOFT FORMATION FORM PAGE 2 ------------------------------------- \\
const SoftFormationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage3 = () => {
        if (state.softskill == null) {
            enqueueSnackbar(t('select.one.formation.softskill'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                {/* -------------------- TITLE -------------------- */}
                <p>
                    <b className="input-title">{t('select.soft.formation.softskill')}</b><FieldRequired/>
                </p>
                {/* -------------------- SOFT CATEGORIES -------------------- */}
                <Grid
                    container justifyContent="center" alignItems="center"
                    style={{ backgroundColor: softskillsColor, ...style.p10, ...style.cW }}
                >
                    <IconAdd />
                    <span style={{ ...style.ml5, ...style.ttU }}>
                        {t('select.soft.formation.softskill')}
                    </span>
                </Grid>
                <SoftCategoryList
                    state={state}
                    setState={setState}
                />
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// -------------------------------------- SOFT CATEGORY LIST ----------------------------------------- \\
const SoftCategoryList = ({ state, setState }) => {
    const { t } = useTranslation();

    const handleChangeAccordion = soft_category_id => {
        if (state.expanded === soft_category_id) {
            setState({ ...state, expanded: null });
        }
        else {
            setState({ ...state, expanded: soft_category_id });
        }
    };

    return (
        <div>
            {state.soft_categories.map(soft_category => (
                <Accordion
                    key={soft_category.id}
                    expanded={state.expanded === soft_category.id}
                    onChange={() => handleChangeAccordion(soft_category.id)}
                >
                    <AccordionSummary expandIcon={<IconExpand />}>
                        <Grid container alignItems="center">
                            <Grid item xs={10}>
                                <span style={style.fwB}>
                                    {soft_category.position}. {getTranslation('name', soft_category)}
                                </span>
                            </Grid>
                            <Grid item xs={2} container justifyContent="flex-end" style={{ padding: '0 10px' }}>
                                {state.soft_category?.id === soft_category.id && (
                                    <div className="badge" style={{ backgroundColor: '#f32c72' }}>
                                        <IconCheck style={{ fontSize: 20 }}/>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <Divider />
                    <div>
                        {(soft_category.softskills && soft_category.softskills.length > 0) ? (
                            <>
                                {/* -------------------- SOFT SKILLS -------------------- */}
                                {soft_category.softskills.map(softskill => (
                                    <Fragment key={softskill.id}>
                                        <Grid
                                            container alignItems="center"
                                            className="list-item-hover"
                                            onClick={() => setState({ ...state, soft_category, softskill })}
                                            style={{
                                                padding: 10,
                                                backgroundColor: state.softskill?.id === softskill.id
                                                ? softskillsColor2
                                                : null
                                            }}
                                        >
                                            <Grid item xs={10} style={{ padding: 10 }}>
                                                <span>{softskill.position}. {getTranslation('name', softskill)}</span>
                                            </Grid>
                                            <Grid item xs={2} container justifyContent="flex-end" style={{ padding: '0px 14px' }}>
                                                {state.softskill?.id === softskill.id ? (
                                                    <Badge color="secondary">
                                                        <IconCheckBox />
                                                    </Badge>
                                                ):(
                                                    <Badge color="secondary">
                                                        <IconCheckoutline />
                                                    </Badge>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Fragment>
                                ))}
                            </>
                        ) : (
                            <Grid style={style.p10}>
                                <span>{t('none')}</span>
                            </Grid>
                        )}
                    </div>
                </Accordion>
            ))}
        </div>
    );
};

export default SoftFormationFormPage2;
