import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';

// Services
import { deleteBill } from 'services/requests';
import { getFormattedDate, servicesColor } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- DELETE BILL -------------------------------------- \\
export const DeleteBill = ({ onClose, refetch, bill }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteBill(bill.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {t('bill')}&nbsp;{bill.number_bill ? bill.number_bill : 'FATEMP' + bill.id}
                        </p>
                        <span>{t('bill.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------------- DETAILS BILL ------------------------------------- \\
export const DetailsBill = ({ onClose, bill }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {t('bill')}
                </p>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('paid')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{bill.paid === 0 ? t('no') : t('yes')}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('bill.date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getFormattedDate(bill.bill_date)}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('number.bill')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{bill.number_bill ? bill.number_bill : 'FATEMP' + bill.id}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('contract')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{bill.contract.reference}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('total.amount.TTC')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{bill.total_amount_TTC}€</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('deadline.date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{bill.deadline_date ? getFormattedDate(bill.deadline_date) : ''}</span>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: servicesColor }}>
                            {t('description')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{bill.description}</span>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog> 
    );
};
