import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, MenuItem, Button, Divider } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import SelectOutlined from 'components/items/select-outlined';
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';

// -------------------------------------- SOFT FORMATION FORM 1 ---------------------------------------- \\
const SoftFormationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    const navigateToPage2 = () => {
        if (state.company_id == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.trainers == null || state.trainers.length === 0) {
            enqueueSnackbar(t('trainers.select.one'), { variant: 'warning' });
        }
        else if (state.learners == null || state.learners.length === 0) {
            enqueueSnackbar(t('learners.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- COMPANY -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('company.host')}<FieldRequired />
                            </b>
                            <Select
                                placeholder={t('company.host')}
                                value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* -------------------- SOFT TRAINING TYPE -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('soft.training.type')}<FieldRequired />
                            </b>
                            <SelectOutlined
                                value={state.soft_training_id}
                                onChange={e => setState({ ...state, soft_training_id: e.target.value })}
                            >
                                {state.soft_trainings.map(training_type => (
                                    <MenuItem key={training_type.id} value={training_type.id}>
                                        <span>{training_type.name}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                        </div>
                        {/* -------------------- TRAINERS -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">
                                {t('trainers.select')}<FieldRequired />
                            </b>
                            <SelectTrainers
                                state={state}
                                setState={setState}
                            />
                        </div>
                        {/* -------------------- LEARNERS -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">
                                {t('learners.select')}<FieldRequired />
                            </b>
                            <SelectLearners
                                state={state}
                                setState={setState}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ----------------------------------------- SELECT TRAINERS ------------------------------------------- \\
const SelectTrainers = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchMembers) {
            if (state.trainers_company_id) {
                getCompanyMembers(state.trainers_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({ ...state, trainerOptions: res.data.data.assessors.map(l => ({ value: l.id, label: l.full_name })) });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, trainerOptions: [] });
                    }
                });
            }
            else {
                setState({ ...state, trainerOptions: [] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.trainers_company_id]);

    return (
        <>
            {/* -------------------- COMPANY -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('company')}
                </span>
                <Select
                    placeholder={t('company')}
                    value={state.trainers_company_id ? state.companies.find(c => c.value === state.trainers_company_id) : null}
                    onChange={company => setState({ ...state, trainers_company_id: company ? company.value : null, authorizedRefetchMembers: true })}
                    options={state.companies}
                />
            </div>
            {/* -------------------- TRAINERS -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('trainers')}
                </span>
                <Select
                    placeholder={t('trainers')}
                    value={state.trainers}
                    onChange={trainers => setState({ ...state, trainers })}
                    options={state.trainerOptions}
                    isMulti
                />
            </div>
        </>
    );
};

// ----------------------------------------- SELECT LEARNERS ------------------------------------------- \\
const SelectLearners = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchMembers) {
            if (state.learners_company_id) {
                getCompanyMembers(state.learners_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({ ...state, learnerOptions: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })) });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, learnerOptions: [] });
                    }
                });
            }
            else {
                setState({ ...state, learnerOptions: [] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.learners_company_id]);

    return (
        <>
            {/* -------------------- COMPANY -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('company')}
                </span>
                <Select
                    placeholder={t('company')}
                    value={state.learners_company_id ? state.companies.find(c => c.value === state.learners_company_id) : null}
                    onChange={company => setState({ ...state, learners_company_id: company ? company.value : null, authorizedRefetchMembers: true })}
                    options={state.companies}
                />
            </div>
            {/* -------------------- LEARNERS -------------------- */}
            <div style={{ marginTop: 10 }}>
                <span>
                    {t('learners')}
                </span>
                <Select
                    placeholder={t('learners')}
                    value={state.learners}
                    onChange={learners => setState({ ...state, learners })}
                    options={state.learnerOptions}
                    isMulti
                />
            </div>
        </>
    );
};

export default SoftFormationFormPage1;
