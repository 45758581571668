import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { getSoftFormationsResources } from 'services/requests';

// Components
import Select from 'components/items/react-select';

// ------------------------------------ SELECT SKILLBASE ----------------------------------------- \\
const SelectSoftTraining = ({ softTrainingId, setSoftTrainingId }) => {
    const { t } = useTranslation();
    const [softTrainings, setSoftTrainings] = useState([]);

    useEffect(() => {
        getSoftFormationsResources().then(res => {
            if (res?.status === 200) {
                setSoftTrainings(res.data.data.soft_trainings.map(s => ({ value: s.id, label: s.name })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            value={softTrainingId ? softTrainings.find(c => c.value === softTrainingId) : null}
            onChange={softTraining => setSoftTrainingId(softTraining ? softTraining.value : null)}
            options={softTrainings}
            placeholder={t('soft.training.type')}
        />
    );
};

export default SelectSoftTraining;
