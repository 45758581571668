import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Material-UI
import { Grid, Button, ButtonGroup, Divider } from '@material-ui/core';
import { Description as IconSkillbase, BarChart as IconGraphic } from '@material-ui/icons';

// Services
import { getSkillbaseWithPositionForUser, getHardPositionsForUserBySkillbase, getSkillbase, getUser } from 'services/requests';
import { getTranslation } from 'services/helpers';

// Components
import SkillbasePositioning from './skillbase-positioning';
import GraphicPositioning from './graphic-positioning';
import HardPositionUserExport from './hardpositions-with-skillbase-export'
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// ------------------------------ HARD POSITIONS WITH SKILLBASE --------------------------------- \\
const HardPositionsWithSkillbase = () => {
    const { t } = useTranslation();
    const { userId } = useParams();

    const [state, setState] = useState({
        // Resources
        user: null,
        menu: 'skillbase',
        expanded: null,
        openExportPositions: false,

        // Skillbase
        skillbases: [],
        skillbase_id: null,
        skillbase: null,

        // Hard Positions
        loading: false,
        error: false,
        offset: 0,
        limit: 3,
        total_count: 0,
        hard_positions: [],
        competence_ids: [],
        knowledge_ids: [],
        skill_ids: [],
    });

    useEffect(() => {
        axios.all([getUser(userId), getSkillbaseWithPositionForUser(userId)])
        .then(axios.spread(function (res_user, res_skillbases) {
            if (res_user?.status === 200 && res_skillbases?.status === 200) {
                setState({
                    ...state,
                    user: res_user.data.data.user,
                    skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                });
            }
            else {
                console.log(res_user + res_skillbases);
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading && state.skillbase_id != null) {
            axios.all([getHardPositionsForUserBySkillbase(state.offset, state.limit, userId, state.skillbase_id), getSkillbase(state.skillbase_id)])
            .then(axios.spread(function (res_for_user_by_skillbase, res_skillbase) {
                if (res_for_user_by_skillbase?.status === 200 && res_skillbase?.status === 200) {

                    let hard_positions = res_for_user_by_skillbase.data.data.hard_positions;
                    let total_count = res_for_user_by_skillbase.data.data.total_count;

                    let competence_ids = [], knowledge_ids = [], skill_ids = [];
                    hard_positions.forEach((hard_position, index) => {

                        hard_position.competences.forEach(c => {
                            competence_ids.push({ unit_id: c.unit_id, id: c.id, mastery: c.mastery, index });
                        });

                        hard_position.knowledges.forEach(k => {
                            knowledge_ids.push({ unit_id: k.unit_id, id: k.id, mastery: k.mastery, index });
                        });

                        hard_position.skills.forEach(s => {
                            skill_ids.push({ unit_id: s.unit_id, id: s.id, mastery: s.mastery, index });
                        });
                    });

                    setState({
                        ...state,
                        loading: false,
                        error: false,
                        limit: (total_count < 3) ? total_count : state.limit,
                        total_count,
                        hard_positions,
                        skillbase: res_skillbase.data.data.skillbase,
                        expanded: null,
                        competence_ids,
                        knowledge_ids,
                        skill_ids,
                    });
                }
                else {
                    console.log(res_for_user_by_skillbase + res_skillbase);
                    setState({
                        ...state,
                        loading: false,
                        error: true,
                        offset: 0,
                        limit: 3,
                        total_count: 0,
                        hard_positions: [],
                        skillbase: null,
                        expanded: null,
                        competence_ids: [],
                        knowledge_ids: [],
                        skill_ids: [],
                    });
                }
            }));
        }
        if (state.loading && state.skillbase_id == null) {
            setState({
                ...state,
                loading: false,
                error: false,
                offset: 0,
                limit: 3,
                total_count: 0,
                hard_positions: [],
                skillbase: null,
                expanded: null,
                competence_ids: [],
                knowledge_ids: [],
                skill_ids: [],
            });
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* -------------------- SKILLBASE -------------------- */}
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <b className="input-title">{t('select.skillbase')}</b><FieldRequired />
                        <Select
                            placeholder={t('skillbase')}
                            value={state.skillbase_id ? state.skillbases.find(s => s.value === state.skillbase_id) : null}
                            onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null, loading: true, offset: 0, limit: 3 })}
                            options={state.skillbases}
                        />
                    </Grid>
                </Grid>
                <UserPositionsWithSkillbase
                    state={state}
                    setState={setState}
                />
            </div>
            {/* -------------------- EXPORT POSITIONS -------------------- */}
            {state.skillbase != null && (
                <>
                    <Divider />
                    <Grid container alignItems="center" justifyContent="center" style={{ padding: '15px 25px' }}>
                        <Button
                            onClick={() => setState({ ...state, openExportPositions: true })}
                            variant="contained"
                        >
                            {t('export.positions')}
                        </Button>
                    </Grid>
                </>
            )}
            {/* -------------------- EXPORT USER POSITIONS -------------------- */}
            {state.openExportPositions && (
                <HardPositionUserExport
                    onClose={() => setState({ ...state, openExportPositions: false })}
                    user={state.user}
                    skillbase={state.skillbase}
                />
            )}
        </>
    );
};

// -------------------------------------- SKILLBASE --------------------------------------------- \\
const UserPositionsWithSkillbase = ({ state, setState }) => {
    const { t } = useTranslation();

    if (state.loading) return <Spinner />;
    if (state.error) return <Error />;
    if (state.skillbase == null) return <></>;

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" style={{ padding: '24px 0px 12px 0px' }}>
                <Button
                    onClick={() => setState({ ...state, menu: state.menu === 'skillbase' ? 'graphic' : 'skillbase' })}
                    variant="outlined" size="small" color="primary"
                >
                    {state.menu === 'graphic' && (
                        <>
                            <IconSkillbase style={{ marginRight: 5, fontSize: 20 }} />
                            {t('skillbase')}
                        </>
                    )}
                    {state.menu === 'skillbase' && (
                        <>
                            <IconGraphic style={{ marginRight: 5, transform: 'rotate(90deg)' }} />
                            {t('graphic')}
                        </>
                    )}
                </Button>
                {state.total_count > 3 && (
                    <div>
                        <b className="input-title">
                            {t('compare.by')} :
                        </b>
                        <ButtonGroup size="small" style={{ marginLeft: 5 }}>
                            {[3, 4, 5].map(count => (
                                <Button
                                    key={count}
                                    onClick={() => setState({ ...state, offset: 0, limit: count, loading: true })}
                                    disabled={state.total_count < count}
                                    variant="contained"
                                    color={state.limit === count ? 'primary' : 'default'}
                                    style={{ boxShadow: 'none' }}
                                >
                                    {count}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </div>
                )}
            </Grid>
            {/* -------------------- SWITCH BETWEEN SKILLBASE & GRAPHIC -------------------- */}
            {state.menu === 'skillbase' && (
                <SkillbasePositioning
                    state={state}
                    setState={setState}
                />
            )}
            {state.menu === 'graphic' && (
                <GraphicPositioning
                    state={state}
                    setState={setState}
                />
            )}
        </>
    );
};

export default HardPositionsWithSkillbase;
