import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button, Grid, Accordion, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

// Services
import { getFormattedDate, cleaColor, cleaColor2 } from 'services/helpers';

// Style
import style from 'style';

// ---------------------------------------- DETAILS CLEA FORMATION --------------------------------------- \\
export const DetailsCleaFormation = ({ onClose, clea_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth maxWidth="lg">
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {t('clea.training.details')}
                </p>
                <Divider />
                {/* ---------------------- CLEA NAME ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: cleaColor }}>
                            {t('name')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{clea_formation.name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- COMPANY ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: cleaColor }}>
                            {t('company')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{clea_formation?.company?.name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------- CLEA FORMATION TYPE---------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: cleaColor }}>
                            {t('clea.training.type')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{clea_formation.clea_formation_type?.name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- DATE ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: cleaColor }}>
                            {t('date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {clea_formation.clea_sessions.map(session => (
                            <Fragment key={session.id}>
                                <span> {getFormattedDate(session.session_date) + ' : ' + t('from') + ' ' + moment(session.start, 'HH:mm:ss').format('HH:mm') + ' ' + t('to') + ' ' + moment(session.end, 'HH:mm:ss').format('HH:mm')}</span>
                                <br />
                            </Fragment>
                        ))}
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- TRAINERS ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: cleaColor }}>
                            {t('trainers')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {clea_formation.trainers.map(trainer => (
                            <div key={trainer.id} style={{ ...style.dF, ...style.aiC }}>
                                <span>
                                    {trainer.full_name}
                                    &nbsp;
                                    {trainer.company && '(' + trainer.company.name + ')'}
                                </span>
                            </div>
                        ))}
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- LEARNERS ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: cleaColor }}>
                            {t('learners')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {clea_formation.learners.map(learner => (
                            <div key={learner.id} style={{ ...style.dF, ...style.aiC }}>
                                <span>
                                    {learner.full_name}
                                    &nbsp;
                                    {learner.company && '(' + learner.company.name + ')'}
                                </span>
                            </div>
                        ))}
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- CLEA COMPETENCES ---------------------- */}
                <div style={{ marginTop: 24, backgroundColor: cleaColor, padding: 10 }}>
                    <span style={{ color: 'white' }}>{t('clea.competences')}</span>
                </div>
                <div>
                    {clea_formation.clea_competences.map(competence => (
                        <Accordion key={competence.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                style={{ fontSize: 14, backgroundColor: cleaColor2 }}
                            >
                                {competence.name}
                            </AccordionSummary>
                            <Divider />
                            <div style={{ padding: 24 }}>
                                <b className="input-title">{t('clea.domain')} : </b><span>{competence.clea_domain_name}</span>
                                <br/>
                                <b className="input-title">{t('clea.sub.domain')} : </b><span>{competence.clea_subdomain_name}</span>
                            </div>
                        </Accordion>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog> 
    );
};

// ----------------------------------------- CLEA LEARNERS MODAL ----------------------------------------- \\
export const CleaLearnersModal = ({ onClose, clea_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {clea_formation.name}
                </p>
                <span style={{ color: cleaColor }}>
                    {t('learners.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {clea_formation.learners.map(learner => (
                        <span key={learner.id}>
                            {learner.full_name}
                            &nbsp;
                            {learner.company_name && '(' + learner.company_name + ')'}
                            <br />
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ----------------------------------------- CLEA TRAINERS MODAL ----------------------------------------- \\
export const CleaTrainersModal = ({ onClose, clea_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {clea_formation.name}
                </p>
                <span style={{ color: cleaColor }}>
                    {t('trainers.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {clea_formation.trainers.map(trainer => (
                        <span key={trainer.id}>
                            {trainer.full_name}
                            &nbsp;
                            {trainer.company_name && '(' + trainer.company_name + ')'}
                            <br />
                        </span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
