import React, { useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid } from '@material-ui/core';

// Services
import { softskillsColor } from 'services/helpers';

// Components
import SoftSkillsMessage from 'components/common/soft-skills-message';
import SoftSkills from './softskills-skillbase';
import SoftPositions from './softpositions';
import SoftEvaluations from './softevaluations';
import SkillbasesWithSoftskills from './skillbases-with-softskills';
import AssessmentGrids from './assessment-grids';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------------- SOFT SKILLS MENU ROUTER ---------------------------------------- \\
const SoftSkillsMenuRouter = () => {
    const { t } = useTranslation();

    return (
        <>
            <BreadcrumbsItem to="/mobipass/softskills" className="breadcrumb-item">
                {t('softskills')}
            </BreadcrumbsItem>
            <Switch>
                <Route exact path="/mobipass/softskills">
                    <SoftSkillsMenu />
                </Route>
                <Route path="/mobipass/softskills/skillbase">
                    <SoftSkills />
                </Route>
                <Route path="/mobipass/softskills/positioning">
                    <SoftPositions />
                </Route>
                <Route path="/mobipass/softskills/evaluation">
                    <SoftEvaluations />
                </Route>
                <Route path="/mobipass/softskills/assessment-grids">
                    <AssessmentGrids />
                </Route>
                <Redirect from="*" to="/mobipass/softskills" />
            </Switch>
        </>
    );
};

// ------------------------------------------- SOFT SKILLS MENU -------------------------------------------- \\
const SoftSkillsMenu = () => {
    const { t } = useTranslation();
    const history = useHistory();
    
    const [state, setState] = useState({
        softMessageDislayed: false,
        openSkillbaseWithSoftSkills: false,
    });

    return (
        <>
            {/* -------------------- MESSAGE -------------------- */}
            <SoftSkillsMessage
                displayed={state.softMessageDislayed}
                setDisplayed={softMessageDislayed => setState({ ...state, softMessageDislayed })}
            />
            {/* -------------------- SOFT SKILLS MENU -------------------- */}
            <div className="full-container" style={{ ...style.mt15 }}>
                <div
                    className="soft-skill-menu"
                    onClick={() => history.push('/mobipass/softskills/skillbase')}
                >
                    {t('view.softskills.skillbase')}
                </div>
                <div
                    className="soft-skill-menu"
                    onClick={() => history.push('/mobipass/softskills/positioning')}
                    style={{ ...style.mt24 }}
                >
                    {t('initial.positioning')}
                </div>
                <div
                    className="soft-skill-menu"
                    onClick={() => history.push('/mobipass/softskills/evaluation')}
                    style={{ ...style.mt24 }}
                >
                    {t('evaluation')}
                </div>
                <div
                    className="soft-skill-menu"
                    onClick={() => history.push('/mobipass/evaluation')}
                    style={{ ...style.mt24 }}
                >
                    {t('evaluation.through.skillbase')}
                </div>
                <div
                    className="soft-skill-menu"
                    onClick={() => history.push('/mobipass/softskills/assessment-grids')}
                    style={{ ...style.mt24 }}
                >
                    {t('assessment.grids.generation')}
                </div>
                <Grid container alignItems="center" justifyContent="center" style={{ padding: 12 }}>
                    <FieldRequired /><span>{t('only.some.occupational.standards.linked.softskills')}</span>
                    <Button
                        onClick={() => setState({ ...state, openSkillbaseWithSoftSkills: true })}
                        variant="contained"
                        style={{ textTransform: 'none', marginLeft: 10, backgroundColor: softskillsColor, color: 'white' }}
                    >
                        {t('see.the.list')}
                    </Button>
                </Grid>
            </div>
            {/* -------------------- SKILL BASE LIST -------------------- */}
            {state.openSkillbaseWithSoftSkills && (
                <SkillbasesWithSoftskills
                    onClose={() => setState({ ...state, openSkillbaseWithSoftSkills: false })}
                />
            )}
        </>
    );
};

export default SoftSkillsMenuRouter;
