import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

// Material-UI
import { Grid, IconButton, Divider, Button } from '@material-ui/core';
import { Search as IconSearch, Cancel as IconCancel } from '@material-ui/icons';

// Services
import { getLearners } from 'services/requests';
import { cleaColor, getFormattedDate } from 'services/helpers';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import CleaLearnerCourse from './clea-learner-course';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';

// Style
import style from 'style';

// ---------------------------------- CLEA COURSE ROUTER ---------------------------------------- \\
const CleaCourseRouter = () => {
    const storedUser = getStoredUser();
    const LEARNER_ID = storedUser?.id;
    const ROLE_ID = storedUser?.role?.id;

    return (
        <Switch>
            {ROLE_ID < 5 && (
                <Route exact path="/mobipass/clea/course">
                    <CleaCourses />
                </Route>
            )}
            <Route path="/mobipass/clea/course/:learnerId">
                <CleaLearnerCourse />
            </Route>
            {ROLE_ID < 5 ? (
                <Redirect to="/mobipass/clea/course" />
            ) : (
                <Redirect to={`/mobipass/clea/course/${LEARNER_ID}`} />
            )}
        </Switch>
    );
};

// ------------------------------------- CLEA COURSES ------------------------------------------- \\
const CleaCourses = () => {
    const history = useHistory();
    const storedFilters = JSON.parse(sessionStorage.getItem('clea-courses-filters'));
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        learners: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        last_name: storedFilters?.last_name || '',
        first_name: storedFilters?.first_name || '',
        company_id: storedFilters?.company_id || null,
    });
    const total_page = state.offset + state.limit > state.total_count ?
    state.total_count : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('clea-courses-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            last_name: '',
            first_name: '',
            company_id: null,
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('clea-courses-filters', JSON.stringify({
                last_name: state.last_name,
                first_name: state.first_name,
                company_id: state.company_id,
            }));

            getLearners(
                state.offset,
                state.limit,
                state.last_name,
                state.first_name,
                state.company_id,
            ).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        learners: res.data.data.users,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push('/mobipass/clea')}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: cleaColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('last.name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('first.name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('clea.course.start')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('clea.course.end')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('learners')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('last.name')}
                                value={state.last_name}
                                onChange={e => setState({ ...state, last_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('first.name')}
                                value={state.first_name}
                                onChange={e => setState({ ...state, first_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} />
                        <Grid item md={2} />
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- LEARNER LIST -------------------- */}
                <LearnerList
                    loading={state.loading}
                    error={state.error}
                    learners={state.learners}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// ------------------------------------- LEARNER LIST ------------------------------------------- \\
const LearnerList = ({ loading, error, learners }) => {
    const history = useHistory();
    const { t } = useTranslation();

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="full-container-content">
            {learners.length > 0 ? (
                learners.map(learner => (
                    <Fragment key={learner.id}>
                        <Grid
                            container
                            onClick={() => history.push(`/mobipass/clea/course/${learner.id}`)}
                            className="list-item-hover"
                        >
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{learner.last_name}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{learner.first_name}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{learner.company?.name}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{getFormattedDate(learner?.clea_start_date)}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{getFormattedDate(learner?.clea_end_date)}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

export default CleaCourseRouter;
