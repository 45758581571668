import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Material-UI
import { Grid, Button, Divider, IconButton } from '@material-ui/core';
import { AddCircle as IconAdd, Delete as IconDelete, Create as IconUpdate } from '@material-ui/icons';

// Services
import { servicesColor, getTranslation } from 'services/helpers';

// Components
import { CreateSoftSkill, UpdateSoftSkill, DeleteSoftSkill } from './softskill-modals';
import Tooltip from '../../items/tooltip';

// Style
import style from 'style';

// ------------------------------------- SOFTSKILL LIST ------------------------------------- \\
const SoftSkillList = ({ category, refetch }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();  

    const [state, setState] = useState({
        softskill: undefined,
        openCreateSoftSkill: false,
        openUpdateSoftSkill: false,
        openDeleteSoftSkill: false,
    });

    function handleOpenUpdate(event, softskill) {
        event.stopPropagation();
        setState({ ...state, openUpdateSoftSkill: true, softskill });
    }

    function handleOpenDelete(event, softskill) {
        event.stopPropagation();
        setState({ ...state, openDeleteSoftSkill: true, softskill });
    }

    return (
        <>
            <div style={style.p24}>
                {/* -------------------- LIST HEADER -------------------- */}
                <Grid style={{ ...style.p10, backgroundColor: servicesColor  }}>
                    <span style={style.cW}>{t('softskills')}</span>
                </Grid>
                {/* -------------------- SOFTSKILL LIST -------------------- */}
                {category.softskills.length > 0 ? (
                    category.softskills.map(softskill => (
                        <Fragment key={softskill.id}>
                            <Grid
                                onClick={() => history.push(`${match.path}/${softskill.id}`)}
                                container alignItems="center"
                                className="list-item-hover"
                            >
                                <Grid item xs={12} md={10} style={style.p10}>
                                    <span>{category.position}.{softskill.position}  {getTranslation('name', softskill)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} container justifyContent="center">
                                    <Tooltip title={t('edit')}
                                        item={(
                                            <IconButton
                                                onClick={e => handleOpenUpdate(e, softskill)}
                                                style={style.p6}
                                            >
                                                <IconUpdate />
                                            </IconButton>
                                        )}
                                    />
                                    <Tooltip title={t('delete')}
                                        item={(
                                            <IconButton
                                                onClick={e => handleOpenDelete(e, softskill)}
                                                style={{ ...style.p6, ...style.ml5 }}
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <div style={style.p10}>
                            <span>{t('none')}</span>
                        </div>
                        <Divider />
                    </>
                )}
                {/* -------------------- ADD SOFTSKILL -------------------- */}
                <Button
                    onClick={() => setState({ ...state, openCreateSoftSkill: true })}
                    variant="contained"
                    style={style.mt10}
                >
                    <IconAdd />
                    <span style={style.ml5}>{t('softskill.add')}</span>
                </Button>
            </div>
            {/* -------------------- CREATE SOFTSKILL -------------------- */}
            {state.openCreateSoftSkill && (
                <CreateSoftSkill
                    onClose={() => setState({ ...state, openCreateSoftSkill: false })}
                    refetch={refetch}
                    category={category}
                />
            )}
            {/* -------------------- UPDATE SOFTSKILL -------------------- */}
            {state.openUpdateSoftSkill && (
                <UpdateSoftSkill
                    onClose={() => setState({ ...state, openUpdateSoftSkill: false })}
                    refetch={refetch}
                    softskill={state.softskill}
                />
            )}
            {/* -------------------- DELETE SOFTSKILL -------------------- */}
            {state.openDeleteSoftSkill && (
                <DeleteSoftSkill
                    onClose={() => setState({ ...state, openDeleteSoftSkill: false })}
                    refetch={refetch}
                    softskill={state.softskill}
                />
            )}
        </>
    );
};

export default SoftSkillList;
