import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Dialog, DialogContent, DialogActions, Button, Divider } from '@material-ui/core';

// Services
import { getTranslation, mobipassColor } from 'services/helpers';

// Style
import style from 'style';

const styles = {
    border: {
        borderWidth: '0 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        padding: 10,
    },
};

// ------------------------------------ LEARNING OUTCOME MODAL ------------------------------------ \\
const LearningOutcomeModal = ({ onClose, learning }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                {Object.keys(learning).length > 0 && (
                    <>
                        <p className="dialog-title">
                            {getTranslation('name', learning)}
                        </p>
                        <div style={{ backgroundColor: mobipassColor, ...style.p10 }}>
                            <span style={style.cW}>{t('competence')}</span>
                        </div>
                        {learning.competences.length > 0 ? (
                            learning.competences.map(competence => (
                                <div key={competence.id} style={styles.border}>
                                    <span>{competence.numerotation + t('competence.numerotation')}&nbsp;{getTranslation('name', competence)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                        <div style={{ backgroundColor: mobipassColor, ...style.p10, ...style.mt15 }}>
                            <span style={style.cW}>{t('knowledge')}</span>
                        </div>
                        {learning.knowledges.length > 0 ? (
                            learning.knowledges.map(knowledge => (
                                <div key={knowledge.id} style={styles.border}>
                                    <span>{knowledge.numerotation + t('knowledge.numerotation')}&nbsp;{getTranslation('name', knowledge)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                        <div style={{ backgroundColor: mobipassColor, ...style.p10, ...style.mt15 }}>
                            <span style={style.cW}>{t('skill')}</span>
                        </div>
                        {learning.skills.length > 0 ? (
                            learning.skills.map(skill => (
                                <div key={skill.id} style={styles.border}>
                                    <span>{skill.numerotation + t('skill.numerotation')}&nbsp;{getTranslation('name', skill)}</span>
                                </div>
                            ))
                        ) : (
                            <div style={styles.border}>
                                <span>{t('none')}</span>
                            </div>
                        )}
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LearningOutcomeModal;
