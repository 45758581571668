import React, { useState, useEffect, Fragment } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid, Divider, IconButton } from '@material-ui/core';
import { Cancel as IconCancel, Search as IconSearch, Loupe as IconMore, Delete as IconDelete, Create as IconEdit, Assignment as IconDetail } from '@material-ui/icons';

// Services
import { getAllSoftFormations } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateSoftEvaluation from './soft-formation-create';
import UpdateSoftEvaluation from './soft-formation-update';
import DeleteSoftFormation from './soft-formation-delete';
import DetailsSoftFormation from './soft-formation-details';
import { SoftFormationTrainersModal, SoftFormationLearnersModal } from './soft-formation-modals';
import SoftSkillsMessage from 'components/common/soft-skills-message';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';
import SelectSoftTraining from 'components/select/select-soft-training';
import SelectSoftSkill from 'components/select/select-soft-skill';
 
// Style
import style from 'style';

// ------------------------------------------ ROUTER ---------------------------------------------------- \\
const SoftFormationsRouter = () => {
    const { t } = useTranslation();
    const match = useRouteMatch();

    return (
        <>
            {/* -------------------- BREADCRUMB -------------------- */}
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('soft.formation')}
            </BreadcrumbsItem>
            {/* -------------------- ROUTER -------------------- */}
            <Switch>
                <Route exact path={`${match.path}`}>
                    <SoftFormations />
                </Route>
                <Route path={`${match.path}/create`}>
                    <CreateSoftEvaluation />
                </Route>
                <Route path={`${match.path}/:softFormationId`}>
                    <UpdateSoftEvaluation />
                </Route>
            </Switch>
        </>
    );
};

// -------------------------------------- SOFT FORMATIONS ----------------------------------------------- \\
const SoftFormations = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch();
    const { mediaMD } = useMediaQueries();
    const storedFilters = JSON.parse(sessionStorage.getItem('softformations-filters'));
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        offset: 0,
        limit: 30,
        total_count: 0,
        soft_formations: [],
        soft_categories: [],
        company_id: storedFilters?.company_id || null,
        trainer_name: storedFilters?.trainer_name || '',
        learner_name: storedFilters?.learner_name || '',
        soft_training_id: storedFilters?.soft_training_id || null,
        soft_skill_id: storedFilters?.soft_skill_id || null,
        softMessageDisplayed: false,
    });

    const total_page = state.offset + state.limit > state.total_count
        ? state.total_count
        : state.offset + state.limit;

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        sessionStorage.removeItem('softformations-filters');
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            limit: 30,
            company_id: null,
            soft_training_id: null,
            soft_skill_id: null,
            trainer_name: '',
            learner_name: '',
        });
    };

    useEffect(() => {
        if (state.loading) {

            sessionStorage.setItem('softformations-filters', JSON.stringify({
                company_id: state.company_id,
                soft_training_id: state.soft_training_id,
                soft_skill_id: state.soft_skill_id,
                trainer_name: state.trainer_name,
                learner_name: state.learner_name,
            }));

            getAllSoftFormations({
                offset: state.offset,
                limit: state.limit,
                company_id: state.company_id,
                soft_training_id: state.soft_training_id,
                softskill_id: state.soft_skill_id,
                learner: state.learner_name,
                trainer: state.trainer_name,
            }).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        soft_formations: res.data.data.soft_formations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- MESSAGE -------------------- */}
            <SoftSkillsMessage
                displayed={state.softMessageDisplayed}
                setDisplayed={softMessageDisplayed => setState({ ...state, softMessageDisplayed })}
            />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid
                    item xs={12} md={4} 
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    {ROLE_ID < 5 && (
                        <Button
                            onClick={() => history.push(`${match.path}/create`)}
                            variant="contained"
                        >
                            {t('add')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} md={4} container justifyContent="center">
                    <Pagination
                        limit={state.limit}
                        total_count={state.total_count}
                        setOffset={offset => setState({ ...state, loading: true, offset })}
                    />
                </Grid>
            </Grid>
            <div className="full-container">
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: softskillsColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('soft.training.type')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('soft.skill')} + {t('level')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('trainer')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('learner')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('soft.formation')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectSoftTraining
                                softTrainingId={state.soft_training_id}
                                setSoftTrainingId={soft_training_id => onSelectSearch('soft_training_id', soft_training_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectSoftSkill
                                softSkillId={state.soft_skill_id}
                                setSoftSkillId={soft_skill_id => onSelectSearch('soft_skill_id', soft_skill_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('trainer')}
                                value={state.trainer_name}
                                onChange={e => setState({ ...state, trainer_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('learner')}
                                value={state.learner_name}
                                onChange={e => setState({ ...state, learner_name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- SOFT FORMATION LIST -------------------- */}
                <SoftFormationList
                    ROLE_ID={ROLE_ID}
                    soft_formations={state.soft_formations}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            <Divider />
            {/* -------------------- TOTAL COUNT -------------------- */}
            <Grid container justifyContent="space-between" style={{ padding: '12px 24px' }}>
                <span style={style.fwB}>
                    {state.total_count > 0 && (
                        <>{state.offset + 1} / {total_page}</>
                    )}
                </span>
                <span style={style.fwB}>
                    {state.total_count} {t('results')}
                </span>
            </Grid>
        </>
    );
};

// ------------------------------------- SOFT FORMATION LIST -------------------------------------------- \\
const SoftFormationList = ({ ROLE_ID, loading, error, refetch, soft_formations }) => {
    const { t } = useTranslation();
    const match = useRouteMatch();
    const history = useHistory();
    const USER_ID = getStoredUser()?.id;

    const [state, setState] = useState({
        soft_formation: null,
        openDeleteSoftFormation: false,
        openDetailsSoftFormation: false,
        openSoftFormationLearnersModal: false,
        openSoftFormationTrainersModal: false,
    });

    function handleRefetch() {
        refetch();
        setState({ ...state, openDeleteSoftFormation: false });
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {soft_formations.length > 0 ? (
                    soft_formations.map(soft_formation => (
                        <Fragment key={soft_formation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{soft_formation.company.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{soft_formation.soft_training.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{soft_formation.softskill.name + " - " + t('level') + " " + soft_formation.soft_level.level}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {soft_formation.trainers.length === 1 ? (
                                        <span>
                                            {soft_formation.trainers[0].full_name}
                                        </span>
                                    ) : (
                                        <>
                                            <span>{soft_formation.trainers.length}</span>
                                            <Tooltip title={t('learners.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openSoftFormationTrainersModal: true, soft_formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {soft_formation.learners.length === 1 ? (
                                        <span>
                                            {soft_formation.learners[0].full_name}
                                        </span>
                                    ) : (
                                        <>
                                            <span>{soft_formation.learners.length}</span>
                                            <Tooltip title={t('learners.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openSoftFormationLearnersModal: true, soft_formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={(
                                            <IconButton
                                                className="list-btn"
                                                onClick={() => setState({ ...state, openDetailsSoftFormation: true, soft_formation })}
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        )}
                                    />
                                    {(ROLE_ID <= 4 || USER_ID === soft_formation.creator_id) && (
                                        <>
                                            <Tooltip title={t('edit')}
                                                item={(
                                                    <IconButton
                                                        className="list-btn"
                                                        onClick={() => history.push(`${match.path}/${soft_formation.id}`)}
                                                    >
                                                        <IconEdit />
                                                    </IconButton>
                                                )}
                                            />
                                            <Tooltip title={t('delete')}
                                                item={(
                                                    <IconButton
                                                        className="list-btn"
                                                        onClick={() => setState({ ...state, openDeleteSoftFormation: true, soft_formation })}
                                                    >
                                                        <IconDelete />
                                                    </IconButton>
                                                )}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <> 
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>  
                )}
            </div>
            {/* -------------------- DELETE SOFT EVALUATION -------------------- */}
            {state.openDeleteSoftFormation && (
                <DeleteSoftFormation
                    open={state.openDeleteSoftFormation}
                    onClose={() => setState({ ...state, openDeleteSoftFormation: false })}
                    refetch={() => handleRefetch()}
                    soft_formation={state.soft_formation}
                />
            )}
            {/* -------------------- DETAILS SOFT EVALUATION -------------------- */}
            {state.openDetailsSoftFormation && (
                <DetailsSoftFormation
                    open={state.openDetailsSoftFormation}
                    onClose={() => setState({ ...state, openDetailsSoftFormation: false })}
                    soft_formation={state.soft_formation}
                />
            )}
            {/* -------------------- LEARNERS MODAL -------------------- */}
            {state.openSoftFormationLearnersModal && (
                <SoftFormationLearnersModal
                    open={state.openSoftFormationLearnersModal}
                    onClose={() => setState({ ...state, openSoftFormationLearnersModal: false })}
                    soft_formation={state.soft_formation}
                />
            )}
            {/* -------------------- TRAINERS MODAL -------------------- */}
            {state.openSoftFormationTrainersModal && (
                <SoftFormationTrainersModal
                    open={state.openSoftFormationTrainersModal}
                    onClose={() => setState({ ...state, openSoftFormationTrainersModal: false })}
                    soft_formation={state.soft_formation}
                />
            )}
        </>
    );
};

export default SoftFormationsRouter;
