import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { languages } from 'services/constants';

// Components
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ---------------------------------------- NEWS FORM PAGE 1 ---------------------------------------- \\
const NewsFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    function handleTitles(event, code) {
        let titles = state.titles;
        titles[code] = event.target.value;
        setState({ ...state, titles });
    }

    function navigateToPage2() {
        if (Object.values(state.titles).every(title => title === '')) {
            enqueueSnackbar(t('news.enter.one'), { variant: 'warning' });
        }
        else {
            let disabled = [];
            for (const [key, value] of Object.entries(state.titles)) {
                if (value === '') disabled.push(key);
            }
            setState({ ...state, page: 2, disabled });
        }
    }

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, paddingTop: 24 }}>
                {/* -------------------- TITLES -------------------- */}
                <div style={{...style.taC, marginTop: 10 }}>
                    <span style={style.fwB}>{t('news.title')}</span>
                </div>
                {languages.map(language => (
                    <InputTextGrid
                        key={language.id}
                        placeholder={t(language.label)}
                        value={state.titles[language.code]}
                        onChange={e => handleTitles(e, language.code)}
                    />
                ))}
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4}/>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default NewsFormPage1;