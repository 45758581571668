import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';

// File-Saver
import { saveAs } from 'file-saver';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Divider, IconButton } from '@material-ui/core';
import { Delete as IconDelete, Edit as IconEdit, InsertDriveFile as IconCertif } from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { fileUrl, getLabelFromArray, getFileExtension, cleaColor } from 'services/helpers';
import { cleaEvaluationTypes } from 'services/constants';

// Components
import CleaEvaluationDelete from '../clea-evaluation/clea-evaluation-delete';
import Tooltip from 'components/items/tooltip';

// ----------------------------------- CLEA LEARNER EVALUATIONS --------------------------------------- \\
const CleaLearnerEvaluations = ({ refetch, clea_evaluations }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const ROLE_ID = getStoredUser()?.role?.id;

    const [state, setState] = useState({
        evaluation: null,
        openDelete: false,
    });

    const evaluationCanBeDeleted = evaluation => {
        if (clea_evaluations.some(e => e.evaluation_type > evaluation.evaluation_type)) {
            return false;
        }
        return true;
    };

    return (
        <>
            {clea_evaluations.map(evaluation => (
                <Fragment key={evaluation.id}>
                    {/* -------------------- CLEA EVALUATION INFOS -------------------- */}
                    <Grid container alignItems="center" justifyContent="space-between" style={{ padding: 24 }}>
                        <div style={{ paddingRight: 5 }}>
                            <b className="input-title" style={{ fontSize: 14 }}>
                                {t(getLabelFromArray(cleaEvaluationTypes, evaluation.evaluation_type?.toString()))} {t('of')} {moment(evaluation.evaluation_date).format('DD/MM/YYYY')}
                            </b>
                            <br />
                            <br />
                            <span><b>• {t('company')} : </b>{evaluation.company?.name}</span>
                            <br />
                            <span><b>• {t('assessor')} : </b>{evaluation.assessor}</span>
                        </div>
                        <div style={{ paddingLeft: 5 }}>
                            <Tooltip title={t('clea.competences.selected')}
                                item={
                                    <div
                                        onClick={() => history.push(`${match.url}/competences-details/${evaluation.id}`)}
                                        className="clea-icon-button"
                                    >
                                        {evaluation.clea_competences?.length}
                                    </div>
                                }
                            />
                        </div>
                    </Grid>
                    {/* -------------------- CLEA EVALUATION CREATOR & CRUD -------------------- */}
                    <Grid container alignItems="center" justifyContent="space-between" style={{ padding: '0px 24px 24px 24px' }}>
                        <div style={{ paddingRight: 5 }}>
                            <b className="input-title" style={{ color: cleaColor, fontStyle: 'italic' }}>
                                {t('clea.creation.date')} {moment(evaluation.position_date).format('DD/MM/YYYY')}
                                {evaluation.creator != null && (
                                    <>
                                        {t('by')} {evaluation.creator?.full_name}
                                    </>
                                )}
                            </b>
                            {evaluation.last_modifier != null && (
                                <>
                                    <br />
                                    <b className="input-title" style={{ color: cleaColor, fontStyle: 'italic' }}>
                                        {t('clea.last.modification')} {moment(evaluation.updated_at).format('DD/MM/YYYY')} {t('by')} {evaluation.last_modifier.full_name}
                                    </b>
                                </>
                            )}
                        </div>
                        <div style={{ paddingLeft: 5 }}>
                            {evaluation.certificate_url != null && (
                                <Tooltip title={t('clea.view.certificate')}
                                    item={
                                        <IconButton
                                            onClick={() => saveAs(fileUrl + evaluation.certificate_url, t('leaflet') + ' - ' + t('clea.evaluation') + getFileExtension(evaluation.certificate_url)?.toLowerCase())}
                                            style={{ padding: 8 }}
                                        >
                                            <IconCertif />
                                        </IconButton>
                                    }
                                />
                            )}
                            {ROLE_ID < 5 && (
                                <>
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={() => history.push(`${match.url}/evaluation/update/${evaluation.evaluation_type}/id/${evaluation.id}`)}
                                                style={{ padding: 8 }}
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        }
                                    />
                                    {evaluationCanBeDeleted(evaluation) && (
                                        <Tooltip title={t('delete')}
                                            item={
                                                <IconButton
                                                    onClick={() => setState({ ...state, openDelete: true, evaluation })}
                                                    style={{ padding: 8 }}
                                                >
                                                    <IconDelete />
                                                </IconButton>
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                    <Divider />
                </Fragment>
            ))}
            {/* -------------------- DELETE -------------------- */}
            {state.openDelete && (
                <CleaEvaluationDelete
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={refetch}
                    evaluation={state.evaluation}
                />
            )}
        </>
    );
};

export default CleaLearnerEvaluations;
