import React, { useState } from 'react';
import { Editor, RichUtils } from 'draft-js';
import { useTranslation } from 'react-i18next';

// DraftJS
import 'draft-js/dist/Draft.css';

// Material-UI
import { ButtonGroup, Button, Grid } from '@material-ui/core';
import { 
    FormatBold as BoldIcon, FormatItalic as ItalicIcon, FormatUnderlined as UnderLineIcon,
    Title as TitleIcon, FormatListBulleted as UlIcon, FormatListNumbered as OlIcon
} from '@material-ui/icons';

// Style
import style from 'style';

// ---------------------------------------- COMMENT TEXT EDITOR ---------------------------------------- \\
const CommentTextEditor = ({ label, editorState, setEditorState }) => {
    const { t } = useTranslation();
    const [blockType, setBlockType] = useState('')

    const editor = React.useRef(null);
    function focusEditor() {
        editor.current.focus();
    }

    function onBlockTypeClick(type) {
		setEditorState(RichUtils.toggleBlockType(editorState, type));
        blockType === type ? setBlockType('') : setBlockType(type);
	}

    function onInlineStyleClick(style) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }

    return (
        <Grid container alignItems="center">
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <div style={{marginBottom: 10}}>
                        <ButtonGroup color="primary">
                            <Button
                                value={'header-six'}
                                variant={blockType === 'header-six' ? 'contained' : null}
                                onClick={event => onBlockTypeClick(event.currentTarget.value)}
                            >
                                <TitleIcon style={style.fs16} />
                            </Button>
                            <Button
                                value={'unordered-list-item'}
                                variant={blockType === 'unordered-list-item' ? 'contained' : null}
                                onClick={event => onBlockTypeClick(event.currentTarget.value)}
                            >
                                <UlIcon style={style.fs16} />
                            </Button>
                            <Button
                                value={'ordered-list-item'}
                                variant={blockType === 'ordered-list-item' ? 'contained' : null}
                                onClick={event => onBlockTypeClick(event.currentTarget.value)}
                            >
                                <OlIcon style={style.fs16} />
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup color="primary" style={{ marginLeft: 10 }}>
                            <Button 
                                onClick={() => onInlineStyleClick("UNDERLINE")}
                            >
                                <UnderLineIcon style={style.fs16} />
                            </Button>
                            <Button 
                                onClick={() => onInlineStyleClick("BOLD")}
                            >
                                <BoldIcon style={style.fs16}/>
                            </Button>
                            <Button 
                                onClick={() => onInlineStyleClick("ITALIC")}
                            >
                                <ItalicIcon style={style.fs16}/>
                            </Button>
                        </ButtonGroup>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div
                        style={{ border: '1px solid lightgray', borderRadius: 5,  minHeight: '6em', cursor: 'text', zIndex: 0 }}
                        onClick={focusEditor}
                    >
                        <Editor
                            ref={editor}
                            editorState={editorState}
                            onChange={setEditorState}
                            placeholder={t(label)}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
        
    );
};

export default CommentTextEditor;

