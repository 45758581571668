import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

// Moment
import moment from 'moment';

// Services
import { logActivity, getHasAccesses } from 'services/requests';
import {
    isAuthenticated, getStoredUser, cookieDisplayed, getLogActivity, setLogActivity,
    getSoftskillsAccess, setSoftskillsAccess, getCleaAccess, setCleaAccess,
} from 'services/storage';
import { theme } from 'services/helpers';

// Components
import Home from 'components/home';
import Mobipass from 'components/mobipass';
import Mobitrain from 'components/mobitrain';
import Mobitools from 'components/mobitools';
import Management from 'components/management';
import Dashboard from 'components/dashboard';
import Directory from 'components/directory';
import CookieMessage from 'components/common/cookie-message';
import News from 'components/news';
import Support from 'components/support';
import EmailConfirmed from 'components/common/email-confirmed';
import ResetPassword from 'components/common/reset-password';
import LearnerAccessRequest from 'components/management/access-requests/learner';

// -------------------------------------------- APP ---------------------------------------------- \\
const App = () => {
    const location = useLocation();
    const userId = getStoredUser()?.id;

    // Log Activity
    useEffect(() => {
        if (isAuthenticated() && (getLogActivity() !== moment().format('YYYY-MM-DD'))) {
            setLogActivity();
            logActivity();
        }
        if (isAuthenticated() && (getSoftskillsAccess() == null || getCleaAccess() == null)) {
            getHasAccesses(userId).then(res => {
                if (res?.status === 200) {
                    setSoftskillsAccess((res.data.data.has_softskills_access === true) ? '1' : '0');
                    setCleaAccess((res.data.data.has_clea_access === true) ? '1' : '0');
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className="app">
            {(isAuthenticated() && !cookieDisplayed() && theme !== 'mobirhin') && (
                <CookieMessage />
            )}
            <Switch>
                <Route exact path="/home">
                    <Home />
                </Route>
                <PrivateRoute exact path="/dashboard">
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/mobipass">
                    <Mobipass />
                </PrivateRoute>
                <PrivateRoute path="/mobitrain">
                    <Mobitrain />
                </PrivateRoute>
                <PrivateRoute path="/mobitools">
                    <Mobitools />
                </PrivateRoute>
                {getStoredUser()?.role?.id < 5 && (
                    <PrivateRoute path="/management">
                        <Management />
                    </PrivateRoute>
                )}
                <PrivateRoute exact path="/news">
                    <News />
                </PrivateRoute>
                <PrivateLearnerRoute path="/access-requests">
                    <LearnerAccessRequest />
                </PrivateLearnerRoute>
                <PrivateRoute exact path="/directory">
                    <Directory />
                </PrivateRoute>
                <PrivateRoute exact path="/support">
                    <Support />
                </PrivateRoute>
                <Route path="/reset-password">
                    <ResetPassword />
                </Route>
                <Route exact path="/email-confirmed">
                    <EmailConfirmed />
                </Route>
                <Redirect from="*" to="/home" />
            </Switch>
        </div>
    );
};

// ---------------------------------------- PRIVATE ROUTE ---------------------------------------- \\
function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated() ? (
                    children
                ) : (
                    <Redirect to={{ pathname: '/home', state: { from: location } }} />
                )
            }
        />
    );
};

// ------------------------------------ PRIVATE LEARNER ROUTE ------------------------------------ \\
function PrivateLearnerRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (isAuthenticated() && getStoredUser()?.role?.id === 5 && getStoredUser()?.company == null) ? (
                    children
                ) : (
                    <Redirect to={{ pathname: '/home', state: { from: location } }} />
                )
            }
        />
    );
};

export default App;
