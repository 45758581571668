import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Button, Divider } from '@material-ui/core';
import { SaveAlt as ExportIcon } from '@material-ui/icons';

// Services
import { getStoredUser } from 'services/storage';
import { getCompanyList, getCompanyMembers, getEvaluationsForUser } from 'services/requests';
import { getFormattedDate, getTranslation } from 'services/helpers';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ----------------------------------- EXPORT INDIVIDUAL EVALUATION ------------------------------------ \\
const ExportIndividualEvaluation = ({ onClose }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();
    const COMPANY_ID = (storedUser && storedUser.company) ? storedUser.company.id : null;

    const [state, setState] = useState({
        loading: true,
        loadingExport: false,
        error: false,
        companies: [],
        company_id: null,
        learners: [],
        learner_id: null,
    });

    const exportExcelFile = () => {
        if (state.learner_id == null) {
            enqueueSnackbar(t('learner.select.please'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loadingExport: true });
        }
    };

    useEffect(() => {
        getCompanyList().then(res => {
            if (res?.status === 200) {
                setState({
                    ...state,
                    loading: false,
                    companies: res.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                    company_id: COMPANY_ID,
                });
            }
            else {
                setState({ ...state, loading: false, error: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.company_id) {
            getCompanyMembers(state.company_id).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        learners: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        learner_id: null,
                    });
                }
                else {
                    console.log(res);
                }
            });
        }
        else {
            setState({ ...state, learners: [], learner_id: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company_id]);

    useEffect(() => {
        if (state.loadingExport) {
            getEvaluationsForUser(state.learner_id).then(res => {
                if (res?.status === 200) {

                    const evaluations = res.data.data.evaluations;
                    if (evaluations.length === 0) {
                        enqueueSnackbar(t('no.data.to.export.found'), { variant: 'warning' });
                        setState({ ...state, loadingExport: false });
                    }
                    else {
                        // Create Excel File
                        var workbook = new ExcelJS.Workbook();

                        // Add Worksheet to the Workbook
                        var worksheet = workbook.addWorksheet('Évaluations');

                        // Header
                        worksheet.columns = [
                            { header: 'Nom', key: 'name', width: 35 },
                            { header: 'Date', key: 'date', width: 15 },
                            { header: 'Organisme', key: 'company', width: 25 },
                            { header: 'Évaluateur', key: 'assessor', width: 25 },
                            { header: 'Apprenant', key: 'learner', width: 25 },
                            { header: 'Référentiel', key: 'skillbase', width: 25 },
                            { header: 'Infos supplémentaires', key: 'details', width: 25 },
                            { header: 'Acquis d\'apprentissage validés', key: 'learning_validated', width: 40 },
                            { header: 'Acquis d\'apprentissage non validés', key: 'learning_failed', width: 40 },
                            { header: 'Commentaire', key: 'comment', width: 25 }
                        ];
                        worksheet.getRow(1).font = { bold: true };

                        // Rows
                        for (let i = 0; i < evaluations.length; i += 1) {

                            let learnings_validated = '';
                            let learnings_failed = '';

                            evaluations[i].learning_outcomes.forEach(learning => {
                                let portfolio = evaluations[i].portfolios.find(p => p.learning_outcome_id === learning.id);
                                if (portfolio.validated === 0) {
                                    learnings_failed = learnings_failed + getTranslation('name', learning) + '\r\n';
                                }
                                else {
                                    learnings_validated = learnings_validated + getTranslation('name', learning) + '\r\n';
                                }
                            });

                            worksheet.addRow({
                                name: evaluations[i].name,
                                date: getFormattedDate(evaluations[i].evaluation_date),
                                company: evaluations[i].company.name,
                                assessor: evaluations[i].assessor.full_name,
                                learner: evaluations[i].users.find(u => u.id === state.learner_id).full_name,
                                skillbase: getTranslation('name', evaluations[i].skillbase),
                                details: evaluations[i].details,
                                learning_validated: learnings_validated,
                                learning_failed: learnings_failed,
                                comment: evaluations[i].comment,
                            });
                        }

                        // Save Excel File
                        const learner = state.learners.find(l => l.value === state.learner_id);
                        workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), learner?.label + '_evaluations.xlsx'));

                        // Close Form
                        onClose();
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingExport: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingExport]);

    return (
        <Dialog open fullWidth>
            {(state.loading || state.loadingExport) ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('export.learner.evaluations')}
                        </p>
                        {/* ---------------------- COMPANY ---------------------- */}
                        <div>
                            <b className="input-title">{t('company')}</b>
                            <Select
                                placeholder={t('company')}
                                value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                options={state.companies}
                            />
                        </div>
                        {/* ---------------------- LEARNERS ---------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('learner')}</b><FieldRequired/>
                            <Select
                                placeholder={t('learner')}
                                value={state.learner_id ? state.learners.find(c => c.value === state.learner_id) : null}
                                onChange={learner => setState({ ...state, learner_id: learner ? learner.value : null })}
                                options={state.learners}
                            />
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={exportExcelFile} variant="contained" color="primary">
                            {t('export')}
                            <ExportIcon style={{ marginLeft: 5, fontSize: 16 }} />
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default ExportIndividualEvaluation;
