import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, Switch, Route, useLocation, useParams } from 'react-router-dom';

// Material-UI
import { Grid, Divider, Button, ButtonGroup } from '@material-ui/core';

// Services
import { getCompany } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import EvaluationsChart from './evaluations/evaluations-chart';
import SkillbasesChart from './skillbases/skillbases-chart';
import FormationsChart from './formations/formations-chart';
import LearnerStatistics from './learner';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ------------------------------------- COMPANY STATISTICS --------------------------------------- \\
const CompanyStatistics = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { companyId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        menu: 'company',
        company: null,
    });

    const onChangeMenu = value => {
        if (value !== state.menu) {
            if (value === 'company') {
                history.push(`/management/statistics/${companyId}`);
            }
            if (value === 'learners') {
                history.push(`/management/statistics/${companyId}/learners`);
            }
            setState({ ...state, menu: value });
        }
    };

    useEffect(() => {
        if (location.pathname.includes('learners')) {
            setState({ ...state, menu: 'learners' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading) {
            getCompany(companyId).then(res => {
                if (res?.status === 200) {
                    setState({ ...state, loading: false, company: res.data.data.company });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" style={style.p10}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <b>{t('statistics')}</b>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                    style={{ margin: mediaMD ? 0 : 10 }}
                >
                    <span style={{ fontSize: 16 }}>{state.company?.name}</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <ButtonGroup>
                        <Button
                            onClick={() => onChangeMenu('company')}
                            color="primary" variant={state.menu === 'company' ? 'contained' : 'outlined'}
                            style={{ boxShadow: 'none' }}
                        >
                            {t('company')}
                        </Button>
                        <Button
                            onClick={() => onChangeMenu('learners')}
                            color="primary" variant={state.menu === 'learners' ? 'contained' : 'outlined'}
                            style={{ boxShadow: 'none' }}
                        >
                            {t('learners')}
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Divider />
            <Switch>
                <Route exact path={`${match.path}`}>
                    {/* ---------------------- TOOLBAR ---------------------- */}
                    <Grid container alignItems="center" justifyContent="space-between" className="toolbar">
                        <Button
                            onClick={() => history.push('/management/statistics')}
                            variant="contained"
                        >
                            {t('previous')}
                        </Button>
                    </Grid> 
                    <div className="full-container">
                        {state.loading ? (
                            <Spinner />
                        ) : state.error ? (
                            <Error />
                        ) : (
                            <>
                                <EvaluationsChart company={state.company} />
                                <FormationsChart company={state.company} />
                                <SkillbasesChart />
                            </>
                        )}
                    </div>
                </Route>
                <Route path={`${match.path}/learners`}>
                    <LearnerStatistics />
                </Route>
            </Switch>
        </>
    );
};

export default CompanyStatistics;
