import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions, Checkbox } from '@material-ui/core';

// Services
import { getSectors, deleteCompany, getCompanySkillbases, setCompanySkillbases } from 'services/requests';
import { getTranslation } from 'services/helpers';
import { getStoredUser } from 'services/storage';

// Components
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- SKILLBASES MODAL ---------------------------------------- \\
export const SkillbasesModal = ({ onClose, company }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        loading: true,
        error: false,
        sectors: [],
        loadingMutation: false,
        skillbasesSelected: null,
        skillbasesChecked: null,
        skillbasesUsed: null,
    });

    const selectSkillbase = (event, skillbaseId) => {
        let skillbasesChecked = state.skillbasesChecked;
        skillbasesChecked[skillbaseId] = event.target.checked;

        let skillbasesSelected = state.skillbasesSelected;
        if (event.target.checked) {
            skillbasesSelected.push(skillbaseId);
        }
        else {
            const index = skillbasesSelected.findIndex(id => id === skillbaseId);
            skillbasesSelected.splice(index, 1);
        }
        setState({ ...state, skillbasesSelected, skillbasesChecked });
    };

    // Handle Query (Get Sectors & Company Skillbases)
    useEffect(() => {
        axios.all([getSectors(), getCompanySkillbases(company.id)])
        .then(axios.spread(function (res_sectors, res_skillbases) {
            if (res_sectors?.status === 200 && res_skillbases?.status === 200) {

                const sectors = res_sectors.data.data.sectors;

                let skillbasesSelected = []; 
                let skillbasesChecked = {};
                let skillbasesUsed = {};

                for (let i = 0; i < sectors.length; i++) {
                    sectors[i].skillbases.map(skillbase => skillbasesChecked[skillbase.id] = false);
                }

                const skillbases = res_skillbases.data.data.skillbases;

                for (let i = 0; i < skillbases.length; i++) {

                    const skillbase = skillbases[i];

                    skillbasesSelected.push(skillbase.id);
                    skillbasesChecked[skillbase.id] = true;
                    skillbasesUsed[skillbase.id] = skillbase.used;
                }

                setState(state => ({ ...state, loading: false, sectors, skillbasesSelected, skillbasesChecked, skillbasesUsed }));
            }
            else {
                setState(state => ({ ...state, loading: false, error: true }));
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Mutation (Set Company Skillbases)
    useEffect(() => {
        if (state.loadingMutation) {
            setCompanySkillbases(company.id, { skillbases: state.skillbasesSelected }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    onClose();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loadingMutation: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingMutation]);

    return (
        <Dialog open fullWidth>
            {(state.loading || state.loadingMutation) ? (
                <Spinner />
            ) : state.error ? (
                <Error />
            ) :  (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {company.name}
                        </p>
                        <b className="input-title">{t('skillbases.select.company')} :</b>
                        {state.sectors.map(sector => (
                            (sector.skillbases != null && sector.skillbases.length > 0) && (
                                <Fragment key={sector.id}>
                                    <p style={{ margin: '15px 15px 5px 15px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                        {getTranslation('name', sector)}
                                    </p>
                                    <Divider />
                                    {sector.skillbases.map(skillbase => (
                                        <p key={skillbase.id} style={{ ...style.dF, ...style.aiC, ...style.m5_0 }}>
                                            <Checkbox
                                                color="secondary" 
                                                style={style.p5}
                                                disabled={state.skillbasesUsed[skillbase.id]}
                                                checked={state.skillbasesChecked[skillbase.id]}
                                                onChange={ROLE_ID === 1 ? (e) => selectSkillbase(e, skillbase.id) : null}
                                            />
                                            {getTranslation('name', skillbase)}
                                        </p>
                                    ))}
                                </Fragment>
                            )
                        ))}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        {ROLE_ID === 1 ? (
                            <>
                                <Button
                                    onClick={onClose}
                                    variant="contained"
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    onClick={() => setState({ ...state, loadingMutation: true })}
                                    variant="contained" color="primary"
                                >
                                    {t('save')}
                                </Button>
                            </>
                        ) : (
                            <Button
                                onClick={onClose}
                                variant="contained"
                            >
                                {t('close')}
                            </Button>
                        )}
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ----------------------------------------- DELETE COMPANY ----------------------------------------- \\
export const DeleteCompany = ({ onClose, company, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteCompany(company.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {company.name}
                        </p>
                        <span>{t('company.delete.confirm')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
