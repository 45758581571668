import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { mobipassColor, getFormattedDate } from 'services/helpers';
import { deleteEvaluation } from 'services/requests';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ------------------------------------- DELETE EVALUATION ---------------------------------------- \\
export const DeleteEvaluation = ({ onClose, refetch, evaluation }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteEvaluation(evaluation.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.taC}>
                        <p className="dialog-title">
                            {evaluation.name}
                            <br />
                            {getFormattedDate(evaluation.evaluation_date)}
                        </p>
                        <span>
                            {t('evaluation.delete.confirm')}
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('no')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained">
                            {t('yes')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// ---------------------------------------- LEARNERS MODAL ---------------------------------------- \\
export const LearnersModal = ({ onClose, evaluation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open>
            <DialogContent>
                <p className="dialog-title">
                    {evaluation.name}
                </p>
                <span style={{ color: mobipassColor }}>
                    {t('learners.list')}
                </span>
                <Divider style={style.m5_0_15_0} />
                <div>
                    {evaluation.users.map(user => (
                        <span key={user.id}>{user.full_name}<br /></span>
                    ))}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
