import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Material-UI
import { Button, Grid, Divider, IconButton, MenuItem } from '@material-ui/core';
import { Search as IconSearch, Cancel as IconCancel, Delete as IconDelete,  Create as IconCreate, Loupe as IconMore, Link as IconLink } from '@material-ui/icons';

// Services 
import { getTools } from 'services/requests';
import { servicesColor, getTranslation } from 'services/helpers';
import { roles, toolTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import CreateTool from './tool-create';
import UpdateTool from './tool-update';
import LinksModal from './tool-links';
import { DeleteTool, SkillbasesModal, RolesModal } from './tool-modals';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import SelectOutlined from 'components/items/select-outlined';
import InputFilter from 'components/items/input-filter';
import SelectSkillbase from 'components/select/select-skillbase';

// Style
import style from 'style';

// ---------------------------------------- TOOLS MANAGEMENT ---------------------------------------- \\
const HardToolsManagement = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: null,
        offset: 0,
        limit: 50,
        total_count: 0,
        name: '',
        tool_type_id: -1,
        skillbase_id: null,
        role_id: -1,
        active: -1,
        openCreateTool: false,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, offset: 0, error: false });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, offset: 0, error: false, [label]: value });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            name: '',
            tool_type_id: -1,
            skillbase_id: null,
            role_id: -1,
            active: -1,
        });
    };

    useEffect(() => {
        if (state.loading) {
            getTools(
                false,
                state.offset, 
                state.limit,
                state.name,
                state.tool_type_id,
                state.skillbase_id,
                state.role_id,
                state.active,
            ).then(res => {
                if (res?.status === 200) {
                    setState(state => ({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data.tools,
                    }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
            <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                <b>{t('management.hard.tools')}</b>
                <b>{state.total_count + ' ' + t('results')}</b>
            </Grid>
            <Divider />
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container justifyContent="space-between" className="toolbar">
                <Button onClick={() => history.push('/management')} variant="contained">
                    {t('previous')}
                </Button>                    
                <Pagination
                    limit={state.limit}
                    total_count={state.total_count}
                    setOffset={offset => setState({ ...state, loading: true, offset })}
                />
                <Button
                    onClick={() => setState({ ...state, openCreateTool: true })}
                    variant="contained"
                >
                    {t('add')}
                </Button>
            </Grid>
            <div className="full-container" style={{ paddingBottom: 24 }}>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: servicesColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span style={style.cW}>{t('type')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span style={style.cW}>{t('skillbase')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('role')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('active')}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('tools')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={2} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.tool_type_id}
                                onChange={e => onSelectSearch('tool_type_id', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('type')}</span>
                                </MenuItem>
                                {toolTypes.map(toolType => (
                                    <MenuItem key={toolType.id} value={toolType.id}>
                                        <span>{t(toolType.label)}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectSkillbase
                                skillbaseId={state.skillbase_id}
                                setSkillbaseId={skillbase_id => onSelectSearch('skillbase_id', skillbase_id)}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.role_id}
                                onChange={e => onSelectSearch('role_id', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('role')}</span>
                                </MenuItem>
                                {roles.map(role => (
                                    <MenuItem key={role.id} value={role.id}>
                                        <span>{t(role.label)}</span>
                                    </MenuItem>
                                ))}
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectOutlined
                                value={state.active}
                                onChange={e => onSelectSearch('active', e.target.value)}
                            >
                                <MenuItem value={-1}>
                                    <span>{t('all')}</span>
                                </MenuItem>
                                <MenuItem value={0}>
                                    <span>{t('no')}</span>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <span>{t('yes')}</span>
                                </MenuItem>
                            </SelectOutlined>
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- TOOL LIST -------------------- */}
                <ToolList
                    data={state.data}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
            {/* -------------------- CREATE TOOL -------------------- */}
            {state.openCreateTool && (
                <CreateTool
                    onClose={() => setState({ ...state, openCreateTool: false })}
                    refetch={() => setState({ ...state, openCreateTool: false, loading: true })}
                />
            )}
        </>
    );
};

// ------------------------------------------- TOOL LIST -------------------------------------------- \\
const ToolList = ({ data, loading, error, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        tool: null,
        openUpdateTool: false,
        openDeleteTool: false,
        openLinksModal: false,
        openSkillbasesModal: false,
        openRolesModal: false,
    });

    function handleRefetch(open) {
        setState({ ...state, [open]: false });
        refetch();
    }

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {data.length > 0 ? (
                    data.map(tool => (
                        <Fragment key={tool.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getTranslation('name', tool)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{tool.tool_type.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {tool.skillbases.length === 1 ? (
                                        <span>{getTranslation('name', tool.skillbases[0])}</span>
                                    ) : (
                                        <Grid container alignItems="center">
                                            <span>{tool.skillbases.length}</span>
                                            <Tooltip title={t('skillbases')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openSkillbasesModal: true, tool })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    {tool.roles.length === 1 ? (
                                        <div style={style.taC}>
                                            <span>{tool.roles[0].name}</span>
                                        </div>
                                    ) : (
                                        <Grid container alignItems="center" justifyContent="center">
                                            <span>{tool.roles.length}</span>
                                            <Tooltip title={t('roles')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openRolesModal: true, tool })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.taC, ...style.p10 }}>
                                    <span>{tool.active === 1 ? t('yes') : t('no')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.taC, ...style.p10 }}>
                                    <Tooltip title={t('links.edit')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openLinksModal: true, tool })}
                                                className="list-btn"
                                            >
                                                <IconLink />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('edit')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openUpdateTool: true, tool })}
                                                className="list-btn"
                                            >
                                                <IconCreate />
                                            </IconButton>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDeleteTool: true, tool })}
                                                className="list-btn"
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* ---------------------- UPDATE TOOL ---------------------- */}
            {state.openUpdateTool && (
                <UpdateTool
                    open={state.openUpdateTool}
                    onClose={() => setState({ ...state, openUpdateTool: false })}
                    refetch={() => handleRefetch('openUpdateTool')}
                    tool={state.tool}
                />
            )}
            {/* ---------------------- DELETE TOOL ---------------------- */}
            {state.openDeleteTool && (
                <DeleteTool
                    onClose={() => setState({ ...state, openDeleteTool: false })}
                    refetch={() => handleRefetch('openDeleteTool')}
                    tool={state.tool}
                />
            )}
            {/* ---------------------- LINKS MODAL ---------------------- */}
            {state.openLinksModal && (
                <LinksModal
                    onClose={() => setState({ ...state, openLinksModal: false })}
                    tool={state.tool}
                />
            )}
            {/* ---------------------- SKILLBASES MODAL ---------------------- */}
            {state.openSkillbasesModal && (
                <SkillbasesModal
                    onClose={() => setState({ ...state, openSkillbasesModal: false })}
                    tool={state.tool}
                />
            )}
            {/* ---------------------- ROLES MODAL ---------------------- */}
            {state.openRolesModal && (
                <RolesModal
                    onClose={() => setState({ ...state, openRolesModal: false })}
                    tool={state.tool}
                />
            )}
        </>
    );
};

export default HardToolsManagement;
