import React from 'react';

// Material-UI
import { Button } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp} from '@material-ui/icons';

// ------------------------------------- CHANGE ORDER FILTER ------------------------------------ \\
const ChangeOrderFilter = ({ state, setState, param, label }) => {

    const onChangeOrder = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            param,
            order: state.order === 'desc' ? 'asc' : 'desc',
        });
    };

    return (
        <Button
            onClick={onChangeOrder}
            style={{ textTransform: 'none', color: 'white', padding: '2px 6px' }}
        >
            {label}
            {state.order === 'desc' ? (
                <ArrowDropDown />
            ) : (
                <ArrowDropUp />
            )}
        </Button>
    );
};

ChangeOrderFilter.defaultProps = {
    label: '',
};

export default ChangeOrderFilter;
