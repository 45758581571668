import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// --------------------------------------- EVALUATION FORM PAGE 2 ---------------------------------------- \\
const EvaluationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage3 = () => {
        if (state.company_id == null || state.assessor_id == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.users == null || state.users.length === 0) {
            enqueueSnackbar(t('appraisee.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    useEffect(() => {
        if (state.authorizedRefetchMembers) {
            if (state.company_id) {
                getCompanyMembers(state.company_id).then(res => {
                    let assessorOptions = [], userOptions = [];
                    if (res?.status === 200) {
                        assessorOptions = res.data.data.assessors.map(a => ({ value: a.id, label: a.full_name }));
                        userOptions = res.data.data.learners.map(l => ({ value: l.id, label: l.full_name }));
                    }
                    setState({ ...state, assessorOptions, assessor_id: null, userOptions, users: null });
                });
            }
            else {
                setState({ ...state, assessorOptions: [], assessor_id: null, userOptions: [], users: null });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company_id]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- COMPANY -------------------- */}
                        <div>
                            <b className="input-title">
                                {t('company')}<FieldRequired />
                            </b>
                            {state.actionType === 'CREATE' ? (
                                <Select
                                    placeholder={t('company')}
                                    value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                    onChange={company => setState({ ...state, company_id: company ? company.value : null, authorizedRefetchMembers: true })}
                                    options={state.companies}
                                />
                            ) : (
                                <div style={{ padding: '6px 10px', borderRadius: 4, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                                    <span>{state.company_name}</span>
                                </div>
                            )}
                        </div>
                        {/* -------------------- ASSESSOR -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('assessor')}<FieldRequired />
                            </b>
                            <Select
                                placeholder={t('assessor')}
                                value={state.assessor_id ? state.assessorOptions.find(a => a.value === state.assessor_id) : null}
                                onChange={assessor => setState({ ...state, assessor_id: assessor ? assessor.value : null })}
                                options={state.assessorOptions}
                            />
                        </div>
                        {/* -------------------- USERS -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">
                                {t('appraisees')}<FieldRequired />
                            </b>
                            <Select
                                placeholder={t('appraisees')}
                                value={state.users}
                                onChange={users => setState({ ...state, users })}
                                options={state.userOptions}
                                isMulti
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 3</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default EvaluationFormPage2;
