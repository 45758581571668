import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import {
    Avatar, Button, Divider, IconButton, MenuItem, Dialog,
    DialogContent, DialogActions, Switch, FormControlLabel, Grid,
} from '@material-ui/core';
import { 
    Search as IconSearch, AccountCircle as IconProfile,
    Cancel as IconCancel, Assignment as IconDetail,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Services
import { fileUrl, servicesColor } from 'services/helpers';
import { getDirectoryUsers, editUser, getUser } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { roles } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import Pagination from './items/pagination';
import Tooltip from './items/tooltip';
import SelectOutlined from './items/select-outlined';
import Spinner from './items/spinner';
import Error from './items/error';
import Header from './common/header';
import InputFilter from './items/input-filter';

// Style
import style from 'style';

const styles = {
    label: {
        fontSize: 14,
    },
    avatar: {
        margin: '0 auto 10px auto',
        width: 125,
        height: 125
    },
};

// ---------------------------------------- DIRECTORY ----------------------------------------- \\
const Directory = props => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { classes } = props;

    const [state, setState] = useState({
        loading: true,
        error: false,
        data: [],
        offset: 0,
        limit: 50,
        total_count: 0,
        last_name: '',
        first_name: '',
        email: '',
        role_id: -1,
        company: '',
        openUserDetails: false,
        user: null,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, error: false, offset: 0 });
    };

    const onCancelSearch = () => {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            last_name: '',
            first_name: '',
            email: '',
            role_id: -1,
            company: '',
        });
    };

    useEffect(() => {
        if (state.loading) {
            getDirectoryUsers(
                state.offset,
                state.limit,
                state.last_name,
                state.first_name,
                state.email,
                state.role_id,
                state.company,
            ).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        data: res.data.data.users
                    });
                }
                else {
                    console.log(res);
                    setState({
                        ...state,
                        loading: false,
                        error: res,
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Header />
            <div className="main">
                {/* -------------------- TITLE & TOTAL COUNT -------------------- */}
                <Grid container alignItems="center" justifyContent="space-between" style={style.p10}>
                    <b>{t('directory')}</b>
                    <b>{state.total_count + ' ' + t('results')}</b>
                </Grid>
                <Divider />
                {/* -------------------- TOOLBAR -------------------- */}
                <Grid container className="toolbar">
                    <Grid container item xs={12} md={4} justifyContent={mediaMD ? 'flex-start' : 'center'}>
                        <DirectoryAgreement
                            classes={classes}
                            refetch={() => setState({ ...state, loading: true })}
                        />
                    </Grid>
                    <Grid container item xs={12} md={4} justifyContent="center">
                        <Pagination
                            limit={state.limit}
                            total_count={state.total_count}
                            setOffset={offset => setState({ ...state, loading: true, offset })}
                        />
                    </Grid>
                </Grid>
                <div className="full-container" style={{ paddingBottom: 24 }}>
                    {/* -------------------- LIST HEADERS -------------------- */}
                    <Grid container style={{ backgroundColor: servicesColor }}>
                        {mediaMD ? (
                            <>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span style={style.cW}>{t('last.name')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span style={style.cW}>{t('first.name')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span style={style.cW}>{t('email')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span style={style.cW}>{t('role')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span style={style.cW}>{t('company')}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <span style={style.cW}>{t('action')}</span>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('directory')}</span>
                            </Grid>
                        )}
                    </Grid>
                    {/* -------------------- LIST FILTERS -------------------- */}
                    {mediaMD && (
                        <Grid container alignItems="center">
                            <Grid item md={2} style={style.p10}>
                                <InputFilter
                                    placeholder={t('last.name')}
                                    value={state.last_name}
                                    onChange={e => setState({ ...state, last_name: e.target.value })}
                                    onKeyPress={onSearch}
                                />
                            </Grid>
                            <Grid item md={2} style={style.p10}>
                                <InputFilter
                                    placeholder={t('first.name')}
                                    value={state.first_name}
                                    onChange={e => setState({ ...state, first_name: e.target.value })}
                                    onKeyPress={onSearch}
                                />
                            </Grid>
                            <Grid item md={2} style={style.p10}>
                                <InputFilter
                                    placeholder={t('email')}
                                    value={state.email}
                                    onChange={e => setState({ ...state, email: e.target.value })}
                                    onKeyPress={onSearch}
                                />
                            </Grid>
                            <Grid item md={2} style={style.p10}>
                                <SelectOutlined
                                    value={state.role_id}
                                    onChange={e => setState({ ...state, role_id: e.target.value, loading: true, error: false, offset: 0 })}
                                >
                                    <MenuItem value={-1}>
                                        <span>{t('all')}</span>
                                    </MenuItem>
                                    {roles.map(role => (
                                        <MenuItem key={role.id} value={role.id}>
                                            <span>{t(role.label)}</span>
                                        </MenuItem>
                                    ))}
                                </SelectOutlined>
                            </Grid>
                            <Grid item md={2} style={style.p10}>
                                <InputFilter
                                    placeholder={t('company')}
                                    value={state.company}
                                    onChange={e => setState({ ...state, company: e.target.value })}
                                    onKeyPress={onSearch}
                                />
                            </Grid>
                            <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                                <Tooltip title={t('search')}
                                    item={
                                        <IconButton
                                            className="list-btn"
                                            onClick={onSearch}
                                        >
                                            <IconSearch />
                                        </IconButton>
                                    }
                                />
                                <Tooltip title={t('search.cancel')} 
                                    item={
                                        <IconButton
                                            className="list-btn"
                                            onClick={onCancelSearch}
                                        >
                                            <IconCancel />
                                        </IconButton>
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Divider />
                    {/* -------------------- USER LIST -------------------- */}
                    <UserList
                        data={state.data}
                        loading={state.loading}
                        error={state.error}
                        openUserDetails={user => setState({ ...state, user, openUserDetails: true })}
                    />
                </div>
            </div>
            {/* -------------------- USER DETAILS MODAL -------------------- */}
            {state.openUserDetails && (
                <UserDetails
                    user={state.user}
                    open={state.openUserDetails}
                    onClose={() => setState({ ...state, openUserDetails: false })}
                />
            )}
        </>
    );
};

// --------------------------------------- USER LIST ------------------------------------------ \\
const UserList = ({ data, loading, error, openUserDetails }) => {
    const { t } = useTranslation();

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <div className="full-container-content">
            {data.length > 0 ? (
                data.map(user => (
                    <Fragment key={user.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{user.last_name}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{user.first_name}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{user.email || ''}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{user.role ? user.role.name : ''}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={style.p10}>
                                <span>{user.company ? user.company.name : ''}</span>
                            </Grid>
                            <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                <Tooltip title={t('details')} item={
                                    <IconButton
                                        onClick={() => openUserDetails(user)}
                                        className="list-btn"
                                    >
                                        <IconDetail />
                                    </IconButton>  
                                } />
                            </Grid>
                        </Grid>
                        <Divider />     
                    </Fragment>
                ))
            ) : (
                <>     
                    <Grid container alignItems="center" style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
        </div>
    );
};

// ---------------------------------- USER DETAILS MODAL -------------------------------------- \\
const UserDetails = ({ user, open, onClose }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open}>
            <DialogContent style={style.taC}>
                {user.photo ? (
                    <Avatar alt="user_photo" src={fileUrl + user.photo} style={styles.avatar} />
                ) : (
                    <IconProfile style={{ ...styles.avatar, color: 'lightgray' }} />
                )}
                <p>
                    {user.first_name}
                    <br />
                    {user.last_name}
                </p>
                <Divider style={{ margin: '15px 0' }} />
                <p>
                    <b>{t('role')}</b>
                    <br />
                    {user.role ? user.role.name : ''}
                </p>
                {user.company && (
                    <p>
                        <b>{t('company')}</b>
                        <br />
                        {user.company.name}
                    </p>
                )}
                {user.email && (
                    <p>
                        <b>{t('email')}</b>
                        <br />
                        {user.email}
                    </p>
                )}
                {user.country && (
                    <p>
                        <b>{t('country')}</b>
                        <br />
                        {user.country.name}
                    </p>
                )}
                {user.informations && (
                    <p>
                        <b>{t('informations.further')}</b>
                        <br />
                        {user.informations}
                    </p>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ---------------------------------- DIRECTORY AGREEMENT -------------------------------------- \\
const DirectoryAgreement = ({ refetch, classes }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const USER_ID = getStoredUser()?.id;

    const [state, setState] = useState({
        loading: true,
        checked: undefined,
        error: undefined,
    });

    function handleChangeDirectoryAgreement(checked) {
        editUser(USER_ID, { directory: checked }).then(res => {
            if (res?.status === 200) {
                setState({ ...state, checked });
                enqueueSnackbar(res.data.message, { variant: 'success' });
            }
            else {
                console.log(res);
                enqueueSnackbar(res, { variant: 'error' });
            }
        });
    }

    useEffect(() => {
        getUser(USER_ID).then(res => {
            if (res?.status === 200) {
                setState(state => ({
                    ...state,
                    loading: false,
                    checked: res.data.data.user.directory === 0 ? false : true
                }));
            }
            else {
                console.log(res);
                setState(state => ({
                    ...state,
                    loading: false,
                    error: res
                }));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.checked !== undefined) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.checked])

    if (state.loading) return <span>{t('loading')}</span>;
    if (state.error) return <span>{t('error.occured')}</span>;

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={state.checked}
                    onChange={e => handleChangeDirectoryAgreement(e.target.checked)}
                />
            }
            label={t('directory.agreement')}
            classes={{ label: classes.label }}
            style={style.m0}
        />
    );
};

Directory.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Directory);
