import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import InputTextGrid from 'components/items/input-text-grid';

// ---------------------------------------- CLIENT FORM ---------------------------------------- \\
const ClientForm = ({ state, setState }) => {
    const { t } = useTranslation();

    return (
        <>
            {/* -------------------- NAME -------------------- */}
            <InputTextGrid
                value={state.name}
                onChange={e => setState({ ...state, name: e.target.value })}
                placeholder={t('name')}
                required
            />
            {/* -------------------- BILL ADDRESS -------------------- */}
            <InputTextGrid
                value={state.address}
                onChange={e => setState({ ...state, address: e.target.value })}
                placeholder={t('bill.address')}
                required
            />
            {/* -------------------- CONTACT -------------------- */}
            <InputTextGrid
                value={state.contact}
                onChange={e => setState({ ...state, contact: e.target.value })}
                placeholder={t('contact')}
                required
            />
        </>
    );
};

export default ClientForm;
