import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Grid, Dialog, DialogContent, DialogActions, Button, Divider } from '@material-ui/core';

// Services
import { getTranslation, softskillsColor } from 'services/helpers';

// Style
import style from 'style';

// ---------------------------------------- SOFT SKILL MODAL --------------------------------------- \\
const SoftskillModal = ({ onClose, soft_unit, softskill }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth>
            <DialogContent style={style.pb20}>
                {Object.keys(softskill).length > 0 && (
                    <>
                        <p className="dialog-title">
                            {getTranslation('name', softskill)}
                        </p>
                        <Divider />
                        {/* ---------------------- CATEGORY ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: softskillsColor }}>
                                    {t('softcategory')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{softskill?.soft_category?.name}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- REQUIRED LEVEL ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: softskillsColor }}>
                                    {t('softlevel.required')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{soft_unit?.soft_level?.level}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- DESCRIPTION ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: softskillsColor }}>
                                    {t('description')}
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={8} style={style.p10}>
                                <span>{soft_unit?.soft_level?.definition}</span>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* ---------------------- BEHAVIORS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: softskillsColor }}>
                                    {t('observable.behavior')}
                                </span>
                            </Grid>
                            {soft_unit?.soft_level?.behaviors.length > 0 ? (
                                <Grid item xs={12} sm={8} style={style.p10}>
                                    {soft_unit?.soft_level?.behaviors.map((behavior, index) => (
                                        <div
                                            key={behavior.id}
                                            style={{ backgroundColor: 'WhiteSmoke', marginTop: index === 0 ? 0 : 10 }}
                                        >
                                            <span>{behavior.position}. {behavior.definition}</span>
                                        </div>
                                    ))}
                                </Grid>
                            ):(
                                <Grid item xs={12} sm={8} style={style.p10}>
                                    <span>{t('none')}</span>
                                </Grid>
                            )}
                        </Grid>
                        <Divider />
                        {/* ---------------------- SPECIFICS BEHAVIORS ---------------------- */}
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={4} style={style.p10}>
                                <span style={{ color: softskillsColor }}>
                                    {t('specific.behaviors')}
                                </span>
                            </Grid>
                            {soft_unit?.specific_behaviors.length > 0 ? (
                                <Grid item xs={12} sm={8} style={style.p10}>
                                    {soft_unit?.specific_behaviors.map((specific_behavior, index) => (
                                        <div
                                            key={specific_behavior.id}
                                            style={{ backgroundColor: 'WhiteSmoke', marginTop: index === 0 ? 0 : 10 }}
                                        >
                                            <span>{specific_behavior.position}. {getTranslation('definition', specific_behavior)}</span>
                                        </div>
                                    ))}
                                </Grid>
                            ):(
                                <Grid item xs={12} sm={8} style={style.p10}>
                                    <span>{t('none')}</span>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SoftskillModal;
