import React, { Fragment, useState, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Button, IconButton, Divider, Grid } from '@material-ui/core';
import { Assignment as IconDetail } from '@material-ui/icons';

// Services
import { getFormattedDate, mobitrainColor } from 'services/helpers';
import { getFormationsSignatures } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import { SignFormation, DeclineFormation } from './signature-modals';
import DetailsFormation from '../formation/formation-details';
import Tooltip from 'components/items/tooltip';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- SIGNATURE ---------------------------------------- \\
const Signature = () => {
    const match = useRouteMatch();
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: true,
        error: false,
        formations: [],
    });

    useEffect(() => {
        if (state.loading) {
            getFormationsSignatures().then(res => {
                if (res?.status === 200) {
                    setState(state => ({ ...state, loading: false, formations: res.data.data.formations }));
                }
                else {
                    console.log(res);
                    setState(state => ({ ...state, loading: false, error: true }));
                }
            });
        }
    }, [state.loading]);

    return (
        <>
            <BreadcrumbsItem to={`${match.path}`} className="breadcrumb-item">
                {t('signature')}
            </BreadcrumbsItem>
            <div className="full-container" style={{ paddingTop: 24, paddingBottom: 24 }}>
                {/* -------------------- HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: mobitrainColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={4} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.taC, ...style.p10 }}>
                                <span style={style.cW}>{t('date')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.taC, ...style.p10 }}>
                                <span style={style.cW}>{t('details')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.taC, ...style.p10 }}>
                                <span style={style.cW}>{t('decline')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.taC, ...style.p10 }}>
                                <span style={style.cW}>{t('sign')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('trainings')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- SIGNATURE LIST -------------------- */}
                <SignatureList
                    formations={state.formations}
                    loading={state.loading}
                    error={state.error}
                    refetch={() => setState({ ...state, loading: true })}
                />
            </div>
        </>
    );
};

// ---------------------------------------- SIGNATURE LIST ---------------------------------------- \\
const SignatureList = ({ formations, loading, error, refetch }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        formation: null,
        openDetails: false,
        openDecline: false,
        openSign: false,
    });

    const handleRefetch = open => {
        setState({ ...state, [open]: false });
        refetch();
    };

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="full-container-content">
                {formations.length > 0 ? (
                    formations.map(formation => (
                        <Fragment key={formation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={4} style={style.p10}>
                                    <span>{formation.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <span>{getFormattedDate(formation.formation_date)}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton 
                                                onClick={() => setState({ ...state, openDetails: true, formation })} 
                                                className="list-btn"
                                            >
                                                <IconDetail />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Button
                                        onClick={() => setState({ ...state, openDecline: true, formation })} 
                                        variant="contained"
                                        style={style.p3_16}
                                    >
                                        {t('decline')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    <Button
                                        onClick={() => setState({ ...state, openSign: true, formation })} 
                                        color="primary"
                                        variant="contained"
                                        style={style.p3_16}
                                    >
                                        {t('sign')}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- FORMATION DETAILS -------------------- */}
            {state.openDetails && (
                <DetailsFormation
                    isSignComponent
                    formation={state.formation}
                    onClose={() => setState({ ...state, openDetails: false })}
                />
            )}
            {/* -------------------- SIGN FORMATION -------------------- */}
            {state.openSign && (
                <SignFormation
                    formation={state.formation}
                    onClose={() => setState({ ...state, openSign: false })}
                    refetch={() => handleRefetch('openSign')}
                />
            )}
            {/* -------------------- DECLINE FORMATION -------------------- */}
            {state.openDecline && (
                <DeclineFormation
                    formation={state.formation}
                    onClose={() => setState({ ...state, openDecline: false })}
                    refetch={() => handleRefetch('openDecline')}
                />
            )}
        </>
    );
};

export default Signature;
