import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Material-UI
import IconWarning from '@material-ui/icons/WarningRounded';

// Import Style
import style from '../../style';

// ---------------------------------------- ERROR ---------------------------------------- \\
const Error = () => {
    const { t } = useTranslation();

    return (
        <div className="error-and-spinner">
            <IconWarning />
            <span style={style.ml5}>{t('error.occured')}</span>
        </div>
    );
};

export default Error;
