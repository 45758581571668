import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider, Checkbox } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/items/input-text';
import FieldRequired from 'components/items/field-required';
import DatePicker from 'components/items/date-picker';
import Select from 'components/items/react-select';

// ---------------------------------- SOFT EVALUATION FORM PAGE 1 ---------------------------------- \\
const SoftEvaluationFormPage1 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage2 = () => {
        if (state.evaluation_date == null
            || state.evaluation_context === ''
            || (state.company_id == null && state.company_name === '')
            || (state.assessor_id == null && state.assessor_name === '')
            || state.learner_id == null
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 2 });
        }
    };

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- EVALUATION DATE -------------------- */}
                        <div>
                            <b className="input-title">{t('evaluation.date')}</b><FieldRequired />
                            <DatePicker
                                placeholder={t('evaluation.date')}
                                value={state.evaluation_date}
                                onChange={evaluation_date => setState({ ...state, evaluation_date })}
                                outlined
                            />
                        </div>
                        {/* -------------------- EVALUATION CONTEXT -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('evaluation.context')}</b><FieldRequired />
                            <InputText
                                placeholder={t('evaluation.context')}
                                value={state.evaluation_context}
                                onChange={e => setState({ ...state, evaluation_context: e.target.value })}
                                multiline
                            />
                        </div>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('company')}</b><FieldRequired />
                            <Grid container alignItems="center" style={{ paddingBottom: 10 }}>
                                <Checkbox
                                    checked={state.company_manual_entry}
                                    onChange={e => setState({ ...state, company_manual_entry: e.target.checked, company_id: null, company_name: '' })}
                                    style={{ padding: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>{t('manual.entry')}</span>
                            </Grid>
                            {state.company_manual_entry ? (
                                <InputText
                                    placeholder={t('company')}
                                    value={state.company_name}
                                    onChange={e => setState({ ...state, company_name: e.target.value })}
                                />
                            ) : (
                                <Select
                                    placeholder={t('company')}
                                    value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                    onChange={company => setState({ ...state, company_id: company ? company.value : null })}
                                    options={state.companies}
                                />
                            )}
                        </div>
                        {/* -------------------- ASSESSOR -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('assessor')}</b><FieldRequired />
                            <Grid container alignItems="center" style={{ paddingBottom: 10 }}>
                                <Checkbox
                                    checked={state.assessor_manual_entry}
                                    onChange={e => setState({ ...state, assessor_manual_entry: e.target.checked, assessor_company_id: null, assessor_name: '' })}
                                    style={{ padding: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>{t('manual.entry')}</span>
                            </Grid>
                            {state.assessor_manual_entry ? (
                                <InputText
                                    placeholder={t('assessor')}
                                    value={state.assessor_name}
                                    onChange={e => setState({ ...state, assessor_name: e.target.value })}
                                />
                            ) : (
                                <SelectAssessor
                                    state={state}
                                    setState={setState}
                                />
                            )}
                        </div>
                        {/* -------------------- LEARNER -------------------- */}
                        <div style={{ marginTop: 12 }}>
                            <b className="input-title">{t('learner')}</b><FieldRequired />
                            <SelectLearner
                                state={state}
                                setState={setState}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid item xs={false} md={4} />
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>1 / 3</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage2} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

// ---------------------------------------- SELECT ASSESSOR ---------------------------------------- \\
const SelectAssessor = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchAssessor) {
            if (state.assessor_company_id) {
                getCompanyMembers(state.assessor_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({
                            ...state,
                            assessors: res.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                            assessor_id: null,
                            authorizedRefetchAssessor: false,
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, assessors: [], assessor_id: null, authorizedRefetchAssessor: false });
                    }
                });
            }
            else {
                setState({ ...state, assessors: [], assessor_id: null, authorizedRefetchAssessor: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.assessor_company_id]);

    return (
        <>
            {/* -------------------- ASSESSOR COMPANY -------------------- */}
            <Select
                placeholder={t('company')}
                value={state.assessor_company_id ? state.companies.find(c => c.value === state.assessor_company_id) : null}
                onChange={company => setState({ ...state, assessor_company_id: company ? company.value : null, authorizedRefetchAssessor: true })}
                options={state.companies}
            />
            <div style={{ margin: 10 }} />
            {/* -------------------- ASSESSOR -------------------- */}
            <Select
                placeholder={t('assessor')}
                value={state.assessor_id ? state.assessors.find(a => a.value === state.assessor_id) : null}
                onChange={assessor => setState({ ...state, assessor_id: assessor ? assessor.value : null })}
                options={state.assessors}
            />
        </>
    );
};

// ---------------------------------------- SELECT LEARNER ----------------------------------------- \\
const SelectLearner = ({ state, setState }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (state.authorizedRefetchLearner) {
            if (state.learner_company_id) {
                getCompanyMembers(state.learner_company_id).then(res => {
                    if (res?.status === 200) {
                        setState({
                            ...state,
                            learners: res.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                            learner_id: null,
                            authorizedRefetchLearner: false,
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, learners: [], learner_id: null, authorizedRefetchLearner: false });
                    }
                });
            }
            else {
                setState({ ...state, learners: [], learner_id: null, authorizedRefetchLearner: false });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.learner_company_id]);

    return (
        <>
            {/* -------------------- LEARNER COMPANY -------------------- */}
            <Select
                placeholder={t('company')}
                value={state.learner_company_id ? state.companies.find(c => c.value === state.learner_company_id) : null}
                onChange={company => setState({ ...state, learner_company_id: company ? company.value : null, authorizedRefetchLearner: true })}
                options={state.companies}
            />
            <div style={{ margin: 10 }} />
            {/* -------------------- LEARNER -------------------- */}
            <Select
                placeholder={t('learner')}
                value={state.learner_id ? state.learners.find(a => a.value === state.learner_id) : null}
                onChange={assessor => setState({ ...state, learner_id: assessor ? assessor.value : null, loadingSoftCategories: true })}
                options={state.learners}
            />
        </>
    );
};

export default SoftEvaluationFormPage1;
