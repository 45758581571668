import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { getSoftCategories, createSoftUnit } from 'services/requests';
import { getTranslation, softskillsColor2, servicesColor } from 'services/helpers';

// Components
import Select from 'components/items/react-select';
import Spinner from 'components/items/spinner';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ------------------------------------- CREATE SOFT UNIT ------------------------------------- \\
const CreateSoftUnit = ({ onClose, refetch, unit, soft_units }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        soft_categories: [],
        soft_category_id: null,
        softskills: [],
        softskill_id: null,
        soft_levels: [],
        soft_level_id: null,
    });

    const onSelectSoftCategory = soft_category => {
        if (soft_category != null) {

            const softskill_ids = soft_units.map(su => su.softskill?.id);
            const softskills = state.soft_categories
                .find(c => c.value === soft_category.value)?.softskills
                .filter(s => !softskill_ids.includes(s.id));

            setState({
                ...state,
                soft_category_id: soft_category.value,
                softskills: softskills.map(s => ({ value: s.id, label: getTranslation('name', s), soft_levels: s.soft_levels })),
                softskill_id: null,
                softskill: null,
                soft_level_id: null,
            });
        }
        else {
            setState({
                ...state,
                soft_category_id: null,
                softskills: [],
                softskill_id: null,
                softskill: null,
                soft_level_id: null,
            });
        }
    };

    const onSelectSoftskill = softskill => {
        if (softskill != null) {
            setState({
                ...state,
                softskill_id: softskill.value,
                soft_levels: softskill.soft_levels || [],
                soft_level_id: null,
            });
        }
        else {
            setState({
                ...state,
                softskill_id: null,
                soft_levels: [],
                soft_level_id: null,
            });
        }
    };

    const onCreateSoftUnit = () => {
        if (state.soft_level_id == null) {
            enqueueSnackbar(t('softlevel.select.error'), { variant: 'error' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        getSoftCategories().then(res => {
            if (res?.status === 200) {
                setState({ 
                    ...state,
                    soft_categories: res.data.data.soft_categories.map(c => ({ value: c.id, label: getTranslation('name', c), softskills: c.softskills })),
                });
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (state.loading) {
            createSoftUnit({
                unit_id: unit.id,
                soft_level_id: state.soft_level_id,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.soft_unit);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth maxWidth="md">
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('softskill.link')}
                        </p>
                        {/* -------------------------- SELECT SOFT CATEGORY -------------------------- */}
                        <Grid container alignItems="center" style={style.m5_0}>
                            <Grid item xs={12} sm={4}>
                                <b className="input-title">{t('softcategory.select')}</b><FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Select
                                    placeholder={t('softcategory')}
                                    value={state.soft_category_id ? state.soft_categories.find(c => c.value === state.soft_category_id) : null}
                                    onChange={soft_category => onSelectSoftCategory(soft_category)}
                                    options={state.soft_categories}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------------- SELECT SOFT SKILL ---------------------------- */}
                        <Grid container alignItems="center" style={style.m5_0}>
                            <Grid item xs={12} sm={4}>
                                <b className="input-title">{t('softskill.select')}</b><FieldRequired />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Select
                                    placeholder={t('softskill')}
                                    value={state.softskill_id ? state.softskills.find(s => s.value === state.softskill_id) : null}
                                    onChange={softskill => onSelectSoftskill(softskill)}
                                    options={state.softskills}
                                />
                            </Grid>
                        </Grid>
                        {/* ---------------------------- SELECT SOFT LEVEL ---------------------------- */}
                        {state.soft_levels?.length > 0 && (
                            <>
                                <div style={{ marginTop: 48, marginBottom: 10 }}>
                                    <b className="input-title">{t('softlevel.select')}</b><FieldRequired />
                                </div>
                                <div className="soft-level-table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                {state.soft_levels.map(soft_level => (
                                                    <td
                                                        key={soft_level.id}
                                                        className="soft-level-td"
                                                        onClick={() => setState({ ...state, soft_level_id: soft_level.id })}
                                                    >
                                                        <Grid
                                                            container
                                                            className="soft-level"
                                                            style={{
                                                                backgroundColor: state.soft_level_id === soft_level.id
                                                                ? softskillsColor2
                                                                : null
                                                            }}
                                                        >
                                                            <p style={{ ...style.taC, ...style.fwB, ...style.w100 }}>
                                                                {t('level')}&nbsp;{soft_level.level}
                                                                <br />
                                                                {soft_level.level === 1 && t('remember.and.understand')}
                                                                {soft_level.level === 2 && t('apply')}
                                                                {soft_level.level === 3 && t('analyse.and.evaluate')}
                                                                {soft_level.level === 4 && t('create')} 
                                                            </p>
                                                            <p className="soft-level-definition">
                                                                {getTranslation('definition', soft_level)}
                                                            </p>
                                                        </Grid>
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={onCreateSoftUnit}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('link.add')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateSoftUnit;
