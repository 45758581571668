import React from 'react';

// Material-UI
import Grid from '@material-ui/core/Grid';

// Services
import useMediaQueries from 'services/media';

// Components
import InputText from './input-text';
import FieldRequired from './field-required';

// Style
import style from 'style';

// ---------------------------------------- INPUT TEXT GRID ---------------------------------------- \\
const InputTextGrid = ({ placeholder, value, onChange, disabled, type, multiline, required }) => {
    const { mediaSM } = useMediaQueries();

    return (
        <Grid container alignItems="center" style={style.m5_0}>
            <Grid
                item xs={12} sm={4}
                style={{ paddingRight: mediaSM ? 5 : 0 }}
            >
                <b className="input-title">{placeholder}</b>{required && <FieldRequired />}
            </Grid>
            <Grid
                item xs={12} sm={8}
                style={{ paddingLeft: mediaSM ? 5 : 0 }}
            >
                <InputText
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    type={type}
                    multiline={multiline}
                />
            </Grid>
        </Grid>
    );
};

InputTextGrid.defaultProps = {
    required: false,
};

export default InputTextGrid;
