import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogActions, DialogContent } from '@material-ui/core';

// Services
import { signFormation } from 'services/requests';

// Components
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- SIGN FORMATION ---------------------------------------- \\
export const SignFormation = ({ onClose, refetch, formation }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            signFormation(formation.id, true).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                        <p className="dialog-title">
                            {formation.name}
                        </p>
                        <span>
                            {t('training.confirm.presence')}
                            <br />
                            ({t('signature.electronic')})
                        </span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained" color="primary">
                            {t('sign')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

// -------------------------------------- DECLINE FORMATION ---------------------------------------- \\
export const DeclineFormation = ({ onClose, refetch, formation }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            signFormation(formation.id, false).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            {loading ? (
                <Spinner />
            ) : (
                <Fragment>
                    <DialogContent style={{ paddingBottom: 20, textAlign: 'center' }}>
                        <p className="dialog-title">
                            {formation.name}
                        </p>
                        <span>{t('decline.formation')}</span>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => setLoading(true)} variant="contained" color="secondary">
                            {t('decline')}
                        </Button>
                    </DialogActions>
                </Fragment>
            )}
        </Dialog> 
    );
};
