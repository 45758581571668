import React from 'react';

// Material-UI
import { Grid } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { theme } from 'services/helpers';

// Assets
import LogoZerobarrier from 'assets/images/zerobarrier-white.png';
import LogoMobirhin from 'assets/images/mobirhin-white.png';

// Components
import SelectLanguage from './select-language';
import NavigationMenu from './navigation-menu';

// ---------------------------------------- HEADER ---------------------------------------- \\
const Header = () => {
    const { mediaMD, mediaLG } = useMediaQueries();

    return (
        <>
            <Grid
                className="header"
                container alignItems="center"
                style={{
                    position: mediaLG ? 'fixed' : 'static',
                    top: mediaLG ? 0 : null,
                }}
            >
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                >
                    <img
                        alt="Logo Header"
                        src={theme === 'mobirhin' ? LogoMobirhin : LogoZerobarrier}
                        style={{ height: 50 }}
                    />
                </Grid>
                <Grid
                    item xs={12} md={6}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                >
                    <SelectLanguage />
                    <NavigationMenu />
                </Grid>
            </Grid>
            {mediaLG && (
                <div style={{ height: 64 }} />
            )}
        </>
    );
};

export default Header;
