import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// File-Saver
import { saveAs } from 'file-saver';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Grid, Divider, IconButton, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import {
    Search as IconSearch, Cancel as IconCancel, Assignment as IconDetails, Loupe as IconMore,
    InsertDriveFile as IconCertif, SaveAlt as IconSave,
} from '@material-ui/icons';

// Services
import { getCleaFormationsForUser } from 'services/requests';
import { cleaColor, getTranslation, fileUrl, getLabelFromArray } from 'services/helpers';
import { cleaDocumentTypes } from 'services/constants';
import useMediaQueries from 'services/media';

// Components
import { DetailsCleaFormation, CleaTrainersModal } from 'components/mobitrain/clea-formation/clea-formation-modals';
import Pagination from 'components/items/pagination';
import Tooltip from 'components/items/tooltip';
import DatePicker from 'components/items/date-picker';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import InputFilter from 'components/items/input-filter';
import SelectCompany from 'components/select/select-company';

// Style
import style from 'style';

// ---------------------------------- CLEA LEARNER FORMATIONS -------------------------------- \\
const CleaLearnerFormations = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { learnerId } = useParams();

    const [state, setState] = useState({
        loading: true,
        error: false,
        clea_formations: [],
        offset: 0,
        limit: 30,
        total_count: 0,
        name: '',
        clea_formation_date: null,
        company_id: null,
        trainer: '',
        openCreateCleaFormation: false,
    });

    const onSearch = () => {
        setState({ ...state, loading: true, error: false, offset: 0 });
    };

    const onSelectSearch = (label, value) => {
        setState({ ...state, loading: true, error: false, offset: 0, [label]: value });
    };

    function onCancelSearch() {
        setState({
            ...state,
            loading: true,
            error: false,
            offset: 0,
            name: '',
            clea_formation_date: null,
            company_id: null,
            trainer: '',
        });
    }

    useEffect(() => {
        if (state.loading) {
            getCleaFormationsForUser(learnerId, {
                offset: state.offset,
                limit: state.limit,
                name: state.name,
                formation_date: state.clea_formation_date ? moment(state.clea_formation_date, 'MM-DD-YYYY').format('YYYY-MM-DD') : null,
                company_id: state.company_id,
                trainer: state.trainer,
            }).then(res => {
                if (res.status === 200) {
                    setState({
                        ...state,
                        loading: false,
                        offset: res.data.data.offset,
                        total_count: res.data.data.total_count,
                        clea_formations: res.data.data.clea_formations,
                    });
                }
                else {
                    console.log(res);
                    setState({ ...state, loading: false, error: res });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            <div style={{ padding: 24 }}>
                <Grid container alignItems="center" style={{ marginBottom: 10 }}>
                    <Grid
                        item xs={12} md={4}
                        container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    >
                        <b className="input-title" style={{ fontSize: 14 }}>{t('clea.trainings.carried.out')}</b>
                    </Grid>
                    <Grid
                        item xs={12} md={4}
                        container justifyContent="center"
                        style={{ margin: mediaMD ? 0 : 10 }}
                    >
                        <Pagination
                            limit={state.limit}
                            total_count={state.total_count}
                            setOffset={offset => setState({ ...state, loading: true, offset })}
                        />
                    </Grid>
                    <Grid
                        item xs={12} md={4}
                        container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    >
                        <span style={{ fontWeight: 'bold' }}>{state.total_count} {t('results')}</span>
                    </Grid>
                </Grid>
                {/* ---------------------- LIST HEADERS ---------------------- */}
                <Grid container style={{ backgroundColor: cleaColor }}>
                    {mediaMD ? (
                        <>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('name')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('date')}</span>
                            </Grid>
                            <Grid item xs={2} style={style.p10}>
                                <span style={style.cW}>{t('company')}</span>
                            </Grid>
                            <Grid item xs={3} style={style.p10}>
                                <span style={style.cW}>{t('trainer')}</span>
                            </Grid>
                            <Grid item xs={2} style={{ ...style.p10, ...style.taC }}>
                                <span style={style.cW}>{t('action')}</span>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                            <span style={style.cW}>{t('clea.training')}</span>
                        </Grid>
                    )}
                </Grid>
                {/* -------------------- LIST FILTERS -------------------- */}
                {mediaMD && (
                    <Grid container alignItems="center">
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('name')}
                                value={state.name}
                                onChange={e => setState({ ...state, name: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <DatePicker
                                placeholder={t('date')}
                                value={state.clea_formation_date}
                                onChange={clea_formation_date => onSelectSearch('clea_formation_date', clea_formation_date)}
                                outlined
                            />
                        </Grid>
                        <Grid item md={2} style={style.p10}>
                            <SelectCompany
                                companyId={state.company_id}
                                setCompanyId={company_id => onSelectSearch('company_id', company_id)}
                            />
                        </Grid>
                        <Grid item md={3} style={style.p10}>
                            <InputFilter
                                placeholder={t('trainer')}
                                value={state.trainer}
                                onChange={e => setState({ ...state, trainer: e.target.value })}
                                onKeyPress={onSearch}
                            />
                        </Grid>
                        <Grid item md={2} style={{ ...style.p10, ...style.taC }}>
                            <Tooltip title={t('search')} 
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onSearch}
                                    >
                                        <IconSearch />
                                    </IconButton>
                                }
                            />
                            <Tooltip title={t('search.cancel')}
                                item={
                                    <IconButton
                                        className="list-btn"
                                        onClick={onCancelSearch}
                                    >
                                        <IconCancel />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                <Divider />
                {/* -------------------- CLEA FORMATION LIST -------------------- */}
                <CleaFormationList
                    loading={state.loading}
                    error={state.error}
                    clea_formations={state.clea_formations}
                />
            </div>
        </>
    );
};

// ------------------------------------ CLEA FORMATION LIST ---------------------------------- \\
const CleaFormationList = ({ loading, error, clea_formations }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        clea_formation: null,
        openDetails: false,
        openDocuments: false,
        openTrainers: false,
    });

    if (loading) return <Spinner />;
    if (error) return <Error />;

    return (
        <>
            <div className="half-container-content">
                {clea_formations.length > 0 ? (
                    clea_formations.map(clea_formation => (
                        <Fragment key={clea_formation.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={3} style={style.p10}>
                                    <span>{clea_formation.name}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{clea_formation.dates}</span>
                                </Grid>
                                <Grid item xs={12} md={2} style={style.p10}>
                                    <span>{getTranslation('name', clea_formation?.company)}</span>
                                </Grid>
                                <Grid item xs={12} md={3} style={style.p10}>
                                    {clea_formation.trainers?.length > 1 ? (
                                        <>
                                            <span>{clea_formation.trainers.length}</span>
                                            <Tooltip title={t('trainers.list')}
                                                item={
                                                    <IconButton
                                                        onClick={() => setState({ ...state, openTrainers: true, clea_formation })}
                                                        className="list-btn"
                                                    >
                                                        <IconMore style={{ transform: 'rotateY(180deg)' }} />
                                                    </IconButton>
                                                }
                                            />
                                        </>
                                    ) : (
                                        <span>
                                            {clea_formation.trainers[0]?.full_name}
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ ...style.p10, ...style.taC }}>
                                    {clea_formation.clea_formation_documents.length > 0 && (
                                        <Tooltip title={t('clea.proof.file')}
                                            item={
                                                <IconButton
                                                    onClick={() => setState({ ...state, openDocuments: true, clea_formation })}
                                                    className="list-btn"
                                                >
                                                    <IconCertif />
                                                </IconButton>
                                            }
                                        />
                                    )}
                                    <Tooltip title={t('details')}
                                        item={
                                            <IconButton
                                                onClick={() => setState({ ...state, openDetails: true, clea_formation })}
                                                className="list-btn"
                                            >
                                                <IconDetails />
                                            </IconButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Divider />     
                        </Fragment>
                    ))
                ) : (
                    <>     
                        <Grid container alignItems="center" style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            {/* -------------------- DETAILS CLEA FORMATION -------------------- */}
            {state.openDocuments && (
                <CleaFormationDocumentsModal
                    onClose={() => setState({ ...state, openDocuments: false })}
                    clea_formation={state.clea_formation}
                />
            )}
            {/* -------------------- DETAILS CLEA FORMATION -------------------- */}
            {state.openDetails && (
                <DetailsCleaFormation
                    onClose={() => setState({ ...state, openDetails: false })}
                    clea_formation={state.clea_formation}
                />
            )}
            {/* -------------------- CLEA TRAINERS MODAL -------------------- */}
            {state.openTrainers && (
                <CleaTrainersModal
                    onClose={() => setState({ ...state, openTrainers: false })}
                    clea_formation={state.clea_formation}
                />
            )}
        </>
    );
};

// ---------------------------------- CLEA FORMATION DOCUMENTS ------------------------------- \\
const CleaFormationDocumentsModal = ({ onClose, clea_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth maxWidth="lg">
            <DialogContent style={{ paddingBottom: 20 }}>
                <p className="dialog-title">
                    {t('clea.proof.file')}
                </p>
                <Divider />
                {/* -------------------- HEADERS -------------------- */}
                <Grid container style={{ backgroundColor: cleaColor }}>
                    <Grid item xs={12} sm={5} style={style.p10}>
                        <span style={style.cW}>{t('clea.document.name')}</span>
                    </Grid>
                    <Grid item xs={12} sm={5} style={style.p10}>
                        <span style={style.cW}>{t('clea.document.type')}</span>
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ padding: 10, textAlign: 'center' }}>
                        <span style={style.cW}>{t('download')}</span>
                    </Grid>
                </Grid>
                {/* -------------------- DOCUMENT LIST -------------------- */}
                {clea_formation.clea_formation_documents.map((document, index) => (
                    <Fragment key={document.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={5} style={style.p10}>
                                <span>{document.name}</span>
                            </Grid>
                            <Grid item xs={12} sm={5} style={style.p10}>
                                <span>{t(getLabelFromArray(cleaDocumentTypes, document.type?.toString()))}</span>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{ padding: 10, textAlign: 'center' }}>
                                <IconButton
                                    onClick={() => saveAs(fileUrl + document.document)}
                                    style={{ padding: 6 }}
                                >
                                    <IconSave />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog> 
    );
};

export default CleaLearnerFormations;
