import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';

// Services
import { deleteSoftEvaluation } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { getFormattedDate, softskillsColor, getTranslation } from 'services/helpers';

// Style
import style from 'style';

const styles = {
    td: {
        border: '1px solid lightgray',
        fontSize: 14,
        padding: 10,
    },
};

// ----------------------------------- DELETE SOFT EVALUATION ---------------------------------- \\
export const DeleteSoftEvaluation = ({ onClose, refetch, soft_evaluation }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            deleteSoftEvaluation(soft_evaluation.id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setLoading(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <Dialog open>
            <DialogContent style={style.pb20}>
                <p className="dialog-title">
                    {t('evaluation')}
                    <br />
                    {getFormattedDate(soft_evaluation.evaluation_date)}
                </p>
                <span>{t('evaluation.delete.confirm')}</span>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcSB}>
                <Button variant="contained" onClick={onClose}>
                    {t('no')}
                </Button>
                <Button variant="contained" onClick={() => setLoading(true)}>
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ---------------------------------- DETAILS SOFT EVALUATION ---------------------------------- \\
export const DetailsSoftEvaluation = ({ onClose, soft_evaluation }) => {
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {t('evaluation.details')}
                </p>
                <Divider />
                {/* ---------------------- EVALUATION DATE ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getFormattedDate(soft_evaluation.evaluation_date)}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- EVALUATION CONTEXT ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('evaluation.context')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{soft_evaluation.evaluation_context}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- COMPANY ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('company')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{soft_evaluation.company_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- ASSESSOR ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('assessor')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{soft_evaluation.assessor_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- LEARNER ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('learner')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{soft_evaluation.learner.full_name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- SOFT LEVELS ---------------------- */}
                <table style={{ width: '100%', margin: '24px 0px' }}>
                    <thead>
                        <tr>
                            <td style={{ ...styles.td, color: softskillsColor }}>{t('softskills')}</td>
                            <td style={{ ...styles.td, color: softskillsColor, ...style.taC }}>{t('level')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {soft_evaluation.soft_levels.map(soft_level => (
                            <tr key={soft_level.id}>
                                <td style={styles.td}>
                                    {getTranslation('definition', soft_level)}
                                    {(ROLE_ID < 5 && soft_level.observed_behaviors != null) && (
                                        <>
                                            <br />
                                            <span style={{ color: 'gray' }}>
                                                {t('observed.behaviors') + ' : ' + soft_level.observed_behaviors}
                                            </span>
                                        </>     
                                    )}
                                </td>
                                <td style={{ ...styles.td, ...style.taC }}>{t('level')}&nbsp;{soft_level.level}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
