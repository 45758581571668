import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button, Grid, MenuItem } from '@material-ui/core';
import { Clear as IconClear, Save as IconSave, InsertDriveFile as IconFile, AddCircle as IconAdd } from '@material-ui/icons';

// Services 
import { getToolsResources, getTool, addLink, editLink, deleteLink, uploadFileLink } from 'services/requests';
import { getTranslation, fileUrl, getFileExtension } from 'services/helpers';
import { languages } from 'services/constants';

// Components
import Spinner from 'components/items/spinner';
import SelectOutlined from 'components/items/select-outlined';
import SelectMultiple from 'components/items/select-multiple';
import InputText from 'components/items/input-text';
import InputFile from 'components/items/input-file';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

const styles = {
    border: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 3, 
        padding: '15px 0px',
    },
    none: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: 3,
        padding: 10,
    },
};

// ---------------------------------------- LINKS MODAL ---------------------------------------- \\
const LinksModal = ({ onClose, tool }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
 
    const [state, setState] = useState({
        link_type_id: 1,
        url: '',
        file: '',
        request_type: 0, // Add (0) or Edit (1) Link
        languages: null,
        tool_links: tool.tool_links,
        tool_link_id: undefined,
        loadingGet: false,
        loadingAdd: false,
        loadingEdit: false,
        loadingDelete: false,
        loadingUploadFile: false,
    });

    function isLoading() {
        if (state.loadingGet || state.loadingAdd || state.loadingEdit || state.loadingDelete || state.loadingUploadFile) {
            return true;
        }
        return false;
    }

    function handleOnCancel() {
        setState({
            ...state,
            request_type: 0,
            url: '',
            file: '',
            tool_link_id: undefined,
            link_type_id: 1,
            languages: null,
        });
    }

    function handleSelectLink(link) {
        setState({
            ...state,
            request_type: 1,
            url: link.url,
            file: '',
            tool_link_id: link.id,
            link_type_id: link.link_type.id,
            languages: link.languages.map(language => ({ value: language.id, label: language.name }))
        });
    }

    function handleChangeLinkType(link_type_id) {
        setState({
            ...state,
            link_type_id,
            url: '',
            file: '',
        });
    }

    function handleUpdateLink() {
        if (state.link_type_id > 1 && state.url === '') {
            enqueueSnackbar(t('url_enter'), { variant: 'warning' });
        }
        else if (state.link_type_id === 1 && document.getElementById('upload-link-file').files[0] == null && state.url === '') {
            enqueueSnackbar(t('file.select'), { variant: 'warning' });
        }
        else if (!state.languages) {
            enqueueSnackbar(t('language_select'), { variant: 'warning' });
        }
        else {
            if (state.request_type === 0) {
                setState({ ...state, loadingAdd: true });
            }
            else {
                setState({ ...state, loadingEdit: true });
            }
        }
    }

    // ---------- Handle Get Tool ---------- \\
    useEffect(() => {
        if (state.loadingGet) {
            getTool(tool.id).then(res => {
                if (res?.status === 200) {
                    setState({
                        ...state,
                        loadingGet: false,
                        tool_links: res.data.data.tool.tool_links,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingGet: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingGet]);

    // ------------- Handle Add Link ------------- \\
    useEffect(() => {
        if (state.loadingAdd) {
            addLink(tool.id, {
                url: state.url,
                link_type_id: state.link_type_id,
                languages: state.languages.map(language => language.value),
            }).then(res => {
                if (res?.status === 200) {
                    if (state.link_type_id === 1) {
                        setState({
                            ...state,
                            loadingAdd: false,
                            loadingUploadFile: true,
                            tool_link_id: res.data.data.tool_link.id,
                        });
                    }
                    else {
                        enqueueSnackbar(res.data.message, { variant: 'success' });
                        setState({ ...state, loadingAdd: false, loadingGet: true });
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingAdd: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingAdd]);

    // ------------- Handle Edit Link ------------- \\
    useEffect(() => {
        if (state.loadingEdit) {
            editLink(tool.id, state.tool_link_id, {
                url: state.url,
                link_type_id: state.link_type_id,
                languages: state.languages.map(language => language.value),
            }).then(res => {
                if (res?.status === 200) {
                    if (state.link_type_id === 1) {
                        setState({
                            ...state,
                            loadingEdit: false,
                            loadingUploadFile: true,
                            tool_link_id: res.data.data.tool_link.id,
                        });
                    }
                    else {
                        enqueueSnackbar(res.data.message, { variant: 'success' });
                        setState({ ...state, loadingEdit: false, loadingGet: true });
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingEdit: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingEdit]);

    // ---------- Handle Upload File to Link ---------- \\
    useEffect(() => {
        if (state.loadingUploadFile) {

            let formData = new FormData();
            formData.append('document', document.getElementById('upload-link-file').files[0]);
    
            uploadFileLink(tool.id, state.tool_link_id, formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({ ...state, loadingUploadFile: false, loadingGet: true });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingUploadFile: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingUploadFile])

    // ------------- Handle Delete Link ------------- \\
    useEffect(() => {
        if (state.loadingDelete) {
            deleteLink(tool.id, state.tool_link_id).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    setState({
                        ...state,
                        loadingDelete: false, 
                        loadingGet: true,
                        request_type: 0,
                        url: '',
                        file: '',
                        tool_link_id: undefined,
                        link_type_id: 1,
                        languages: null,
                    });
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loadingDelete: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingDelete]);

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {getTranslation('name', tool)}
                </p>
                <div style={styles.border}>
                    {/* -------------------- LINK TYPE -------------------- */}
                    <Grid container alignItems="center" style={style.p0_10}>
                        <Grid item xs={12} sm={4}>
                            <span>{t('type')}</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectLinkTypes
                                link_type_id={state.link_type_id}
                                setLinkTypeId={value => handleChangeLinkType(value)}
                            />
                        </Grid>
                    </Grid>
                    <Divider style={style.m15_0} />
                    {/* -------------------- FILE OR URL -------------------- */}
                    <Grid container alignItems="center" style={style.p0_10}>
                        <Grid item xs={12} sm={4}>
                            {state.link_type_id > 1 ? (
                                <span>Url</span>
                            ) : (
                                <span>{t('file.to.add')}</span>
                            )}
                        </Grid>
                        <Grid  item xs={12} sm={8}>
                            {state.link_type_id > 1 ? (
                                <InputText
                                    placeholder="Url"
                                    value={state.url}
                                    onChange={e => setState({ ...state, url: e.target.value })}
                                />
                            ) : (
                                <>
                                    <InputFile
                                        inputId="link-file"
                                    />
                                    {state.request_type === 1 && state.url !== '' && (
                                        <Button variant="contained" color="secondary"
                                            href={fileUrl + state.url} target="_blank"
                                            style={{ width: '100%', marginTop: 5, color: 'white', padding: '3px 16px' }}
                                        >
                                            <IconFile />
                                            <span style={style.ml5}>
                                                {t('document') + ' ' + getFileExtension(state.url)}
                                            </span>
                                        </Button>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Divider style={style.m15_0} />
                    {/* -------------------- LINK LANGUAGES -------------------- */}
                    <Grid container alignItems="center" style={style.p0_10}>
                        <Grid item xs={12} sm={4}>
                            <span>{t('language')}</span>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SelectMultiple
                                value={state.languages}
                                onChange={value => setState({ ...state, languages: value })}
                                options={languages.map(language => ({ label: t(language.label), value: language.id }))}
                            />
                        </Grid>
                    </Grid>
                    <Divider style={style.m15_0} />
                    {/* -------------------- BUTTONS -------------------- */}
                    <Grid container alignItems="center" justifyContent="center">
                        {state.request_type === 0 ? (
                            <Button
                                onClick={handleUpdateLink}
                                variant="contained"
                                disabled={isLoading()}
                            >
                                <IconAdd />
                                <span style={style.ml5}>{t('link.add.this')}</span>
                            </Button>
                        ) : (
                            <>
                                <Button
                                    onClick={handleOnCancel}
                                    variant="contained"
                                    disabled={isLoading()}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    onClick={handleUpdateLink}
                                    variant="contained"
                                    disabled={isLoading()}
                                    style={style.ml10}
                                >
                                    <IconSave />
                                    <span style={style.ml5}>{t('save')}</span>
                                </Button>
                            </>
                        )}
                    </Grid>
                </div>
                {/* -------------------- LINK LIST -------------------- */}
                <Grid container alignItems="center" style={style.p15}>
                    <span style={style.fwB}>{t('links.added')}</span>
                </Grid>
                {isLoading() ? (
                    <Spinner />
                ) : (
                    <>
                        {state.tool_links.length > 0 ? (
                            state.tool_links.map(link => (
                                <div key={link.id} className="link-container">
                                    <Tooltip title={t('edit')}
                                        item={
                                            <div
                                                onClick={() => handleSelectLink(link)}
                                                className="link-edit"
                                            >
                                                <span>{getTranslation('name', link.link_type) + ' : '}</span>
                                                <br />
                                                <span>{link.url || t('none')}</span>
                                            </div>
                                        }
                                    />
                                    <Tooltip title={t('delete')}
                                        item={
                                            <div
                                                onClick={() => setState({...state, loadingDelete: true, tool_link_id: link.id })}
                                                className="link-delete"
                                            >
                                                <IconClear style={{ fontSize: 18 }} />
                                            </div>
                                        }
                                    />
                                </div>
                            ))
                        ) : (
                            <Grid container alignItems="center" style={styles.none}>
                                <span>{t('none')}</span>
                            </Grid>
                        )}
                    </>
                )}
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// ---------------------------------------- SELECT LINK TYPES ---------------------------------------- \\
const SelectLinkTypes = ({ link_type_id, setLinkTypeId }) => {
    const { t } = useTranslation();

    const [state, setState] = useState({
        loading: true,
        data: undefined,
        error: undefined, 
    });

    useEffect(() => {
        getToolsResources().then(res => {
            if (res?.status === 200) {
                setState(state => ({
                    ...state,
                    loading: false,
                    data: res.data.data.link_types
                }));
            }
            else {
                console.log(res);
                setState(state => ({
                    ...state,
                    loading: false,
                    error: res
                }));
            }
        });
    }, []);

    if (state.loading) return <span>{t('loading')}</span>;
    if (state.error) return <span>{t('error.occured')}</span>;

    return (
        <SelectOutlined
            value={link_type_id}
            onChange={e => setLinkTypeId(e.target.value)}
        >
            {state.data.map(link_type => (
                <MenuItem key={link_type.id} value={link_type.id}>
                    <span>{link_type.name}</span>
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

export default LinksModal;
