import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogContent, DialogActions, Divider, Button } from '@material-ui/core';

// Services
import { addSoftTool } from 'services/requests';
import { initialLanguagesObject, servicesColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import SoftToolForm from './soft-tool-form';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- CREATE SOFT TOOL ---------------------------------------- \\
const CreateSoftTool = ({ onClose, refetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        loading: false,
        checkLevels: { 1: false, 2: false, 3: false, 4: false },

        // Tool Card Data
        names: initialLanguagesObject(),
        descriptions: initialLanguagesObject(),
        active: '0',
        soft_tool_type_id: 1,
        number: '',
        participants: [],
        equipment: null,
        preparation: null,
        duration: null,
        phase: null,
        levels: [],
        audience: null,
        usable_online: '0',
    });

    function handleAddSoftTool() {
        if (Object.values(state.names).every(name => name === '')) {
            enqueueSnackbar(t('name.enter.one'), { variant: 'warning' });
        }
        else if (state.soft_tool_type_id === -1 || (
            state.soft_tool_type_id === 1 && (
                state.number === ''
                || state.participants.length === 0
                || state.equipment == null
                || state.preparation == null
                || state.duration == null
                || state.phase == null
                || state.levels.length === 0
                || state.audience == null
            )
        )) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if(state.loading) {
            const card_filters = {
                number: state.number,
                duration: state.duration,
                participants: state.participants,
                equipment: state.equipment,
                preparation: state.preparation,
                phase: state.phase,
                levels: state.levels,
                audience: state.audience,
                usable_online: state.usable_online,
            };

            addSoftTool({
                soft_tool_type_id: state.soft_tool_type_id,
                active: state.active,
                name: state.names,
                description: state.descriptions,
                card_filters: state.soft_tool_type_id === 1 ? card_filters : null,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth={mediaMD} fullScreen={!mediaMD}>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('tool.add')}
                        </p>
                        <SoftToolForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleAddSoftTool}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateSoftTool;
