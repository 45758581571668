import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateCleaCompetence from './create-clea-competence';
import UpdateCleaCompetence from './update-clea-competence';
import DeleteCleaCompetence from './delete-clea-competence';
import Tooltip from 'components/items/tooltip';

// Style
import style from 'style';

// ------------------------------------- CLEA COMPETENCES -------------------------------------- \\
const CleaCompetences = ({ domain_id, sub_domain, refetch }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        clea_competences: sub_domain.clea_competences,
        clea_competence: null,
        openCreate: false,
        openUpdate: false,
        openDelete: false,
    });

    function handleOpenModal(event, open, clea_competence) {
        event.stopPropagation();
        setState({ ...state, [open]: true, clea_competence });
    }

    return (
        <>
            {/* ---------------------- CLEA COMPETENCES LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: '#e0e0e0' }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={10} style={style.p10}>
                            <span>{t('clea.competence')}</span>
                        </Grid>
                        <Grid item xs={2} style={{ ...style.p10, textAlign: 'center' }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('clea.competence')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- CLEA COMPETENCE LIST ---------------------- */}
            <div>
                {state.clea_competences?.length > 0 ? (
                    state.clea_competences.map(clea_competence => (
                        <Fragment key={clea_competence.id}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={10} style={{ padding: 10 }}>
                                    <span>
                                        {sub_domain.position}.{clea_competence.position} {getTranslation('name', clea_competence)}
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ padding: 10, textAlign: 'center' }}>
                                    <Tooltip title={t('edit')}
                                        item={(
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openUpdate', clea_competence)}
                                                style={{ padding: 8 }}
                                            >
                                                <IconEdit />
                                            </IconButton>
                                        )}
                                    />
                                    <Tooltip title={t('delete')}
                                        item={(
                                            <IconButton
                                                onClick={e => handleOpenModal(e, 'openDelete', clea_competence)}
                                                style={{ padding: 8 }}
                                            >
                                                <IconDelete />
                                            </IconButton>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <>
                        <Grid style={style.p10}>
                            <span>{t('none')}</span>
                        </Grid>
                        <Divider />
                    </>
                )}
            </div>
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreate: true })}
                    className="fab"
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('clea.competence.add')}
                </span>
            </div>
            {/* ---------------------- CREATE CLEA COMPETENCE ---------------------- */}
            {state.openCreate && (
                <CreateCleaCompetence
                    onClose={() => setState({ ...state, openCreate: false })}
                    refetch={refetch}
                    domain_id={domain_id}
                    sub_domain_id={sub_domain.id}
                />
            )}
            {/* ---------------------- UPDATE CLEA COMPETENCE ---------------------- */}
            {state.openUpdate && (
                <UpdateCleaCompetence
                    onClose={() => setState({ ...state, openUpdate: false })}
                    refetch={refetch}
                    domain_id={domain_id}
                    sub_domain_id={sub_domain.id}
                    clea_competence={state.clea_competence}
                />
            )}
            {/* ---------------------- DELETE CLEA COMPETENCE ---------------------- */}
            {state.openDelete && (
                <DeleteCleaCompetence
                    onClose={() => setState({ ...state, openDelete: false })}
                    refetch={refetch}
                    domain_id={domain_id}
                    sub_domain_id={sub_domain.id}
                    clea_competence={state.clea_competence}
                />
            )}
        </>
    );
};

export default CleaCompetences;
