import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Dialog, DialogActions, DialogContent, Divider, Button } from '@material-ui/core';

// Services
import { createContract } from 'services/requests';
import { servicesColor } from 'services/helpers';

// Components
import ContractForm from './contract-form';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- CREATE CONTRACT ---------------------------------------- \\
const CreateContract = ({ onClose, refetch }) => {
    const  { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const match = useRouteMatch();
    const CLIENT_ID = match.params.clientId;

    const [state, setState] = useState({
        loading: false,
        reference: '',
        client: null,
        contract_start_date: null,
        contract_end_date: null,
        company_price: '',
        user_price: '',
        extra_price: '',
        document: undefined,
    });

    function handleCreateContract() {
        if (state.reference === ''
            || (!CLIENT_ID && !state.client)
            || !state.contract_start_date
            || !state.contract_end_date
            || state.company_price === ''
            || state.user_price === ''
            || state.extra_price === ''
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.contract_start_date > state.contract_end_date) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            const file = document.getElementById('upload-contract-document').files[0];
            setState({ ...state, loading: true, document: file });
        }
    }

    useEffect(() => {
        if (state.loading) {

            // Create Contract
            const contract = new FormData();
            contract.append('reference', state.reference);
            contract.append('client_id', CLIENT_ID || state.client.value);
            contract.append('contract_start_date', moment(state.contract_start_date).format('YYYY-MM-DD'));
            contract.append('contract_end_date', moment(state.contract_end_date).format('YYYY-MM-DD'));
            contract.append('company_price', state.company_price);
            contract.append('user_price', state.user_price);
            contract.append('extra_price', state.extra_price);

            if (state.document) {
                contract.append('document', state.document);
            }

            createContract(contract).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch();
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth maxWidth="md">
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent>
                        <p className="dialog-title">
                            {t('contract.add')}
                        </p>
                        <ContractForm state={state} setState={setState} />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button
                            onClick={handleCreateContract}
                            variant="contained"
                            style={{ backgroundColor: servicesColor, color: 'white' }}
                        >
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default CreateContract;
