import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { MenuItem, Grid } from '@material-ui/core';

// Services
import { getUsersResources } from 'services/requests';
import { getStoredUser } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import InputTextGrid from 'components/items/input-text-grid';
import SelectOutlined from 'components/items/select-outlined';
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// ---------------------------------------- USER FORM ---------------------------------------- \\
const UserForm = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaSM } = useMediaQueries();

    return (
        <>
            {/* -------------------- LAST NAME -------------------- */}
            <InputTextGrid
                placeholder={t('last.name')}
                value={state.last_name}
                onChange={e => setState({ ...state, last_name: e.target.value })}
                required
            />
            {/* -------------------- FIRST NAME -------------------- */}
            <InputTextGrid
                placeholder={t('first.name')}
                value={state.first_name}
                onChange={e => setState({ ...state, first_name: e.target.value })}
                required
            />
            {/* -------------------- EMAIL -------------------- */}
            <InputTextGrid
                placeholder={t('email')}
                value={state.email}
                onChange={e => setState({ ...state, email: e.target.value })}
            />
            {/* -------------------- ROLE -------------------- */}
            <Grid container alignItems="center" style={style.m5_0}>
                <Grid item xs={12} sm={4} style={{ paddingRight: mediaSM ? 5 : 0 }}>
                    <b className="input-title">{t('role')}</b>
                </Grid>
                <Grid item xs={12} sm={8} style={{ paddingLeft: mediaSM ? 5 : 0 }}>
                    <SelectRole
                        role_id={state.role_id}
                        setRoleId={role_id => setState({ ...state, role_id })}
                    />
                </Grid>
            </Grid>
            {/* -------------------- COMPANIES -------------------- */}
            <SelectCompanies
                parentState={state}
                setParentState={setState}
            />
        </>
    );
};

// ---------------------------------------- SELECT ROLE -------------------------------------- \\
const SelectRole = ({ role_id, setRoleId }) => {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getUsersResources().then(res => {
            if (res?.status === 200) {
                setRoles(res.data.data.roles);
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectOutlined
            value={role_id}
            onChange={e => setRoleId(e.target.value)}
        >
            {roles.map(role => (
                <MenuItem key={role.id} value={role.id}>
                    <span>{role.name}</span>
                </MenuItem>
            ))}
        </SelectOutlined>
    );
};

// -------------------------------------- SELECT COMPANIES ----------------------------------- \\
const SelectCompanies = ({ parentState, setParentState }) => {
    const { t } = useTranslation(); 
    const { mediaSM } = useMediaQueries();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;
    const [companies, setCompanies] = useState([]);

    const onSetCompanyId = company_id => {
        if (parentState.companies_access) {

            let companies_access = parentState.companies_access;
            let index = companies_access.findIndex(c => c.value === company_id);

            if (index !== -1) {
                companies_access.splice(index, 1);
            }

            setParentState({ ...parentState, company_id, companies_access });
        }
        else {
            setParentState({ ...parentState, company_id });
        }
    };

    useEffect(() => {
        getUsersResources().then(res => {
            if (res?.status === 200) {
                setCompanies(res.data.data.companies.map(c => ({ value: c.id, label: c.name })));
            }
            else {
                console.log(res);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* -------------------- USER COMPANY -------------------- */}
            <Grid container alignItems="center" style={style.m5_0}>
                <Grid item xs={12} sm={4} style={{ paddingRight: mediaSM ? 5 : 0 }}>
                    <b className="input-title">{t('company')}</b><FieldRequired />
                </Grid>
                <Grid item xs={12} sm={8} style={{ paddingLeft: mediaSM ? 5 : 0 }}>
                    <Select
                        placeholder={t('company')}
                        value={parentState.company_id ? companies.find(c => c.value === parentState.company_id) : null}
                        onChange={company => onSetCompanyId(company ? company.value : null)}
                        options={companies}
                    />
                </Grid>
            </Grid>
            {/* -------------------- ACCESSIBLE COMPANIES -------------------- */}
            {(ROLE_ID === 1 && (parentState.role_id > 1 && parentState.role_id < 5)) && (
                <div style={{ marginTop: 24 }}>
                    <b className="input-title">{t('companies.accessible')}</b>
                    <Select
                        placeholder={t('companies.accessible')}
                        value={parentState.companies_access}
                        onChange={companies_access => setParentState({ ...parentState, companies_access })}
                        options={companies.filter(c => c.id !== parentState.company_id)}
                        isMulti
                    />
                </div>
            )}
        </>
    );
};

export default UserForm;
