import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, Grid } from '@material-ui/core';
import { Build as IconTools, People as IconPeople, Public as IconPublic, BusinessCenter as IconSector } from '@material-ui/icons';

// Services
import useMediaQueries from 'services/media';

// Components
import Footer from '../common/footer';

// Assets
import LogoFSE from 'assets/images/logo-fse.png';
import LogoMobipass from 'assets/images/mobipass-white.png';
import LogoMobitrain from 'assets/images/mobitrain-white.png';
import LogoMobitools from 'assets/images/mobitools-white.png';
import HomePicture from 'assets/images/home.jpg';
import MobipackPicture from 'assets/images/mobipack.jpg';
import BackgroundPicture from 'assets/images/background.jpg';
import LogoErasmus from 'assets/images/erasmus.png';
import Video1Poster from 'assets/images/mobipass-poster.png';
import Video2Poster from 'assets/images/chemin-faisant-poster.png';

// Style
import style from '../../style';

const videoURL = 'https://zerobarrier.eu/storage/videos/';

const styles = {
    cards: {
        padding: 10,
        border: '3px solid rgba(60, 60, 60)',
        marginBottom: 25,
        borderRadius: 3,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    textTools: {
        color: 'white',
        padding: 10,
    },
    videos: {
        width: '100%',
        height: 'auto',
        borderRadius: 3,
    },
    home_part3_title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingBottom: 25,
        margin: 0,
    },
    home_part4_title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '25px 25px 0px 25px',
        margin: 0,
    },
};

// ---------------------------------------- HOME ---------------------------------------- \\
const HomeZeroBarrier = () => {
    return (
        <>
            <div className="main">
                <Part1 />
                <Part2 />
                <Part3 />
                <Part4 />
                <Part5 />
            </div>
            <Footer />
        </>
    );
};

// ------------------------------------ HOME CONTENT ------------------------------------ \\
const Part1 = () => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    return (
        <>
            {mediaMD ? (
                <div style={{
                    backgroundImage: 'url(' + HomePicture + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    textAlign: 'right'
                }}>
                    <div style={style.p25}>
                        <p style={{ fontSize: 26, fontWeight: 'bolder', margin: 'auto' }}>
                            {t('home.part1.title1.1')}<br />
                            {t('home.part1.title1.2')}<br />
                            {t('home.part1.title1.3')}<br />
                            {t('home.part1.title1.4')}
                        </p>
                    </div>
                    <div style={style.p25}>
                        <p style={{ fontSize: 20, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)', margin: 'auto' }}>
                            {t('home.part1.title2.1')}<br />
                            {t('home.part1.title2.2')}<br />
                            {t('home.part1.title2.3')}<br />
                            {t('home.part1.title2.4')}
                        </p>
                    </div>
                    <div style={style.p25}>
                        <img alt="Logo FSE" src={LogoFSE} style={{ height: 100 }} />
                    </div>
                </div>
            ) : (
                <div className="row" style={{
                    backgroundImage: 'url(' + HomePicture + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    textAlign: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{ margin: '25px 15px', padding: 10, border: '3px solid rgba(60, 60, 60)', borderRadius: 3, backgroundColor: 'rgba(255, 255, 255, 0.75)' }}>
                        <p style={{ fontSize: 18, fontWeight: 'bold', margin: 'auto' }}>
                            {t('home.part1.title1.1')}<br />
                            {t('home.part1.title1.2')}<br />
                            {t('home.part1.title1.3')}<br />
                            {t('home.part1.title1.4')}
                        </p>
                        <p style={{ fontSize: 14, fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)', margin: 'auto' }}>
                            {t('home.part1.title2.1')}<br />
                            {t('home.part1.title2.2')}<br />
                            {t('home.part1.title2.3')}<br />
                            {t('home.part1.title2.4')}
                        </p>
                        <img alt="Logo FSE" src={LogoFSE} style={{ height: 65 }} />
                    </div>
                </div>
            )}
        </>
    );
};

const Part2 = () => {
    const { t } = useTranslation();
    const { mediaLG } = useMediaQueries();

    return (
        <Grid container alignItems="center" style={style.p25}>
            <Grid item xs={false} lg={2} />
            <Grid
                item xs={12} lg={4}
                container
                justifyContent={mediaLG ? 'flex-start' : 'center'}
            >
                <p>
                    <b>{t('home.part2.title1.1')} :</b><br />
                    • {t('home.part2.title1.2')}<br />
                    • {t('home.part2.title1.3')}<br />
                    &nbsp;<br />
                    <b>{t('home.part2.title2.1')} :</b><br />
                    • {t('home.part2.title2.2')}<br />
                    • {t('home.part2.title2.3')}<br />
                    • {t('home.part2.title2.4')}
                </p>
            </Grid>
            <Grid
                item xs={12} lg={4}
                container
                justifyContent="center"
            >
                <img
                    alt="mobipack"
                    src={MobipackPicture}
                    style={{ width: 'auto', height: 'auto', maxHeight: 235, maxWidth: '100%' }}
                />
            </Grid>
        </Grid>
    );
};

const Part3 = () => {
    const { t } = useTranslation();

    return (
        <div style={{ backgroundImage: 'url(' + BackgroundPicture + ')', backgroundSize: 'cover', padding: '25px 0' }}>
            <p style={styles.home_part3_title}>
                {t('home.part3.title')}
            </p>
            <div className="row">
                <div className="offset-md-2 col-md-4">
                    <video controls={true} src={videoURL + 'mobipass.mp4'} style={styles.videos} poster={Video1Poster} />
                    <p style={{ textAlign: 'center', marginBottom: 15 }}>{t('home.part3.video1')}</p>
                </div>
                <div className="col-md-4">
                    <video controls={true} src={videoURL + 'chemin-faisant.mp4'} style={styles.videos} poster={Video2Poster} />
                    <p style={{ textAlign: 'center', marginBottom: 15 }}>{t('home.part3.video2')}</p>
                </div>
            </div>
            <div className="row" style={style.mb15}>
                <div className="offset-md-2 col-md-8">
                    <video controls={true} src={videoURL + 'zerobarrier-presentation.mp4'} style={styles.videos} />
                    <p style={style.taC}>{t('home.part3.video3')}</p>
                </div>
            </div>
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div style={styles.cards}>
                        <p style={{ fontWeight: 'bold', margin: 'auto' }}>{t('home.part3.content1.title')} :</p>
                        <div style={style.p10}>
                            <p>
                                <IconPublic style={style.mr10} />
                                {t('home.part3.content1.1')}
                            </p>
                        </div>
                        <div style={style.p10}>
                            <p>
                                <IconPeople style={style.mr10} />
                                {t('home.part3.content1.2')}
                            </p>
                        </div>
                        <div style={style.p10}>
                            <p>
                                <IconSector style={style.mr10} />
                                {t('home.part3.content1.3')}
                            </p>
                        </div>
                        <div style={style.p10}>
                            <p>
                                <IconTools style={style.mr10} />
                                {t('home.part3.content1.4')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div style={styles.cards}>
                        <p style={style.mb10}>
                            <b>{t('home.part3.content2.title')}</b><br />
                            {t('home.part3.content2.1')}
                        </p>
                        <p style={style.mb10}>
                            • {t('home.part3.content2.2')}<br />
                            • {t('home.part3.content2.3')}<br />
                            • {t('home.part3.content2.4')}
                        </p>
                        <p>
                            « {t('home.part3.content2.5')} »<br />
                            <b style={{ fontStyle: 'italic' }}>Estelle Demesse, {t('home.part3.content2.6')}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div style={styles.cards}>
                        <p>
                            <b>{t('home.part3.content3.title')}</b><br />
                            « {t('home.part3.content3.1')} »<br />
                            <b style={{ fontStyle: 'italic' }}>Julie, {t('home.part3.content3.2')}</b>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Part4 = () => {
    const { t } = useTranslation();

    return (
        <>
            <p style={styles.home_part4_title}>
                {t('home.part4.title')}
            </p>
            <Grid container alignItems="center">
                <Grid item xs={false} md={1} lg={2} />
                <Grid
                    item xs={12} md={5} lg={4}
                    style={style.p25}
                >
                    <p>
                        <b>{t('home.part4.content1.title')} :</b><br />
                        • {t('home.part4.content1.1')}<br />
                        • {t('home.part4.content1.2')}<br />
                        • {t('home.part4.content1.3')}<br />
                        • {t('home.part4.content1.4')}<br />
                        • {t('home.part4.content1.5')}
                    </p>
                </Grid>
                <Grid
                    item xs={12} md={5} lg={4}
                    style={style.p25}
                >
                    <p>
                        <b>{t('home.part4.content2.title')} :</b><br />
                        • {t('home.part4.content2.1')}<br />
                        • {t('home.part4.content2.2')}<br />
                        • {t('home.part4.content2.3')}<br />
                        • {t('home.part4.content2.4')}
                    </p>
                </Grid>
            </Grid>
            <Divider />
        </>
    );
};

const Part5 = () => {
    const { t } = useTranslation();

    return (
        <Grid container style={style.p25}>
            <Grid item xs={12} lg={4} style={style.p10}>
                <div style={{ backgroundColor: '#3A88C8', borderRadius: 3, textAlign: 'center', height: '100%' }}>
                    <img alt="Logo Mobipass" src={LogoMobipass} style={{ height: 75 }} />
                    <p style={styles.textTools}>
                        • {t('home.part5.content1.1')}<br />
                        • {t('home.part5.content1.2')}
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} lg={4} style={style.p10}>
                <div style={{ backgroundColor: '#E99300', borderRadius: 3, textAlign: 'center', height: '100%' }}>
                    <img alt="Logo Mobitrain" src={LogoMobitrain} style={{ height: 75 }} />
                    <p style={styles.textTools}>
                        • {t('home.part5.content2.1')}<br />
                        • {t('home.part5.content2.2')}
                    </p>
                    <img alt="Logo Erasmus" src={LogoErasmus} style={{ height: 50, margin: '5px 0' }} />
                </div>
            </Grid>
            <Grid item xs={12} lg={4} style={style.p10}>
                <div style={{ backgroundColor: '#BD2363', borderRadius: 3, textAlign: 'center', height: '100%' }}>
                    <img alt="Logo Mobitools" src={LogoMobitools} style={{ height: 75 }} />
                    <p style={styles.textTools}>
                        • {t('home.part5.content3')}
                    </p>
                </div>
            </Grid>
        </Grid>
    );
};

export default HomeZeroBarrier;
