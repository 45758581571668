import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Dialog, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import { SaveAlt as IconSaveAlt } from '@material-ui/icons';

// Services
import { getHardPositionsForUserBySkillbase } from 'services/requests';

// Components
import ExcelExport from './excel-export';
import DatePicker from 'components/items/date-picker';
import FieldRequired from 'components/items/field-required';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ------------------------------------ USER HARD POSITIONS EXPORT -------------------------------------- \\
const UserHardPositionsExport = ({ onClose, user, skillbase }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        date_from: null,
        date_to: null,
        offset: 0,
        limit: 1,
    });

    const onExport = () => {
        if (state.date_from == null || state.date_to == null) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else if (state.date_from > state.date_to) {
            enqueueSnackbar(t('start.date.compared.to.end.date'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    // Handle Get Hard Positions for user by skillbase
    useEffect(() => {
        if (state.loading) {

            const date_from = moment(state.date_from).format('YYYY-MM-DD');
            const date_to = moment(state.date_to).format('YYYY-MM-DD');

            getHardPositionsForUserBySkillbase(state.offset, state.limit, user.id, skillbase.id, date_from, date_to).then(res => {
                if (res?.status === 200) {
                    const hard_positions = res.data.data.hard_positions;
                    if (hard_positions.length > 0) {
                        ExcelExport(skillbase, hard_positions, user, t);
                        onClose();
                    }
                    else {
                        enqueueSnackbar(t('no.data.to.export.found'), { variant: 'warning' });
                        setState({ ...state, loading: false });
                    }
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth>
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={style.pb20}>
                        <p className="dialog-title">
                            {t('export.positions')}
                        </p>
                        <Grid container alignItems="center">
                            {/* ----------------- START DATE -------------------- */}
                            <Grid item xs={6} style={{ paddingRight: 5 }}>
                                <b className="input-title">{t('start.date')}</b><FieldRequired />
                                <DatePicker
                                    placeholder={t('start.date')}
                                    value={state.date_from}
                                    onChange={date_from => setState({ ...state, date_from })}
                                    outlined
                                />
                            </Grid>
                            {/* ----------------- END DATE -------------------- */}
                            <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                <b className="input-title">{t('end.date')}</b><FieldRequired />
                                <DatePicker
                                    placeholder={t('end.date')}
                                    value={state.date_to}
                                    onChange={date_to => setState({ ...state, date_to })}
                                    outlined
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            <span>{t('close')}</span>
                        </Button>
                        <Button
                            onClick={onExport}
                            variant="contained" color="primary"
                        >
                            <span>EXCEL</span>
                            <IconSaveAlt style={{ marginLeft: 5, fontSize: 16 }} />
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog> 
    );
};

export default UserHardPositionsExport;
