import React from 'react';

// ChartJS
import { PolarArea } from 'react-chartjs-2';

// Style
import style from 'style';

// ------------------------------ CHART POLAR AREA ------------------------------ \\
const ChartPolarArea = ({ labels, data }) => {
    return(
        <div className="chart" style={style.p10}>
            <PolarArea
                data={{
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: [
                            "rgba(255, 0, 0, 0.6)",
                            "rgba(200, 200, 0, 0.6)",
                            "rgba(200, 0, 200, 0.6)",
                            "rgba(0, 200, 200, 0.6)",
                            "rgba(0, 0, 255, 0.6)",
                            "rgba(240, 140, 20, 0.6)",
                            "rgba(20, 240, 20, 0.6)",
                            "rgba(230, 170, 230, 0.6)",
                            "rgba(20, 20, 20, 0.6)",
                            "rgba(30, 140, 50, 0.6)",
                        ],
                    }]
                }}
                options={{
                    maintainAspectRatio: false,
                    scale: { min: 0, max: 4, ticks: { maxTicksLimit: 8, min: 0, max: 4, z: 1, backdropColor: 'rgba(250, 250, 250, 0)', stepSize: 1 } }
                }}
            />
        </div>
    );
};

export default ChartPolarArea;
