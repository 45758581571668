import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getTranslation } from 'services/helpers';
import { addFormation, getFormationsResources, getCompanyList, getSkillbases, getCompanyFormationModels, getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FormationFormPage1 from './formation-form/formation-form-page-1';
import FormationFormPage2 from './formation-form/formation-form-page-2';
import FormationFormPage3 from './formation-form/formation-form-page-3';
import FormationFormPage4 from './formation-form/formation-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- CREATE FORMATION ---------------------------------------- \\
const CreateFormation = () => {
    const { mediaMD } = useMediaQueries();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const storedUser = getStoredUser();
    const COMPANY_ID = storedUser ? storedUser.company?.id : null;

    const [state, setState] = useState({
        loading: false,
        loadingResources: true,
        errorResources: false,
        page: 1,
        actionType: 'CREATE',
        companies: [],

        // Page 1
        places: [],
        place_id: 1,
        place_details: '',
        types: [],
        type_id: 1,
        type_details: '',
        program: '',

        // Page 2
        authorizedRefetchMembers: false,
        trainers_company_id: COMPANY_ID,
        trainerOptions: [],
        trainers: storedUser ? [{ value: storedUser.id, label: storedUser.full_name }] : [],
        learners_company_id: COMPANY_ID,
        learnerOptions: [],
        learners: [],

        // Page 3
        name: '',
        descriptorMenu: 'model',
        useFormationModelName: false,
        formationModels: [],
        authorizedRefetchModels: false,
        formation_model_company_id: COMPANY_ID,
        authorizedRefetchModel: false,
        formation_model_id: null,
        skillbases: [],
        authorizedRefetchSkillbase: false,
        skillbase_id: null,
        skillbase: null,
        descriptors: null,

        // Page 4
        sessionIsOpen: null,
        sessions: [{
            formation_date: null,
            start: '',
            end: '',
            comment: ''
        }],
    });

    // Handle Get Resources
    useEffect(() => {
        if (state.loadingResources) {
            axios.all([
                getFormationsResources(),
                getCompanyList(),
                getCompanyMembers(COMPANY_ID),
                getSkillbases(),
                getCompanyFormationModels(COMPANY_ID),
            ])
            .then(axios.spread(function (
                res_resources,
                res_companies,
                res_company_members,
                res_skillbases,
                res_formation_models,
            ) {
                if (res_resources?.status === 200 && res_companies?.status === 200 && res_skillbases?.status === 200 && res_formation_models?.status === 200) {
                    setState(state => ({
                        ...state,
                        loadingResources: false,
                        places: res_resources.data.data.places,
                        types: res_resources.data.data.types,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        trainerOptions: res_company_members.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                        learnerOptions: res_company_members.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                        formationModels: res_formation_models.data.data.formation_models.map(fm => ({ value: fm.id, label: fm.name, model: fm })),
                    }));
                }
                else {
                    console.log(res_resources + res_companies + res_skillbases + res_formation_models);
                    setState(state => ({ ...state, loadingResources: false, errorResources: true }));
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);
    
    // Handle Create Formation
    useEffect(() => {
        if (state.loading) {

            let competences = [], knowledges = [], skills = [];
            Object.values(state.descriptors).forEach(descriptor => {
                competences = competences.concat(descriptor.filter(d => d.includes('competence_')));
                knowledges = knowledges.concat(descriptor.filter(d => d.includes('knowledge_')));
                skills = skills.concat(descriptor.filter(d => d.includes('skill_')));
            });

            competences = competences.map(competence => competence.split('competence_')[1]);
            knowledges = knowledges.map(knowledge => knowledge.split('knowledge_')[1]);
            skills = skills.map(skill => skill.split('skill_')[1]);

            addFormation({
                place_id: state.place_id,
                place_details: state.place_details,
                type_id: state.type_id,
                type_details: state.type_details,
                program: state.program,
                trainers: state.trainers.map(trainer => trainer.value),
                learners: state.learners.map(learner => learner.value),
                name: state.name,
                formation_model_id: state.formation_model_id,
                skillbase_id: state.skillbase_id,
                competences,
                knowledges,
                skills,
                sessions: state.sessions,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobitrain/formation`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar" style={{ flexDirection: mediaMD ? 'row' : 'column-reverse' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobitrain/formation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('training.add')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            {/* -------------------- CONTAINER -------------------- */}
            {state.loadingResources || state.loading ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <FormationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <FormationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <FormationFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 4 && (
                        <FormationFormPage4 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default CreateFormation;
