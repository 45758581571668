import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid, Checkbox } from '@material-ui/core';

// Services
import { getCompanyMembers } from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import InputText from 'components/items/input-text';
import FieldRequired from 'components/items/field-required';
import Select from 'components/items/react-select';

// --------------------------------------- CERTIFICATION FORM PAGE 2 ---------------------------------------- \\
const CertificationFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const navigateToPage3 = () => {
        if ((state.company_id == null && state.company_name === '')
            || (state.assessor_id == null && state.assessor_name === '')
        ) {
            enqueueSnackbar(t('fields.required'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    };

    useEffect(() => {
        if (state.authorizedRefetchAssessorCompanies) {
            if (state.company_id) {
                getCompanyMembers(state.company_id, true).then(res => {
                    if (res?.status === 200) {
                        setState({
                            ...state,
                            authorizedRefetchAssessorCompanies: false,
                            assessors: res.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                            assessor_id: null,
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, authorizedRefetchAssessorCompanies: false, assessors: [], assessor_id: null });
                    }
                });
            }
            else {
                setState({ ...state, authorizedRefetchAssessorCompanies: false, assessors: [], assessor_id: null });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.company_id]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- COMPANY -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('company')}</b><FieldRequired />
                            <Grid container alignItems="center" style={{ paddingBottom: 10 }}>
                                <Checkbox
                                    checked={state.company_manual_entry}
                                    onChange={e => setState({ ...state, company_manual_entry: e.target.checked, company_id: null, company_name: '' })}
                                    style={{ padding: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>{t('manual.entry')}</span>
                            </Grid>
                            {state.company_manual_entry ? (
                                <InputText
                                    placeholder={t('company')}
                                    value={state.company_name}
                                    onChange={e => setState({ ...state, company_name: e.target.value })}
                                />
                            ) : (
                                <Select
                                    placeholder={t('company')}
                                    value={state.company_id ? state.companies.find(c => c.value === state.company_id) : null}
                                    onChange={company => setState({ ...state, company_id: company ? company.value : null, authorizedRefetchAssessorCompanies: true })}
                                    options={state.companies}
                                />
                            )}
                        </div>
                        {/* -------------------- ASSESSOR -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('assessor')}</b><FieldRequired />
                            <Grid container alignItems="center" style={{ paddingBottom: 10 }}>
                                <Checkbox
                                    checked={state.assessor_manual_entry}
                                    onChange={e => setState({ ...state, assessor_manual_entry: e.target.checked, assessor_id: null, assessor_name: '' })}
                                    style={{ padding: 0 }}
                                />
                                <span style={{ marginLeft: 5 }}>{t('manual.entry')}</span>
                            </Grid>
                            {state.assessor_manual_entry ? (
                                <InputText
                                    placeholder={t('assessor')}
                                    value={state.assessor_name}
                                    onChange={e => setState({ ...state, assessor_name: e.target.value })}
                                />
                            ) : (
                                <Select
                                    placeholder={t('assessor')}
                                    value={state.assessor_id ? state.assessors.find(a => a.value === state.assessor_id) : null}
                                    onChange={assessor => setState({ ...state, assessor_id: assessor ? assessor.value : null })}
                                    options={state.assessors}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>2 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CertificationFormPage2;
