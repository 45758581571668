// Tools to Create PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Services
import { mobipassColor, getTranslation, mobipassColor2 } from 'services/helpers';

// Assets
import LogoZB from 'assets/images/zerobarrier.png';

// --------------------------------- GENEREATE PDF -------------------------------------- \\
const GeneratePDF = (skillbase, t) => {

    // Create new PDF
    const pdf = new jsPDF('landscape');

    // Logo Zero Barrier
    pdf.addImage(LogoZB, 5, 5, 31.5, 6.61, '', 'FAST');

    // Create and populate the table of skillbase with empty positions
    skillbase.units.forEach(unit => {
        
        const unit_name = getTranslation('name', unit);
        let body = [];

        unit.learning_outcomes.forEach(learning => {
            
            // For each descriptor, push an array of the value for each row in the final table
            learning.competences.forEach(competence => {
                body.push([
                    getTranslation('name', learning),
                    competence.numerotation + t('competence.numerotation') + ' ' + getTranslation('name', competence),
                    '',
                    '',
                    '',
                ]);
            });
            learning.knowledges.forEach(knowledge => {
                body.push([
                    getTranslation('name', learning),
                    knowledge.numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', knowledge),
                    '',
                    '',
                    '',
                ]);
            });
            learning.skills.forEach(skill => {
                body.push([
                    getTranslation('name', learning),
                    skill.numerotation + t('skill.numerotation') + ' ' + getTranslation('name', skill),
                    '',
                    '',
                    '',
                ]);
            });
        });

        pdf.autoTable({
            startY: 20,
            theme: 'grid',
            headStyles: { fontSize: 12, minCellWidth: 30, textColor: 240},
            head: [
                [
                    {
                        content: unit_name,
                        colSpan: 5,
                        styles: { halign: 'center', fillColor: [mobipassColor] },
                    }
                ],
                [
                    {   
                        content: t('learning.outcomes'),
                        styles: { fontSize: 10, halign: 'center', fillColor: [mobipassColor2], textColor: 2 }
                    },
                    {   
                        content: t('descriptors'),
                        styles: { fontSize: 10, halign: 'center', fillColor: [mobipassColor2], textColor: 2 }
                    },
                    {   
                        content: t('mastery.0'),
                        styles: { fontSize: 10, halign: 'center', fillColor: [mobipassColor2], textColor: 2 }
                    },
                    {   
                        content: t('mastery.1'),
                        styles: { fontSize: 10, halign: 'center', fillColor: [mobipassColor2], textColor: 2 }
                    },
                    {   
                        content: t('mastery.2'),
                        styles: { fontSize: 10, halign: 'center', fillColor: [mobipassColor2], textColor: 2 }
                    },
                ],
            ],
            body: body,
            bodyStyles: {fontSize: 7, textColor: 2, minCellWidth: 30},
            
        });
        pdf.addPage();
    })
    
    pdf.save(getTranslation('name', skillbase) +  '.pdf');
};

export default GeneratePDF;
