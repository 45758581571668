import React from 'react';

// Material-UI
import Grid from '@material-ui/core/Grid';

// Services
import useMediaQueries from 'services/media';

// Components
import DatePicker from './date-picker';
import FieldRequired from './field-required';

// Style
import style from 'style';

// ---------------------------------------- DATE PICKER GRID ---------------------------------------- \\
const DatePickerGrid = ({ placeholder, value, onChange, outlined, required }) => {
    const { mediaSM } = useMediaQueries();

    return (
        <Grid container alignItems="center" style={style.m5_0}>
            <Grid
                item xs={12} sm={4}
                style={{ paddingRight: mediaSM ? 5 : 0 }}
            >
                <b className="input-title">{placeholder}</b>{required && <FieldRequired />}
            </Grid>
            <Grid
                item xs={12} sm={8}
                style={{ paddingLeft: mediaSM ? 5 : 0 }}
            >
                <DatePicker
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    outlined={outlined}
                />
            </Grid>
        </Grid>
    );
};

DatePickerGrid.defaultProps = {
    required: false,
};

export default DatePickerGrid;
