import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';

// Services
import { getFormattedDate, softskillsColor, getTranslation } from 'services/helpers';

// Components
import SoftFormationCertificate from './soft-formation-certificate';

// Style
import style from 'style';

// ---------------------------------- DETAILS SOFT FORMATION ---------------------------------- \\
const DetailsSoftFormation = ({ onClose, soft_formation }) => {
    const { t } = useTranslation();

    return (
        <Dialog open fullWidth>
            <DialogContent>
                <p className="dialog-title">
                    {t('formation.details')}
                </p>
                <Divider />
                {/* ---------------------- DATE ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('date')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {soft_formation.soft_sessions.length > 1 ? (
                            soft_formation.soft_sessions.map(session => (
                                <Fragment key={session.id}>
                                    <span> {getFormattedDate(session.session_date) + " : " + t('from') + " " + moment(session.start, "HH:mm:ss").format("HH:mm") + " " + t('to') + " " + moment(session.end, "HH:mm:ss").format("HH:mm")} </span>
                                    <br />
                                </Fragment>
                            ))
                        ):(
                            <span>{getFormattedDate(soft_formation.soft_sessions[0].session_date) + " : " + t('from') + " " + moment(soft_formation.soft_sessions[0].start, "HH:mm:ss").format("HH:mm") + " " + t('to') + " " + moment(soft_formation.soft_sessions[0].end, "HH:mm:ss").format("HH:mm")}</span>
                        )}
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- EVALUATION CONTEXT ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('softskill')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{getTranslation('name', soft_formation.softskill) + " - " + t('level') + " " + soft_formation.soft_level.level}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- COMPANY ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('company.host')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        <span>{soft_formation.company.name}</span>
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- TRAINERS ---------------------- */}
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} style={style.p10}>
                        <span style={{ color: softskillsColor }}>
                            {t('trainers')}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={8} style={style.p10}>
                        {soft_formation.trainers != null && soft_formation.trainers.map((trainer, index) => (
                            <Fragment key={trainer.id}>
                                {index !== 0 && (
                                    <br />
                                )}
                                <span>
                                    {trainer.full_name}
                                    &nbsp;
                                    {trainer.company_name != null ? '(' + trainer.company_name + ')' : ''}
                                </span>
                            </Fragment>
                        ))}
                    </Grid>
                </Grid>
                <Divider />
                {/* ---------------------- LEARNERS ---------------------- */}
                <Grid style={style.p10}>
                    <span style={{ color: softskillsColor }}>
                        {t('learners')}
                    </span>
                </Grid>
                <Grid>
                    {soft_formation.learners != null && soft_formation.learners.map((learner, index) => (
                        <Grid
                            key={learner.id}
                            container alignItems="center" justifyContent="space-between"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: 4, padding: 6, marginTop: index === 0 ? 0 : 6 }}
                        >
                            <span>
                                {learner.full_name} {learner.company_name ? '(' + learner.company_name + ')' : ''}
                            </span>
                            <SoftFormationCertificate
                                soft_formation={soft_formation}
                                level={soft_formation.soft_level.level}
                                learner={learner}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions style={style.jcC}>
                <Button onClick={onClose} variant="contained">
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DetailsSoftFormation;
