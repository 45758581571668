import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

// Material-UI
import { List, ListItem, Divider } from '@material-ui/core';
import { Build as IconHardTools, Loyalty as IconSoftTools } from '@material-ui/icons';

// Services
import { mobitoolsColor, softskillsColor } from 'services/helpers';
import { getSoftskillsAccess } from 'services/storage';

// Components
import Profile from 'components/common/profile';

// Style
import style from 'style';

// ---------------------------------------- SIDE MENU ---------------------------------------- \\
const SideMenu = ({ position }) => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const [path, setPath] = useState('tools');

    useEffect(() => {
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    return (
        <div className="side-menu" style={{ position }}>
            <div className="profile">
                <Profile />
            </div>
            <Divider />
            <div className="menu">
                <List component="nav" style={style.p0}>
                    {/* -------------------- TOOLS -------------------- */}
                    <ListItem 
                        button
                        selected={path === 'tools'} 
                        onClick={() => history.push('/mobitools/tools')} 
                        style={style.p12_16}
                    >
                        <IconHardTools style={{ color: mobitoolsColor }} />
                        <span className="side-menu-title">{t('hardskills.tools')}</span>
                    </ListItem>
                    {/* -------------------- SOFT SKILLS TOOLS -------------------- */}
                    {getSoftskillsAccess() === '1' && (
                        <>
                            <Divider />
                            <ListItem 
                                button
                                selected={path === 'softskills-tools'} 
                                onClick={() => history.push('/mobitools/softskills-tools')} 
                                style={style.p12_16}
                            >
                                <IconSoftTools style={{ color: softskillsColor }} />
                                <span className="side-menu-title">{t('softskills.tools')}</span>
                            </ListItem>
                            <Divider />
                        </>
                    )}
                </List>
            </div>
        </div>
    );
};

export default SideMenu;
