import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { Divider, IconButton, Fab, Grid } from '@material-ui/core';
import { Edit as IconEdit, Delete as IconDelete, Add as IconAdd } from '@material-ui/icons';

// Services
import { getTranslation } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import CreateCompetence from './create-competence';
import UpdateCompetence from './update-cometence';
import DeleteCompetence from './delete-competence';

// Style
import style from 'style';

// ---------------------------------------- COMPETENCES ---------------------------------------- \\
const Competences = ({ unitId, learning }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();

    const [state, setState] = useState({
        competences: learning?.competences || [],
        competence: null,
        openCreateCompetence: false,
        openUpdateCompetence: false,
        openDeleteCompetence: false,
    });

    function handleOpenModal(event, open, competence) {
        event.stopPropagation();
        setState({ ...state, [open]: true, competence });
    }

    return (
        <>
            {/* ---------------------- LIST HEADERS ---------------------- */}
            <Grid container style={{ backgroundColor: '#e0e0e0' }}>
                {mediaMD ? (
                    <>
                        <Grid item xs={8} style={style.p10}>
                            <span>{t('competence')}</span>
                        </Grid>
                        <Grid item xs={4} style={{ ...style.p10, ...style.taC }}>
                            <span>{t('action')}</span>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12} style={{ ...style.p10, ...style.taC }}>
                        <span>{t('competences')}</span>
                    </Grid>
                )}
            </Grid>
            {/* ---------------------- COMPETENCE LIST ---------------------- */}
            {state.competences.length > 0 ? (
                state.competences.map(competence => (
                    <Fragment key={competence.id}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} md={8} style={{ padding: 10 }}>
                                <span>
                                    {getTranslation('name', competence)}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ ...style.p10, ...style.taC }}>
                                <IconButton
                                    onClick={e => handleOpenModal(e, 'openUpdateCompetence', competence)}
                                    style={{ padding: 6 }}
                                >
                                    <IconEdit />
                                </IconButton>
                                <IconButton
                                    onClick={e => handleOpenModal(e, 'openDeleteCompetence', competence)}
                                    style={{ padding: 6 }}
                                >
                                    <IconDelete />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Fragment>
                ))
            ) : (
                <>
                    <Grid style={style.p10}>
                        <span>{t('none')}</span>
                    </Grid>
                    <Divider />
                </>
            )}
            <div className="fab-container">
                <Fab
                    onClick={() => setState({ ...state, openCreateCompetence: true })}
                    className="fab"
                >
                    <IconAdd />
                </Fab>
                <span style={{ ...style.ttU, ...style.ml10 }}>
                    {t('competence.add')}
                </span>
            </div>
            {/* ---------------------- CREATE COMPETENCE ---------------------- */}
            {state.openCreateCompetence && (
                <CreateCompetence
                    onClose={() => setState({ ...state, openCreateCompetence: false })}
                    refetch={competences => setState({ ...state, openCreateCompetence: false, competences })}
                    competences={state.competences}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
            {/* ---------------------- UPDATE COMPETENCE ---------------------- */}
            {state.openUpdateCompetence && (
                <UpdateCompetence
                    onClose={() => setState({ ...state, openUpdateCompetence: false })}
                    refetch={competences => setState({ ...state, openUpdateCompetence: false, competences })}
                    competences={state.competences}
                    competence={state.competence}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
            {/* ---------------------- DELETE COMPETENCE ---------------------- */}
            {state.openDeleteCompetence && (
                <DeleteCompetence
                    onClose={() => setState({ ...state, openDeleteCompetence: false })}
                    refetch={competences => setState({ ...state, openDeleteCompetence: false, competences })}
                    competences={state.competences}
                    competence={state.competence}
                    unitId={unitId}
                    learningId={learning.id}
                />
            )}
        </>
    );
};

export default Competences;
