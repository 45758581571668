import React from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Grid, Divider } from '@material-ui/core';

// Services
import useMediaQueries from 'services/media';
import { languages } from 'services/constants';

// Components
import InputTextGrid from 'components/items/input-text-grid';

// Style
import style from 'style';

// ---------------------------------------- NEWS FORM PAGE 2 ---------------------------------------- \\
const NewsFormPage2 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { mediaMD } = useMediaQueries();
    const { enqueueSnackbar } = useSnackbar();

    function handleDescriptions(event, code) {
        let descriptions = state.descriptions;
        descriptions[code] = event.target.value;
        setState({ ...state, descriptions });
    }

    function navigateToPage3() {
        if (Object.values(state.descriptions).every(description => description === '')) {
            enqueueSnackbar(t('news.enter.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, page: 3 });
        }
    }

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, paddingTop: 24 }}>
            {/* -------------------- DESCRIPTION -------------------- */}
                <div style={{...style.taC, margin: '20px 0 10px 0'}}>
                    <span style={style.fwB}>{t('news.description')}</span>
                </div>
                {languages.map(language => (
                    <InputTextGrid
                        key={language.id}
                        multiline
                        disabled={state.disabled.find(item => item === language.code)}
                        placeholder={t(language.label)}
                        value={state.descriptions[language.code]}
                        onChange={e => handleDescriptions(e, language.code)}
                    />
                ))}
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" style={{ padding: '15px 25px' }}>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={() => setState({ ...state, page: 1 })} variant="contained">
                        {t('page.previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center"
                >
                    <span>2 / 4</span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button onClick={navigateToPage3} variant="contained">
                        {t('page.next')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default NewsFormPage2;
