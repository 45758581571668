import moment from 'moment';

// --------------------------------------------------------------- \\
// ---------------------------- TOKEN ---------------------------- \\
// --------------------------------------------------------------- \\

export const setToken = (data, remember = false) => {
    if (remember) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('remember', remember);
    }
    else {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('user', JSON.stringify(data.user));
    }
};

export const getToken = () => {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
};

export const clearToken = () => {
    sessionStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('remember');
};

// --------------------------------------------------------------- \\
// ------------------------- AUTHENTICATION ---------------------- \\
// --------------------------------------------------------------- \\

export const isAuthenticated = () => {
    if (sessionStorage.getItem('token') || localStorage.getItem('token')) {
        return true;
    } 
    return false;
};

// --------------------------------------------------------------- \\
// ----------------------------- LOCALE -------------------------- \\
// --------------------------------------------------------------- \\

export const setLocale = locale => {
    localStorage.setItem('locale', locale);
};

export const getLocale = () => {
    return localStorage.getItem('locale');
};

// --------------------------------------------------------------- \\
// ------------------------ STORED USER -------------------------- \\
// --------------------------------------------------------------- \\

export const setStoredUser = user => {
    if (localStorage.getItem('remember')) {
        localStorage.setItem('user', JSON.stringify(user));
    }
    else {
        sessionStorage.setItem('user', JSON.stringify(user));
    }
};

export const getStoredUser = () => {
    return JSON.parse(sessionStorage.getItem('user')) || JSON.parse(localStorage.getItem('user'));
};

// --------------------------------------------------------------- \\
// ----------------------- LOG ACTIVITY -------------------------- \\
// --------------------------------------------------------------- \\

export const setLogActivity = () => {
    sessionStorage.setItem('activity', moment().format('YYYY-MM-DD'));
};

export const getLogActivity = () => {
    return sessionStorage.getItem('activity');
};

// --------------------------------------------------------------- \\
// ---------------------- COOKIE MESSAGE ------------------------- \\
// --------------------------------------------------------------- \\

export const setCookieDisplayed = bool => {
    localStorage.setItem('cookie5', bool);
};

export const cookieDisplayed = () => {
    return localStorage.getItem('cookie5');
};

// --------------------------------------------------------------- \\
// --------------------------- NEWS ------------------------------ \\
// --------------------------------------------------------------- \\

export const setRefetchNews = () => {
    localStorage.setItem('refetch_news', moment().format('YYYY-MM-DD h:mm:ss'));
};

export const getRefetchNews = () => {
    return localStorage.getItem('refetch_news');
};

// --------------------------------------------------------------- \\
// ---------------------- SOFTSKILLS ACCESS ---------------------- \\
// --------------------------------------------------------------- \\

export const setSoftskillsAccess = has_softskills_access => {
    sessionStorage.setItem('has_softskills_access', has_softskills_access);
};

export const getSoftskillsAccess = () => {
    return sessionStorage.getItem('has_softskills_access');
};

// --------------------------------------------------------------- \\
// ------------------------- CLEA ACCESS ------------------------- \\
// --------------------------------------------------------------- \\

export const setCleaAccess = has_clea_access => {
    sessionStorage.setItem('has_clea_access', has_clea_access);
};

export const getCleaAccess = () => {
    return sessionStorage.getItem('has_clea_access');
};
