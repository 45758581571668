import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// File Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Button, Grid } from '@material-ui/core';
import { Email as IconMail, Send as IconSend, SaveAlt as IconSave } from '@material-ui/icons';

// Services
import { isValidEmail, servicesColor } from 'services/helpers';
import { contact } from 'services/requests';
import { getStoredUser } from 'services/storage';
import { theme } from 'services/helpers';

// Components
import Header from './common/header';
import Footer from './common/footer';
import FooterMobiRhin from './common/footer-mobirhin';
import Spinner from './items/spinner';
import FieldRequired from './items/field-required';
import InputText from './items/input-text';

// Assets
import ZerobarrierTutorial from 'assets/pdf/tutorial-zb.pdf'
import FormationSupport from 'assets/pdf/formation-support.pdf';

// Style
import style from 'style';

// ---------------------------------------- SUPPORT ---------------------------------------- \\
const Support = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const storedUser = getStoredUser();

    const [state, setState] = useState({
        loading: false,
        last_name: storedUser?.last_name,
        first_name: storedUser?.first_name,
        email: storedUser?.email,
        legal_status: '',
        message: '',
        openSnackbar: false,
    });

    const sendEmail = () => {
        if (state.last_name === '') {
            document.getElementById('last_name').focus();
            enqueueSnackbar(t('last.name.enter'), { variant: 'warning' });
        }
        else if (state.first_name === '') {
            document.getElementById('first_name').focus();
            enqueueSnackbar(t('first.name.enter'), { variant: 'warning' });
        }
        else if (state.email === '') {
            document.getElementById('email').focus();
            enqueueSnackbar(t('email.enter'), { variant: 'warning' });
        }
        else if (!isValidEmail(state.email)) {
            document.getElementById('email').focus();
            enqueueSnackbar(t('enter.valid.email'), { variant: 'warning' });
        }
        else if (state.message === '') {
            document.getElementById('message').focus();
            enqueueSnackbar(t('message.enter'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loading) {
            contact({
                last_name: state.last_name,
                first_name: state.first_name,
                legal_status: state.legal_status,
                email: state.email,
                message: state.message,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'succes' });
                    setState(state => ({ ...state, loading: false }));
                }
                else {
                    console.log(res);
                    enqueueSnackbar(res, { variant: 'error' });
                    setState(state => ({ ...state, loading: false }));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- HEADER -------------------- */}
            <Header />
            <div className="main">
                {state.loading ? (
                    <Spinner />
                ) : (
                    <Grid container>
                        <Grid item xs={1} md={2} lg={3} />
                        <Grid item xs={10} md={8} lg={6}>
                            <div className="full-container" style={{ padding: 24 }}>
                                {/* -------------------- DOWNLOAD DOCUMENTS -------------------- */}
                                <Button
                                    onClick={() => saveAs(ZerobarrierTutorial, 'TutorielZerobarrier.pdf')}
                                    variant="contained"
                                    className="list-btn"
                                    style={{ width: '100%' }}
                                >
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <span>{t('download.tutorial')}</span>
                                        <IconSave  style={{ fontSize: 20 }} />
                                    </Grid>
                                </Button>
                                <Button
                                    onClick={() => saveAs(FormationSupport, 'SupportDeFormation.pdf')}
                                    variant="contained"
                                    className="list-btn"
                                    style={{ width: '100%', marginTop: 10 }}
                                >
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <span>{t('download.formation.support')}</span>
                                        <IconSave  style={{ fontSize: 20 }} />
                                    </Grid>
                                </Button>
                                {/* -------------------- SUPPORT -------------------- */}
                                <Grid
                                    container alignItems="center" justifyContent="space-between"
                                    style={{ ...style.p10, backgroundColor: servicesColor, borderTopLeftRadius: 4, borderTopRightRadius: 4, marginTop: 24 }}
                                >
                                    <span style={style.cW}>
                                        {t('support')}
                                    </span>
                                    <span style={style.cW}>
                                        <IconMail style={style.mr5} />
                                        {theme === 'mobirhin' ? (
                                                <span>
                                                    support@mobirhin.eu
                                                </span>
                                            ) : (
                                                <span>
                                                    support@zerobarrier.eu
                                                </span>
                                            )}
                                    </span>
                                </Grid>
                                <form style={{ border: '1px solid lightgray', padding: '12px 24px', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
                                    <div className="form-row">
                                        <div className="form-group col-lg-6">
                                            <b className="input-title">{t('last.name')}</b><FieldRequired />
                                            <InputText
                                                id="last_name"
                                                placeholder={t('last.name')}
                                                value={state.last_name}
                                                onChange={e => setState({ ...state, last_name: e.target.value })}
                                            />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <b className="input-title">{t('first.name')}</b><FieldRequired />
                                            <InputText
                                                id="first.name"
                                                placeholder={t('first.name')}
                                                value={state.first_name}
                                                onChange={e => setState({ ...state, first_name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <b className="input-title">{t('email')}</b><FieldRequired />
                                        <InputText
                                            id="email"
                                            placeholder={t('email')}
                                            value={state.email}
                                            onChange={e => setState({ ...state, email: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <b className="input-title">{t('company.name')}</b>
                                        <InputText
                                            placeholder={t('company.name')}
                                            value={state.legal_status}
                                            onChange={e => setState({ ...state, legal_status: e.target.value })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <b className="input-title">{t('message')}</b><FieldRequired />
                                        <InputText
                                            id="message"
                                            placeholder={t('message')}
                                            value={state.message}
                                            onChange={e => setState({ ...state, message: e.target.value })}
                                            multiline
                                        />
                                    </div>
                                    <div style={style.taC}>
                                        <Button onClick={sendEmail} variant="contained">
                                            {t('send')}
                                            <IconSend style={{ marginLeft: 5, fontSize: 16 }} />
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </div>
            {/* -------------------- FOOTER -------------------- */}
            {theme === 'mobirhin' ? (
                <FooterMobiRhin />
            ) : (
                <Footer />
            )}
        </>
    );
};

export default Support;
