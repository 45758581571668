import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Button, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';

// Services
import { getCleaDomains, addLinkToLearningOutcome } from 'services/requests';

// Components
import CleaForm from './clea-form';
import Spinner from 'components/items/spinner';

// Style
import style from 'style';

// ---------------------------------------- ADD LINK TO LEARNING ---------------------------------------- \\
const AddLinkToLearning = ({ onClose, refetch, learning, clea_competences }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        loading: false,
        clea_domains: [],
        clea_competence_ids: clea_competences.map(c => c.id) || [],
    });

    useEffect(() => {
        getCleaDomains().then(res => {
            if (res?.status === 200) {
                setState({ ...state, clea_domains: res.data.data.clea_domains });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.loading) {
            addLinkToLearningOutcome(learning.id, {
                clea_competence_ids: state.clea_competence_ids
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    refetch(res.data.data.learning_outcome.clea_competences);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <Dialog open fullWidth maxWidth="lg">
            {state.loading ? (
                <Spinner />
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <p className="dialog-title">
                            {t('clea.competence.link')}
                        </p>
                        <CleaForm
                            state={state}
                            setState={setState}
                        />
                    </DialogContent>
                    <Divider />
                    <DialogActions style={style.jcSB}>
                        <Button onClick={onClose} variant="contained">
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => setState({ ...state, loading: true })} variant="contained">
                            {t('validate')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default AddLinkToLearning;
