import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Divider, Button } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';
import { getTranslation } from 'services/helpers';
import {
    editFormation, getFormation, getSkillbase,
    getFormationsResources, getCompanyList, getSkillbases, getCompanyFormationModels
} from 'services/requests';
import useMediaQueries from 'services/media';

// Components
import FormationFormPage1 from './formation-form/formation-form-page-1';
import FormationFormPage2 from './formation-form/formation-form-page-2';
import FormationFormPage3 from './formation-form/formation-form-page-3';
import FormationFormPage4 from './formation-form/formation-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// Style
import style from 'style';

// ---------------------------------------- UPDATE FORMATION ---------------------------------------- \\
const UpdateFormation = () => {
    const { t } =useTranslation();
    const { mediaMD } = useMediaQueries();
    const { formationId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const storedUser = getStoredUser();
    const COMPANY_ID = (storedUser && storedUser.company) ? storedUser.company.id : null;

    const [state, setState] = useState({
        loading: false,
        loadingFormation: true,
        loadingResources: false,
        errorResources: false,
        page: 1,
        actionType: 'UPDATE',
        companies: [],

        // Page 1
        places: [],
        place_id: 1,
        place_details: '',
        types: [],
        type_id: 1,
        type_details: '',
        program: '',

        // Page 2
        authorizedRefetchMembers: false,
        trainers_company_id: null,
        trainerOptions: [],
        trainers: [],
        learners_company_id: null,
        learnerOptions: [],
        learners: [],

        // Page 3
        name: '',
        descriptorMenu: 'model',
        useFormationModelName: false,
        formationModels: [],
        authorizedRefetchModels: false,
        formation_model_company_id: null,
        authorizedRefetchModel: false,
        formation_model_id: null,
        skillbases: [],
        authorizedRefetchSkillbase: false,
        skillbase_id: null,
        skillbase: null,
        descriptors: null,

        // Page 4
        sessionIsOpen: null,
        sessions: [{
            formation_date: null,
            start: '',
            end: '',
            comment: ''
        }],
    });

    useEffect(() => {
        getFormation(formationId).then(res => {
            if (res?.status === 200) {

                const formation = res.data.data.formation;

                setState({
                    ...state,
                    loadingFormation: false,
                    loadingResources: true,
                    formation,
                    name: formation.name,
                    place_id: formation.place?.id,
                    place_details: formation.place_details || '',
                    type_id: formation.type?.id,
                    type_details: formation.type_details || '',
                    program: formation.program || '',
                    trainers: formation.formation_trainers.map(i => i.trainer).map(t => ({ value: t.id, label: t.full_name })),
                    learners: formation.formation_learners.map(i => i.learner).map(l => ({ value: l.id, label: l.full_name })),
                    descriptorMenu: formation.formation_model ? 'model' : 'skillbase',
                    formation_model_id: formation.formation_model ? formation.formation_model.id : null,
                    formation_model_company_id: formation.formation_model ? formation.formation_model.company?.id : null,
                    skillbase_id: formation.skillbase?.id,
                    sessions: [{
                        formation_date: formation.formation_date,
                        start: formation.start,
                        end: formation.end,
                        comment: formation.comment || '',
                    }],
                });
            }
            else {
                console.log(res);
                setState({ ...state, errorResources: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

    // Handle Get Resources
    useEffect(() => {
        if (state.loadingResources && state.formation) {
            axios.all([
                getFormationsResources(),
                getCompanyList(),
                getSkillbases(),
                getCompanyFormationModels(state.formation_model_company_id || COMPANY_ID),
                getSkillbase(state.skillbase_id),
            ]).then(axios.spread(function (
                res_resources,
                res_companies,
                res_skillbases,
                res_formation_models,
                res_skillbase,
            ) {
                if (res_resources?.status === 200 && res_companies?.status === 200 && res_skillbases?.status === 200 && res_formation_models?.status === 200 && res_skillbase?.status === 200) {

                    const skillbase = res_skillbase.data.data.skillbase;

                    let descriptors = {};
                    skillbase.units.forEach(unit => {
                        unit.learning_outcomes.forEach(learning => {
                            descriptors[learning.id] = [];
                        });
                    });

                    state.formation.competences.forEach(competence => {
                        let learningDescriptors = descriptors[competence.learning_outcome_id];
                        learningDescriptors.push('competence_' + competence.id);
                    });

                    state.formation.knowledges.forEach(knowledge => {
                        let learningDescriptors = descriptors[knowledge.learning_outcome_id];
                        learningDescriptors.push('knowledge_' + knowledge.id);
                    });

                    state.formation.skills.forEach(skill => {
                        let learningDescriptors = descriptors[skill.learning_outcome_id];
                        learningDescriptors.push('skill_' + skill.id);
                    });

                    setState(state => ({
                        ...state,
                        loadingResources: false,
                        places: res_resources.data.data.places,
                        types: res_resources.data.data.types,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        formationModels: res_formation_models.data.data.formation_models.map(fm => ({ value: fm.id, label: fm.name, model: fm })),
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                        skillbase,
                        descriptors,
                    }));
                }
                else {
                    console.log(res_resources + res_companies + res_skillbases + res_formation_models + res_skillbase);
                    setState(state => ({ ...state, loadingResources: false, errorResources: true }));
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    // Handle Update Formation
    useEffect(() => {
        if (state.loading) {

            let competences = [], knowledges = [], skills = [];
            Object.values(state.descriptors).forEach(descriptor => {
                competences = competences.concat(descriptor.filter(d => d.includes('competence_')));
                knowledges = knowledges.concat(descriptor.filter(d => d.includes('knowledge_')));
                skills = skills.concat(descriptor.filter(d => d.includes('skill_')));
            });

            competences = competences.map(competence => competence.split('competence_')[1]);
            knowledges = knowledges.map(knowledge => knowledge.split('knowledge_')[1]);
            skills = skills.map(skill => skill.split('skill_')[1]);

            editFormation(state.formation.id, {
                name: state.name,
                place_id: state.place_id,
                place_details: state.place_details,
                type_id: state.type_id,
                type_details: state.type_details,
                program: state.program,
                trainers: state.trainers.map(trainer => trainer.value),
                learners: state.learners.map(learner => learner.value),
                formation_model_id: state.formation_model_id,
                skillbase_id: state.skillbase_id,
                competences,
                knowledges,
                skills,
                sessions: state.sessions,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobitrain/formation`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar" style={{flexDirection: mediaMD ? "row" : "column-reverse"}}>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobitrain/formation`)}
                        variant="contained"
                    >
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container
                    justifyContent="center"
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {t('training.edit')}
                    </span>
                </Grid>
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            {/* -------------------- CONTAINER -------------------- */}
            {(state.loadingFormation || state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <FormationFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <FormationFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 3 && (
                        <FormationFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 5 -------------------- */}
                    {state.page === 4 && (
                        <FormationFormPage4 state={state} setState={setState} />
                    )} 
                </>
            )}
        </>
    );
};

export default UpdateFormation;
