import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Serives
import useMediaQueries from 'services/media';
import { createHardPosition, getUser, getSkillbases } from 'services/requests';
import { getTranslation } from 'services/helpers';
import { getStoredUser } from 'services/storage';

// Components
import HardPositionsForm from './hardpositions-form';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';
import DatePicker from 'components/items/date-picker';
import FieldRequired from 'components/items/field-required';

// Style
import style from 'style';

// --------------------------------- CREATE HARD POSITION ---------------------------------------- \\
const CreateHardPosition = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { userId } = useParams();
    const storedUser = getStoredUser();
    const ROLE_ID = (storedUser && storedUser.role) ? storedUser.role.id : null;

    const [state, setState] = useState({
        actionType: 'CREATE',
        loadingResources: true,
        errorResources: false,
        loading: false,
        learner: null,
        skillbases: [],
        unit: null,
        loadingSkillbase: false,

        // Hard Position
        position_date: new Date(),
        skillbase_id: null,
        learner_id: userId,
        competence_ids: [],
        knowledge_ids: [],
        skill_ids: [],

        // Last Position
        last_hard_position: null,
        last_competence_ids: [],
        last_knowledge_ids: [],
        last_skill_ids: [],
    });

    const onCreateHardPosition = () => {
        if (state.position_date == null) {
            enqueueSnackbar(t('selet.date.warning'), { variant: 'warning' });
        }
        else if (state.competence_ids.length === 0 && state.knowledge_ids.length === 0 && state.skill_ids.length === 0) {
            enqueueSnackbar(t('descriptor.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getUser(userId), getSkillbases()])
            .then(axios.spread(function (res_user, res_skillbases) {
                if (res_user?.status === 200 && res_skillbases?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        learner: res_user.data.data.user,
                        skillbases: res_skillbases.data.data.skillbases.map(s => ({ value: s.id, label: getTranslation('name', s) })),
                    });
                }
                else {
                    console.log(res_user + res_skillbases);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {
            createHardPosition({
                position_date: moment(state.position_date).format('YYYY-MM-DD'),
                skillbase_id: state.skillbase_id,
                learner_id: state.learner_id,
                competence_ids: state.competence_ids,
                knowledge_ids: state.knowledge_ids,
                skill_ids: state.skill_ids,
            }).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push(`/mobipass/positioning/${userId}`);
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container className="toolbar">
                <Grid
                    item xs={12} md={4}
                    container alignItems="center" justifyContent={mediaMD ? 'flex-start' : 'center'}
                    style={{ marginBottom: mediaMD ? 0 : 10 }}
                >
                    <Button
                        onClick={() => history.push(`/mobipass/positioning/${userId}`)}
                        variant="contained"
                    >
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container justifyContent="center" alignItems="center"
                    style={style.taC}
                >
                    <span style={{ ...style.fwB, ...style.fs16 }}>
                        {ROLE_ID < 5 && (
                            <>
                                {state.learner?.full_name}
                                <br />
                            </>
                        )}
                        {ROLE_ID < 5 ? t('add.positioning') : t('self.positioning')}
                    </span>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container alignItems='center' justifyContent={mediaMD ? 'flex-end' : 'center'}
                    style={{ marginTop: mediaMD ? 0 : 10 }}  
                >
                    <b className="input-title" style={{ paddingRight: 10 }}>
                        {t('date')}<FieldRequired />
                    </b>
                    <DatePicker
                        placeholder={t('date')}
                        value={state.position_date}
                        onChange={value => setState({ ...state, position_date: value })}
                        outlined
                        fullWidth={false}
                    />
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- HARD POSITIONS FORM -------------------- */}
            <div className="full-container" style={{ paddingBottom: 24, paddingTop: 24 }}>
                {(state.loadingResources || state.loading) ? (
                    <Spinner />
                ) : state.errorResources ? (
                    <Error />
                ) : (
                    <HardPositionsForm
                        state={state}
                        setState={setState}
                    />
                )}
            </div>
            <Divider />
            {/* -------------------- SAVE -------------------- */}
            <Grid container alignItems="center" justifyContent="center" style={{ padding: '15px 25px' }}>
                <Button onClick={onCreateHardPosition} variant="contained" color="primary">
                    {t('save')}
                </Button>
            </Grid>
        </>
    );
};

export default CreateHardPosition;
