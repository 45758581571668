import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

// Axios
import axios from 'axios';

// Notistack
import { useSnackbar } from 'notistack';

// Moment
import moment from 'moment';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';

// Services
import { getCompanyList, updateMobility, getMobility, getCompanyMembers } from 'services/requests';
import { getFormattedDate } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import MobilityFormPage1 from './mobility-form/mobility-form-page-1';
import MobilityFormPage2 from './mobility-form/mobility-form-page-2';
import MobilityFormPage3 from './mobility-form/mobility-form-page-3';
import MobilityFormPage4 from './mobility-form/mobility-form-page-4';
import Spinner from 'components/items/spinner';
import Error from 'components/items/error';

// ---------------------------------------- UPDATE MOBILITY ---------------------------------------- \\
const UpdateMobility = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();
    const { mobilityId } = useParams();

    const [state, setState] = useState({
        loadingMobility: true,
        loadingResources: false,
        errorResources: false,
        loading: false,
        page: 1,
        companies: [],
        origin_trainers: [],
        host_trainers: [],
        learners: [],
        formationsOptions: [],

        // Page 1
        start_date: null,
        end_date: null,
        document: null,
        document_url: null,
        description: '',

        // Page 2
        authorizedRefetchOrigin: false,
        origin_company_id: null,
        origin_trainer_manual_entry: false,
        origin_trainer_id: null,
        origin_trainer_name: '',
        learner_id: null,

        // Page 3
        authorizedRefetchHost: false,
        host_company_manual_entry: false,
        host_company_id: null,
        host_company_name: '',
        host_trainer_manual_entry: false,
        host_trainer_id: null,
        host_trainer_name: '',

        // Page 4
        formations: [],
    });

    useEffect(() => {
        getMobility(mobilityId).then(res => {
            if (res?.status === 200) {

                let mobility = res.data.data.mobility;

                setState({
                    ...state,
                    loadingMobility: false,
                    loadingResources: true,
                    start_date: mobility.start_date,
                    end_date: mobility.end_date,
                    description: mobility.description || '',
                    document_url: mobility.document,
                    origin_company_id: mobility.origin_company_id,
                    origin_trainer_manual_entry: mobility.origin_trainer_id != null ? false : true,
                    origin_trainer_id: mobility.origin_trainer_id,
                    origin_trainer_name: mobility.origin_trainer_name,
                    learner_id: mobility.learner?.id,
                    host_company_manual_entry: mobility.host_company_id != null ? false : true,
                    host_company_id: mobility.host_company_id,
                    host_company_name: mobility.host_company_name,
                    host_trainer_manual_entry: mobility.host_trainer_id != null ? false : true,
                    host_trainer_id: mobility.host_trainer_id,
                    host_trainer_name: mobility.host_trainer_name,
                    formations: mobility.formations.map(f => ({ value: f.id, label: getFormattedDate(f.formation_date) + ' - ' + f.name }))
                });
            }
            else {
                console.log(res);
                setState({ ...state, loadingMobility: false, errorResources: true });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (state.loadingResources) {
            axios.all([getCompanyList(), getCompanyMembers(state.origin_company_id), getCompanyMembers(state.host_company_id || 1)])
            .then(axios.spread(function (res_companies, res_origin, res_host) {
                if (res_companies?.status === 200 && res_origin?.status === 200 && res_host?.status === 200) {
                    setState({
                        ...state,
                        loadingResources: false,
                        companies: res_companies.data.data.companies.map(c => ({ value: c.id, label: c.name })),
                        origin_trainers: res_origin.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                        learners: res_origin.data.data.learners.map(l => ({ value: l.id, label: l.full_name })),
                        host_trainers: res_host.data.data.assessors.map(a => ({ value: a.id, label: a.full_name })),
                    });
                }
                else {
                    console.log(res_companies + res_origin + res_host);
                    setState({ ...state, loadingResources: false, errorResources: true });
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loadingResources]);

    useEffect(() => {
        if (state.loading) {

            const formData = new FormData();
            formData.append('start_date', moment(state.start_date).format('YYYY-MM-DD'));
            formData.append('end_date', moment(state.end_date).format('YYYY-MM-DD'));
            formData.append('description', state.description);
            formData.append('origin_company_id', state.origin_company_id != null ? state.origin_company_id : '');
            formData.append('origin_trainer_id', state.origin_trainer_id != null ? state.origin_trainer_id : '');
            formData.append('origin_trainer_name', state.origin_trainer_name);
            formData.append('learner_id', state.learner_id);
            formData.append('host_company_id', state.host_company_id != null ? state.host_company_id : '');
            formData.append('host_company_name', state.host_company_name);
            formData.append('host_trainer_id', state.host_trainer_id != null ? state.host_trainer_id : '');
            formData.append('host_trainer_name', state.host_trainer_name);
            formData.append('formations', state.formations ? JSON.stringify(state.formations.map(f => f.value)) : '');

            if (state.document) {
                formData.append('document', state.document);
            }

            updateMobility(mobilityId, formData).then(res => {
                if (res?.status === 200) {
                    enqueueSnackbar(res.data.message, { variant: 'success' });
                    history.push('/mobitrain/mobility');
                }
                else {
                    enqueueSnackbar(res, { variant: 'error' });
                    setState({ ...state, loading: false });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loading]);

    return (
        <>
            {/* -------------------- TOOLBAR -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/mobitrain/mobility')} variant="contained">
                        {t('cancel')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginTop: mediaMD ? 0 : 10 }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>{t('mobility.edit')}</span>
                </Grid>
            </Grid>
            <Divider />
            {/* -------------------- FORM -------------------- */}
            {(state.loadingMobility || state.loadingResources || state.loading) ? (
                <Spinner />
            ) : state.errorResources ? (
                <Error />
            ) : (
                <>
                    {/* -------------------- PAGE 1 -------------------- */}
                    {state.page === 1 && (
                        <MobilityFormPage1 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 2 -------------------- */}
                    {state.page === 2 && (
                        <MobilityFormPage2 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 3 -------------------- */}
                    {state.page === 3 && (
                        <MobilityFormPage3 state={state} setState={setState} />
                    )}
                    {/* -------------------- PAGE 4 -------------------- */}
                    {state.page === 4 && (
                        <MobilityFormPage4 state={state} setState={setState} />
                    )}
                </>
            )}
        </>
    );
};

export default UpdateMobility;
