import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch, Redirect } from 'react-router-dom';

// Services
import { getSoftskillsAccess } from 'services/storage';
import useMediaQueries from 'services/media';

// Components
import SideMenu from './side-menu';
import Tool from './tool';
import SoftskillsTool from './softskills-tools';
import Header from 'components/common/header';
import Breadcrumb from 'components/common/breadcrumb';
import Settings from 'components/common/settings';
import SideDrawer from 'components/common/side-drawer';

// ---------------------------------------- MOBITOOLS ---------------------------------------- \\
const Mobitools = () => {
    const { mediaLG } = useMediaQueries();

    return (
        <>
            <BreadcrumbsItem to="/mobitools" className="breadcrumb-item">
                MOBITOOLS
            </BreadcrumbsItem>
            <Header />
            <div className="main">
                {mediaLG ? (
                    <SideMenu position="fixed" />
                ) : (
                    <SideDrawer menu={<SideMenu position="initial" />} />
                )}
                <div className="container">
                    <Breadcrumb />
                    <Switch>
                        <Route path="/mobitools/tools">
                            <Tool />
                        </Route>
                        {getSoftskillsAccess() === '1' && (
                            <Route exact path="/mobitools/softskills-tools">
                                <SoftskillsTool />
                            </Route>
                        )}
                        <Route exact path="/mobitools/settings">
                            <Settings />
                        </Route>
                        <Redirect from="*" to="/mobitools/tools" />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export default Mobitools;
