import React from 'react';

// ---------------------------------------- FIELD REQUIRED ---------------------------------------- \\
const FieldRequired = () => {
    return (
        <b style={{ fontSize: 24, color: '#f32c72' }}>*</b>
    );
};

export default FieldRequired;
