import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Moment
import moment from 'moment';

// Notistack
import { useSnackbar } from 'notistack';

// Excel JS
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

// Material-UI
import { IconButton, Snackbar } from '@material-ui/core';
import IconSave from '@material-ui/icons/SaveAlt';

// Services
import { getBills } from 'services/requests';
import { getFormattedDate } from 'services/helpers';

// Components
import Tooltip from 'components/items/tooltip';

// ---------------------------------------- BILL EXPORT ---------------------------------------- \\
const BillExport = ({ data }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        message: t('downloading') + '...0%',
        start: false,
    });

    function getAllBillsToExport(bills, offset) {
        getBills({
            offset: offset,
            limit: data.limit,
            paid: data.paid,
            bill_date: data.bill_date ? moment(data.bill_date).format('YYYY-MM-DD') : null,
            number_bill: data.number_bill,
            contract_id: data.contract_id,
            client_id: data.client_id,
            client_name: data.client_name,
            export_excel: true,
        }).then(res => {
            if (res?.status === 200) {
                
                let res_bills = res.data.data.bills;
                bills = bills.concat(res_bills);

                setState({
                    ...state,
                    message: t('downloading') + '...' + Math.round((100 * bills.length) / data.total_count) + '%',
                });

                if (res_bills.length < data.limit) {
                    createExcelFile(bills);
                }
                else {
                    getAllBillsToExport(bills, offset + data.limit);
                }
            }
            else {
                console.log(res);
                setState({ ...state, start: false });
                enqueueSnackbar(t('download.failed'), { variant: 'error' });
            }
        });
    }

    function createExcelFile(bills) {
        if (bills.length === 0) {
            setState({ ...state, start: false });
            enqueueSnackbar(t('no.data.to.export.was.found'), { variant: 'warning' });
        }
        else {
            // Create Excel File
            var workbook = new ExcelJS.Workbook();

            bills.forEach(bill => {

                // Add Worksheet to the Workbook
                const worksheet_name = bill.number_bill ? bill.number_bill : 'FATEMP' + bill.id;
                var worksheet = workbook.addWorksheet(worksheet_name);

                // Bill Headers
                worksheet.columns = [
                    { header: t('number.bill'), key: 'number_bill', width: 45 },
                    { header: t('paid'), key: 'paid', width: 25 },
                    { header: t('bill.date'), key: 'bill_date', width: 25 },
                    { header: t('contract'), key: 'contract', width: 25 },
                    { header: t('client'), key: 'client', width: 25 },
                    { header: t('total.amount'), key: 'total_amount_TTC', width: 25 },
                    { header: t('deadline.date'), key: 'deadline_date', width: 25 },
                ];
                worksheet.getRow(1).font = { bold: true };

                // Bill Infos
                worksheet.addRow({
                    number_bill: bill.number_bill ? bill.number_bill : 'FATEMP' + bill.id,
                    paid: bill.paid === 0 ? t('no') : t('yes'),
                    bill_date: getFormattedDate(bill.bill_date),
                    contract: bill.contract.reference,
                    client: bill.contract.client.name,
                    total_amount_TTC: bill.total_amount_TTC,
                    deadline_date: bill.deadline_date ? getFormattedDate(bill.deadline_date) : '',
                });

                // Subscriptions
                if (bill.subscriptions.length > 0) {

                    worksheet.addRow([]);
                    worksheet.addRow(['ABONNEMENTS']);

                    // Subscription Headers
                    worksheet.addRow([
                        t('type'),
                        t('company'),
                        t('start.date'),
                        t('end.date'),
                        t('price'),
                        t('items'),
                        t('details'),
                    ]);
                    worksheet.getRow(4).font = { bold: true };
                    worksheet.getRow(5).font = { bold: true };

                    // Subscription Infos
                    bill.subscriptions.forEach(subscription => { 
                        let monthly_subs_table = '';
                        if (subscription.subscription_type.id === 2) {
                            for (const [key, value] of Object.entries(subscription.subs_by_month)) {
                                monthly_subs_table +=  moment(key).format('MM/YYYY') + ' : ' + value + ' ' + t('subscriptions') + '\r\n';
                            }
                        }
                        worksheet.addRow([
                            subscription.subscription_type.label,
                            subscription.company.name,
                            getFormattedDate(subscription.start_date),
                            getFormattedDate(subscription.end_date),
                            subscription.price,
                            subscription.nb_items,
                            monthly_subs_table,
                        ]);
                    });
                }
            });

            // Save Excel File
            workbook.xlsx.writeBuffer().then(buffer => saveAs(new Blob([buffer]), t('bills') + '.xlsx'));
            setState({ ...state, start: false });
            enqueueSnackbar(t('download.complete'), { variant: 'success' });
        }
    }

    // Start Export
    useEffect(() => {
        if (state.start) {
            getAllBillsToExport([], 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.start]);

    return (
        <>
            <Tooltip title={t('export.excel')}
                item={
                    <IconButton
                        className="list-btn"
                        onClick={() => setState({ ...state, start: true })}
                        disabled={state.start}
                    >
                        <IconSave />
                    </IconButton>
                }
            />
            <Snackbar
                open={state.start}
                message={state.message}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            />
        </>
    );
};

export default BillExport;
