import React, { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// Notistack
import { useSnackbar } from 'notistack';

// Material-UI
import { Divider, Button, Grid } from '@material-ui/core';
import { CheckBoxOutlineBlank as UncheckedIcon, CheckBox as CheckedIcon } from '@material-ui/icons';

// Services
import { getSkillbase } from 'services/requests';
import { mobipassColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import Select from 'components/items/react-select';
import FieldRequired from 'components/items/field-required';
import { getTranslation } from 'services/helpers';
import { mobipassColor2 } from 'services/helpers';

// --------------------------------------- CERTIFICATION FORM PAGE 4 ---------------------------------------- \\
const CertificationFormPage4 = ({ state, setState }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaMD } = useMediaQueries();

    const saveCertification = () => {
        if (state.units == null || state.units.length === 0) {
            enqueueSnackbar(t('unit.select.one'), { variant: 'warning' });
        }
        else {
            setState({ ...state, loading: true });
        }
    };

    const onClickUnit = unit_id => {
        let updatedUnits = state.units;
        let index = updatedUnits.findIndex(id => id === unit_id);

        if (index === -1) {
            updatedUnits.push(unit_id);
        }
        else {
            updatedUnits.splice(index, 1);
        }

        setState({ ...state, units: updatedUnits });
    };

    useEffect(() => {
        if (state.authorizedRefetchSkillbases) {
            if (state.skillbase_id) {
                getSkillbase(state.skillbase_id).then(res => {
                    if (res?.status === 200) {
                        setState({
                            ...state,
                            authorizedRefetchSkillbases: false,
                            skillbase: res.data.data.skillbase,
                            units: [],
                        });
                    }
                    else {
                        console.log(res);
                        setState({ ...state, authorizedRefetchSkillbases: false, skillbase: null, units: [] });
                    }
                });
            }
            else {
                setState({ ...state, authorizedRefetchSkillbases: false, skillbase: null, units: [] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.skillbase_id]);

    return (
        <>
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- SKILLBASE -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <b className="input-title">{t('skillbase')}</b>
                            {state.actionType === 'CREATE' && (
                                <Select
                                    placeholder={t('skillbase')}
                                    value={state.skillbase_id ? state.skillbases.find(s => s.value === state.skillbase_id) : null}
                                    onChange={skillbase => setState({ ...state, skillbase_id: skillbase ? skillbase.value : null, authorizedRefetchSkillbases: true })}
                                    options={state.skillbases}
                                />
                            )}
                            {state.actionType === 'UPDATE' && (
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: 4, padding: '6px 10px' }}>
                                    <span>{getTranslation('name', state.skillbase)}</span>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                {/* -------------------- UNITS -------------------- */}
                {state.skillbase != null && (
                    <div style={{ marginTop: 24 }}>
                        <b className="input-title">{t('units.select')}</b><FieldRequired />
                        <div style={{ padding: '10px 20px', backgroundColor: mobipassColor, color: 'white' }}>
                            <span>{t('units.of.learning.outcomes')}</span>
                        </div>
                        {state.skillbase.units.map(unit => (
                            <Fragment key={unit.id}>
                                <Grid
                                    onClick={() => onClickUnit(unit.id)}
                                    container alignItems="center" justifyContent="space-between"
                                    className="list-item-hover"
                                    style={{ padding: '10px 20px', backgroundColor: state.units.includes(unit.id) ? mobipassColor2 : null }}
                                >
                                    <span>{unit.position}. {getTranslation('name', unit)}</span>
                                    {state.units.includes(unit.id) ? (
                                        <CheckedIcon />
                                    ) : (
                                        <UncheckedIcon />
                                    )}
                                </Grid>
                                <Divider />
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>
            <Divider />
            {/* -------------------- FOOTER -------------------- */}
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => setState({ ...state, page: 3 })} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: mediaMD ? 0 : 10 }}>
                    <span>4 / 4</span>
                </Grid>
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-end' : 'center'}>
                    <Button onClick={saveCertification} variant="contained" color="primary">
                        {t('save')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default CertificationFormPage4;
