import React from 'react';
import { useTranslation } from 'react-i18next';

// Material-UI
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

// Services
import { getStoredUser } from 'services/storage';

// Components
import SelectCountry from 'components/select/select-country';
import InputFile from 'components/items/input-file';
import InputText from 'components/items/input-text';
import DatePicker from 'components/items/date-picker';
import FieldRequired from 'components/items/field-required';

// ---------------------------------------- COMPANY FORM ---------------------------------------- \\
const CompanyForm = ({ state, setState }) => {
    const { t } = useTranslation();
    const ROLE_ID = getStoredUser()?.role?.id;

    return (
        <>
            {/* -------------------- COMPANY NAME -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('company.name')}</b><FieldRequired />
                <InputText
                    placeholder={t('company.name')}
                    value={state.name}
                    onChange={e => setState({ ...state, name: e.target.value })}
                />
            </div>
            {/* -------------------- COUNTRY -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('country')}</b><FieldRequired />
                <SelectCountry
                    countryId={state.country_id}
                    setCountryId={country_id => setState({ ...state, country_id })}
                />
            </div>
            {/* -------------------- ADDRESS -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{'N° & ' + t('street')}</b>
                <InputText
                    placeholder={'N° & ' + t('street')}
                    value={state.address}
                    onChange={e => setState({ ...state, address: e.target.value })}
                />
            </div>
            {/* -------------------- ZIP CODE -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('zip.code')}</b>
                <InputText
                    placeholder={t('zip.code')}
                    value={state.zip_code}
                    onChange={e => setState({ ...state, zip_code: e.target.value })}
                />
            </div>
            {/* -------------------- CITY -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('city')}</b>
                <InputText
                    placeholder={t('city')}
                    value={state.city}
                    onChange={e => setState({ ...state, city: e.target.value })}
                />
            </div>
            {/* -------------------- LEGAL STATUS -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('legal.status')}</b>
                <InputText
                    placeholder={t('legal.status')}
                    value={state.legal_status}
                    onChange={e => setState({ ...state, legal_status: e.target.value })}
                />
            </div>
            {/* -------------------- LEADER -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('leader')}</b>
                <InputText
                    placeholder={t('leader')}
                    value={state.leader}
                    onChange={e => setState({ ...state, leader: e.target.value })}
                />
            </div>
            {/* -------------------- CREATION DATE -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('creation.date')}</b>
                <DatePicker
                    placeholder={t('creation.date')}
                    value={state.creation_date}
                    onChange={creation_date => setState({ ...state, creation_date })}
                    outlined
                />
            </div>
            {/* -------------------- EMAIL -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('email')}</b>
                <InputText
                    placeholder={t('email')}
                    value={state.email}
                    onChange={e => setState({ ...state, email: e.target.value })}
                />
            </div>
            {/* -------------------- PHONE -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('phone')}</b>
                <InputText
                    placeholder={t('phone')}
                    value={state.phone}
                    onChange={e => setState({ ...state, phone: e.target.value })}
                />
            </div>
            {/* -------------------- WEBSITE -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('website')}</b>
                <InputText
                    placeholder={t('website')}
                    value={state.website}
                    onChange={e => setState({ ...state, website: e.target.value })}
                />
            </div>
            {/* -------------------- ACTIVITIES -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('activities')}</b>
                <InputText
                    placeholder={t('activities')}
                    value={state.activities}
                    onChange={e => setState({ ...state, activities: e.target.value })}
                    multiline
                />
            </div>
            {/* -------------------- APPROVAL -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('approval')}</b>
                <InputText
                    placeholder={t('approval')}
                    value={state.approval}
                    onChange={e => setState({ ...state, approval: e.target.value })}
                />
            </div>
            {/* -------------------- EMPLOYEES NUMBER -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('employees.number')}</b>
                <InputText
                    placeholder={t('employees.number')}
                    value={state.employees}
                    onChange={e => setState({ ...state, employees: e.target.value })}
                    type="number"
                />
            </div>
            {/* -------------------- DESCRIPTION -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('description')}</b>
                <InputText
                    placeholder={t('description')}
                    value={state.description}
                    onChange={e => setState({ ...state, description: e.target.value })}
                    multiline
                />
            </div>
            {/* -------------------- HAS SOFTSKILL ACCESS -------------------- */}
            {ROLE_ID === 1 && (
                <div style={{ marginTop: 12 }}>
                    <b className="input-title">{t('softskills.access')}</b>
                    <RadioGroup
                        value={state.has_softskills_access}
                        onChange={e => setState({ ...state, has_softskills_access: e.target.value })}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel value="0" control={<Radio style={{ padding: 8 }} />} label={t('no')} />
                        <FormControlLabel value="1" control={<Radio style={{ padding: 8 }} color="primary" />} label={t('yes')} />
                    </RadioGroup>
                </div>
            )}
            {/* -------------------- HAS CLEA ACCESS -------------------- */}
            {ROLE_ID === 1 && (
                <div style={{ marginTop: 12 }}>
                    <b className="input-title">{t('clea.access')}</b>
                    <RadioGroup
                        value={state.has_clea_access}
                        onChange={e => setState({ ...state, has_clea_access: e.target.value })}
                        style={{ flexDirection: 'row' }}
                    >
                        <FormControlLabel value="0" control={<Radio style={{ padding: 8 }} />} label={t('no')} />
                        <FormControlLabel value="1" control={<Radio style={{ padding: 8 }} color="primary" />} label={t('yes')} />
                    </RadioGroup>
                </div>
            )}
            {/* -------------------- COMPANY LOGO -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('logo')}</b>
                <InputFile
                    inputId="company"
                />
            </div>
            {/* -------------------- FOOTER -------------------- */}
            <div style={{ marginTop: 12 }}>
                <b className="input-title">{t('certificate.footer')} (260 {t('characters')} max)</b>
                <InputText
                    placeholder={t('certificate.footer')}
                    value={state.footer}
                    onChange={e => setState({ ...state, footer: e.target.value.length > 260 ? state.footer : e.target.value })}
                    multiline
                />
            </div>
        </>
    );
};

export default CompanyForm;
