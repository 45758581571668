import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// File Saver
import { saveAs } from 'file-saver';

// Material-UI
import { Grid, Button, Divider } from '@material-ui/core';
import { SaveAlt as DownloadIcon } from '@material-ui/icons';

// Services
import { languages } from 'services/constants';
import { softskillsColor } from 'services/helpers';
import useMediaQueries from 'services/media';

// Components
import SpecificAssessmentGrid from './specific-assessment-grid';
import SpecificPlacementGrid from './specific-placement-grid';

// Assets
import GenericAssessmentGridFR from 'assets/generic-assessment-grids/generic-assessment-grid-fr.xlsx';
import GenericAssessmentGridEN from 'assets/generic-assessment-grids/generic-assessment-grid-en.xlsx';
import GenericAssessmentGridES from 'assets/generic-assessment-grids/generic-assessment-grid-es.xlsx';
import GenericAssessmentGridDE from 'assets/generic-assessment-grids/generic-assessment-grid-de.xlsx';
import GenericPlacementGridFR from 'assets/generic-placement-grids/generic-placement-grid-fr.xlsx';
import GenericPlacementGridEN from 'assets/generic-placement-grids/generic-placement-grid-en.xlsx';
import GenericPlacementGridES from 'assets/generic-placement-grids/generic-placement-grid-es.xlsx';
import GenericPlacementGridDE from 'assets/generic-placement-grids/generic-placement-grid-de.xlsx';
import GenericPlacementGridSL from 'assets/generic-placement-grids/generic-placement-grid-sl.xlsx';

const styles = {
    td: {
        padding: 10,
        border: '1px solid lightgray',
        textAlign: 'center',
    },
};

// ---------------------------------------- ASSESSMENT GRIDS ---------------------------------------- \\
const AssessmentGrids = () => {
    const history = useHistory();
    const { mediaMD } = useMediaQueries();
    const { t } = useTranslation();

    return (
        <>
            <Grid container alignItems="center" className="toolbar">
                <Grid item xs={12} md={4} container justifyContent={mediaMD ? 'flex-start' : 'center'}>
                    <Button onClick={() => history.push('/mobipass/softskills')} variant="contained">
                        {t('previous')}
                    </Button>
                </Grid>
                <Grid item xs={12} md={4} style={{ textAlign: 'center', marginTop: mediaMD ? 0 : 10 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                        {t('assessment.grids.generation')}
                    </span>
                </Grid>
            </Grid>
            <Divider />
            <div className="full-container" style={{ paddingBottom: 24, textAlign: 'initial' }}>
                <Grid container>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6}>
                        {/* -------------------- GENERIC PLACEMENT GRIDS -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <GenericPlacementGrids />
                        </div>
                        {/* -------------------- SPECIFIC PLACEMENT GRID -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <SpecificPlacementGrid />
                        </div>
                        {/* -------------------- GENERIC ASSESSMENT GRIDS -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <GenericAssessmentGrids />
                        </div>
                        {/* -------------------- SPECIFIC ASSESSMENT GRID -------------------- */}
                        <div style={{ marginTop: 24 }}>
                            <SpecificAssessmentGrid />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

// ------------------------------------- GENERIC ASSESSMENT GRIDS ----------------------------------- \\
const GenericAssessmentGrids = () => {
    const { t } = useTranslation();

    const onDownloadGenericAssessmentGrid = code => {
        switch (code) {
            case 'fr':
                return saveAs(GenericAssessmentGridFR, t('generic.assessment.grid') + ' FR.xlsx');
            case 'en':
                return saveAs(GenericAssessmentGridEN, t('generic.assessment.grid') + ' EN.xlsx');
            case 'de':
                return saveAs(GenericAssessmentGridDE, t('generic.assessment.grid') + ' DE.xlsx');
            case 'es':
                return saveAs(GenericAssessmentGridES, t('generic.assessment.grid') + ' ES.xlsx');
            default:
                return saveAs(GenericAssessmentGridEN, t('generic.assessment.grid') + ' EN.xlsx');
        }
    };

    return (
        <>
            <b className="input-title">
                {t('download.generic.assessment.grids')}
            </b>
            <table style={{ width: '100%', marginTop: 6, fontSize: 14 }}>
                <thead>
                    <tr>
                        <td style={{ ...styles.td, width: '70%' }}>
                            <b>{t('language')}</b>
                        </td>
                        <td style={{ ...styles.td, width: '30%' }}>
                            <b>{t('download')}</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {languages.filter(l => ['fr', 'en', 'de', 'es'].includes(l.code)).map(language => (
                        <tr key={language.id}>
                            <td style={{ ...styles.td, width: '70%' }}>
                                {t(language.label)}
                            </td>
                            <td style={{ ...styles.td, width: '30%' }}>
                                <Button
                                    onClick={() => onDownloadGenericAssessmentGrid(language.code)}
                                    variant="contained"
                                    style={{ padding: '4px 16px', backgroundColor: softskillsColor, color: 'white' }}
                                >
                                    {language.code}
                                    <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

// -------------------------------------- GENERIC PLACEMENT GRIDS ----------------------------------- \\
const GenericPlacementGrids = () => {
    const { t } = useTranslation();

    const onDownloadGenericPlacementGrid = code => {
        switch (code) {
            case 'fr':
                return saveAs(GenericPlacementGridFR, t('generic.placement.grid') + ' FR.xlsx');
            case 'en':
                return saveAs(GenericPlacementGridEN, t('generic.placement.grid') + ' EN.xlsx');
            case 'de':
                return saveAs(GenericPlacementGridDE, t('generic.placement.grid') + ' DE.xlsx');
            case 'es':
                return saveAs(GenericPlacementGridES, t('generic.placement.grid') + ' ES.xlsx');
            case 'sl':
                return saveAs(GenericPlacementGridSL, t('generic.placement.grid') + ' SL.xlsx');
            default:
                return saveAs(GenericPlacementGridEN, t('generic.placement.grid') + ' EN.xlsx');
        }
    };

    return (
        <>
            <b className="input-title">
                {t('download.generic.placement.grids')}
            </b>
            <table style={{ width: '100%', marginTop: 6, fontSize: 14 }}>
                <thead>
                    <tr>
                        <td style={{ ...styles.td, width: '70%' }}>
                            <b>{t('language')}</b>
                        </td>
                        <td style={{ ...styles.td, width: '30%' }}>
                            <b>{t('download')}</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {languages.filter(l => ['fr', 'en', 'de', 'es', 'sl'].includes(l.code)).map(language => (
                        <tr key={language.id}>
                            <td style={{ ...styles.td, width: '70%' }}>
                                {t(language.label)}
                            </td>
                            <td style={{ ...styles.td, width: '30%' }}>
                                <Button
                                    onClick={() => onDownloadGenericPlacementGrid(language.code)}
                                    variant="contained"
                                    style={{ padding: '4px 16px', backgroundColor: softskillsColor, color: 'white' }}
                                >
                                    {language.code}
                                    <DownloadIcon style={{ marginLeft: 5, fontSize: 16 }} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default AssessmentGrids;
