import React from 'react';

// Import Nivo/Pie
import { ResponsivePie } from '@nivo/pie';

// ---------------------------------------- CHART PIE ---------------------------------------- \\
const ChartPie = ({ data, fill, id }) => {
    return (
        <div id={id} className="chart">
            <ResponsivePie
                data={data}
                fill={fill}
                margin={{ top: 50, right: 200, bottom: 50, left: 50 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: 'set3' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        translateY: 56,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemTextColor: '#999',
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ]
                    },
                ]}
            />
        </div>
    );
};

export default ChartPie;
