// Tools to Create PDF
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Services
import { getFormattedDate, mobitrainColor, getTranslation } from 'services/helpers';

// Assets
import LogoZB from 'assets/images/zerobarrier.png';

// --------------------------------------- FORMATION DETAILS EXPORT ---------------------------------------- \\
const FormationDetailsExport = (t, state, setState) => {
    
    // Formation
    var formation = state.formation;

    // Create new PDF
    const pdf = new jsPDF();

    // Logo Zero Barrier
    pdf.addImage(LogoZB, 10, 10, 36.5, 7.7);

    // Title
    pdf.setFontSize(14);
    pdf.setFont('helvetica', 'bold');
    pdf.text(formation.name?.toUpperCase(), 105, 25, { align: 'center' });
    pdf.text(t('training.of') + ' ' + getFormattedDate(formation.formation_date), 105, 30, { align: 'center' });

    // Details
    let trainers = '';
    formation.formation_trainers.forEach(item => {
        trainers += item.trainer?.full_name + (item.trainer?.company != null ? ' (' + item.trainer.company?.name + ')' : '') + '\r\n';
    });

    let learners = '';
    formation.formation_learners.forEach(item => {
        learners += item.learner?.full_name + (item.learner?.company != null ? ' (' + item.learner.company?.name + ')' : '') + '\r\n';
    });

    let descriptors = '';
    formation.descriptors.forEach(descriptor => {
        descriptor.competences.forEach(competence => {
            descriptors += competence.numerotation + t('competence.numerotation') + ' ' + getTranslation('name', competence) + '\r\n'
        });
        descriptor.knowledges.forEach(knowledge => {
            descriptors += knowledge.numerotation + t('knowledge.numerotation') + ' ' + getTranslation('name', knowledge) + '\r\n'
        });
        descriptor.skills.forEach(skill => {
            descriptors += skill.numerotation + t('skill.numerotation') + ' ' + getTranslation('name', skill) + '\r\n'
        });
    });

    autoTable(pdf, {
        margin: {
            top: 40,
            right: 10,
            left: 10,
            bottom: 10,
        },
        theme: 'grid',
        head: [[ { content: t('formation.details'), colSpan: 2 } ]],
        headStyles: { fillColor: mobitrainColor, textColor: 'white', fontStyle: 'bold', halign: 'center' },
        body: [
            [t('entered.by'), formation.creator?.full_name],
            [t('time.start'), formation.start],
            [t('time.end'), formation.end],
            [t('place'), formation.place?.name + '\r\n' + (formation.place_details || '')],
            [t('type'), formation.type?.name + '\r\n' + (formation.type_details || '')],
            [t('description'), formation.program],
            [t('comment'), formation.comment],
            [t('trainers'), trainers],
            [t('learners'), learners],
            [t('skillbase'), getTranslation('name', formation.skillbase)],
            [t('descriptors'), descriptors],
        ],
        columnStyles: { 0: { cellWidth: 40 }, 1: { cellWidth: 150 } },
    });

    // Footers of Page
    var numberOfPages = pdf.internal.getNumberOfPages();
    for (let i = 0; i < numberOfPages; i += 1) {

        // Set Current Page
        pdf.setPage(i + 1);

        // Page Number
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'bolditalic');
        pdf.text(((i + 1) + ' / ' + numberOfPages), 105, 287, { align: 'center' });
    }

    // Save the PDF
    pdf.save(formation.name + '.pdf');
    setState({ ...state, loadingExport: false });
};

export default FormationDetailsExport;
